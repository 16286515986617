<div fxLayout="column" class="w-100-p" style="box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); padding: 12px; margin: 12px 0px;">
  <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%;margin-bottom: 12px;border-bottom: 3px solid lightgray;">
    <h2>
      Select the areas
    </h2>
    <span fxFlex></span>
    <div>
      <button mat-raised-button (click)="selectAreas('area', null)">Manage</button>
    </div>
  </div>
  <div style="box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); margin-bottom: 12px; padding: 0px 10px;" *ngFor="let item of model.areas">
    <div fxLayout="row" class="m-8">
      <h2 class="m-0 ml-0">
         Area: {{ item }}
        <!-- {{ item.title | textTranslate:currentLang }} -->
      </h2>
      <span fxFlex></span>
      <div>
        <a (click)="selectAreas('type', item)" style="color: blue; cursor: pointer;">Select category</a>
        <!-- <button mat-raised-button (click)="selectAreas('type', item)">Select category</button> -->
      </div>
    </div>
    <div fxLayout="row" *ngIf="model.typesObj && model.typesObj[item]" class="ml-12">
      <p class="mr-4">
        Selected Categories: 
      </p>
      <div *ngFor="let item4 of model.typesObj[item]">
        <p>
          {{ item4 }}, 
        </p>
      </div>
    </div>
  </div>
</div>