import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'app-datatable-hard-filter',
  templateUrl: './datatable-hard-filter.component.html',
  styleUrls: ['./datatable-hard-filter.component.scss']
})
export class DatatableHardFilterComponent implements OnInit {

  @Input() defaultHardFilter: string; 
  @Input() layout: string; 
  @Input() rows = []; 
  @Input() showBadge: boolean; 
  @Input() stepLabel: string; 
  @Input() appHardFilter = ''; 
  @Input() currentMQFilter: string; 
  @Input() dtTableAdvanceFilters: Array<any> = []; 
  @Output() hardFilterClicked: EventEmitter<Object> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  buttonClicked(filter: any) {
    this.hardFilterClicked.emit(filter.value);
  }
}
