import * as tslib_1 from "tslib";
import { Query } from '@datorama/akita';
import { AuthStore } from './auth.store';
import * as i0 from "@angular/core";
import * as i1 from "./auth.store";
var AuthQuery = /** @class */ (function (_super) {
    tslib_1.__extends(AuthQuery, _super);
    function AuthQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        _this.isAuthenticated = _this.select(function (state) { return !!state.token; });
        _this.officeName = _this.select(function (state) { return state.officeName; });
        _this.officeId = _this.select(function (state) { return state.officeId; });
        _this.email = _this.select(function (state) { return state.email; });
        _this.isAdmin = _this.select(function (state) { return state.isAdmin; });
        _this.redirectUrl = _this.select(function (state) { return state.redirectUrl; });
        _this.defaultLang = _this.select(function (state) { return state.defaultLang; });
        _this.invitationCount = _this.select(function (state) { return state.invitationCount; });
        return _this;
    }
    AuthQuery.ngInjectableDef = i0.defineInjectable({ factory: function AuthQuery_Factory() { return new AuthQuery(i0.inject(i1.AuthStore)); }, token: AuthQuery, providedIn: "root" });
    return AuthQuery;
}(Query));
export { AuthQuery };
