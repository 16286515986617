/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./excel-import.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i3 from "@angular/material/list";
import * as i4 from "@angular/common";
import * as i5 from "./excel-import.component";
import * as i6 from "../../../services/helpers/helpers.service";
import * as i7 from "../../../services/office-helpers/office-helpers.service";
var styles_ExcelImportComponent = [i0.styles];
var RenderType_ExcelImportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExcelImportComponent, data: {} });
export { RenderType_ExcelImportComponent as RenderType_ExcelImportComponent };
function View_ExcelImportComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["class", "mat-list-item"], ["mat-list-item", ""]], [[4, "background", null], [4, "color", null], [2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSelect(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(1, 1097728, null, 3, i3.MatListItem, [i1.ElementRef, [2, i3.MatNavList], [2, i3.MatList]], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(335544320, 2, { _avatar: 0 }), i1.ɵqud(335544320, 3, { _icon: 0 }), (_l()(), i1.ɵted(5, 2, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.model && (_v.context.$implicit._id == _co.model.importRecId)) ? "rgba(200,200,200,0.5)" : ""); var currVal_1 = ((_co.model && (_v.context.$implicit._id == _co.model.importRecId)) ? "black" : ""); var currVal_2 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_3 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _v.context.$implicit.label; _ck(_v, 5, 0, currVal_4); }); }
function View_ExcelImportComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "mat-nav-list", [["class", "mat-nav-list mat-list-base"], ["role", "navigation"]], null, null, null, i2.View_MatNavList_0, i2.RenderType_MatNavList)), i1.ɵdid(2, 49152, null, 0, i3.MatNavList, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ExcelImportComponent_2)), i1.ɵdid(4, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.regRecList; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_ExcelImportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExcelImportComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.field.hide; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ExcelImportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-excel-import", [], null, null, null, View_ExcelImportComponent_0, RenderType_ExcelImportComponent)), i1.ɵdid(1, 114688, null, 0, i5.ExcelImportComponent, [i6.HelpersService, i7.OfficeHelpersService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExcelImportComponentNgFactory = i1.ɵccf("app-excel-import", i5.ExcelImportComponent, View_ExcelImportComponent_Host_0, { model: "model", field: "field", translateData: "translateData" }, { onFieldValueUpdated: "onFieldValueUpdated", modelChange: "modelChange" }, []);
export { ExcelImportComponentNgFactory as ExcelImportComponentNgFactory };
