import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { DtTableAdvanceFilterComponent } from './dt-table-advance-filter.component';

@NgModule({
    declarations: [
      DtTableAdvanceFilterComponent
    ],
    imports     : [
       FuseSharedModule
    ],
    exports: [
      DtTableAdvanceFilterComponent
    ],
    entryComponents: [
        DtTableAdvanceFilterComponent
    ]
})

export class DtTableAdvanceFilterModule { }
