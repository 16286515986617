import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableColumnSettingsComponent } from './data-table-column-settings.component';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
  declarations: [DataTableColumnSettingsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FuseSharedModule
  ],
  entryComponents: [
    DataTableColumnSettingsComponent
  ]
})
export class DataTableColumnSettingsModule { }
