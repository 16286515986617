import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-room-view-link',
  templateUrl: './room-view-link.component.html',
  styleUrls: ['./room-view-link.component.scss']
})
export class RoomViewLinkComponent implements OnInit {

  @Input() recData: string;
  @Input() regId: string;
  @Input() field: any;
  builder;
  @Input() tempService: any;
  @Input() translateData: any;
  
  constructor() { }

  ngOnInit() {
  }

  closeDialog() {
    this.tempService.closePopup(null);
  }
}
