import { HelpersService } from './../../../services/helpers/helpers.service';
import { OnInit, EventEmitter, AfterViewInit, ChangeDetectorRef, OnChanges } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatDialog } from '@angular/material';
import { FieldOptionUpdateDialogComponent } from 'app/main/content/components/field-option-update-dialog/field-option-update-dialog.component';
var ButtonListComponent = /** @class */ (function () {
    function ButtonListComponent(translationLoader, dialog, changeDetectorRef, _helperService) {
        this.translationLoader = translationLoader;
        this.dialog = dialog;
        this.changeDetectorRef = changeDetectorRef;
        this._helperService = _helperService;
        // tslint:disable-next-line:no-output-on-prefix
        this.onFieldValueUpdated = new EventEmitter();
        this.parentIdChange = new EventEmitter();
        this.modelChange = new EventEmitter();
        this.valChange = new EventEmitter();
        // tslint:disable-next-line:no-output-on-prefix
        this.onError = new EventEmitter();
        this.loading = false;
        this.error = false;
        this.errorMessage = '';
        this.model = this.model || '';
    }
    ButtonListComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.translateData && changes.translateData.currentValue) {
            this.translateData = changes.translateData.currentValue;
            this.translationLoader.loadTranslationsV2(this.translateData);
        }
        console.log("button init - ", this.field);
    };
    ButtonListComponent.prototype.editOptions = function () {
        var _this = this;
        var dialogRef = this.dialog.open(FieldOptionUpdateDialogComponent, {
            width: '850px',
            height: 'max-content',
            disableClose: true,
            restoreFocus: false,
            data: {
                fieldLabel: this.field.label,
                formData: this.formData,
                id: this.field.id,
                fieldOptions: JSON.parse(JSON.stringify(this.field.options || [])) || [],
                field: this.field
            },
            panelClass: 'happ-form-builder-dialog'
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.field.options = result;
                _this.changeDetectorRef.detectChanges();
            }
        });
    };
    ButtonListComponent.prototype.getOptions = function () {
        var _this = this;
        if (this.field.regId && this.field.optionTitle) {
            this.field.optionTitle = this.field.optionTitle || '';
            var temp_1 = this.field.optionTitle.split(',').map(function (ele) { return ({
                prop: ele.trim(),
                title: ele.trim()
            }); });
            var cols = temp_1.concat([
                {
                    prop: '_id',
                    title: '_id'
                }
            ]);
            if (this.field.optionValue) {
                cols.push(this.field.optionValue);
            }
            this.loading = true;
            this._helperService.getDataForOptionBasedField(this.field.regId, cols, this.field.hardFilter, this.field.sortBy)
                .then(function (data) {
                var tt = [];
                data = data || [];
                data.forEach(function (ele) {
                    if (_this.field.optionValue) {
                        tt.push({
                            title: temp_1.map(function (ele2) { return (ele[ele2.prop]); }).join('-'),
                            value: ele[_this.field.optionValue],
                            _id: ele._id
                        });
                    }
                    else {
                        tt.push({
                            title: temp_1.map(function (ele2) { return (ele[ele2.prop]); }).join('-'),
                            _id: ele._id
                        });
                    }
                });
                _this.field.options = tt || [];
                _this.loading = false;
                // this.processValue();
                _this.changeDetectorRef.detectChanges();
            }).catch(function (err) {
                console.info(err);
                _this.loading = false;
                _this.changeDetectorRef.detectChanges();
            });
        }
    };
    ButtonListComponent.prototype.objectToString = function (data) {
        if (typeof (data) === 'object') {
            return '';
        }
        else {
            return data;
        }
    };
    ButtonListComponent.prototype.simpleButton = function (item) {
        if (this.field.saveParentId) {
            this.parentId = item._id;
            this.parentIdChange.next(this.parentId);
        }
        this.model = item.value || item.title;
        this.onChange(null);
    };
    ButtonListComponent.prototype.onChange = function (name) {
        var val = JSON.parse(JSON.stringify(this.model));
        if (this.field && this.field.typeNumber) {
            this.model = parseFloat(this.model);
        }
        if (name) {
            if (this.field.commaSeperated) {
                var t1 = this.model.split(',').map(function (ele) { return ele.trim(); });
                if (t1.includes(name)) {
                    t1.splice(t1.indexOf(name), 1);
                }
                else {
                    t1.push(name);
                }
                val = t1.join(', ');
                this.model = val;
            }
            else {
                if (typeof (this.model) !== 'object') {
                    this.model = {};
                }
                this.model[name] = !this.model[name];
                val[name] = !val[name];
            }
        }
        this.valChange.emit({
            model: val
        });
        this.modelChange.next(val);
        if (this.field['required'] && (this.model === null || this.model === undefined || this.model === '')) {
            this.error = true;
            this.errorMessage = 'ct.field_is_required';
            if (!this.model) {
                this.onError.emit({
                    error: true,
                    label: this.field['label']
                });
            }
        }
        else {
            this.errorMessage = '';
            this.error = false;
            this.onError.emit({
                error: false,
                label: this.field['label']
            });
        }
        console.log("button clicked - ", this.field);
        if (this.field.haveRules || true) {
            this.onFieldValueUpdated.emit({
                label: this.field.label,
                value: this.model,
                ruleIndex: this.field.ruleIndex,
                field: this.field
            });
            this.changeDetectorRef.detectChanges();
        }
    };
    ButtonListComponent.prototype.ngAfterViewInit = function () {
        var temp = {};
        if (this.field.multiple) {
            if (this.field.commaSeperated) {
                if (!this.field.userRegister) {
                    this.model = this.objectToString(this.model) || '';
                    var t1_1 = this.model.split(',').map(function (ele) { return ele.trim(); });
                    this.field['options'].forEach(function (element) {
                        if (t1_1.includes(element.value)) {
                            temp[element.value] = true;
                        }
                        else {
                            temp[element.value] = false;
                        }
                    });
                    this.model = temp;
                }
            }
            else {
                this.field['options'].forEach(function (element) {
                    temp[element.value] = false;
                });
                this.model = (this.model) ? this.model : temp;
            }
        }
        else {
            if (typeof (this.model) === 'object') {
                this.model = '';
            }
            else {
                this.model = this.model || '';
            }
        }
        if (this.field['preselectedValue'] && this.model && this.model.length === 0) {
            this.model = this.field['preselectedValue'];
            this.onChange(null);
        }
    };
    ButtonListComponent.prototype.processValue = function () {
        var temp = {};
        if (this.field.multiple) {
            if (this.field.commaSeperated) {
                this.model = this.objectToString(this.model) || '';
                var t1_2 = this.model.split(',').map(function (ele) { return ele.trim(); });
                this.field['options'].forEach(function (element) {
                    if (t1_2.includes(element.value)) {
                        temp[element.value] = true;
                    }
                    else {
                        temp[element.value] = false;
                    }
                });
                this.model = temp;
            }
            else {
                this.field['options'].forEach(function (element) {
                    temp[element.value] = false;
                });
                this.model = (this.model) ? this.model : temp;
            }
        }
    };
    ButtonListComponent.prototype.ngOnInit = function () {
        if (this.field.userRegister) {
            this.getOptions();
        }
        this.changeDetectorRef.markForCheck();
        this.translationLoader.loadTranslationsV2(this.translateData);
        if (this.field['required'] && (this.model === null || this.model === undefined || this.model === '')) {
            this.error = true;
            this.errorMessage = 'ct.field_is_required';
            if (!this.model) {
                this.onError.emit({
                    error: true,
                    label: this.field['label']
                });
            }
        }
    };
    Object.defineProperty(ButtonListComponent.prototype, "getLength", {
        get: function () {
            var _this = this;
            if (this.model && typeof (this.model) === 'object') {
                var temp_2 = [];
                Object.keys(this.model).forEach(function (ele) {
                    if (_this.model[ele]) {
                        temp_2.push(ele);
                    }
                });
                return temp_2;
            }
            else if (this.model && typeof (this.model) === 'string') {
                return this.model.split(',').map(function (ele) { return ele.trim(); });
            }
        },
        enumerable: true,
        configurable: true
    });
    return ButtonListComponent;
}());
export { ButtonListComponent };
