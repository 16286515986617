import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HelpersService } from '../../../services/helpers/helpers.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { md5 } from '../../authentication/md5';

@Component({
  selector: 'app-migrate',
  templateUrl: './migrate.component.html',
  styleUrls: ['./migrate.component.scss']
})
export class MigrateComponent implements OnInit {

  @Input()
  userInvitationId: string;

  loginForm: FormGroup;
  loginFormErrors: any;
  languages: any;
  selectedLangCode: string;
  errMess;
  loader = false;
  isSuccess = false;

  constructor(
    private _formBuilder: FormBuilder,
    private translate: TranslateService,
    private translationLoader: FuseTranslationLoaderService,
    private helperService: HelpersService,
    private http: HttpClient


  ) {
    this.loginFormErrors = {
      email: {},
      password: {}
  };
   }

  ngOnInit() {
    this.helperService
        .getDefaultLangs()
        .then(lang => {
            this.languages = lang || [];
            this.selectedLangCode = this.translate.currentLang;
        })
        .catch(err => {
            console.info(err);
        });

    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

    this.loginForm.valueChanges.subscribe(
        () => {
            this.onLoginFormValuesChanged();
        },
        err => {
            console.info(err);
        }
    );
  }

  onLoginFormValuesChanged(): void {
    for (const field in this.loginFormErrors) {
        if (!this.loginFormErrors.hasOwnProperty(field)) {
            continue;
        }

        // Clear previous errors
        this.loginFormErrors[field] = {};

        // Get the control
        const control = this.loginForm.get(field);

        if (control && control.dirty && !control.valid) {
            this.loginFormErrors[field] = control.errors;
        }
    }
  }

  login() {
    this.loader = true;
        const user = {
            email: this.loginForm.get('email').value,
            password: md5(this.loginForm.get('password').value)
        };
    this.http.post(`${environment.serverUrl}api/offices/user-invitation/${this.userInvitationId}/migrate-invitation`, user)
    .subscribe((data) => {
      this.loader = false;
      this.isSuccess = true;
      this.helperService.openSnackBar('Invitation Migrated Successfully');
    }, err => {
      this.loader = false;
      this.helperService.handleHttpError(err);
    });
  }

}
