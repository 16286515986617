import { CKEditor5 } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const CK_EDITOR_CONFIG: CKEditor5.Config = {
    language: 'en',
    minHeight: '300px',
    toolbar: {
        
        items: [
            'heading', 
            'undo', 
            'redo', 
            'bold', 
            'italic', 
            'imageTextAlternative', 
            'imageUpload', 
            'imageStyle:full', 
            'imageStyle:side', 
            'link', 
            'numberedList', 
            'bulletedList', 
            'mediaEmbed', 
            'insertTable', 
            'tableColumn', 
            'tableRow', 
            'fontSize',
            'mergeTableCells'
    ],
        shouldGroupWhenFull: true
    }
};

export const CK_EDITOR = ClassicEditor;
