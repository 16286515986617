import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss']
})
export class NumberComponent implements OnInit {
  @Input() field;
// tslint:disable-next-line:no-output-on-prefix
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() model: any; 
  @Input() translateData: any;

  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onError: EventEmitter<Object> = new EventEmitter();
  
    min_error = false;
    max_error = false;
    neg_error = false;
    dec_error = false;
    error: boolean;
    errorMessage: string;
    constructor() { }
  
    ngOnInit() {
      if (this.field['required'] && (this.model === null || this.model === undefined || (this.model === ''))) {
        this.error = true;
        this.errorMessage = 'ct.field_is_required';
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
      }
    }
  
    onKeyPress() {
      this.min_error = this.model < this.field.min;
      this.max_error = this.model > this.field.max;
      if (this.field['required'] && (this.model === null || this.model === undefined || (this.model === ''))) {
        this.errorMessage = 'ct.field_is_required';
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
      } else if (this.model < this.field.min) {
        this.model = this.field.min;
        this.errorMessage = 'ct.min_number_allowed_is';
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
      } else if (this.model > this.field.max) {
        this.model = this.field.max;
        this.errorMessage = 'ct.max_number_allowed_is';
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
      } else if (!this.field['allow_negative']) {
        if (this.model < 0) {
          this.errorMessage = 'ct.negative_number_is_not_allowed';        
          this.neg_error = true;
          this.model = this.model * -1;        
          this.onError.emit({
            error: true,
            label: this.field['label']
          });
        } else {
          this.neg_error = false;
          this.onError.emit({
            error: false,
            label: this.field['label']
          });
          this.errorMessage = '';
        }
      } else if (!this.field['allow_decimal']) {
        if (('' + this.model).indexOf('.') !== -1) {
          // tslint:disable-next-line:radix
          this.model = parseInt(('' + this.model).split('.').join(''));
          this.dec_error = true;
          this.errorMessage = 'ct.decimal_number_is_now_allowed';
          this.onError.emit({
            error: true,
            label: this.field['label']
          });
        } else {
          this.errorMessage = '';
          this.onError.emit({
            error: false,
            label: this.field['label']
          });
          this.dec_error = false;
        }
        
      } else if (this.field['allow_decimal']) {
        if (this.field['decimal_places']) {
          this.model = parseFloat(this.model.toFixed(this.field['decimal_places']));
        } else {
          this.errorMessage = '';
        }
      } else {
        this.errorMessage = '';
      }
      this.modelChange.next(this.model);
      if (this.field.haveRules) {
        this.onFieldValueUpdated.emit({
          label: this.field.label,
          value: this.model,
          ruleIndex: this.field.ruleIndex,
          field: this.field
        });
      }
    }
  
    
  pasteIt(eve) {
    eve.preventDefault();
    this.model = (eve && eve.clipboardData && eve.clipboardData.getData('Text')) || this.model;
    this.onKeyPress();
  }
}
