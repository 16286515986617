import { ActionModulesInterface, ActionAppsInterface } from './actions.interface';
import { IAction } from 'app/main/content/interfaces/register.interface';

export const ActionModules: Array<ActionModulesInterface> = [
  {
    key: 'customRegistries',
    title: 'Custom Registries List'
  },
  {
    key: 'customRegisterRecordsList',
    title: 'Custom Registries Records List'
  },
  {
    key: 'dynamicForm',
    title: 'Dynamic forms actions'
  },
  {
    key: 'addressbook',
    title: 'AddressBook List actions'
  },
  {
    key: 'agenda',
    title: 'Agenda List actions'
  }
];

export const ActionApps: Array<ActionAppsInterface> = [
  {
    name: 'RegRec Insert OneRecordview',
    app: 'app.oneRecordView.add',
    fuse2App: '/oneRecordView/new/:regId/:formId/new/ng5',
    group: 'record'
  },
  {
    name: 'RegRec Insert OneRecordview Popup',
    app: 'app.oneRecordView.add.popup',
    group: 'record'
  },
  {
    name: 'RegRec Edit OneRecordview',
    app: 'app.oneRecordView.edit',
    fuse2App: '/oneRecordView/edit/:regId/:formId/:recId/ng5',
    group: 'record'
  },
  {
    name: 'RegRec Edit OneRecordview Popup',
    app: 'app.oneRecordView.edit.popup',
    group: 'record'
  },
  {
    name: 'RegRec Edit OneRecordview side Menu',
    app: 'app.oneRecordView.edit.sideMenu',
    group: 'record'
  },
  {
    name: 'RegRec Edit Training OneRecordview',
    app: 'app.oneRecordView.training.edit',
    fuse2App: '/oneRecordView/training/:regId/:recId',
    group: 'record'
  },
  {
    name: 'RegRec Delete OneRecordview',
    app: 'deleteRecord',
    group: 'record'
  },
  {
    name: 'RegRec Insert OneRecordView + Instance',
    app: 'app.oneRecordView.processStatus',
    fuse2App: '/oneRecordView/recWithInstance/:regId/ng5',
    group: 'record'
  },
  {
    name: 'RegRec New Browse',
    app: 'app.customregistries.registriesrecordslistnew',
    group: 'register'
  },
  {
    name: 'RegRec Edit',
    app: 'app.customregistries.editRecords',
    group: 'record'
  },
  {
    name: 'Reg BarCode',
    app: 'app.customregistries.barCodeRecords',
    fuse2App: '/offices/:officeId/registries/groupOpened/all/barcode/single/:regId',
    group: 'special'
  },
  {
    name: 'RegRec Insert',
    app: 'app.customregistries.addRecords',
    group: 'record'
  },
  {
    name: 'RegRec Old Browse',
    app: 'app.customregistries.registriesrecordslist',
    group: 'register'
  },
  {
    name: 'AddressBook CMS',
    app: 'app.pinboxOfficeContacts2.dashboards.crm',
    group: 'register'
  },
  {
    name: 'Scanque',
    app: 'app.scanque',
    fuse2App: '/offices/:officeId/registries/scanqueuerecordslist/:regId',
    group: 'register'
  },
  {
    name: 'Reg Package Records',
    app: 'app.customregistries.acceptPackageRecords',
    fuse2App: '/offices/:officeId/registries/groupOpened/all/barcode/package/:regId',
    group: 'special'
  },
  {
    name: 'Reg PostBook',
    app: 'app.customregistries.postBook',
    fuse2App: '/offices/:officeId/postBook/:regId',
    group: 'special'
  },
  {
    name: 'Reg Search Records',
    app: 'app.customregistries.searchRecords',
    group: 'special'
  },
  {
    name: 'Select From Third Register',
    app: 'app.customregistries.selectRegister',
    fuse2App: '/offices/:officeId/registries/card/all/:priceListRegId/selection/:childRegId/:parentRegId',
    group: 'position'
  },
  {
    name: 'Position Insert',
    app: 'app.customregistries.positionInsert',
    // fuse2App: '/oneRecordView/child/:parentId/new/:regId/:formId/:recId/ng5',
    group: 'position'
  },
  {
    name: 'Select From Third Register',
    app: 'app.customregistries.positionEdit',
    fuse2App: '/oneRecordView/child/:parentId/edit/:regId/:formId/:recId/ng5',
    group: 'position'
  },
  {
    name: 'Insert with the Instance',
    app: 'app.customregistries.positionInsertWithInstance',
    fuse2App: '/oneRecordView/child/:parentId/recWithInstance/:regId/ng5',
    group: 'position'
  },
  {
    name: 'Parent To Child Register Browse',
    app: 'app.customregistries.openChildRegister',
    group: 'position'
  },
  {
    name: 'Stepper - RegRec Insert OneRecordview',
    app: 'app.stepperOneRecordView.add',
    fuse2App: 'form/:formId/reg/:regId/rec/new/new',
    group: 'stepper'
  },
  {
    name: 'Stepper - RegRec Browse',
    app: 'app.stepperBrowse',
    fuse2App: '/offices/:officeId/academy/courses/all/stepper/:regId/0',
    group: 'stepper'
  },
  {
    name: 'Stepper - RegRec Edit OneRecordview',
    app: 'app.stepperOneRecordView.edit',
    fuse2App: 'form/:formId/reg/:regId/rec/:recId/edit',
    group: 'stepper'
  },
  {
    name: 'Stepper - RegRec Insert OneRecordView + Instance',
    app: 'app.stepperOneRecordView.processStatus',
    fuse2App: ':regId/recWithInstance',
    group: 'stepper'
  },
  {
    name: 'Task Settings',
    app: 'app.message.start',
    fuse2App: '',
    group: 'message_api'
  },
];

export const ActionPlacements = [{ name: 'headerActions' }, { name: 'subheaderActions' }, { name: 'recordActions' }, { name: 'dropdownActions' }];

export const ActionDataModel: IAction = {
  default_label: '',
  translations: {
    pl: '',
    en: ''
  },
  icon: '',
  sort: 0,
  placement: {
    headerActions: false,
    subheaderActions: false,
    recordActions: true,
    dropdownActions: false
  },
  module: '',
  application: '',
  fuse2App: '',
  params: { json: '' },
  applicationName: '',
  access_level: '',
  actionParams: '',
  actionType: 'subAction'
};
