import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import * as _ from 'lodash';
import { OfficeHelpersService } from '../../services/office-helpers/office-helpers.service';
import { AuthStore } from '../../state/authState/auth.store';
import { OfficeStore } from '../../state/officeState/office.store';
import { HelpersService } from '../../services/helpers/helpers.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { WorkspaceStore } from '../workspaceState/workspace.store';

@Injectable()
export class OfficeGlobalService {
    
  constructor(
    private _officeHelper: OfficeHelpersService,
    private authStore: AuthStore,
    private officeStore: OfficeStore,
    private helperService: HelpersService,
    private fuseConfig2: FuseConfigService,
    private workspaceStore: WorkspaceStore
  ) { }

  
  async changeOffice(officeId: string, url?: any) {
    try {
      localStorage.setItem("selectedOfficeId", JSON.stringify(officeId));
      const officeDoc = await this._officeHelper.loadNewOffice(officeId);
      this.updateAllDataForNewOffice(officeDoc, url);
    } catch (error) {
      throw error;
    }
  }

  async changeVirtualOffice(orgId: string, url?: any) {
    try {
      localStorage.setItem("selectedVirtualOrgId", JSON.stringify(orgId));
      // return localStorage.getItem("tempInitUrl") || `/offices/${this.office}/dashboard`
      // const officeDoc = await this._officeHelper.loadNewOffice(orgId);
      // this.updateAllDataForNewOffice(officeDoc, url);
      await this._officeHelper.updateOfficeDoc({ lastSelectedOrg: orgId })
    } catch (error) {
      throw error;
    }
  }

  updateAllDataForNewOffice(officeDoc: any, url?: any) {
    this.workspaceStore.reset();
    this.authStore.update({ officeId: (officeDoc['_id']), officeName: officeDoc.name, redirectUrl: url || `/offices/${officeDoc['_id']}/dashboard` });
    this.officeStore.update(officeDoc);
    this.helperService.getFuseConfig()
      .then((fuseSettings) => {
          if (fuseSettings && Object.keys(fuseSettings).length) {
              this.fuseConfig2.setConfig(fuseSettings);
          } else {
              this.fuseConfig2.resetToDefaults();
          }
      }).catch(err => {
          console.info(err);
          this.fuseConfig2.resetToDefaults();
      });
  }

}
