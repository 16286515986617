import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { NumberComponent } from './number.component';



@NgModule({
    declarations: [
      NumberComponent
    ],
    imports     : [
       FuseSharedModule
    ],
    exports: [
      NumberComponent
    ]
})

export class NumberModule
{

}
