import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-save-contact-info-popup',
  templateUrl: './save-contact-info-popup.component.html',
  styleUrls: ['./save-contact-info-popup.component.scss']
})
export class SaveContactInfoPopupComponent implements OnInit {

  keys = [
    {
      title: 'deliveryAddress.shortName',
      key: 'shortName'
    },
    {
      title: 'deliveryAddress.longName',
      key: 'longName'
    },
    {
      title: 'deliveryAddress.companyName',
      key: 'companyName'
    },
    {
      title: 'deliveryAddress.companyAdress',
      key: 'companyAdress'
    },
    {
      title: 'deliveryAddress.companyCity',
      key: 'companyCity'
    },
    {
      title: 'deliveryAddress.postalcode',
      key: 'postalcode'
    }
  ];

  constructor(
    public dialogRef: MatDialogRef<SaveContactInfoPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,

  ) { }

  ngOnInit() {
    if (this.data && this.data.field && this.data.field.showFourFields) {
      this.keys = this.keys.splice(2);
    }
  }

}
