import { ProfileHelperService } from './../main/content/services/profile-helper/profile-helper.service';
import {  Injectable } from '@angular/core';
import { AuthService } from '../main/content/pages/authentication/auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';
import { IMainMenuItem } from 'app/main/content/interfaces/mainMenuItem.interface';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { IOfficeData, IOfficeUser } from 'app/main/content/interfaces/office.interface';
import { FuseNavigation } from '@fuse/types';
import { IProfileWorkspace, IProfile } from 'app/main/content/interfaces/profile.interface';
import { CreateWorkspaceComponent } from 'app/main/content/apps/workspace-management/create-workspace/create-workspace.component';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { MatDialog } from '@angular/material';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { Router } from '@angular/router';
import { AuthQuery } from 'app/main/content/state/authState/auth.query';

export const navigation: FuseNavigation[] = [];

@Injectable()
export class FuseNavigationMenuService {
    public updateMenu: BehaviorSubject<boolean> = new BehaviorSubject(false);
    CLIENT_PANEL_THEME = {
        isNotOffice: true,
        logout: () => {
            this.authService.logOut("cp-login")
        },
        layout: {
            navbar   : {
                hidden: false,
                folded: false
            },
            toolbar  : {
                hidden: true
            },
            footer   : {
                hidden: true
            },
            sidepanel: {
                hidden: true
            }
        }
    };
    CLIENT_PANEL_NAV = [
        {
            'id'       : 'general',
            'title'    : 'toolbar.general',
            'translate': 'toolbar.general',
            'type'     : 'group',
            'children' : [
            {
                'id': '1',
                'title': 'toolbar.dashboard',
                'translate': 'toolbar.dashboard',
                'type': 'item',
                'heading': '',
                'headingDesc': '',
                'slug': 'dashboard',
                'sort': 0,
                'icon': 'home',
                'url': `/cp/dashboard`
            }
        ]
    },
    {
        'id'       : 'cases',
        'title'    : 'toolbar.cases',
        'translate': 'toolbar.cases',
        'type'     : 'group',
        'children' : [
            {
                'id': '15',
                'title': 'toolbar.active_cases',
                'translate': 'toolbar.active_cases',
                'type': 'item',
                'queryParams': { status: "active" },
                'heading': '',
                'headingDesc': '',
                'slug': 'active_cases',
                'sort': 15,
                'icon': 'work',
                'url': `/cp/my-cases/personal/all`
            },
    
            {
                'id': '20',
                'title': 'toolbar.closed_cases',
                'translate': 'toolbar.closed_cases',
                'type': 'item',
                'heading': '',
                'queryParams': { status: "closed" },
                'headingDesc': '',
                'slug': 'closed_cases',
                'sort': 20,
                'icon': 'close',
                'url': `/cp/my-cases/personal/all`
            },
            {
                'id': '21',
                'title': 'toolbar.archived_cases',
                'translate': 'toolbar.archived_cases',
                'type': 'item',
                'queryParams': { status: "archive" },
                'heading': '',
                'headingDesc': '',
                'slug': 'archived_cases',
                'sort': 21,
                'icon': 'archive',
                'url': `/cp/my-cases/personal/all`
            },
            {
                'id': '24',
                'title': 'toolbar.new_cases',
                'translate': 'toolbar.new_cases',
                'type': 'item',
                'heading': '',
                'headingDesc': '',
                'slug': 'add_circle',
                'sort': 24,
                'icon': 'view_agenda',
                'url': `/cp/services`
            },
            {
                'id': '30',
                'title': 'toolbar.calendar',
                'translate': 'toolbar.calendar',
                'type': 'item',
                'heading': '',
                'headingDesc': '',
                'slug': 'add_circle',
                'sort': 24,
                'icon': 'view_agenda',
                'url': `/cp/calendar`
            },
            {
                'id': '40',
                'title': 'toolbar.personal_data',
                'translate': 'toolbar.personal_data',
                'type': 'item',
                'heading': '',
                'headingDesc': '',
                'slug': 'add_circle',
                'sort': 24,
                'icon': 'view_agenda',
                'url': `/cp/personal-data`
            },
            {
                'id': '30',
                'title': 'toolbar.room_event_list',
                'translate': 'toolbar.room_event_list',
                'type': 'item',
                'heading': '',
                'headingDesc': '',
                'slug': 'add_circle',
                'sort': 24,
                'icon': 'view_agenda',
                'url': `/cp/room-events`
            },
        ]
    },
    {
        'id'       : 'financial',
        'title'    : 'toolbar.financial',
        'translate': 'toolbar.financial',
        'type'     : 'group',
        'children' : [
     {
        'id': '30',
        'title': 'toolbar.orders',
        'translate': 'toolbar.orders',
        'type': 'item',
        'heading': '',
        'headingDesc': '',
        'slug': 'orders',
        'sort': 30,
        'icon': 'description',
        'url': `/cp/orders`
     },
     {
        'id': '35',
        'title': 'toolbar.payments',
        'translate': 'toolbar.payments',
        'type': 'item',
        'heading': '',
        'headingDesc': '',
        'slug': 'payments',
        'sort': 35,
        'icon': 'payment',
        'url': `/cp/payments`
     },
     {
        'id': '47',
        'title': 'toolbar.invoices',
        'translate': 'toolbar.invoices',
        'type': 'item',
        'heading': '',
        'headingDesc': '',
        'slug': 'invoices',
        'sort': 47,
        'icon': 'description',
        'url': `/cp/invoices`
     },
    ]},
    {
        'id'       : 'settings',
        'title'    : 'toolbar.settings',
        'translate': 'toolbar.settings',
        'type'     : 'group',
        'children' : [
            {
                'id': '58',
                'title': 'toolbar.user_profile',
                'translate': 'toolbar.user_profile',
                'type': 'item',
                'heading': '',
                'headingDesc': '',
                'slug': 'user_profile',
                'sort': 58,
                'icon': 'face',
                'url': `/cp/user/profile`
            },
            {
                'id': '58',
                'title': 'toolbar.office_view',
                'translate': 'toolbar.office_view',
                'type': 'item',
                'heading': '',
                'headingDesc': '',
                'slug': 'office_view',
                'sort': 58,
                'icon': 'face',
                'function': () => {
                    this._profileHelper.getLocalProfileData()
                    .then((data: any) => {
                        if (data.lastSelectedOffice && data.offices && data.offices.length) {
                            this._router.navigateByUrl(`/offices/${data.lastSelectedOffice}/dashboard`);
                        } else {
                            this._router.navigateByUrl(`/dashboard`);
                        }
                    }).catch(err => {
                        console.error(err);
                    });
                }
            }
        ]
    }
    ]
    SPECIALIST_PANEL_NAV = [
    {
        'id'       : 'specialist',
        'title'    : 'toolbar.specialist',
        'translate': 'toolbar.specialist',
        'type'     : 'group',
        'children' : [
            {
                'id': '30',
                'title': 'toolbar.dashboard',
                'translate': 'toolbar.dashboard',
                'type': 'item',
                'heading': '',
                'headingDesc': '',
                'slug': 'add_circle',
                'sort': 24,
                'icon': 'view_agenda',
                'url': `/cp/specialist/dashboard`
            },
            {
                'id': '30',
                'title': 'toolbar.cases',
                'translate': 'toolbar.cases',
                'type': 'item',
                'heading': '',
                'headingDesc': '',
                'slug': 'add_circle',
                'sort': 24,
                'icon': 'view_agenda',
                'url': `/cp/specialist/cases/specialist/all`
            },
            {
                'id': '30',
                'title': 'toolbar.specialist_calendar',
                'translate': 'toolbar.specialist_calendar',
                'type': 'item',
                'heading': '',
                'headingDesc': '',
                'slug': 'add_circle',
                'sort': 24,
                'icon': 'view_agenda',
                'url': `/cp/specialist/calendar`
            }
        ]
    },
    {
        'id'       : 'settings',
        'title'    : 'toolbar.settings',
        'translate': 'toolbar.settings',
        'type'     : 'group',
        'children' : [
            {
                'id': '58',
                'title': 'toolbar.user_profile',
                'translate': 'toolbar.user_profile',
                'type': 'item',
                'heading': '',
                'headingDesc': '',
                'slug': 'user_profile',
                'sort': 58,
                'icon': 'face',
                'url': `/cp/user/profile`
            },
            {
                'id': '58',
                'title': 'toolbar.office_view',
                'translate': 'toolbar.office_view',
                'type': 'item',
                'heading': '',
                'headingDesc': '',
                'slug': 'office_view',
                'sort': 58,
                'icon': 'face',
                'function': () => {
                    this._profileHelper.getLocalProfileData()
                    .then((data: any) => {
                        if (data.lastSelectedOffice && data.offices && data.offices.length) {
                            this._router.navigateByUrl(`/offices/${data.lastSelectedOffice}/dashboard`);
                        } else {
                            this._router.navigateByUrl(`/dashboard`);
                        }
                    }).catch(err => {
                        console.error(err);
                    });
                }
            }
        ]
    }
    ]
    defaultWorkspaceConfig: any = {
        showWorkspace: true,
        workspaceList: [],
        selectedWorkspace: {
            'id': 'dashboard-workspace',
            'title': 'toolbar.office_dashboard',
            'name': 'toolbar.office_dashboard',
            'translate': 'toolbar.office_dashboard',
            'type': 'item',
            'heading': '',
            'headingDesc': '',
            'sort': 0,
            'icon': 'home',
            'url': `/offices/dashboard`
        }
    };
    public $workspaceConfig: BehaviorSubject<any> = new BehaviorSubject(this.defaultWorkspaceConfig);
    public $virtualOrgValue: BehaviorSubject<any> = new BehaviorSubject({});
    public model: any[];
    public selectedOffice: string;
    public selectedOfficeObject: any;
    public isAdmin = false;
    public officeId: string;
    public menuId: string;
    public currentNavigation = [];
    public currentLang: string;
    public langIndex: object = {
        en: 0,
        pl: 1
    };

    customFunctionNavItem = {
        'id': 'custom-function',
        'title': 'Custom Function',
        'type': 'group',
        'icon': 'settings',
        'children': [
            {
                'id': 'customize',
                'title': 'Customize',
                'type': 'item',
                'icon': 'settings',
                'function': () => {
                    this._fuseSidebarService.getSidebar('themeOptionsPanel').toggleOpen();
                }
            }
        ]
    };
    constructor(
        private authService: AuthService,
        private translate: TranslateService,
        private officeHelpersService: OfficeHelpersService,
        public dialog: MatDialog,
        private helperService: HelpersService,
        private fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _profileHelper: ProfileHelperService,
        private _router: Router,
        private authQuery: AuthQuery

    ) {
        this.model = [];
        this.authService.loadUserCredentials();
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.currentLang = event['lang'] || 'en';
        });
        this.authQuery.isAdmin.subscribe((isAdmin) => {
            this.isAdmin = isAdmin;
        });
    }

    checkAdmin() {
        return this.isAdmin;
    }

    getWorkspaceFilterData() {
        let officeData: any = window.localStorage.getItem("ngStorage-selectedOffice")
        if (officeData) {
            officeData = JSON.parse(officeData);
        }
        let virtualOrgObj: any = localStorage.getItem("lastSelectedOrg");
        if (virtualOrgObj) {
            virtualOrgObj = JSON.parse(virtualOrgObj);
        }
        if (officeData.projects && officeData.projects.multiOrganizationOffice) {
            if (!virtualOrgObj) {
                return {
                    allowFilter: true,
                    workspaceIds: null
                }
            }
            if (virtualOrgObj && virtualOrgObj.virtualOrgWorkspacesIds) {
                const tempIds = virtualOrgObj.virtualOrgWorkspacesIds.split(",").map((ele) => (ele.trim())).filter((ele) => (ele));

                return {
                    allowFilter: true,
                    workspaceIds: tempIds
                }
            } else {
                return {
                    allowFilter: false,
                    workspaceIds: []
                }
            }
        } else {
            return {
                allowFilter: false,
                workspaceIds: []
            }
        }
    }


    public menuItems(orgSettings: any = {}) {
        console.log("orgSettings = ", orgSettings);
        this.model = [];
        let tempModel = [];
        return new Promise((resolve, reject) => {
            try {
                if (window.location.pathname.includes("/cp/specialist") && window.location.pathname !== '/cp-login') {
                    return resolve(this.SPECIALIST_PANEL_NAV);
                } else if (window.location.pathname.includes("/cp") && window.location.pathname !== '/cp-login') {
                    // const temp1 = JSON.parse(JSON.stringify(this.CLIENT_PANEL_THEME));
                    // console.log("7777777", temp1);
                    // temp1.logout = () => {
                    //     console.log("8888", this);
                    //     // this.authService.logOut();
                    // };
                    return resolve(this.CLIENT_PANEL_NAV);
                }
                this.resetValue();
                
                this.fuseNavigationService.setCurrentNavigation('loading');
                this.officeHelpersService.getLocalOfficeData().then((officeData: IOfficeData) => {
                    this.selectedOfficeObject = officeData;
                    const officeApps = this.officeHelpersService.getOfficeApps();
                    this.officeId = (this.officeHelpersService.officeData && this.officeHelpersService.officeData._id);
                    if (officeApps && officeApps['mainMenu'] && officeApps['mainMenu']['id']) {
                        this.menuId = officeApps['mainMenu']['id'];
                        if (this.officeId && this.menuId) {
                            this.officeHelpersService.getOfficeMainMenu()
                                .then((mainMenu: Array<IMainMenuItem>) => {
                                    tempModel = [];
                                    tempModel = [];
                                    this.createWorkspaceMenu(orgSettings)
                                        .then((tempWSMenu) => {
                                            if (tempWSMenu[0] && tempWSMenu[0].children && tempWSMenu[0].children.length) {
                                                tempModel = tempModel.concat(tempWSMenu);
                                            }

                                            let menuList: Array<IMainMenuItem> = mainMenu;
                                            menuList = menuList.sort((a, b) => {
                                                return (a && b && a['weight'] > b['weight']) ? 1 : ((a && b && b['weight'] > a['weight']) ? -1 : 0);
                                            });
                                            menuList.forEach(element => {
                                                if (this.isGroupType(element)) {
                                                    const tempObj = this.getGroupData(element);

                                                    menuList.forEach(ele => {
                                                        if (this.isSubGroupType(ele)) {
                                                            if (ele['subGroup']) {

                                                                const SubGroup = this.getSubGroupData(ele);
                                                                menuList.forEach(subGroupElement => {

                                                                    let params = '';
                                                                    // tslint:disable-next-line:max-line-length
                                                                    if (subGroupElement && (subGroupElement['state'] === 'app.customregistries.registriesList' || subGroupElement['state'] === 'app.stepperList' || subGroupElement['state'] === 'app.processInstances.dashboards')) {
                                                                        const temp = [];
                                                                        // tslint:disable-next-line:max-line-length
                                                                        if (subGroupElement['fuse2Url'] && (subGroupElement['state'] === 'app.customregistries.registriesList' || subGroupElement['state'] === 'app.stepperList')) {
                                                                            const viewType = (subGroupElement['registerViewType']) ? subGroupElement['registerViewType'] : 'card';
                                                                            params = '/' + viewType;
                                                                            // const expanded = (ele['expanded']) ? ele['expanded'] : 'expanded';
                                                                            // params = '/' + expanded;
                                                                        }
                                                                        subGroupElement['registerParams'] = subGroupElement['registerParams'] || [];
                                                                        subGroupElement['processParams'] = subGroupElement['processParams'] || [];

                                                                        // tslint:disable-next-line:max-line-length
                                                                        if (subGroupElement['state'] === 'app.customregistries.registriesList' || subGroupElement['state'] === 'app.stepperList') {
                                                                            subGroupElement['registerParams'].map(register => {
                                                                                if (register.selected) {
                                                                                    temp.push(register.key);
                                                                                }
                                                                            });
                                                                        }

                                                                        if (subGroupElement['state'] === 'app.processInstances.dashboards') {
                                                                            subGroupElement['processParams'].map(process => {
                                                                                if (process.selected) {
                                                                                    temp.push(process.key);
                                                                                }
                                                                            });
                                                                        }

                                                                        if (subGroupElement['state'] === 'app.stepperList') {
                                                                            params = '';
                                                                        }


                                                                        params += (temp && temp.length ? '/' + temp.join(',') : '/all');
                                                                    }

                                                                    if (subGroupElement && (subGroupElement['state'] === 'app.gdprResources')) {
                                                                        const regGroups = [];
                                                                        subGroupElement['registerGroups'].map(register => {
                                                                            if (register.selected) {
                                                                                regGroups.push(register.key);
                                                                            }
                                                                        });

                                                                        params = `/gdpr-resources/${regGroups.join(',')}`;
                                                                    }


                                                                    // tslint:disable-next-line:max-line-length
                                                                    if (subGroupElement && (subGroupElement['state'] === 'app.pinboxOfficeContacts2.contacts') && this.selectedOfficeObject && this.selectedOfficeObject['apps'] && this.selectedOfficeObject['apps']['office_addressbook'] && this.selectedOfficeObject['apps']['office_addressbook']['id']) {
                                                                        params = `/${this.selectedOfficeObject['apps']['office_addressbook']['id']}`;
                                                                    }

                                                                    // tslint:disable-next-line:max-line-length
                                                                    if (subGroupElement && (subGroupElement['state'] === 'app.globalCalendar') && this.selectedOfficeObject && this.selectedOfficeObject['apps'] && this.selectedOfficeObject['apps']['Agenda'] && this.selectedOfficeObject['apps']['Agenda']['id']) {
                                                                        params = `/${this.selectedOfficeObject['apps']['Agenda']['id']}`;
                                                                    }

                                                                    // tslint:disable-next-line:max-line-length
                                                                    if (ele && ele['type'] && subGroupElement && !subGroupElement['subGroup'] && !subGroupElement['hidden'] && subGroupElement['navigation'] && subGroupElement['navigation'].split('.').length >= 3 && subGroupElement['navigation'].split('.').includes(ele['type'])) {
                                                                        SubGroup['children'].push(this.getMenuItemChildren(subGroupElement, params));
                                                                    }


                                                                });

                                                                // tslint:disable-next-line:max-line-length
                                                                if (ele && !ele['hidden'] && ele['navigation'] && ele['navigation'].split('.').length > 1 && ele['navigation'].split('.').includes(element['navigation'])) {

                                                                    tempObj['children'].push(SubGroup);
                                                                }
                                                            }

                                                        } else {
                                                            let params = '';
                                                            // tslint:disable-next-line:max-line-length
                                                            if (ele && (ele['state'] === 'app.customregistries.registriesList' || ele['state'] === 'app.stepperList' || ele['state'] === 'app.processInstances.dashboards')) {
                                                                const temp = [];
                                                                // tslint:disable-next-line:max-line-length
                                                                if (ele['fuse2Url'] && (ele['state'] === 'app.customregistries.registriesList' || ele['state'] === 'app.stepperList')) {
                                                                    const viewType = (ele['registerViewType']) ? ele['registerViewType'] : 'card';
                                                                    params = '/' + viewType;

                                                                }
                                                                ele['registerParams'] = ele['registerParams'] || [];
                                                                ele['processParams'] = ele['processParams'] || [];

                                                                if (ele['state'] === 'app.customregistries.registriesList' || ele['state'] === 'app.stepperList') {
                                                                    ele['registerParams'].map(register => {
                                                                        if (register.selected) {
                                                                            temp.push(register.key);
                                                                        }
                                                                    });
                                                                }

                                                                if (ele['state'] === 'app.processInstances.dashboards') {
                                                                    ele['processParams'].map(process => {
                                                                        if (process.selected) {
                                                                            temp.push(process.key);
                                                                        }
                                                                    });
                                                                }
                                                                if (ele['state'] === 'app.stepperList') {
                                                                    params = '';
                                                                }
                                                                params += (temp && temp.length ? '/' + temp.join(',') : '/all');
                                                            }

                                                            if (ele && (ele['state'] === 'app.gdprResources')) {
                                                                const regGroups = [];
                                                                ele['registerGroups'].map(register => {
                                                                    if (register.selected) {
                                                                        regGroups.push(register.key);
                                                                    }
                                                                });

                                                                params = `/gdpr-resources/${regGroups.join(',')}`;
                                                            }
                                                            // tslint:disable-next-line:max-line-length
                                                            if (ele && (ele['state'] === 'app.pinboxOfficeContacts2.contacts') && this.selectedOfficeObject && this.selectedOfficeObject['apps'] && this.selectedOfficeObject['apps']['office_addressbook'] && this.selectedOfficeObject['apps']['office_addressbook']['id']) {
                                                                params = `/${this.selectedOfficeObject['apps']['office_addressbook']['id']}`;
                                                            }

                                                            // tslint:disable-next-line:max-line-length
                                                            if (ele && (ele['state'] === 'app.globalCalendar') && this.selectedOfficeObject && this.selectedOfficeObject['apps'] && this.selectedOfficeObject['apps']['Agenda'] && this.selectedOfficeObject['apps']['Agenda']['id']) {
                                                                params = `/${this.selectedOfficeObject['apps']['Agenda']['id']}`;
                                                            }

                                                            // tslint:disable-next-line:max-line-length
                                                            if (ele && !ele['hidden'] && ele['navigation'] && ele['navigation'].split('.').length > 1 && ele['navigation'].split('.')[0].includes(element['navigation'])) {
                                                                tempObj['children'].push(this.getMenuItemChildren(ele, params));
                                                            }
                                                        }
                                                    });
                                                    if (tempObj && tempObj.children && tempObj.children.length) {
                                                        tempModel.push(tempObj);
                                                    }
                                                }
                                            });
                                            // if (this.isAdmin) {
                                            //     tempModel.push(this.customFunctionNavItem);
                                            // }
                                            this.model = tempModel;
                                            this.currentNavigation = this.model;
                                            resolve(tempModel);
                                        }).catch(err => {
                                            console.info(err);
                                            reject(err);
                                        });
                                }).catch(err => {
                                    console.info(err);
                                    reject(err);
                                });
                        }

                    } else {
                        this.currentNavigation = [];
                        reject([]);
                    }
                }).catch(err => {
                    // console.info(err);
                });
            } catch (err) {
                console.info(err);
                reject(err);
            }
        });
    }

    isGroupType(menuItem: any): boolean {
        return (menuItem && menuItem['navigation'] && !menuItem['navigation'].includes('.'));
    }

    getGroupData(menuItem: any) {
        return {
            'id': menuItem['type'],
            'title': menuItem['title'],
            'translate': this.getTranslatedTitle(menuItem),
            'type': 'group',
            'icon': (menuItem['icon']) ? menuItem['icon'] : '',
            'children': [],
            'sort': menuItem['weight'],
            'params': {}
        };
    }

    isSubGroupType(menuItem: any): boolean {
        return (menuItem && menuItem['navigation'] && (menuItem['navigation'].split('.').length >= 3 || menuItem['subGroup']));
    }

    getSubGroupData(menuItem: any): any {
        return {
            'id': menuItem['type'],
            'title': menuItem['title'],
            'translate': this.getTranslatedTitle(menuItem),
            'type': (menuItem['subGroupCollapse'] ? 'collapse' : 'group'),
            'sort': menuItem['weight'],
            'icon': menuItem['icon'] || '',
            'children': []
        };;
    }

    getMenuItemChildren(menuItem: any, params: any): any {
        menuItem = menuItem || {};
        return {
            'id': menuItem['type'],
            'title': menuItem['title'],
            'translate': this.getTranslatedTitle(menuItem),
            'type': 'item',
            'heading': menuItem['heading'],
            'headingDesc': menuItem['headingDesc'],
            'sort': menuItem['weight'],
            'icon': menuItem['icon'] || '',
            'url': (menuItem['fuse2Url']) ? (menuItem['fuse2Url'].replace(':officeId', this.officeId) + params) : ''
        };
    }

    refreshMenuItems() {
        try {
            setTimeout(() => {
                console.log("refreshMenuItems = ");
                this.fuseNavigationService.unregister('main');
                this.fuseNavigationService.register('main', []);
                this.fuseNavigationService.setCurrentNavigation('main');
                this.menuItems(this.getWorkspaceFilterData()).then((response) => {
                    this.fuseNavigationService.unregister('main');
                    this.fuseNavigationService.register('main', response);
                    this.fuseNavigationService.setCurrentNavigation('main');
                }).catch((error) => {
                    this.helperService.openSnackBar(error || 'Error occured while pulling menu');
                });
            }, 800);
        } catch (err) {
            console.info(err);
        }
    }

    async createWorkspaceMenu(orgSettings): Promise<any> {
        console.log(`CREATING WD MENU`)
        try {

            if (false && (!this.selectedOfficeObject.showWorkspace || this.selectedOfficeObject.showWorkspace === 'false')) {
                this.$workspaceConfig.next({ type: 'workspaceList', workspaceList: [] });
                return []
            } else {
                const workspaceMenuItems = {
                    'id': 'Workspaces',
                    'title': 'Obszar roboczy',
                    'translate': 'Obszar roboczy',
                    'type': 'group',
                    'children': [],
                    'sort': 0,
                    'params': {}
                };
                
                const ssMenuItems = {
                    'id': 'Workspaces',
                    'title': 'SS workspace',
                    'translate': 'SS workspace',
                    'type': 'group',
                    'children': [],
                    'sort': 0,
                    'params': {}
                };

                const functionItem = {
                    'id': 'add-workspace',
                    'title': 'Dodaj obszar roboczy',
                    'translate': 'Dodaj obszar roboczy',
                    'type': 'item',
                    'heading': '',
                    'headingDesc': '',
                    'sort': 0,
                    'icon': 'add',
                    'actionType': 'function',
                    'current': this,
                    'function': this.openWorkspace
                };
                const tempObj = {
                    'name': 'toolbar.dashboard',
                    'url': `/offices/dashboard`,
                    'icon': 'home'
                };
                const dashboardItem = {
                    'id': 'dashboard-workspace',
                    'title': 'toolbar.dashboard',
                    'name': 'toolbar.dashboard',
                    'translate': 'toolbar.dashboard',
                    'type': 'item',
                    'heading': '',
                    'headingDesc': '',
                    'classes': 'happ-nav-small-hide',
                    'sort': 0,
                    'icon': 'home',
                    'url': `/offices/${this.officeId}/dashboard`,
                    'function': () => { this.workspaceChange(tempObj); this._router.navigate([`/offices/${this.officeId}/dashboard`]); }
                };
                try {
                    workspaceMenuItems.children = workspaceMenuItems.children || [];
                    workspaceMenuItems.children.push(dashboardItem);
                    let data = await this.helperService.getRequest('api/profile/workspaces')
                    data = data || [];
                    if (orgSettings.allowFilter || (!orgSettings.allowFilter && orgSettings.workspaceIds)) {
                        data.forEach((workspace: any) => {
                            if (workspace && workspace.slug && !workspace.archive && workspace.type === "workspace") {
                                if (orgSettings.workspaceIds.includes(workspace.workspaceId) || (!orgSettings.allowFilter && orgSettings.workspaceIds)) {
                                    workspaceMenuItems.children.push({
                                        'id': workspace.workspaceId,
                                        'title': workspace.name,
                                        'translate': workspace.name,
                                        'type': 'item',
                                        'heading': '',
                                        'headingDesc': '',
                                        'slug': workspace.slug,
                                        'sort': workspace.sort,
                                        'icon': workspace.icon,
                                        'function': () => { this.workspaceChange(workspace); this._router.navigate([`/workspace/${workspace.slug}/dashboard`]); },
                                        'url': `/workspace/${workspace.slug}`
                                    });
                                }
                            }
                        });
    
                        data.forEach((workspace: any) => {
                            if (workspace && !workspace.archive && workspace.type === "ss") {
                                ssMenuItems.children.push({
                                    'id': new Date().getTime(),
                                    'title': workspace.name,
                                    'translate': workspace.name,
                                    'type': 'item',
                                    'heading': '',
                                    'headingDesc': '',
                                    'slug': workspace.slug,
                                    'sort': workspace.sort,
                                    'icon': workspace.icon,
                                    'function': () => { this.workspaceChange(workspace); this._router.navigate([workspace.route]); },
                                    'url': workspace.route
                                });
                            }
                        });
                    }
                        if (this.isAdmin) {
                            workspaceMenuItems.children.push(functionItem);
                        }
                        this.defaultWorkspaceConfig.workspaceList = (workspaceMenuItems && workspaceMenuItems.children) || [];
                        this.$workspaceConfig.next({ type: 'workspaceList', workspaceList: this.defaultWorkspaceConfig.workspaceList });

                        if (ssMenuItems && ssMenuItems.children.length) {
                            return([workspaceMenuItems, ssMenuItems]);
                        } else {
                            return([workspaceMenuItems]);
                        }
                    } catch (error) {
                        if (this.isAdmin) {
                            workspaceMenuItems.children.push(functionItem);
                        }
                        console.info(error);
                        if (ssMenuItems && ssMenuItems.children.length) {
                            return([workspaceMenuItems, ssMenuItems]);
                        } else {
                            return([workspaceMenuItems]);
                        }
                        
                }
            }

        } catch (err) {
            console.info(err);
            throw err;
        }
    }

    resetValue() {
        this.defaultWorkspaceConfig = {
            showWorkspace: true,
            workspaceList: [],
            selectedWorkspace: {
                'id': 'dashboard-workspace',
                'title': 'toolbar.dashboard',
                'name': 'toolbar.dashboard',
                'translate': 'toolbar.dashboard',
                'type': 'item',
                'heading': '',
                'headingDesc': '',
                'sort': 0,
                'icon': 'home',
                'url': `/offices/dashboard`
            }
        };
    }

    workspaceChange(workspace: any) {
        console.log("workspace change = ", workspace);
        this.defaultWorkspaceConfig.selectedWorkspace = workspace;
        this.$workspaceConfig.next({ type: 'workspace', selectedWorkspace: this.defaultWorkspaceConfig.selectedWorkspace});
    }

    openWorkspace(current: any) {
        const tt: any = this;
        const dialogRef = tt.current.dialog.open(CreateWorkspaceComponent, {
            width: '500px',
            height: 'max-content',
            panelClass: 'happ-form-builder-dialog'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                tt.current.refreshMenuItems();
            }
        });
    }

    getTranslatedTitle(ele: IMainMenuItem): string {
        return (ele && ele.translations && ele['translations'][this.langIndex[this.currentLang]] &&
            ele['translations'][this.langIndex[this.currentLang]][this.currentLang]) || ele.title || '';
    }

}


export const CLIENT_PANEL_NAV = [
    {
        'id'       : 'general',
        'title'    : 'toolbar.general',
        'translate': 'toolbar.general',
        'type'     : 'group',
        'children' : [
        {
            'id': '1',
            'title': 'toolbar.dashboard',
            'translate': 'toolbar.dashboard',
            'type': 'item',
            'heading': '',
            'headingDesc': '',
            'slug': 'dashboard',
            'sort': 0,
            'icon': 'home',
            'url': `/cp/dashboard`
        }
    ]
},
{
    'id'       : 'cases',
    'title'    : 'toolbar.cases',
    'translate': 'toolbar.cases',
    'type'     : 'group',
    'children' : [
        {
            'id': '15',
            'title': 'toolbar.active_cases',
            'translate': 'toolbar.active_cases',
            'type': 'item',
            'queryParams': { status: "active" },
            'heading': '',
            'headingDesc': '',
            'slug': 'active_cases',
            'sort': 15,
            'icon': 'cases',
            'url': `/cp/my-cases/personal/all`
        },

        {
            'id': '20',
            'title': 'toolbar.closed_cases',
            'translate': 'toolbar.closed_cases',
            'type': 'item',
            'heading': '',
            'queryParams': { status: "closed" },
            'headingDesc': '',
            'slug': 'closed_cases',
            'sort': 20,
            'icon': 'close',
            'url': `/cp/my-cases/personal/all`
        },
        {
            'id': '21',
            'title': 'toolbar.archived_cases',
            'translate': 'toolbar.archived_cases',
            'type': 'item',
            'queryParams': { status: "archive" },
            'heading': '',
            'headingDesc': '',
            'slug': 'archived_cases',
            'sort': 21,
            'icon': 'archive',
            'url': `/cp/my-cases/personal/all`
        },
        {
            'id': '24',
            'title': 'toolbar.new_cases',
            'translate': 'toolbar.new_cases',
            'type': 'item',
            'heading': '',
            'headingDesc': '',
            'slug': 'new_cases',
            'sort': 24,
            'icon': 'add_circle',
            'url': `/cp/services`
        },
    ]
},
{
    'id'       : 'financial',
    'title'    : 'toolbar.financial',
    'translate': 'toolbar.financial',
    'type'     : 'group',
    'children' : [
 {
    'id': '30',
    'title': 'toolbar.orders',
    'translate': 'toolbar.orders',
    'type': 'item',
    'heading': '',
    'headingDesc': '',
    'slug': 'orders',
    'sort': 30,
    'icon': 'description',
    'url': `/cp/orders`
 },
 {
    'id': '35',
    'title': 'toolbar.payments',
    'translate': 'toolbar.payments',
    'type': 'item',
    'heading': '',
    'headingDesc': '',
    'slug': 'payments',
    'sort': 35,
    'icon': 'payment',
    'url': `/cp/payments`
 },
 {
    'id': '47',
    'title': 'toolbar.invoices',
    'translate': 'toolbar.invoices',
    'type': 'item',
    'heading': '',
    'headingDesc': '',
    'slug': 'invoices',
    'sort': 47,
    'icon': 'description',
    'url': `/cp/invoices`
 },
]},
{
    'id'       : 'settings',
    'title'    : 'toolbar.settings',
    'translate': 'toolbar.settings',
    'type'     : 'group',
    'children' : [
        {
            'id': '58',
            'title': 'toolbar.user_profile',
            'translate': 'toolbar.user_profile',
            'type': 'item',
            'heading': '',
            'headingDesc': '',
            'slug': 'user_profile',
            'sort': 58,
            'icon': 'face',
            'url': `/cp/user/profile`
        }
    ]
}
]

export const CLIENT_PANEL_THEME = {
    isNotOffice: true,
    logout: () => {
    },
    layout: {
        navbar   : {
            hidden: false,
            folded: false
        },
        toolbar  : {
            hidden: true
        },
        footer   : {
            hidden: true
        },
        sidepanel: {
            hidden: true
        }
    }
};