import { NgModule } from '@angular/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { PositionComponent } from './position.component';
import { DatatableModule } from '../../datatable/datatable.module';
import { MaterialModule } from '@fuse/material.module';

@NgModule({
    imports     : [
       FuseSharedModule,
        MaterialModule,
        DatatableModule,
    ],
    declarations: [
        PositionComponent,
    ],
    exports: [
        PositionComponent,
    ],
    entryComponents: [
    ]
})
export class PositionModule
{
}
