import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-ss-event-form-config',
  templateUrl: './ss-event-form-config.component.html',
  styleUrls: ['./ss-event-form-config.component.scss']
})
export class SsEventFormConfigComponent implements OnInit {

  @Input() translateData: any;
  @Input() field;
  @Output() onFieldValueUpdated: EventEmitter<any> = new EventEmitter();
  @Input() builder;
  @Input() model: any; 
  @Output() modelChange: EventEmitter<any> = new EventEmitter();
  @Output() onError: EventEmitter<any> = new EventEmitter();
  
  data = {};

  registerForm = [
    {
      type: "input",
      title: "First Name",
      key: "firstName"
    },
    {
      type: "input",
      title: "Last Name",
      key: "lastName"
    },
    {
      type: "input",
      title: "Email",
      key: "email"
    },
    {
      type: "dropdown",
      title: "Gender",
      key: "gender",
      options: [
        {
          title: 'Man',
          key: 'man'
        },
        {
          title: 'Woman',
          key: 'woman'
        }
      ]
    },
    {
      type: "input",
      title: "Phone No",
      key: "phoneNumber"
    },
    {
      type: "dropdown",
      title: "Meals",
      key: "meals",
      options: [
        {
          title: 'Vegetarian',
          key: 'vegetarian'
        },
        {
          title: 'Vegan',
          key: 'vegan'
        },
        {
          title: 'No requirements',
          key: 'no_requirements'
        }
      ]
    },
    {
      type: "input",
      title: "Country",
      key: "country"
    },
    {
      type: "input",
      title: "City",
      key: "city"
    },
    {
      type: "input",
      title: "FB Profile",
      key: "fbProfile"
    }
  ];
  constructor() { }

  ngOnInit() {
    this.data = this.model || {};
    this.registerForm.forEach((ele) => {
      this.data[ele.key] = this.data[ele.key] || {};
    });
    console.log("all data - ", this.data);
    
  }

  onChange() {
    console.log("all data - ", this.data);
    this.model = this.data;
    this.modelChange.next(this.model);
  }
}
