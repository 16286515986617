import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { ProviderServiceSelectionComponent } from './provider-service-selection.component';
import { RecordCommentModule } from 'app/main/content/global-component/control-template/record-comment/record-comment.module';
import { ProcessStatusModule } from 'app/main/content/global-component/control-template/process-status/process-status.module';

@NgModule({
  declarations: [
      ProviderServiceSelectionComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FuseSharedModule,
    RecordCommentModule,
    ProcessStatusModule
  ],
  entryComponents: [
    
  ],
  exports: [
    ProviderServiceSelectionComponent
  ]
})
export class ProviderServiceSelectionModule { }
