import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { ListComponent } from './list.component';



@NgModule({
    declarations: [
      ListComponent
    ],
    imports     : [
       FuseSharedModule
    ],
    exports: [
      ListComponent
    ]
})

export class ListModule
{

}
