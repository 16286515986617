<div class="p-12 mb-20">
  <table>
    <tr>
      <td *ngFor="let item of columns" [style.width]="item.width" [style.textAlign]="item.textAlign">
        <ng-container [ngSwitch]="item.key">
          <ng-container *ngSwitchCase="'action'">
            <p>
              {{ 'ct.action' | translate }}
            </p>
          </ng-container>
          <ng-container *ngSwitchDefault>
              <p *ngFor="let ele of item">
                {{ ele.title | translate }}
              </p>
            </ng-container>
          </ng-container>

      </td>
    </tr>
    <tr *ngFor="let item of data | fullTextSearch:query:['name']">
      <td *ngFor="let col of columns" [style.width]="item.width">
        <ng-container [ngSwitch]="col.key">
          <ng-container *ngSwitchCase="'action'">
            <button mat-raised-button (click)="editForm(item)" >
              {{ 'ct.edit' | translate }}
            </button>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <p *ngFor="let ele of col">
              {{ item[ele.key] }}
            </p>
          </ng-container>
        </ng-container>
      </td>
    </tr>
  </table>
</div>