import { Component, OnInit, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { LanguagesInterface } from 'app/main/content/interfaces/languages.interface';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { SetDefaultLanguageService } from 'app/main/content/services/set-default-language/set-default-language.service';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { AuthService } from '../auth.service';
import { LanguagesModel } from 'app/main/content/models/languages.model';
import { MaintenanceComponent } from '../../maintenance/maintenance.component';
import { md5 } from '../md5';
import { environment } from 'environments/environment';
import { VersionCheckService } from 'app/version-check.service';
import { SocketService } from 'app/main/content/services/socket/socket.service';
import { GoogleLoginProvider } from "angularx-social-login";
import { AuthService as SocialAuthService } from "angularx-social-login";
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';


const ALL_SIDE_HIDE_LAYOUT = {
    layout: {
        navbar: {
            hidden: true
        },
        toolbar: {
            hidden: true
        },
        footer: {
            hidden: true
        },
        sidepanel: {
            hidden: true
        }
    }
};

const SHOW_TOOLBAR = {
    layout: {
        navbar: {
            hidden: true
        },
        toolbar: {
            hidden: false
        },
        footer: {
            hidden: true
        },
        sidepanel: {
            hidden: true
        }
    }
};

@Component({
    selector: 'login-2',
    templateUrl: './login-2.component.html',
    styleUrls: ['./login-2.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class Login2Component implements OnInit, OnDestroy {

    COMPKEY = 'authentication';
    version = '';
    queryParams: any = {};
    loginForm: FormGroup;
    loginFormErrors: any;
    selectedLanguage: any;
    selectedLangCode: any;
    errMess: String = '';
    loader = false;
    storagePrefix;
    hostName;
    
    languages: Array<LanguagesInterface>;
    status: string;
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private authService: AuthService,
        private route: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef,
        private translate: TranslateService,
        private translationLoader: FuseTranslationLoaderService,
        public dialog: MatDialog,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private socialAuthService: SocialAuthService,
        private setDefaultLanguageService: SetDefaultLanguageService,
        private helperService: HelpersService,
        public versionCheckService: VersionCheckService,
        private socketService: SocketService
    ) {
        const queryParams = this.route.snapshot.queryParams || {};
        if (queryParams && queryParams.redirectUrl) {
            if (queryParams.redirectUrl === "/invitation/list") {
                window.localStorage.clear();
            }
            window.localStorage.setItem('redirectUrl', queryParams.redirectUrl);
        }
        this.storagePrefix = environment.storagePrefix;
        this.hostName = window.location.hostname;
        console.log(this.route);

        console.log("config changing = 22");

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                style    : 'vertical-layout-1',
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this.loginFormErrors = {
            email: {},
            password: {}
        };

        this.version = environment.version;
        this.languages = LanguagesModel;
        
        this.selectedLanguage = this.languages[0];
        this.selectedLangCode = this.selectedLanguage['id'];
        if (localStorage.getItem('selectedLanguage')) {
            this.languages.forEach((lang, i) => {
                if (lang.id === localStorage.getItem('selectedLanguage')) {
                    this.selectedLanguage = lang;
                    this.selectedLangCode = this.selectedLanguage['id'];
                }
            });
        }
    }

    ngOnInit(): void {
        this.setDefaultLanguageService.setDefaultLanguage();

        this.helperService.getDefaultLangs()
        .then(lang => {
            this.languages = lang || [];
            this.selectedLangCode = this.translate.currentLang;
        }).catch(err => {
            console.info(err);
        });

        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });

        this.loginForm.valueChanges.subscribe(
            () => {
                this.onLoginFormValuesChanged();
            },
            err => {
                console.info(err);
            }
        );
        this.helperService.getTrans(this.COMPKEY)
        .then(data2 => {
            this.translationLoader.loadTranslationsV2(data2);
        }).catch(err => {
            console.info(err);
        });
    }

    ngOnDestroy(): void {
        this.loader = false;
    }

    login(isGoogle?: any): any {
        if (!this.loginForm.valid) {
            return this.helperService.showInfo("All Fields are required", "error", "error");
        }
        this.loader = true;
        this.status = "progress";

        let user = {
            login: this.loginForm.get('email').value,
            password: md5(this.loginForm.get('password').value),
            appType: 'ng5'
        };
        if (isGoogle) {
            user = isGoogle
        }
        this._fuseSplashScreenService.show();
        this.authService.logIn(user)
        .then((status: string) => {

            if (status) {
                this.status = "success";
                let token: any = '';
                if (window.localStorage.getItem('ngStorage-token')) {
                    try {
                        const temp = window.localStorage.getItem('ngStorage-token');
                        token = JSON.parse(temp);
                        token = token.token;
                    } catch (error) {
                        token = '';
                    }
                }
                this.socketService.initSocket(token);

                this.loader = false;
                this.helperService.showInfo('info_and_errors.logged_in_success_redirecting_to_dashboard', 'info_and_errors.login_success');
                console.log("config changing = 22");
                setTimeout(() => {
                    this._fuseSplashScreenService.hide();
                }, 2000);
                // window.location.reload();
                if (environment.production) {
                    this.versionCheckService.checkVersion(environment.versionCheckURL, true);
                }
            } else {
                this.errMess = 'auth.email_or_password_incorrect';
                this.loader = false;
                this._fuseSplashScreenService.hide();
                this.helperService.showInfo('info_and_errors.email_or_password_incorrect', 'info_and_errors.login_error', 'error');
            }
            })
            .catch(error => {
                console.log(error);
                this.loader = false;
                this._fuseSplashScreenService.hide();
                // this._fuseSplashScreenService.show();
                if (error['status'] === 0) {
                    this.dialog.open(MaintenanceComponent, {
                        width: '100%',
                        height: '100%',
                        panelClass: 'happ-form-builder-dialog',
                        disableClose: true,
                        data: { error }
                    });
                } else {
                    console.log(error);
                    this.errMess = (error && error.message) || (error && error.error && error.error.txt) || error || 'Some error occured.';
                }
            });
    }

    onLoginFormValuesChanged(): void {
        for (const field in this.loginFormErrors) {
            if (!this.loginFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.loginFormErrors[field] = {};

            // Get the control
            const control = this.loginForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.loginFormErrors[field] = control.errors;
            }
        }
    }

    setLanguage(lang: any): void {
        this.languages.map(ele => {
            if (lang === ele.id) {
                this.selectedLanguage = ele;
            }
        });

        localStorage.setItem('selectedLanguage', this.selectedLanguage.id);
        this.selectedLangCode = this.selectedLanguage.id;
        this.translate.setDefaultLang(this.selectedLanguage.id);
        this.translate.use(localStorage.getItem('selectedLanguage') || this.translate.getBrowserLang() || 'en');
    }

    loginGoogle() {
        this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID)
        .then((data) => {
            this.login(data);
            console.log(data);
        }).catch(err => {
            console.error(err);
        });
    }

}
