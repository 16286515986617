<div *ngIf="!isNotPopup" style="background: #f6f6f6;" class="m-0 p-12">
  <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <button class="mr-12" (click)="dialogRef.close(null)" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
    <span class="title dialog-title"> {{ 'global.copy_workspace_app_config' | translate }} </span>
    <span fxFlex></span>
  </div>
</div>
<div class="py-32 px-12 pb-0" style="min-height: 300px;" fxLayout="row">
  <div fxFlex="50" style="overflow: auto; border-right: 1px solid gray;" fxLayoutAlign="start center" fxLayout="column">
    <h2>Workspace List</h2>
    <ng-container *ngFor="let workspace of workspaceList">
      <button class="m-8 w-50-p" mat-raised-button [color]="(selectedWorkspace && selectedWorkspace.id === workspace.id) ? 'primary' : ''" (click)="selectWorkspace(workspace)"  >
        {{ workspace.title | translate }}
      </button>
    </ng-container>
  </div>
  <div fxFlex="50" style="overflow: auto;" fxLayoutAlign="start center" fxLayout="column">
    <ng-container *ngIf="workspaceApps && workspaceApps.length; else emptySideBar">
      <h2>Workspace apps</h2>
      <ng-container *ngFor="let workspaceApp of workspaceApps">
        <button [color]="(selectedWorkspaceApp && selectedWorkspaceApp.stepNumber === workspaceApp.stepNumber) ? 'primary' : ''" class="m-8 w-50-p" mat-raised-button (click)="selectApp(workspaceApp)"  >
          {{ workspaceApp.stepLabel | translate }}
        </button>
      </ng-container>
    </ng-container>
    <ng-template #emptySideBar fxLayout="center center">
      <h2 style="margin-top: 50%;">{{ 'workspace.select_some_workspace' | translate }}</h2>
    </ng-template>
  </div>
</div>