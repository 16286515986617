/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tab-settings.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./tab-settings.component";
import * as i3 from "../section-settings/section-settings.component.ngfactory";
import * as i4 from "../section-settings/section-settings.component";
import * as i5 from "../field-settings/field-settings.component.ngfactory";
import * as i6 from "../field-settings/field-settings.component";
import * as i7 from "../../../services/helpers/helpers.service";
import * as i8 from "@angular/material/dialog";
import * as i9 from "../../../state/workspaceState/workspace.store";
import * as i10 from "@angular/router";
import * as i11 from "@angular/common";
import * as i12 from "../../../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i13 from "@angular/material/tabs";
import * as i14 from "@ngx-translate/core";
var styles_TabSettingsComponent = [i0.styles];
var RenderType_TabSettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TabSettingsComponent, data: {} });
export { RenderType_TabSettingsComponent as RenderType_TabSettingsComponent };
function View_TabSettingsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tab-settings", [], null, null, null, View_TabSettingsComponent_0, RenderType_TabSettingsComponent)), i1.ɵdid(1, 114688, null, 0, i2.TabSettingsComponent, [], { settings: [0, "settings"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TabSettingsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-section-settings", [], null, null, null, i3.View_SectionSettingsComponent_0, i3.RenderType_SectionSettingsComponent)), i1.ɵdid(1, 114688, null, 0, i4.SectionSettingsComponent, [], { settings: [0, "settings"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TabSettingsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-field-settings", [], null, null, null, i5.View_FieldSettingsComponent_0, i5.RenderType_FieldSettingsComponent)), i1.ɵdid(1, 114688, null, 0, i6.FieldSettingsComponent, [i7.HelpersService, i8.MatDialog, i9.WorkspaceStore, i1.ChangeDetectorRef, i10.Router], { settings: [0, "settings"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TabSettingsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, null, null, null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i11.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabSettingsComponent_4)), i1.ɵdid(4, 278528, null, 0, i11.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i11.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabSettingsComponent_5)), i1.ɵdid(6, 278528, null, 0, i11.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i11.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabSettingsComponent_6)), i1.ɵdid(8, 278528, null, 0, i11.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i11.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.type; _ck(_v, 2, 0, currVal_0); var currVal_1 = "tab"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "section"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "field"; _ck(_v, 8, 0, currVal_3); }, null); }
function View_TabSettingsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 6, "mat-tab", [], null, null, null, i12.View_MatTab_0, i12.RenderType_MatTab)), i1.ɵdid(1, 770048, [[1, 4]], 2, i13.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(335544320, 2, { templateLabel: 0 }), i1.ɵqud(335544320, 3, { _explicitContent: 0 }), i1.ɵpid(131072, i14.TranslatePipe, [i14.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_TabSettingsComponent_3)), i1.ɵdid(6, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 4).transform(_v.context.$implicit.title)); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.children; _ck(_v, 6, 0, currVal_1); }, null); }
function View_TabSettingsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "mat-tab-group", [["class", "mat-tab-group"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], null, null, i12.View_MatTabGroup_0, i12.RenderType_MatTabGroup)), i1.ɵdid(2, 3325952, null, 1, i13.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i13.MAT_TABS_CONFIG]], null, null), i1.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabSettingsComponent_2)), i1.ɵdid(5, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.settings.settings; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).dynamicHeight; var currVal_1 = (i1.ɵnov(_v, 2).headerPosition === "below"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_TabSettingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabSettingsComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TabSettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tab-settings", [], null, null, null, View_TabSettingsComponent_0, RenderType_TabSettingsComponent)), i1.ɵdid(1, 114688, null, 0, i2.TabSettingsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TabSettingsComponentNgFactory = i1.ɵccf("app-tab-settings", i2.TabSettingsComponent, View_TabSettingsComponent_Host_0, { settings: "settings" }, {}, []);
export { TabSettingsComponentNgFactory as TabSettingsComponentNgFactory };
