import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-datatable-super-filter',
  templateUrl: './datatable-super-filter.component.html',
  styleUrls: ['./datatable-super-filter.component.scss']
})
export class DatatableSuperFilterComponent implements OnInit {

  @Input() superFilter: Array<any>; 
  @Input() valueFilter: any; 
  @Output() valueFilterChange: EventEmitter<any> = new EventEmitter();
  
  constructor() { }

  ngOnInit() {
  }
  
  superFilterButtonClicked(filterOption, buttonInfo, empty) {
    this.valueFilter[filterOption['label']] = this.valueFilter[filterOption['label']] || {};

    if (this.valueFilter && this.valueFilter[filterOption['label']] && filterOption['label']) {
      const tt = (buttonInfo.value || buttonInfo.title);
      this.valueFilter[filterOption['label']].$in = this.valueFilter[filterOption['label']].$in || [];

      // for empty filter
      if (empty && this.valueFilter && this.valueFilter[filterOption['label']] && this.valueFilter[filterOption['label']].$in && 
      !this.valueFilter[filterOption['label']].$in.includes(null)) {
        this.valueFilter[filterOption['label']].$in.push(null);
        this.valueFilter[filterOption['label']].$in.push(undefined);
        this.valueFilter[filterOption['label']].$in.push(0);
        this.valueFilter[filterOption['label']].$in.push('');
      } else if (empty && this.valueFilter && this.valueFilter[filterOption['label']] && this.valueFilter[filterOption['label']].$in) {
        let index = this.valueFilter[filterOption['label']].$in.indexOf(null);
        if (index >= 0) {
          this.valueFilter[filterOption['label']].$in.splice(index, 1);
        } 
        index = this.valueFilter[filterOption['label']].$in.indexOf(undefined);
        if (index >= 0) {
          this.valueFilter[filterOption['label']].$in.splice(index, 1);
        } 
        index = this.valueFilter[filterOption['label']].$in.indexOf(0);
        if (index >= 0) {
          this.valueFilter[filterOption['label']].$in.splice(index, 1);
        } 
        index = this.valueFilter[filterOption['label']].$in.indexOf('');
        if (index >= 0) {
          this.valueFilter[filterOption['label']].$in.splice(index, 1);
        } 

        if (this.valueFilter[filterOption['label']].$in && this.valueFilter[filterOption['label']].$in.length === 0) {
          delete this.valueFilter[filterOption['label']];
        }
      }


      if (this.valueFilter && this.valueFilter[filterOption['label']] && this.valueFilter[filterOption['label']].$in && 
          !this.valueFilter[filterOption['label']].$in.includes(tt)) {
            this.valueFilter[filterOption['label']].$in.push(tt);
      } else if (this.valueFilter && this.valueFilter[filterOption['label']] && this.valueFilter[filterOption['label']].$in) {
        const index = this.valueFilter[filterOption['label']].$in.indexOf(tt);
        if (index >= 0) {
          this.valueFilter[filterOption['label']].$in.splice(index, 1);
          if (this.valueFilter[filterOption['label']].$in && this.valueFilter[filterOption['label']].$in.length === 0) {
            delete this.valueFilter[filterOption['label']];
          }
        } 
      }
      // this.getServerSideRecord();
    }
    this.valueFilterChange.next(this.valueFilter);    
  }
}
