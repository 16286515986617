import { Injectable } from '@angular/core';
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';
import * as _ from 'lodash';



@Injectable()
export class ActionService {
  private userGroups: object = {};
  private cachedAccessLevel: any;
  private selectedOffice: object;
  private loggedInUser: object;
  public currentLang: string;
  public actionTmpl: any;

  btn_classes: object = {
    recordActions: 'md-raised md-button ng-scope md-default-theme',
    headerActions: 'md-white-bg ',
    subheaderActions: 'md-white-bg'
  };


  constructor(private translate: TranslateService) {

  }

  public getColumnInit = function(){
    this.userGroups = {};
    this.currentLang = this.translate.currentLang || 'en';
    this.selectedOffice = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'));
    this.loggedInUser = JSON.parse(localStorage.getItem('ngStorage-token'));

    if (this.selectedOffice &&  this.loggedInUser && this.loggedInUser['profile'] && this.loggedInUser['profile']['login']) {
      let user = this.selectedOffice['users'].filter( (e) => {
        return this.loggedInUser['profile']['login'] === e.email;
      });
      let groups = (user[0] && user[0].groups) || [];
      groups.forEach((group) => {
        if (group) {
          this.userGroups[group.name] = true;
        }
      });
    }
  };

  public getClass = function(placement){
    let btn = '';
    placement.forEach((val, key) => {
      if (val){
        btn = this.btn_classes[key];
      }
    });
  };

  getActionButton (action, lng) {
    let lang = lng || 'en' ; // || $translate.use();
    let label =  action.default_label; //action.translations[lang] || action.default_label;
    let buttonHtml = '';
    let params = action.params;
    if (action.postBookUser){
      params.showUser = action.postBookUser;
    }
    //let href = $state.href(action.application, params, {absolute: true});
    if (action.access_level <= this.cachedAccessLevel ) {
      buttonHtml = 'show button'; //'<md-button class="' + self.getClass(action.placement) + '" href=" '+ href + '">' + label + '</md-button>'
      return buttonHtml;
    }
    return buttonHtml;
  }


  getActionsByModuleAndPlacement(actions, module, placement) {
    return actions.filter((action) => {
      return action.placement[placement] && action.module === module;
    }).sort((a, b) => {
      return a.sort - b.sort;
    });
  }

  getActionsByPlacement(actions, placement) {
    return actions.filter(function (action) {
      return action.placement[placement];
    }).sort(function (a, b) {
      return a.sort - b.sort;
    });
  }

  cacheAccessLevel(groups) {
    this.cachedAccessLevel = this.getAccessLevel(groups);
  }

  getDataAccess(groups) {
    let accessLevel;
    let userAllowedGroups: any = (groups || []).filter((group) => {
      return this.userGroups[group.name];
    });

    userAllowedGroups.forEach((group) => {
      if (accessLevel !== 'allrecords') {
        accessLevel = group.show;
      } else {
        accessLevel = 'allrecords';
      }
    });
    return accessLevel || 'allrecords';
  }


  getAccessLevel(groups) {
    let mapLevel = {
      No: 0,
      allrecords: 1,
      Yes: 2,
      DeleteFull: 3
    };

    let accessLevel = 0;

    // if(groups)
    let userAllowedGroups = groups.filter((group) => {
      return this.userGroups[group.name];
    });

    userAllowedGroups.forEach((group) => {
      if (mapLevel[group.access] > accessLevel) {
        accessLevel = mapLevel[group.access];
      }
    });
    return accessLevel;
  }


  wrappedDelete(params){
    return this.deleteSettings.bind(null, params);
  }


  deleteSettings(params){
    alert(1);
  }




  getDTColumn (groups, actions, type, paramNames, scope , routeParams) {
    // if (scope){
    //   scope.wrappedDelete = this.wrappedDelete;
    // }


    this.getColumnInit();
    // this is done to have option button also
    const filteredActions = this.getActionsByModuleAndPlacement(actions, type, 'recordActions')
                              .concat(this.getActionsByModuleAndPlacement(actions, type, 'dropdownActions'));
    const accessLevel = this.getAccessLevel(groups);
    let buttons = [];
    buttons = filteredActions.filter((action) => {
      if (action.access_level > accessLevel) {
        return [];
      }else{
        if (action.icon){
          action.icon =  action.icon.replace('icon', 'mdi');
        }
        return action;
      }
    });

    const column = {
      prop: '_id',
      name: 'Actions',
      class: 'action-txt-align',
      sortable: false,
      render: buttons
    };
    return column;
  }
 
  getRegisterListButtons(groups: Array<object>, actions: Array<object>, type: string): Array<object> {
    this.getColumnInit();
    const filteredActions = this.getActionsByModuleAndPlacement(actions, type, 'recordActions');
    const accessLevel = this.getAccessLevel(groups);
    let buttons = [];
    buttons = filteredActions.filter((action) => (action.access_level > accessLevel) ? false : true );
    return buttons;
  }

}




