<div class="pb-12" *ngIf="!field.hide || builder">
  <!-- <div (click)="editMode=!editMode" >
      <mat-form-field class="happ-small-form-field-outline w-100-p" floatLabel="always" appearance="outline" fxFlex>
          <mat-label> {{ field.title | translate }} </mat-label>
          <input type="text" matInput [disabled]="true" [(ngModel)]="model">
      </mat-form-field>
  </div> -->
  <div class="happ-list-field" [class]="field.class">
    <div class="happ-list happ-content" *ngIf="!loading" fxLayout="row">
      <mat-form-field color="accent" fxFlex floatLabel="always" appearance="outline" class="happ-small-form-field-outline">
        <mat-label>{{ field.title | translate }}</mat-label>
        <input  [(ngModel)]="model" #temp (blur)="recheck()" (keyup)="inputValueChanged($event, temp.value)"
          [disabled]="field.showdisablecontroltype || field.disable" type="text" matInput [matAutocomplete]="auto">
        <mat-autocomplete (optionSelected)="onChange($event)" #auto="matAutocomplete">
          <mat-option *ngFor="let option of filteredOptions" [value]="option && (option.value || option.title)">
            {{(option && (option.title || option.value)) | translate }} </mat-option>
        </mat-autocomplete>
        <mat-icon class="mr-8" matSuffix>search</mat-icon>
      </mat-form-field>
      <div class="w-40" *ngIf="field.showEdit && !field.userRegister">
        <button (click)="editOptions()"
          [matTooltip]="'ct.edit_options' | translate" mat-icon-button>
          <mat-icon>settings</mat-icon>
        </button>
      </div>
    </div>
    <div class="error-message" *ngIf="errorMessage"> 
      {{ errorMessage | translate }} 
      <div fxLayoutAlign="center center" *ngIf="loading">
        <mat-spinner></mat-spinner>
      </div>
    </div>
    <div class="happ-desc happ-field-description" *ngIf="field.allow_description && field.description">
      {{ field.description | translate }} </div>
  </div>
</div>
