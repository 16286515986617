<mat-toolbar matDialogTitle fxLayoutAlign="center center" class="call-header-one mat-toolbar mat-accent m-0" fxLayout="row">
  <!-- <div style="background: #f6f6f6;" class="m-0 p-8" *ngIf="data"> -->
    <div fxFlex fxLayout="header" fxLayoutAlign="space-between center">
      <span class="title dialog-title">
        <p class="m-0 font-size-20 ml-12"> 
          {{ 'popup.call_history' | translate }}
        </p>
      </span>
      <span fxFlex></span>
      <button class="mr-12" (click)="close()" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-toolbar>

  <!-- <div class="w-100-p h-400" fxLayoutAlign="center center" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div> -->
<div fxLayout="column"  >
  <div class="ml-8 mt-8 mb-8 pb-12" style="border-bottom: 1px solid gray;" fxLayout="row">
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-form-field floatLabel="always" appearance="outline" class="happ-small-form-field-outline happ-dt-table-filter-input">
        <mat-label> {{ 'popup.search' | translate }} </mat-label>
        <input matInput aria-label="record_fast_search_input" (keyup.enter)="filter(query)" [(ngModel)]="query">
      </mat-form-field>
      <button aria-label="record_fast_search_button" style="margin: auto 2px;" class="ml-8" matTooltip="search"
        (click)="filter(query)" mat-icon-button>
        <mat-icon style="color: black;">search</mat-icon>
      </button>
    </div>
    <span fxFlex></span>
    <div style="margin: auto;">
      <button class="mr-8"  (click)="toggleType('sharedOffice')" mat-raised-button>
        <mat-icon class="mb-4" *ngIf="(requestData && requestData.type === 'sharedOffice')">radio_button_checked</mat-icon> 
        <mat-icon class="mb-4" *ngIf="(requestData && requestData.type !== 'sharedOffice')">radio_button_unchecked</mat-icon> 
        {{ 'popup.shared_office' | translate }} 
      </button>
      <button class="mr-8" (click)="toggleType('myOffice')" mat-raised-button>
        <mat-icon class="mb-4" *ngIf="(requestData && requestData.type === 'myOffice')">radio_button_checked</mat-icon> 
        <mat-icon class="mb-4" *ngIf="(requestData && requestData.type !== 'myOffice')">radio_button_unchecked</mat-icon> 
        {{ 'popup.my_office' | translate }}
      </button>
    </div>
  </div>
  <div class="m-20">
    <mat-card>

      <ngx-datatable [messages]="{emptyMessage: 'Brak danych', totalMessage: 'TOTAL'}" [@animateStagger]="{value:'50'}"
        class="bootstrap" [rows]="rows" [loadingIndicator]="loadingIndicator" [columns]="requestData.columns"
        [columnMode]="'flex'" [headerHeight]="50" [externalPaging]="true" [footerHeight]="50" [rowHeight]="'auto'"
        [count]="requestData.page.totalElements" [offset]="requestData.page.pageNumber" (sort)="onSort($event)"
        [limit]="requestData.page.size" (page)='setPage($event)'>
        <ngx-datatable-column *ngFor="let col of requestData.columns; let colIndex=index" fxFlex [prop]="col.prop"
          [name]="col.title" [flexGrow]="col.flexGrow" >
          <!-- Header Template Starts -->
          <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
            <div class="ml-4" (click)="sort();" style="display: inline-block; position: absolute; top: 13px;"
              *ngIf="column.name != 'Actions'">{{ column.name | translate}}</div>
            <div class="mr-8 w-100-p" style="position: absolute; right: 25px; display: inline-block;"
              *ngIf="column.name == 'Actions'">
              <span fxFlex></span>
              <p>{{ 'popup.action' | translate }}</p>
            </div>
          </ng-template>
          <!-- Header Template Ends -->
          <!-- Row and cell Template Starts -->
          <ng-template let-value="value" class="happ-dummy" let-rowIndex="rowIndex" let-row="row" style="color: blue;"
            ngx-datatable-cell-template>
            <div class="ml-4" *ngIf="col.prop === 'reportData'">
              <button *ngIf="value" (click)="openDescriptionPopup(value)" mat-icon-button>
                      <mat-icon>info</mat-icon>
                  </button>
            </div>
            <div class="ml-4" *ngIf="col.prop !== 'reportData' && col.prop !== '_id'">
              <p>
                {{ value || '--' }}
              </p>
            </div>
            <div class="p-4 mr-8 w-100-p" *ngIf="col.prop === '_id'">
              <span fxFlex></span>
              <!-- <button class=mr-8 color="warn" mat-raised-button (click)="deleteTemplate(row)"> Delete </button> -->
              <button class=mr-8 mat-raised-button (click)="edit(row, rowIndex)"> {{ 'popup.edit' | translate }} </button>
            </div>
          </ng-template>
          <!-- Row and cell Template Ends -->
        </ngx-datatable-column>
      </ngx-datatable>
    </mat-card>
  </div>
</div>