/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./room-view-link.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/flex-layout/flex";
import * as i6 from "@angular/flex-layout/core";
import * as i7 from "./room-view-link.component";
var styles_RoomViewLinkComponent = [i0.styles];
var RenderType_RoomViewLinkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RoomViewLinkComponent, data: {} });
export { RenderType_RoomViewLinkComponent as RenderType_RoomViewLinkComponent };
function View_RoomViewLinkComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "p-12"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.closeDialog() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 1), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 2, 0, ("/cp/room/client/" + _co.recData._id)); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.field.title)); _ck(_v, 3, 0, currVal_3); }); }
function View_RoomViewLinkComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RoomViewLinkComponent_4)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.recData && _co.recData._id); _ck(_v, 2, 0, currVal_0); }, null); }
function View_RoomViewLinkComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "p-12"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.closeDialog() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 1), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 2, 0, ("/cp/room/provider/" + _co.recData._id)); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.field.title)); _ck(_v, 3, 0, currVal_3); }); }
function View_RoomViewLinkComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RoomViewLinkComponent_6)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.recData && _co.recData._id); _ck(_v, 2, 0, currVal_0); }, null); }
function View_RoomViewLinkComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i3.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RoomViewLinkComponent_3)), i1.ɵdid(4, 278528, null, 0, i3.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i3.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RoomViewLinkComponent_5)), i1.ɵdid(6, 278528, null, 0, i3.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i3.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.field.linkType; _ck(_v, 2, 0, currVal_0); var currVal_1 = "client"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "provider"; _ck(_v, 6, 0, currVal_2); }, null); }
function View_RoomViewLinkComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("ct.select_room_type_in_settings")); _ck(_v, 2, 0, currVal_0); }); }
function View_RoomViewLinkComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "mb-12"], ["fxLayout", "column"], ["style", "background: aliceblue; border: 1px solid lightgray; border-radius: 5px;position: relative;"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i5.DefaultLayoutDirective, [i1.ElementRef, i6.StyleUtils, [2, i5.LayoutStyleBuilder], i6.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RoomViewLinkComponent_2)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RoomViewLinkComponent_7)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.field.linkType; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.field.linkType; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_RoomViewLinkComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RoomViewLinkComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.field.hide || _co.builder); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RoomViewLinkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-room-view-link", [], null, null, null, View_RoomViewLinkComponent_0, RenderType_RoomViewLinkComponent)), i1.ɵdid(1, 114688, null, 0, i7.RoomViewLinkComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RoomViewLinkComponentNgFactory = i1.ɵccf("app-room-view-link", i7.RoomViewLinkComponent, View_RoomViewLinkComponent_Host_0, { recData: "recData", regId: "regId", field: "field", tempService: "tempService", translateData: "translateData" }, {}, []);
export { RoomViewLinkComponentNgFactory as RoomViewLinkComponentNgFactory };
