import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';

@Component({
  selector: 'app-mailing-list-loading-popup',
  templateUrl: './mailing-list-loading-popup.component.html',
  styleUrls: ['./mailing-list-loading-popup.component.scss']
})
export class MailingListLoadingPopupComponent implements OnInit {
  loading: boolean;

  constructor(
    public dialogRef: MatDialogRef<MailingListLoadingPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private helperService: HelpersService,
  ) { }

  ngOnInit() {
    console.log("item to execute")
    this.saveData();
  }

  async saveData() {
    try {
      this.loading = true;
      await this.helperService.postRequest(`api/reg-execute/${this.data.sourceRegId}/${this.data.recId}?actionId=${this.data.action.actionId}`, this.data);
      this.loading = false;
      this.dialogRef.close();
    } catch (error) {
      this.loading = false;
      this.dialogRef.close();
      alert(error);
    }
  }

}
