<div class="dialog-content-wrapper">
  <mat-toolbar style="background: #f6f6f6;" matDialogTitle class="m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title"> Build App </span>
      <span fxFlex></span>
      <button (click)="dialogRef.close(null)" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <div class="p-24" style="overflow: auto;" fxLayout="column">
    <div *ngIf="!loading && currentStep === 0">
      <div *ngIf="isEdit">
        <h2>App List Based on current register:</h2>
        <mat-nav-list>
          <a style="margin: 10px 0px;" (click)="pluginSelected(item)" *ngFor="let item of pluginList"
            mat-list-item>
            <div fxFlex="33">
              {{ item['name'] }} - version {{ item.activeVersion }}
            </div>
          </a>
        </mat-nav-list>
      </div>
      <div *ngIf="!isEdit">
        <div class="happ-field-right-align" style="width: 96%; margin: auto;">
          <p> Alias Name </p>
          <input [(ngModel)]="step1Data['alias_name']" placeholder="Enter here..." type="text">
        </div>
        <!-- select register -->
        <div>
          <div fxLayout="row" class="mr-8" fxLayoutAlign="start center">
            <label for="search" class="mr-8">
              <mat-icon>search</mat-icon>
            </label>
            <mat-form-field floatLabel="always" appearance="outline" floatLabel="always">
                <mat-label>Search</mat-label>
              <input matInput placeholder="Search" [(ngModel)]="query">
            </mat-form-field>
          </div>
          <mat-nav-list>
            <a style="margin: 10px 0px;" (click)="registerSelected(list._id)" *ngFor="let list of regList | fullTextSearch:query:['name']; let i=index"
              mat-list-item>
              <div fxFlex="33">
                {{ list['name'] }}
              </div>
            </a>
          </mat-nav-list>
        </div>
      </div>
    </div>
    <div fxLayout="column" *ngIf="!loading && currentStep === 1">
      <div>
        <h3 class="happ-ws-settings-heading">
          General
        </h3>
        <p style="width: 96%; margin: auto; margin-bottom: 10px; font-weight: 700;" *ngIf="isEdit"> Current Version - {{ step2Data.activeVersion }} </p>
        <div class="happ-field-right-align" style="width: 96%; margin: auto;">
          <p>App Name</p>
          <input [disabled]="isEdit" [(ngModel)]="step2Data.name" placeholder="Enter here..." type="text">
        </div>
        <div class="happ-field-right-align" style="width: 96%; margin: auto;">
          <p>smallDesc</p>
          <input [disabled]="isEdit" [(ngModel)]="step2Data.smallDesc" placeholder="Enter here..." type="text">
        </div>
        <div class="happ-field-right-align" style="width: 96%; margin: auto;">
          <p>description</p>
          <input [disabled]="isEdit" [(ngModel)]="step2Data.description" placeholder="Enter here..." type="text">
        </div>
        <div class="happ-field-right-align" style="width: 96%; margin: auto;">
          <p>businessFunction</p>
          <input [disabled]="isEdit" [(ngModel)]="step2Data.businessFunction" placeholder="Enter here..." type="text">
        </div>
        <div class="happ-field-right-align" style="width: 96%; margin: auto;">
          <p>appSector</p>
          <input [disabled]="isEdit" [(ngModel)]="step2Data.appSector" placeholder="Enter here..." type="text">
        </div>

        <div *ngIf="isEdit" class="happ-field-right-align" style="width: 96%; margin: auto;">
          <p style="margin: auto 0px;">Version</p>
            <div fxLayout="row wrap">
              <button class="mx-4" mat-raised-button [color]="(step2Data.versionType === 'big')? 'primary' : ''" (click)="step2Data.versionType='big'" >
                Big
              </button>
              <button class="mx-4" mat-raised-button [color]="(step2Data.versionType === 'medium')? 'primary' : ''" (click)="step2Data.versionType='medium'" >
                Medium
              </button>
              <button class="mx-4" mat-raised-button [color]="(step2Data.versionType === 'small')? 'primary' : ''" (click)="step2Data.versionType='small'" >
                Small
              </button>
            </div>
        </div>
        <div *ngIf="isEdit" class="happ-field-right-align" style="width: 96%; margin: auto;">
          <p style="margin: auto 0px;">Is beta</p>
            <div fxLayout="row wrap">
              <button class="mx-4" mat-raised-button [color]="(step2Data.isBeta === true)? 'primary' : ''" (click)="step2Data.isBeta=true" >
                Yes
              </button>
              <button class="mx-4" mat-raised-button [color]="(step2Data.isBeta === false)? 'primary' : ''" (click)="step2Data.isBeta=false" >
                No
              </button>
            </div>
        </div>
        <div  class="happ-field-right-align" style="width: 96%; margin: auto;">
          <p style="margin: auto 0px;">Is Published</p>
            <div fxLayout="row wrap">
              <button class="mx-4" mat-raised-button [color]="(step2Data.published === true)? 'primary' : ''" (click)="step2Data.published=true" >
                Yes
              </button>
              <button class="mx-4" mat-raised-button [color]="(step2Data.published === false)? 'primary' : ''" (click)="step2Data.published=false" >
                No
              </button>
            </div>
        </div>
        <div  class="happ-field-right-align" style="width: 96%; margin: auto;">
          <p style="margin: auto 0px;">Language</p>
            <div fxLayout="row wrap">
              <mat-form-field class="w-200">
                <mat-label>Language</mat-label>
                <mat-select [(ngModel)]="packageData.lang">
                  <mat-option *ngFor="let lang of languages" [value]="lang.flag">
                    {{lang.title}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
        </div>
        <h2 *ngIf="isEdit">Change log:</h2>
        <ck-editor name="editor1" *ngIf="isEdit"  [(ngModel)]="step2Data.changeLog" skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>

      </div>
      <div>
        <div class="happ-ws-settings-heading" fxLayout="row">
          <h3 class="happ-ws-settings-heading m-0">
            App Data(step)
          </h3>
          <span fxFlex></span>
          <button *ngIf="!(data.extraData.createNew || data.extraData.updateTemplate)" (click)="addAppData()" mat-icon-button>
            <mat-icon aria-label="Example icon-button with a heart icon">add_circle</mat-icon>
          </button>
        </div>
        <table *ngIf="data.extraData.createNew || data.extraData.updateTemplate">
          <thead>
            <tr>
              <th>
                {{ 'appBuilder.name' | translate }}
              </th>
              <th style="text-align: right;">
                {{ 'appBuilder.icon' | translate }}
              </th>
              <th style="text-align: right;">
                {{ 'appBuilder.include' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of includingAppsList">
              <td>
                {{ row.stepLabel }}
              </td>
              <td style="text-align: right;">
                <mat-icon>{{  row.icon}}</mat-icon>
              </td>
              <td style="text-align: right;">
                <mat-slide-toggle [checked]="selectedAppsList.includes(row.stepNumber)" (change)="toggleChange(row)" ></mat-slide-toggle>
              </td>
            </tr>
          </tbody>
        </table>
        <br>
        <ng-container *ngIf="!(data.extraData.createNew || data.extraData.updateTemplate)">
          <div fxLayout="column" *ngFor="let appData of step2Data.appData; let i=index">
            
            <div>
              <button (click)="removeAppData(i)" color="warn" mat-raised-button> Remove app {{ i+1 }} </button>
            </div>
  
            <div class="happ-field-right-align" style="width: 96%; margin: auto;">
              <p>App Name</p>
              <input [(ngModel)]="appData.stepLabel" placeholder="Enter here..." type="text">
            </div>
            <div class="happ-field-right-align" style="width: 96%; margin: auto;" fxLayout="row" class="h-60">
              <p style="font-weight: 700; width: 36%; margin: auto 0px;" >Icon</p>
              <div matTooltip="Click to edit" style="cursor: pointer;" (click)="selectIcon(i)">
                <mat-icon class="font-size-30"> {{ appData.icon }} </mat-icon>
              </div>
            </div>
            <div style="width: 96%; margin: auto;" class="happ-field-right-align">
              <p>Select register for app: </p>
              <div fxLayout="row wrap">
                <button *ngFor="let reg of regAliases" (click)="appData.regId = (reg.aliasName + '_register')" [color]="((reg.aliasName + '_register') === appData.regId) ? 'primary' : ''" mat-raised-button> {{ reg.name }} </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngFor="let dep of dependencies">
        <div *ngIf="dep.list && dep.list.length">
          <h3 class="happ-ws-settings-heading">
            {{ dep.name }}
          </h3>
          <div>
            <div *ngFor="let field of dep.list" class="happ-field-right-align" style="width: 96%; margin: auto;">
              <p>{{ field.name }} ( {{ field.id }} )</p>
              <input [(ngModel)]="field.aliasName" placeholder="Enter here..." type="text">
            </div>
          </div>
        </div>
      </div>
      <span fxFlex></span>
      <div class="p-24 pt-20 pb-20">
        <p class="font-size-14" style="color: red;" *ngIf="error"> {{ error }} </p>
        <span fxFlex></span>
        <button (click)="selectedApp=null;error=''" mat-button class="mr-4">{{ 'workspace.cancel' | translate }}</button>
        <button mat-raised-button (click)="createFromOfficeApp()" color="primary">{{ 'workspace.create_app' | translate
          }}</button>
      </div>
    </div>
    <div fxLayoutAlign="center center" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>