import * as tslib_1 from "tslib";
import { Store, StoreConfig } from '@datorama/akita';
import * as i0 from "@angular/core";
function getOfficeDoc() {
    try {
        return JSON.parse(localStorage.getItem('ngStorage-selectedOffice'));
    }
    catch (err) {
        throw err;
    }
}
export function createInitialState() {
    try {
        return tslib_1.__assign({}, getOfficeDoc());
    }
    catch (error) {
        return {};
    }
}
var OfficeStore = /** @class */ (function (_super) {
    tslib_1.__extends(OfficeStore, _super);
    function OfficeStore() {
        return _super.call(this, createInitialState()) || this;
    }
    OfficeStore.ngInjectableDef = i0.defineInjectable({ factory: function OfficeStore_Factory() { return new OfficeStore(); }, token: OfficeStore, providedIn: "root" });
    OfficeStore = tslib_1.__decorate([
        StoreConfig({ name: 'office', resettable: true }),
        tslib_1.__metadata("design:paramtypes", [])
    ], OfficeStore);
    return OfficeStore;
}(Store));
export { OfficeStore };
