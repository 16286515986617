import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { GlobalModule } from '@fuse/global.module';
import { SelectIconComponent } from './select-icon.component';

@NgModule({
    imports     : [
       FuseSharedModule,
        GlobalModule,
    ],
    declarations: [
        SelectIconComponent
    ],
    providers   : [
    ],
    exports: [
        SelectIconComponent
    ],
    entryComponents: [
        SelectIconComponent
    ]
})
export class SelectIconModule
{
}
