import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SetDefaultLanguageService {

  constructor(
    private translationService: TranslateService
  ) { }

  setDefaultLanguage() {
    localStorage.setItem('selectedLanguage', (this.translationService.getDefaultLang() || 'en'));
  }

}
