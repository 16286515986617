import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectFormPopupComponent } from './select-form-popup.component';
import { MaterialModule } from '@fuse/material.module';
import { FormListModule } from '../../global-component/form-list/form-list.module';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
  declarations: [SelectFormPopupComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    FormListModule,
  ],
  entryComponents: [
    SelectFormPopupComponent
  ]
})
export class SelectFormPopupModule { }
