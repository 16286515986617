import { Injectable } from '@angular/core';
import { HelpersService } from '../helpers/helpers.service';
import { SocketService } from './socket.service';

@Injectable()
export class UserSocketService {
    constructor(
        private socketService: SocketService,
        private heleprService: HelpersService
    ) {
        this.socketService.onSocketEventTriggered.subscribe((data) => {
            console.log("userEvent", data);
            if (data && (data.eventType === 'userEvents' || data.eventType === 'personalNotification')) {
                this.onEvent(data.res);
                if (data && data.res && data.res.message) {
                    this.heleprService.showInfo(data.res.message, data.res.title, "info");
                }
            }
        });
     }

    onEvent(res: any) {
        console.log('inside the user event', res);
    }
}
