<div>
  <mat-toolbar style="background: #f6f6f6;" matDialogTitle class="m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <div *ngIf="data.triggerUpdate">
        <button (click)="closePopup()" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <h2>{{ 'workspace.apps_settings' | translate }}</h2>
      <span fxFlex></span>
      <button *ngIf="data.triggerUpdate" (click)="saveData()" mat-icon-button>
        <mat-icon>check</mat-icon>
      </button>
      <button *ngIf="!data.triggerUpdate" (click)="closePopup()" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <div>
    <app-json-settings [jsonSettings]="settingsModel" ></app-json-settings>
  </div>
</div>