import { Pipe, PipeTransform } from '@angular/core';
import { FuseUtils } from '@fuse/utils';

@Pipe({name: 'filter',
pure: false
})
export class FilterPipe implements PipeTransform
{
    /**
     * Transform
     *
     * @param {any[]} mainArr
     * @param {string} searchText
     * @param {string} property
     * @returns {any}
     */
    transform(mainArr: any[] = [], searchText: any, property: string, checkNull = false): any
    {
        if (typeof(searchText) === 'boolean') {
            if (checkNull) {
                return mainArr.filter(ele => (ele && !ele[property]));
            }
            return mainArr.filter(ele => (ele && ele[property] === searchText));
        } else {
            return FuseUtils.filterArrayByString(mainArr, searchText);
        }
    }
}
