export const CustomRegisterModel = {
    'regAccess': 'READ',
    'regSubTypes': 'customRegister',
    'formTemplate': 'multi-col-tpl',
    'actions': [
        {
        'default_label': 'edit',
        'translations': {
            'pl': 'Edytuj',
            'en': 'edit'
        },
        'sort': 1,
        'placement': {
            'headerActions': false,
            'subheaderActions': false,
            'recordActions': true
        },
        'module': 'customRegisterRecordsList',
        'application': 'app.oneRecordView.edit',
        'params': {},
        'access_level': '2'
        },
        {
        'default_label': 'browse',
        'translations': {
            'pl': 'Przegladaj',
            'en': 'browse'
        },
        'sort': 2,
        'placement': {
            'headerActions': false,
            'subheaderActions': false,
            'recordActions': true
        },
        'module': 'customRegistries',
        'application': 'app.customregistries.registriesrecordslistnew',
        'params': {},
        'access_level': '2'
        },
        {
        'default_label': 'insert',
        'translations': {
            'pl': 'Dodaj',
            'en': 'insert'
        },
        'sort': 3,
        'placement': {
            'headerActions': true,
            'subheaderActions': false,
            'recordActions': false
        },
        'module': 'customRegisterRecordsList',
        'application': 'app.oneRecordView.add',
        'params': {},
        'access_level': '2'
        }
    ],
    'fields': [],
    'treeStrucutre': [],
    'ng5FormId': '',
    'listsParameter': {
        'orderBy': 'creatorLogin',
        'ascdesc': 'asc',
        'actionsDef': 'predefined'
    },
    'officeId': '',
    'groupAccess': [],
    'dataGroupsAccess': [],
    'type': 'correspondence-register',
    'name': '',
    'description': '',
    'reg_symbol': '',
    'reg_signature_pattern': '',
    'reg_turnOnCounter': 'yes',
    'reg_reset': '',
    'reg_sign1': '',
    'reg_sign2': '',
    'reg_sign3': '',
    'reg_sign4': '',
    'reg_sign5': ''
};
