import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatAutocompleteSelectedEvent } from '@angular/material';

@Component({
  selector: 'app-electronic-sender-country',
  templateUrl: './electronic-sender-country.component.html',
  styleUrls: ['./electronic-sender-country.component.scss']
})
export class ElectronicSenderCountryComponent implements OnInit {
  @Input() field;
  @Input() model: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();

  countries: any = []; 
  filteredList;
  inputValue: any;
  filterValue: any;
  constCountryList: any = [];
  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private helperService: HelpersService
  ) { }

  ngOnInit() {
    this.model = this.model || '';
    this.countries = this.model.split(',') || [];
    this.countries = this.countries.filter(ele => ele);
    this.helperService.getTrans('control-template')
    .then((data2) => {
      this.translationLoader.loadTranslationsV2(data2);
    }).catch(err => {
      console.info(err);
    });

    this.helperService.getRequest(`api/electronic-sender/countries`)
    .then((countries) => {
      // this.countries = countries || [];
      this.constCountryList = countries || [];
      this.filteredList = countries || [];
    }).catch( (err) => {
      console.info(err);
    });

  }

  optionSelected() {
    this.onKeyPress();
  }

  onKeyPress() {
    this.modelChange.next(this.model);
  }

  changed(value) {
    this.filteredList = this.countries.filter(ele => ele.toLowerCase().indexOf(value) === 0);
  }

  
  addCountry(event: MatAutocompleteSelectedEvent, data): void
  {
      // const input = event.input;
      const value = (event && event.option && event.option.value);


      // Add tag
      if ( value )
      {
        if (!this.countries.includes(value)) {
          this.countries.push(value);
          // this.onValueChange.emit(this.countries);
          this.countries = this.countries || [];
          this.model = this.countries.toString();
          this.modelChange.next(this.model);
          this.inputValue = '';
        }
      }

      if (this.inputValue) {
        this.inputValue = '';
      }

  }

  removeUser(tag: any)
  {
      const index = this.countries.indexOf(tag);

      if ( index >= 0 )
      {
          this.countries.splice(index, 1);
          // this.model = this.users;
          // this.modelChange.next(this.model);
          // this.onValueChange.emit(this.users);
      }
  }

  inputValueChanged(temp, val) {
    if (temp.key === ',' || temp.key === ' ') {
      if (val) {
        val = val.substr(0, (val.length - 1));
      }
      this.addCountry(temp, val);
    }
    this.filterValue = this._filterStates(val); 
  }
  



  private _filterStates(value: string) {
    const filterValue = (value && value.toLowerCase());
    return this.constCountryList.filter(state => state.toLowerCase().indexOf(filterValue) === 0);
  }
}
