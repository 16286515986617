<div  style="background: #f6f6f6;" class="m-0 p-8">
  <div fxFlex fxLayout="header" fxLayoutAlign="space-between center">
    
    <span class="title dialog-title">
      <p>{{ 'ct.company_detail' | translate }} 
      </p>
    </span>
    <span fxFlex></span>
    <button class="mr-12" (click)="dialogRef.close(null)" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div class="container" id="tableOne" style="overflow: auto; height: calc(100vh - 80px);">
  <div class="" tabindex="29">
    <div class="tableHeaderAK " tabindex="31">
      <h4>Figuruje w rejestrze VAT</h4>
    </div>
  </div>
  <table class="tableAK" tabindex="32" id="akmf-name">
    <tbody>
      <tr>
        <td class="twofirst">Firma (nazwa) lub imię i nazwisko</td>
        <td class="twosecond" style="font-weight:normal">'EASY ACCESS TECHNOLOGY' SPÓŁKA Z OGRANICZONĄ
          ODPOWIEDZIALNOŚCIĄ</td>
      </tr>
    </tbody>
  </table>
  <table class="tableAK" tabindex="33" id="akmf-nip">
    <tbody>
      <tr>
        <td class="twofirst">Numer, za pomocą którego podmiot został zidentyfikowany na potrzeby podatku, jeżeli taki
          numer został przyznany</td>
        <td class="twosecond" style="font-weight:normal">2040000177</td>
      </tr>
    </tbody>
  </table>
  <table class="tableAK" tabindex="35" id="akmf-statusVat">
    <tbody>
      <tr>
        <td class="twofirst">Status podatnika (wg stanu na dzień sprawdzenia 28-03-2022)</td>
        <td class="twosecond">Czynny</td>
      </tr>
    </tbody>
  </table>
  <table class="tableAK" tabindex="36" id="akmf-regon">
    <tbody>
      <tr>
        <td class="twofirst">Numer identyfikacyjny REGON, o ile został nadany</td>
        <td class="twosecond" style="font-weight:normal">193000020</td>
      </tr>
    </tbody>
  </table>
  <table class="tableAK" tabindex="37" id="akmf-krs">
    <tbody>
      <tr>
        <td class="twofirst">Numer w Krajowym Rejestrze Sądowym, o ile został nadany</td>
        <td class="twosecond" style="font-weight:normal">0000198814</td>
      </tr>
    </tbody>
  </table>
  <table class="tableAK" tabindex="38" id="akmf-workingAddress">
    <tbody>
      <tr>
        <td class="twofirst">Adres siedziby – w przypadku podmiotu niebędącego osobą fizyczną</td>
        <td class="twosecond">HENRYKA HUBERTUSA JABŁOŃSKIEGO 18/4, 80-706 GDAŃSK</td>
      </tr>
    </tbody>
  </table>
  <table class="tableAK" tabindex="39" id="akmf-residenceAddress">
    <tbody>
      <tr>
        <td class="twofirst">Adres stałego miejsca prowadzenia działalności albo adres miejsca zamieszkania, w przypadku
          braku adresu stałego miejsca prowadzenia działalności - w odniesieniu do osoby fizycznej </td>
        <td class="twosecond">-</td>
      </tr>
    </tbody>
  </table>
  <table class="tableAK" tabindex="40" id="akmf-authorizedClerks">
    <tbody>
      <tr>
        <td class="twofirst">Imiona i nazwiska prokurentów oraz ich numery identyfikacji podatkowej </td>
        <td class="twosecond">
          <div class="rowFullSize">-</div>
        </td>
      </tr>
    </tbody>
  </table>
  <table class="tableAK" tabindex="41" id="akmf-representatives">
    <tbody>
      <tr>
        <td class="twofirst">Imiona i nazwiska osób wchodzących w skład organu uprawnionego do reprezentowania podmiotu
          oraz ich numery identyfikacji podatkowej </td>
        <td class="twosecond">
          <div class="rowFullSize">-</div>
        </td>
      </tr>
    </tbody>
  </table>
  <table class="tableAK" tabindex="42" id="akmf-partners">
    <tbody>
      <tr>
        <td class="twofirst">Imię i nazwisko lub firma (nazwa) wspólnika oraz jego numer identyfikacji podatkowej </td>
        <td class="twosecond">
          <div class="rowFullSize"> - </div>
        </td>
      </tr>
    </tbody>
  </table>
  <table class="tableAK" tabindex="43" id="akmf-accountNumbers">
    <tbody>
      <tr>
        <td class="twofirst count">Numery rachunków rozliczeniowych lub imiennych rachunków w SKOK</td>
        <td class="twosecond count">
          <div style="font-weight:bold" id="akmf-residenceAddress-row-0">91 1090 1098 0000 0001 0227 4058</div>
        </td>
      </tr>
    </tbody>
  </table>
  <table class="tableAK" tabindex="45" id="akmf-registrationLegalDate">
    <tbody>
      <tr>
        <td class="twofirst">
          <div><span style="display:block;">Data rejestracji jako podatnika VAT</span><span
              style="display:block;"></span></div>
        </td>
        <td class="twosecond">
          <div><span style="display:block;">2004-03-19</span><span style="display:block;"></span></div>
        </td>
      </tr>
    </tbody>
  </table>
  <table class="tableAK" tabindex="46" id="akmf-registrationDenialDate">
    <tbody>
      <tr>
        <td class="twofirst">
          <div><span style="display:block;">Data odmowy rejestracji jako podatnika VAT</span><span
              style="display:block;">Podstawa prawna odmowy rejestracji</span></div>
        </td>
        <td class="twosecond">
          <div class="rtext"><span style="display:block;">-</span><span style="display:block;">-</span></div>
        </td>
      </tr>
    </tbody>
  </table>
  <table class="tableAK" tabindex="47" id="akmf-removalDate">
    <tbody>
      <tr>
        <td class="twofirst">
          <div><span style="display:block;">Data wykreślenia rejestracji jako podatnika VAT</span><span
              style="display:block;">Podstawa prawna wykreślenia</span></div>
        </td>
        <td class="twosecond">
          <div class="rtext"><span style="display:block;">-</span><span style="display:block;">-</span></div>
        </td>
      </tr>
    </tbody>
  </table>
  <table class="tableAK" tabindex="48" id="akmf-restorationDate">
    <tbody>
      <tr>
        <td class="twofirst">
          <div><span style="display:block;">Data przywrócenia rejestracji jako podatnika VAT</span><span
              style="display:block;">Podstawa prawna przywrócenia</span></div>
        </td>
        <td class="twosecond">
          <div class="rtext"><span style="display:block;">-</span><span style="display:block;">-</span></div>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="container boxSearch">
    <div class="row">
      <div class="col">
        <div class="searchFooterAK toLeftBox" tabindex="49">Identyfikator wyszukiwania: Xbp92-8f0dk51</div>
      </div>
      <div class="col">
        <div class="toRightBox">
          <div style="display:inline; line-height:42px; padding-right:10px;"><button tabindex="50"
              title="Drukuj potwierdzenie" style="margin-top:-3px;" type="button" id="superPrintButton"
              class="btn btn-emptyAk printButtonLoader"> Drukuj potwierdzenie </button></div>
        </div>
      </div>
    </div>
  </div>
</div>