import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { MailingFooterComponent } from '../mailing-footer/mailing-footer.component';
var MailingFooterListComponent = /** @class */ (function () {
    function MailingFooterListComponent(helperService, dialog, dialogRef, data) {
        this.helperService = helperService;
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.data = data;
    }
    MailingFooterListComponent.prototype.ngOnInit = function () {
        this.getFooterList();
    };
    MailingFooterListComponent.prototype.getFooterList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.helperService.getTemplateList("?type=footer")];
                    case 1:
                        _a.footerList = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MailingFooterListComponent.prototype.addOrEdit = function (i) {
        var _this = this;
        var temp = this.dialog.open(MailingFooterComponent, {
            disableClose: true,
            width: '100%',
            height: '100%',
            panelClass: 'happ-form-builder-dialog',
            data: this.footerList[i] || {}
        });
        temp.afterClosed()
            .subscribe(function (response) {
            if (response) {
                _this.getFooterList();
            }
        });
    };
    MailingFooterListComponent.prototype.close = function () {
        this.dialogRef.close();
    };
    return MailingFooterListComponent;
}());
export { MailingFooterListComponent };
