import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';

@Component({
  selector: 'app-dt-file-list-popup',
  templateUrl: './dt-file-list-popup.component.html',
  styleUrls: ['./dt-file-list-popup.component.scss']
})
export class DtFileListPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DtFileListPopupComponent>,
    private helperService: HelpersService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit() {
  }

  downloadPdf(docId) {
    this.helperService
      .getDocument(docId)
      .then(docData => {
        if (docData) {
          this.helperService.downloadDocument(docData);
        }
      })
      .catch(err => {
      });
  }

}
