import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var SsEventFormAddPopupComponent = /** @class */ (function () {
    function SsEventFormAddPopupComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.formData = {};
        this.formData = this.data.data || {};
    }
    SsEventFormAddPopupComponent.prototype.ngOnInit = function () {
        console.log("data - ", this.data);
    };
    SsEventFormAddPopupComponent.prototype.save = function () {
        this.dialogRef.close(tslib_1.__assign({}, this.formData));
    };
    SsEventFormAddPopupComponent.prototype.delete = function () {
        this.dialogRef.close({
            operation: "delete"
        });
    };
    return SsEventFormAddPopupComponent;
}());
export { SsEventFormAddPopupComponent };
