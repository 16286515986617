import { branch } from './branch';
export const environment = {
    branch: branch,
    version: 'v4.1.18',
    production: true,
    G_MAP_API: "AIzaSyBFsPmLE2vm5ZD48xN7ZWws6MP8SHjM3Ug",
    hmr       : false,
    // serverUrl: 'https://dev-release-p24back.unicloud.pl/'    
    serverUrl: '{{BACKEND_SERVER_URL}}',
    external_page_url: "https://v41-stage-external.dev.eat.pl/",
    przelewy24Url: 'https://sandbox.przelewy24.pl',
    versionCheckURL : 'version.json',
    payUUrl: 'http://localhost:3005/',
    storagePrefix: 'https://storage.googleapis.com/pinbox24-ocr-test/platform_images/default',
    PROVIDER_SERVICE_REGISTRATION_REG_ID: '5fe76fbc6147421e880fa771',
    ADMIN_CLIENT_REG_ID: '5fefe8071838d523f8ff8676',
    ADMIN_P24_REG_ID: '5ff46942868aab001a5f191e',
    ADMIN_SERVICES_REG_ID: '60011e5a423c5a002bbea3ef',
    GLOBAL_OFFICE_ID: "5cb9648308e32961acf1ff1f",
    ORDER_SETTINGS_INFO: {
        regId: "60275aac404f1e37a4c245b4",
        officeId: "5cb9648308e32961acf1ff1f"
    }
};
