import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, AfterViewInit, ChangeDetectorRef, OnChanges } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FieldOptionUpdateDialogComponent } from 'app/main/content/components/field-option-update-dialog/field-option-update-dialog.component';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
var ButtonListWithTagComponent = /** @class */ (function () {
    function ButtonListWithTagComponent(snackBar, translationLoader, dialog, _helperService, changeDetectorRef) {
        this.snackBar = snackBar;
        this.translationLoader = translationLoader;
        this.dialog = dialog;
        this._helperService = _helperService;
        this.changeDetectorRef = changeDetectorRef;
        // tslint:disable-next-line:no-output-on-prefix
        this.onFieldValueUpdated = new EventEmitter();
        this.modelChange = new EventEmitter();
        this.valChange = new EventEmitter();
        // tslint:disable-next-line:no-output-on-prefix
        this.onError = new EventEmitter();
        this.error = false;
        this.loading = false;
        this.errorMessage = '';
        this.errorMes = false;
        this.inputValue = "abc";
        this.items = [];
        this.model = {};
    }
    ButtonListWithTagComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.translateData && changes.translateData.currentValue) {
            this.translateData = changes.translateData.currentValue;
            this.translationLoader.loadTranslationsV2(this.translateData);
        }
    };
    ButtonListWithTagComponent.prototype.editOptions = function () {
        var _this = this;
        var dialogRef = this.dialog.open(FieldOptionUpdateDialogComponent, {
            width: '850px',
            disableClose: true,
            height: 'max-content',
            data: {
                fieldLabel: this.field.label,
                formData: this.formData,
                id: this.field.id, fieldOptions: JSON.parse(JSON.stringify(this.field.options || [])) || [], field: this.field
            },
            panelClass: 'happ-form-builder-dialog'
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.field.options = result;
            }
        });
    };
    ButtonListWithTagComponent.prototype.getOptions = function () {
        var _this = this;
        if (this.field.regId && this.field.optionTitle) {
            var cols = [
                {
                    prop: this.field.optionTitle,
                    title: this.field.optionTitle
                },
                {
                    prop: '_id',
                    title: '_id'
                }
            ];
            if (this.field.optionValue) {
                cols.push(this.field.optionValue);
            }
            this.loading = true;
            this._helperService.getDataForOptionBasedField(this.field.regId, cols, this.field.hardFilter, this.field.sortBy)
                .then(function (data) {
                var tt = [];
                data = data || [];
                data.forEach(function (ele) {
                    if (_this.field.optionValue) {
                        tt.push({
                            title: ele[_this.field.optionTitle],
                            value: ele[_this.field.optionValue]
                        });
                    }
                    else {
                        tt.push({
                            title: ele[_this.field.optionTitle]
                        });
                    }
                });
                _this.field.options = tt || [];
                _this.loading = false;
                _this.changeDetectorRef.detectChanges();
            }).catch(function (err) {
                console.info(err);
                _this.loading = false;
                _this.changeDetectorRef.detectChanges();
            });
        }
    };
    ButtonListWithTagComponent.prototype.onChange = function (name) {
        this.errorMes = false;
        if (name) {
            this.modelButton[name] = !this.modelButton[name];
            if (this.model) {
                var tempArr = this.model.split(',').map(function (ele) { return ele.trim(); }) || [];
                if (tempArr.includes(name)) {
                    this.errorMes = true;
                }
                else {
                    this.model += ', ' + name;
                }
            }
            else {
                this.model = name;
            }
        }
        this.valChange.emit({
            modelButton: this.modelButton
        });
        // this.modelButton = this.modelButton || {};
        // this.model = '';
        // Object.keys(this.modelButton).forEach(ele => {
        //   if (this.modelButton[ele]) {
        //     this.model += ele + ', ' ;
        //   }
        // });
        this.modelChange.next(this.model);
        if (this.field['required'] && (this.modelButton === null || this.modelButton === undefined || this.modelButton === '')) {
            this.error = true;
            this.errorMessage = 'ct.field_is_required';
            if (!this.modelButton) {
                this.onError.emit({
                    error: true,
                    label: this.field['label']
                });
            }
        }
        else {
            this.errorMessage = '';
            this.error = false;
            this.onError.emit({
                error: false,
                label: this.field['label']
            });
        }
        this.onFieldValueUpdated.emit({
            label: this.field.label,
            value: this.model,
            ruleIndex: this.field.ruleIndex,
            field: this.field
        });
    };
    ButtonListWithTagComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        var temp = {};
        this.field['options'].forEach(function (element) {
            temp[element.value || element.title] = false;
        });
        this.modelButton = (this.modelButton) ? this.modelButton : temp;
        if (this.field['preselectedValue'] && this.modelButton && this.modelButton.length === 0) {
            this.modelButton = this.field['preselectedValue'];
            this.onChange(null);
        }
        if (this.model && this.model.length) {
            var tempArr = this.model.split(',') || [];
            tempArr.forEach(function (ele) {
                if (ele) {
                    ele = ele.trim();
                    _this.modelButton[ele] = true;
                }
            });
        }
        else {
            this.model = '';
        }
    };
    ButtonListWithTagComponent.prototype.ngOnInit = function () {
        if (this.field.userRegister) {
            this.getOptions();
        }
        this.getOfficeTags();
        this.model = this.model || '';
        this.items = this.model.split(",").filter(function (ele) { return ele; });
        this.translationLoader.loadTranslationsV2(this.translateData);
        if (this.field['required'] && (this.modelButton === null || this.modelButton === undefined || this.modelButton === '')) {
            this.error = true;
            this.errorMessage = 'ct.field_is_required';
            if (!this.modelButton) {
                this.onError.emit({
                    error: true,
                    label: this.field['label']
                });
            }
        }
    };
    ButtonListWithTagComponent.prototype.onKeyPress = function () {
        this.errorMes = false;
        if (this.field['required'] && (!this.model || this.model && this.model.length === 0)) {
            this.errorMessage = 'ct.field_is_required';
            this.error = true;
            this.onError.emit({
                error: true,
                label: this.field['label']
            });
        }
        else {
            this.errorMessage = '';
            this.error = false;
            this.onError.emit({
                error: false,
                label: this.field['label']
            });
        }
        this.modelChange.next(this.model);
    };
    ButtonListWithTagComponent.prototype.inputValueChanged = function (temp, val) {
        if (temp.key === ',' || temp.key === ' ') {
            if (val) {
                val = val.substr(0, (val.length - 1));
            }
            this.addItem(temp, val);
        }
        this.filterValue = this._filterStates(val);
    };
    ButtonListWithTagComponent.prototype.addItem = function (eve, val) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        val = (eve && eve.option && eve.option.value) || val || '';
                        if (this.items.includes(val) || !val.trim()) {
                            return [2 /*return*/];
                        }
                        this.items.push(val);
                        this.model = this.items.join(",");
                        this.modelChange.next(this.model);
                        this.inputValue = '';
                        console.log("removing the data");
                        return [4 /*yield*/, this._helperService.postRequest("api/record-tag", { key: this.field.label, tag: val, operation: "add", regId: this.regId, updateKey: this.field.label, updateValue: this.model, recId: this.recId })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ButtonListWithTagComponent.prototype.removeItem = function (ele) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.items.includes(ele)) return [3 /*break*/, 2];
                        this.items.splice(this.items.indexOf(ele), 1);
                        this.model = this.items.join(",");
                        this.modelChange.next(this.model);
                        return [4 /*yield*/, this._helperService.postRequest("api/record-tag", { key: this.field.label, tag: ele, operation: "delete", regId: this.regId, updateKey: this.field.label, updateValue: this.model, recId: this.recId })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ButtonListWithTagComponent.prototype._filterStates = function (value) {
        var filterValue = (value && value.toLowerCase());
        return this.officeTags.filter(function (state) { return state.tag.toLowerCase().indexOf(filterValue) === 0; });
    };
    ButtonListWithTagComponent.prototype.getOfficeTags = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, error_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this;
                        return [4 /*yield*/, this._helperService.getRequest("api/record-tag?key=" + this.field.label)];
                    case 1:
                        _a.officeTags = _b.sent();
                        this.filterValue = JSON.parse(JSON.stringify(this.officeTags));
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _b.sent();
                        console.log("Err = ", error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return ButtonListWithTagComponent;
}());
export { ButtonListWithTagComponent };
