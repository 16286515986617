
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar, MatDialog } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FileRetryViewComponent } from '../file-upload/file-retry-view/file-retry-view.component';

@Component({
  selector: 'app-file-upload-key',
  templateUrl: './file-upload-key.component.html',
  styleUrls: ['./file-upload-key.component.scss']
})
export class FileUploadKeyComponent implements OnInit, OnDestroy, OnChanges {
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() recId: string;
  @Input() parentId: string;
  @Input() regId: string;
  @Input() model: any;
  @Input() translateData: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Output() recordCreated: EventEmitter<Object> = new EventEmitter();
   
  officeId;
  loading = false;
  getLoading = false;
  getError = '';
  creatorId;
  fileName;
  fileDoc;
  creatorLogin;
  fileList;
  selectedFile;
  docBase;
  fileId;
  fileType;
  retry: any = 1;
  finalError = false;
  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    public snackBar: MatSnackBar,
    private helperService: HelpersService,
    private translationLoader: FuseTranslationLoaderService,
  ) { }

  
  ngOnChanges(changes: any) {

    if (changes && changes.translateData && changes.translateData.currentValue) {
      this.translateData = changes.translateData.currentValue;
      this.translationLoader.loadTranslationsV2(this.translateData);    
    }
  }

  ngOnDestroy() {
    this.selectedFile = '';
  }

  ngOnInit() {
    this.translationLoader.loadTranslationsV2(this.translateData);

    this.selectedFile = {};
    // this.parentId = (this.parentId === 'new') ? null : this.parentId;

    const office = (localStorage.getItem('ngStorage-selectedOffice')) ? JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) : null;
    if (office) {
      this.officeId = office['_id'];
    }

    const profile = (localStorage.getItem('ngStorage-profile')) ? JSON.parse(localStorage.getItem('ngStorage-profile')) : null;


    if (profile) {
      this.creatorId = profile['profileId'];
      this.creatorLogin = profile['email'];
    }

    if (this.model) {
      this.openPreview(this.model['id']);
    }
  }

  
  updateData() {
    const temp = {};
    temp[this.field.label] = this.model;
    this.helperService.updateRegisterRecord(this.regId, this.parentId || this.recId, temp)
    .then((data) => {
    }).catch(err => {
      console.info(err);
    });
  }

  createNewRec() {
    return new Promise((resolve, reject) => {
      if ((!this.recId || this.recId === 'new') && !this.parentId) {
        // create new record
        const temp = {};
        temp[this.field.label] = this.model;
        this.helperService.createRegisterRecord(this.regId, temp)
        .then((data) => { 
          this.recId = data._id;
          this.recordCreated.emit(data);
          resolve(null);
        }).catch(reject);
      } else {
        this.updateData();
      }
    });
  }

  uploadFile(file) {
    console.log(this.field);
    if (this.field.uploadBase64) {
      const files = file.files[0];
      const reader = new FileReader();
      reader.readAsBinaryString(files);
      reader.onload = () => {
          // this.picture = reader.result as string;
          this.model = { name: `${Date.now()}.jpg`, base64: `data:image/png;base64,${btoa(reader.result as any)}`  || '', type: this.field.type, fileWithKey : true , imgHeight :  this.field.imgHeight || '100%' , imgWidth : this.field.imgWidth || '100%' };
          this.modelChange.next(this.model);
          this.fileName = this.model.name;
          this.docBase = (this.model && this.model.base64);
          this.fileType = "image";

      };
    } else {
      const files = file.files;
      const formData = new FormData();
      for (let i = 0; i < files.length; i++ ) {
        formData.append('uplFile', files[i], files[i].name);
      }
      this.loading = true;
      // tslint:disable-next-line:max-line-length
      this.helperService.postRequest(`api/offices/files/upload?creatorId=${this.creatorId}&creatorLogin=${this.creatorLogin}&officeId=${this.officeId}&recId=${this.recId}&regId=${this.regId}`, formData)
      .then((data) => {
        // this.fetchData();
        this.loading = false;
        if (data) {
          // update the value
          this.selectedFile = {};
          // tslint:disable-next-line:max-line-length
          this.model = { id: (data && data['_id'])  || '', type: this.field.type, fileWithKey : true , imgHeight :  this.field.imgHeight || '100%' , imgWidth : this.field.imgWidth || '100%' };
          this.modelChange.next(this.model);
          // this.loading = true;
          this.openPreview(this.model['id']);
          this.createNewRec()
          .then((data2) => {
            this.loading = false;
          }).catch(err => {
            this.loading = false;
            console.info(err);
          });
        }
      }).catch(err => {
        console.info(err);
      });
    }
  }

  fetchData( ) {
    if (this.recId && this.regId) {
      this.helperService.getRequest(`api/offices/files/list?officeId=${this.officeId}&recId=${this.recId}&regId=${this.regId}&type=oneRecordDocument`)
      .then((data) => {
        this.fileList = data || [];
      }).catch(err => {
        console.info(err);
      });
    }
  }

  
  fileChanged(file) {
    const files = file.files;
    this.selectedFile[this.field['label']] = (files[0] && files[0]['name'] ) || '';
  }

  openPreview(id: string): void {
    if (id) {
      this.fileId = id;
      this.getLoading = true;
      this.helperService.getRequest(`api/offices/files/${id}/getBase64File`)
      .then((data) => {
        this.getLoading = false;
          if (data) {
          this.downloadData(data);
        }
      }).catch(err => {
        this.getError = 'retry';
        this.openRetryView(id);
        console.info(err);
        this.helperService.openSnackBar(err);
      });
    }
    if (this.field.uploadBase64) {
      this.fileType = 'image';
      this.docBase = (this.model && this.model.base64);
      this.fileName = this.model.name;
      console.log(this.model.base64);
    }
  }
  
  
  openRetryView(id: string) {
    const temp = this.dialog.open(FileRetryViewComponent, {
      disableClose: false,
      width: '40%',
      height: '400px',
      restoreFocus: false,
      panelClass: 'happ-form-builder-dialog',
      data: {
        url: `api/offices/files/${id}/getBase64File`,
        id
      }
    });

    temp.afterClosed().subscribe((data) => {
      if (data) {
        this.loading = false;
        this.getLoading = false;
        this.downloadData(data);
      }
    });
  }
  
  downloadData(data: any) {
    this.fileName = data.originalname;
    if (data['mimetype']) {
    if (data['mimetype'].includes('image')) {
      this.fileType = 'image';
    } else if (data['mimetype'].includes('pdf')) {
      this.fileType = 'pdf';
    }
  }
  this.fileDoc = data;
  const base64 = data['base64'];
  const blob = this.b64toBlob(base64, data['mimetype'], null);
  const blobUrl = URL.createObjectURL(blob);
  this.docBase =  this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  downloadFile() {
    this.helperService.downloadDocument(this.fileDoc);
  }

  deleteFile() {
    if (this.field.uploadBase64) {
      this.model = null;
      this.updateData();
    } else {

      this.helperService.openConfirmPopUp('popup.confirm_delete_file')
      .subscribe((data) => {
        if (data) {
          this.helperService.deleteRequest(`api/offices/files/${this.fileId}`)
          .then(() => {
            // this.fetchData();
            this.model = null;
            this.updateData();
            this.helperService.openSnackBar('File Deleted');
            this.fileName = null;
          }).catch(err => {
            console.info(err);
            this.helperService.openSnackBar(err);
          });
        }
      });
    }
  }
}
