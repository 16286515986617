import { HelpersService } from './../../services/helpers/helpers.service';
import { Component, OnInit, ViewChild, ViewEncapsulation, Input, Output, EventEmitter, Inject } from '@angular/core';
import { FormBuilderModalComponent } from '../form-builder-modal/form-builder-modal.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { IFormInterface } from '../../interfaces/form.interface';
import * as _ from 'lodash';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-form-list',
  templateUrl: './form-list.component.html',
  styleUrls: ['./form-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class FormListComponent implements OnInit {

  @Input() allowSelection: boolean;
  @Input() allowMultipleSelection: boolean;
  @Input() singleForm: any; 
  @Output() singleFormChange: EventEmitter<Object> = new EventEmitter();
  @Input() multipleForm: any; 
  @Output() multipleFormChange: EventEmitter<Object> = new EventEmitter();

  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  rows: Array<any>;
  selectedForms = [];
  loadingIndicator = false;
  // tslint:disable-next-line:max-line-length
  requestData = { 'page': { 'size': 10, 'totalElements': 0, 'totalPages': 0, 'pageNumber': 0 }, 'columns': [{ 'prop': 'title', 'title': 'Title' }, { 'prop': 'createdAt', 'title': 'Date Created' }, { 'prop': 'createdById', 'title': 'Created By' }, { 'prop': '_id', 'title': 'Actions' }], 'sort': { 'prop': 'createdAt', 'dir': 'desc' } };
  query: string;
  constructor(
    private helperService: HelpersService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.reRenderList();
    if (this.allowSelection && this.allowMultipleSelection) {
      this.multipleForm = this.multipleForm || [];
      this.selectedForms = this.multipleForm.map((ele: IFormInterface) => ele._id);
    }
  }


  deleteForm(form: any) {
    this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });
    if (form) {
      this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete Form - ' + form['title'] + '?';
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.helperService.deleteForm(form['_id'])
            .then(() => {
              this.reRenderList();
              this.helperService.openSnackBar('Form Deleted');
            }).catch(err => {
              this.helperService.openSnackBar(err);
            });
        }
        this.confirmDialogRef = null;
      }, err => {
        console.info(err);
      });
    }
  }

  reRenderList() {
    // rerender
    this.helperService.getFormOrTemplate('form', this.requestData)
      .then((data) => {
        this.rows = data.data;
        this.requestData.page = data.page;
      }).catch(err => {
        console.info(err);
      });
  }

  setPage(pageInfo) {
    this.requestData.page.pageNumber = pageInfo.offset;
    this.loadingIndicator = true;
    this.reRenderList();
  }

  filter(query) {
    this.requestData['search'] = {
      value: query || '',
      regex: false
    };
    this.reRenderList();
  }

  onSort(event) {
    this.loadingIndicator = true;
    this.requestData['sort'] = (event.sorts && event.sorts[0]);
    this.reRenderList();
  }

  addNewForm() {
    // new
    const dialogRef = this.dialog.open(FormBuilderModalComponent, {
      width: '100%',
      data: { operation: 'add', formData: null },
      panelClass: 'happ-form-builder-dialog'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.reRenderList();
    }, err => {
      this.helperService.openSnackBar(err);
      console.info(err);
    });
  }

  editForm(form: any) {
    // edit
    this.helperService.getForm(form)
      .then((formData) => {
        const dialogRef = this.dialog.open(FormBuilderModalComponent, {
          width: '100%',
          data: { operation: 'edit', formData: formData },
          panelClass: 'happ-form-builder-dialog'
        });
        dialogRef.afterClosed().subscribe(result => {
          this.reRenderList();
        }, err => {
          this.helperService.openSnackBar(err);
          console.info(err);
        });
      }).catch(err => {
        console.info(err);
      });
  }

  selectForm(form: IFormInterface) {
    if (this.allowSelection) {
      if (this.allowMultipleSelection) {
        if (this.selectedForms.includes(form._id)) {
          // remove form
          const index = this.selectedForms.indexOf(form._id);
          this.selectedForms.splice(index, 1);
          this.multipleForm = this.multipleForm || [];
          const index2 = _.findIndex(this.multipleForm, { _id: form._id});
          if (index2 >= 0) {
            this.multipleForm.splice(index2, 1);
          }
          this.multipleFormChange.next(this.multipleForm);    
        } else {
          this.selectedForms.push(form._id);
          this.multipleForm.push(form);
          this.multipleFormChange.next(this.multipleForm);
        }
      } else {
        this.singleForm = form;
        this.selectedForms = [];
        this.selectedForms.push(form._id);
        this.singleFormChange.next(this.singleForm);
      }
    }
  }

  deselectForm() {
    this.singleForm = null;
    this.selectedForms = [];
    this.singleFormChange.next(this.singleForm);
  }
}
