<div class="pb-12" *ngIf="!field.hide || builder">
  <div class="happ-list-field" [class]="field.class"
      [fxLayout]="((field.label_alignment === 'left') ? 'row' : 'column')+' wrap'">
      <div class="happ-list happ-content" *ngIf="!loading" fxLayout="{{field.flex}} wrap">
          <!-- <button [disabled]="field.showdisablecontroltype || field.disable" class="happ-button-list"
              [ngClass]="{'happ-small-button': field.smallButton}" class="my-4"
              [style.text-align]="(field.align)? field.align : 'center'" mat-raised-button
              (click)="simpleButton(option)" *ngFor="let option of list"
              [color]="((option.clientOfficeId && model == option.clientOfficeId._id))? 'primary' : ''">
              {{ (option.clientOfficeId && option.clientOfficeId.name) }} </button> -->
              <mat-form-field color="accent" [ngClass]="{'happ-small-form-field-outline': field.smallField}" floatLabel="always" appearance="outline" fxFlex>
                <mat-label>{{field.title | translate}}</mat-label>
                <input matInput placeholder="{{field.title | translate}}" [(ngModel)]="filterText" fxFlex #temp (keyup)="changed(temp.value)" [disabled]="field.showdisablecontroltype=='true' || field.disable" [hidden]="field.hidden" aria-label="State" [matAutocomplete]="auto">
                <mat-autocomplete (optionSelected)="onSelection()" #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredNames" [value]="option.clientOfficeId.name">
                        <span>{{ (option.clientOfficeId && option.clientOfficeId.name) }}</span>
                    </mat-option>
                </mat-autocomplete>
                <div class="error-message" *ngIf="errorMessage && (!min_error && !max_error)">
                    {{ errorMessage | translate }}
                </div>
            </mat-form-field>
      </div>
      <div fxLayoutAlign="center center" *ngIf="loading">
          <mat-spinner></mat-spinner>
      </div>
  </div>
  <div class="happ-desc" *ngIf="field.allow_description && field.description"
      [innerHTML]="field.description | htmlWithStyle">
  </div>
</div>