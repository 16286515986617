<div style="background: #f6f6f6;" class="m-0">
  <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <button class="mr-12" (click)="dialogRef.close(null)" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
    <span class="title dialog-title">
      <p>{{ 'general.select_register' | translate }}
        <!-- <button (click)="openInstructionPopup('info.ws_settings_hard_filter_info')" mat-icon-button>
          <mat-icon>info</mat-icon>
        </button> -->
      </p>
    </span>
    <span fxFlex></span>
    <button class="mr-12" (click)="saveData()" mat-icon-button>
      <mat-icon>check</mat-icon>
    </button>
  </div>
</div>
<div>
  <table>
    <thead>
      <tr>
        <th>Register not in list</th>
        <th>Register in list</th>
        </tr>
    </thead>
    <tr>
      <td class="py-0 w-50-p">
        <mat-form-field class="happ-small-form-field-outline w-100-p py-12" floatLabel="never" floatLabel="always"
          appearance="outline">
          <mat-label>{{ 'general.search' | translate }}</mat-label>
          <input matInput rows="10" [(ngModel)]="searchLeft" />
        </mat-form-field>
      </td>
      <td class="py-0 w-50-p">
        <mat-form-field class="happ-small-form-field-outline w-100-p py-12" floatLabel="never" floatLabel="always"
          appearance="outline">
          <mat-label>{{ 'general.search' | translate }}</mat-label>
          <input matInput rows="10" [(ngModel)]="searchRight" />
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td class="px-0 w-50-p">
        <div style="height: calc(80vh - 180px); overflow: auto;">
          <div class="happ-div-hover" (click)="addRegisterToList(reg)" *ngFor="let reg of filteredRegisterList | fullTextSearch:searchLeft:['name']" fxLayout="row">
            <div fxFlex="80"> {{ reg.name }} </div>
            <div fxFlex="20">
              <span fxFlex></span>
              <mat-icon>arrow_forward</mat-icon>
            </div>
          </div>
        </div>
      </td>
      <td class="px-0 w-50-p">
        <div style="height: calc(80vh - 180px); overflow: auto;">
          <ng-container *ngIf="data.selectedRegValues && data.selectedRegValues.length">
            <div class="happ-div-hover" (click)="removeRegisterFromList(reg)" *ngFor="let reg of data.selectedRegValues | fullTextSearch:searchRight:['name']" fxLayout="row">
              <div fxFlex="80"> {{ reg.name }} </div>
              <div fxFlex="20">
                <span fxFlex></span>
                <mat-icon>arrow_back</mat-icon>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="data.selectedRegValues && data.selectedRegValues.length === 0">
            <div fxLayoutAlign="center center" class="w-100-p h-100-p" >
              <h2>Select register from left</h2>
            </div>
          </ng-container>
        </div>
      </td>
    </tr>
  </table>
</div>