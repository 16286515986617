import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { MailingFooterComponent } from '../mailing-footer/mailing-footer.component';

@Component({
  selector: 'app-mailing-footer-list',
  templateUrl: './mailing-footer-list.component.html',
  styleUrls: ['./mailing-footer-list.component.scss']
})
export class MailingFooterListComponent implements OnInit {
  footerList: any;
  loading;
  constructor(
    private helperService: HelpersService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MailingFooterListComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit() {
    this.getFooterList();
  }

  async getFooterList() {
    this.footerList = await this.helperService.getTemplateList(`?type=footer`);
  }

  addOrEdit(i?) {
    const temp = this.dialog.open(MailingFooterComponent, {
      disableClose: true,
      width: '100%',
      height: '100%',
      panelClass: 'happ-form-builder-dialog',
      data: this.footerList[i] || {}
    });
    
    temp.afterClosed()
      .subscribe((response) => {
        if (response) {
          this.getFooterList();
        }
      });
  }

  close() {
    this.dialogRef.close();
  }


}
