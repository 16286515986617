<div class="p-8" fxLayout="column">
  <div class="grey-100-bg pl-12" fxLayout="row">
    <p fxFlex="50"> Name (isActive) </p>
    <!-- <p fxFlex="30">
      keyName=value
    </p> -->
    <div class="pr-20" fxFlex>
      <span fxFlex></span>
      <p>Action</p>
    </div>
  </div>
  <div style="border: 1px solid #ccc4c4; border-top: 0px;" class="py-4"
    *ngFor="let action of cardActionList | sort:'sort'; let i=index" fxLayout="row">
    <div>
      <button (click)="delete(i)" mat-icon-button aria-label="Example icon-button with a heart icon">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    <p class="pl-12" fxFlex="50"> {{ action.name }} </p>
    <span fxFlex></span>
    <div>
      <div class="happ-div-table w-76" >
        <p> Active: {{ (action.isActive)? 'Yes' : 'No' }} </p>
      </div>
      <div class="happ-div-table w-68" >
        <p> Sort: {{ action.sort }} </p>
      </div>
      <div class="happ-div-table w-68" >
        <p> Flex: {{ action.flexWidth }} </p>
      </div>
      <div style="margin: auto; " class="pr-4 ml-16" fxFlex>
        <!-- <span fxFlex></span> -->
        <div>
          <button class="mr-8" mat-raised-button (click)="edit(action, i)">Edit</button>
        </div>
      </div>
    </div>
  </div>
</div>