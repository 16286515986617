import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { MatSnackBar } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';

@Component({
  selector: 'app-case-to-rec-id',
  templateUrl: './case-to-rec-id.component.html',
  styleUrls: ['./case-to-rec-id.component.scss']
})
export class CaseToRecIdComponent implements OnInit {
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() model;
  @Input() translateData: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Input() parentModel;
  @Output() parentModelChange: EventEmitter<Object> = new EventEmitter();

  records = [];
  edit = true;
  selected = {};
  filterText = '';

  constructor(
    private http: HttpClient,
    public snackBar: MatSnackBar,
    private helperService: HelpersService
  ) { }

  ngOnInit() {
      this.selected = {};
      if (this.model && this.parentModel) {
        this.selected['_id'] = this.parentModel;
        this.selected['signatura'] = this.model;
        this.filterText = this.model;
        this.edit = false;
      }
  }

  onChange(selected) {
    if (selected) {
      if (this.field.haveRules) {
        this.onFieldValueUpdated.emit({
          label: this.field.label,
          value: this.model,
          ruleIndex: this.field.ruleIndex,
          field: this.field
        });
      }
      this.modelChange.next(selected.signatura);
      this.parentModelChange.next(selected._id);
    }
  }

  filterRecords(filterText: String) {
    if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
      this.helperService.getRequest(`api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/search?signatura=${filterText}`)
      .then((data) => {
        this.records = data || [];
      }).catch(err => {
        this.helperService.openSnackBar(err);
      });
    } else {
      this.snackBar.open('No officeId in office doc', 'Okay', {
        duration: 2000,
      });
    }
  }
}
