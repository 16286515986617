import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullTextSearch',
  pure: false
})
export class FullTextSearchPipe implements PipeTransform {

  constructor() { }

  transform(value: any, query: string, field?: any): any {
      return query ? value.reduce((prev, next) => {
        query = query.toLowerCase();
        if (field) {
          if (typeof field === 'object' && field.length > 0) {
            field.forEach(element => {
              if (next[element] && typeof(next[element]) === 'string') {
                const searchField = next[element].toLowerCase();
                if (searchField.includes(query)) { 
                  prev.push(next); 
                }
              }
            });
          } else {
            if (next[field]) {
              const searchField = next[field].toLowerCase();
              if (searchField.includes(query)) { 
                  prev.push(next); 
              }
            }
          }
        } else {
          if (next && next.toLowerCase().includes(query)) {
            prev.push(next);
          }
        }
        return prev;
      }, []) : value;
    }
}
