import { Component, OnInit, Inject } from '@angular/core';
import { OfficeHelpersService } from '../../services/office-helpers/office-helpers.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OfficeGlobalService } from '../../state/officeState/office.global.service';
import { HelpersService } from '../../services/helpers/helpers.service';
import { ProfileHelperService } from '../../services/profile-helper/profile-helper.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-office-list-popup',
  templateUrl: './office-list-popup.component.html',
  styleUrls: ['./office-list-popup.component.scss']
})
export class OfficeListPopupComponent implements OnInit {

  loading = false;
  officeList;
  searchQuery = '';
  

  constructor(
    private _officeHelper: OfficeHelpersService,
    public dialogRef: MatDialogRef<OfficeListPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router,
    private officeGlobalService: OfficeGlobalService,
    private helperService: HelpersService,
    private profileHelperService: ProfileHelperService
  ) { }

  ngOnInit() {
    let query = '';
    if (this.data.privateArea) {
      query = `?privateArea=true`;
    }
    this.getOfficeList(query);
  }

  getOfficeList(query) {
    this.loading = true;
    this._officeHelper.getOfficeList(query)
    .then((officeList) => {
      this.officeList = officeList;
      this.loading = false;
    }).catch(err => {
      console.error(err);
      this.loading = false;
    }); 
  }

  changeOffice(office: any) {
    if (this.data && this.data.changeOffice) {
      this.router.navigateByUrl('/offices/'+office._id+'/dashboard')
      this.officeGlobalService.changeOffice(office['_id'] || office['officeId'])
      .then(() => {
        this.dialogRef.close(null);
      }).catch(err => {
        console.info(err);
      });
    } else {
      this.dialogRef.close(office);
    }
  }

  onAdd(eve: any, i: any) {
    console.log(eve);
    this.starOffice(eve);
    this.officeList[i].starred = !this.officeList[i].starred;
  }

  starOffice(office: any) {
    if (office && office._id) {
      this.helperService.patchRequest(`api/profile/${office._id}/starred`, { name: office.name, starred: (!office.starred)})
      .then(() => {
        this.profileHelperService.refreshProfileContactData();
      }).catch(err => {
        console.info(err);
      });
    } else {
      console.log('no officeId provided');
    }
  }
}
