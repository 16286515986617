<div  class="content" fusePerfectScrollbar>
    <div [fxShow]="layout === 'left'" fxLayoutAlign="center center" style="height: 61px; border-bottom: 1px solid #b3afaf; border-right: 1px solid #b3afaf; background: white;" >
        <h2>
            {{ stepLabel | translate }}
        </h2>
    </div>
    <div fxLayout="row">
        <div class="w-100-p" fxLayout="column wrap" *ngIf="layout === 'left'; else otherLayout">
            <div fxLayout="column wrap" class="mb-8 mt-4">
                <button class="mx-4 m-4 ml-0"
                    [color]="(currentMQFilter == filter.value || (!currentMQFilter && defaultHardFilter === filter.value))?'accent': ''" style="height: max-content;"
                    *ngFor="let filter of dtTableAdvanceFilters | sort:'sort'"
                    (click)="buttonClicked(filter)"
                    mat-raised-button>
                    <div fxLayout="row" class="h-40 font-size-20" fxLayoutAlign="space-between center">
                        <div>
                            {{filter.name}} 
                        </div>
                        <div class="badge badge-light" *ngIf="showBadge">
                            {{ (rows | mqFilter: appHardFilter | mqFilter: filter.value)?.length }}
                        </div>
                    </div>
                </button>
            </div>
        </div>
        <ng-template #otherLayout>
            <div fxLayout="row wrap">
                <div fxLayout="row wrap" class="mb-8 mt-4">
                    <button class="happ-button my-4"
                        [color]="(currentMQFilter == filter.value || (!currentMQFilter && defaultHardFilter === filter.value))?'accent': ''" style="height: max-content;"
                        *ngFor="let filter of dtTableAdvanceFilters"
                        (click)="buttonClicked(filter)"
                        mat-raised-button>
                        {{filter.name}}
                    </button>
                </div>
            </div>
        </ng-template>
            
    </div>
</div>