import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OfficeHelpersService } from '../../services/office-helpers/office-helpers.service';
import { IFormInterface } from '../../interfaces/form.interface';

@Component({
  selector: 'app-field-option-update-dialog',
  templateUrl: './field-option-update-dialog.component.html',
  styleUrls: ['./field-option-update-dialog.component.scss']
})
export class FieldOptionUpdateDialogComponent implements OnInit {

  loading = false;
  officeId: string;
  fieldOptions = [];
  constructor(
    private _helperService: HelpersService,
    public dialogRef: MatDialogRef<FieldOptionUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _officeHelper: OfficeHelpersService
  ) { }

  ngOnInit() {
    this.getFieldOptions();
    this._officeHelper.getLocalOfficeData()
      .then((data) => {
        this.officeId = data._id;
      }).catch(err => {
        console.error(err);
      });
  }


  getFieldOptions() {
    this.fieldOptions = [];
    const formData: IFormInterface = this.data.formData || {};
    formData.ng5Form = formData.ng5Form || [];
    formData.ng5Form.forEach(tab => {
      tab.tabContent = tab.tabContent || [];

      tab.tabContent.forEach((field: any) => {
        if (field.category === 'radio' || field.category === 'checkbox') {
          if (this.data.id === field.id) {
            this.fieldOptions = field.options || [];
          }
        } else if (field.control_type === 'section') {
          field.children = field.children || [];
          field.children.forEach(sectionChild => {
            sectionChild.forEach(sectionChildField => {
              if (sectionChildField.category === 'radio' || sectionChildField.category === 'checkbox') {
                if (this.data.id === sectionChildField.id && sectionChildField.options && this.data.fieldOptions.length !== sectionChildField.options) {
                  this.fieldOptions = sectionChildField.options || [];
                }
              }

            });
          });
        }
      });
    });
  }

  update(reqData: any) {
    try {
      reqData['type'] = 'option-edit';
      this.loading = true;
      this._helperService.putRequest(`api/offices/${this.officeId}/forms/${this.data.formData._id}/form-field/${this.data.id}`, reqData)
        .then((data) => {
          this.data.formData = data;
          this.loading = false;
          this.getFieldOptions();
          this._helperService.showInfo('Option Added', 'Success', 'success');
        }).catch(err => {
          this.loading = false;
          this._helperService.showInfo(err, 'Error', 'error');
        })
    } catch (err) {
      console.info(err);
    }

  }

  removeOptions(option: any) {
    this.update({
      operation: 'remove',
      option: option
    });
  }

  addOption() {
    this._helperService.openInputPopUp('ct.enter_option_title', 'ct.confirm_message', '', true)
      .subscribe((data) => {
        if (data) {
          if (this.data.field.control_type === 'checkbox') {
            this.update({
              operation: 'add',
              keyName: 'name',
              option: {
                'name': data,
                value: false
              }
            });
          } else {
            this.update({
              operation: 'add',
              keyName: 'title',
              option: {
                'title': data,
                'background': 'white',
                'color': 'gray'
              }
            });
          }
        }
      });
  }

  formatTitle(str: string): string {
    return str.replace(/[^A-Z0-9]/ig, '_');
  }

}
