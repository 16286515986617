import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { OfficeListPopupComponent } from 'app/main/content/apps/office-list-popup/office-list-popup.component';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';
import { ProfileHelperService } from 'app/main/content/services/profile-helper/profile-helper.service';
var CalOfficeSelectionComponent = /** @class */ (function () {
    function CalOfficeSelectionComponent(dialog, officeService, profileService) {
        this.dialog = dialog;
        this.officeService = officeService;
        this.profileService = profileService;
        // tslint:disable-next-line:no-output-on-prefix
        this.onFieldValueUpdated = new EventEmitter();
        this.parentIdChange = new EventEmitter();
        this.updateRecord = new EventEmitter();
        this.modelChange = new EventEmitter();
        this.modelNameChange = new EventEmitter();
        this.valChange = new EventEmitter();
        // tslint:disable-next-line:no-output-on-prefix
        this.onError = new EventEmitter();
    }
    CalOfficeSelectionComponent.prototype.ngOnInit = function () {
        this.getDefault()
            .catch(function (err) { return console.error(err); });
    };
    CalOfficeSelectionComponent.prototype.getDefault = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var profile, officeDoc;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.model) return [3 /*break*/, 4];
                        if (!window.location.pathname.includes("/cp")) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.profileService.getLocalProfileData()];
                    case 1:
                        profile = _a.sent();
                        this.model = profile.privateAreaOfficeId;
                        this.modelName = "global.private_area";
                        this.modelChange.emit(this.model);
                        this.modelNameChange.emit(this.modelName);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.officeService.getLocalOfficeData()];
                    case 3:
                        officeDoc = _a.sent();
                        this.model = officeDoc._id;
                        this.modelName = officeDoc.name;
                        this.modelChange.emit(this.model);
                        this.modelNameChange.emit(this.modelName);
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CalOfficeSelectionComponent.prototype.selectOffice = function (field) {
        var _this = this;
        var temp = this.dialog.open(OfficeListPopupComponent, {
            width: '100%',
            height: '100%',
            restoreFocus: false,
            panelClass: 'happ-form-builder-dialog',
            data: { privateArea: true, calView: true }
        });
        temp.afterClosed()
            .subscribe(function (response) {
            console.log("response = ", response);
            if (response) {
                _this.model = response._id;
                _this.modelName = response.name;
                _this.modelChange.emit(_this.model);
                _this.modelNameChange.emit(_this.modelName);
            }
        });
    };
    return CalOfficeSelectionComponent;
}());
export { CalOfficeSelectionComponent };
