import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {MatSnackBar} from '@angular/material';
import { DatatableService } from '../../datatable/datatable.service';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-position',
  templateUrl: './position.component.html',
  styleUrls: ['./position.component.scss']
})
export class PositionComponent implements OnInit, AfterViewInit {

  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() regRec;
  @Input() recId: string;
  @Input() signature: string;
  @Input() regId: string;
  @Input() model: string; 
  @Input() translateData: any;
  @Output() modelChange: EventEmitter<any> = new EventEmitter();
  @Output() updateParentOnSave: EventEmitter<any> = new EventEmitter();
  @Output() getRecord: EventEmitter<any> = new EventEmitter();

  public options: any = null;
  public officeId: string;
  loading = false;
  errorMessage: String;
  positionRegId;

  constructor(
    private datatableService: DatatableService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private router: Router,

  ) { }

  ngOnInit() {
    this.datatableService.applicationType = 'position';
    localStorage.setItem('parent-backUrl', this.router.url);
    this.datatableService.onRecordUpdated.subscribe((data) => {
      if (data) {
        this.getRecord.emit({dontPull: true, data});
      }
    });
  }

  ngAfterViewInit(): void {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))) {
          this.officeId = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'];
      } else {
        console.error('No office Id');
        return;
      }

    if (!this.recId || this.recId === 'new') {
      console.error('First Create record and then Position will appear');
      this.errorMessage = 'First Create the record and then Position will appear';
    } else {
      this.loading = true;
      if (this.field && this.field['postionsLookupSettings'] && this.field['postionsLookupSettings']['regId']) {
        const regId = this.field['postionsLookupSettings']['regId'];
        this.positionRegId = this.field['postionsLookupSettings']['regId'];
        console.log("filter field = ", this.field, this.recId);
        const params = {
          'regId': regId,
          'recId': this.recId || '',
          formId: this.field.selectedFormId,
          'field': this.field.filterField || 'parentId',
          'signature': this.signature || '',
          'parentRegId': this.regId,
          'officeId': this.officeId,
          parentId: this.recId,
          addOnInit: this.field.addOnInit,
          'hideDeleteButton': this.field.hideDeleteButton,
          'unSubscribeThis': this.field.unSubscribeThis,
          'hideAddButton': this.field.hideAddButton,
          'hideBulkActionButton': this.field.hideBulkActionButton,
          'hideEditButton': this.field.hideEditButton,
          'unBlacklist': this.field.unBlacklist,
          'removeEmail': this.field.removeEmail,
          'price_list_regId': this.field['postionsLookupSettings']['price_list_regId'],
          'postionsLookupSettings': {
            'regId': this.field['postionsLookupSettings']['regId'] || '',
            'price_list_regId': this.field['postionsLookupSettings']['price_list_regId'] || '',
            'parentId': this.recId || ''
        }
      };
      
      if (this.field['filterField']) {
        params['postionsLookupSettings']['field'] = this.field['filterField'];

        params['postionsLookupSettings'][this.field['filterField'] || 'parentId'] = this.regRec[this.field['filterField']];
      }

      console.log("fiels", this.field);
      if (this.field.useInQuery) {
        delete params.parentId;
        params['filter'] = params['filter'] || {};
        params['filter'][this.field.filterField || 'parentId'] = {
          $elemMatch: { _id: this.recId }
        };
      }
      this.datatableService.params = params;
      if (this.field && this.field.postionsLookupSettings && this.field.postionsLookupSettings.formFields && this.field.postionsLookupSettings.formFields.length) {
        this.field.postionsLookupSettings.formFields = this.field.postionsLookupSettings.formFields || [];
        const temp = {};
        this.field.postionsLookupSettings.formFields.forEach((ele) => {
          temp[ele] = this.regRec[ele];
        });
        this.datatableService.positionExtraValues = temp;
      }
      this.loading = false;
      this.options = {};
  
    } else {
      this.errorMessage = 'No Child Reg Selected in Settings';
      console.error('No Child Reg Selected in Settings');
    }
  }
  }


  updateOnSave(event) {
    this.updateParentOnSave.emit(event);
  }

}
