/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./template-preview.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/common";
import * as i8 from "@angular/flex-layout/flex";
import * as i9 from "@angular/flex-layout/core";
import * as i10 from "../../../../../@fuse/pipes/htmlWithStyle.pipe";
import * as i11 from "@angular/platform-browser";
import * as i12 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i13 from "@angular/material/icon";
import * as i14 from "./template-preview.component";
import * as i15 from "../../services/helpers/helpers.service";
import * as i16 from "@angular/material/dialog";
var styles_TemplatePreviewComponent = [i0.styles];
var RenderType_TemplatePreviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TemplatePreviewComponent, data: {} });
export { RenderType_TemplatePreviewComponent as RenderType_TemplatePreviewComponent };
function View_TemplatePreviewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "mr-8"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.htmlPreviewLang = _v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(2, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.htmlPreviewLang === _v.context.$implicit.id) ? "primary" : ""); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.title; _ck(_v, 2, 0, currVal_3); }); }
function View_TemplatePreviewComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), (_co.templateData.header.translation[_co.htmlPreviewLang] || ""))); _ck(_v, 0, 0, currVal_0); }); }
function View_TemplatePreviewComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), (_co.templateData.mainContent.translation[_co.htmlPreviewLang] || ""))); _ck(_v, 0, 0, currVal_0); }); }
function View_TemplatePreviewComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), (_co.templateData.footer.translation[_co.htmlPreviewLang] || ""))); _ck(_v, 0, 0, currVal_0); }); }
function View_TemplatePreviewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Html preview"])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["style", "border: 1px solid #c5c3c3c2; width: 90%; margin: auto; padding: 4px 24px; box-shadow: 10px 10px 5px #c5c4c475;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemplatePreviewComponent_5)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemplatePreviewComponent_6)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemplatePreviewComponent_7)), i1.ɵdid(9, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.templateData.header && _co.templateData.header.translation); _ck(_v, 5, 0, currVal_0); var currVal_1 = (_co.templateData.mainContent && _co.templateData.mainContent.translation); _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.templateData.footer && _co.templateData.footer.translation); _ck(_v, 9, 0, currVal_2); }, null); }
function View_TemplatePreviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "p-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["fxLayout", "row"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i8.DefaultLayoutDirective, [i1.ElementRef, i9.StyleUtils, [2, i8.LayoutStyleBuilder], i9.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemplatePreviewComponent_3)), i1.ɵdid(5, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["fxFlex", ""]], null, null, null, null, null)), i1.ɵdid(7, 671744, null, 0, i8.DefaultFlexDirective, [i1.ElementRef, i9.StyleUtils, i9.LAYOUT_CONFIG, i8.FlexStyleBuilder, i9.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemplatePreviewComponent_4)), i1.ɵdid(9, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.languages; _ck(_v, 5, 0, currVal_1); var currVal_2 = ""; _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.previewView === "html"); _ck(_v, 9, 0, currVal_3); }, null); }
function View_TemplatePreviewComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Html preview"])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["style", "border: 1px solid #c5c3c3c2; width: 90%; margin: auto; padding: 4px 24px; box-shadow: 10px 10px 5px #c5c4c475;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(7, 1), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(9, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), _co.templateData.header.content)); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent.parent, 0), _co.templateData.mainContent.content)); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent.parent, 0), _co.templateData.footer.content)); _ck(_v, 8, 0, currVal_2); }); }
function View_TemplatePreviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemplatePreviewComponent_2)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemplatePreviewComponent_8)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.templateData.isMultiLang; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.templateData.isMultiLang; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_TemplatePreviewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i10.HtmlWithStylePipe, [i11.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "px-24"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "mt-8"], ["fxLayout", "row"]], null, null, null, null, null)), i1.ɵdid(3, 671744, null, 0, i8.DefaultLayoutDirective, [i1.ElementRef, i9.StyleUtils, [2, i8.LayoutStyleBuilder], i9.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [["class", "m-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Preview "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["fxFlex", ""]], null, null, null, null, null)), i1.ɵdid(8, 671744, null, 0, i8.DefaultFlexDirective, [i1.ElementRef, i9.StyleUtils, i9.LAYOUT_CONFIG, i8.FlexStyleBuilder, i9.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "button", [["aria-label", "Example icon button with a vertical three dot icon"], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(12, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i12.View_MatIcon_0, i12.RenderType_MatIcon)), i1.ɵdid(13, 9158656, null, 0, i13.MatIcon, [i1.ElementRef, i13.MatIconRegistry, [8, null], [2, i13.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemplatePreviewComponent_1)), i1.ɵdid(16, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 3, 0, currVal_0); var currVal_1 = ""; _ck(_v, 8, 0, currVal_1); _ck(_v, 13, 0); var currVal_6 = _co.templateData; _ck(_v, 16, 0, currVal_6); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 11).disabled || null); var currVal_3 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵnov(_v, 13).inline; var currVal_5 = (((i1.ɵnov(_v, 13).color !== "primary") && (i1.ɵnov(_v, 13).color !== "accent")) && (i1.ɵnov(_v, 13).color !== "warn")); _ck(_v, 12, 0, currVal_4, currVal_5); }); }
export function View_TemplatePreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-template-preview", [], null, null, null, View_TemplatePreviewComponent_0, RenderType_TemplatePreviewComponent)), i1.ɵdid(1, 114688, null, 0, i14.TemplatePreviewComponent, [i15.HelpersService, i16.MatDialogRef, i16.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TemplatePreviewComponentNgFactory = i1.ɵccf("app-template-preview", i14.TemplatePreviewComponent, View_TemplatePreviewComponent_Host_0, {}, {}, []);
export { TemplatePreviewComponentNgFactory as TemplatePreviewComponentNgFactory };
