import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { WorkspaceManagementService } from '../workspace-management.service';
import { SelectIconComponent } from '../select-icon/select-icon.component';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { FormClass } from 'app/main/content/classes/form.class';
import * as _ from 'lodash'; 
import { IProfile, IProfileOffice } from 'app/main/content/interfaces/profile.interface';
import { Router } from '@angular/router';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { CustomRegisterModel } from 'app/main/content/models/custom-register.model';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-create-workspace',
  templateUrl: './create-workspace.component.html',
  styleUrls: ['./create-workspace.component.scss']
})
export class CreateWorkspaceComponent implements OnInit {
  
  workspaceData;
  tempDataAccess: any;
  officeGroupList: any;
  customRegister: any;
  officeId: any;
  loading = false;

  tempGroupAccess: any;
  registerTypes: any;
  profileOffices: Array<IProfileOffice>;
  action = "add";

  constructor(
    public dialog: MatDialog,
    public workspaceService: WorkspaceManagementService,
    public dialogRef: MatDialogRef<CreateWorkspaceComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private helperService: HelpersService,
    public snackBar: MatSnackBar,
    private router: Router,
    private translationLoader: FuseTranslationLoaderService,
  ) { }

  ngOnInit() {
    this.helperService.getTrans('workspace-management')
      .then((data2) => {
        this.translationLoader.loadTranslationsV2(data2);
      }).catch(err => {
        console.info(err);
      });
      if (this.data && this.data.workspace) {
        this.workspaceData = this.data.workspace || {};
        this.action = "edit";
      } else {
        this.action = "add";
        this.workspaceData = JSON.parse(JSON.stringify(CustomRegisterModel));
        this.workspaceData = this.workspaceData || {};
        this.workspaceData.icon = 'apps';
      }
    console.log("works to be created - ", this.workspaceData);   
  }

  
  selectIcon(keyName: string): void {
    const dialogRef = this.dialog.open(SelectIconComponent, {
      width: '700px',
      height: '600px',
      panelClass: 'happ-form-builder-dialog',
      data: { icon: this.workspaceData[keyName] }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.workspaceData[keyName] = result;
      }
    });
  }

  saveClick() {
    if (this.data && this.data.workspace) {
      this.updateWorkspace();
    } else {
      this.createWorkspace();
    }
  }

  createWorkspace() {
    const allData = {};

    this.loading = true;
    this.helperService.postRequest('api/offices/work-space/new', this.workspaceData)
    .then(res => {
      this.loading = false;
      allData['regId'] = res['_id'] || '';
      allData['slug'] = res['slug'] || '';
      this.snackBar.open('Workspace created.', 'Okay', {
        duration: 2000,
      });
      // this.
      this.dialogRef.close(true);
      if (!this.data.notMove) {
        this.router.navigate([`/workspace/${allData['slug']}`]);
      }
    })
    .catch(err => {
      this.loading = false;
      console.info(err);
      this.snackBar.open(err || 'Error occured while Creating Workspace', 'Okay', {
        duration: 2000,
      });
    });
  }

  updateWorkspace() {
    const allData = {};

    this.loading = true;
    delete this.workspaceData._id;
    this.helperService.putRequest(`api/offices/work-space/${this.data.workspace.slug}`, this.workspaceData)
    .then(res => {
      this.loading = false;
      allData['regId'] = res['_id'] || '';
      allData['slug'] = res['slug'] || '';
      this.snackBar.open('Workspace created.', 'Okay', {
        duration: 2000,
      });
      // this.
      this.dialogRef.close(true);
      if (!this.data.notMove) {
        this.router.navigate([`/workspace/${allData['slug']}`]);
      }
    })
    .catch(err => {
      this.loading = false;
      console.info(err);
      this.snackBar.open(err || 'Error occured while Creating Workspace', 'Okay', {
        duration: 2000,
      });
    });
  }
}
