// tslint:disable:indent
import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { WorkspaceState, WorkspaceStore } from './workspace.store';


@Injectable({ providedIn: 'root' })
export class WorkspaceQuery extends Query<WorkspaceState> {
	workspaceDoc = this.select((state) => state.workspaceData);
	selectedStep = this.select((state) => state.selectedStep);
	isWorkspaceAdmin = this.select((state) => state.isWorkspaceAdmin);

    constructor(protected store: WorkspaceStore) {
		super(store);
	}
}
