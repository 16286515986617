/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cal-office-selection.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "./cal-office-selection.component";
import * as i11 from "@angular/material/dialog";
import * as i12 from "../../../services/office-helpers/office-helpers.service";
import * as i13 from "../../../services/profile-helper/profile-helper.service";
var styles_CalOfficeSelectionComponent = [i0.styles];
var RenderType_CalOfficeSelectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CalOfficeSelectionComponent, data: {} });
export { RenderType_CalOfficeSelectionComponent as RenderType_CalOfficeSelectionComponent };
export function View_CalOfficeSelectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["fxLayout", "row"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["fxFlex", ""]], null, null, null, null, null)), i1.ɵdid(7, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "button", [["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectOffice(_co.field) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(11, 0, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 2, 0, currVal_1); var currVal_3 = ""; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform((_co.modelName || "ct.select_calendar_office"))); _ck(_v, 4, 0, currVal_2); var currVal_4 = (i1.ɵnov(_v, 10).disabled || null); var currVal_5 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_4, currVal_5); var currVal_6 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("ct.select_office")); _ck(_v, 11, 0, currVal_6); }); }
export function View_CalOfficeSelectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cal-office-selection", [], null, null, null, View_CalOfficeSelectionComponent_0, RenderType_CalOfficeSelectionComponent)), i1.ɵdid(1, 114688, null, 0, i10.CalOfficeSelectionComponent, [i11.MatDialog, i12.OfficeHelpersService, i13.ProfileHelperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CalOfficeSelectionComponentNgFactory = i1.ɵccf("app-cal-office-selection", i10.CalOfficeSelectionComponent, View_CalOfficeSelectionComponent_Host_0, { field: "field", parentId: "parentId", translateData: "translateData", model: "model", modelName: "modelName", hideTitle: "hideTitle", builder: "builder", formData: "formData" }, { onFieldValueUpdated: "onFieldValueUpdated", parentIdChange: "parentIdChange", updateRecord: "updateRecord", modelChange: "modelChange", modelNameChange: "modelNameChange", valChange: "valChange", onError: "onError" }, []);
export { CalOfficeSelectionComponentNgFactory as CalOfficeSelectionComponentNgFactory };
