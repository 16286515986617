import { Component, Input, OnInit } from '@angular/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';

@Component({
  selector: 'app-mailing-log',
  templateUrl: './mailing-log.component.html',
  styleUrls: ['./mailing-log.component.scss']
})
export class MailingLogComponent implements OnInit {
  @Input() recId: string;
  logs: any;

  constructor(
    private helperService: HelpersService,
  ) { }

  ngOnInit() {
    this.getLogs();

  }

  async getLogs() {
    this.logs = await this.helperService.getRequest(`api/mailing-list/logs/${this.recId}`);
  }

}
