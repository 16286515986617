import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { FilterPipe } from './filter.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { HtmlWithStylePipe } from './htmlWithStyle.pipe';
import { FullTextSearchPipe } from './registries-list.pipe';
import { ArraySortPipe } from './sort.pipe';
import { MqFilterPipe } from './mqFilter.pipe';
import { ContainsPipe } from './contains.pipe';
import { GetFromDotPipe } from './getFromDot.pipe';
import { FileSizePipe } from './fileSize.pipe';

@NgModule({
    declarations: [
        KeysPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        HtmlWithStylePipe,
        FullTextSearchPipe,
        ArraySortPipe,
        MqFilterPipe,
        ContainsPipe,
        GetFromDotPipe,
        FileSizePipe
    ],
    imports     : [],
    exports     : [
        KeysPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        HtmlWithStylePipe,
        FullTextSearchPipe,
        ArraySortPipe,
        MqFilterPipe,
        ContainsPipe,
        GetFromDotPipe,
        FileSizePipe
    ]
})
export class FusePipesModule
{
}
