import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HelpersService } from '../../services/helpers/helpers.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-data-table-column-settings',
  templateUrl: './data-table-column-settings.component.html',
  styleUrls: ['./data-table-column-settings.component.scss']
})
export class DataTableColumnSettingsComponent implements OnInit {

  formFields = [];
  formName: string;
  currentRec = {
    title: '',
    label: '',
    sort: 50,
    columnAlign: 'left'
  };

  editIndex = null;

  constructor(
    public dialogRef: MatDialogRef<DataTableColumnSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private helperService: HelpersService
  ) { }

  ngOnInit() {
    this.data = this.data || {};
    this.data.dtTableColumns = this.data.dtTableColumns || [];
    // this.getForm();
  }

  async getForm() {
    try {
      if (this.data && this.data.formId) {
        const formData = await this.helperService.getForm(this.data.formId);
        this.formFields = this.helperService.getFormFlatField(formData.ng5Form);
        this.formName = formData.title;
        this.formatFields();
      } else {
        this.helperService.showInfo('No From Id found', '', 'error');
      }
    } catch (error) {
      this.helperService.showInfo(error, '', 'error');
    }
  }

  formatFields() {
    const fieldsToCopy = [ 'columnAlign', 'sort', 'show_on_grid' ];
    this.data.dtTableColumns.forEach((ele: any) => {
      const index = _.findIndex(this.formFields, { label: ele.label });
      fieldsToCopy.forEach((ele2) => {
        this.formFields[index][ele2] = ele[ele2];
      });
    });
  }
  
  saveData() {
    const temp = this.formFields.filter((ele) => (ele && ele.show_on_grid));
    this.dialogRef.close(this.data.dtTableColumns);
  }

  addNew() {
    if (this.currentRec.label && this.currentRec.title) {
      if (this.editIndex !== null) {
        this.data.dtTableColumns[this.editIndex] = this.currentRec;
        this.editIndex = null;
      } else {
        this.data.dtTableColumns.push((this.currentRec));
      }
      this.currentRec = {
        title: '',
        label: '',
        sort: 50,
        columnAlign: 'left'
      };
    } else {
      this.helperService.showInfo('Title and KeyName ir required to add field', '', 'error');
    }
  }
}
