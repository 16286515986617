import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
import { HttpClient } from 'selenium-webdriver/http';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';

@Component({
  selector: 'app-contact-pick',
  templateUrl: './contact-pick.component.html',
  styleUrls: ['./contact-pick.component.scss']
})
export class ContactPickComponent implements OnInit, OnDestroy, OnChanges {

  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() regRec;
  @Input() builder;
  @Input() model: string; 
@Input() translateData: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Input() modelId: string;
  @Output() modelIdChange: EventEmitter<Object> = new EventEmitter();
   
    
    filteredData;
    officeId;
    query;
    edit = false;
    message;
  regId: any;
  result: any[];
  address: any;
  searchMultiFileds: any;

    constructor(
      private translationLoader: FuseTranslationLoaderService,
      private helperService: HelpersService
    ) { }
  
    
  ngOnChanges(changes: any) {

    if (changes && changes.translateData && changes.translateData.currentValue) {
      this.translateData = changes.translateData.currentValue;
      this.translationLoader.loadTranslationsV2(this.translateData);    
    }
  }

    ngOnInit() {
      this.translationLoader.loadTranslationsV2(this.translateData);

      this.regRec = this.regRec || {};


      if (localStorage.getItem('ngStorage-selectedOffice') &&
        JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && 
        JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['apps'] && 
        JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['apps']['office_addressbook'] &&
        JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['apps']['office_addressbook']['id']
        ) {
          this.officeId = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'];
          this.regId = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['apps']['office_addressbook']['id'];
      } else {
        this.helperService.openSnackBar('No Address book Id found in localstorage');
        console.log('No office Data found');
      }
    }
  
    ngOnDestroy(): void {
      this.result = [];
      this.edit = false;
    }

  onKeyPress(i) {
    if (this.result && this.result[i]) {
      this.modelId = (this.result[i]['_id'] || '');
      // this.updateAddress.emit({
      //   companyAdress:  (this.result[i]['companyAdress'] || ''),
      //   companyCity:    (this.result[i]['companyCity'] || ''),
      //   shortName:      (this.result[i]['shortName'] || ''),
      //   longName:       (this.result[i]['longName'] || ''),
      //   postalcode:     (this.result[i]['postalcode'] || ''),
      //   companyName:    (this.result[i]['companyName'] || ''),
      // });
    }
    this.modelChange.next(this.model);
    this.modelIdChange.next(this.modelId);
    this.onFieldValueUpdated.emit({
        label: this.field.label,
        value: this.model,
        ruleIndex: this.field.ruleIndex,
        field: this.field
      });
  }

  search() {
    if (this.regId && this.officeId) {
      this.helperService.getRequest(this.getURL())
        .then((data) => {
          this.result = data || [];
          if (!this.result) {
            this.message = 'Nie znaleziono danych tego wyszukiwania';
            return;
          } else {
            this.message = this.result.length + ' znalezionych danych';
          }
          this.address = this.result.map(element => {
            // tslint:disable-next-line:max-line-length
            return `${element['companyName'] || ''} ${element['postalcode'] || ''} ${element['longName'] || ''} ${element['shortName'] || ''} ${element['companyCity'] || ''} ${element['companyAdress'] || ''}`;
            // tslint:disable-next-line:max-line-length
            // return (element['companyName'] || '') + ' ' + (element['postalcode'] || '') + ' ' + (element['longName'] || '') + ' ' + (element['shortName'] || '') + ' ' + (element['companyCity'] || '') + ' ' + (element['companyAdress'] || '');
          });
        }).catch(err => {
          this.message = err;
          console.info(err);
        });
    } else {
      console.log('No office or reg Id found');
      this.message = 'No office or reg Id found';
    }
  }

  getURL(): string {
    return `api/offices/contacts/lists/search?searchMultiFields=${this.searchMultiFileds}&officeId=${this.officeId}&regId=${this.regId}`;
  }

}
