import * as tslib_1 from "tslib";
import { Query } from '@datorama/akita';
import { OfficeStore } from './office.store';
import * as i0 from "@angular/core";
import * as i1 from "./office.store";
var OfficeQuery = /** @class */ (function (_super) {
    tslib_1.__extends(OfficeQuery, _super);
    function OfficeQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        _this.officeDoc = _this.select(function (state) { return state; });
        _this.officeCredit = _this.select(function (state) {
            if (state) {
                var c1 = state.promoCredits || 0;
                var c2 = state.purchasedCredits || 0;
                return c1 + c2;
            }
            else {
                return 0;
            }
        });
        return _this;
    }
    OfficeQuery.ngInjectableDef = i0.defineInjectable({ factory: function OfficeQuery_Factory() { return new OfficeQuery(i0.inject(i1.OfficeStore)); }, token: OfficeQuery, providedIn: "root" });
    return OfficeQuery;
}(Query));
export { OfficeQuery };
