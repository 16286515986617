import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import icons from './icons';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-select-icon',
  templateUrl: './select-icon.component.html',
  styleUrls: ['./select-icon.component.scss']
})
export class SelectIconComponent implements OnInit {

  @Input() hideToolbar: boolean; 
  @Input() model: string; 
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();

  icons;
  query;
  
  constructor(public dialogRef: MatDialogRef<SelectIconComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.icons = icons || [];
  }

  iconSelected(icon: any) {
    if (this.hideToolbar) {
      this.model = icon;
      this.modelChange.next(this.model);
    } else {
      this.dialogRef.close(icon);
    }
  }

}
