import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { InputTextComponent } from './input-text.component';


@NgModule({
    declarations: [
      InputTextComponent
    ],
    imports     : [
       FuseSharedModule
    ],
    exports: [
      InputTextComponent
    ]
})

export class InputTextModule
{

}
