import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { HelpersService } from '../../services/helpers/helpers.service';
import { IWorkspace } from './workspace-management.interface';
import { IProfile, IProfileWorkspace } from '../../interfaces/profile.interface';
import * as _ from 'lodash';
import { IRegister } from '../../interfaces/register.interface';
import { ProfileHelperService } from '../../services/profile-helper/profile-helper.service';

@Injectable()
export class WorkspaceManagementService implements Resolve<any>
{
    workspaceList: Array<IWorkspace>;    
    shownList: any [] = [];
    hiddenList: any [] = [];
    officeWorkspaceList: any;
    currentUser: string;
    loading = false;

    constructor(
        private helperService: HelpersService,
        private profileHelper: ProfileHelperService
    ) { }

    /**
     * The register list App Main Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getWorkspaceList()
            ]).then(
                () => {
                    resolve(true);
                },
                reject
            );
        });
    }

    async getWorkspaceList(): Promise<any> {
        try {
            const data = await this.helperService.getWorkspaceList();
            this.workspaceList = data || [];
            this.shownList = this.workspaceList.filter((ele) => (ele && !ele.hidden));
            this.hiddenList = this.workspaceList.filter((ele) => (ele && ele.hidden));
            console.log("shownList = ", this.shownList);
            return this.workspaceList;
        } catch (error) {
            throw error;
        }
    }

    refreshItems(item: any) {
        const showFindIndex = this.shownList.findIndex((ele: any) => (ele && ele.workspaceId === item.workspaceId));
        if (showFindIndex !== -1) {
            this.hiddenList.push(this.shownList[showFindIndex]);
            this.shownList.splice(showFindIndex, 1);
        } else {
            const hideFindIndex = this.hiddenList.findIndex((ele: any) => (ele && ele.workspaceId === item.workspaceId));
            if (hideFindIndex) {
                this.shownList.push(this.shownList[hideFindIndex]);
                this.hiddenList.splice(hideFindIndex, 1);
            }
        }
    }

    async getAllWorkspaceList(): Promise<any> {
        try {
            const profileDoc = await this.profileHelper.getLocalProfileData();
            this.currentUser = profileDoc.email;
            const data = await this.helperService.getRequest('api/offices/work-space/list');
            console.log("daa- ", data);
            this.officeWorkspaceList = data;
            // this.officeWorkspaceList = this.filterOutUninvitedWorkspace(data);
            return this.officeWorkspaceList;
        } catch (error) {
            throw error;
        }
    }

    async joinMe(wsSlug: string) {
        try {
            this.loading = true;
            await this.helperService.postRequest(`api/offices/invitation`, 
          {
            invitedUserRole: 'admin', 
            invitationType: 'workspace',
            workspaceSlug: wsSlug,
            force: true,
            invitedToEmails: [this.currentUser],
            message: 'Self Invited'
          });
          this.loading = false;
          this.helperService.openSnackBar('User added to workspace');
        } catch (error) {
            console.log(error);
        }
    }


    filterOutUninvitedWorkspace(workspaceList: IRegister[] = []) {
        try {
            return workspaceList.filter((reg) => {
                if (reg && reg.users) {
                    reg.users = reg.users || [];
                    const index = _.findIndex(reg.users, { userEmail: this.currentUser });
                    if (index === -1) {
                        return true;
                    }
                    return false;
                } else {
                    return false;
                }
            });
        } catch (error) {
            throw error;
        }
    }

    async removeWorkspace(workspace: IRegister): Promise<any> {
        try {
            await this.helperService.deleteRequest(`api/offices/work-space/${workspace.slug}`);
            return true;
        } catch (error) {
            throw error;
        }
    }

    async leaveWorkspace(workspace: IRegister): Promise<any> {
        try {
            await this.helperService.leaveWorkspace(workspace.slug);
            return true;
        } catch (error) {
            throw error;
        }
    }

    async unarchiveWorkspace(workspace: IRegister): Promise<any> {
        try {
            await this.helperService.updateWorkspaceData(workspace.slug, { archive: false });
            return true;
        } catch (error) {
            throw error;
        }
    }

    async archiveWorkspace(workspace: IRegister): Promise<any> {
        try {
            await this.helperService.updateWorkspaceData(workspace.slug, { archive: true });
            return true;
        } catch (error) {
            throw error;
        }
    }

    async createWorkspace(workspace: string): Promise<any> {
        return '';
    }

    getLocalWorkspace(): Array<IProfileWorkspace> {
        if (JSON.parse(localStorage.getItem('ngStorage-profile')) && JSON.parse(localStorage.getItem('selectedOfficeId'))) {
            const profile: IProfile = JSON.parse(localStorage.getItem('ngStorage-profile'));
            const officeId: string = JSON.parse(localStorage.getItem('selectedOfficeId'));
            const index = _.findIndex(profile['offices'] || [], { 'officeId': officeId });
            return profile['offices'][index].workspaces || [];
        } else {
            return [];
        }
    }
}
