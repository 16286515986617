<div *ngIf="instanceId">
  <ngx-timeline [events]="events">
    <ng-template let-event let-index="rowIndex" timelineDate>
      {{ event.date | date: 'yyyy-MM-dd HH:mm:ss' }}
   </ng-template>
  </ngx-timeline>
  <div fxLayoutAlign="center center">
    <h2>
      Rozpoczęcie procesu
    </h2>
  </div>
</div>
<div *ngIf="!instanceId || (events && events.length === 0)">
  <h2>
    Brak danych do pokazania.</h2>
</div>