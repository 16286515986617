import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { SsEventFormAddPopupComponent } from './ss-event-form-add-popup/ss-event-form-add-popup.component';

@Component({
  selector: 'app-ss-event-form',
  templateUrl: './ss-event-form.component.html',
  styleUrls: ['./ss-event-form.component.scss']
})
export class SsEventFormComponent implements OnInit {

  @Input() translateData: any;
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<any> = new EventEmitter();
  @Input() builder;
  @Input() model: any; 
  @Output() modelChange: EventEmitter<any> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onError: EventEmitter<any> = new EventEmitter();
  
  listData: any[] = []
  constructor(
    private helperService: HelpersService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.listData = this.model || [];
  }

  add(index?) {
    // this.listData.push({});
    
    const dialogRef = this.dialog.open(SsEventFormAddPopupComponent, {
      width: '100%',
      panelClass: 'happ-form-builder-dialog',
      data: { 
        edit: index,
        data: this.listData[index] || {}
      }
  });


  dialogRef.afterClosed().subscribe(async (result) => {
    console.log("data -", result, this.listData);
      if (result) {
        if (result && result.operation === "delete") {
          this.listData.splice(index, 1);
        } else if (index) {
          this.listData[index] = result;
        } else {
          this.listData.push(result);
        }
        this.model = this.listData;
        this.modelChange.next(this.model);
      }
  }, err => {
      console.info(err);
  });
  }

  remove(i) {

  }
}
