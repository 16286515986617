<div>
    <mat-toolbar *ngIf="!hideToolbar" style="background: #f6f6f6;" matDialogTitle class="m-0">
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
          <button class="mr-12" (click)="dialogRef.close(null)" mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
          <span class="title dialog-title"> Select Icon - {{ (data && data.icon) || '' }} </span>
          <span fxFlex></span>
          <div fxLayout="row" fxLayoutAlign="start center">
              <label for="search" class="mr-8">
                  <mat-icon>search</mat-icon>
              </label>
              <mat-form-field>
                  <input matInput placeholder="Search" [(ngModel)]="query">
              </mat-form-field>
          </div>
        </div>
      </mat-toolbar>
  <div style="overflow: auto" class="example-card p-24 h-520">
    <div fxLayout="row wrap" class="mb-8">
      <div [class]="(icon === model) ? 'p-8 m-4 happ-icon-container active' : 'p-8 m-4 happ-icon-container'"  (click)="iconSelected(icon)" *ngFor="let icon of icons | fullTextSearch:query">
        <mat-icon class="font-size-34" style="height: 34px; width: 34px;"> {{ icon }} </mat-icon>
      </div>
    </div>
  </div>
</div>