export const ConstActions = [
    {
      'default_label': 'edit',
      'translations': {
        'pl': 'Edytuj',
        'en': 'edit'
      },
      'sort': 1,
      'placement': {
        'headerActions': false,
        'subheaderActions': false,
        'recordActions': true,
        'dropdownActions': false
      },
      'module': 'customRegisterRecordsList',
      'application': 'app.oneRecordView.edit.popup',
      'params': {
        'json': ''
      },
      'access_level': '2',
      'applicationName': 'RegRec Edit OneRecordview Popup'
    },
    {
      'default_label': 'insert',
      'translations': {
        'pl': 'Dodaj',
        'en': 'insert'
      },
      'sort': 3,
      'placement': {
        'headerActions': true,
        'subheaderActions': false,
        'recordActions': false,
        'dropdownActions': false
      },
      'module': 'customRegisterRecordsList',
      'application': 'app.oneRecordView.add.popup',
      'params': {
        'json': ''
      },
      'access_level': '2',
      'applicationName': 'RegRec Insert OneRecordview Popup'
    },
    {
      'default_label': 'Usuń',
      'translations': {
        'pl': 'Usuń',
        'en': 'Delete'
      },
      'icon': '',
      'sort': '',
      'placement': {
        'headerActions': false,
        'subheaderActions': false,
        'recordActions': true,
        'dropdownActions': false
      },
      'module': 'customRegisterRecordsList',
      'application': 'deleteRecord',
      'params': {},
      'applicationName': 'RegRec Delete OneRecordview',
      'access_level': '2',
      'actionParams': ''
    },
    {
      'default_label': 'dttable.select_from_another_register',
      'translations': {
        'pl': 'dttable.select_from_another_register',
        'en': 'dttable.select_from_another_register'
      },
      'icon': '',
      'sort': '',
      'fuse2App': './../selection/:priceListRegId/:childRegId/:parentRegId',
      'placement': {
        'headerActions': true,
        'subheaderActions': false,
        'recordActions': false,
        'dropdownActions': false
      },
      'module': 'customRegisterRecordsList',
      'application': 'app.customregistries.selectRegister',
      'params': {},
      'applicationName': 'Select From Third Register',
      'access_level': '2',
      'actionParams': ''
    }
];
