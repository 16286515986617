import { HelpersService } from './../../../../../services/helpers/helpers.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TaskSelectionComponent } from '../task-selection/task-selection.component';
import { MessageSettingsComponent } from 'app/main/content/global-component/message-settings/message-settings.component';
import { SelectFormField } from '../select-form-field.model';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
  selector: 'app-task-category-selection',
  templateUrl: './task-category-selection.component.html',
  styleUrls: ['./task-category-selection.component.scss']
})
export class TaskCategorySelectionComponent implements OnInit {

  tasksInfo = {};
  selectedCategory: string;
  selectFormField: any;
  task: any = {
    id: new Date().getTime(),
    name: 'Task Name',
    taskType: '',
    formType: 'main-form'
};
  FormField: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private helperService: HelpersService,
    public dialog: MatDialog,
    private translationLoader: FuseTranslationLoaderService,
    public dialogRef: MatDialogRef<TaskCategorySelectionComponent>,
  ) { }

  ngOnInit() {
    this.helperService.getTrans('processSettings')
    .then((data2) => {
      this.translationLoader.loadTranslationsV2(data2);
    }).catch(err => {
      console.info(err);
    });
    this.getAllProcessTaskInfo();
    this.selectFormField = JSON.parse(JSON.stringify(SelectFormField)) || {};
    this.getAllFormFields();
  }


  getAllProcessTaskInfo() {
    this.helperService.getRequest(`api/process-tasks-types`)
      .then((data) => {
        this.tasksInfo = data || {};
      }).catch(err => {
        console.info(err);            
      });
  }

  openTaskSelection(taskCategory: string) {
    if (taskCategory === 'Send Message') {
      this.openMessageSettings();
    } else {
      this.selectedCategory = taskCategory;
      const dialogRef = this.dialog.open(TaskSelectionComponent, {
        width: '100%',
        panelClass: 'happ-form-builder-dialog',
        data: { 
          selectedCategory: this.selectedCategory, 
          taskList: this.tasksInfo['taskTypeObj'][taskCategory], 
          processData: this.data['processData'], 
          activityData: this.data['activityData'] 
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close(result);
        }
      });
    }
  }

  openMessageSettings() {
    const tempDialog = this.dialog.open(MessageSettingsComponent, {
        disableClose: false,
        data: { 
            selectFormField: this.selectFormField, 
            task: this.task, 
            FormField: this.FormField,
            processData: this.data['processData']
        },
        width: '100%',
        panelClass: 'happ-form-builder-dialog'
    });
  
    tempDialog.afterClosed().subscribe(result => {
        if ( result ) {
          this.task = result || {};
        }
    }, err => {
        console.info(err);
      }); 
}

getAllFormFields() {
  if (this.data.activityData && this.data.activityData['selectedForm']) {
    return this.getFormField(this.data.activityData['selectedForm']);
  }
}


getFormField(formId: string): Promise<object> {
  return new Promise((resolve, reject) => {
      this.getForm(formId)
      .then(data => {
          this.FormField = this.FormField || [];
          this.FormField = this.FormField.concat(this.traverse(data));
          resolve();
      })
      .catch(err => {reject(); });
  });
}

getForm(formId: string): Promise<object> {
  return this.helperService.getForm(formId)
  .then((data) => {
      return (data && data['ng5Form']);
  });
}


traverse = function(obj) {
  const ng5AllFields = [];
  obj.forEach((tabValue) => {
    if (tabValue.tabContent && typeof tabValue.tabContent === 'object') {
      tabValue.tabContent.forEach((value) => {
        if (value.field || (value.data !== null && typeof value.data === 'string') )  {
          ng5AllFields.push(value);
        }
        if (value.children && typeof value.children === 'object') {
          value.children.forEach((childValue) => {
            if (childValue.field || (childValue.data !== null && typeof childValue.data === 'string') ) {
              ng5AllFields.push(childValue);
            }
            if (childValue && typeof childValue === 'object') {
              childValue.forEach((nestedChildValue) => {
                if (nestedChildValue.field || (nestedChildValue.data !== null && typeof nestedChildValue.data === 'string') ) {
                  ng5AllFields.push(nestedChildValue);
                }
              });
            }

          });
        }
      });
    }
  });
  return ng5AllFields;
};

}
