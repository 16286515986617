import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfficeListComponent } from './office-list.component';
import { OfficeListService } from './office-list.service';
import { MaterialModule } from '@fuse/material.module';
 
@NgModule({
  imports: [
    CommonModule,
    MaterialModule
  ],
  declarations: [
    OfficeListComponent
  ],
  providers: [
    OfficeListService
  ]
})
export class OfficeListModule { }
