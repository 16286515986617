import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { SmtpListComponent } from './smtp-list/smtp-list.component';
import { SmtpFormComponent } from './smtp-form/smtp-form.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FuseSharedModule,
  ],
  exports: [
    
  ],
  declarations: [
    SmtpListComponent,
    SmtpFormComponent
  ],
  entryComponents: [
    SmtpListComponent,
    SmtpFormComponent
  ]
})
export class SmtpSettingsModule { }
