<div class="happ-list-field" fxLayout="column">

  <div class="m-12" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="center center">
      <p class="m-0 mr-8" style="font-size: 20px;">
        {{ 'ct.start_time' | translate }}
      </p>
      <input (change)="onInputChange($event, 'startTime')" type="time" class="happ-input-time" [(ngModel)]="regRec['startTime']" [placeholder]="'ct.start_time' | translate">
    </div>
    <span fxFlex></span>
    <div fxLayout="row" fxLayoutAlign="center center">
      <p class="m-0 mr-8" style="font-size: 20px;">
        {{ 'ct.end_time' | translate }}
      </p>
      <input type="time" class="happ-input-time" [(ngModel)]="regRec['endTime']" [placeholder]="'ct.end_time' | translate">
    </div>
  </div>


  <div class="happ-list m-12" fxFlex="100" [fxLayout]="(field && field.showOnForm)? 'column' : 'row'" [ngClass]="{'formButton': (field && field.showOnForm), 'header-or-footer-button': (!field || (field && !field.showOnForm))}">
      <button [color]="(regRec.selectedDays.includes(button.value)) ? 'primary' : ''" [ngClass]="{'happ-small-button': (field && field.smallButton)}" class="happ-button-list mx-8" mat-raised-button (click)="toggleDay(button)" role="listitem" *ngFor="let button of days">
          <span>
              {{ button.title | translate }}
          </span>
      </button>
  </div>

  <div *ngFor="let item of days">
    <ng-container *ngIf="regRec.selectedDays.includes(item.value)">
      <div class="p-12" style="border-bottom: 1px solid lightgray;">
        <div fxLayout="column">
          <h2 class="m-0">
            {{ 'ct.select_slot' | translate }} - {{ item.title | translate }}
          </h2>
          <div fxLayoutAlign="center center" class="m-8" style="border: 1px solid lightgray;" *ngFor="let slot of regRec.slotRanges[item.value]; let i=index;" fxLayout="row">
            <div>
              <input type="time" class="happ-input-time" [(ngModel)]="slot['startTime']" [placeholder]="'ct.start_time' | translate">
              <input type="time" class="happ-input-time" [(ngModel)]="slot['endTime']" [placeholder]="'ct.end_time' | translate">
            </div>
            <span fxFlex></span>
            <div>
              <button class="mr-8" mat-raised-button (click)="deleteRow(i, item.value)" >
                {{ 'ct.delete' | translate }}
              </button>
            </div>
          </div>
          <div fxLayout="row">
            <span fxFlex></span>
            <button class="m-8" mat-raised-button (click)="addRow(item.value)" >
              {{ 'ct.add' | translate }}
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- <mat-tab-group>
    <ng-container *ngFor="let item of days">
      <mat-tab *ngIf="selectedDays.includes(item.value)" [label]="item.title | translate"> 
        <div class="p-12">
          <h2 class="mt-0">
            {{ 'ct.select_slot' | translate }}
          </h2>
          <div fxLayout="row wrap" *ngIf="allDateRange[item.value]">
            <div [ngClass]="{'active': selectedSlots[item.value].includes(slot.formatted), 'happ-slot-item': true}" (click)="toggleSlotSelection(slot, item.value)"  *ngFor="let slot of allDateRange[item.value].times">
              {{ slot.formatted }}
            </div>
          </div>
        </div>  
      </mat-tab>
    </ng-container>
  </mat-tab-group> -->
</div>
