<div style="background: #039be5; color: white" class="m-0 p-8">
  <div fxFlex fxLayout="header" fxLayoutAlign="space-between center">
    
    <span class="title dialog-title">
      <p style="font-size: 20px;" class="m-0 ml-12">
        {{ 'ct.select_slot' | translate }} 
      </p>
    </span>
    <span fxFlex></span>
    <button class="mr-12" (click)="dialogRef.close(null)" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div id="calendar-main" *ngIf="!loading" >

  <!-- HEADER -->
  <div class="p-8 p-sm-8" [ngClass]="viewDate | date:'MMM'">

      <div class="header-content" fxLayout="column" fxLayoutAlign="space-between">

          <div class="toolbar" fxLayout="row wrap" *ngIf="dtTableAdvanceFilters && dtTableAdvanceFilters.length > 0" fxLayoutAlign="start center">
              <button class="happ-button mx-4 m-4" [color]="(currentMQFilter==filter.value)?'accent': ''" style="height: max-content;" *ngFor="let filter of dtTableAdvanceFilters" (click)="mqFilterChanged(filter)" mat-raised-button>
                  {{filter.name}}
              </button>
          </div>
      </div>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content p-12 pt-0">
      <div fxLayout="row" fxLayoutAlign="space-around start" fxLayout.lt-md="column">
        <div fxFlex="60" fxFlex.lt-md="100" style="height: calc(100vh - 70px);">
          <div id="calendar" >
          </div>
        </div>
        <div fxFlex="38" fxFlex.lt-md="100" class="p-12 pt-0 w-100-p">
          <ng-container *ngIf="!selectedEventData">
            <h2>
              {{ 'ct.select_time_slot_from_left' }}
            </h2>
            <p>
              {{ 'ct.select_time_slot_desc' | translate }}
            </p>
          </ng-container>
          <ng-container *ngIf="selectedEventData" >
            <div fxLayout="column">
              <div class="mb-12" fxLayout="row" fxLayoutAlign="center center">
                <h2 class="m-0">
                  {{ 'ct.selected_event' | translate }}
                </h2>
                <span fxFlex></span>
                <div>
                  <button mat-raised-button (click)="submit()" >
                    {{ 'ct.submit' | translate }}
                  </button>
                </div>
              </div>
              <div class="happ-event-list-item" fxLayout="column">
                <div fxLayout="row">
                  <p>
                    {{ 'ct.selectedStartDate' | translate }}  
                  </p>
                  <span fxFlex></span>
                  <button mat-icon-button [matTooltip]="'ct.selectedStartDateInfo'">
                    <mat-icon>help_outline</mat-icon>
                  </button>
                </div>
                <strong *ngIf="selectedEventData.start">
                  {{ (selectedEventData.start || selectedEventData.startDateTime) | date:"yyyy-MM-dd HH:mm:ss" }}
                </strong>
                <strong *ngIf="!selectedEventData.start">
                  {{ 'ct.start_not_selected' | translate }}
                </strong>
              </div>
              <div class="happ-event-list-item" fxLayout="column">
                <div fxLayout="row">
                  <p>
                    {{ 'ct.selectedEndDate' | translate }}  
                  </p>
                  <span fxFlex></span>
                  <button mat-icon-button [matTooltip]="'ct.selectedEndDateInfo'">
                    <mat-icon>help_outline</mat-icon>
                  </button>
                </div>
                <strong *ngIf="(selectedEventData.endDateTime || selectedEventData.end)">
                  {{ (selectedEventData.endDateTime || selectedEventData.end) | date:"yyyy-MM-dd HH:mm:ss" }}
                </strong>
                <strong *ngIf="!(selectedEventData.endDateTime || selectedEventData.end)">
                  {{ 'ct.end_not_selected' | translate }}
                </strong>
              </div>
              <div class="happ-event-list-item" fxLayout="column">
                <div fxLayout="row">
                  <p>
                    {{ 'ct.expireDateTime' | translate }}  
                  </p>
                  <span fxFlex></span>
                  <button mat-icon-button [matTooltip]="'ct.expireDateTimeInfo'">
                    <mat-icon>help_outline</mat-icon>
                  </button>
                </div>
                <strong>
                  {{ selectedEventData.expireDateTime | date:"yyyy-MM-dd HH:mm:ss" }}
                </strong>
              </div>
              <div class="happ-event-list-item" fxLayout="column">
                <div fxLayout="row">
                  <p>
                    {{ 'ct.eventSubject' | translate }}  
                  </p>
                  <span fxFlex></span>
                  <button mat-icon-button [matTooltip]="'ct.eventSubjectInfo'">
                    <mat-icon>help_outline</mat-icon>
                  </button>
                </div>
                <strong>
                  {{ selectedEventData.subject }}
                </strong>
              </div>
              <div class="happ-event-list-item" fxLayout="column">
                <div fxLayout="row">
                  <p>
                    {{ 'ct.caseSignature' | translate }}  
                  </p>
                  <span fxFlex></span>
                  <button mat-icon-button [matTooltip]="'ct.caseSignatureInfo'">
                    <mat-icon>help_outline</mat-icon>
                  </button>
                </div>
                <strong>
                  {{ selectedEventData.caseSignature }}
                </strong>
              </div>
            </div>
          </ng-container>

          <div>
            <h2>
              {{ 'ct.event_range' | translate }}
            </h2>
            <div class="mb-12">
              <!-- slotDuration -->
              <div fxLayout="row">
                <div>
                  <button class="mr-4" [color]="(slotDuration === item.value) ? 'primary' : ''" *ngFor="let item of timeGap" (click)="changeSlotDuration(item)" mat-raised-button>
                    {{ item.title }}
                  </button>
                </div>
              </div>
              <div class="mt-20" fxLayout="row">
                <mat-form-field floatLabel="always" appearance="outline" class="happ-small-form-field-outline mr-8">
                  <mat-label>{{ 'ct.start_day' | translate }}</mat-label>
                  <mat-select (selectionChange)="onSelectionChange($event, 'minTime')" [(ngModel)]="startDay" >
                    <mat-option *ngFor="let item of timeRange" [value]="item">
                      {{item}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field floatLabel="always" appearance="outline" class="happ-small-form-field-outline">
                  <mat-label>{{ 'ct.end_day' | translate }}</mat-label>
                  <mat-select (selectionChange)="onSelectionChange($event, 'maxTime')" [(ngModel)]="endDay">
                    <mat-option *ngFor="let item of timeRange" [value]="item">
                      {{item}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>

     
  </div>
  <!-- / CONTENT -->
</div>

<div id="calendar-main" fxLayoutAlign="center center" class="page-layout simple fullwidth" fusePerfectScrollbar *ngIf="loading" >
  <mat-spinner></mat-spinner>
</div>