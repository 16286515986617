import { NgModule } from '@angular/core';
import { GetUserInfoByEmailPipe } from './getUserInfoByEmail.pipe';
import { ParseHtmlContentPipe } from './parseHtmlContent.pipe';
import { SumOfFieldPipe } from './sumOfField.pipe';
import { ClickPipeTest } from './clickPipeTest.pipe';
import { GetFromLocalStorageDataPipe } from './getFromLocalStorageData.pipe';
import { TranslateHtmlPipe } from './translateHtml.pipe';
import { TextTranslatePipe } from './textTranslate.pipe';
import { ParseHtmlPipe } from './parseHtml.pipe';


@NgModule({
    declarations: [
       GetUserInfoByEmailPipe,
       ParseHtmlContentPipe,
       SumOfFieldPipe,
       ClickPipeTest,
       GetFromLocalStorageDataPipe,
       TranslateHtmlPipe,
       TextTranslatePipe,
       ParseHtmlPipe
    ],
    imports     : [],
    exports     : [
       GetUserInfoByEmailPipe,
       ParseHtmlContentPipe,
       SumOfFieldPipe,
       ClickPipeTest,
       GetFromLocalStorageDataPipe,
       TranslateHtmlPipe,
       TextTranslatePipe,
       ParseHtmlPipe
    ]
})
export class GlobalPipesModule
{
}
