 
<div style="position: relative;" fxFlex>
    <div fxLayout="row" *ngIf="!field.defaultEdit && !errorMessage && field.showEdit" (click)="field.defaultEdit=!field.defaultEdit">
        <button class="mr-4" mat-icon-button>
            <mat-icon>edit</mat-icon>
        </button>
        <p class="font-size-18 mt-12">
            {{ (model) | date: 'yyyy-MM-dd' }}
        </p>
    </div>
    <div fxLayout="column wrap" *ngIf="field.defaultEdit || errorMessage || !field.showEdit">
        <mat-form-field color="accent" [ngClass]="{'w-100-p': true, 'happ-small-form-field-outline': field.smallField}" floatLabel="always" appearance="outline" *ngIf="!field.hide || builder">
                <mat-label> {{ field.title | translate }} </mat-label>

            <input matInput (click)="picker.open()" [matDatepicker]="picker" [placeholder]="field.title | translate" [disabled]="field.disable" (dateChange)="ondateChange()" [hidden]="field.hidden" [(ngModel)]="date">
            <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon class="margin-right: 0px;" matDatepickerToggleIcon >keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <div class="error-message" *ngIf="errorMessage">
                    {{ errorMessage | translate }}
                </div>
        </mat-form-field>
    </div>
    <div class="happ-field-description" *ngIf="field.allow_description && field.description"> {{ field.description }} </div>
</div>