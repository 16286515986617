<div>
    <mat-toolbar style="background: #f6f6f6;" matDialogTitle class="m-0">
      <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <button class="mr-8" (click)="dialogRef.close(null)" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
        <span class="title dialog-title"> {{ 'ct.add_contact_title' | translate }} </span>
        <span fxFlex></span>
        <div fxLayout="row"  fxLayoutAlign="start center">
         
          <button aria-label="add_options" color="warn"  class="mx-4" (click)="dialogRef.close(true)" mat-button mat-raised-button>
            {{ 'ct.save' | translate }} 
          </button>
        </div>
      </div>
    </mat-toolbar>
    <div style="overflow: auto;" class="p-12 px-20" fxLayout="column">
        <table class="happ-info-table mb-12">
            <tr class="pl-16" *ngIf="data && data.field" style="width: 50%;">
                <!-- info -->
                <h2 class="m-0 ml-8 mb-4 font-weight-700">
                  {{ 'ct.add_contact_more_heading' | translate }}
                </h2>
                <p class="mb-12 ml-8">
                  {{ 'ct.add_contact_more_info' | translate }}
                </p>
              </tr>
            <tr>
                <!-- left -->
                <table>
                  <tr *ngFor="let temp of keys">
                    <td style="width: 200px;"> {{ temp.title | translate }} </td>
                    <td> {{ (data && data.fieldLabel && data.fieldLabel[temp.key]) || '-' }} </td>
                  </tr>
                </table>
            </tr>
          </table>
    </div>
    <div *ngIf="!data">
      {{ 'ct.no_option_present' | translate }}
    </div>
    <!-- <div class="p-24 pt-20 pb-20">
      <span fxFlex></span>
      <button (click)="dialogRef.close(null)" aria-label="custom_app_cancel_button" mat-button class="mr-4">{{ 'ct.cancel' | translate }}</button>
      <button mat-raised-button [disabled]="loading" aria-label="custom_app_create_button" (click)="update()">{{ 'ct.update' | translate }}</button>
    </div> -->
  </div>
