<div style="width: 100%; background: white;">

    <div fxLayout="column" fxLayoutAlign="center start" class="w-100-p" *ngIf="!loading && !success">
        <div fxLayout="row" class="w-100-p" style="border: 1px solid lightgray;">
            <div class="w-100-p px-12">
                <h2>
                    Pinbox24.com
                </h2>
                <span fxFlex></span>
                <h2>
                    Pinbox Information System - Shared service
                </h2>
            </div>
        </div>
        <div *ngIf="data" style="width: 80%;margin: auto;">
            <p>
                {{ data && data.extraData && data.extraData.actionStatus }}
            </p>
            <h1>
                Hey, {{ data.email}}
            </h1>
            <div style="border: 1px solid lightgray; padding: 12px;">
                <div [innerHTML]="data.websiteHtml | htmlWithStyle"></div>
            </div>
            <br>
            <p>
                According to GDPR regulation we have to ask you for permission to send to you information in that topic do you agree to receive this info?
            </p>
            <br>
            <div fxLayout="row" *ngIf="data.action === 'sendMailWithRecord' || data.action === 'sendMailWithRecordWithSharedService'">
                <div class="w-100-p">
                    <button mat-raised-button class="w-20-p" (click)="reject()" >Reject</button>
                    <button mat-raised-button style="background: green; color: white;" class="w-80-p" (click)="accept()" >Accept</button>
                </div>
            </div>

            <div>
                <h1>Sender Identification</h1>
                <div fxLayout="column">
                    <div fxLayout="row">
                        <p fxFlex="50">
                            Company
                        </p>
                        <p fxFlex="50"  *ngIf="data && data.officeInfo && data.officeInfo.name">
                            {{ data.officeInfo.name }}
                        </p>
                    </div>
                    <div fxLayout="row">
                        <p>
                            Address
                        </p>
                    </div>
                    <div fxLayout="row">
                        <p>
                            City
                        </p>
                    </div>
                    <div fxLayout="row">
                        <p>
                            Country
                        </p>
                    </div>
                </div>
            </div>

            <div style="border: 1px solid lightgray; padding: 12px; text-align: center;">
                <p>
                    If you don't like to receive any information from us -
                    <a (click)="unSubscribeAll()" style="color: blue;">
                        Click here.
                    </a>
                </p>
                <p>
                    If you don't like to receive this kind of information from us -
                    <a (click)="unSubscribeThis()" style="color: blue;">
                        Click here.
                    </a>
                </p>
            </div>
        </div>
    </div>
    
    <div style="width: 100%; height: 400px;" fxLayoutAlign="center center" *ngIf="!loading && success">
        <h1>
            Thank you for showing you interest.
            <span *ngIf="data.action === 'sendMailWithRecordWithSharedService'">
                Redirecting to service page.
            </span>
        </h1>
    </div>
    
    <div style="width: 100%; height: 400px;" fxLayoutAlign="center center" *ngIf="loading && !success">
        <mat-spinner></mat-spinner>
    </div>
</div>