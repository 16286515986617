<div class="dialog-content-wrapper" style="background: #fafafa;">
  <mat-toolbar matDialogTitle class="m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title"> {{ 'popup.app_updated' | translate }} </span>
      <button mat-button class="mat-icon-button"
              (click)="dialogRef.close()"
              aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-toolbar>

  <div class="p-24 m-0" fusePerfectScrollbar>
    <div fxLayoutAlign="center center" fxLayout="column">
      <div>
        <p>
          {{ 'popup.app_updated_successfully' | translate }}
        </p>
      </div>
      <div>
        <button mat-raised-button class="mat-accent mr-16" aria-label="confirm_popup_confirm_button" (click)="dialogRef.close(true)">{{ 'popup.okay' | translate }}</button>
      </div>
    </div>
  </div>
</div>
