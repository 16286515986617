import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-card-actions',
  templateUrl: './card-actions.component.html',
  styleUrls: ['./card-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class CardActionsComponent implements OnInit {

  @Input() cardActions: any[];
  @Input() rowData: any = {};
  @Output() optionClicked: EventEmitter<Object> = new EventEmitter();  

  selectedValue;
  isEdit = false;
  config = {
    height: 150,
    toolbar: [
    [ 'Bold', 'Italic', '-', 'NumberedList', 'BulletedList', 'FontSize', 'TextColor', '-', 'Table', 'HorizontalRule' ]
]};
  oldValue = {};

  constructor(
    private _helperService: HelpersService,
    private translationService: TranslateService,
    private _adapter: DateAdapter<any>
  ) { }

  ngOnInit() {
    this.cardActions = this.cardActions || [];
    this._adapter.setLocale(this.translationService.getDefaultLang());

    this.cardActions = this.cardActions.filter((ele) => (ele && ele.isActive));
    this.oldValue = JSON.parse(JSON.stringify(this.rowData));
    this.cardActions.forEach((action) => {
      if (action && action.actionType === 'editor') {
        this.rowData[`${this.rowData['_id']}_${action.keyName}`] = this.checkForEditMode(action);
      }
    });
  }

  openLink(link: string) {
    window.open(link, '_blank', 'location=yes,height="100%",width="100%",top="10px",left="10px",scrollbars=yes,status=yes');
  }

  checkForEditMode(action: any) {
    if (action && this.rowData[action.keyName] && action.actionType === 'editor') {
      return false;
    }
    return true;
  }

  optionChanged(option: any, action: any) {
    option.keyName = action.keyName;
    this.optionClicked.emit(option);
  }

  closeEditor(action: any) {
    this.rowData[`${this.rowData['_id']}_${action.keyName}`] = this.checkForEditMode(action);
  }

  editorChange(value: any, action: any) {
    this.rowData[`${this.rowData['_id']}_${action.keyName}`] = this.checkForEditMode(action);
    this.optionChanged({ value }, action);
  }

  inputChange(value: any, action: any) {
    this.oldValue[action.keyName] = this.oldValue[action.keyName] || '';
    if (value !== this.oldValue[action.keyName]) {
      this.oldValue[action.keyName] = value;
      this.optionChanged({ value }, action);
    }
  }
}
