<ng-container *ngIf="settings">
  <mat-tab-group>
    <mat-tab *ngFor="let tab of settings.settings" [label]="tab.title | translate">
      <ng-container *ngFor="let tabSection of tab.children" >
        <ng-container [ngSwitch]="tabSection.type">
          <app-tab-settings *ngSwitchCase="'tab'" [settings]="tabSection" ></app-tab-settings>
          <app-section-settings *ngSwitchCase="'section'" [settings]="tabSection" ></app-section-settings>
          <app-field-settings *ngSwitchCase="'field'" [settings]="tabSection" ></app-field-settings>
        </ng-container>
      </ng-container>
    </mat-tab>
  </mat-tab-group>
</ng-container>