export const FuseLayoutSettingsConst = [
    {
        title: 'toolbar.layout1',
        keyName: 'layout1',
        settings: {
            navbar   : {
                folded             : true,
                hidden             : false,
                position           : 'left',
                variant            : 'vertical-style-1'
            },
            toolbar  : {
                hidden               : false,
                position             : 'below-fixed'
            },
            footer   : {
                hidden               : true,
            },
            sidepanel: {
                hidden  : false,
                position: 'right'
            }
        }
    },
    {
        title: 'toolbar.layout2',
        keyName: 'layout2',
        settings: {
            navbar   : {
                folded             : false,
                hidden             : true,
                position           : 'left',
                variant            : 'vertical-style-1'
            },
            toolbar  : {
                hidden               : false,
                position             : 'below-fixed'
            },
            footer   : {
                hidden               : true,
            },
            sidepanel: {
                hidden  : false,
                position: 'right'
            }
        }
    },
    {
        title: 'toolbar.layout3',
        keyName: 'layout3',
        settings: {
            navbar   : {
                folded             : false,
                hidden             : false,
                position           : 'left',
                variant            : 'vertical-style-1'
            },
            toolbar  : {
                hidden               : false,
                position             : 'below-fixed'
            },
            footer   : {
                hidden               : true,
            },
            sidepanel: {
                hidden  : false,
                position: 'right'
            }
        }
    },
    {
        title: 'toolbar.default',
        keyName: 'default',
        settings: {
            navbar   : {
                primaryBackground  : 'fuse-navy-700',
                secondaryBackground: 'fuse-navy-900',
                folded             : true,
                hidden             : false,
                position           : 'left',
                variant            : 'vertical-style-1'
            },
            toolbar  : {
                customBackgroundColor: false,
                background           : 'fuse-white-500',
                hidden               : false,
                position             : 'above'
            },
            footer   : {
                customBackgroundColor: true,
                background           : 'fuse-navy-900',
                hidden               : true,
                position             : 'below-fixed'
            },
            sidepanel: {
                hidden  : false,
                position: 'right'
            }
        }
    }
];
