<div class="happ-table-" *ngIf="logs && logs.length">
  <h2>
    Logs
  </h2>
  <table>
    <tr>
      <td> <strong>Date</strong></td>
      <td> <strong>Messages</strong></td>
      <td> <strong>To Send</strong></td>
      <td> <strong>Sended</strong></td>
      <td> <strong>Status</strong></td>
      <td> <strong>Action Name</strong></td>
      <td> <strong>User</strong></td>
    </tr>
    <tr *ngFor="let item of logs">
      <td>{{ item.createdAt | date : "yyyy-MM-dd hh:mm" }}</td>
      <td>{{ item.message }}</td>
      <td>{{ item.toSend }}</td>
      <td>{{ item.totalSent }}</td>
      <td>{{ item.status }}</td>
      <td>{{ item.actionName }}</td>
      <td>{{ item.userEmail }}</td>
    </tr>
  </table>
</div>