import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import * as moment from 'moment';

@Component({
  selector: 'app-date-filter-field',
  templateUrl: './date-filter-field.component.html',
  styleUrls: ['./date-filter-field.component.scss']
})
export class DateFilterFieldComponent implements OnInit {

  @Input() model: string;
  @Input() fieldTitle: string;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();

  dateList = [
    {
      subHeading: 'dttable.all',
      list: [
        {
          title: 'dttable.all',
          value: '##df_all##'
        },
        {
          title: 'dttable.customValue',
          value: '##df_customValue##'
        }
      ]
    },
    {
      subHeading: 'dttable.past',
      list: [
        {
          title: 'dttable.yesterday',
          value: '##df_yesterday##'
        },
        {
          title: 'dttable.7_days',
          value: '##df_7_days##'
        },
        {
          title: 'dttable.30_days',
          value: '##df_30_days##'
        },
        {
          title: 'dttable.year',
          value: '##df_year##'
        },
        {
          title: 'dttable.last_calendar_week',
          value: '##df_last_calendar_week##'
        },
        {
          title: 'dttable.last_calendar_month',
          value: '##df_last_calendar_month##'
        },
        {
          title: 'dttable.last_calendar_year',
          value: '##df_last_calendar_year##'
        },
        {
          title: 'dttable.all_past_dates',
          value: '##df_all_past_dates##'
        }
      ]
    },
    {
      subHeading: 'dttable.present',
      list: [
        {
          title: 'dttable.today',
          value: '##df_today##'
        },
        {
          title: 'dttable.current_week',
          value: '##df_current_week##'
        },
        {
          title: 'dttable.current_month',
          value: '##df_current_month##'
        },
        {
          title: 'dttable.current_year',
          value: '##df_current_year##'
        }
      ]
    },
    {
      subHeading: 'dttable.future',
      list: [
        {
          title: 'dttable.tomorrow',
          value: '##df_tomorrow##'
        },
        {
          title: 'dttable.7_days',
          value: '##df_7_days##'
        },
        {
          title: 'dttable.30_days',
          value: '##df_30_days##'
        },
        {
          title: 'dttable.year',
          value: '##df_year##'
        },
        {
          title: 'dttable.next_calendar_week',
          value: '##df_next_calendar_week##'
        },
        {
          title: 'dttable.next_calendar_month',
          value: '##df_next_calendar_month##'
        },
        {
          title: 'dttable.next_calendar_year',
          value: '##df_next_calendar_year##'
        },
        {
          title: 'dttable.all_future_dates',
          value: '##df_all_future_dates##'
        }
      ]
    }
  ];

  constructor() { }

  ngOnInit() {
    this.model = this.model || '';
  }

  onChange(eve: any) {
    this.modelChange.next(this.model);
  }

}
