import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlatformLayoutComponent } from './platform-layout.component';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
  declarations: [PlatformLayoutComponent],
  imports: [
    CommonModule,
    FuseSharedModule
  ],
  exports: [
    PlatformLayoutComponent
  ],
  entryComponents: [
    PlatformLayoutComponent
  ]
})
export class PlatformLayoutModule { }
