<div *ngIf="type === 'popup'" style="background: #f6f6f6;" class="m-0">
  <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">
      <p style="    font-size: 20px; margin: 12px;">{{ 'ct.download_file' | translate }}
      </p>
    </span>
    <span fxFlex></span>
    <button class="mr-12" (click)="dialogRef.close(null)" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div class="w-100-p h-300 px-12" fxLayout="column">
  <h2 style="font-size: 22px;">
    {{ 'ct.file_download_heading' | translate }}
  </h2>
  <div>
    <div fxLayout="row" style="border-bottom: 1px solid lightgray;" class="py-8 my-8" *ngFor="let app of retryServers; let i=index;">
      <h3 class="m-0" style="font-size: 18px; margin-left: 8px; margin-top: -4px;"> 
        <span *ngIf="(i < (retry - 1)); else infoMsg" >{{ app.errorTitle | translate }} </span>
        <ng-template #infoMsg>{{ app.title | translate }} </ng-template>
      </h3>
      <span fxFlex></span>
      <div>
        <mat-icon class="mx-8 h-30 w-30 font-size-30 mr-20" style="color: red;" *ngIf="i < (retry - 1)" >warning</mat-icon>
        <mat-spinner class="mx-8" *ngIf="i === (retry - 1)" [diameter]="26"></mat-spinner>
      </div>
    </div>
  </div>
  <h2 *ngIf="error">
    {{ 'ct.file_not_found_contact_admin' | translate }} ( {{ (data && data.id) }} )
  </h2>
</div>

