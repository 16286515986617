<ng-container *ngIf="!loading">
  <ng-container *ngIf="statusList">
    <div fxLayout="row">
      <mat-form-field class="w-100-p" appearance="outline">
        <mat-label>Select Status</mat-label>
        <mat-select [(ngModel)]="status" >
          <mat-option *ngFor="let item of statusList" [value]="item">
            {{item.label}}({{ item.id }})
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-icon-button (click)="moveTo()" >
        <mat-icon>arrow_forward</mat-icon>
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="!statusList">
    <p style="text-align: center;">
      {{ 'ct.not_process_id' | translate }}
    </p>
  </ng-container>
</ng-container>
<ng-container *ngIf="loading">
  <div fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
  </div>
</ng-container>