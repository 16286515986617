import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDialog } from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HelpersService } from '../../services/helpers/helpers.service';
import { TemplateModalComponent } from '../../global-component/template-modal/template-modal.component';

@Component({
  selector: 'app-select-template-popup',
  templateUrl: './select-template-popup.component.html',
  styleUrls: ['./select-template-popup.component.scss']
})
export class SelectTemplatePopupComponent implements OnInit, AfterViewInit {
  ṭemplates: Array<any> = [];
  constructor(
    public dialogRef: MatDialogRef<SelectTemplatePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private translationLoader: FuseTranslationLoaderService,
    private helperService: HelpersService
  ) { }

  ngOnInit() {
    this.helperService.getTrans('control-template')
      .then((data2) => {
        this.translationLoader.loadTranslationsV2(data2);
      }).catch(err => {
        console.info(err);
      });
  }


  ngAfterViewInit(): void {
    this.ṭemplates = this.data['ṭemplateList'] || [];
  }

  closeDialog(data): void {
    if (data) {
    this.dialogRef.close(data);
    }
    else {
    this.dialogRef.close();
    }
  }

  newTemplate(template) {
    let dialogRef;

    if (template) {
      this.getTemplate(template['_id'])
      .then(data => {
        dialogRef = this.dialog.open(TemplateModalComponent, {
          width: '100%',
          height: '100vh',
          data: { data: data, operation: 'edit', formVar: [], selectedFormName: '' },
          panelClass: 'happ-form-builder-dialog'
        });
          }).catch(err => {
            console.warn(err);
          });
      } else {
        
        dialogRef = this.dialog.open(TemplateModalComponent, {
          width: '100%',
          height: '100vh',
          data: { data: null, operation: 'add', formVar: [], selectedFormName: '' },
          panelClass: 'happ-form-builder-dialog'
        });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.snackBar.open('Updated', 'Okay', {
            duration: 2000,
          });
        } else {
          this.snackBar.open('Error occurred', 'Okay', {
            duration: 2000,
          });
        }
      });
  
      }
    }

    getTemplate(templateId: string): Promise<any> {
      return this.helperService.getTemplate(templateId);
    }

    generateDoc(ele: any) {
      this.closeDialog({ _id: ele._id, showAsMainDocument: ele.showAsMainDocument});
    }
}
