import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
var MailingListLoadingPopupComponent = /** @class */ (function () {
    function MailingListLoadingPopupComponent(dialogRef, data, helperService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.helperService = helperService;
    }
    MailingListLoadingPopupComponent.prototype.ngOnInit = function () {
        console.log("item to execute");
        this.saveData();
    };
    MailingListLoadingPopupComponent.prototype.saveData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.loading = true;
                        return [4 /*yield*/, this.helperService.postRequest("api/reg-execute/" + this.data.sourceRegId + "/" + this.data.recId + "?actionId=" + this.data.action.actionId, this.data)];
                    case 1:
                        _a.sent();
                        this.loading = false;
                        this.dialogRef.close();
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        this.loading = false;
                        this.dialogRef.close();
                        alert(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return MailingListLoadingPopupComponent;
}());
export { MailingListLoadingPopupComponent };
