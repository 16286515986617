import { NgModule } from '@angular/core';
import { WsAppsSettingsComponent } from './ws-apps-settings.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { AfterPluginInstallModule } from '../plugin/after-plugin-install/after-plugin-install.module';
import { OfficeGlobalVarModule } from '../office-global-var/office-global-var.module';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { StepActionModule } from '../settings/reg-settings/setting-nodes/academy-course/step-actions/step-actions.module';
import { ProcessSettingsListService } from '../processes-settings/process-settings-list/process-settings-list.service';
import { DateFilterFieldModule } from '../../components/date-filter-field/date-filter-field.module';
import { GeneralTabComponent } from './ws-apps-settings-components/general-tab/general-tab.component';
import { CardActionsComponent } from './ws-apps-settings-components/card-actions/card-actions.component';
import { CardActionsListComponent } from './ws-apps-settings-components/card-actions/card-actions-list/card-actions-list.component';
import { AddCardActionComponent } from './ws-apps-settings-components/card-actions/add-card-action/add-card-action.component';
import { SettingItemViewModule } from '../../components/setting-item-view/setting-item-view.module';
import { JsonSettingsModule } from '../../components/json-settings/json-settings.module';
import { DescriptionEditModule } from '../../components/description-edit/description-edit.module';
import { SelectFormPopupModule } from '../../components/select-form-popup/select-form-popup.module';
import { HardFilterSetupModule } from '../../components/hard-filter-setup/hard-filter-setup.module';
import { SelectWorkspaceAppModule } from '../../components/select-workspace-app/select-workspace-app.module';
import { DtTableAdvanceFilterModule } from '../../global-component/drag-and-drop/dt-table-advance-filter/dt-table-advance-filter.module';
import { DataTableColumnSettingsModule } from '../../components/data-table-column-settings/data-table-column-settings.module';
import { WsTemplateSelectionModule } from '../../components/ws-template-selection/ws-template-selection.module';
import { RegisterSelectionViewModule } from '../../components/register-selection-view/register-selection-view.module';
import { AddBuilderModule } from '../app-builder/app-builder.module';

@NgModule({
    imports     : [
        CommonModule,
        FuseSharedModule,
        AfterPluginInstallModule,
        OfficeGlobalVarModule,
        NgxDatatableModule,
        StepActionModule,
        DateFilterFieldModule,
        SettingItemViewModule,
        JsonSettingsModule,
        DescriptionEditModule,
        SelectFormPopupModule,
        HardFilterSetupModule,
        SelectWorkspaceAppModule,
        DtTableAdvanceFilterModule,
        DataTableColumnSettingsModule,
        WsTemplateSelectionModule,
        RegisterSelectionViewModule
    ],
    declarations: [
        WsAppsSettingsComponent,
        GeneralTabComponent,
        CardActionsComponent,
        CardActionsListComponent,
        AddCardActionComponent
    ],
    providers   : [
        ProcessSettingsListService
    ],
    exports: [
        WsAppsSettingsComponent
    ],
    entryComponents: [
        WsAppsSettingsComponent,
        AddCardActionComponent,
        CardActionsComponent
    ]
})
export class WSAppsSettingsModule
{
}
