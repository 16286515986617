// tslint:disable:indent
import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IRegister } from '../../interfaces/register.interface';
import * as _ from 'lodash';

export interface WorkspaceState { 
	workspaceData: IRegister;
	selectedStep: number;
	isWorkspaceAdmin: boolean;
}

export function createInitialState(): WorkspaceState {
	const selectedStep = localStorage.getItem('currentStep') || '0';
	return {
		workspaceData: null as any,
		selectedStep: (parseInt(selectedStep, 10) === NaN) ? 0 : parseInt(selectedStep, 10),
		isWorkspaceAdmin: false
	} as any;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'workspace', resettable: true })
export class WorkspaceStore extends Store<WorkspaceState> {

	constructor() {
		super(createInitialState());
	}

}
