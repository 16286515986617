import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { SUPER_ADMIN_EMAILS } from '../consts/superAdminConfig.const';

@Injectable()
export class SuperAdminRouteGuard implements CanActivate {

  constructor(
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (JSON.parse(localStorage.getItem('ngStorage-token')) &&
          JSON.parse(localStorage.getItem('ngStorage-token'))['username'] && 
          SUPER_ADMIN_EMAILS.includes(JSON.parse(localStorage.getItem('ngStorage-token'))['username'])
      ) {
        return true;
      } else {
        this.router.navigate(['/no-access/super-admin']);
      }
      return false;
  }
}
