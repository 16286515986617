/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./select-template.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/setting-item-view/setting-item-view.component.ngfactory";
import * as i3 from "../../../components/setting-item-view/setting-item-view.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "@angular/common";
import * as i11 from "./select-template.component";
import * as i12 from "@angular/material/dialog";
import * as i13 from "../../../../../../@fuse/services/translation-loader.service";
var styles_SelectTemplateComponent = [i0.styles];
var RenderType_SelectTemplateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectTemplateComponent, data: {} });
export { RenderType_SelectTemplateComponent as RenderType_SelectTemplateComponent };
function View_SelectTemplateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "mb-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "app-setting-item-view", [], null, null, null, i2.View_SettingItemViewComponent_0, i2.RenderType_SettingItemViewComponent)), i1.ɵdid(2, 114688, null, 0, i3.SettingItemViewComponent, [], { heading: [0, "heading"], description: [1, "description"], value: [2, "value"] }, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, 0, 3, "button", [["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectTemplate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(7, 0, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.field.title)); var currVal_1 = i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 4).transform(_co.field.description)); var currVal_2 = _co.selectedValue; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_3 = (i1.ɵnov(_v, 6).disabled || null); var currVal_4 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("ct.select_template")); _ck(_v, 7, 0, currVal_5); }); }
export function View_SelectTemplateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SelectTemplateComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.field.hide; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SelectTemplateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-select-template", [], null, null, null, View_SelectTemplateComponent_0, RenderType_SelectTemplateComponent)), i1.ɵdid(1, 114688, null, 0, i11.SelectTemplateComponent, [i12.MatDialog, i13.FuseTranslationLoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectTemplateComponentNgFactory = i1.ɵccf("app-select-template", i11.SelectTemplateComponent, View_SelectTemplateComponent_Host_0, { field: "field", templateList: "templateList", translateData: "translateData", model: "model" }, { onFieldValueUpdated: "onFieldValueUpdated", modelChange: "modelChange" }, []);
export { SelectTemplateComponentNgFactory as SelectTemplateComponentNgFactory };
