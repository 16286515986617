import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';
import { SmtpFormComponent } from '../../../smtp-settings/smtp-form/smtp-form.component';
import { SmtpListComponent } from '../../../smtp-settings/smtp-list/smtp-list.component';
import { MailingFooterListComponent } from '../mailing-footer-list/mailing-footer-list.component';
import { MailingFooterComponent } from '../mailing-footer/mailing-footer.component';
import { MailingListLoadingPopupComponent } from '../mailing-list-loading-popup/mailing-list-loading-popup.component';

@Component({
  selector: 'app-mailing-list-action-popup',
  templateUrl: './mailing-list-action-popup.component.html',
  styleUrls: ['./mailing-list-action-popup.component.scss']
})
export class MailingListActionPopupComponent implements OnInit {

  files: any[] = [];
  formData: any;
  loading: boolean;
  smtpList;
  smtpServerList;
  smtpEmailList;
  regList = [];
  officeId: string;
  sharedServiceData: any;
  introJson = [
    {
      element: '#mailing_list_type',
      intro: 'Select type of list',
      position: 'bottom'
    },
    {
      element: '#mailing_list_save',
      intro: 'Save content',
      position: 'bottom'
    },
    {
      element: '#mailing_list_cancel',
      intro: 'Cancel save',
      position: 'bottom'
    },
  ]
  footerList: any;
  logs: any = [];


  constructor(
    public dialogRef: MatDialogRef<MailingListActionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private helperService: HelpersService,
    private _officeHelper: OfficeHelpersService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.formData = this.data.formData || {};
    this.files = this.formData.files || [];
    this.getLocalOfficeFilter();
    this.getRegisterList();
    this.getFooterList();
    this.getLogs();
    this.helperService.setIntroJson(this.introJson);
    setTimeout(() => {
      this.helperService.startIntro();
    }, 2000);
  }

  async getFooterList() {
    this.footerList = await this.helperService.getTemplateList(`?type=footer`);
  }

  async getLogs() {
    this.logs = await this.helperService.getRequest(`api/mailing-list/logs/${this.data.recId}?actionId=${this.formData.actionId}`);
  }

  async getRegisterList() {
    this.regList = await this.helperService.getRegisterList(`?allowMailingList=true `);
  }

  async getSharedService() {
    const temp = await this.helperService.getRequest(`api/external-page/${this.officeId}.pages.pinbox24.com?&route=our-services`);
    this.sharedServiceData = temp.providerServicesLists || [];
  }

  close() {
    this.dialogRef.close();
  }

  uploadFile(file) {
    const files = file.files;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++ ) {
      formData.append('uplFile', files[i], files[i].name);
    }
    this.loading = true;
    this.helperService.uploadFiles(`&regId=${this.data.regId}&recId=${this.data.recId}`, formData)
    .then((data) => {
      this.loading = false;
      console.log("data", data);
      this.files = this.files || [];
      this.files.push(data);
    }).catch(err => {
        console.info(err);
        this.loading = false;
    });
  }

  validate() {
    const invalidFields = [];
    switch (this.data.action) {
      case "sendMailWithRecordWithSharedService":
        if (!this.formData.sharedServiceId) {
          invalidFields.push("sharedServiceId");
        }
      case "sendMailWithRecord":
        if (!this.formData.regId) {
          invalidFields.push("regId");
        }
        if (!this.formData.websiteHtml) {
          invalidFields.push("websiteHtml");
        }
        default:
          if (!this.formData.name) {
            invalidFields.push("name");
          } 
          if (!this.formData.subject) {
            invalidFields.push("subject");
          } 
          if (!this.formData.smtpServer) {
            invalidFields.push("smtpServer");
          } 
          if (!this.formData.from) {
            invalidFields.push("from");
          } 
    }
    return invalidFields;
  }

  save() {
    const temp = this.validate();
    if (temp.length) {
      return this.helperService.showInfo(`Following fields are required - ${temp.join(", ")}`, "Error", "error");
    }
    this.dialogRef.close({...this.formData, files: this.files});
  }

  async validateBySendEmail() {
    try {
      await this.helperService.getRequest(`api/mailing-list-validate/${this.data.recId}/${this.formData.actionId}`);
      this.helperService.showInfo("Smtp config is valid");
    } catch (error) {
      alert(error);
    }
  }

  deleteItem(i) {
    this.files.splice(i, 1);
  }

  async getLocalOfficeFilter() {
    try {
      const temp = await this.helperService.getRequest("api/office-smtp");
      this.smtpEmailList = temp;
      this.smtpServerList = temp.filter((ele: any) => (ele && ele.type === 'default'));
    } catch (error) {
      alert(error);
    }
  }

  openSMTPSettings() {
    const temp = this.dialog.open(SmtpListComponent, {
      disableClose: true,
      width: '100%',
      height: '100%',
      panelClass: 'happ-form-builder-dialog',
      data: {
        smtpServerList: this.smtpServerList
      }
    });
    
    temp.afterClosed()
      .subscribe((response) => {
        this.getLocalOfficeFilter();
      });
  }

  openSMTPFormSettings() {
    if (!this.formData.smtpServer) {
      return;
    }
    const temp = this.dialog.open(SmtpFormComponent, {
      disableClose: true,
      width: '100%',
      height: '100%',
      panelClass: 'happ-form-builder-dialog',
      data:{
        type: "smtpEmailForm",
        smtpServer: this.formData.smtpServer
      }
    });
    
    temp.afterClosed()
      .subscribe((response) => {
        this.getLocalOfficeFilter();
      });
  }

  openFooterSettings() {
    const temp = this.dialog.open(MailingFooterListComponent, {
      disableClose: true,
      width: '100%',
      height: '100%',
      panelClass: 'happ-form-builder-dialog',
    });
    
    temp.afterClosed()
      .subscribe((response) => {
        this.getFooterList();
      });
  }

  
  async execute(item) {
    try {
      console.log("item to execute")
      const temp = this.validate();
      if (temp.length) {
        return this.helperService.showInfo(`Following fields are required - ${temp.join(", ")}`, "Error", "error");
      }
      this.helperService.openConfirmPopUp("Are you sure you want to send email to this mailing list?")
      .subscribe((data) => {
        // if (data) {
          const temp = this.dialog.open(MailingListLoadingPopupComponent, {
            disableClose: true,
            width: '60%',
            height: '60%',
            panelClass: 'happ-form-builder-dialog',
            data: { 
              key: "recList", 
              action: item,
              sourceRegId: this.data.regId,
              recId: this.data.recId,
              actionIndex: this.data.index
            }
          });
          temp.afterClosed()
            .subscribe((response) => {
              this.getLogs();
            });
        // }
      });
    } catch (error) {
      alert(error);
    }
  }


  onServerChange(eve) {
    const temp = this.smtpServerList.find((ele) => (ele && ele._id === this.formData.smtpServer));
    if (temp) {
      this.formData.from = temp.username;
    }
  }

}
