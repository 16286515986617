import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { navigation, FuseNavigationMenuService } from 'app/navigation/navigation';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { AuthService } from 'app/main/content/pages/authentication/auth.service';
import { MatDialog } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { ProfileHelperService } from 'app/main/content/services/profile-helper/profile-helper.service';
import { SystemInfoComponent } from 'app/main/content/pages/system-info/system-info.component';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { SocketService } from 'app/main/content/services/socket/socket.service';
import { InvitationListService } from 'app/main/content/pages/invitation-list/invitation-list.service';
import { PlatformIssueReporterComponent } from 'app/main/content/global-component/platform-issue-reporter/platform-issue-reporter.component';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';
import { ADMIN_EMAILS } from '../../../main/content/consts/adminConfig.const';
import { FuseLayoutSettingsConst } from '../../../main/content/consts/fuseLayoutSettings.const';
import { AuthQuery } from 'app/main/content/state/authState/auth.query';
import { AuthStore } from 'app/main/content/state/authState/auth.store';
import { OfficeStore } from 'app/main/content/state/officeState/office.store';
import { OfficeQuery } from 'app/main/content/state/officeState/office.query';
import { WorkspaceStore } from 'app/main/content/state/workspaceState/workspace.store';
import { WorkspaceQuery } from 'app/main/content/state/workspaceState/workspace.query';
import { PlatformSettings } from './platform-settings.const';
import { environment } from 'environments/environment';
import { PlatformLayoutComponent } from 'app/main/content/components/platform-layout/platform-layout.component';
import { OfficeListPopupComponent } from 'app/main/content/apps/office-list-popup/office-list-popup.component';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import * as introJs from 'intro.js/intro.js';
var ToolbarComponent = /** @class */ (function () {
    function ToolbarComponent(router, authService, navigationService, dialog, helperService, profileHelperService, _fuseConfigService, _fuseSidebarService, _translateService, translationLoader, socketService, _invitationService, _officeHelper, _fuseNavigationService, authQuery, authStore, officeStore, workspaceStore, officeQuery, workspaceQuery, _changeDetectorRef) {
        this.router = router;
        this.authService = authService;
        this.navigationService = navigationService;
        this.dialog = dialog;
        this.helperService = helperService;
        this.profileHelperService = profileHelperService;
        this._fuseConfigService = _fuseConfigService;
        this._fuseSidebarService = _fuseSidebarService;
        this._translateService = _translateService;
        this.translationLoader = translationLoader;
        this.socketService = socketService;
        this._invitationService = _invitationService;
        this._officeHelper = _officeHelper;
        this._fuseNavigationService = _fuseNavigationService;
        this.authQuery = authQuery;
        this.authStore = authStore;
        this.officeStore = officeStore;
        this.workspaceStore = workspaceStore;
        this.officeQuery = officeQuery;
        this.workspaceQuery = workspaceQuery;
        this._changeDetectorRef = _changeDetectorRef;
        this.introJS = introJs();
        this.unsubscribe = new Subject();
        this.isLoggedIn = false;
        this.hideToolbar = false;
        this.isOfficeView = true;
        this.loginByPassUrls = [
            '/forgot-password',
            '/cp-login',
            '/register',
            '/login'
        ];
        this.loginByPassUrlContains = [
            '/userInvitation',
            '/mail-detail',
            '/checkout',
            '/provider-client',
            '/set-password',
            '/changepassword'
        ];
        this.layouts = FuseLayoutSettingsConst || [];
        this.defaultWorkspaceConfig = {
            showWorkspace: true,
            workspaceList: [],
            selectedWorkspace: {}
        };
        this.isProvider = false;
        this.languages = [
            {
                'id': 'en',
                'title': 'English',
                'flag': 'us'
            },
            {
                'id': 'pl',
                'title': 'Polish',
                'flag': 'pl'
            }
        ];
        this.invitationCount = 0;
        this.ADMIN_EMAILS = ADMIN_EMAILS;
        this.officeSettings = [];
        this.home = '/dashboard';
        this.toolBarShortcuts = [];
        this.changedOfficeName = null;
        this.selectedWorkspaceName = '';
        this.testDate = Date.now();
        this.officeCredit = 0;
        this.checkLoginAndToken();
        this.officeSettings = JSON.parse(JSON.stringify(PlatformSettings));
        if (this.getLocalStorageToken()) {
            this.getOfficeList();
        }
        this.processInitOperations()
            .then(function () {
        }).catch(function (err) {
            console.info(err);
        });
    }
    ToolbarComponent.prototype.getToolbarShortcuts = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var profile, currentUser, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.profileHelperService.getLocalProfileData()];
                    case 1:
                        profile = _a.sent();
                        return [4 /*yield*/, this._officeHelper.getOfficeUser(profile.profileId)];
                    case 2:
                        currentUser = _a.sent();
                        if (currentUser) {
                            this.toolBarShortcuts = currentUser['toolbarShortcut'] || [];
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        throw error_1;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ToolbarComponent.prototype.getSelectedLanguage = function () {
        var _this = this;
        try {
            this.selectedLanguage = this.languages[0];
            if (localStorage.getItem('selectedLanguage')) {
                this.languages.forEach(function (lang) {
                    if (lang.id === localStorage.getItem('selectedLanguage')) {
                        _this.selectedLanguage = lang;
                    }
                });
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    ToolbarComponent.prototype.isByPassUrl = function (url) {
        console.log("222222");
        var temp = false;
        this.loginByPassUrls.forEach(function (ele) {
            if (ele === url) {
                temp = true;
            }
        });
        this.loginByPassUrlContains.forEach(function (ele) {
            if (url.includes(ele)) {
                temp = true;
            }
        });
        console.log("is by pass", temp);
        return temp;
    };
    ToolbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.introJS.setOptions({
            steps: [
                {
                    element: '#step1',
                    intro: 'Welcome to your new app!',
                    position: 'bottom'
                },
                {
                    element: '#step2',
                    intro: "Ok, wasn't that fun?",
                    position: 'right'
                },
                {
                    element: '[aria-label="open_office_dropdown"]',
                    intro: "let's keep going",
                    position: 'top'
                },
                {
                    element: '#step4',
                    intro: 'More features, more fun.',
                    position: 'right'
                }
            ],
            showProgress: true
        });
        // setTimeout(() => {
        //     this.introJS.start();
        // }, 1500);
        this.navigationService.$virtualOrgValue.subscribe(function (changed) {
            var tempVal = localStorage.getItem("lastSelectedOrg");
            tempVal = JSON.parse(tempVal);
            _this.virtualOrgObj = tempVal;
        });
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (ele) {
            if (ele.url.includes("/cp") && window.location.pathname !== '/cp-login') {
                // switch theme.
                _this.isOfficeView = false;
                _this._fuseNavigationService.unregister('main');
                if (window.location.pathname.includes("/cp/specialist") && window.location.pathname !== '/cp-login') {
                    _this._fuseNavigationService.register('main', _this.navigationService.SPECIALIST_PANEL_NAV);
                }
                else if (ele.url.includes("/cp") && window.location.pathname !== '/cp-login') {
                    _this._fuseNavigationService.register('main', _this.navigationService.CLIENT_PANEL_NAV);
                }
                _this._fuseNavigationService.setCurrentNavigation('main');
                var temp = JSON.parse(JSON.stringify(_this.navigationService.CLIENT_PANEL_THEME));
                // temp.logout = this.logOut;
                _this._fuseConfigService.setConfig(temp);
                _this.profileHelperService.getLocalProfileData()
                    .then(function (data) {
                    if (data.privateAreaOfficeId) {
                        _this._officeHelper.getOfficeData(data.privateAreaOfficeId)
                            .then(function (officeDoc) {
                            localStorage.setItem('ngStorage-selectedOffice', JSON.stringify(officeDoc));
                            localStorage.setItem('selectedOfficeId', JSON.stringify(officeDoc._id));
                        }).catch(function (err) {
                            console.error(err);
                        });
                    }
                }).catch(function (err) {
                    console.error(err);
                });
                // this._fuseConfigService.config = temp;
            }
            else if (window.location.pathname.includes('/mail-detail')) {
                // skip
            }
            else {
                if (ele.url.startsWith("/workspace/")) {
                    if (_this.officeData.projects && _this.officeData.projects.multiOrganizationOffice) {
                        setTimeout(function () {
                            var flatNavigation = _this._fuseNavigationService.getFlatNavigation(_this._fuseNavigationService.getCurrentNavigation())
                                .map(function (ele) { return (ele.url); });
                            if (ele.url.split("/").length === 3) {
                                ele.url = ele.url + "/dashboard";
                            }
                            var t3 = _.some(flatNavigation, function (ele2) { return (ele.url.startsWith(ele2)); });
                            if (!t3) {
                                _this.router.navigateByUrl("/offices/" + (_this.officeData['_id']) + "/dashboard");
                                return;
                            }
                        }, 8000);
                    }
                }
                if (ele.url.startsWith("/workspace/") && _this.officeData.projects && _this.officeData.projects.multiOrganizationOffice) {
                    if (!localStorage.getItem("selectedVirtualOrgId")) {
                        _this.router.navigateByUrl("/offices/" + (_this.officeData['_id']) + "/organizations");
                    }
                }
                else if (!_this.isOfficeView) {
                    _this.isOfficeView = true;
                    if (_this.officeData._id) {
                        _this.changeOffice(_this.officeData._id);
                    }
                }
                else {
                    if (ele.url === "/login" && _this.officeData['_id']) {
                        // this.router.navigateByUrl(`/offices/${(this.officeData['_id'])}/dashboard`)
                        _this.logOut();
                    }
                }
            }
        });
        this.authQuery.email.pipe(takeUntil(this.unsubscribe)).subscribe(function (email) {
            console.log("loggin in = ", email);
            _this.username = email;
        });
        this.authQuery.officeName.pipe(takeUntil(this.unsubscribe)).subscribe(function (officeName) {
            _this.selectedOfficeName = officeName;
            _this.checkWorkspaceAndOfficeRoutes();
            _this.getInvitationCount();
        });
        this.authQuery.isAdmin.pipe(takeUntil(this.unsubscribe)).subscribe(function (isAdmin) {
            _this.navigationService.isAdmin = isAdmin;
        });
        this.authQuery.invitationCount.pipe(takeUntil(this.unsubscribe)).subscribe(function (invitationCount) {
            _this.invitationCount = invitationCount;
        });
        if (this.authService.isLoggedIn()) {
            this.socketService.reConnectSocket();
        }
        this.authQuery.isAuthenticated.pipe(takeUntil(this.unsubscribe)).subscribe(function (isAuthenticated) {
            _this.isLoggedIn = isAuthenticated;
        });
        this.authQuery.redirectUrl.pipe(takeUntil(this.unsubscribe)).subscribe(function (url) {
            // (console as any).routeLog("redirect url", url);
            if (url) {
                _this.router.navigate([url]);
            }
        });
        this.officeQuery.officeDoc.pipe(takeUntil(this.unsubscribe)).subscribe(function (officeData) {
            if (officeData.type === "privateArea") {
                return;
            }
            _this.officeData = JSON.parse(window.localStorage.getItem("ngStorage-selectedOffice"));
            _this.isProvider = false;
            if (_this.officeData && _this.officeData.sharedServiceSettings && _this.officeData.sharedServiceSettings.status === "active") {
                _this.isProvider = true;
            }
            console.log("MOVING - BEFORE = ", _this.officeData);
            if (_this.officeData.projects && _this.officeData.projects.multiOrganizationOffice) {
                if (!localStorage.getItem("selectedVirtualOrgId")) {
                    _this.router.navigateByUrl("/offices/" + (_this.officeData['_id']) + "/organizations");
                }
            }
            _this.selectedOfficeName = officeData.name;
            _this.checkWorkspaceAndOfficeRoutes();
            _this.getOfficeCredit();
            if (!window.location.pathname.startsWith("/workspace/")) {
                _this._changeDetectorRef.detectChanges();
                _this.navigationService.resetValue();
                _this.selectedWorkspaceName = 'toolbar.dashboard';
                _this.navigationService.refreshMenuItems();
            }
        });
        this.workspaceQuery.workspaceDoc.pipe(takeUntil(this.unsubscribe)).subscribe(function (workspaceData) {
            _this._changeDetectorRef.detectChanges();
            if (workspaceData && _this.officeData._id === workspaceData.officeId) {
                _this.selectedWorkspaceName = (workspaceData && workspaceData.name) || 'toolbar.dashboard';
            }
        });
        this.getSelectedLanguage();
        window.onfocus = function () {
            _this._officeHelper.getLocalOfficeData().then(function (officeData) {
                if (!window.location.pathname.includes("/cp/") && _this.selectedOfficeName !== officeData.name) {
                    _this.changedOfficeName = officeData.name;
                    _this._fuseConfigService.config = {
                        layout: {
                            navbar: {
                                hidden: true,
                                folded: true
                            },
                            toolbar: {
                                hidden: false,
                                position: 'above'
                            }
                        }
                    };
                }
                else {
                    _this.changedOfficeName = null;
                }
            }).catch(function (err) {
                _this.changedOfficeName = null;
            });
        };
        this._unsubscribeAll = new Subject();
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (settings) {
            _this.horizontalNavbar = settings.layout.navbar.position === 'top';
            _this.rightNavbar = settings.layout.navbar.position === 'right';
            _this.hiddenNavbar = settings.layout.navbar.hidden === true;
        });
        var langId = localStorage.getItem('selectedLanguage');
        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { 'id': langId || this._translateService.currentLang });
        this.version = environment.version;
        this.handleLoaderOnNavigation();
    };
    ToolbarComponent.prototype.changeLayout = function (settings) {
        this._fuseConfigService.setConfig({
            layout: settings
        });
        this.updateOfficeUserWithLayoutSettings();
    };
    ToolbarComponent.prototype.getInvitationCount = function () {
        var _this = this;
        if (this.isLoggedIn) {
            this.helperService.getRequest('api/offices/user-invitation/count')
                .then(function (data) {
                _this.invitationCount = data;
            }).catch(function (err) {
                console.log(err);
            });
        }
    };
    ToolbarComponent.prototype.updateOfficeUserWithLayoutSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var profile_1, updated_1, officeData, err_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, this.profileHelperService.getLocalProfileData()];
                    case 1:
                        profile_1 = _a.sent();
                        updated_1 = false;
                        return [4 /*yield*/, this._officeHelper.getLocalOfficeData()];
                    case 2:
                        officeData = _a.sent();
                        officeData.users = officeData.users || [];
                        officeData.users.forEach(function (ele) {
                            if (profile_1 && profile_1.profileId && ele && ele.id && ele.id === profile_1.profileId) {
                                ele.aotFuseSetting = _this._fuseConfigService.configValue;
                                updated_1 = true;
                            }
                        });
                        if (!updated_1) return [3 /*break*/, 4];
                        return [4 /*yield*/, this._officeHelper.updateOfficeDoc({ users: officeData.users })];
                    case 3:
                        _a.sent();
                        this.helperService.showInfo('info_and_errors.layout_updated_and_saved');
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_1 = _a.sent();
                        console.info(err_1);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ToolbarComponent.prototype.moveToDashboard = function () {
        if (this.officeData && this.officeData.type !== "privateArea") {
            this.router.navigate(["/offices/" + this.officeData._id + "/dashboard"]);
        }
    };
    ToolbarComponent.prototype.moveTo = function (setting) {
        if (setting && setting.fuse2Url) {
            var url = setting.fuse2Url.replace(':officeId', this.officeData._id);
            this.router.navigate([url]);
        }
    };
    /**
     * On destroy
     */
    ToolbarComponent.prototype.ngOnDestroy = function () {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this.unsubscribe.next();
        this.unsubscribe.complete();
    };
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Toggle sidebar open
     *
     * @param key
     */
    ToolbarComponent.prototype.toggleSidebarOpen = function (key) {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    };
    ToolbarComponent.prototype.logOut = function () {
        this.username = undefined;
        this.authService.logOut();
        this.authStore.reset();
        this.officeStore.reset();
        this.workspaceStore.reset();
        this.selectedWorkspaceName = '';
        this.selectedOfficeName = '';
        this.socketService.disconnectSocket();
        console.log("11111");
        this.router.navigate(['/login']);
    };
    ToolbarComponent.prototype.addIssue = function (type) {
        this.dialog.open(PlatformIssueReporterComponent, {
            width: 'max-content',
            maxWidth: '80%',
            height: 'max-content',
            restoreFocus: false,
            panelClass: 'happ-form-builder-dialog',
            data: { reporterType: type }
        });
    };
    ToolbarComponent.prototype.changeOffice = function (office) {
        var _this = this;
        if (office) {
            this._officeHelper.loadNewOffice(office['_id'] || office['officeId'] || office)
                .then(function (data) {
                console.log("Moving = officeDoc", data);
                _this.virtualOrgObj = null;
                if (data['_id']) {
                    console.log("organizations = ");
                    _this.authStore.update({ officeId: (data['_id']), officeName: data.name, redirectUrl: "/offices/" + (data['_id']) + "/dashboard" });
                    console.log("moving before 22");
                    if (data.projects && data.projects.multiOrganizationOffice) {
                        _this.router.navigateByUrl("/offices/" + (data['_id']) + "/organizations");
                    }
                    else {
                        _this.router.navigateByUrl("/offices/" + (data['_id']) + "/dashboard");
                    }
                    _this.officeStore.reset();
                    _this.officeStore.update(data);
                    _this.authService.onOfficeChange.next(data['_id']);
                }
            })
                .catch(function (err) {
                console.info(err);
            });
        }
    };
    /**
     * Set the language
     *
     * @param lang
     */
    ToolbarComponent.prototype.setLanguage = function (lang) {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;
        // Use the selected language for translations
        this._translateService.use(lang.id);
        this._translateService.setDefaultLang(lang.id);
        localStorage.setItem("selectedLanguage", lang.id);
        this.updateProfileForLang(lang);
    };
    ToolbarComponent.prototype.updateProfileForLang = function (lang) {
        var _this = this;
        this.helperService.updateProfileData({ defaultLang: lang.id })
            .catch(function (err) {
            _this.helperService.openSnackBar(err);
        });
    };
    ToolbarComponent.prototype.getOfficeList = function () {
        if (JSON.parse(localStorage.getItem('ngStorage-profile')) && JSON.parse(localStorage.getItem('ngStorage-profile'))['offices']) {
            this.officeList = JSON.parse(localStorage.getItem('ngStorage-profile'))['offices'];
        }
        else {
            this.error = 'some error occured';
        }
    };
    ToolbarComponent.prototype.systemInfo = function () {
        this.dialog.open(SystemInfoComponent, {
            width: '60%',
            height: '60%',
            panelClass: 'happ-form-builder-dialog',
            data: {}
        });
    };
    /**
    * Toggle sidebar folded status
    */
    ToolbarComponent.prototype.toggleSidebarFolded = function () {
        this._fuseSidebarService.getSidebar('navbar').open();
    };
    ToolbarComponent.prototype.handleWorkspaceMenuData = function (data) {
        if (data === void 0) { data = {}; }
        try {
            if (data && data.type) {
                {
                    this.defaultWorkspaceConfig = this.defaultWorkspaceConfig || this.navigationService.defaultWorkspaceConfig || {};
                    switch (data.type) {
                        case 'workspace':
                            console.log("WorkspaceDebug 22222");
                            this.selectedWorkspaceName = (data.selectedWorkspace && data.selectedWorkspace.name) || 'toolbar.dashboard';
                            break;
                        case 'workspaceList':
                            this.defaultWorkspaceConfig.workspaceList = data.workspaceList;
                            break;
                        case 'isVisible':
                            this.defaultWorkspaceConfig.showWorkspace = data.showWorkspace;
                            break;
                    }
                }
            }
        }
        catch (error) {
            throw error;
        }
    };
    ToolbarComponent.prototype.processInitOperations = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, loc, _d, _e, error_2;
            var _this = this;
            return tslib_1.__generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _f.trys.push([0, 3, , 4]);
                        // get all active languages from backend
                        _a = this;
                        _c = (_b = this.helperService).formatTheLanguageArray;
                        return [4 /*yield*/, this.helperService.getActiveLangs()];
                    case 1:
                        // get all active languages from backend
                        _a.languages = _c.apply(_b, [_f.sent()]);
                        // init default workspace config
                        this.defaultWorkspaceConfig = this.navigationService.defaultWorkspaceConfig || this.defaultWorkspaceConfig;
                        // handle change in workspace and list of workspace
                        this.navigationService.$workspaceConfig.subscribe(function (data) { _this.handleWorkspaceMenuData(data); });
                        loc = window.location.pathname;
                        this.navigation = navigation;
                        // Set the private defaults
                        this._unsubscribeAll = new Subject();
                        _e = (_d = this.translationLoader).loadTranslationsV2;
                        return [4 /*yield*/, this.helperService.getTrans('toolbar')];
                    case 2:
                        _e.apply(_d, [_f.sent()]);
                        this._invitationService.invitationCountChanged.subscribe(function (data) {
                            _this.getInvitationCount();
                            if (data) {
                                _this.invitationCount = data;
                            }
                        });
                        this.socketService.inviationNotify().subscribe(function (data) {
                            _this.invitationCount = (data && data.inviatationCount) || 0;
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _f.sent();
                        throw error_2;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ToolbarComponent.prototype.handleLoaderOnNavigation = function () {
        var _this = this;
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationStart) {
                _this.showLoadingBar = true;
            }
            if (event instanceof NavigationEnd) {
                if (event.url) {
                    _this.currentUrl = event.url;
                }
                _this.checkWorkspaceAndOfficeRoutes();
                _this.showLoadingBar = false;
            }
        }, function (err) {
            _this.showLoadingBar = false;
        }, function () {
            _this.showLoadingBar = false;
        });
    };
    ToolbarComponent.prototype.openLayoutPopup = function () {
        var _this = this;
        var temp = this.dialog.open(PlatformLayoutComponent, {
            width: 'max-content',
            maxWidth: '80%',
            height: 'max-content',
            restoreFocus: false,
            panelClass: 'happ-form-builder-dialog'
        });
        temp.afterClosed()
            .subscribe(function (response) {
            if (response) {
                switch (response) {
                    case 'no_menu_layout':
                        _this.changeLayout(_this.layouts[1].settings);
                        break;
                    case 'small_icon_layout':
                        _this.changeLayout(_this.layouts[3].settings);
                        break;
                    case 'icon_with_text_layout':
                        _this.changeLayout(_this.layouts[2].settings);
                        break;
                }
            }
        });
    };
    ToolbarComponent.prototype.checkWorkspaceAndOfficeRoutes = function () {
        this.currentUrl = this.currentUrl || window.location.pathname;
        if (this.officeData && (this.currentUrl && (!this.currentUrl.startsWith('/workspace/') && !(this.currentUrl === "/offices/" + this.officeData._id + "/dashboard")))) {
            // this.navigationService.resetValue();
            // this.selectedWorkspaceName = 'toolbar.out_of_workspace_routes';
        }
        if (this.officeData && this.currentUrl && (this.currentUrl === "/offices/" + this.officeData._id + "/dashboard" || this.currentUrl === "/offices/" + this.officeData._id + "/organizations")) {
            this.selectedOfficeName = this.officeData.name || '';
            this.selectedWorkspaceName = 'toolbar.dashboard';
        }
        if (this.currentUrl && this.currentUrl === '/personal-dashboard') {
            this.selectedOfficeName = 'dashboard.personal_dashboard';
            // this.hideToolbar = true;
        }
        else {
            // this.hideToolbar = false;
        }
    };
    ToolbarComponent.prototype.openOfficeList = function () {
        this.dialog.open(OfficeListPopupComponent, {
            width: '100%',
            height: '100%',
            restoreFocus: false,
            panelClass: 'happ-form-builder-dialog',
            data: { changeOffice: true }
        });
    };
    ToolbarComponent.prototype.getCookie = function (name) {
        var value = '; ' + document.cookie;
        var parts = value.split('; ' + name + '=');
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
    };
    ToolbarComponent.prototype.getLocalStorageToken = function () {
        if (localStorage.getItem('ngStorage-token')) {
            var temp = JSON.parse(localStorage.getItem('ngStorage-token'));
            return temp.token;
        }
        else {
            return null;
        }
    };
    ToolbarComponent.prototype.checkLoginAndToken = function () {
        var cookieToken = this.getCookie('token');
        var localStorageToken = this.getLocalStorageToken();
        if (cookieToken) {
            if (localStorageToken !== cookieToken) {
                this.authService.afterLogin(cookieToken);
            }
            else if (!localStorageToken) {
                this.authService.afterLogin(cookieToken);
            }
        }
    };
    ToolbarComponent.prototype.getOfficeCredit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.officeData._id && localStorage.getItem('selectedOfficeId'))) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.helperService.getRequest("api/office-credit/" + this.officeData._id)];
                    case 1:
                        data = _a.sent();
                        data.promoCredits = data.promoCredits || 0;
                        data.purchasedCredits = data.purchasedCredits || 0;
                        this.officeCredit = data.totalAmount || 0;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ToolbarComponent.prototype.moveToVirtualOrg = function () {
        this.router.navigateByUrl("/offices/" + (this.officeData['_id']) + "/organizations");
    };
    return ToolbarComponent;
}());
export { ToolbarComponent };
