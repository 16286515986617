import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { HelpersService } from '../helpers/helpers.service';
import { OfficeStore } from '../../state/officeState/office.store';
var OfficeHelpersService = /** @class */ (function () {
    function OfficeHelpersService(helperService, officeStore) {
        this.helperService = helperService;
        this.officeStore = officeStore;
    }
    /**
     *
     * @param officeId
     * get office data from server
     */
    OfficeHelpersService.prototype.getCurrentOfficeData = function (officeId) {
        return {};
    };
    /**
     * return office data from local storage
     */
    OfficeHelpersService.prototype.getLocalOfficeData = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))) {
                _this.officeData = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'));
                resolve(JSON.parse(localStorage.getItem('ngStorage-selectedOffice')));
            }
            else {
                reject('No Office Doc Found. Please Make Sure you have some Office selected');
            }
        });
    };
    OfficeHelpersService.prototype.getOfficeApps = function () {
        if (this.officeData) {
            return this.officeData.apps || {};
        }
        else {
            throw new Error('No office data...');
        }
    };
    /**
     * this is to get calendar id from the office doc...
     */
    OfficeHelpersService.prototype.getOfficeCalendarId = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.officeData) {
                if (_this.officeData.apps && _this.officeData.apps['Agenda'] && _this.officeData.apps['Agenda']['id']) {
                    resolve(_this.officeData.apps['Agenda']['id']);
                }
                else {
                    reject('No Calendar App found in the office Doc');
                }
            }
            else {
                reject('No Office Doc Found. Please Make Sure you have some Office selected');
            }
        });
    };
    OfficeHelpersService.prototype.getOfficeAddressBookId = function () {
        return '';
    };
    OfficeHelpersService.prototype.getOfficeGroups = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.getLocalOfficeData()
                .then(function (officeData) {
                resolve(officeData.groups || []);
            }).catch(reject);
        });
    };
    OfficeHelpersService.prototype.updateLocalOfficeData = function (officeData) {
        localStorage.setItem('ngStorage-selectedOffice', JSON.stringify(officeData));
    };
    OfficeHelpersService.prototype.getOfficeUsers = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.getLocalOfficeData()
                .then(function (officeData) {
                resolve(officeData.users || []);
            }).catch(reject);
        });
    };
    OfficeHelpersService.prototype.getOfficeUser = function (userId) {
        return this.getOfficeUsers()
            .then(function (officeUsers) {
            officeUsers = officeUsers || [];
            var tempIndex = _.findIndex(officeUsers, { id: userId });
            if (tempIndex >= 0) {
                return officeUsers[tempIndex];
            }
            else {
                return null;
            }
            // return officeUsers;
        });
    };
    OfficeHelpersService.prototype.getOfficeData = function (officeId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (officeId) {
                _this.helperService.getRequest("api/offices/" + officeId)
                    .then(resolve).catch(reject);
            }
            else {
                reject('No officeId');
            }
        });
    };
    OfficeHelpersService.prototype.refreshLocalOfficeData = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                _this.getOfficeData(JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'])
                    .then(function (officeData) {
                    if (officeData) {
                        localStorage.setItem('ngStorage-selectedOffice', JSON.stringify(officeData));
                    }
                    _this.getLocalOfficeData();
                    _this.officeStore.update(officeData);
                    resolve(officeData);
                }).catch(function (err) {
                    console.info(err);
                    reject(err);
                });
            }
            else {
                console.log('No officeId in local storage');
            }
        });
    };
    OfficeHelpersService.prototype.refreshLocalOfficeDataUsingObject = function (officeData) {
        if (officeData) {
            localStorage.setItem('ngStorage-selectedOffice', JSON.stringify(officeData));
        }
    };
    OfficeHelpersService.prototype.updateOfficeUserData = function (data) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                _this.helperService.putRequest("api/offices/" + JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'] + "/logged-user-profile", data)
                    .then(function (res) {
                    _this.refreshLocalOfficeData();
                    resolve(res);
                }).catch(reject);
            }
            else {
                reject('No officeId found');
            }
        });
    };
    OfficeHelpersService.prototype.getOfficeMainMenu = function () {
        return new Promise(function (resolve, reject) {
            try {
                resolve([]);
                // this.getLocalOfficeData();
                // const officeApps = this.getOfficeApps();
                // if (officeApps && officeApps['mainMenu'] && officeApps['mainMenu']['id']) {
                //   this.helperService.getRequest(`api/offices/${this.officeData._id}/apps/main-menu`)
                //   .then((mainMenu: Array<IMainMenuItem>) => {
                //     resolve(mainMenu || []);
                //   }).catch(err => {
                //     console.info(err);
                //     reject(err);
                //   });
                // } else {
                //   reject('No main Menu found in the office doc');
                // }
            }
            catch (err) {
                reject(err);
            }
        });
    };
    OfficeHelpersService.prototype.updateOfficeDoc = function (updatedDoc) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                _this.getLocalOfficeData();
                var officeApps = _this.getOfficeApps();
                if (_this.officeData._id && updatedDoc) {
                    _this.helperService.patchRequest("api/offices/" + _this.officeData._id, updatedDoc)
                        .then(function (mainMenu) {
                        resolve(mainMenu || []);
                    }).catch(function (err) {
                        console.info(err);
                        reject(err);
                    });
                }
                else {
                    reject('No office id or data is null');
                }
            }
            catch (err) {
                reject(err);
            }
        });
    };
    OfficeHelpersService.prototype.getOfficeFilters = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.getLocalOfficeData()
                .then(function (officeData) {
                officeData = officeData || {};
                resolve(officeData.officeFilters || []);
            }).catch(function (err) {
                reject(err);
            });
        });
    };
    OfficeHelpersService.prototype.loadNewOffice = function (officeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var officeDoc, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        window.localStorage.removeItem("lastSelectedOrg");
                        window.localStorage.removeItem("selectedVirtualOrgId");
                        return [4 /*yield*/, this.helperService.getRequest("api/offices/" + officeId)];
                    case 1:
                        officeDoc = _a.sent();
                        if (officeDoc) {
                            localStorage.setItem('selectedOfficeId', JSON.stringify(officeDoc['_id']));
                            localStorage.setItem('ngStorage-selectedOffice', JSON.stringify(officeDoc));
                            return [2 /*return*/, JSON.parse(JSON.stringify(officeDoc))];
                        }
                        else {
                            console.error('No office doc found');
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        throw error_1;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OfficeHelpersService.prototype.getOfficeList = function (query) {
        if (query === void 0) { query = ''; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.helperService.getRequest("api/offices" + query)];
                    case 1: return [2 /*return*/, (_a.sent()) || []];
                    case 2:
                        error_2 = _a.sent();
                        throw error_2;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return OfficeHelpersService;
}());
export { OfficeHelpersService };
