import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { HelpersService } from '../../services/helpers/helpers.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Component({
    selector     : 'mailing-detail-external',
    templateUrl  : './mailing-detail-external.component.html',
    styleUrls    : ['./mailing-detail-external.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class MailingDetailExternalComponent implements OnInit
{
    data: any;
    loading: boolean;
    success: string;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private http: HttpClient,
        private helperService: HelpersService,
        private route: ActivatedRoute
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    async ngOnInit() {
        await this.getData();
    }

    async getData() {
        try {
            console.log("temp = ");
            // this.data = await this.helperService.getRequest(`api/mailing-list/info/${this.route.snapshot.params.recId}/${this.route.snapshot.params.index}`, {}, true)
            // console.log("this.data", this.data);
            this.http.get(`${environment.serverUrl}api/mailing-list/info/${this.route.snapshot.params.recId}/${this.route.snapshot.params.index}?recId=${this.route.snapshot.queryParams.recId}`)
            .subscribe((data: any) => {
                this.data = data.result;
                this.data.email = this.route.snapshot.params.email;
                console.log("data", data);
            }, err => alert(err));
        } catch (error) {
            alert(error);
        }
    }

    async accept() {
        try {
            this.loading = true;
            await this.helperService.getRequest(`api/mailing-list/action/${this.route.snapshot.queryParams.regId}/${this.route.snapshot.params.recId}/accept?recId=${this.route.snapshot.queryParams.recId}`, {}, true)
            // this.loading = false;
            this.success = "accept";
            if (this.data.action === 'sendMailWithRecordWithSharedService') {
                setTimeout(() => {
                    window.location.href = `https://5cb9648308e32961acf1ff1f.pages.pinbox24.com/our-services/${this.data.sharedServiceId}?trackingId=${this.route.snapshot.queryParams.recId}`
                    // window.location.href = `https://5cb9648308e32961acf1ff1f.pages.pinbox24.com/our-services/${this.data.sharedServiceId}?trackingId=${this.route.snapshot.queryParams.recId}`
                }, 2000);
            }
        } catch (error) {
            // this.loading = false;
            alert(error);
        }
    }

    async reject() {
        try {
            this.loading = true;
            await this.helperService.getRequest(`api/mailing-list/action/${this.route.snapshot.queryParams.regId}/${this.route.snapshot.params.recId}/reject?recId=${this.route.snapshot.queryParams.recId}`, {}, true)
            this.success = "reject";
            this.loading = false;
        } catch (error) {
            this.loading = false;
            alert(error);
        }
    }

    async unSubscribeAll() {
        try {
            this.loading = true;
            await this.helperService.getRequest(`api/mailing-list/action/${this.route.snapshot.queryParams.regId}/${this.route.snapshot.params.recId}/unSubscribeAll?recId=${this.route.snapshot.queryParams.recId}`, {}, true)
            this.success = "unSubscribeAll";
            this.loading = false;
        } catch (error) {
            this.loading = false;
            alert(error);
        }
    }

    async unSubscribeThis() {
        try {
            this.loading = true;
            await this.helperService.getRequest(`api/mailing-list/action/${this.route.snapshot.queryParams.regId}/${this.route.snapshot.params.recId}/unSubscribeThis?recId=${this.route.snapshot.queryParams.recId}`, {}, true)
            this.success = "unSubscribeThis";
            this.loading = false;
        } catch (error) {
            this.loading = false;
            alert(error);
        }
    }

}
