import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Message } from './model/message';
import { Event } from './model/event';
import { environment } from 'environments/environment';

import * as socketIo from 'socket.io-client';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class SocketService {
    socket;
    previousEvent: any = {};
    subscribedChannels = [];
    onCallEvent: BehaviorSubject<any> = new BehaviorSubject({});
    onSocketEventTriggered: BehaviorSubject<any> = new BehaviorSubject({});
    callsList = [];

    constructor(
        private toastrService: ToastrService
    ) { }

    public initSocket(token: string): void {
        if (!environment.production) {
            console.log("YOU ARE WORKING IN LOCAL ENV - NO SOCKET CONNECTED");
            return;
        }
        this.socket = socketIo(environment.serverUrl, {
            query: {
                token
            },
            transports: ['websocket', 'polling']
        });
        this.callsList = [];

        this.subscribeToChannel();
        this.unSubscribeToChannel();
    }

    public subscribeToBasicEvents() {
        if (!this.socket) {
            return;
        }
        this.socket.on('disconnect', (data: any) => {
            console.log('disconnect', data);
        });
        this.socket.on('connection', (data: any) => {
            console.log('connection', data);
        });
    }

    public disconnectSocket() {
        if (this.socket) {
            this.socket.disconnect();
            this.subscribedChannels.forEach((ele) => {
                this.socket.removeListener(ele);
            });
            this.subscribedChannels = [];
        }
    }

    reConnectSocket() {
        if (!this.socket) {
            let data: any = localStorage.getItem('ngStorage-token');
            if (data) {
                data = JSON.parse(data);
                this.initSocket(data.token);
            }
        }
    }

    public send(message: Message): void {
        if (!this.socket) {
            return;
        }
        this.socket.emit('message', message);
    }

    public sendSocketEvent(eventName: string, data: any) {
        if (!this.socket) {
            return;
        }
        // console.log('sendSocketEvent', eventName, data);
        this.socket.emit(eventName, data);
    }

    public usersNotifyToReload(users: any[]): void {
        if (!this.socket) {
            return;
        }
        this.socket.emit('usersNotifyToReload', users);
    }

    public electronicSender(users: any[]): void {
        if (!this.socket) {
            return;
        }
        this.socket.emit('electronicSenderData', users);
    }

    public packageSenderFilter(packageFilter: any, params: any): void {
        if (!this.socket) {
            return;
        }
        this.socket.emit('packageSenderFilterData', { packageFilter: packageFilter, params: params });
    }

    public authenticate(token: string): void {
        if (!this.socket) {
            return;
        }
        this.socket.on('authenticated', () => {
        }).emit('authenticate', { token: token });
    }

    public userNotifyToUpdate(): Observable<any> {
        return new Observable<any>(observer => {
            if (!this.socket) {
                return;
            }
            this.socket.on('userNotifyToUpdate', () => observer.next());
        });
    }

    public electronicReciver(): Observable<any> {
        return new Observable<any>(observer => {
            if (!this.socket) {
                return;
            }
            this.socket.on('electronicReceiverData', (data) => observer.next(data));
        });
    }

    public packageSenderReciver(): Observable<any> {
        return new Observable<any>(observer => {
            if (!this.socket) {
                return;
            }
            this.socket.on('packageSenderReciverData', (data) => observer.next(data));
        });
    }

    public onEvent(event: Event): Observable<any> {
        return new Observable<Event>(observer => {
            if (!this.socket) {
                return;
            }
            this.socket.on(event, () => observer.next());
        });
    }

    public inviationNotify(): Observable<any> {
        return new Observable<any>(observer => {
            if (!this.socket) {
                return;
            }
            this.socket.on('inviationNotify', (data) => observer.next(data));
        });
    }

    async showInfo(message: string, title: any = '', type: string = 'info', options: any = {}) {
        this.toastrService[type](message, title, options);
    }

    public subscribeToChannel() {
        if (!this.socket) {
            return;
        }

        this.socket.on('message', (data: any) => {
            console.log("message recived", data);
        });

        this.socket.on('channelJoined', (data: any) => {
            // console.log('Channels list', this.subscribedChannels);
            if (this.subscribedChannels.includes(data.channelName)) {
                console.log(' *** User already in channel', data);
            } else {
                data.eventType = data.eventType || 'defaultEvent';
                this.subscribedChannels.push(data.channelName);
                // console.log('--------- channelJoined ', data);
                this.socket.on(data.channelName, (res: any) => {
                    // console.log('triggered from backend - ', res);
                    if (data.eventType === 'phoneNumber') {
                        this[data.eventType]({ that: this, ...res });
                    } else {
                        this.onSocketEventTriggered.next({ eventType: data.eventType, res: { that: this, ...res } });
                    }
                    return true;
                });
            }
        });
    }

    public unSubscribeToChannel() {
        if (!this.socket) {
            return;
        }
        console.log('unsubscribeToChannel');
        this.socket.on('channelLeaved', (data: any) => {
            // console.log('--------- channelLeaved ', data);
            this.socket.removeListener(data.channelName);
            if (this.subscribedChannels.indexOf(data.channelName) !== -1) {
                this.subscribedChannels.splice(this.subscribedChannels.indexOf(data.channelName), 1);
            }
        });
    }


    public subscribeToNotification(type: string, channelName: string) {
        if (!this.socket) {
            return;
        }
        if (this.previousEvent[type]) {
            this.socket.removeListener(this.previousEvent[type]);
        }
        this.previousEvent[type] = channelName;
        // console.log('subscribing to ', channelName);
        this.socket.on(channelName, (data) => {
            // console.log('event  ', data);
            this.showInfo(data);
        });
    }

    public defaultEvent(res: any) {
        console.log('triggering default event', res);
    }

    public phoneNumber(res: any) {
        // console.log('phoneNumber', res);
        res.that.callsList = res.that.callsList || [];
        switch (res.operationType) {
            case 'new':
                // const temp = JSON.parse(JSON.stringify(res.that.callsList));
                const temp = res.that.callsList;
                temp.unshift(res);
                res.that.callsList = res.that.getUniqueObjectFromArray(temp, 'callId');
                break;
            case 'update': {
                const index = _.findIndex(res.that.callsList, { callId: res.callId });
                if (index !== -1) {
                    res.that.callsList[index] = res;
                }
            }
                break;
            case 'delete': {
                const index = _.findIndex(res.that.callsList, { callId: res.callId });
                // console.log("totla", res.that.callsList);
                // console.log("removing item fronm", index);
                if (index !== -1) {
                    res.that.callsList.splice(index, 1);
                }
            }
                break;
        }
        res.that.onCallEvent.next({
            eventType: 'new_call'
        });
    }

    getUniqueObjectFromArray = (array: any[] = [], keyName: string) => {
        const resArr: any = [];
        array.filter((item) => {
            const index = resArr.findIndex((ele: any) => ele[keyName] === item[keyName]);
            if (index <= -1) {
                resArr.push(item);
            }
            return null;
        });
        return resArr;
    };
}
