export const ProcessShapes = [
    {
      'id': '1',
      'type': 'start',
      'kind': 'interactive',
      'shape': 'egg',
      'label': 'Start',
      'style': 'filled',
      'fillcolor': 'lightyellow',
      'fontcolor': 'black',
      'fixedsize': ''
    },
    {
      'id': '2',
      'type': 'end',
      'kind': 'interactive',
      'shape': 'egg',
      'label': 'End',
      'style': 'filled',
      'fillcolor': 'black',
      'fontcolor': 'white',
      'fixedsize': ''
    },
    {
      'id': '3',
      'type': 'activity',
      'kind': 'interactive',
      'shape': 'oval',
      'labelR': '{{selectedRolesComaSeparated}}',
      'label': '{{userInput}}',
      'style': '',
      'fillcolor': '',
      'fontcolor': 'black',
      'fixedsize': ''
    },
    {
      'id': '4',
      'type': 'activity',
      'kind': 'automatic',
      'shape': 'diamond',
      'labelR': '{{selectedRolesComaSeparated}}',
      'label': '{{userInput}}',
      'style': 'filled',
      'fillcolor': 'red',
      'fontcolor': 'black',
      'fixedsize': ''
    },
    {
      'id': '5',
      'type': 'switch',
      'kind': 'interactive',
      'shape': 'diamond',
      'labelR': '{{selectedRolesComaSeparated}}',
      'label': '{{userInput}}',
      'style': '',
      'fillcolor': '#7EF9FF',
      'fontcolor': 'black',
      'fixedsize': ''
    },
    {
      'id': '6',
      'type': 'switch',
      'kind': 'automatic',
      'shape': 'diamond',
      'labelR': '{{selectedRolesComaSeparated}}',
      'label': '{{userInput}}',
      'style': 'filled',
      'fillcolor': '#7EF9FF',
      'fontcolor': 'black',
      'fixedsize': ''
    },
    {
      'id': '7',
      'type': 'split',
      'kind': 'interactive',
      'shape': 'triangle',
      'labelR': '{{selectedRolesComaSeparated}}',
      'label': '{{userInput}}',
      'style': '',
      'fillcolor': '',
      'fontcolor': 'black',
      'fixedsize': ''
    },
    {
      'id': '8',
      'type': 'split',
      'kind': 'automatic',
      'shape': 'triangle',
      'labelR': '{{selectedRolesComaSeparated}}',
      'label': '{{userInput}}',
      'style': 'filled',
      'fillcolor': 'lightgray',
      'fontcolor': 'black',
      'fixedsize': ''
    },
    {
      'id': '9',
      'type': 'join',
      'kind': 'interactive',
      'shape': 'invtriangle',
      'labelR': '{{selectedRolesComaSeparated}}',
      'label': '{{userInput}}',
      'style': '',
      'fillcolor': '',
      'fontcolor': 'black',
      'fixedsize': ''
    },
    {
      'id': '10',
      'type': 'join',
      'kind': 'automatic',
      'shape': 'invtriangle',
      'labelR': '{{selectedRolesComaSeparated}}',
      'label': '{{userInput}}',
      'style': 'filled',
      'fillcolor': 'lightgray',
      'fontcolor': 'black',
      'fixedsize': ''
    },
    {
      'id': '11',
      'type': 'standalone',
      'kind': 'interactive',
      'shape': 'octagon',
      'labelR': '{{selectedRolesComaSeparated}}',
      'label': '{{userInput}}',
      'style': '',
      'fillcolor': '',
      'fontcolor': 'black',
      'fixedsize': ''
    },
    {
      'id': '12',
      'type': 'state',
      'kind': 'interactive',
      'shape': 'rectangle',
      'labelR': '{{selectedRolesComaSeparated}}',
      'label': '{{userInput}}',
      'style': 'filled',
      'fillcolor': 'lightyellow',
      'fontcolor': 'black',
      'fixedsize': ''
    },
    {
      'id': '13',
      'type': 'trigger',
      'kind': 'automatic',
      'shape': 'tab',
      'labelR': '{{selectedRolesComaSeparated}}',
      'label': '{{userInput}}',
      'style': 'filled',
      'fillcolor': 'lightgray',
      'fixedsize': '',
      'fontcolor': 'black'
    },
    {
      'id': '14',
      'type': 'trigger',
      'kind': 'interactive',
      'shape': 'tab',
      'labelR': '{{selectedRolesComaSeparated}}',
      'label': '{{userInput}}',
      'style': 'filled',
      'fillcolor': 'lightgray',
      'fixedsize': '',
      'fontcolor': 'black'
    },
    {
      'id': '15',
      'type': 'end',
      'kind': 'automatic',
      'shape': 'egg',
      'label': 'End',
      'style': 'filled',
      'fillcolor': 'black',
      'fontcolor': 'white',
      'fixedsize': ''
    },
    {
      'id': '16',
      'type': 'subgraph',
      'kind': 'automatic',
      'shape': 'rectangle',
      'label': 'Subgraph',
      'style': 'filled',
      'fillcolor': 'white',
      'fontcolor': 'black',
      'fixedsize': ''
    },
    {
      'id': '17',
      'type': 'person',
      'kind': 'automatic',
      'shape': 'Mrecord',
      'label': 'person',
      'style': 'filled',
      'fillcolor': 'white',
      'fontcolor': 'black',
      'fixedsize': ''
    },
    {
      'id': '18',
      'type': 'department',
      'kind': 'automatic',
      'shape': 'Mrecord',
      'label': 'department',
      'style': 'filled',
      'fillcolor': 'white',
      'fontcolor': 'black',
      'fixedsize': ''
    },
    {
      'id': '19',
      'type': 'depGroup',
      'kind': 'automatic',
      'shape': 'Mrecord',
      'label': 'depGroup',
      'style': 'filled',
      'fillcolor': 'white',
      'fontcolor': 'black',
      'fixedsize': ''
    },
    {
      'id': '20',
      'type': 'standaloneAct',
      'kind': 'interactive',
      'shape': 'triangle',
      'labelR': '{{selectedRolesComaSeparated}}',
      'label': '{{userInput}}',
      'style': '',
      'fillcolor': '',
      'fontcolor': 'black',
      'fixedsize': ''
    },
    {
      'id': '21',
      'type': 'statePayment',
      'kind': 'interactive',
      'shape': 'component',
      'label': 'Start',
      'style': 'filled',
      'fillcolor': 'lightyellow',
      'fontcolor': 'black',
      'fixedsize': ''
    },
    {
      'id': '22',
      'type': 'room',
      'kind': 'interactive',
      'shape': 'component',
      'label': 'Start',
      'style': 'filled',
      'fillcolor': 'lightyellow',
      'fontcolor': 'black',
      'fixedsize': ''
    }
  ];
