export const STATIC_FIELDS = {
    processStatus: {
        "name": "Process Status",
        "changedType": "Process Status",
        "id": 7970,
        "control_type": "process_status",
        "groupBy": "process",
        "category": "advance",
        "icon": "hand-pointer",
        "class": "half",
        "required": false,
        "hide": false,
        "disable": false,
        "data": "",
        "field": true,
        "allow_description": false,
        "description": "",
        "show_on_grid": false,
        "show_on_search": false,
        "inline_edit": false,
        "cell_width": 1,
        "field_sort": "",
        "grid_sort": 50,
        "showOnHeader": false,
        "showOnFooter": false,
        "title": "Process Status",
        "label": "process_status7970",
        "showOnForm": true,
        "smallButton": true
      }
}