<div>
    <mat-toolbar style="background: #f6f6f6;" matDialogTitle class="m-0">
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
          <span class="title dialog-title"> {{ 'workspace.create_new_app' | translate }} </span>
          <span fxFlex></span>
          <button (click)="dialogRef.close(null)" mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </mat-toolbar>
  <div class="example-card p-24" *ngIf="workspaceData">
    <div fxLayout="column" class="mb-8">
      <div class="happ-form-section">
        <p>{{ 'workspace.app_name' | translate }}</p>
        <input aria-label="create_workspace_name_input" [(ngModel)]="workspaceData.name" class="happ-form-input" placeholder="">
      </div>
      <div class="mb-12" fxLayout="row">
        <p style="font-weight: 700" class="mr-8 mt-4">{{ 'workspace.icon' | translate }}:</p>
        <div matTooltip="{{ 'workspace.click_to_edit' | translate }}" style="cursor: pointer;" (click)="selectIcon('icon')">
          <mat-icon class="font-size-30"> {{ workspaceData.icon }} </mat-icon>
        </div>
      </div>
      <div class="happ-form-section">
        <p>{{ 'workspace.show_on_mobile' | translate }}</p>
        <mat-checkbox [(ngModel)]="workspaceData.showOnMobile"></mat-checkbox>
      </div>
      <div class="mb-12" fxLayout="row" *ngIf="workspaceData.showOnMobile">
        <p style="font-weight: 700" class="mr-8 mt-4">{{ 'workspace.mobile_icon' | translate }}:</p>
        <div matTooltip="{{ 'workspace.click_to_edit' | translate }}" style="cursor: pointer;" (click)="selectIcon('mobileIcon')">
          <mat-icon class="font-size-30"> {{ workspaceData.mobileIcon }} </mat-icon>
        </div>
      </div>
      <div>
        <p style="font-weight: 700;" class="mb-12">{{ 'workspace.description' | translate }}:</p>
        <app-html-editor type="admin" [(model)]="workspaceData.description"></app-html-editor>

      </div>
    </div>
    <div>  
      <span fxFlex></span>
      <button mat-button aria-label="create_workspace_cancel_button" (click)="dialogRef.close(null)" class="mr-4">{{ 'workspace.cancel' | translate }}</button>
      <button mat-raised-button [disabled]="loading" aria-label="create_workspace_create_button" (click)="saveClick()" color="primary"> {{ ( (action === 'edit') ? 'worksapce.save' : 'workspace.create') | translate }} </button>
    </div>
  </div>
</div>