<mat-toolbar matDialogTitle fxLayoutAlign="center center" class="call-header-one mat-toolbar mat-accent m-0"
  fxLayout="row">
  <div fxFlex fxLayout="header" fxLayoutAlign="space-between center">
    <span class="title dialog-title">
      <p class="m-0 font-size-20 ml-12">
        {{ 'popup.mailing_footer' | translate }}
      </p>
    </span>
    <span fxFlex></span>
    <button class="mr-12" (click)="save()" mat-icon-button id="mailing_list_save">
      <mat-icon>check</mat-icon>
    </button>
    <button class="mr-12" (click)="close()" mat-icon-button id="mailing_list_cancel">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</mat-toolbar>

<div fxLayout="column p-24 mb-24" style="    width: 100%;">

  <div class="m-20" fxLayout="column">
    <p>
      {{ 'popup.title' | translate }}
    </p>
    <mat-form-field fxFlex class="mx-8 happ-small-form-field-outline">
      <input matInput [(ngModel)]="title" >
    </mat-form-field>
  
    <br>
    <p>
      {{ 'popup.footer_content' | translate }}
    </p>
    <ck-editor name="editor1" [(ngModel)]="footerContent" skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>

  </div>


</div>