import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-ct-info-source-select',
  templateUrl: './ct-info-source-select.component.html',
  styleUrls: ['./ct-info-source-select.component.scss']
})
export class CtInfoSourceSelectComponent implements OnInit {

  @Input() field;
  @Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() regId;
  @Input() builder;
  @Input() translateData;
  @Input() model: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Output() onError: EventEmitter<Object> = new EventEmitter();

  list = [];
  loading = false;

  filterText;
  errorMessage;
  activeLang = 'en';
  error: boolean;
  filteredNames: any = [];
  constructor(
    private helperService: HelpersService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.activeLang = this.translateService.getDefaultLang();
    this.translateService.onLangChange.subscribe((data2: any) => {
      this.activeLang = data2;
    });
    this.getInformationSources();
  }

  async getInformationSources() {
    try {
      this.loading = true;
      this.list = await this.helperService.getRequest('api/integration-source/list');
      this.filteredNames = this.list;
      const user = _.find(this.filteredNames, (ele: any) => {
        return (ele && ele.key === this.model);
      });
      console.log("user", user);
      this.filterText = (user && user.key);

      this.loading = false;
    } catch (error) {
      console.error(error);
      this.loading = false;
    }
  }

  checkError() {
    // errorValidation
    if (this.field['required'] && (!this.model || this.model && this.model.length === 0)) {
      this.error = true;
      this.errorMessage = 'ct.field_is_required';
      if (!this.model) {
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
      }
    } else {
      this.error = false;
      this.errorMessage = '';
      if (this.model) {
        this.onError.emit({
          error: false,
          label: this.field['label']
        });
      }
    }
  }

  private _filterStates(value: string): any {
    const filterValue = value.toLowerCase();
    return this.list.filter(state => (state && state.key.toLowerCase().indexOf(filterValue) === 0));
  }

  changed(value) {
    this.filteredNames = this._filterStates(this.filterText);
  }

  onSelection() {
    const user = _.find(this.filteredNames, (ele: any) => {
      return (ele && ele.key === this.filterText);
    });

    this.model = (user && user.key);
    this.modelChange.next(this.model);

  }

}
