/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bar-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/common";
import * as i10 from "./bar-button.component";
var styles_MatBarButtonComponent = [i0.styles];
var RenderType_MatBarButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MatBarButtonComponent, data: {} });
export { RenderType_MatBarButtonComponent as RenderType_MatBarButtonComponent };
function View_MatBarButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "bar mat-progress-bar"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(1, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { color: [0, "color"], value: [1, "value"], mode: [2, "mode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.options.barColor; var currVal_4 = _co.options.value; var currVal_5 = _co.options.mode; _ck(_v, 1, 0, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).value; var currVal_1 = i1.ɵnov(_v, 1).mode; var currVal_2 = i1.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_MatBarButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["mat-button", ""]], [[8, "type", 0], [2, "active", null], [2, "mat-raised-button", null], [2, "mat-stroked-button", null], [2, "mat-flat-button", null], [2, "fullWidth", null], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], null, null, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "span", [], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_MatBarButtonComponent_1)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = (_co.options.active || _co.options.disabled); var currVal_9 = _co.options.buttonColor; _ck(_v, 1, 0, currVal_8, currVal_9); var currVal_10 = (_co.options.active && !_co.options.disabled); _ck(_v, 5, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options.type; var currVal_1 = _co.options.active; var currVal_2 = _co.options.raised; var currVal_3 = _co.options.stroked; var currVal_4 = _co.options.flat; var currVal_5 = _co.options.fullWidth; var currVal_6 = (i1.ɵnov(_v, 1).disabled || null); var currVal_7 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_MatBarButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-bar-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_MatBarButtonComponent_0, RenderType_MatBarButtonComponent)), i1.ɵdid(1, 49152, null, 0, i10.MatBarButtonComponent, [], null, null)], null, null); }
var MatBarButtonComponentNgFactory = i1.ɵccf("mat-bar-button", i10.MatBarButtonComponent, View_MatBarButtonComponent_Host_0, { options: "options", transText: "transText" }, { btnClick: "btnClick" }, ["*"]);
export { MatBarButtonComponentNgFactory as MatBarButtonComponentNgFactory };
