import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@fuse/material.module';
import { HtmlEditorModule } from '../html-editor/html-editor.module';
import { PendingPaymentComponent } from './pending-payment.component';
import { HelpersService } from '../../services/helpers/helpers.service';

@NgModule({
  declarations: [
    
  PendingPaymentComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    TranslateModule,
    MaterialModule,
    HtmlEditorModule
  ],
  exports: [
    PendingPaymentComponent
  ],
  entryComponents: [
    // PendingPaymentComponent
  ],
  providers: [
    HelpersService
  ]
})
export class PendingPaymentModule { }
