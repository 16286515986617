import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, Inject, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatDialog } from '@angular/material';
import { FileRetryViewComponent } from './file-retry-view/file-retry-view.component';
import { ProfileHelperService } from 'app/main/content/services/profile-helper/profile-helper.service';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, AfterViewInit, OnDestroy {
  
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() model: string; 
@Input() translateData: any;
  @Input() recordMainDocument: string;
  @Input() recId: string;
  @Input() regId: string;
  @Input() appId: string;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Output() recordMainDocumentChange: EventEmitter<Object> = new EventEmitter();
  // @ViewChild(DataTableDirective)
  // dtElement: DataTableDirective;

  officeId;
  creatorId;
  creatorLogin;
  fileList;
  docBase;
  loading = false;
  downloadLoading = false;
  selectedFiles: Array<String> = [];

  // dtOptions: DataTables.Settings = {};
  formList = [];
  dtTrigger: Subject<any> = new Subject();


  constructor(
    private sanitizer: DomSanitizer,
    private translationLoader: FuseTranslationLoaderService,
    private helperService: HelpersService,
    public dialog: MatDialog,
    private officeHelper: OfficeHelpersService,
    private profileHelper: ProfileHelperService
  ) {
    this.translationLoader.loadTranslationsV2(this.translateData);
  }

  
  async ngOnInit() {
    this.fetchData();

    const profileData = await this.profileHelper.getLocalProfileData();
    if (profileData) {
      this.creatorId = profileData.profileId;
      this.creatorLogin = profileData.email;
    }

    const officeData = await this.officeHelper.getLocalOfficeData();
    this.officeId = officeData._id;
    
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  fileChanged(file) {
    const files = file.files;
    this.selectedFiles = [];
    for (let i = 0; i < files.length; i++ ) {
      this.selectedFiles.push(files[i]['name']);
    }
  }


  onKeyPress() {
    this.modelChange.next(this.model);
  }

  deleteFile(id: string) {
    this.helperService.openConfirmPopUp('popup.confirm_delete_file_message')
    .subscribe((res) => {
      if (res) {
        if (this.recordMainDocument === id) {
          this.removeMainDoc();
        }
        this.helperService.deleteRequest(`api/offices/files/${id}`)
        .then(() => {
          this.fetchData();

        }).catch(err => {
          console.info(err);
          this.helperService.openSnackBar(err);
        });
      }
    });
  }

  removeMainDoc() {
      this.recordMainDocument = '';
      this.recordMainDocumentChange.next(this.recordMainDocument);
  }

  saveAsMainDoc(id: string) {
    if (id) {
      this.recordMainDocument = id;
      this.recordMainDocumentChange.next(this.recordMainDocument);
    }
  }


  uploadFile(file) {
    const files = file.files;
    const formData = new FormData();
    let url = '';
    for (let i = 0; i < files.length; i++ ) {
      formData.append('uplFile', files[i], files[i].name);
    }
    if (this.regId && this.recId) {
      if (this.recId !== 'new') {
        // tslint:disable-next-line:max-line-length
        url = `api/offices/files/upload?creatorId=${this.creatorId}&creatorLogin=${this.creatorLogin}&officeId=${this.officeId}&recId=${this.recId}&regId=${this.regId}`;
      }
    } else if (this.appId) {
      // tslint:disable-next-line:max-line-length
      url = `api/offices/files/upload?creatorId=${this.creatorId}&creatorLogin=${this.creatorLogin}&officeId=${this.officeId}&appId=${this.appId}`;
    }
    this.loading = true;
    this.helperService.postRequest(url, formData)
    .then((data) => {
      this.loading = false;
      if (data) {
        this.fetchData();
      }
    }).catch(err => {
      this.loading = false;
      console.info(err);
    });
  }

  fetchData( ) {
    if (this.recId && this.regId) {
      if (this.recId !== 'new') {
        this.helperService.getRequest(`api/offices/files/list?officeId=${this.officeId}&recId=${this.recId}`)
        .then((data) => {
          this.fileList = data || [];
          this.rerender();
        }).catch(err => {
          console.info(err);
        });
      }
    } if (this.appId) {
      this.helperService.getRequest(`api/offices/files/list?officeId=${this.officeId}&appId=${this.appId}`)
      .then((data) => {
        this.fileList = data || [];
        this.rerender();
      }).catch(err => {
        console.info(err);
      });
    }
  }

  openPreview(id: string): void {
    if (id) {
      this.downloadLoading = true;
      this.helperService.getRequest(`api/offices/files/${id}/getBase64File`)
      .then((data) => {
        this.downloadLoading = false;
        if (data) {
          this.helperService.downloadDocument(data);
        }
      }).catch(err => {
        console.info(err);
        this.openRetryView(id);
        this.downloadLoading = false;
      });
    }
  }

  openRetryView(id: string) {
    const temp = this.dialog.open(FileRetryViewComponent, {
      disableClose: false,
      width: '40%',
      height: '400px',
      restoreFocus: false,
      panelClass: 'happ-form-builder-dialog',
      data: {
        url: `api/offices/files/${id}/getBase64File`,
        id
      }
    });

    temp.afterClosed().subscribe((data) => {
      if (data) {
        this.helperService.downloadDocument(data);
      }
    });
  }

  rerender(): void {
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }


  ngAfterViewInit(): void {
    this.dtTrigger.next();
    // this.rerender();
}
}
