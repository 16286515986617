import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { FileRetryViewComponent } from './file-retry-view.component';

@NgModule({
    declarations: [
      FileRetryViewComponent,
    ],
    imports     : [
        FuseSharedModule
    ],
    exports: [
        FileRetryViewComponent,
    ],
    entryComponents: [
        FileRetryViewComponent
    ]
})

export class FileRetryModule { }
