import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-json-settings',
  templateUrl: './json-settings.component.html',
  styleUrls: ['./json-settings.component.scss']
})
export class JsonSettingsComponent implements OnInit {

  @Input() jsonSettings = [];
  
  constructor() { }

  ngOnInit() {
  }

}
