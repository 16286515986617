<div class="px-24">


  <div fxLayout="row" class="mt-8">
      <div>
          <h2 class="m-0">
              Preview
          </h2>
      </div>
      <span fxFlex></span>
      <div>
          <button (click)="close()" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
              <mat-icon>close</mat-icon>
          </button>
      </div>
  </div>
  <div *ngIf="templateData">
    <div class="p-12" *ngIf="templateData.isMultiLang">
        <div fxLayout="row" >
            <div>
                <button class="mr-8" [color]="(htmlPreviewLang===item.id) ? 'primary' : ''" mat-raised-button (click)="htmlPreviewLang=item.id" *ngFor="let item of languages" > {{ item.title }} </button>
            </div>
            <span fxFlex></span>

        </div>
        <div *ngIf="previewView==='html'">
            <h2>Html preview</h2>
            <div style="border: 1px solid #c5c3c3c2; width: 90%; margin: auto; padding: 4px 24px; box-shadow: 10px 10px 5px #c5c4c475;">
                <div *ngIf="templateData.header && templateData.header.translation" [innerHTML]="(templateData.header.translation[htmlPreviewLang] || '') | htmlWithStyle" ></div>
                <div *ngIf="templateData.mainContent && templateData.mainContent.translation" [innerHTML]="(templateData.mainContent.translation[htmlPreviewLang] || '') | htmlWithStyle" ></div>
                <div *ngIf="templateData.footer && templateData.footer.translation" [innerHTML]="(templateData.footer.translation[htmlPreviewLang] || '') | htmlWithStyle" ></div>
            </div>
        </div>
    </div>
    <div *ngIf="!templateData.isMultiLang">
        <h2>Html preview</h2>
        <div style="border: 1px solid #c5c3c3c2; width: 90%; margin: auto; padding: 4px 24px; box-shadow: 10px 10px 5px #c5c4c475;">
            <div [innerHTML]="templateData.header.content | htmlWithStyle" ></div>
            <div [innerHTML]="templateData.mainContent.content | htmlWithStyle" ></div>
            <div [innerHTML]="templateData.footer.content | htmlWithStyle" ></div>
        </div>
    </div>
</div>
</div>