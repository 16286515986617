import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectTemplate } from './select-template.component';
import { TemplateListModule } from '../../template-list/template-list.module';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
  declarations: [SelectTemplate],
  imports: [
    CommonModule,
    FuseSharedModule,
    TemplateListModule,
  ],
  entryComponents: [
    SelectTemplate
  ]
})
export class SelectTemplateModule { }
