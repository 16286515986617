import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DatatableComponent } from './datatable.component';
import { DatatableService } from './datatable.service';
import { FuseSharedModule } from '@fuse/shared.module';
import { ControlTemplateModule } from '../control-template/control-template.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { PrivateFilterComponent } from './private-filter/private-filter.component';
import { SideMenuEditModule } from '../side-menu-edit/side-menu-edit.module';
import { WSAppsSettingsModule } from '../../apps/ws-apps-settings/ws-apps-settings.module';
import { AfterPluginInstallModule } from '../../apps/plugin/after-plugin-install/after-plugin-install.module';
import { OfficeGlobalVarModule } from '../../apps/office-global-var/office-global-var.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { EditFormPopupModule } from '../../apps/custom-registries/edit-form-popup/edit-form-popup.module';
import { DateFilterFieldModule } from '../../components/date-filter-field/date-filter-field.module';
import { LimitDecimalPlacesDigitsDirective } from '../../directives/limitDecimalPlaceDigits.directive';
import { AutofocusModule } from 'angular-autofocus-fix';
import { RecordBulkUpdateComponent } from './record-bulk-update/record-bulk-update.component';
import { DatatableActionsComponent } from './datatable-components/datatable-actions/datatable-actions.component'; 
import { TextMaskModule } from 'angular2-text-mask';
import { DatatableHardFilterComponent } from './datatable-components/datatable-hard-filter/datatable-hard-filter.component';
import { DatatableSuperFilterComponent } from './datatable-components/datatable-super-filter/datatable-super-filter.component';
import { DatatableOfficeFilterComponent } from './datatable-components/datatable-office-filter/datatable-office-filter.component';
import { HeaderActionsComponent } from './datatable-components/header-actions/header-actions.component';
import { FuseSidebarModule } from '@fuse/components';
import { DatatableCardViewComponent } from './datatable-components/datatable-card-view/datatable-card-view.component';
import { CardActionsComponent } from './datatable-components/datatable-card-view/card-actions/card-actions.component';
import { MatBadgeModule } from '@angular/material';
import { CardHeaderMoreOptionsComponent } from './datatable-components/datatable-card-view/card-header-more-options/card-header-more-options.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { DataTableStore } from './state/datatable.store';
import { DataTableQuery } from './state/datatable.query';
import { DtTableReportPopupModule } from '../../components/dt-table-report-popup/dt-table-report-popup.module';
import { DatatableHeaderTmpComponent } from './datatable-components/datatable-header-tmp/datatable-header-tmp.component';
import { DatatableRowTmpComponent } from './datatable-components/datatable-row-tmp/datatable-row-tmp.component';
import { DescriptionPopupModule } from './description-popup/description-popup.module';
import { DtFileListPopupComponent } from './datatable-components/dt-file-list-popup/dt-file-list-popup.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FuseSharedModule,
    ControlTemplateModule,
    NgxChartsModule,
    SideMenuEditModule,
    WSAppsSettingsModule,
    AfterPluginInstallModule,
    OfficeGlobalVarModule,
    NgxDatatableModule,
    DateFilterFieldModule,
    AutofocusModule,
    TextMaskModule,
    FuseSidebarModule,
    MatBadgeModule,
    CKEditorModule,
    DtTableReportPopupModule,
    DescriptionPopupModule
    // EditFormPopupModule
  ],
  exports: [DatatableComponent],
  declarations: [
    DatatableComponent,
    PrivateFilterComponent,
    LimitDecimalPlacesDigitsDirective,
    RecordBulkUpdateComponent,
    DatatableActionsComponent,
    DatatableHardFilterComponent,
    DatatableSuperFilterComponent,
    DatatableOfficeFilterComponent,
    HeaderActionsComponent,
    DatatableCardViewComponent,
    CardActionsComponent,
    CardHeaderMoreOptionsComponent,
    DatatableHeaderTmpComponent,
    DatatableRowTmpComponent,
    DtFileListPopupComponent
  ],
  providers: [
    DatatableService,
    DataTableStore,
    DataTableQuery
  ],
  entryComponents: [
    RecordBulkUpdateComponent,
    DtFileListPopupComponent
  ]
})
export class DatatableModule { }
