import { Component, OnInit, Input } from '@angular/core';
import { TimelineEvent } from 'ngx-timeline';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';

@Component({
  selector: 'app-instance-timeline',
  templateUrl: './instance-timeline.component.html',
  styleUrls: ['./instance-timeline.component.scss']
})
export class InstanceTimelineComponent implements OnInit {
  events: Array<any>;

  @Input() field;
  @Input() instanceId;


  constructor(
    private http: HttpClient,
    private helperService: HelpersService
  ) { }

  ngOnInit() {
    this.getInstanceHistory();
    this.events = new Array<any>();
   }

  getInstanceHistory () {
    if (this.instanceId) {
      this.helperService.getRequest(`api/process/instanceHistory/${this.instanceId}`)
      .then((data) => {
        this.events = [];
        this.events = this.formatData(data) || [];
      }).catch(err => {
        console.info(err);
      });
    } else {
      console.log('No processId');
    }
  }

  formatData(data): any {
    data = data || [];
    const typeMap = {
      'act': 'fa-user-circle',
      'state': 'fa-cogs'
    };
    const temp = [];
    data.forEach((ele) => {
      if (ele.activityType !== 'trigger') {
        temp.push({
          'date': new Date(ele.createdAt || ele.entryDate),
          'header': ele.userEmail,
          'body': `${ele.activityLabel}`,
          'footer': `${ele.comment || ''}`,
          'icon': typeMap[ele.activityType] || 'fa-cogs'
        });
      }
    });
    return temp;
  }

}
