/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ss-type-select.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../pipes/textTranslate.pipe";
import * as i4 from "../../../../../../../node_modules/@angular/material/checkbox/typings/index.ngfactory";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/material/checkbox";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "@angular/common";
import * as i10 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i11 from "@angular/material/button";
import * as i12 from "@angular/cdk/platform";
import * as i13 from "@angular/flex-layout/flex";
import * as i14 from "@angular/flex-layout/core";
import * as i15 from "./ss-type-select.component";
import * as i16 from "../../../../../../@fuse/services/translation-loader.service";
import * as i17 from "../../../services/helpers/helpers.service";
var styles_SsTypeSelectComponent = [i0.styles];
var RenderType_SsTypeSelectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SsTypeSelectComponent, data: {} });
export { RenderType_SsTypeSelectComponent as RenderType_SsTypeSelectComponent };
function View_SsTypeSelectComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "happ-field-title mr-12 mb-8 ml-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " : "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.field.title)); _ck(_v, 1, 0, currVal_0); }); }
function View_SsTypeSelectComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(0, i3.TextTranslatePipe, []), (_l()(), i1.ɵeld(4, 0, null, null, 3, "td", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "mat-checkbox", [["class", "mat-checkbox"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChangeItem(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatCheckbox_0, i4.RenderType_MatCheckbox)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.MatCheckbox]), i1.ɵdid(7, 8568832, null, 0, i6.MatCheckbox, [i1.ElementRef, i1.ChangeDetectorRef, i7.FocusMonitor, i1.NgZone, [8, null], [2, i6.MAT_CHECKBOX_CLICK_ACTION], [2, i8.ANIMATION_MODULE_TYPE]], { checked: [0, "checked"] }, { change: "change" })], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.model.includes(_v.context.$implicit.key); _ck(_v, 7, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_v.context.$implicit.title, _co.currentLang)); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 7).id; var currVal_2 = null; var currVal_3 = i1.ɵnov(_v, 7).indeterminate; var currVal_4 = i1.ɵnov(_v, 7).checked; var currVal_5 = i1.ɵnov(_v, 7).disabled; var currVal_6 = (i1.ɵnov(_v, 7).labelPosition == "before"); var currVal_7 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_SsTypeSelectComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "table", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 3, "td", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SsTypeSelectComponent_5)), i1.ɵdid(12, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.types; _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("ct.ss_type")); _ck(_v, 5, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("ct.selected")); _ck(_v, 9, 0, currVal_1); }); }
function View_SsTypeSelectComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "w-100-p h-400"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("ct.no_types_found")); _ck(_v, 2, 0, currVal_0); }); }
function View_SsTypeSelectComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SsTypeSelectComponent_4)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SsTypeSelectComponent_6)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.types.length; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.types.length; _ck(_v, 4, 0, currVal_1); }, null); }
function View_SsTypeSelectComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "mr-4"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeSingle(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i11.MatButton, [i1.ElementRef, i12.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(2, 0, [" ", " "])), i1.ɵpid(0, i3.TextTranslatePipe, [])], function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_v.context.$implicit.key === _co.model) ? "primary" : ""); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_v.context.$implicit.title, _co.currentLang)); _ck(_v, 2, 0, currVal_3); }); }
function View_SsTypeSelectComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["fxLayout", "row wrap"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i13.DefaultLayoutDirective, [i1.ElementRef, i14.StyleUtils, [2, i13.LayoutStyleBuilder], i14.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SsTypeSelectComponent_8)), i1.ɵdid(4, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row wrap"; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.types; _ck(_v, 4, 0, currVal_1); }, null); }
function View_SsTypeSelectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["fxLayout", "column"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i13.DefaultLayoutDirective, [i1.ElementRef, i14.StyleUtils, [2, i13.LayoutStyleBuilder], i14.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SsTypeSelectComponent_2)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SsTypeSelectComponent_3)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SsTypeSelectComponent_7)), i1.ɵdid(7, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.hideTitle; _ck(_v, 3, 0, currVal_1); var currVal_2 = (!_co.field.singleSelection && _co.types); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.field.singleSelection; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_SsTypeSelectComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SsTypeSelectComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.field.hide || _co.builder); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SsTypeSelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ss-type-select", [], null, null, null, View_SsTypeSelectComponent_0, RenderType_SsTypeSelectComponent)), i1.ɵdid(1, 638976, null, 0, i15.SsTypeSelectComponent, [i16.FuseTranslationLoaderService, i17.HelpersService, i2.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SsTypeSelectComponentNgFactory = i1.ɵccf("app-ss-type-select", i15.SsTypeSelectComponent, View_SsTypeSelectComponent_Host_0, { translateData: "translateData", field: "field", builder: "builder", model: "model" }, { onFieldValueUpdated: "onFieldValueUpdated", modelChange: "modelChange", onError: "onError" }, []);
export { SsTypeSelectComponentNgFactory as SsTypeSelectComponentNgFactory };
