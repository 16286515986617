import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatDialog } from '@angular/material';
import { FileRetryViewComponent } from './file-retry-view/file-retry-view.component';
import { ProfileHelperService } from 'app/main/content/services/profile-helper/profile-helper.service';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';
var FileUploadComponent = /** @class */ (function () {
    function FileUploadComponent(sanitizer, translationLoader, helperService, dialog, officeHelper, profileHelper) {
        this.sanitizer = sanitizer;
        this.translationLoader = translationLoader;
        this.helperService = helperService;
        this.dialog = dialog;
        this.officeHelper = officeHelper;
        this.profileHelper = profileHelper;
        this.onFieldValueUpdated = new EventEmitter();
        this.modelChange = new EventEmitter();
        this.recordMainDocumentChange = new EventEmitter();
        this.loading = false;
        this.downloadLoading = false;
        this.selectedFiles = [];
        // dtOptions: DataTables.Settings = {};
        this.formList = [];
        this.dtTrigger = new Subject();
        this.translationLoader.loadTranslationsV2(this.translateData);
    }
    FileUploadComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var profileData, officeData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.fetchData();
                        return [4 /*yield*/, this.profileHelper.getLocalProfileData()];
                    case 1:
                        profileData = _a.sent();
                        if (profileData) {
                            this.creatorId = profileData.profileId;
                            this.creatorLogin = profileData.email;
                        }
                        return [4 /*yield*/, this.officeHelper.getLocalOfficeData()];
                    case 2:
                        officeData = _a.sent();
                        this.officeId = officeData._id;
                        return [2 /*return*/];
                }
            });
        });
    };
    FileUploadComponent.prototype.ngOnDestroy = function () {
        this.dtTrigger.unsubscribe();
    };
    FileUploadComponent.prototype.fileChanged = function (file) {
        var files = file.files;
        this.selectedFiles = [];
        for (var i = 0; i < files.length; i++) {
            this.selectedFiles.push(files[i]['name']);
        }
    };
    FileUploadComponent.prototype.onKeyPress = function () {
        this.modelChange.next(this.model);
    };
    FileUploadComponent.prototype.deleteFile = function (id) {
        var _this = this;
        this.helperService.openConfirmPopUp('popup.confirm_delete_file_message')
            .subscribe(function (res) {
            if (res) {
                if (_this.recordMainDocument === id) {
                    _this.removeMainDoc();
                }
                _this.helperService.deleteRequest("api/offices/files/" + id)
                    .then(function () {
                    _this.fetchData();
                }).catch(function (err) {
                    console.info(err);
                    _this.helperService.openSnackBar(err);
                });
            }
        });
    };
    FileUploadComponent.prototype.removeMainDoc = function () {
        this.recordMainDocument = '';
        this.recordMainDocumentChange.next(this.recordMainDocument);
    };
    FileUploadComponent.prototype.saveAsMainDoc = function (id) {
        if (id) {
            this.recordMainDocument = id;
            this.recordMainDocumentChange.next(this.recordMainDocument);
        }
    };
    FileUploadComponent.prototype.uploadFile = function (file) {
        var _this = this;
        var files = file.files;
        var formData = new FormData();
        var url = '';
        for (var i = 0; i < files.length; i++) {
            formData.append('uplFile', files[i], files[i].name);
        }
        if (this.regId && this.recId) {
            if (this.recId !== 'new') {
                // tslint:disable-next-line:max-line-length
                url = "api/offices/files/upload?creatorId=" + this.creatorId + "&creatorLogin=" + this.creatorLogin + "&officeId=" + this.officeId + "&recId=" + this.recId + "&regId=" + this.regId;
            }
        }
        else if (this.appId) {
            // tslint:disable-next-line:max-line-length
            url = "api/offices/files/upload?creatorId=" + this.creatorId + "&creatorLogin=" + this.creatorLogin + "&officeId=" + this.officeId + "&appId=" + this.appId;
        }
        this.loading = true;
        this.helperService.postRequest(url, formData)
            .then(function (data) {
            _this.loading = false;
            if (data) {
                _this.fetchData();
            }
        }).catch(function (err) {
            _this.loading = false;
            console.info(err);
        });
    };
    FileUploadComponent.prototype.fetchData = function () {
        var _this = this;
        if (this.recId && this.regId) {
            if (this.recId !== 'new') {
                this.helperService.getRequest("api/offices/files/list?officeId=" + this.officeId + "&recId=" + this.recId)
                    .then(function (data) {
                    _this.fileList = data || [];
                    _this.rerender();
                }).catch(function (err) {
                    console.info(err);
                });
            }
        }
        if (this.appId) {
            this.helperService.getRequest("api/offices/files/list?officeId=" + this.officeId + "&appId=" + this.appId)
                .then(function (data) {
                _this.fileList = data || [];
                _this.rerender();
            }).catch(function (err) {
                console.info(err);
            });
        }
    };
    FileUploadComponent.prototype.openPreview = function (id) {
        var _this = this;
        if (id) {
            this.downloadLoading = true;
            this.helperService.getRequest("api/offices/files/" + id + "/getBase64File")
                .then(function (data) {
                _this.downloadLoading = false;
                if (data) {
                    _this.helperService.downloadDocument(data);
                }
            }).catch(function (err) {
                console.info(err);
                _this.openRetryView(id);
                _this.downloadLoading = false;
            });
        }
    };
    FileUploadComponent.prototype.openRetryView = function (id) {
        var _this = this;
        var temp = this.dialog.open(FileRetryViewComponent, {
            disableClose: false,
            width: '40%',
            height: '400px',
            restoreFocus: false,
            panelClass: 'happ-form-builder-dialog',
            data: {
                url: "api/offices/files/" + id + "/getBase64File",
                id: id
            }
        });
        temp.afterClosed().subscribe(function (data) {
            if (data) {
                _this.helperService.downloadDocument(data);
            }
        });
    };
    FileUploadComponent.prototype.rerender = function () {
    };
    FileUploadComponent.prototype.b64toBlob = function (b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
        var byteCharacters = atob(b64Data);
        var byteArrays = [];
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    };
    FileUploadComponent.prototype.ngAfterViewInit = function () {
        this.dtTrigger.next();
        // this.rerender();
    };
    return FileUploadComponent;
}());
export { FileUploadComponent };
