import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SelectTemplatePopupComponent } from 'app/main/content/components/select-template-popup/select-template-popup.component';
import * as _ from 'lodash';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
  selector: 'app-select-template',
  templateUrl: './select-template.component.html',
  styleUrls: ['./select-template.component.scss']
})
export class SelectTemplateComponent implements OnInit {
  @Input() field: any;
  @Input() templateList: any = [];
  @Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() translateData: any;
  @Input() model: string; 
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  
  selectedValue = '';

  constructor(
    public dialog: MatDialog,
    private translationLoader: FuseTranslationLoaderService,
  ) { }

  ngOnInit() {
    this.getSelectedValue();
    this.translationLoader.loadTranslationsV2(this.translateData);
  }

  selectTemplate() {
    this.dialog.open(SelectTemplatePopupComponent, {
      width: '100%',
      data: { templateList: this.templateList, formId: null},
      panelClass: 'happ-form-builder-dialog'
    }).afterClosed().subscribe(result => {
      if (result && result._id) {
        this.model = result._id;
        this.modelChange.next(this.model);
        this.getSelectedValue();
      }
    });
  }

  getSelectedValue() {
    if (this.model && this.templateList) {
      this.templateList = this.templateList || [];
      const data: any = _.find(this.templateList, { _id: this.model });
      this.selectedValue = `${(data && data.title) || ''} (${this.model})`;
    }
  }

}
