import { Pipe, PipeTransform } from '@angular/core';
import mqFilter from 'app/main/content/services/mqFilter.service';
import * as _ from 'lodash';


@Pipe({
    name: 'mqFilter',
    pure: false
})
export class MqFilterPipe implements PipeTransform
{
    /**
     * Transform
     *
     * @param value
     * @param {string[]} args
     * @returns {any}
     */
    transform(value: any, filter: string, type: 'array' | 'string' = 'array', counter?: any): any
    {
        switch (type) {
            case 'string':
                value = value || {};
                if (!filter) {
                    return true;
                } else {
                    const tt = mqFilter(filter, {});
                    return this.filterStep(tt, value);
                }
            break;
            default:
                value = value || [];
                if (!filter) {
                    return value;
                } else {
                    const tt = mqFilter(filter, {});
                    value = value || [];
                    // return _.filter(value, tt || {});
                    return value.filter((tempValue: any) => {
                        return this.filterStep(tt, tempValue);
                    });
                }
        }
    }

    // get first key of the object
    getKey(obj: any = {} ) {
        return Object.keys(obj)[0];
    }

    filterStep(filterObj: any = {}, value: any) {
        const objKey = this.getKey(filterObj);
        if (objKey.startsWith('$') && Array.isArray(filterObj[objKey])) {
            switch (objKey) {
                case '$and':
                    const temp = filterObj[objKey].filter((ele: any) => {
                        const t1 = this.filterStep(ele, value);
                        return t1;
                    });
                    return (filterObj[objKey] && temp.length === filterObj[objKey].length);
                case '$or':
                    const temp2 = filterObj[objKey].filter((ele: any) => {
                        return this.filterStep(ele, value);
                    });
                    return (filterObj[objKey] && temp2.length > 0);
            }
            return true;
        } else {
            return this.filterStep2(filterObj, value);        
        }
    }

    filterStep2(filterObj: any = {}, value: any) {
        const objKey = this.getKey(filterObj);
        if (typeof(filterObj[objKey]) === 'object' && this.getKey(filterObj[objKey]) && this.getKey(filterObj[objKey]).startsWith('$')) {
            const innerObjValue = filterObj[objKey][this.getKey(filterObj[objKey])];
            switch (this.getKey(filterObj[objKey])) {
                case '$ne':
                    return (innerObjValue !== value[objKey]);    
                case '$eq':
                    return (innerObjValue === value[objKey]);
                case '$lt':
                    return (innerObjValue > value[objKey]);
                case '$lte':
                    return (innerObjValue >= value[objKey]);
                case '$gt':
                    return (innerObjValue < value[objKey]);
                case '$gte':
                    return (innerObjValue <= value[objKey]);
                case '$in':
                    return (innerObjValue && innerObjValue.includes(value[objKey]));
                case '$nin':
                    return (innerObjValue && !innerObjValue.includes(value[objKey]));
                case '$regex':
                    return (value[objKey] && value[objKey].includes(innerObjValue));
            }
            return true;
        } else {
            return (filterObj[objKey] === value[objKey]);
        }
    }
}
