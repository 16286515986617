<div>
  <div class="example-card mb-8">
    <div class="w-100-p mb-8" fxLayout="row wrap" style="background: #0499e2;
    color: white;
    padding: 8px;">
      <p class="ml-12" style="font-size: 18px; margin: auto;">
        {{ 'popup.add_app' | translate }}
      </p>
      <span fxFlex></span>
      <div>
        <button (click)="dialogRef.close(null);" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div fxLayout="row wrap" class="my-16 p-12" style="justify-content: space-evenly;">
    <div (click)="dialogRef.close('no_menu_layout')" class="p-4 happ-workspace-tab" aria-label="create_custom_app" fxLayout="column" fxLayoutAlign="center center">
      <div>
        <img class="w-300" src="/assets/layout/no_menu.png" alt="No Menu">
      </div>
      <p class="font-size-16">
        {{ 'popup.no_menu_layout' | translate }}
      </p>
    </div>
    <div (click)="dialogRef.close('small_icon_layout')" class="p-4 happ-workspace-tab" aria-label="create_office_app" fxLayout="column" fxLayoutAlign="center center">
      <div>
        <img class="w-300" src="/assets/layout/small_icon.png" alt="Small icon Menu">
      </div>
      <p class="font-size-16">
        {{ 'popup.small_icon_layout' | translate }}
      </p>
    </div>
    <div (click)="dialogRef.close('icon_with_text_layout')" class="p-4 happ-workspace-tab" aria-label="create_market_app" fxLayout="column" fxLayoutAlign="center center">
      <div>
        <img class="w-300" src="/assets/layout/big_menu.png" alt="Big Menu">
      </div>
      <p class="font-size-16">
        {{ 'popup.icon_with_text_layout' | translate }}
      </p>
    </div>
  </div>
</div>
