import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';

@Component({
  selector: 'app-ct-add-service-variant',
  templateUrl: './ct-add-service-variant.component.html',
  styleUrls: ['./ct-add-service-variant.component.scss']
})
export class CtAddServiceVariantComponent implements OnInit {

  loading = false;
  officeId: string;
  prodData: any = {};
  fieldOptions = [];
  constructor(
    private _helperService: HelpersService,
    public dialogRef: MatDialogRef<CtAddServiceVariantComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _officeHelper: OfficeHelpersService
  ) { }

  ngOnInit() {
    this.prodData = (this.data && this.data.prodData) || {};
    this._officeHelper.getLocalOfficeData()
      .then((data) => {
        this.officeId = data._id;
      }).catch(err => {
        console.error(err);
      });
  }

  addOption() {
    this.dialogRef.close(this.prodData);
  }

}
