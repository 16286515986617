import { Component, OnInit, AfterViewInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import * as _ from 'lodash';

@Component({
  selector: 'template-select-dialog',
  templateUrl: 'select-template.component.html',
})
// tslint:disable-next-line:component-class-suffix
export class SelectTemplate implements OnInit, AfterViewInit {

  selectedTemplate: any;
  selectedTemplateList = [];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SelectTemplate>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data.allowMultipleSelection) {
      this.selectedTemplate = this.data['selectedTemplate'] || [];
      this.selectedTemplateList = this.selectedTemplate.map((ele) => ({ _id: (ele.templateId && ele.templateId._id) }));
    } else {
      this.selectedTemplate = this.data['selectedTemplate'] || this.selectedTemplate || '';
    }
  }

  ngAfterViewInit(): void {
  }

  closeModal(data) {
    if (data) {
      if (this.data.allowMultipleSelection) {
        this.selectedTemplateList = this.selectedTemplateList.filter((ele) => (ele && ele._id));
        this.selectedTemplate = this.selectedTemplateList.map((ele) => ({ templateId: {_id: ele._id, title: ele.title, showAsMainDocument: ele.showAsMainDocument} }));
        this.dialogRef.close(this.selectedTemplate);
      } else {
        this.dialogRef.close(this.selectedTemplate);
      }
    } else {
      this.dialogRef.close();
    }
  }

}
