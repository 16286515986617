import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';
import { DescriptionPopupComponent } from 'app/main/content/global-component/datatable/description-popup/description-popup.component';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';


@Component({
  selector: 'app-call-history',
  templateUrl: './call-history.component.html',
  styleUrls: ['./call-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CallHistoryComponent implements OnInit {
  rows: Array<any>;
  loadingIndicator = false;
  // tslint:disable-next-line:max-line-length
  requestData = { 
    'page': 
    { 'size': 10, 'totalElements': 0, 'totalPages': 0, 'pageNumber': 0 }, 
    'columns': [
      { 'flexGrow': 1, 'prop': 'registredDataTime', 'title': 'popup.registredDataTime' }, 
      { 'flexGrow': 1, 'prop': 'from', 'title': 'popup.from' }, 
      { 'flexGrow': 1, 'prop': 'to', 'title': 'popup.to' }, 
      { 'flexGrow': 3, 'prop': 'reporter', 'title': 'popup.reporter' }, 
      { 'flexGrow': 1, 'prop': 'category', 'title': 'popup.category' }, 
      { 'flexGrow': 1, 'prop': 'reportData', 'title': 'popup.reportData' }, 
      { 'flexGrow': 1, 'prop': 'callTime', 'title': 'popup.callTime' }, 
      { 'flexGrow': 1, 'prop': '_id', 'title': 'Actions' }
    ], 
    'sort': 
    { 'prop': 'registredDataTime', 'dir': 'desc' }, 
    type: 'sharedOffice' 
  };

  extraCols = [
    {
      'prop': 'callState',
      title: 'callState'
    },
    {
      'prop': 'script',
      title: 'script'
    },
    {
      'prop': 'officeId',
      title: 'officeId'
    },

  ];
  query: string;
  officeId;
  loading = false;
  constructor(
    private helperService: HelpersService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CallHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _changeDetectorRef: ChangeDetectorRef,
    private officeHelper: OfficeHelpersService
  ) { }

  ngOnInit() {
    this.officeHelper.getLocalOfficeData()
    .then(data => {
      this.officeId = data._id;
      this.reRenderList();
    });
  }

  toggleType(type) {
    if (this.requestData.type !== type) {
      this.requestData.type = type;
      this.reRenderList();
    }
    // if (this.requestData.type === 'sharedOffice') {
    // } else {
    //   this.requestData.type = 'sharedOffice';
    //   this.reRenderList();
    // }
  }

  reRenderList() {
    // rerender
    this.loading = true;
    this.rows = [];
    const temp = JSON.parse(JSON.stringify(this.requestData));
    temp.columns = temp.columns.concat(this.extraCols);
    this.helperService.makeSharedServiceRequest('post', this.officeId, 'api/shared-service/rec-list', temp)
      .then((data: any) => {
        this.rows = data.data;
        this.requestData.page = data.page;
        this._changeDetectorRef.detectChanges();
        this.loading = false;
      }).catch(err => {
        console.info(err);
        this.loading = false;
      });
  }

  setPage(pageInfo) {
    this.requestData.page.pageNumber = pageInfo.offset;
    this.loadingIndicator = true;
    this.reRenderList();
  }

  filter(query) {
    this.requestData['search'] = {
      value: query || '',
      regex: false
    };
    this.reRenderList();
  }

  onSort(event) {
    this.loadingIndicator = true;
    this.requestData['sort'] = (event.sorts && event.sorts[0]);    
    this.reRenderList();
  }

  close() {
    this.dialogRef.close();
  }

  openDescriptionPopup(value) {
    this.dialog.open(DescriptionPopupComponent, {
      width: '60%',
      height: '60%',
      panelClass: 'happ-form-builder-dialog',
      restoreFocus: false,
      data: { type: 'html', value: value }
    });
  }

  edit(item: any, i: any) {
    item = this.rows[i] || item;
    item.recId = item._id;
    item.ownerOfficeId = item.officeId;
    item.operator = item.operator || {};
    item.operator.email = item.reporter;
    console.log('***** index', item, i);
    this.helperService.openCallPopup(item);
  }
}
