import { Injectable } from '@angular/core';
import { IOfficeData, IGroup, IOfficeUser } from '../../interfaces/office.interface';
import * as _ from 'lodash';
import { HelpersService } from '../helpers/helpers.service';
import { IMainMenuItem } from '../../interfaces/mainMenuItem.interface';
import { reject } from 'lodash';
import { OfficeStore } from '../../state/officeState/office.store';

@Injectable()
export class OfficeHelpersService {

  public officeData: IOfficeData;
  public localOfficeData: IOfficeData;

  constructor(
    private helperService: HelpersService,
    private officeStore: OfficeStore,

  ) { }

  /**
   * 
   * @param officeId 
   * get office data from server
   */
  getCurrentOfficeData(officeId: string): IOfficeData  {
    return <IOfficeData>{};
  }

  /**
   * return office data from local storage
   */
  getLocalOfficeData(): Promise<IOfficeData> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))) {
        this.officeData = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'));
        resolve(JSON.parse(localStorage.getItem('ngStorage-selectedOffice')));
      } else {
        reject('No Office Doc Found. Please Make Sure you have some Office selected');
      }
    });
  }

  
  getOfficeApps(): any {
    if (this.officeData) {
      return this.officeData.apps || {} as any;
    } else {
      throw new Error('No office data...');
    }
  }

  /**
   * this is to get calendar id from the office doc...
   */
  getOfficeCalendarId(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.officeData) {
        if (this.officeData.apps && this.officeData.apps['Agenda'] && this.officeData.apps['Agenda']['id']) {
          resolve(this.officeData.apps['Agenda']['id']);
        } else {
          reject('No Calendar App found in the office Doc');
        }
      } else {
        reject('No Office Doc Found. Please Make Sure you have some Office selected');        
      }
    });
  }

  getOfficeAddressBookId(): string {
    return '';
  }

  getOfficeGroups(): Promise<Array<IGroup>> {
    return new Promise((resolve, reject) => {
      this.getLocalOfficeData()
      .then((officeData: IOfficeData) => {
        resolve(officeData.groups || []);
      }).catch(reject);
    });
  }

  updateLocalOfficeData(officeData: IOfficeData) {
    localStorage.setItem('ngStorage-selectedOffice', JSON.stringify(officeData));
  }

  getOfficeUsers(): Promise<Array<IOfficeUser>> {
    return new Promise((resolve, reject) => {
      this.getLocalOfficeData()
      .then((officeData: IOfficeData) => {
        resolve(officeData.users || []);
      }).catch(reject);
    });
  }

  getOfficeUser(userId: string): Promise<IOfficeUser> {
    return this.getOfficeUsers()
    .then((officeUsers: Array<IOfficeUser>) => {
      officeUsers = officeUsers || [];
      const tempIndex = _.findIndex(officeUsers, {id: userId});
      if (tempIndex >= 0) {
        return officeUsers[tempIndex];
      } else {
        return null;
      }
      // return officeUsers;
    });
  }

  getOfficeData(officeId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (officeId) {
        this.helperService.getRequest(`api/offices/${officeId}`)
        .then(resolve).catch(reject);
      } else {
        reject('No officeId');
      }
    });
  }

  refreshLocalOfficeData() {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
        this.getOfficeData(JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'])
        .then((officeData: any) => {
          if (officeData) {
            localStorage.setItem('ngStorage-selectedOffice', JSON.stringify(officeData));
          }
          this.getLocalOfficeData();
          this.officeStore.update(officeData);
          resolve(officeData);
        }).catch(err => {
          console.info(err);
          reject(err);
        });
      } else {
        console.log('No officeId in local storage');
      }
    })
  }

  refreshLocalOfficeDataUsingObject(officeData: any) {
    if (officeData) {
      localStorage.setItem('ngStorage-selectedOffice', JSON.stringify(officeData));
    }
  }

  updateOfficeUserData(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
        this.helperService.putRequest(`api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/logged-user-profile`, data)
        .then(res => {
          this.refreshLocalOfficeData();
          resolve(res);
        }).catch(reject);
      } else {
        reject('No officeId found');
      }
    });
  }
  
  getOfficeMainMenu(): Promise<Array<IMainMenuItem>> {
    return new Promise((resolve, reject) => {
      try {
        resolve([]);
        // this.getLocalOfficeData();
        // const officeApps = this.getOfficeApps();
        // if (officeApps && officeApps['mainMenu'] && officeApps['mainMenu']['id']) {
        //   this.helperService.getRequest(`api/offices/${this.officeData._id}/apps/main-menu`)
        //   .then((mainMenu: Array<IMainMenuItem>) => {
        //     resolve(mainMenu || []);
        //   }).catch(err => {
        //     console.info(err);
        //     reject(err);
        //   });
        // } else {
        //   reject('No main Menu found in the office doc');
        // }
      } catch (err) {
        reject(err);
      }
    });
  }

  updateOfficeDoc(updatedDoc: any): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.getLocalOfficeData();
        const officeApps = this.getOfficeApps();
        if (this.officeData._id && updatedDoc) {
          this.helperService.patchRequest(`api/offices/${this.officeData._id}`, updatedDoc)
          .then((mainMenu: Array<IMainMenuItem>) => {
            resolve(mainMenu || []);
          }).catch(err => {
            console.info(err);
            reject(err);
          });
        } else {
          reject('No office id or data is null');
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  getOfficeFilters(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getLocalOfficeData()
      .then((officeData: IOfficeData) => {
        officeData = officeData || <IOfficeData>{};
        resolve(officeData.officeFilters || []);
      }).catch(err => {
        reject(err);
      });
    });
  }

  async loadNewOffice(officeId: string) {
    try {
      window.localStorage.removeItem("lastSelectedOrg")
      window.localStorage.removeItem("selectedVirtualOrgId")
      const officeDoc = await this.helperService.getRequest(`api/offices/${officeId}`);
      if (officeDoc) {
        localStorage.setItem('selectedOfficeId', JSON.stringify(officeDoc['_id']));
        localStorage.setItem('ngStorage-selectedOffice', JSON.stringify(officeDoc));
        return JSON.parse(JSON.stringify(officeDoc));
      } else {
        console.error('No office doc found');
      }
    } catch (error) {
      throw error;
    }
  }

  async getOfficeList(query = '') {
    try {
     return await this.helperService.getRequest(`api/offices${query}`) || [];
    } catch (error) {
      throw error;
    }
  }
}
