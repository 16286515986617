<ng-container *ngIf="!isControlType">
    <ck-editor *ngIf="!all"  name="editor1" [config]="config" (change)="onValueChange()" [(ngModel)]="model" skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>
    <ck-editor *ngIf="all" name="editor1" (change)="onValueChange()" [(ngModel)]="model" skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>
</ng-container>

<ng-container *ngIf="isControlType">
    <div style="position: relative;">
    <p class="happ-placeholder" *ngIf="!defaultEditMode">{{ title | translate }}</p>
    <div *ngIf="!defaultEditMode" (click)="defaultEditMode=true" class="happ-html-preview-area" [innerHTML]="model | htmlWithStyle">
    </div>
    <div style="border: 1px solid #e0e0e0; border-bottom: 0px;" fxLayout="row" class="w-100-p" *ngIf="defaultEditMode" >
        <div>
        <p class="m-0 ml-8 font-weight-700" style="margin-top: 10px !important;">
            {{ title | translate }}
        </p>
        </div>
        <span fxFlex></span>
        <div fxLayout="row">
        <div style="border-left: 1px solid #e0e0e0;">
            <button color="warn" mat-icon-button (click)="closeEditor()">
            <mat-icon>close</mat-icon>
            </button>
        </div>
        <div style="border-left: 1px solid #e0e0e0;">
            <button class="happ-square-button" style="background: lightgreen;" mat-icon-button (click)="saveData()">
            <mat-icon>check</mat-icon>
            </button>
        </div>
        </div>
    </div>
    <ck-editor *ngIf="defaultEditMode" (change)="onValueChange()" name="editor1" class="w-100-p" [config]="config" [(ngModel)]="model" skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>
    </div>

</ng-container>