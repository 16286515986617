import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';
import { SmtpFormComponent } from '../smtp-form/smtp-form.component';

@Component({
  selector: 'app-smtp-list',
  templateUrl: './smtp-list.component.html',
  styleUrls: ['./smtp-list.component.scss']
})
export class SmtpListComponent implements OnInit {
  smtpSettings: any;
  loading: boolean;

  constructor(
    public dialogRef: MatDialogRef<SmtpListComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _officeHelper: OfficeHelpersService,
    private helperService: HelpersService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getLocalOfficeFilter();
  }

  async getLocalOfficeFilter() {
    try {
      const temp = await this.helperService.getRequest("api/office-smtp");
      this.smtpSettings = temp.filter((ele: any) => (ele && ele.type === 'default'));
    } catch (error) {
      alert(error);
    }
  }

  async save() {
    try {
      this.loading = true;
      await this._officeHelper.updateOfficeDoc({ smtp: this.smtpSettings })
      await this._officeHelper.refreshLocalOfficeData();
      this.loading = false;
      this.helperService.showInfo("Updated success", "Success", "success");
      this.dialogRef.close();
    } catch (error) {
      this.loading = false;
      alert(error);
    }
  }
  
  close() {
    this.dialogRef.close();
  }

  addOrEdit(i) {
    const data = this.dialog.open(SmtpFormComponent, {
      disableClose: false,
      width: '100%',
      height: '100%',
      panelClass: 'happ-form-builder-dialog',
      data: {
        type: "smtpConfig",
        smtpSettings: this.smtpSettings[i]
      }
    });

    data.afterClosed()
      .subscribe((response) => {
        if (response) {
          this.getLocalOfficeFilter();
        }
      });
  }

}
