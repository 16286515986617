import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';
var RecordBulkUpdateComponent = /** @class */ (function () {
    function RecordBulkUpdateComponent(dialogRef, data, _helperService, officeService, _translationLoader) {
        this.dialogRef = dialogRef;
        this.data = data;
        this._helperService = _helperService;
        this.officeService = officeService;
        this._translationLoader = _translationLoader;
        this.reqData = {
            updatedDoc: [{}]
        };
        this.loading = false;
        this.step = "1";
        this.query = '';
        this.mailingOperation = 'create';
        this.actions = [
            {
                title: 'bulk_action.add_to_mailing_list',
                desc: 'bulk_action.add_to_mailing_list_desc',
                type: 'mailingList'
            },
            {
                title: 'bulk_action.update',
                desc: 'bulk_action.update_desc',
                type: 'update'
            },
            {
                title: 'bulk_action.delete',
                desc: 'bulk_action.delete_desc',
                type: 'delete'
            },
            {
                title: 'bulk_action.tags',
                desc: 'bulk_action.tags_desc',
                type: 'tags'
            },
        ];
        this.mailingList = [];
    }
    RecordBulkUpdateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.data.fields = this.data.fields || [];
        this.data.fields = this.data.fields.filter(function (ele) { return (ele && (ele.control_type === 'contact-search' || ele.control_type === 'contact_pick' || ele.control_type === 'delivery_address')); });
        this.apps = this.officeService.getOfficeApps();
        this.getMailingList();
        this.getAddressbookRegister();
        if (this.data && this.data.isTask) {
            this.reqData.actionType = 'processTask';
            this.reqData.taskObject = this.data.taskObject;
        }
        // this.reqData.regId = this.data.regId;
        this.reqData.columnFields = this.data.columnFields || [];
        this.reqData.dtTableFilter = this.data.dtTableFilter || {};
        if (this.data.mqHardFilter) {
            if (this.reqData.dtTableFilter.mqFilter) {
                this.reqData.dtTableFilter.mqFilter = this.reqData.dtTableFilter.mqFilter + " AND " + this.data.mqHardFilter;
            }
            else {
                this.reqData.dtTableFilter.mqFilter = this.data.mqHardFilter;
            }
        }
        this._helperService.getTrans('bulk_action')
            .then(function (data2) {
            _this._translationLoader.loadTranslationsV2(data2);
        }).catch(function (err) {
            console.info(err);
        });
    };
    RecordBulkUpdateComponent.prototype.confirmBulkAction = function () {
        var _this = this;
        if (this.reqData.actionType === "mailingList") {
            this.reqData.name = this.listName;
            this.reqData.listId = this.listId;
        }
        if (this.reqData.actionType === "tags") {
            this.reqData.field = this.selectedField;
            this.reqData.tags = this.tags;
            this.reqData.updatedDoc = this.reqData.updatedDoc || [];
            // this.reqData.updatedDoc = this.reqData.updatedDoc.filter((ele) => (ele && ele.checked));
        }
        if (this.reqData.actionType === "mailingList" && !this.listName) {
            return this._helperService.showInfo("Enter list name", "Error", "error");
        }
        // if (this.reqData.actionType === "tags" && !this.tags) {
        //   return this._helperService.showInfo("Enter tags.", "Error", "error");
        // }
        if (this.reqData.actionType === "mailingList") {
            if (this.mailingOperation === "update" || this.mailingOperation === "create") {
                var message = '';
                if (this.mailingOperation === "create") {
                    message = "You are about to update mailing list. Are you sure you want to do?";
                }
                else {
                    message = "You are about to create new mailing list. Are you sure you want to do?";
                }
                this._helperService.openConfirmPopUp(message)
                    .subscribe(function (data) {
                    if (data) {
                        _this.makeApiRequest();
                    }
                });
            }
            else {
                this.makeApiRequest();
            }
        }
        else {
            this.makeApiRequest();
        }
    };
    RecordBulkUpdateComponent.prototype.makeApiRequest = function () {
        var _this = this;
        this.loading = true;
        this.reqData.dtTableReqBody = this.data.dtTableFilter;
        this._helperService.putRequest("api/registries/" + this.data.regId + "/bulkAction", this.reqData)
            .then(function (data) {
            _this._helperService.openSnackBar('Done Bulk Action');
            _this.dialogRef.close(true);
            _this.loading = false;
        }).catch(function (err) {
            _this._helperService.openSnackBar(err);
            _this.loading = false;
        });
    };
    RecordBulkUpdateComponent.prototype.getMailingList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, error_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this;
                        return [4 /*yield*/, this._helperService.getRequest("api/reg/" + this.apps.mailingList.id + "/list")];
                    case 1:
                        _a.mailingList = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _b.sent();
                        this._helperService.showInfo(error_1, "Error", "error");
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    RecordBulkUpdateComponent.prototype.getAddressbookRegister = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, error_2;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this;
                        return [4 /*yield*/, this._helperService.getRegisterData(this.apps.office_addressbook.id)];
                    case 1:
                        _a.addressBook = _b.sent();
                        this.addressBook.tagKeys = this.addressBook.tagKeys || [];
                        this.reqData.updatedDoc = [];
                        this.addressBook.tagKeys.forEach(function (ele) {
                            _this.reqData.updatedDoc.push({
                                keyName: ele.key,
                                value: "",
                                checked: false
                            });
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _b.sent();
                        this._helperService.showInfo(error_2, "Error", "error");
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    RecordBulkUpdateComponent.prototype.addTag = function () {
        this.reqData.updatedDoc.push({
            keyName: this.customTagKey,
            value: "",
            checked: true
        });
        this.customTagKey = '';
        this.customTag = false;
    };
    return RecordBulkUpdateComponent;
}());
export { RecordBulkUpdateComponent };
