import { NgModule } from '@angular/core';
import { AppBuilderComponent } from './app-builder.component';
import { SelectIconModule } from '../workspace-management/select-icon/select-icon.module';
import { GlobalModule } from '@fuse/global.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { CKEditorModule } from 'ngx-ckeditor';
import { UpdateSuccessComponent } from './update-success/update-success.component';


@NgModule({
    imports     : [
        FuseSharedModule,
        GlobalModule,
        SelectIconModule,
        CKEditorModule
    ],
    declarations: [
        AppBuilderComponent,
        UpdateSuccessComponent
    ],
    providers   : [
    ],
    exports: [
        AppBuilderComponent,
        UpdateSuccessComponent
    ],
    entryComponents: [
        AppBuilderComponent,
        UpdateSuccessComponent
    ]
})
export class AddBuilderModule
{
}
