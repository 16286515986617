import { Component, OnInit, Inject } from '@angular/core';
import { HelpersService } from '../../services/helpers/helpers.service';
import { SelectIconComponent } from '../workspace-management/select-icon/select-icon.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UpdateSuccessComponent } from './update-success/update-success.component';

@Component({
  selector: 'app-app-builder',
  templateUrl: './app-builder.component.html',
  styleUrls: ['./app-builder.component.scss']
})
export class AppBuilderComponent implements OnInit {
  loading: boolean;
  regList;
  includingAppsList = [];
  selectedAppsList = [];
  isEdit = false;
  languages = [];
  currentStep = 0;
  dependencies;
  selectedPlugin;
  loadingValue = 0;
  step1Data: any = {};
  regAliases;
  step2Data: any = {
    appType: 'app',
    type: 'workspace',
    versionType: 'small',
    isBeta: false,
    source: {},
    appData: [
      {
        stepLabel: '',
        appSlug: '',
        stepNumber: 3,
        type: 'register',
        regId: '',
        orParams: [],
        andParams: [],
        ansFilter: [],
        scanqueue: {},
        icon: 'note_add'
      }
    ]
  };
  steps = [
    {
      name: 'Select Register',
      index: 0,
      status: 'progress'
    },
    {
      name: 'Enter Alias Names',
      index: 1,
      status: 'pending'
    },
    {
      name: 'Building App',
      index: 2,
      status: 'pending'
    }
  ];
  pluginList: any = [];
  constructor(
    private helperService: HelpersService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AppBuilderComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  ngOnInit() {
    this.getActiveLangs();
    this.getInitData();
  }

  async getInitData() {
    try {
      this.loading = true;
      console.log(this.data);
      this.data.extraData = this.data.extraData || {};
      if (this.data.extraData.createNew) {
        // new
        this.step2Data.source.workspaceSlug = this.data.workspaceSlug;
        this.step1Data.alias_name = Date.now().toString();
        await this.registerSelected(this.data.appRegId);
        this.getAllIncludingApps();
      } else if (this.data.extraData.updateTemplate) {
        // update
        this.isEdit = true;
        this.step2Data.source.workspaceSlug = this.data.workspaceSlug;
        this.step1Data.alias_name = Date.now().toString();
        this.step2Data.versionType = 'small';
        await this.registerSelected(this.data.appRegId);
        this.currentStep = 0;

        this.getAllIncludingApps();
        this.getAllPlugins(this.data.appRegId);
      } else {
        // final case

        const regList = await this.helperService.getSmallRegisterList();
        this.loadingValue = 100;
        this.loading = false;
        this.regList = regList || [];
      }
      this.loading = false;

    } catch (error) {
      console.error(error);
      this.loadingValue = 100;
      this.loading = false;
    }
  }

  async getAllPlugins(regId: string) {
    try {
      this.pluginList = await this.helperService.getRequest(`api/plugins/${regId}`);
    } catch (error) {
      console.error(error);
    }
  }

  getAllIncludingApps() {
    this.regAliases = this.regAliases || [];
    const temp = this.regAliases.map((ele) => (ele && ele.id));
    this.includingAppsList = this.data.appList.filter((ele) => (temp.includes(ele.regId)));
    this.step2Data.appData = this.includingAppsList;
    this.selectedAppsList = this.includingAppsList.map((ele) => (ele.stepNumber));
  }

  toggleChange(item: any) {
    if (!this.selectedAppsList.includes(item.stepNumber)) {
      this.selectedAppsList.push(item.stepNumber);
      delete item.id;
      this.step2Data.appData.push(item);
    } else {
      const index = this.selectedAppsList.indexOf(item.stepNumber);
      this.step2Data.appData = this.step2Data.appData.filter((ele) => (ele.stepNumber !== item.stepNumber));
      if (index !== -1) {
        this.selectedAppsList.splice(index, 1);
      }
    }
  }

  startTicking(time: number) {
    setInterval(() => {
      this.loadingValue += 1;
    }, time);
  }

  async registerSelected(regId: string) {
    try {
      if (!this.step1Data['alias_name']) {
        this.helperService.openSnackBar(`Please enter alias Name`);
        return;
      }
      this.currentStep = 1;
      this.loading = true;
      this.loadingValue = 0;
      this.startTicking(700);
      this.step2Data.source.regId = regId;
      this.step1Data['appId'] = regId;
      this.step2Data['aliasName'] = this.step1Data['alias_name'];
      this.step2Data['pluginKey'] = this.step1Data['alias_name'];
      const data = await this.helperService.postRequest(`api/createApp`, this.step1Data);
  
      this.loadingValue = 100;
      this.loading = false;
      this.dependencies = data || [];
      this.dependencies.forEach(ele => {
        if (ele && ele.name === 'Registers') {
          this.regAliases = ele.list || [];
        }
      });
    } catch (error) {
      this.loadingValue = 100;
      this.loading = false;
      console.info(error);
    }
  }

  selectIcon(index): void {
    const dialogRef = this.dialog.open(SelectIconComponent, {
      width: '700px',
      height: '600px',
      panelClass: 'happ-form-builder-dialog',
      data: { icon: this.step2Data.appData[index].icon }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.step2Data.appData[index].icon = result;
      }
    });
  }

  addAppData() {
    this.step2Data.appData = this.step2Data.appData || [];
    this.step2Data.appData.push({
      stepLabel: '',
      appSlug: '',
      stepNumber: 3,
      type: 'register',
      regId: '',
      orParams: [],
      andParams: [],
      ansFilter: [],
      scanqueue: {},
      icon: 'note_add'
    });
  }

  removeAppData(i) {
    this.step2Data.appData = this.step2Data.appData || [];
    this.step2Data.appData.splice(i, 1);
  }

  async createFromOfficeApp() {
    try {
      // make required..

    const reqFields = [
      'name',
      'smallDesc',
      'description',
      'businessFunction',
      'appSector'
    ];

    if (this.step2Data) {
      let allDone = '';
      reqFields.forEach(ele => {
        if (ele && !this.step2Data[ele]) {
          allDone = allDone + '- ' + ele;
        }
      });
      if (allDone) {
        this.helperService.openSnackBar(`Please Fill ${allDone}`);
        return;
      }
    } else {
      return;
    }

    this.dependencies.forEach(ele => {
      if (ele && ele.name) {
        this.step2Data[ele.name.toLowerCase()] = ele.list || [];
      }
    });


    this.loading = true;
    if (!this.isEdit) {
      await this.helperService.postRequest(`api/offices/officePlugin/createPackageApp`, this.step2Data);
      this.loading = false;
      this.helperService.openSnackBar('App created..');
      this.dialogRef.close(true);
    } else {
      await this.helperService.postRequest(`api/offices/officePlugin/updatePackageApp/${this.step2Data._id}`, this.step2Data);
      this.loading = false;
      this.helperService.openSnackBar('App Update..');
      this.dialogRef.close(true);
      this.dialog.open(UpdateSuccessComponent, {
        width: '400px',
        height: '200px',
        restoreFocus: false,
        panelClass: 'happ-form-builder-dialog'
      });
    }
    } catch (error) {
      console.log(error);
      this.loading = false;
      this.helperService.openSnackBar(
        error || 'Error occured while creating app'
      );
    }
  }

  async getActiveLangs() {
    this.languages = this.helperService.formatTheLanguageArray(await this.helperService.getActiveLangs());

  }

  async pluginSelected(item: any) {
    try {
      this.selectedPlugin = item;
      this.step1Data.alias_name = item.aliasName;
      await this.registerSelected(this.data.appRegId);

      this.step2Data = Object.assign(this.step2Data, item);
      this.currentStep = 1;
    } catch (error) {
      
    }
  }
}
