import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { EditFormPopupComponent } from 'app/main/content/apps/custom-registries/edit-form-popup/edit-form-popup.component';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
var SharedServicePositionComponent = /** @class */ (function () {
    function SharedServicePositionComponent(_helperService, dialog) {
        this._helperService = _helperService;
        this.dialog = dialog;
        this.columns = [
            [
                {
                    title: 'ct.signatura',
                    key: 'signatura'
                },
                {
                    title: 'ct.type',
                    key: 'type'
                }
            ],
            [
                {
                    title: 'ct.serviceName',
                    key: 'serviceName'
                }
            ],
            [
                {
                    title: 'ct.serviceStatus',
                    key: 'processStatusLabel'
                }
            ],
            [
                {
                    title: 'ct.caseStatus',
                    key: 'caseStatus'
                }
            ],
            [
                {
                    title: 'ct.caseManager',
                    key: 'caseManager'
                },
                {
                    title: 'ct.responsiblePerson',
                    key: 'responsiblePerson'
                },
            ],
            [
                {
                    title: 'ct.caseRegisteration',
                    key: 'registredDataTime'
                },
                {
                    title: 'ct.lastModification',
                    key: 'modificatorDateTime'
                },
            ],
            {
                key: "action",
                title: 'Action',
                width: "10px",
                textAlign: "center"
            }
        ];
        this.data = [];
    }
    SharedServicePositionComponent.prototype.ngOnInit = function () {
        this.getData();
    };
    SharedServicePositionComponent.prototype.getData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, error_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        this.loading = true;
                        _a = this;
                        return [4 /*yield*/, this._helperService.getRequest("api/registries/shared-service-position/" + this.recId + "/list")];
                    case 1:
                        _a.data = _b.sent();
                        this.loading = false;
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _b.sent();
                        this.loading = false;
                        console.error(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SharedServicePositionComponent.prototype.editForm = function (item) {
        var _this = this;
        var dialogRef = this.dialog.open(EditFormPopupComponent, {
            width: '100%',
            panelClass: 'happ-form-builder-dialog',
            data: {
                recId: item._id,
                regId: item.regId,
                dialog: this.dialog,
                defaultValue: {},
                action: 'edit',
                title: item.processStatusLabel
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.getData();
            }
        }, function (err) {
            console.info(err);
        });
    };
    return SharedServicePositionComponent;
}());
export { SharedServicePositionComponent };
