import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { environment } from 'environments/environment';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';


@Injectable()
export class ProcessSettingsListService implements Resolve<any>
{
    processList;
    constructor(
        private http: HttpClient,
        private helperService: HelpersService
        )
    {
    }

    /**
     * The register list App Main Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getProcess()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getProcess(): Promise<any> {
        return this.helperService.getProcessList()
        .then((processList) => {
            this.processList = processList || [];
            return processList || [];
        });
    }

}
