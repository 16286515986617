<div class="px-24" fxLayout="column">
    <div fxLayout="row" class="px-8 mb-20" fxLayoutAlign="start center">
        <div>
            <h2> Select Template </h2>
        </div>
        <span fxFlex></span>
        <button class="mx-4" mat-raised-button (click)="closeModal(null)"> Cancel </button>
        <button class="mx-4" mat-raised-button color="warn" (click)="closeModal('done')"> Done </button>
    </div>
    <app-template-list [(singleTemplate)]="selectedTemplate" [fields]="data.fields" [allowSelection]="data.allowSelection" [allowMultipleSelection]="data.allowMultipleSelection"
        [(multipleTemplate)]="selectedTemplateList"></app-template-list>
</div>