import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSummernote } from './ng-summernote';
import { NgSummernoteService } from './ng-summernote.service';


@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    NgSummernote
  ],
  providers: [
    NgSummernoteService
  ],
  exports: [
    NgSummernote
  ]
})
export class NgSummernoteModule { }
