<div fxLayout="column" class="p-12" *ngIf="!field.hide || builder">
  <div fxLayout="row">
    <div fxLayout="row" class="mb-12 w-100-p" fxLayoutAlign="space-between center ">
      <div class="file-upload">
        <label for="upload" class="file-upload__label">{{ (field.title || field.label) | translate }}</label>
        <input id="upload" accept="image/*{{ (field.pdfAndImage) ? ',application/pdf, .xlsx, .xls, .csv':'' }}"
          (change)="fileChanged(file)" class="file-upload__input" name="uplFile" #file type="file">
        {{ selectedFile[field['label']] }} </div>
      <button *ngIf="selectedFile[field['label']]" mat-raised-button color="warn" (click)="uploadFile(file)">
        {{ 'ct.save' | translate }} </button>
    </div>
  </div>
  <ng-container *ngIf="fileName && !getLoading && !finalError">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <button *ngIf="!field.uploadBase64" mat-raised-button (click)="downloadFile()" >{{ 'ct.download' | translate }}</button>
      <p> {{ fileName }} </p>
      <button mat-raised-button (click)="deleteFile()" color="warn" >{{ 'ct.delete' | translate }}</button>
    </div>
    <img  [src]="docBase" style="margin: auto;" *ngIf="docBase && fileType === 'image'" [style.height]="field.imgHeight || '100%'" [style.width]="field.imgWidth || '100%'" alt="img">
    <iframe [src]="docBase" *ngIf="fileType === 'pdf'" [style.height]="field.imgHeight || '100%'" [style.width]="field.imgWidth || '100%'" frameborder="0"></iframe>
  </ng-container>
  <p *ngIf="finalError">
    {{ 'ct.file_not_found_contact_admin' | translate }}
  </p>
  <div *ngIf="getLoading && !finalError">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <p *ngIf="getError === 'retry'">
      {{ 'ct.retry_download' | translate }}
    </p>
  </div>
  <div *ngIf="field.allow_description && field.description" [innerHTML]="field.description | htmlWithStyle" >
  </div>
</div>
<div class="happ-loading" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>