import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseNavigationMenuService } from 'app/navigation/navigation';
import { HelpersService } from '../../services/helpers/helpers.service';
import { IRegister, INg5courseSetting } from '../../interfaces/register.interface';
import { WorkspaceStore } from '../../state/workspaceState/workspace.store';

@Component({
  selector: 'app-select-workspace-app',
  templateUrl: './select-workspace-app.component.html',
  styleUrls: ['./select-workspace-app.component.scss']
})
export class SelectWorkspaceAppComponent implements OnInit {

  @Input() isNotPopup: boolean;
  @Output() modelChange: EventEmitter<any> = new EventEmitter();

  workspaceList = [];
  workspaceApps: any = [];
  selectedWorkspace: any;
  selectedWorkspaceApp: any;

  constructor(
    public dialogRef: MatDialogRef<SelectWorkspaceAppComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _navigationService: FuseNavigationMenuService,
    private _helperService: HelpersService,
    private workspaceStore: WorkspaceStore
  ) { }

  ngOnInit() {
    this.getUsersWorkspace();
  }

  getUsersWorkspace() {
    
    this.workspaceList = this._navigationService.defaultWorkspaceConfig.workspaceList || [];
    this.workspaceList = this.workspaceList.filter((ele) => (/^[0-9a-fA-F]{24}$/.test(ele.id)));
  }

  async selectWorkspace(workspace: any) {
    try {
      const workspaceDoc: IRegister = await this._helperService.getRegisterData(workspace.id);
      this.selectedWorkspace = workspace;
      this.workspaceApps = workspaceDoc.appList || [];
      this.workspaceApps.splice(0, 1);
    } catch (error) {
      this._helperService.showInfo(error, '', 'error');
    }    
  }

  selectApp(workspaceApp: INg5courseSetting) {
    console.log(workspaceApp);
    this.selectedWorkspaceApp = workspaceApp;
    if (this.isNotPopup) {
      this.modelChange.emit({ duplicateWorkspaceId: this.selectedWorkspace.id, duplicateWorkspaceApp: this.selectedWorkspaceApp.stepNumber });
    } else {
      this.performPopupOperation();
    }
  }

  performPopupOperation() {
    if (this.data && this.data.existWithResult) {
      this.dialogRef.close(this.selectedWorkspaceApp);
    } else {
      this._helperService.openConfirmPopUp(`Are you sure you want to copy config of workspaceApp - ${this.selectedWorkspaceApp.stepLabel}`)
      .subscribe((data) => {
        if (data && this.data.workspaceSlug) {
          this.selectedWorkspaceApp['wsAppType'] = 'duplicate-app';
          this._helperService.postRequest(`api/offices/work-space/${this.data.workspaceSlug}/apps/new`, this.selectedWorkspaceApp)
          .then((data2) => {
            this.dialogRef.close(this.selectedWorkspaceApp);
            if (data2) {
              this.workspaceStore.update({ workspaceData: data2 });
              this._helperService.showInfo('Workspace App Duplicated', '', 'success');
            }
          }).catch(err => {
            this._helperService.showInfo(err, '', 'error');
          });
        } else {
          this._helperService.showInfo('No workspace Slug provided', '', 'error');
        }
      });
    }
  }

}
