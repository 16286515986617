/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ext-bank-check.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/flex-layout/flex";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "@angular/common";
import * as i6 from "./ext-bank-check.component";
import * as i7 from "@angular/common/http";
import * as i8 from "@angular/material/dialog";
var styles_ExtBankCheckComponent = [i0.styles];
var RenderType_ExtBankCheckComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExtBankCheckComponent, data: {} });
export { RenderType_ExtBankCheckComponent as RenderType_ExtBankCheckComponent };
function View_ExtBankCheckComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [["style", "margin: 0px; background: green; color: white; padding: 10px; font-size: 18px; text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "a", [["style", "margin: 12px; cursor: pointer;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewDetail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("ct.valid")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("ct.company_detail")); _ck(_v, 5, 0, currVal_1); }); }
function View_ExtBankCheckComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [["style", "margin: 0px; background: red; color: white; padding: 10px; font-size: 18px; text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("ct.invalid")); _ck(_v, 2, 0, currVal_0); }); }
function View_ExtBankCheckComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["fxLayout", "column"], ["style", "position: relative;"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i3.DefaultLayoutDirective, [i1.ElementRef, i4.StyleUtils, [2, i3.LayoutStyleBuilder], i4.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExtBankCheckComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExtBankCheckComponent_3)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.bankRes && (_co.bankRes.statusVat === "Czynny")); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.bankRes && (_co.bankRes.statusVat !== "Czynny")); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_ExtBankCheckComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExtBankCheckComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.bankRes && !_co.field.hide) || _co.builder); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ExtBankCheckComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ext-bank-check", [], null, null, null, View_ExtBankCheckComponent_0, RenderType_ExtBankCheckComponent)), i1.ɵdid(1, 638976, null, 0, i6.ExtBankCheckComponent, [i7.HttpClient, i8.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExtBankCheckComponentNgFactory = i1.ɵccf("app-ext-bank-check", i6.ExtBankCheckComponent, View_ExtBankCheckComponent_Host_0, { field: "field", regRec: "regRec", translateData: "translateData", builder: "builder", autoFocus: "autoFocus", model: "model", isTextarea: "isTextarea" }, { onFieldValueUpdated: "onFieldValueUpdated", modelChange: "modelChange", onError: "onError" }, []);
export { ExtBankCheckComponentNgFactory as ExtBankCheckComponentNgFactory };
