import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { IProcess } from 'app/main/content/interfaces/process.interface';

@Component({
  selector: 'app-move-to-any-status',
  templateUrl: './move-to-any-status.component.html',
  styleUrls: ['./move-to-any-status.component.scss']
})
export class MoveToAnyStatusComponent implements OnInit {

  @Input() model;
  @Input() field;
  @Input() translateData: any;


  @Output() updateRecord: EventEmitter<any> = new EventEmitter();


  statusList;
  status;
  loading = false;

  constructor(
    private helperService: HelpersService
  ) { }

  ngOnInit() {
    this.getProcessDoc();
  }

  async getProcessDoc() {
    try {
      console.log(this.model);
      if (this.model.processId) {
        this.loading = true;
        const data: IProcess = await this.helperService.getProcess(this.model.processId);
        this.loading = false;
        data.activities = data.activities || [];
        this.statusList = data.activities.filter((ele) => {
          if (ele.type === 'state' && ele.kind === 'interactive') {
            return true;
          }
          return false;
        });
      }
    } catch (error) {
      this.loading = false;
      this.helperService.showInfo(error, 'Error', 'error');
      console.error(error);
    }
  }

  moveTo() {
    try {
      if (this.status) {
        this.updateRecord.emit({
          isUpdateRecord: true,
          updatedData: this.model,
          regId: this.model.regId,
          initBehaviour: '',
          recId: this.model._id,
          buttonId: this.status.id,
          type: 'toAnyStatus',
          // tslint:disable-next-line:max-line-length
          navigateTo: `oneRecordView/process/${this.model['processId']}/instances/${this.model['instanceId']}/processing/${this.status.id}/${decodeURI(this.status.label.replace(/\s+/g, '-'))}`
        });
      } else {
        this.helperService.showInfo('Select some Status First', 'Error', 'error');
      }
    } catch (error) {
      this.helperService.showInfo(error, 'Error', 'error');
    }
  }
}
