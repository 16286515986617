import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
var MoveToAnyStatusComponent = /** @class */ (function () {
    function MoveToAnyStatusComponent(helperService) {
        this.helperService = helperService;
        this.updateRecord = new EventEmitter();
        this.loading = false;
    }
    MoveToAnyStatusComponent.prototype.ngOnInit = function () {
        this.getProcessDoc();
    };
    MoveToAnyStatusComponent.prototype.getProcessDoc = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        console.log(this.model);
                        if (!this.model.processId) return [3 /*break*/, 2];
                        this.loading = true;
                        return [4 /*yield*/, this.helperService.getProcess(this.model.processId)];
                    case 1:
                        data = _a.sent();
                        this.loading = false;
                        data.activities = data.activities || [];
                        this.statusList = data.activities.filter(function (ele) {
                            if (ele.type === 'state' && ele.kind === 'interactive') {
                                return true;
                            }
                            return false;
                        });
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        this.loading = false;
                        this.helperService.showInfo(error_1, 'Error', 'error');
                        console.error(error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    MoveToAnyStatusComponent.prototype.moveTo = function () {
        try {
            if (this.status) {
                this.updateRecord.emit({
                    isUpdateRecord: true,
                    updatedData: this.model,
                    regId: this.model.regId,
                    initBehaviour: '',
                    recId: this.model._id,
                    buttonId: this.status.id,
                    type: 'toAnyStatus',
                    // tslint:disable-next-line:max-line-length
                    navigateTo: "oneRecordView/process/" + this.model['processId'] + "/instances/" + this.model['instanceId'] + "/processing/" + this.status.id + "/" + decodeURI(this.status.label.replace(/\s+/g, '-'))
                });
            }
            else {
                this.helperService.showInfo('Select some Status First', 'Error', 'error');
            }
        }
        catch (error) {
            this.helperService.showInfo(error, 'Error', 'error');
        }
    };
    return MoveToAnyStatusComponent;
}());
export { MoveToAnyStatusComponent };
