import * as tslib_1 from "tslib";
import { ProfileHelperService } from './../main/content/services/profile-helper/profile-helper.service';
import { AuthService } from '../main/content/pages/authentication/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { IOfficeData } from 'app/main/content/interfaces/office.interface';
import { CreateWorkspaceComponent } from 'app/main/content/apps/workspace-management/create-workspace/create-workspace.component';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { MatDialog } from '@angular/material';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { Router } from '@angular/router';
import { AuthQuery } from 'app/main/content/state/authState/auth.query';
export var navigation = [];
var FuseNavigationMenuService = /** @class */ (function () {
    function FuseNavigationMenuService(authService, translate, officeHelpersService, dialog, helperService, fuseNavigationService, _fuseSidebarService, _profileHelper, _router, authQuery) {
        var _this = this;
        this.authService = authService;
        this.translate = translate;
        this.officeHelpersService = officeHelpersService;
        this.dialog = dialog;
        this.helperService = helperService;
        this.fuseNavigationService = fuseNavigationService;
        this._fuseSidebarService = _fuseSidebarService;
        this._profileHelper = _profileHelper;
        this._router = _router;
        this.authQuery = authQuery;
        this.updateMenu = new BehaviorSubject(false);
        this.CLIENT_PANEL_THEME = {
            isNotOffice: true,
            logout: function () {
                _this.authService.logOut("cp-login");
            },
            layout: {
                navbar: {
                    hidden: false,
                    folded: false
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        this.CLIENT_PANEL_NAV = [
            {
                'id': 'general',
                'title': 'toolbar.general',
                'translate': 'toolbar.general',
                'type': 'group',
                'children': [
                    {
                        'id': '1',
                        'title': 'toolbar.dashboard',
                        'translate': 'toolbar.dashboard',
                        'type': 'item',
                        'heading': '',
                        'headingDesc': '',
                        'slug': 'dashboard',
                        'sort': 0,
                        'icon': 'home',
                        'url': "/cp/dashboard"
                    }
                ]
            },
            {
                'id': 'cases',
                'title': 'toolbar.cases',
                'translate': 'toolbar.cases',
                'type': 'group',
                'children': [
                    {
                        'id': '15',
                        'title': 'toolbar.active_cases',
                        'translate': 'toolbar.active_cases',
                        'type': 'item',
                        'queryParams': { status: "active" },
                        'heading': '',
                        'headingDesc': '',
                        'slug': 'active_cases',
                        'sort': 15,
                        'icon': 'work',
                        'url': "/cp/my-cases/personal/all"
                    },
                    {
                        'id': '20',
                        'title': 'toolbar.closed_cases',
                        'translate': 'toolbar.closed_cases',
                        'type': 'item',
                        'heading': '',
                        'queryParams': { status: "closed" },
                        'headingDesc': '',
                        'slug': 'closed_cases',
                        'sort': 20,
                        'icon': 'close',
                        'url': "/cp/my-cases/personal/all"
                    },
                    {
                        'id': '21',
                        'title': 'toolbar.archived_cases',
                        'translate': 'toolbar.archived_cases',
                        'type': 'item',
                        'queryParams': { status: "archive" },
                        'heading': '',
                        'headingDesc': '',
                        'slug': 'archived_cases',
                        'sort': 21,
                        'icon': 'archive',
                        'url': "/cp/my-cases/personal/all"
                    },
                    {
                        'id': '24',
                        'title': 'toolbar.new_cases',
                        'translate': 'toolbar.new_cases',
                        'type': 'item',
                        'heading': '',
                        'headingDesc': '',
                        'slug': 'add_circle',
                        'sort': 24,
                        'icon': 'view_agenda',
                        'url': "/cp/services"
                    },
                    {
                        'id': '30',
                        'title': 'toolbar.calendar',
                        'translate': 'toolbar.calendar',
                        'type': 'item',
                        'heading': '',
                        'headingDesc': '',
                        'slug': 'add_circle',
                        'sort': 24,
                        'icon': 'view_agenda',
                        'url': "/cp/calendar"
                    },
                    {
                        'id': '40',
                        'title': 'toolbar.personal_data',
                        'translate': 'toolbar.personal_data',
                        'type': 'item',
                        'heading': '',
                        'headingDesc': '',
                        'slug': 'add_circle',
                        'sort': 24,
                        'icon': 'view_agenda',
                        'url': "/cp/personal-data"
                    },
                    {
                        'id': '30',
                        'title': 'toolbar.room_event_list',
                        'translate': 'toolbar.room_event_list',
                        'type': 'item',
                        'heading': '',
                        'headingDesc': '',
                        'slug': 'add_circle',
                        'sort': 24,
                        'icon': 'view_agenda',
                        'url': "/cp/room-events"
                    },
                ]
            },
            {
                'id': 'financial',
                'title': 'toolbar.financial',
                'translate': 'toolbar.financial',
                'type': 'group',
                'children': [
                    {
                        'id': '30',
                        'title': 'toolbar.orders',
                        'translate': 'toolbar.orders',
                        'type': 'item',
                        'heading': '',
                        'headingDesc': '',
                        'slug': 'orders',
                        'sort': 30,
                        'icon': 'description',
                        'url': "/cp/orders"
                    },
                    {
                        'id': '35',
                        'title': 'toolbar.payments',
                        'translate': 'toolbar.payments',
                        'type': 'item',
                        'heading': '',
                        'headingDesc': '',
                        'slug': 'payments',
                        'sort': 35,
                        'icon': 'payment',
                        'url': "/cp/payments"
                    },
                    {
                        'id': '47',
                        'title': 'toolbar.invoices',
                        'translate': 'toolbar.invoices',
                        'type': 'item',
                        'heading': '',
                        'headingDesc': '',
                        'slug': 'invoices',
                        'sort': 47,
                        'icon': 'description',
                        'url': "/cp/invoices"
                    },
                ]
            },
            {
                'id': 'settings',
                'title': 'toolbar.settings',
                'translate': 'toolbar.settings',
                'type': 'group',
                'children': [
                    {
                        'id': '58',
                        'title': 'toolbar.user_profile',
                        'translate': 'toolbar.user_profile',
                        'type': 'item',
                        'heading': '',
                        'headingDesc': '',
                        'slug': 'user_profile',
                        'sort': 58,
                        'icon': 'face',
                        'url': "/cp/user/profile"
                    },
                    {
                        'id': '58',
                        'title': 'toolbar.office_view',
                        'translate': 'toolbar.office_view',
                        'type': 'item',
                        'heading': '',
                        'headingDesc': '',
                        'slug': 'office_view',
                        'sort': 58,
                        'icon': 'face',
                        'function': function () {
                            _this._profileHelper.getLocalProfileData()
                                .then(function (data) {
                                if (data.lastSelectedOffice && data.offices && data.offices.length) {
                                    _this._router.navigateByUrl("/offices/" + data.lastSelectedOffice + "/dashboard");
                                }
                                else {
                                    _this._router.navigateByUrl("/dashboard");
                                }
                            }).catch(function (err) {
                                console.error(err);
                            });
                        }
                    }
                ]
            }
        ];
        this.SPECIALIST_PANEL_NAV = [
            {
                'id': 'specialist',
                'title': 'toolbar.specialist',
                'translate': 'toolbar.specialist',
                'type': 'group',
                'children': [
                    {
                        'id': '30',
                        'title': 'toolbar.dashboard',
                        'translate': 'toolbar.dashboard',
                        'type': 'item',
                        'heading': '',
                        'headingDesc': '',
                        'slug': 'add_circle',
                        'sort': 24,
                        'icon': 'view_agenda',
                        'url': "/cp/specialist/dashboard"
                    },
                    {
                        'id': '30',
                        'title': 'toolbar.cases',
                        'translate': 'toolbar.cases',
                        'type': 'item',
                        'heading': '',
                        'headingDesc': '',
                        'slug': 'add_circle',
                        'sort': 24,
                        'icon': 'view_agenda',
                        'url': "/cp/specialist/cases/specialist/all"
                    },
                    {
                        'id': '30',
                        'title': 'toolbar.specialist_calendar',
                        'translate': 'toolbar.specialist_calendar',
                        'type': 'item',
                        'heading': '',
                        'headingDesc': '',
                        'slug': 'add_circle',
                        'sort': 24,
                        'icon': 'view_agenda',
                        'url': "/cp/specialist/calendar"
                    }
                ]
            },
            {
                'id': 'settings',
                'title': 'toolbar.settings',
                'translate': 'toolbar.settings',
                'type': 'group',
                'children': [
                    {
                        'id': '58',
                        'title': 'toolbar.user_profile',
                        'translate': 'toolbar.user_profile',
                        'type': 'item',
                        'heading': '',
                        'headingDesc': '',
                        'slug': 'user_profile',
                        'sort': 58,
                        'icon': 'face',
                        'url': "/cp/user/profile"
                    },
                    {
                        'id': '58',
                        'title': 'toolbar.office_view',
                        'translate': 'toolbar.office_view',
                        'type': 'item',
                        'heading': '',
                        'headingDesc': '',
                        'slug': 'office_view',
                        'sort': 58,
                        'icon': 'face',
                        'function': function () {
                            _this._profileHelper.getLocalProfileData()
                                .then(function (data) {
                                if (data.lastSelectedOffice && data.offices && data.offices.length) {
                                    _this._router.navigateByUrl("/offices/" + data.lastSelectedOffice + "/dashboard");
                                }
                                else {
                                    _this._router.navigateByUrl("/dashboard");
                                }
                            }).catch(function (err) {
                                console.error(err);
                            });
                        }
                    }
                ]
            }
        ];
        this.defaultWorkspaceConfig = {
            showWorkspace: true,
            workspaceList: [],
            selectedWorkspace: {
                'id': 'dashboard-workspace',
                'title': 'toolbar.office_dashboard',
                'name': 'toolbar.office_dashboard',
                'translate': 'toolbar.office_dashboard',
                'type': 'item',
                'heading': '',
                'headingDesc': '',
                'sort': 0,
                'icon': 'home',
                'url': "/offices/dashboard"
            }
        };
        this.$workspaceConfig = new BehaviorSubject(this.defaultWorkspaceConfig);
        this.$virtualOrgValue = new BehaviorSubject({});
        this.isAdmin = false;
        this.currentNavigation = [];
        this.langIndex = {
            en: 0,
            pl: 1
        };
        this.customFunctionNavItem = {
            'id': 'custom-function',
            'title': 'Custom Function',
            'type': 'group',
            'icon': 'settings',
            'children': [
                {
                    'id': 'customize',
                    'title': 'Customize',
                    'type': 'item',
                    'icon': 'settings',
                    'function': function () {
                        _this._fuseSidebarService.getSidebar('themeOptionsPanel').toggleOpen();
                    }
                }
            ]
        };
        this.model = [];
        this.authService.loadUserCredentials();
        this.translate.onLangChange.subscribe(function (event) {
            _this.currentLang = event['lang'] || 'en';
        });
        this.authQuery.isAdmin.subscribe(function (isAdmin) {
            _this.isAdmin = isAdmin;
        });
    }
    FuseNavigationMenuService.prototype.checkAdmin = function () {
        return this.isAdmin;
    };
    FuseNavigationMenuService.prototype.getWorkspaceFilterData = function () {
        var officeData = window.localStorage.getItem("ngStorage-selectedOffice");
        if (officeData) {
            officeData = JSON.parse(officeData);
        }
        var virtualOrgObj = localStorage.getItem("lastSelectedOrg");
        if (virtualOrgObj) {
            virtualOrgObj = JSON.parse(virtualOrgObj);
        }
        if (officeData.projects && officeData.projects.multiOrganizationOffice) {
            if (!virtualOrgObj) {
                return {
                    allowFilter: true,
                    workspaceIds: null
                };
            }
            if (virtualOrgObj && virtualOrgObj.virtualOrgWorkspacesIds) {
                var tempIds = virtualOrgObj.virtualOrgWorkspacesIds.split(",").map(function (ele) { return (ele.trim()); }).filter(function (ele) { return (ele); });
                return {
                    allowFilter: true,
                    workspaceIds: tempIds
                };
            }
            else {
                return {
                    allowFilter: false,
                    workspaceIds: []
                };
            }
        }
        else {
            return {
                allowFilter: false,
                workspaceIds: []
            };
        }
    };
    FuseNavigationMenuService.prototype.menuItems = function (orgSettings) {
        var _this = this;
        if (orgSettings === void 0) { orgSettings = {}; }
        console.log("orgSettings = ", orgSettings);
        this.model = [];
        var tempModel = [];
        return new Promise(function (resolve, reject) {
            try {
                if (window.location.pathname.includes("/cp/specialist") && window.location.pathname !== '/cp-login') {
                    return resolve(_this.SPECIALIST_PANEL_NAV);
                }
                else if (window.location.pathname.includes("/cp") && window.location.pathname !== '/cp-login') {
                    // const temp1 = JSON.parse(JSON.stringify(this.CLIENT_PANEL_THEME));
                    // console.log("7777777", temp1);
                    // temp1.logout = () => {
                    //     console.log("8888", this);
                    //     // this.authService.logOut();
                    // };
                    return resolve(_this.CLIENT_PANEL_NAV);
                }
                _this.resetValue();
                _this.fuseNavigationService.setCurrentNavigation('loading');
                _this.officeHelpersService.getLocalOfficeData().then(function (officeData) {
                    _this.selectedOfficeObject = officeData;
                    var officeApps = _this.officeHelpersService.getOfficeApps();
                    _this.officeId = (_this.officeHelpersService.officeData && _this.officeHelpersService.officeData._id);
                    if (officeApps && officeApps['mainMenu'] && officeApps['mainMenu']['id']) {
                        _this.menuId = officeApps['mainMenu']['id'];
                        if (_this.officeId && _this.menuId) {
                            _this.officeHelpersService.getOfficeMainMenu()
                                .then(function (mainMenu) {
                                tempModel = [];
                                tempModel = [];
                                _this.createWorkspaceMenu(orgSettings)
                                    .then(function (tempWSMenu) {
                                    if (tempWSMenu[0] && tempWSMenu[0].children && tempWSMenu[0].children.length) {
                                        tempModel = tempModel.concat(tempWSMenu);
                                    }
                                    var menuList = mainMenu;
                                    menuList = menuList.sort(function (a, b) {
                                        return (a && b && a['weight'] > b['weight']) ? 1 : ((a && b && b['weight'] > a['weight']) ? -1 : 0);
                                    });
                                    menuList.forEach(function (element) {
                                        if (_this.isGroupType(element)) {
                                            var tempObj_1 = _this.getGroupData(element);
                                            menuList.forEach(function (ele) {
                                                if (_this.isSubGroupType(ele)) {
                                                    if (ele['subGroup']) {
                                                        var SubGroup_1 = _this.getSubGroupData(ele);
                                                        menuList.forEach(function (subGroupElement) {
                                                            var params = '';
                                                            // tslint:disable-next-line:max-line-length
                                                            if (subGroupElement && (subGroupElement['state'] === 'app.customregistries.registriesList' || subGroupElement['state'] === 'app.stepperList' || subGroupElement['state'] === 'app.processInstances.dashboards')) {
                                                                var temp_1 = [];
                                                                // tslint:disable-next-line:max-line-length
                                                                if (subGroupElement['fuse2Url'] && (subGroupElement['state'] === 'app.customregistries.registriesList' || subGroupElement['state'] === 'app.stepperList')) {
                                                                    var viewType = (subGroupElement['registerViewType']) ? subGroupElement['registerViewType'] : 'card';
                                                                    params = '/' + viewType;
                                                                    // const expanded = (ele['expanded']) ? ele['expanded'] : 'expanded';
                                                                    // params = '/' + expanded;
                                                                }
                                                                subGroupElement['registerParams'] = subGroupElement['registerParams'] || [];
                                                                subGroupElement['processParams'] = subGroupElement['processParams'] || [];
                                                                // tslint:disable-next-line:max-line-length
                                                                if (subGroupElement['state'] === 'app.customregistries.registriesList' || subGroupElement['state'] === 'app.stepperList') {
                                                                    subGroupElement['registerParams'].map(function (register) {
                                                                        if (register.selected) {
                                                                            temp_1.push(register.key);
                                                                        }
                                                                    });
                                                                }
                                                                if (subGroupElement['state'] === 'app.processInstances.dashboards') {
                                                                    subGroupElement['processParams'].map(function (process) {
                                                                        if (process.selected) {
                                                                            temp_1.push(process.key);
                                                                        }
                                                                    });
                                                                }
                                                                if (subGroupElement['state'] === 'app.stepperList') {
                                                                    params = '';
                                                                }
                                                                params += (temp_1 && temp_1.length ? '/' + temp_1.join(',') : '/all');
                                                            }
                                                            if (subGroupElement && (subGroupElement['state'] === 'app.gdprResources')) {
                                                                var regGroups_1 = [];
                                                                subGroupElement['registerGroups'].map(function (register) {
                                                                    if (register.selected) {
                                                                        regGroups_1.push(register.key);
                                                                    }
                                                                });
                                                                params = "/gdpr-resources/" + regGroups_1.join(',');
                                                            }
                                                            // tslint:disable-next-line:max-line-length
                                                            if (subGroupElement && (subGroupElement['state'] === 'app.pinboxOfficeContacts2.contacts') && _this.selectedOfficeObject && _this.selectedOfficeObject['apps'] && _this.selectedOfficeObject['apps']['office_addressbook'] && _this.selectedOfficeObject['apps']['office_addressbook']['id']) {
                                                                params = "/" + _this.selectedOfficeObject['apps']['office_addressbook']['id'];
                                                            }
                                                            // tslint:disable-next-line:max-line-length
                                                            if (subGroupElement && (subGroupElement['state'] === 'app.globalCalendar') && _this.selectedOfficeObject && _this.selectedOfficeObject['apps'] && _this.selectedOfficeObject['apps']['Agenda'] && _this.selectedOfficeObject['apps']['Agenda']['id']) {
                                                                params = "/" + _this.selectedOfficeObject['apps']['Agenda']['id'];
                                                            }
                                                            // tslint:disable-next-line:max-line-length
                                                            if (ele && ele['type'] && subGroupElement && !subGroupElement['subGroup'] && !subGroupElement['hidden'] && subGroupElement['navigation'] && subGroupElement['navigation'].split('.').length >= 3 && subGroupElement['navigation'].split('.').includes(ele['type'])) {
                                                                SubGroup_1['children'].push(_this.getMenuItemChildren(subGroupElement, params));
                                                            }
                                                        });
                                                        // tslint:disable-next-line:max-line-length
                                                        if (ele && !ele['hidden'] && ele['navigation'] && ele['navigation'].split('.').length > 1 && ele['navigation'].split('.').includes(element['navigation'])) {
                                                            tempObj_1['children'].push(SubGroup_1);
                                                        }
                                                    }
                                                }
                                                else {
                                                    var params = '';
                                                    // tslint:disable-next-line:max-line-length
                                                    if (ele && (ele['state'] === 'app.customregistries.registriesList' || ele['state'] === 'app.stepperList' || ele['state'] === 'app.processInstances.dashboards')) {
                                                        var temp_2 = [];
                                                        // tslint:disable-next-line:max-line-length
                                                        if (ele['fuse2Url'] && (ele['state'] === 'app.customregistries.registriesList' || ele['state'] === 'app.stepperList')) {
                                                            var viewType = (ele['registerViewType']) ? ele['registerViewType'] : 'card';
                                                            params = '/' + viewType;
                                                        }
                                                        ele['registerParams'] = ele['registerParams'] || [];
                                                        ele['processParams'] = ele['processParams'] || [];
                                                        if (ele['state'] === 'app.customregistries.registriesList' || ele['state'] === 'app.stepperList') {
                                                            ele['registerParams'].map(function (register) {
                                                                if (register.selected) {
                                                                    temp_2.push(register.key);
                                                                }
                                                            });
                                                        }
                                                        if (ele['state'] === 'app.processInstances.dashboards') {
                                                            ele['processParams'].map(function (process) {
                                                                if (process.selected) {
                                                                    temp_2.push(process.key);
                                                                }
                                                            });
                                                        }
                                                        if (ele['state'] === 'app.stepperList') {
                                                            params = '';
                                                        }
                                                        params += (temp_2 && temp_2.length ? '/' + temp_2.join(',') : '/all');
                                                    }
                                                    if (ele && (ele['state'] === 'app.gdprResources')) {
                                                        var regGroups_2 = [];
                                                        ele['registerGroups'].map(function (register) {
                                                            if (register.selected) {
                                                                regGroups_2.push(register.key);
                                                            }
                                                        });
                                                        params = "/gdpr-resources/" + regGroups_2.join(',');
                                                    }
                                                    // tslint:disable-next-line:max-line-length
                                                    if (ele && (ele['state'] === 'app.pinboxOfficeContacts2.contacts') && _this.selectedOfficeObject && _this.selectedOfficeObject['apps'] && _this.selectedOfficeObject['apps']['office_addressbook'] && _this.selectedOfficeObject['apps']['office_addressbook']['id']) {
                                                        params = "/" + _this.selectedOfficeObject['apps']['office_addressbook']['id'];
                                                    }
                                                    // tslint:disable-next-line:max-line-length
                                                    if (ele && (ele['state'] === 'app.globalCalendar') && _this.selectedOfficeObject && _this.selectedOfficeObject['apps'] && _this.selectedOfficeObject['apps']['Agenda'] && _this.selectedOfficeObject['apps']['Agenda']['id']) {
                                                        params = "/" + _this.selectedOfficeObject['apps']['Agenda']['id'];
                                                    }
                                                    // tslint:disable-next-line:max-line-length
                                                    if (ele && !ele['hidden'] && ele['navigation'] && ele['navigation'].split('.').length > 1 && ele['navigation'].split('.')[0].includes(element['navigation'])) {
                                                        tempObj_1['children'].push(_this.getMenuItemChildren(ele, params));
                                                    }
                                                }
                                            });
                                            if (tempObj_1 && tempObj_1.children && tempObj_1.children.length) {
                                                tempModel.push(tempObj_1);
                                            }
                                        }
                                    });
                                    // if (this.isAdmin) {
                                    //     tempModel.push(this.customFunctionNavItem);
                                    // }
                                    _this.model = tempModel;
                                    _this.currentNavigation = _this.model;
                                    resolve(tempModel);
                                }).catch(function (err) {
                                    console.info(err);
                                    reject(err);
                                });
                            }).catch(function (err) {
                                console.info(err);
                                reject(err);
                            });
                        }
                    }
                    else {
                        _this.currentNavigation = [];
                        reject([]);
                    }
                }).catch(function (err) {
                    // console.info(err);
                });
            }
            catch (err) {
                console.info(err);
                reject(err);
            }
        });
    };
    FuseNavigationMenuService.prototype.isGroupType = function (menuItem) {
        return (menuItem && menuItem['navigation'] && !menuItem['navigation'].includes('.'));
    };
    FuseNavigationMenuService.prototype.getGroupData = function (menuItem) {
        return {
            'id': menuItem['type'],
            'title': menuItem['title'],
            'translate': this.getTranslatedTitle(menuItem),
            'type': 'group',
            'icon': (menuItem['icon']) ? menuItem['icon'] : '',
            'children': [],
            'sort': menuItem['weight'],
            'params': {}
        };
    };
    FuseNavigationMenuService.prototype.isSubGroupType = function (menuItem) {
        return (menuItem && menuItem['navigation'] && (menuItem['navigation'].split('.').length >= 3 || menuItem['subGroup']));
    };
    FuseNavigationMenuService.prototype.getSubGroupData = function (menuItem) {
        return {
            'id': menuItem['type'],
            'title': menuItem['title'],
            'translate': this.getTranslatedTitle(menuItem),
            'type': (menuItem['subGroupCollapse'] ? 'collapse' : 'group'),
            'sort': menuItem['weight'],
            'icon': menuItem['icon'] || '',
            'children': []
        };
        ;
    };
    FuseNavigationMenuService.prototype.getMenuItemChildren = function (menuItem, params) {
        menuItem = menuItem || {};
        return {
            'id': menuItem['type'],
            'title': menuItem['title'],
            'translate': this.getTranslatedTitle(menuItem),
            'type': 'item',
            'heading': menuItem['heading'],
            'headingDesc': menuItem['headingDesc'],
            'sort': menuItem['weight'],
            'icon': menuItem['icon'] || '',
            'url': (menuItem['fuse2Url']) ? (menuItem['fuse2Url'].replace(':officeId', this.officeId) + params) : ''
        };
    };
    FuseNavigationMenuService.prototype.refreshMenuItems = function () {
        var _this = this;
        try {
            setTimeout(function () {
                console.log("refreshMenuItems = ");
                _this.fuseNavigationService.unregister('main');
                _this.fuseNavigationService.register('main', []);
                _this.fuseNavigationService.setCurrentNavigation('main');
                _this.menuItems(_this.getWorkspaceFilterData()).then(function (response) {
                    _this.fuseNavigationService.unregister('main');
                    _this.fuseNavigationService.register('main', response);
                    _this.fuseNavigationService.setCurrentNavigation('main');
                }).catch(function (error) {
                    _this.helperService.openSnackBar(error || 'Error occured while pulling menu');
                });
            }, 800);
        }
        catch (err) {
            console.info(err);
        }
    };
    FuseNavigationMenuService.prototype.createWorkspaceMenu = function (orgSettings) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var workspaceMenuItems_1, ssMenuItems_1, functionItem, tempObj_2, dashboardItem, data, error_1, err_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("CREATING WD MENU");
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        if (!(false && (!this.selectedOfficeObject.showWorkspace || this.selectedOfficeObject.showWorkspace === 'false'))) return [3 /*break*/, 2];
                        this.$workspaceConfig.next({ type: 'workspaceList', workspaceList: [] });
                        return [2 /*return*/, []];
                    case 2:
                        workspaceMenuItems_1 = {
                            'id': 'Workspaces',
                            'title': 'Obszar roboczy',
                            'translate': 'Obszar roboczy',
                            'type': 'group',
                            'children': [],
                            'sort': 0,
                            'params': {}
                        };
                        ssMenuItems_1 = {
                            'id': 'Workspaces',
                            'title': 'SS workspace',
                            'translate': 'SS workspace',
                            'type': 'group',
                            'children': [],
                            'sort': 0,
                            'params': {}
                        };
                        functionItem = {
                            'id': 'add-workspace',
                            'title': 'Dodaj obszar roboczy',
                            'translate': 'Dodaj obszar roboczy',
                            'type': 'item',
                            'heading': '',
                            'headingDesc': '',
                            'sort': 0,
                            'icon': 'add',
                            'actionType': 'function',
                            'current': this,
                            'function': this.openWorkspace
                        };
                        tempObj_2 = {
                            'name': 'toolbar.dashboard',
                            'url': "/offices/dashboard",
                            'icon': 'home'
                        };
                        dashboardItem = {
                            'id': 'dashboard-workspace',
                            'title': 'toolbar.dashboard',
                            'name': 'toolbar.dashboard',
                            'translate': 'toolbar.dashboard',
                            'type': 'item',
                            'heading': '',
                            'headingDesc': '',
                            'classes': 'happ-nav-small-hide',
                            'sort': 0,
                            'icon': 'home',
                            'url': "/offices/" + this.officeId + "/dashboard",
                            'function': function () { _this.workspaceChange(tempObj_2); _this._router.navigate(["/offices/" + _this.officeId + "/dashboard"]); }
                        };
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 5, , 6]);
                        workspaceMenuItems_1.children = workspaceMenuItems_1.children || [];
                        workspaceMenuItems_1.children.push(dashboardItem);
                        return [4 /*yield*/, this.helperService.getRequest('api/profile/workspaces')];
                    case 4:
                        data = _a.sent();
                        data = data || [];
                        if (orgSettings.allowFilter || (!orgSettings.allowFilter && orgSettings.workspaceIds)) {
                            data.forEach(function (workspace) {
                                if (workspace && workspace.slug && !workspace.archive && workspace.type === "workspace") {
                                    if (orgSettings.workspaceIds.includes(workspace.workspaceId) || (!orgSettings.allowFilter && orgSettings.workspaceIds)) {
                                        workspaceMenuItems_1.children.push({
                                            'id': workspace.workspaceId,
                                            'title': workspace.name,
                                            'translate': workspace.name,
                                            'type': 'item',
                                            'heading': '',
                                            'headingDesc': '',
                                            'slug': workspace.slug,
                                            'sort': workspace.sort,
                                            'icon': workspace.icon,
                                            'function': function () { _this.workspaceChange(workspace); _this._router.navigate(["/workspace/" + workspace.slug + "/dashboard"]); },
                                            'url': "/workspace/" + workspace.slug
                                        });
                                    }
                                }
                            });
                            data.forEach(function (workspace) {
                                if (workspace && !workspace.archive && workspace.type === "ss") {
                                    ssMenuItems_1.children.push({
                                        'id': new Date().getTime(),
                                        'title': workspace.name,
                                        'translate': workspace.name,
                                        'type': 'item',
                                        'heading': '',
                                        'headingDesc': '',
                                        'slug': workspace.slug,
                                        'sort': workspace.sort,
                                        'icon': workspace.icon,
                                        'function': function () { _this.workspaceChange(workspace); _this._router.navigate([workspace.route]); },
                                        'url': workspace.route
                                    });
                                }
                            });
                        }
                        if (this.isAdmin) {
                            workspaceMenuItems_1.children.push(functionItem);
                        }
                        this.defaultWorkspaceConfig.workspaceList = (workspaceMenuItems_1 && workspaceMenuItems_1.children) || [];
                        this.$workspaceConfig.next({ type: 'workspaceList', workspaceList: this.defaultWorkspaceConfig.workspaceList });
                        if (ssMenuItems_1 && ssMenuItems_1.children.length) {
                            return [2 /*return*/, ([workspaceMenuItems_1, ssMenuItems_1])];
                        }
                        else {
                            return [2 /*return*/, ([workspaceMenuItems_1])];
                        }
                        return [3 /*break*/, 6];
                    case 5:
                        error_1 = _a.sent();
                        if (this.isAdmin) {
                            workspaceMenuItems_1.children.push(functionItem);
                        }
                        console.info(error_1);
                        if (ssMenuItems_1 && ssMenuItems_1.children.length) {
                            return [2 /*return*/, ([workspaceMenuItems_1, ssMenuItems_1])];
                        }
                        else {
                            return [2 /*return*/, ([workspaceMenuItems_1])];
                        }
                        return [3 /*break*/, 6];
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        err_1 = _a.sent();
                        console.info(err_1);
                        throw err_1;
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    FuseNavigationMenuService.prototype.resetValue = function () {
        this.defaultWorkspaceConfig = {
            showWorkspace: true,
            workspaceList: [],
            selectedWorkspace: {
                'id': 'dashboard-workspace',
                'title': 'toolbar.dashboard',
                'name': 'toolbar.dashboard',
                'translate': 'toolbar.dashboard',
                'type': 'item',
                'heading': '',
                'headingDesc': '',
                'sort': 0,
                'icon': 'home',
                'url': "/offices/dashboard"
            }
        };
    };
    FuseNavigationMenuService.prototype.workspaceChange = function (workspace) {
        console.log("workspace change = ", workspace);
        this.defaultWorkspaceConfig.selectedWorkspace = workspace;
        this.$workspaceConfig.next({ type: 'workspace', selectedWorkspace: this.defaultWorkspaceConfig.selectedWorkspace });
    };
    FuseNavigationMenuService.prototype.openWorkspace = function (current) {
        var tt = this;
        var dialogRef = tt.current.dialog.open(CreateWorkspaceComponent, {
            width: '500px',
            height: 'max-content',
            panelClass: 'happ-form-builder-dialog'
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                tt.current.refreshMenuItems();
            }
        });
    };
    FuseNavigationMenuService.prototype.getTranslatedTitle = function (ele) {
        return (ele && ele.translations && ele['translations'][this.langIndex[this.currentLang]] &&
            ele['translations'][this.langIndex[this.currentLang]][this.currentLang]) || ele.title || '';
    };
    return FuseNavigationMenuService;
}());
export { FuseNavigationMenuService };
export var CLIENT_PANEL_NAV = [
    {
        'id': 'general',
        'title': 'toolbar.general',
        'translate': 'toolbar.general',
        'type': 'group',
        'children': [
            {
                'id': '1',
                'title': 'toolbar.dashboard',
                'translate': 'toolbar.dashboard',
                'type': 'item',
                'heading': '',
                'headingDesc': '',
                'slug': 'dashboard',
                'sort': 0,
                'icon': 'home',
                'url': "/cp/dashboard"
            }
        ]
    },
    {
        'id': 'cases',
        'title': 'toolbar.cases',
        'translate': 'toolbar.cases',
        'type': 'group',
        'children': [
            {
                'id': '15',
                'title': 'toolbar.active_cases',
                'translate': 'toolbar.active_cases',
                'type': 'item',
                'queryParams': { status: "active" },
                'heading': '',
                'headingDesc': '',
                'slug': 'active_cases',
                'sort': 15,
                'icon': 'cases',
                'url': "/cp/my-cases/personal/all"
            },
            {
                'id': '20',
                'title': 'toolbar.closed_cases',
                'translate': 'toolbar.closed_cases',
                'type': 'item',
                'heading': '',
                'queryParams': { status: "closed" },
                'headingDesc': '',
                'slug': 'closed_cases',
                'sort': 20,
                'icon': 'close',
                'url': "/cp/my-cases/personal/all"
            },
            {
                'id': '21',
                'title': 'toolbar.archived_cases',
                'translate': 'toolbar.archived_cases',
                'type': 'item',
                'queryParams': { status: "archive" },
                'heading': '',
                'headingDesc': '',
                'slug': 'archived_cases',
                'sort': 21,
                'icon': 'archive',
                'url': "/cp/my-cases/personal/all"
            },
            {
                'id': '24',
                'title': 'toolbar.new_cases',
                'translate': 'toolbar.new_cases',
                'type': 'item',
                'heading': '',
                'headingDesc': '',
                'slug': 'new_cases',
                'sort': 24,
                'icon': 'add_circle',
                'url': "/cp/services"
            },
        ]
    },
    {
        'id': 'financial',
        'title': 'toolbar.financial',
        'translate': 'toolbar.financial',
        'type': 'group',
        'children': [
            {
                'id': '30',
                'title': 'toolbar.orders',
                'translate': 'toolbar.orders',
                'type': 'item',
                'heading': '',
                'headingDesc': '',
                'slug': 'orders',
                'sort': 30,
                'icon': 'description',
                'url': "/cp/orders"
            },
            {
                'id': '35',
                'title': 'toolbar.payments',
                'translate': 'toolbar.payments',
                'type': 'item',
                'heading': '',
                'headingDesc': '',
                'slug': 'payments',
                'sort': 35,
                'icon': 'payment',
                'url': "/cp/payments"
            },
            {
                'id': '47',
                'title': 'toolbar.invoices',
                'translate': 'toolbar.invoices',
                'type': 'item',
                'heading': '',
                'headingDesc': '',
                'slug': 'invoices',
                'sort': 47,
                'icon': 'description',
                'url': "/cp/invoices"
            },
        ]
    },
    {
        'id': 'settings',
        'title': 'toolbar.settings',
        'translate': 'toolbar.settings',
        'type': 'group',
        'children': [
            {
                'id': '58',
                'title': 'toolbar.user_profile',
                'translate': 'toolbar.user_profile',
                'type': 'item',
                'heading': '',
                'headingDesc': '',
                'slug': 'user_profile',
                'sort': 58,
                'icon': 'face',
                'url': "/cp/user/profile"
            }
        ]
    }
];
export var CLIENT_PANEL_THEME = {
    isNotOffice: true,
    logout: function () {
    },
    layout: {
        navbar: {
            hidden: false,
            folded: false
        },
        toolbar: {
            hidden: true
        },
        footer: {
            hidden: true
        },
        sidepanel: {
            hidden: true
        }
    }
};
