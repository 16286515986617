export const PlatformSettings = [
    {
        'title': 'toolbar.user_management',
        'fuse2Url': '/offices/:officeId/users-settings',
      },
      {
        'title': 'toolbar.office_configuration',
        'fuse2Url': '/offices/:officeId/office-configuration',
      },
      {
        'title': 'toolbar.department_management',
        'fuse2Url': '/offices/:officeId/department-settings',
      },
      {
        'title': 'toolbar.app_settings',
        'fuse2Url': '/offices/:officeId/application-settings',
      },
      {
        'title': 'toolbar.process',
        'fuse2Url': '/offices/:officeId/process-settings',
      },
      {
        'title': 'toolbar.form',
        'fuse2Url': '/offices/:officeId/general-forms',
      },
      {
        'title': 'toolbar.template',
        'fuse2Url': '/offices/:officeId/general-templates',
      },
      {
        'title': 'toolbar.workspace_management',
        'fuse2Url': '/workspace-management/list',
      },
      {
        'title': 'toolbar.translate',
        'fuse2Url': '/translate/key',
      }
];
