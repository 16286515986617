import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { OfficeHelpersService } from '../../../../services/office-helpers/office-helpers.service';

@Component({
  selector: 'app-datatable-office-filter',
  templateUrl: './datatable-office-filter.component.html',
  styleUrls: ['./datatable-office-filter.component.scss']
})
export class DatatableOfficeFilterComponent implements OnInit, OnChanges {
  @Input() regId: string; 
  @Input() refreshOfficeFilter: number; 
  @Input() appDataOfficeFilters: Array<string>; 
  @Output() officeFilterClicked: EventEmitter<Object> = new EventEmitter();
  
  officeFilters: any;
  selectedOfficeFilter: any;

  constructor(
    private _officeHelper: OfficeHelpersService
  ) { }

  ngOnInit() {
    this.getOfficeFiltersForRegister();
  }

  ngOnChanges() {
    console.info('Reloading Office Filter');
    this.getOfficeFiltersForRegister();
  }

  getOfficeFiltersForRegister() {
    this._officeHelper.getOfficeFilters()
    .then((filters) => {
      this.officeFilters = filters || [];
      this.officeFilters = this.officeFilters.filter((ele: any) => (ele && ele.regId === this.regId));
      this.appDataOfficeFilters = this.appDataOfficeFilters || [];
      this.officeFilters = this.officeFilters.filter((ele: any) => (ele && this.appDataOfficeFilters.includes(ele.name)));
    }).catch(err => {
      console.info(err);
    });
  }

  buttonClicked(officeFilter: any) {
    this.selectedOfficeFilter = officeFilter.name;
    this.officeFilterClicked.emit(officeFilter);
  }
}
