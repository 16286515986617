export const InstanceFieldConst = [
    {
        'keyName': '_id',
        'title': '_id'
    },
    {
        'keyName': 'updatedAt',
        'title': 'Updated At'
    },
    {
        'keyName': 'createdAt',
        'title': 'Created At'
    },
    {
        'keyName': 'officeId',
        'title': 'Office Id'
    },
    {
        'keyName': 'processId',
        'title': 'Process Id'
    },
    {
        'keyName': 'state',
        'title': 'State'
    },
    {
        'keyName': 'previousState',
        'title': 'Previous State'
    },
    {
        'keyName': 'stateLabel',
        'title': 'State Label'
    },
    {
        'keyName': 'stateKind',
        'title': 'State Kind'
    },
    {
        'keyName': 'creatorLogin',
        'title': 'Creator Login'
    },
    {
        'keyName': 'creatorId',
        'title': 'Creator Id'
    },
    {
        'keyName': 'modificatorLogin',
        'title': 'Modificator Login'
    },
    {
        'keyName': 'modificatorId',
        'title': 'Modificator Id'
    },
    {
        'keyName': 'regId',
        'title': 'Reg Id'
    },
    {
        'keyName': 'responsibleEmail',
        'title': 'Responsible Email'
    },
    {
        'keyName': 'responsibleId',
        'title': 'Responsible Id'
    },
    {
        'keyName': 'additionalData',
        'title': 'Additional Data'
    },
    {
        'keyName': 'messageData',
        'title': 'Message Data'
    },
    {
        'keyName': 'comments',
        'title': 'Comments'
    },
    {
        'keyName': 'stateTasks',
        'title': 'State Tasks'
    },
    {
        'keyName': 'stateTaskStage',
        'title': 'State TaskStage'
    },
    {
        'keyName': 'createDatatime',
        'title': 'Create Date Time'
    },
    {
        'keyName': 'parentInstanceId',
        'title': 'Parent Instance Id'
    },
    {
        'keyName': 'recId',
        'title': 'Rec Id'
    },
    {
        'keyName': 'statusInitTime',
        'title': 'Status Init Time'
    },
    {
        'keyName': 'stateTaskComment',
        'title': 'State Task Comment'
    },
    {
        'keyName': 'modifiedBy',
        'title': 'Modified By'
    },
    {
        'keyName': 'createdBy',
        'title': 'Created By'
    },
    {
        'keyName': 'responsible',
        'title': 'Responsible'
    }
];
