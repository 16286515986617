import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-status-toggle',
  templateUrl: './status-toggle.component.html',
  styleUrls: ['./status-toggle.component.scss']
})
export class StatusToggleComponent implements OnInit {

  @Input() field: any;
  @Input() hideTitle: any;
  @Input() model: string; 
  @Output() modelChange: EventEmitter<any> = new EventEmitter();
  @Output() onError: EventEmitter<any> = new EventEmitter();
  @Output() valChange: EventEmitter<any> = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }

  updateStatus(data: any) {
    this.model = data;
    this.valChange.emit({
      model: this.model
    });
    this.modelChange.emit(data);
  }
}
