  <div style="background: #f6f6f6;" class="m-0">

    <ng-container [ngSwitch]="view">
      <ng-container *ngSwitchCase="'step1'">
        <div>
          <table>
            <thead>
              <tr>
                <th>{{ 'ct.services_not_in_list' | translate }}</th>
                <th>{{ 'ct.services_in_list' | translate }}</th>
                </tr>
            </thead>
            <tr>
              <td class="py-0 w-50-p">
                <mat-form-field class="happ-small-form-field-outline w-100-p py-12" floatLabel="never" floatLabel="always"
                  appearance="outline">
                  <mat-label>{{ 'general.search' | translate }}</mat-label>
                  <input matInput rows="10" [(ngModel)]="searchLeft" />
                </mat-form-field>
              </td>
              <td class="py-0 w-50-p">
                <mat-form-field class="happ-small-form-field-outline w-100-p py-12" floatLabel="never" floatLabel="always"
                  appearance="outline">
                  <mat-label>{{ 'general.search' | translate }}</mat-label>
                  <input matInput rows="10" [(ngModel)]="searchRight" />
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td class="px-0 w-50-p">
                <div style="height: calc(80vh - 250px); overflow: auto;">
                  <div class="happ-div-hover" (click)="addRegisterToList(reg)" *ngFor="let reg of filteredList | fullTextSearch:searchLeft:['serviceName']" fxLayout="row">
                    <div fxFlex="80"> 
                      <p>{{ reg.serviceName }}</p>
                       </div>
                    <div fxFlex="20">
                      <span fxFlex></span>
                      <mat-icon>arrow_forward</mat-icon>
                    </div>
                  </div>
                </div>
              </td>
              <td class="px-0 w-50-p">
                <div style="height: calc(80vh - 250px); overflow: auto;">
                  <ng-container *ngIf="selectedServiceValues && selectedServiceValues.length">
                    <div *ngFor="let reg of selectedServiceValues | fullTextSearch:searchRight:['serviceName']" fxLayout="row" >
                      <div fxLayoutAlign="center center" (click)="removeRegisterFromList(reg)" style="padding: 8px; border: 1px solid gray; cursor: pointer;">
                        <mat-icon>arrow_back</mat-icon>
                      </div>
                      <div fxFlex fxLayout="column">
                        <div fxLayout="row">
                          <div (click)="reg.showVar=!reg.showVar" fxFlex fxLayout="column" class="happ-div-hover" >
                            <h3 class="m-0 p-0">
                              <strong>
                                {{ reg.serviceName }}
                              </strong>
                            </h3>
                            <div fxLayout="row" class="w-100-p">
                              <p>
                                {{ (reg.selectedVariant && reg.selectedVariant[0] && reg.selectedVariant[0].name) }}
                              </p>
                              <span fxFlex></span>
                              <p>
                                {{ (reg.selectedVariant && reg.selectedVariant[0] && reg.selectedVariant[0].price) }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="reg.showVar">
                          <div style="cursor: pointer; border: 1px solid gray;" (click)="reg.selectedVariant=[item]" [class]="(reg.selectedVariant && reg.selectedVariant[0] && reg.selectedVariant[0].key===item.key) ? 'selected-variant p-8' : 'p-8'" fxLayout="row" *ngFor="let item of reg.serviceVariantsArray">
                            <div fxLayoutAlign="center center">
                              <h3 class="m-0">
                                {{ item.name }}
                              </h3>
                              <p class="m-0 ml-8">
                                {{ item.desc }}
                              </p>
                            </div>
                            <span fxFlex></span>
                            <p class="m-0">
                              {{ item.price }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="selectedServiceValues && selectedServiceValues.length === 0">
                    <div fxLayoutAlign="center center" class="w-100-p h-100-p" >
                      <h2>Select Service from left</h2>
                    </div>
                  </ng-container>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div>
          <button mat-raised-button (click)="view='step2';onNext()" class="happ-small-button w-100-p mt-12">
            {{ 'ct.next' | translate }}
          </button>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'step2'">
        <div>
          <table>
            <thead>
              <tr>
                <th>{{ 'ct.comments' | translate }}</th>
                <th>{{ 'ct.selected_services' | translate }}</th>
                </tr>
            </thead>
            <tr>
              <td class="px-0 w-50-p">
                <div style="height: calc(80vh - 250px); overflow: auto;">
                  <div>
                    <button class="happ-small-button w-100-p m-12" mat-raised-button (click)="view='step1'" >
                      {{ 'ct.move_back' | translate }}
                    </button>
                  </div>
                  <!-- <h2>Rec comment</h2> -->
                  <div class="mt-16 w-100-p">
                    <div class="m-12">
                      <h2>
                        {{ 'ct.comments' | translate }}
                      </h2>
                      <mat-form-field floatLabel="always" appearance="outline" class="example-full-width w-100-p">
                        <mat-label> {{ 'ct.comment' | translate }} </mat-label>
                        <textarea matInput [(ngModel)]="comment" [placeholder]="'ct.comment' | translate"></textarea>
                      </mat-form-field>

                      <ng-container *ngIf="!showAllComments && allComments.length">
                        <div fxLayout="column" class="p-8 mt-4" style="border: 1px solid gray;">
                          <div fxLayout="row" >
                            <p style="font-weight: 700;">
                              {{ allComments[0].creator }}
                            </p>
                            <span fxFlex></span>
                            <p>
                              {{ allComments[0].creatingTime | date: 'yyyy:MM:dd HH:mm:ss' }}
                            </p>
                          </div>
                          <p style="font-size: 16px; margin-top: 8px;">
                            {{ allComments[0].description }}
                          </p>
                        </div>
                        <div class="mt-" fxLayout="row">
                          <span fxFlex></span>
                          <a (click)="showAllComments=true" > {{ 'ct.show_all' | translate }} </a>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="showAllComments">
                        <div fxLayout="column" *ngFor="let item of allComments" class="p-8 mt-4" style="border: 1px solid gray;">
                          <div fxLayout="row" >
                            <p style="font-weight: 700;">
                              {{ item.creator }}
                            </p>
                            <span fxFlex></span>
                            <p>
                              {{ item.creatingTime | date: 'yyyy:MM:dd HH:mm:ss' }}
                            </p>
                          </div>
                          <p style="font-size: 16px; margin-top: 8px;">
                            {{ item.description }}
                          </p>
                        </div>
                      </ng-container>
                    </div>
                    <!-- <app-record-comment [field]="staticCommentField" [regId]="editNg5Service.regId" [recId]="editNg5Service.recId" [translateData]="translateData"  ></app-record-comment> -->
                  </div>
                </div>
              </td>
              <td class="px-0 w-50-p">
                <div style="height: calc(80vh - 250px); overflow: auto;">
                  <ng-container *ngIf="selectedServiceValues && selectedServiceValues.length">
                    <div *ngFor="let reg of selectedServiceValues | fullTextSearch:searchRight:['serviceName']" fxLayout="row" >
                      <div fxLayoutAlign="center center" (click)="removeRegisterFromList(reg)" style="padding: 8px; border: 1px solid gray; cursor: pointer;">
                        <mat-icon>arrow_back</mat-icon>
                      </div>
                      <div fxFlex fxLayout="column">
                        <div fxLayout="row">
                          <div (click)="reg.showVar=!reg.showVar" fxFlex fxLayout="column" class="happ-div-hover" >
                            <h3 class="m-0 p-0">
                              <strong>
                                {{ reg.serviceName }}
                              </strong>
                            </h3>
                            <div fxLayout="row" class="w-100-p">
                              <p>
                                {{ (reg.selectedVariant && reg.selectedVariant[0] && reg.selectedVariant[0].name) }}
                              </p>
                              <span fxFlex></span>
                              <p>
                                {{ (reg.selectedVariant && reg.selectedVariant[0] && reg.selectedVariant[0].price) }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="reg.showVar">
                          <div style="cursor: pointer; border: 1px solid gray;" (click)="reg.selectedVariant=[item]" [class]="(reg.selectedVariant && reg.selectedVariant[0] && reg.selectedVariant[0].key===item.key) ? 'selected-variant p-8' : 'p-8'" fxLayout="row" *ngFor="let item of reg.serviceVariantsArray">
                            <div fxLayoutAlign="center center">
                              <h3 class="m-0">
                                {{ item.name }}
                              </h3>
                              <p class="m-0 ml-8">
                                {{ item.desc }}
                              </p>
                            </div>
                            <span fxFlex></span>
                            <p class="m-0">
                              {{ item.price }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="selectedServiceValues && selectedServiceValues.length === 0">
                    <div fxLayoutAlign="center center" class="w-100-p h-100-p" >
                      <h2>Select Service from left</h2>
                    </div>
                  </ng-container>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div *ngIf="!editNg5Service.loading" class="happ-action-header">
          <div *ngIf="staticProcessStatus">
              <div class="my-8"
                  *ngIf="!staticProcessStatus.hide && editNg5Service.error && editNg5Service.error.length === 0">
                  <app-process-status [(model)]="editNg5Service.formData"
                      (updateRecord)="processUpdateRecordTrigger($event)" [field]="staticProcessStatus">
                  </app-process-status>
              </div>
              <div fxLayoutAlign="center center" *ngIf="editNg5Service.error && editNg5Service.error.length > 0">
                  <h3> {{ 'ONE_REC.REMOVE_ALL_ERRORS' | translate }} </h3>
              </div>
          </div>
      </div>
      </ng-container>
    </ng-container>

</div>
