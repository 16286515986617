import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from './material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpModule } from '@angular/http';
import { ControlTemplateModule } from 'app/main/content/global-component/control-template/control-template.module';
import { PositionModule } from 'app/main/content/global-component/control-template/position/position.module';
import { NgSummernoteModule } from 'app/main/content/global-component/ng-summernote/ng-summernote.module';
import { ConfirmChangesComponent } from 'app/main/content/global-component/confirm-changes/confirm-changes.component';
import { FormViewComponent } from 'app/main/content/global-component/one-recordview/form-view/form-view.component';
import { InputDialogModule } from 'app/main/content/global-component/input-dialog/input-dialog.module';
import { FuseSidebarModule } from './components';
import { SharedServicePositionModule } from 'app/main/content/global-component/control-template/shared-service-position/shared-service-position.module';


@NgModule({
    declarations   : [
        FormViewComponent,
        ConfirmChangesComponent
    ],
    imports        : [
        ControlTemplateModule,
        CommonModule,
        MaterialModule,
        PositionModule,
        FlexLayoutModule,
        HttpModule,
        SharedServicePositionModule,
        InputDialogModule,
        NgSummernoteModule,
        FuseSidebarModule
    ],
    exports        : [
        FormViewComponent,
        CommonModule,
        MaterialModule,
        PositionModule,
        FlexLayoutModule,
        ControlTemplateModule,
        SharedServicePositionModule,
        HttpModule,
        NgSummernoteModule,
        ConfirmChangesComponent,
        FuseSidebarModule
    ],
    entryComponents: [
    ],
    providers      : [
    ]
})

export class GlobalModule
{

}
