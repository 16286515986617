import { Component, OnInit, AfterViewInit, OnChanges, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { FieldOptionUpdateDialogComponent } from 'app/main/content/components/field-option-update-dialog/field-option-update-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() field;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() model;
  @Input() translateData: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Input() hideTitle: boolean;
  @Input() builder;
  @Input() formData: any;
  @Output() valChange: EventEmitter<Object> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onError: EventEmitter<Object> = new EventEmitter();
  
  loading = false;
  error = false;
  errorMessage = '';
  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private _helperService: HelpersService

  ) {
    this.model = this.model || '';
  }

  
  ngOnChanges(changes: any) {

    if (changes && changes.translateData && changes.translateData.currentValue) {
      this.translateData = changes.translateData.currentValue;
      this.translationLoader.loadTranslationsV2(this.translateData);    
    }
  }

  onChange(name) {
    
    this.valChange.emit({
      model: this.model
    });
    this.modelChange.next(this.model);

    if (this.field['required'] && (this.model === null || this.model === undefined || this.model === '')) {
      this.error = true;
      this.errorMessage = 'ct.field_is_required';
      if (!this.model) {
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
      }
    } else {
      this.errorMessage = '';
      this.error = false;
      this.onError.emit({
        error: false,
        label: this.field['label']
      });
    }
    if (this.field.haveRules) {
      this.onFieldValueUpdated.emit({
        label: this.field.label,
        value: this.model,
        ruleIndex: this.field.ruleIndex,
        field: this.field
      });
      this.changeDetectorRef.detectChanges();

    }
  }
  
  ngAfterViewInit() {
    const temp = {};
    if (this.field.multiple) {
      this.field['options'].forEach(element => {
        temp[element.name] = false;
      });
      this.model = (this.model) ? this.model : temp;          
    } else {
      if (typeof(this.model) === 'object') {
        this.model = '';
      } else {
        this.model = this.model || '';
      }
    }
 
  
    if (this.field['preselectedValue'] && this.model && this.model.length === 0){
      this.model = this.field['preselectedValue'];
      this.onChange(null);
    }
  }

  ngOnInit() {
    this.changeDetectorRef.markForCheck();
    this.translationLoader.loadTranslationsV2(this.translateData);
    if (this.field['required'] && (this.model === null || this.model === undefined || this.model === '')) {
      this.error = true;
      this.errorMessage = 'ct.field_is_required';
      if (!this.model) {
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
      }
    }
  }
}
