import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { HelpersService } from '../../services/helpers/helpers.service';
import { SelectIconComponent } from '../workspace-management/select-icon/select-icon.component';
import { MatDialog, MatDialogRef } from '@angular/material';
import { UpdateSuccessComponent } from './update-success/update-success.component';
var AppBuilderComponent = /** @class */ (function () {
    function AppBuilderComponent(helperService, dialog, dialogRef, data) {
        this.helperService = helperService;
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.data = data;
        this.includingAppsList = [];
        this.selectedAppsList = [];
        this.isEdit = false;
        this.languages = [];
        this.currentStep = 0;
        this.loadingValue = 0;
        this.step1Data = {};
        this.step2Data = {
            appType: 'app',
            type: 'workspace',
            versionType: 'small',
            isBeta: false,
            source: {},
            appData: [
                {
                    stepLabel: '',
                    appSlug: '',
                    stepNumber: 3,
                    type: 'register',
                    regId: '',
                    orParams: [],
                    andParams: [],
                    ansFilter: [],
                    scanqueue: {},
                    icon: 'note_add'
                }
            ]
        };
        this.steps = [
            {
                name: 'Select Register',
                index: 0,
                status: 'progress'
            },
            {
                name: 'Enter Alias Names',
                index: 1,
                status: 'pending'
            },
            {
                name: 'Building App',
                index: 2,
                status: 'pending'
            }
        ];
        this.pluginList = [];
    }
    AppBuilderComponent.prototype.ngOnInit = function () {
        this.getActiveLangs();
        this.getInitData();
    };
    AppBuilderComponent.prototype.getInitData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var regList, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 7, , 8]);
                        this.loading = true;
                        console.log(this.data);
                        this.data.extraData = this.data.extraData || {};
                        if (!this.data.extraData.createNew) return [3 /*break*/, 2];
                        // new
                        this.step2Data.source.workspaceSlug = this.data.workspaceSlug;
                        this.step1Data.alias_name = Date.now().toString();
                        return [4 /*yield*/, this.registerSelected(this.data.appRegId)];
                    case 1:
                        _a.sent();
                        this.getAllIncludingApps();
                        return [3 /*break*/, 6];
                    case 2:
                        if (!this.data.extraData.updateTemplate) return [3 /*break*/, 4];
                        // update
                        this.isEdit = true;
                        this.step2Data.source.workspaceSlug = this.data.workspaceSlug;
                        this.step1Data.alias_name = Date.now().toString();
                        this.step2Data.versionType = 'small';
                        return [4 /*yield*/, this.registerSelected(this.data.appRegId)];
                    case 3:
                        _a.sent();
                        this.currentStep = 0;
                        this.getAllIncludingApps();
                        this.getAllPlugins(this.data.appRegId);
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.helperService.getSmallRegisterList()];
                    case 5:
                        regList = _a.sent();
                        this.loadingValue = 100;
                        this.loading = false;
                        this.regList = regList || [];
                        _a.label = 6;
                    case 6:
                        this.loading = false;
                        return [3 /*break*/, 8];
                    case 7:
                        error_1 = _a.sent();
                        console.error(error_1);
                        this.loadingValue = 100;
                        this.loading = false;
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    AppBuilderComponent.prototype.getAllPlugins = function (regId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, error_2;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this;
                        return [4 /*yield*/, this.helperService.getRequest("api/plugins/" + regId)];
                    case 1:
                        _a.pluginList = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _b.sent();
                        console.error(error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AppBuilderComponent.prototype.getAllIncludingApps = function () {
        this.regAliases = this.regAliases || [];
        var temp = this.regAliases.map(function (ele) { return (ele && ele.id); });
        this.includingAppsList = this.data.appList.filter(function (ele) { return (temp.includes(ele.regId)); });
        this.step2Data.appData = this.includingAppsList;
        this.selectedAppsList = this.includingAppsList.map(function (ele) { return (ele.stepNumber); });
    };
    AppBuilderComponent.prototype.toggleChange = function (item) {
        if (!this.selectedAppsList.includes(item.stepNumber)) {
            this.selectedAppsList.push(item.stepNumber);
            delete item.id;
            this.step2Data.appData.push(item);
        }
        else {
            var index = this.selectedAppsList.indexOf(item.stepNumber);
            this.step2Data.appData = this.step2Data.appData.filter(function (ele) { return (ele.stepNumber !== item.stepNumber); });
            if (index !== -1) {
                this.selectedAppsList.splice(index, 1);
            }
        }
    };
    AppBuilderComponent.prototype.startTicking = function (time) {
        var _this = this;
        setInterval(function () {
            _this.loadingValue += 1;
        }, time);
    };
    AppBuilderComponent.prototype.registerSelected = function (regId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, error_3;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        if (!this.step1Data['alias_name']) {
                            this.helperService.openSnackBar("Please enter alias Name");
                            return [2 /*return*/];
                        }
                        this.currentStep = 1;
                        this.loading = true;
                        this.loadingValue = 0;
                        this.startTicking(700);
                        this.step2Data.source.regId = regId;
                        this.step1Data['appId'] = regId;
                        this.step2Data['aliasName'] = this.step1Data['alias_name'];
                        this.step2Data['pluginKey'] = this.step1Data['alias_name'];
                        return [4 /*yield*/, this.helperService.postRequest("api/createApp", this.step1Data)];
                    case 1:
                        data = _a.sent();
                        this.loadingValue = 100;
                        this.loading = false;
                        this.dependencies = data || [];
                        this.dependencies.forEach(function (ele) {
                            if (ele && ele.name === 'Registers') {
                                _this.regAliases = ele.list || [];
                            }
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        this.loadingValue = 100;
                        this.loading = false;
                        console.info(error_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AppBuilderComponent.prototype.selectIcon = function (index) {
        var _this = this;
        var dialogRef = this.dialog.open(SelectIconComponent, {
            width: '700px',
            height: '600px',
            panelClass: 'happ-form-builder-dialog',
            data: { icon: this.step2Data.appData[index].icon }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.step2Data.appData[index].icon = result;
            }
        });
    };
    AppBuilderComponent.prototype.addAppData = function () {
        this.step2Data.appData = this.step2Data.appData || [];
        this.step2Data.appData.push({
            stepLabel: '',
            appSlug: '',
            stepNumber: 3,
            type: 'register',
            regId: '',
            orParams: [],
            andParams: [],
            ansFilter: [],
            scanqueue: {},
            icon: 'note_add'
        });
    };
    AppBuilderComponent.prototype.removeAppData = function (i) {
        this.step2Data.appData = this.step2Data.appData || [];
        this.step2Data.appData.splice(i, 1);
    };
    AppBuilderComponent.prototype.createFromOfficeApp = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var reqFields, allDone_1, error_4;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        reqFields = [
                            'name',
                            'smallDesc',
                            'description',
                            'businessFunction',
                            'appSector'
                        ];
                        if (this.step2Data) {
                            allDone_1 = '';
                            reqFields.forEach(function (ele) {
                                if (ele && !_this.step2Data[ele]) {
                                    allDone_1 = allDone_1 + '- ' + ele;
                                }
                            });
                            if (allDone_1) {
                                this.helperService.openSnackBar("Please Fill " + allDone_1);
                                return [2 /*return*/];
                            }
                        }
                        else {
                            return [2 /*return*/];
                        }
                        this.dependencies.forEach(function (ele) {
                            if (ele && ele.name) {
                                _this.step2Data[ele.name.toLowerCase()] = ele.list || [];
                            }
                        });
                        this.loading = true;
                        if (!!this.isEdit) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.helperService.postRequest("api/offices/officePlugin/createPackageApp", this.step2Data)];
                    case 1:
                        _a.sent();
                        this.loading = false;
                        this.helperService.openSnackBar('App created..');
                        this.dialogRef.close(true);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.helperService.postRequest("api/offices/officePlugin/updatePackageApp/" + this.step2Data._id, this.step2Data)];
                    case 3:
                        _a.sent();
                        this.loading = false;
                        this.helperService.openSnackBar('App Update..');
                        this.dialogRef.close(true);
                        this.dialog.open(UpdateSuccessComponent, {
                            width: '400px',
                            height: '200px',
                            restoreFocus: false,
                            panelClass: 'happ-form-builder-dialog'
                        });
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        error_4 = _a.sent();
                        console.log(error_4);
                        this.loading = false;
                        this.helperService.openSnackBar(error_4 || 'Error occured while creating app');
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    AppBuilderComponent.prototype.getActiveLangs = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, _c;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _a = this;
                        _c = (_b = this.helperService).formatTheLanguageArray;
                        return [4 /*yield*/, this.helperService.getActiveLangs()];
                    case 1:
                        _a.languages = _c.apply(_b, [_d.sent()]);
                        return [2 /*return*/];
                }
            });
        });
    };
    AppBuilderComponent.prototype.pluginSelected = function (item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.selectedPlugin = item;
                        this.step1Data.alias_name = item.aliasName;
                        return [4 /*yield*/, this.registerSelected(this.data.appRegId)];
                    case 1:
                        _a.sent();
                        this.step2Data = Object.assign(this.step2Data, item);
                        this.currentStep = 1;
                        return [3 /*break*/, 3];
                    case 2:
                        error_5 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return AppBuilderComponent;
}());
export { AppBuilderComponent };
