import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { IOfficeData } from '../../interfaces/office.interface';
import { IProfile } from '../../interfaces/profile.interface';
import { environment } from 'environments/environment';


// Our service to handle errors (ideally in its own file)
@Injectable()
export class ErrorLogService {
    issueData: any = {};
    browserInfo: any = {};
    userToken: any;
    
  LOGIN_TIME_DIFF = 1000 * 60 * 60 * 8;
  REAUTH_TIME = 1000 * 60 * 60 * 6;
  SNACKBAR_DURATION = 2000;

  
    constructor(
        // private _officeHelper: OfficeHelpersService,
        // private _profileHelper: ProfileHelperService,
        // private _helperService: HelpersService,
        private http: HttpClient,

      ) { }

    logError(error: any) {      
        this.issueData.error = (error && error.stack);

        this.issueData.version = environment.version;
        this.issueData.current_url = window.location.href;
        this.getInitData()
        .then(() => {
                
            this.browserInfo = this.getBrowserInfo();

            this.issueData.browserInfo = this.browserInfo;
            this.issueData.browserName = this.browserInfo.browserName;
            this.issueData.fullVersion = this.browserInfo.fullVersion;
            if (error instanceof HttpErrorResponse) {
                this.issueData.errorMsg = `There was an HTTP error. \n ${error.message} Status code: ${(<HttpErrorResponse>error).status}`;
            } else if (error instanceof TypeError) {
                this.issueData.errorMsg = `There was a Type error. \n${error.message}`;
            } else if (error instanceof Error) {
                this.issueData.errorMsg = `There was a general error.\n ${error.message}`;
            } else {
                this.issueData.errorMsg = `Nobody threw an error but something happened! \n ${error}`;
            }
            console.warn(this.issueData.errorMsg);
            if (environment && environment.production) {
                this.createConsoleError(this.issueData)
                .catch(err => {
                    console.info(err);
                });
            } else {
              console.error(error);
            }
        }).catch(err => {
            console.info(err);
        });
    }

    stringifyError(err, filter, space) {
      const plainObject = {};
      Object.getOwnPropertyNames(err).forEach(function(key) {
        plainObject[key] = err[key];
      });
      return JSON.stringify(plainObject, filter, space);
    }

    async getInitData () {
        try {
          this.issueData.office_json = await this.getLocalOfficeData() || {};
          delete this.issueData.office_json.pluginInfo;
          delete this.issueData.office_json.plugins;
          this.issueData.logged_user = await this.getLocalProfileData() || {};
          this.getLocalWorkspaceData();
        } catch (err) {
          return err;
        }
      }

      getLocalWorkspaceData() {
        if (localStorage.getItem('wsAppData') && JSON.parse(localStorage.getItem('wsAppData'))) {
          this.issueData.workspace_json = JSON.parse(localStorage.getItem('wsAppData'));
        }
      }
      
      createConsoleError(recData: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.post(`${environment.serverUrl}api/consoleErrors`, recData)
            .subscribe((data) => {
              this.buildBackendResponse(data, resolve, reject);
            }, err => this.buildHttpError(err, reject));
        });
      }

        /**
   * 
   * @param data 
   * @param resolve 
   * @param reject 
   * build the success or error message from the response
   */
  buildBackendResponse(data, resolve, reject): void {
    if (data && data['success']) {
      if (data['result']) {
        resolve(data['result']);
      } else {
        reject('Response is empty. Please check the configuration');
      }
    } else {
      reject((data && data['error'] && data['error']['txt']) || 'Some error occured');
    }
  }

  /**
   * 
   * @param err 
   * @param reject 
   * build the error message from the http error
   */
  buildHttpError(err, reject): void {
    if (err && err['status'] && err['message']) {
      reject(err['status'] + '-' + err['message']);
    } else {
      reject('Error Occured while fetching the data');
    }
  }

      /**
     * return office data from local storage
     */
    getLocalOfficeData(): Promise<IOfficeData> {
        return new Promise((resolve, reject) => {
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))) {
            resolve(JSON.parse(localStorage.getItem('ngStorage-selectedOffice')));
        } else {
            reject('No Office Doc Found. Please Make Sure you have some Office selected');
        }
        });
    }
    getLocalProfileData(): Promise<IProfile> {
        return new Promise((resolve, reject) => {
          if (JSON.parse(localStorage.getItem('ngStorage-profile'))) {
            resolve(JSON.parse(localStorage.getItem('ngStorage-profile')));
          } else {
            reject('No profile Found. Please login first');
          }
        });
      }
      
  getBrowserInfo() {
    const nVer = navigator.appVersion;
    const nAgt = navigator.userAgent;
    let browserName  = navigator.appName;
    let fullVersion  = '' + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset, verOffset, ix;

    // In Opera, the true version is after "Opera" or after "Version"

    if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
    browserName = 'Opera';
    fullVersion = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf('Version')) !== -1) {
      fullVersion = nAgt.substring(verOffset + 8);
    }
    }
    // In MSIE, the true version is after "MSIE" in userAgent

    else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
    browserName = 'Microsoft Internet Explorer';
    fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome"

    else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
    browserName = 'Chrome';
    fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version"

    else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
    browserName = 'Safari';
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf('Version')) !== -1) {
      fullVersion = nAgt.substring(verOffset + 8);
    }
    }
    // In Firefox, the true version is after "Firefox"

    else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
    browserName = 'Firefox';
    fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent

    else if ( (nameOffset = nAgt.lastIndexOf(' ') + 1) <
              (verOffset = nAgt.lastIndexOf('/')) )
    {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
    }

    // trim the fullVersion string at semicolon/space if present

    if ((ix = fullVersion.indexOf(';')) !== -1) {
      fullVersion = fullVersion.substring(0, ix);
    }
    if ((ix = fullVersion.indexOf(' ')) !== -1) {
      fullVersion = fullVersion.substring(0, ix);
    }

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
    fullVersion  = '' + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
    }

    return {
      browserName,
      fullVersion,
      majorVersion,
      appName: navigator.appName,
      userAgent: navigator.userAgent
    };
  }
  
}
