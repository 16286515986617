<div class="page-layout carded fullwidth single-scroll" fusePerfectScrollbar>

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- CONTENT HEADER -->
        <div class="header white-fg p-24" fxLayout="row" fxLayoutAlign="start center">
            <!-- <h2>{{ 'books.books_list' | translate }}</h2> -->
            <h2>Lista Biur</h2>
            <!-- <breadcrumb></breadcrumb> -->

        </div>
        <!-- / CONTENT HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card fuse-white-bg">

            <!-- CONTENT -->
            <div class="content p-24" style="position: relative;">
                <div class="w-100-p" *ngIf="!officeListService.error">
                    <mat-list role="list" fxFlex>
                        <mat-list-item class="mat-elevation-z1" style="margin: 10px 0px; justify-content: space-between;" role="listitem" *ngFor="let list of officeList">
                            <div style="display: flex; flex-direction: row; align-items: center;">
                                <button mat-icon-button (click)="list['starred']=!list['starred']; officeListService.starOffice(list)">
                                    <mat-icon [style.color]="(list['starred'])? '#FFB300': 'black'" aria-label="Example icon-button with a heart icon">star_rate</mat-icon>
                                </button>
                                <p class="m-0">
                                        {{ list.name }}
                                </p>
                            </div>

                            <button [attr.aria-label]="list.name" style="text-transform: capitalize;" mat-button (click)="changeOffice(list)">
                                Select                              
                            </button>

                        </mat-list-item>

                    </mat-list>
                </div>
                <div class="h-500 w-100-p" fxLayoutAlign="center center" *ngIf="officeListService.error">
                    <h3>
                        {{ officeListService.error }}
                    </h3>
                </div>
                <!-- <mat-icon class="happ-setting-icon">sentiment_very_dissatisfied</mat-icon> -->
            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>