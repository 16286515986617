import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'sumOfField'})
export class SumOfFieldPipe implements PipeTransform
{
    /**
     * Transform
     *
     * @param {any[]} mainArr
     * @param {string} searchText
     * @param {string} property
     * @returns {any}
     */
    transform(mainArr: any[] = [], property: string): any
    {
        let sum = 0;
        mainArr.forEach((ele) => {
            if (ele[property]) {
                const temp = parseFloat(ele[property]);
                if (!isNaN(temp)) {
                    sum = sum + temp;
                }
            }
        });
        return sum;
    }
}
