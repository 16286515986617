import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { HelpersService } from '../../services/helpers/helpers.service';
import * as moment from 'moment';
import { IUserInvitation } from '../../interfaces/userInvitation.interface';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit {

  invitationExpired = false;
  userInvitationModel: IUserInvitation = {} as any;
  headingText = '';
  desc1 = '';
  desc2 = '';
  buttonText = '';
  status = 'reject';
  invitationId: any;
  loading = true;


  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private _fuseConfigService: FuseConfigService,
    private helperService: HelpersService,
    private translationLoader: FuseTranslationLoaderService,

  ) { 
    this._fuseConfigService.config = {
      layout: {
          navbar: {
              hidden: true
          },
          toolbar: {
              hidden: true
          },
          footer: {
              hidden: true
          },
          sidepanel: {
              hidden: true
          }
      }
  };
  }

  ngOnInit() {
    // localStorage.clear();
    this.helperService.getTrans('invitation')
      .then(data2 => {
          this.translationLoader.loadTranslationsV2(data2);
      }).catch(err => {
          console.info(err);
      });
    this._fuseConfigService.config = {
      layout: {
          navbar: {
              hidden: true
          },
          toolbar: {
              hidden: true
          },
          footer: {
              hidden: true
          },
          sidepanel: {
              hidden: true
          }
      }
  };
    const { userInvitationId, status } = this.route.snapshot.params;
    this.status = status;
    this.invitationId = userInvitationId;
    if (status === 'reject') {
      this.headingText = `You've been invited to join a Pinbo24 account. Are you sure you want to reject?`;
      this.buttonText = 'Confirm Reject';
    } else {
      this.headingText = `You've been invited to join a Pinbo24 account. Are you sure you want to accept?`;
      this.buttonText = 'Confirm Accept';
    }
    this.getInvitationData(userInvitationId);
  }

  getInvitationData(invitationId: string) {
    this.http.get(`${environment.serverUrl}api/offices/user-invitation-un-auth/${invitationId}`)
    .subscribe((data: any) => {
      this.loading = false;
      this.userInvitationModel = (data && data.result) || {};
      this.invitationExpired = moment(this.userInvitationModel.expirationDate).isBefore(moment());
      if (this.invitationExpired) {
        this.userInvitationModel.status = 'expired';
      }
      //   this.status = 'expired';
      //   this.headingText = 'Invitation Already Expired. Contact support for new link.';
      // } else if (this.userInvitationModel && this.userInvitationModel.status === 'accepted') {
      //   this.status = 'expired';
      //   this.headingText = 'Invitation Already Accepted!';
      // } else if (this.userInvitationModel && this.userInvitationModel.status === 'rejected') {
      //   this.status = 'expired';
      //   this.headingText = 'Invitation Already Rejected!';
      // } else if (this.status === 'set-password' && !this.userInvitationModel.isNewUser) {
      //   this.status = 'other';
      //   this.headingText = `Already Have account with email - ${this.userInvitationModel.invitedToEmail}. Please login`;
      // } else if (this.status === 'migrate' && this.userInvitationModel.oldInvitedEmail) {
      //   this.status = 'other';
      //   this.headingText = `This link is already migrated from email - ${this.userInvitationModel.oldInvitedEmail} to email - ${this.userInvitationModel.invitedToEmail}`;
      // }
      
    }, err => {
      this.loading = false;
      this.helperService.handleHttpError(err);
      // this.helperService.openSnackBar(err);
    });
  }

  triggerAction() {
    if (this.status === 'accept') {
      this.confirmAccept();
    } else {
      this.confirmReject();
    }
  }

  confirmAccept() {
    this.loading = true;
    this.http.get(`${environment.serverUrl}api/offices/user-invitation/${this.invitationId}/accept`)
    .subscribe((data: any) => {
      this.helperService.openSnackBar('Invitation Accepted. Redirecting to the office');
      this.loading = false;
    }, err => {
      this.helperService.openSnackBar(err);
      this.loading = false;
    });
  }

  confirmReject() {
    this.loading = true;
    this.http.get(`${environment.serverUrl}api/offices/user-invitation/${this.invitationId}/reject`)
    .subscribe((data: any) => {
      this.helperService.openSnackBar('Invitation Rejected. Thanks for your time');
      this.loading = false;
    }, err => {
      this.helperService.openSnackBar(err);
      this.loading = false;
    });
  }
}
