import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalModule } from '@fuse/global.module';
import { TreeModule } from 'angular-tree-component';
import { FuseSidebarModule } from '@fuse/components/sidebar/sidebar.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { ExternalProcessViewComponent } from './external-process-view.component';


@NgModule({
  imports: [
    CommonModule,
    FuseSharedModule,
    TreeModule,
    GlobalModule,
    FuseSidebarModule
  ],
  providers: [
  ],
  declarations: [
  ExternalProcessViewComponent],
  entryComponents: [
  ]
})
export class ExternalProcessViewModule { }
