import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TemplatePreviewComponent } from 'app/main/content/components/template-preview/template-preview.component';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { SelectTemplate } from '../../drag-and-drop/select-template/select-template.component';
var CtTemplateSelectionComponent = /** @class */ (function () {
    function CtTemplateSelectionComponent(translationLoader, dialogSec, helperService) {
        this.translationLoader = translationLoader;
        this.dialogSec = dialogSec;
        this.helperService = helperService;
        this.onFieldValueUpdated = new EventEmitter();
        this.updateRecord = new EventEmitter();
        this.model = [];
        this.modelChange = new EventEmitter();
        // tslint:disable-next-line:no-output-on-prefix
        this.onError = new EventEmitter();
    }
    CtTemplateSelectionComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.translateData && changes.translateData.currentValue) {
            this.translateData = changes.translateData.currentValue;
            this.translationLoader.loadTranslationsV2(this.translateData);
        }
    };
    CtTemplateSelectionComponent.prototype.ngOnInit = function () {
    };
    CtTemplateSelectionComponent.prototype.selectTemplate = function (type) {
        var _this = this;
        var key = "" + this.field.label + type;
        console.log("this.regRec[`${key}Id`]", this.regRec[key + "Id"]);
        var dialogRef = this.dialogSec.open(SelectTemplate, {
            width: '100%',
            data: {
                templateList: null,
                selectedTemplate: this.regRec[key + "Id"] || [],
                allowMultipleSelection: false,
                allowSelection: true,
                fields: ['_id', 'title']
            },
            panelClass: 'happ-form-builder-dialog'
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log("result", result);
            if (result) {
                var temp = {};
                temp[key + "Id"] = result._id;
                temp[key + "Name"] = result.title;
                _this.regRec[key + "Id"] = result._id;
                _this.regRec[key + "Name"] = result.title;
                _this.updateRecord.emit(temp);
            }
        });
    };
    CtTemplateSelectionComponent.prototype.previewTemplate = function (type) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var key, templateData, dialogRef, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        key = "" + this.field.label + type + "Id";
                        console.log("key", key);
                        console.log("this.regRecr", this.regRec);
                        this.loading = true;
                        return [4 /*yield*/, this.helperService.getTemplate(this.regRec[key])];
                    case 1:
                        templateData = _a.sent();
                        this.loading = false;
                        dialogRef = this.dialogSec.open(TemplatePreviewComponent, {
                            width: '100%',
                            height: '100%',
                            panelClass: 'happ-form-builder-dialog',
                            restoreFocus: false,
                            data: { templateData: templateData }
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        this.loading = false;
                        alert(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return CtTemplateSelectionComponent;
}());
export { CtTemplateSelectionComponent };
