<mat-card>

  
  <mat-card class="m-12">
    <h2>
      Pinbox24 Shared service system payments
    </h2>

    <table>
      <tr>
        <td>
          {{ 'ct.case_no' | translate }}
        </td>
        <td>
          {{ (recordData.parentCaseId) }}
        </td>
      </tr>
      <tr>
        <td>
          {{ 'ct.service_no' | translate }}
        </td>
        <td>
          {{ (recordData.signatura) }}
        </td>
      </tr>
      <tr>
        <td>
          {{ 'ct.purchase_order' | translate }}
        </td>
        <td>
          {{ (recordData.activeOrderSignature) }}
        </td>
      </tr>
      <tr>
        <td>
          {{ 'ct.case_provider' | translate }}
        </td>
        <td>
          {{ (recordData.providerName) }}
        </td>
      </tr>
    </table>
  </mat-card>

  <mat-card class="m-12">
    <table>
      <tr>
        <td> {{ 'ct.payment_method' | translate }} </td>
        <td>
          <div>
            <button class="mr-12" mat-raised-button (click)="reqBody.paymentSource='online';reqBody.paymentMethod='przelewy24'" [color]="reqBody.paymentSource==='online' ? 'primary' : ''" > {{ 'ct.online' | translate }} </button>
            <button mat-raised-button (click)="reqBody.paymentSource='credit'" [color]="reqBody.paymentSource==='credit' ? 'primary' : ''" > {{ 'ct.credit' | translate }} </button>
          </div>
        </td>
        <td></td>
        <td></td>
      </tr>
      <tr *ngIf="reqBody.paymentSource==='online'" >
        <td> {{ 'ct.payment_gateway' | translate }} </td>
        <td>
          <div>
            <button mat-raised-button (click)="reqBody.paymentMethod='przelewy24'" [color]="reqBody.paymentMethod==='przelewy24' ? 'primary' : ''" > przelewy24 </button>
          </div>
        </td>
        <td></td>
        <td></td>

      </tr>
      <tr *ngIf="reqBody.paymentSource" >
        <td> {{ 'ct.pay' | translate }} </td>
        <td>
          <button (click)="payOrder()" mat-raised-button   >
            {{ 'ct.paynow' | translate }}
          </button>
        </td>
        <td>
          {{ 'ct.cancel_order' | translate }}
        </td>
        <td>
          <button mat-raised-button (click)="cancelClick()" color="warn" class="ml-8" >
            {{ 'ct.cancel_order' | translate }}
          </button>
        
        </td>
      </tr>
    </table>
  </mat-card>

  <br>
  <mat-card class="m-12">
    <table *ngIf="orderDetail">
      <tbody>
        <tr>
          <td>
            <p>
              {{ 'ct.date' | translate }}
              <br>
              {{ orderDetail.registredDataTime }}
            </p>
          </td>
          <td>
            <p>
              {{ 'ct.status' | translate }}
              <br>
              {{ orderDetail.processStatusLabel }}
            </p>
          </td>
          <td>
            <p>
              {{ 'ct.total' | translate }}
              <br>
              {{ (orderDetail && orderDetail.orderDetail && orderDetail.orderDetail.selectedVariant && orderDetail.orderDetail.selectedVariant[0] && orderDetail.orderDetail.selectedVariant[0].price)  }}
            </p>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </mat-card>

  <mat-card class="m-12">
    <h2>
      {{ 'ct.items' | translate }}
    </h2>

    <table>
      <thead>
        <tr>
          <th>
            {{ 'ct.serviceName' | translate }}
          </th>
          <th>
            {{ 'ct.qty' | translate }}
          </th>
          <th>
            {{ 'ct.unit_price' | translate }}
          </th>
          <th>
            {{ 'ct.final_price' | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {{ (orderDetail && orderDetail.orderDetail && orderDetail.orderDetail.serviceName ) }}
          </td>
          <td>
            1
          </td>
          <td>
            {{ (orderDetail && orderDetail.orderDetail && orderDetail.orderDetail.selectedVariant && orderDetail.orderDetail.selectedVariant[0] && orderDetail.orderDetail.selectedVariant[0].price ) }}
          </td>
          <td>
            {{ (orderDetail && orderDetail.orderDetail && orderDetail.orderDetail.selectedVariant && orderDetail.orderDetail.selectedVariant[0] && orderDetail.orderDetail.selectedVariant[0].price ) }}
          </td>
        </tr>
      </tbody>
    </table>
  </mat-card>

  <mat-card class="m-12" *ngIf="paymentList && paymentList.length" >
    <h2> {{ 'ct.attached_payments' | translate }} </h2>
    
    <table>
      <thead>
        <tr>
          <th>
            {{ 'ct.number' | translate }}
          </th>
          <th>
            {{ 'ct.description' | translate }}
          </th>
          <th>
            {{ 'ct.total' | translate }}
          </th>
          <th>
            {{ 'ct.date' | translate }}
          </th>
          <th>
            {{ 'ct.method' | translate }}
          </th>
          <th>
            {{ 'ct.status' | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of paymentList">
          <td>
            {{ item.signatura }}
          </td>
          <td>
            {{ item.purpose }}
          </td>
          <td>
            {{ item.amountPaid }}
          </td>
          <td>
            {{ item.registredDataTime }}
          </td>
          <td>
            {{ item.paymentMethod }}
          </td>
          <td>
            {{ item.status }}
          </td>
        </tr>
      </tbody>
    </table>
  </mat-card>
</mat-card>