import { INg5courseSetting } from 'app/main/content/interfaces/register.interface';
import * as objectPath from 'object-path';
import { AppBuilderComponent } from '../../app-builder/app-builder.component';

export class SuperAdminSettings {
    general = [
        {
          'type': 'field',
          'settings':  {
            updateApi: '',
            fields: [
              {
                fieldType: 'popup',
                title: 'workspace.install_app_template',
                description: 'workspace.install_app_template_desc',
                initValue: '',
                defaultValue: '',
                keyName: 'installApp',
                accessLevel: 'user',
                extraData: { createNew: true },
                component: AppBuilderComponent,
                buttonTitle: 'workspace.install_app_template',
                height: '100%',
                width: '100%'
              },
              {
                fieldType: 'popup',
                title: 'workspace.update_app_template',
                description: 'workspace.update_app_template_desc',
                initValue: '',
                defaultValue: '',
                keyName: 'updateTemplate',
                accessLevel: 'user',
                extraData: { updateTemplate: true },
                component: AppBuilderComponent,
                buttonTitle: 'workspace.update_app_template',
                height: '100%',
                width: '100%'
              }
            ]
        }
      }
    ];

    settingJson = [
        {
            'type': 'section',
            'settings': [
                {
                  'title': 'workspace.installer',
                  'children': [ ...this.general ]
                }
            ]
        }
    ];
    constructor(url: string, appData: INg5courseSetting = {} as any, moreInfo: any = {}) {
        this.settingJson.forEach((setting: any) => {
            setting.settings.forEach((sectionSetting: any) => {
                sectionSetting.children.forEach((fieldSetting: any) => {
                    fieldSetting.settings = fieldSetting.settings || {};
                    fieldSetting.settings.updateApi = url;
                    fieldSetting.settings['triggerSub'] = moreInfo.triggerSub;
                    fieldSetting.settings.fields = fieldSetting.settings.fields || [];
                    fieldSetting.settings.fields.forEach((field: any) => {
                        field['initValue'] = (objectPath.get(appData, field.keyName) || objectPath.get(appData, field.keyName) === false) ? 
                        objectPath.get(appData, field.keyName) : field['defaultValue'];
                        field['dialogRef'] = moreInfo.dialogRef;

                        if (field.fieldType === 'select') {
                          field.options = field.options || [];
                          field.options = field.options.concat(moreInfo[field.keyName] || []);
                        }
                        if (field.fieldType === 'popup') {
                          moreInfo[field.keyName] = moreInfo[field.keyName] || {};
                          field.extraData = field.extraData || {};
                          field.extraData = Object.assign(moreInfo[field.keyName], field.extraData);
                        }
                    });
                });
            });
        });
    }

    get settingsModel () {
        return this.settingJson;
    }
}
