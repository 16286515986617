import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { HelpersService } from '../../services/helpers/helpers.service';
var TemplatePreviewComponent = /** @class */ (function () {
    function TemplatePreviewComponent(helperService, dialogRef, data) {
        this.helperService = helperService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.htmlPreviewLang = 'en';
        this.previewView = 'html';
        this.languages = [];
    }
    TemplatePreviewComponent.prototype.ngOnInit = function () {
        this.getLang();
        this.templateData = this.data.templateData;
    };
    TemplatePreviewComponent.prototype.getLang = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, err_1;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 2, , 3]);
                        _a = this;
                        _c = (_b = this.helperService).formatTheLanguageArray;
                        return [4 /*yield*/, this.helperService.getActiveLangs()];
                    case 1:
                        _a.languages = _c.apply(_b, [_d.sent()]);
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _d.sent();
                        console.error(err_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    TemplatePreviewComponent.prototype.close = function () {
        this.dialogRef.close();
    };
    return TemplatePreviewComponent;
}());
export { TemplatePreviewComponent };
