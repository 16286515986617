import { Injectable } from '@angular/core';
import { SocketService } from './socket.service';

@Injectable()
export class WorkspaceSocketService {
    constructor(
        private socketService: SocketService
    ) {
        this.socketService.onSocketEventTriggered.subscribe((data) => {
            if (data && data.eventType === 'workspaceEvents') {
                this.onEvent(data.res);
            }
        });
    }

    onEvent(res: any) {
        console.log('inside the office event', res);
    }
}
