import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HelpersService } from '../../../services/helpers/helpers.service';
import { MatSnackBar } from '@angular/material';
import * as _ from 'lodash';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
@Component({
  selector: 'app-private-filter',
  templateUrl: './private-filter.component.html',
  styleUrls: ['./private-filter.component.scss']
})
export class PrivateFilterComponent implements OnInit {
  @Input() regId;
  @Output() privateFilterChange: EventEmitter<Object> = new EventEmitter();

  profileData = {};
  filterList = [];
  deleteMode = false;

  constructor(
    private helperService: HelpersService,
    public snackBar: MatSnackBar,
    private translationLoader: FuseTranslationLoaderService,
  ) { }

  ngOnInit(): void {
    // this.translationLoader.loadTranslations(english, polish);
    this.helperService.getTrans('datatable')
      .then((data2) => {
        this.translationLoader.loadTranslationsV2(data2);
      }).catch(err => {
        console.info(err);
      });
    this.helperService.getProfileData()
    .then((data) => {
      this.profileData = data;
      this.filterList = (this.profileData['privateFilters'] && this.profileData['privateFilters'][this.regId]) || [];
    }).catch((err) => {
      console.info(err);
      this.snackBar.open(err || 'Some error Occured', 'Okay', {
        duration: 2000,
      });
    });
  }

  addPrivateFilter(filters, filterName, regId): void {
    this.profileData['privateFilters'] = this.profileData['privateFilters'] || {};
    this.profileData['privateFilters'][regId] = this.profileData['privateFilters'][regId] || [];
    this.profileData['privateFilters'][regId].push({
      name: filterName,
      filter: filters
    });

    this.helperService.updateProfileData({privateFilters: this.profileData['privateFilters']})
    .then((res) => {
      this.snackBar.open('Private Filter Created', 'Okay', {
        duration: 2000,
      });
    }).catch(err => {
      console.info(err);
      this.snackBar.open(err || 'Some error Occured', 'Okay', {
        duration: 2000,
      });
    });
  }

  filterIt(filter) {
    if (filter && !this.deleteMode) { 
      this.privateFilterChange.emit(filter.filter);
    } else if (filter && this.deleteMode) {
      this.deleteFilter(filter);
    }
  }

  deleteFilter(filter) {
    this.profileData['privateFilters'] = this.profileData['privateFilters'] || {};
    this.profileData['privateFilters'][this.regId] = this.profileData['privateFilters'][this.regId] || [];

    const filterIndex = _.findIndex(this.profileData['privateFilters'][this.regId], {name: filter.name});

    if (filterIndex >= 0) {
      this.profileData['privateFilters'][this.regId].splice(filterIndex, 1);
      this.helperService.updateProfileData({privateFilters: this.profileData['privateFilters']})
      .then((res) => {
        this.snackBar.open('Private Filter Created', 'Okay', {
          duration: 2000,
        });
      }).catch(err => {
        console.info(err);
        this.snackBar.open(err || 'Some error Occured', 'Okay', {
          duration: 2000,
        });
      });
    }
  }
}
