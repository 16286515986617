import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';
import { AgentProposalSelectionPopupComponent } from '../agent-proposal-selection-popup/agent-proposal-selection-popup.component';

@Component({
  selector: 'app-agent-proposal-builder',
  templateUrl: './agent-proposal-builder.component.html',
  styleUrls: ['./agent-proposal-builder.component.scss']
})
export class AgentProposalBuilderComponent implements OnInit {

  @Input() editNg5Service: any;
  @Input() staticProcessStatus: any;
  @Input() staticCommentField: any;
  @Output() updateDataFromCt: EventEmitter<any> = new EventEmitter();
  @Output() processUpdateRecord: EventEmitter<any> = new EventEmitter();

  @Input() translateData: any;

  recData: any;
  proposals: any = [
    {
      startDateTime: ""
    }
  ];
  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.recData = this.editNg5Service.formData;
    this.proposals = this.recData.proposals || this.proposals;
  }

  addProposal() {
    this.proposals.push({
      startDateTime: ""
    })
    this.selectProposal(this.proposals.length - 1);
  }

  removeProposal(index: any) {
    this.proposals.splice(index, 1);
  }

  processUpdateRecordTrigger(event: any) {
    console.log("event");
    this.processUpdateRecord.emit(event);
  }

  selectProposal(i) {
    // this.proposals[i]['startDateTime'] = moment();
    const dialogRef = this.dialog.open(AgentProposalSelectionPopupComponent, {
      width: '100%',
      height: '100%',
      panelClass: 'happ-form-builder-dialog',
      data: {
        serviceId: this.editNg5Service.formData._id,
        duration: this.recData.eventTime,
        expiryTime: this.recData.expiryTime,
        eventId: this.proposals[i]['eventId'],
        eventDoc: this.proposals[i],
        subject: (this.proposals[i] && this.proposals[i].subject) || this.recData.serviceName,
        caseSignature: this.recData.parentCaseId
      }
    });

    
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log("event - ", result);
        this.proposals[i] = result;
        // this.proposals[i]['startDateTime'] = result.start;
        // this.proposals[i]['eventId'] = result.eventId;
        this.updateRec({ proposals: this.proposals, saveData: true });
      }
    });
  }

  onBlur(field) {
    const updateDoc: any = {
      saveData: true
    };
    if (field === "expiryTime") {
      updateDoc[field] = this.recData[field];
      updateDoc["expireDateTime"] = moment().add(updateDoc[field], "hour");
    } else {
      updateDoc[field] = this.recData[field];
    }
    this.updateRec(updateDoc);
  }

  updateRec(data: any) {
    this.updateDataFromCt.emit(data);
  }
}
