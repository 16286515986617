<div style="background: #f6f6f6;" class="m-0">
  <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <button class="mr-12" (click)="dialogRef.close(null)" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
    <span class="title dialog-title"> {{ 'global.form_list' | translate }} </span>
    <span fxFlex></span>
    <button class="mr-12" (click)="saveFormSelection()" mat-icon-button>
        <mat-icon>check</mat-icon>
      </button>
  </div>
</div>
<app-form-list [allowSelection]="allowSelection" [allowMultipleSelection]="allowMultipleSelection" [(singleForm)]="singleForm" [(multipleForm)]="multipleForm" ></app-form-list>