import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-setting-item-view',
  templateUrl: './setting-item-view.component.html',
  styleUrls: ['./setting-item-view.component.scss']
})
export class SettingItemViewComponent implements OnInit {

  @Input() heading = '';
  @Input() layout = 'row';
  @Input() description = '';
  @Input() value = '';

  constructor() { }

  ngOnInit() {
  }

}
