import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { OfficeQuery } from '../../state/officeState/office.query';
var ALL_SIDE_HIDE_LAYOUT = {
    layout: {
        navbar: {
            hidden: true
        },
        toolbar: {
            hidden: true
        },
        footer: {
            hidden: true
        },
        sidepanel: {
            hidden: true
        }
    }
};
var PendingPaymentComponent = /** @class */ (function () {
    function PendingPaymentComponent(
    // public dialogRef: MatDialogRef<PendingPaymentComponent>,
    // @Inject(MAT_DIALOG_DATA) public data,
    http, _fuseConfigService, officeQuery, route, router) {
        this.http = http;
        this._fuseConfigService = _fuseConfigService;
        this.officeQuery = officeQuery;
        this.route = route;
        this.router = router;
        this.timer = 0;
    }
    PendingPaymentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.startTimmer();
        this.getConfig();
        this.tempCurr = this._fuseConfigService.configValue;
        this._fuseConfigService.config = ALL_SIDE_HIDE_LAYOUT;
        setTimeout(function () {
            _this._fuseConfigService.config = ALL_SIDE_HIDE_LAYOUT;
        }, 400);
        this.officeQuery.officeDoc.subscribe(function (officeData) {
            if (officeData) {
                _this.dayDiff = moment().diff(officeData.lastPaymentDate, "day");
            }
        });
    };
    PendingPaymentComponent.prototype.startTimmer = function () {
        var _this = this;
        this.timerInstance = setInterval(function () {
            if (_this.timer === 0) {
                clearInterval(_this.timerInstance);
                // this.close();
            }
            else {
                _this.timer = _this.timer - 1;
            }
        }, 1000);
    };
    PendingPaymentComponent.prototype.close = function () {
        // this.dialogRef.close();
        if (this.route.snapshot.queryParams.redirectUrl) {
            this.router.navigateByUrl(this.route.snapshot.queryParams.redirectUrl);
        }
        else {
            this.router.navigateByUrl("/dashboard");
        }
        this._fuseConfigService.config = this.tempCurr;
        setTimeout(function () {
            window.location.reload();
        }, 500);
    };
    PendingPaymentComponent.prototype.addCredit = function () {
        this.router.navigateByUrl("/office/payments/dashboard");
        this.close();
    };
    PendingPaymentComponent.prototype.getRequest = function (getUrl, extraHeader) {
        var _this = this;
        if (extraHeader === void 0) { extraHeader = {}; }
        return new Promise(function (resolve, reject) {
            try {
                var token = JSON.parse(localStorage.getItem('ngStorage-token'))['token'];
                _this.http.get("" + environment.serverUrl + getUrl, { headers: new HttpHeaders({ 'Authorization': token }) })
                    .subscribe(function (data) {
                    _this.buildBackendResponse(data, resolve, reject);
                }, function (err) { return _this.buildHttpError(err, reject); });
            }
            catch (err) {
                reject(err);
            }
        });
    };
    /**
   *
   * @param err
   * @param reject
   * build the error message from the http error
   */
    PendingPaymentComponent.prototype.buildHttpError = function (err, reject) {
        // if (err && err['status'] && err['message']) {
        //   reject(err['status'] + '-' + err['message']);
        // } else {
        //   reject('Error Occured while fetching the data');
        // }
        reject((err && err.error && err.error.message) || err.message || 'Error Occured while fetching the data');
    };
    PendingPaymentComponent.prototype.buildBackendResponse = function (data, resolve, reject) {
        if (data && data['success']) {
            if (data['result']) {
                resolve(data['result']);
            }
            else {
                reject('Response is empty. Please check the configuration');
            }
        }
        else {
            reject((data && data['error'] && data['error']['txt']) || 'Some error occured');
        }
    };
    PendingPaymentComponent.prototype.getConfig = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.getRequest("api/global-var/pending-usage-config")];
                    case 1:
                        data = _a.sent();
                        this.configData = data.data || [];
                        this.selectedConf = this.configData.filter(function (ele) {
                            console.log("ele - ", ele, _this.dayDiff);
                            return (ele && ele.min < _this.dayDiff && ele.max > _this.dayDiff);
                        });
                        this.selectedConf = this.selectedConf[0] || {};
                        this.timer = (this.selectedConf && this.selectedConf.time) || 5;
                        this.startTimmer();
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        // this.helperSerice.showInfo(error, "Error", "error");
                        alert(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return PendingPaymentComponent;
}());
export { PendingPaymentComponent };
