<div fxLayout="row">
  <div fxFlex>

    <div *ngIf="!loading">
      <div *ngIf="this.error">
        <p style="color: red;">
          {{ error }}
        </p>
        totalMarkers: {{ totalMarkers }}
      </div>
      
      <!-- <div [ngStyle]="{'display': (totalMarkers > 0) ? 'block' : 'none', 'position': 'relative'}" > -->
      <div style="position: relative;">
        <div id="map" style="height: 500px;"></div>
        <p (click)="reInitData()" style="margin: auto;
        position: absolute;
        top: 0px;
        margin-right: 10px;
        margin-top: 8px;
        cursor: pointer;
        right: 0px;
        z-index: 9999;">
          {{ 'ct.refresh' | translate }}
        </p>
        <div *ngIf="this.totalMarkers <= 0"  style="width: 100%; height: 500px; display: flex; justify-content: center; align-items: center; flex-direction: column;border: 1px solid lightgray;position: absolute;
        top: 0px;
        right: 0px;
        height: 500px;
        background: white;
        z-index: 99999;">
          {{ 'ct.no_marker_to_load' | translate }}
        </div>
      </div>
    </div>
  
    <div
    style="width: 100%; height: 500px; display: flex; justify-content: center; align-items: center; flex-direction: column;border: 1px solid lightgray;"
    *ngIf="loading">
    <mat-spinner></mat-spinner>
    <p style="margin-top: 12px;">
      {{ 'ct.map_cluster_loading' | translate }}
    </p>
  </div>
  </div>

<div style="width: 300px; margin-left: 12px;">

  <mat-tab-group [(selectedIndex)]="selectedIndex" >
    <mat-tab [label]="'ct.map_filters' | translate"> 
      <div style="margin-top: 12px;">
        <div style="color: gray;
        border-bottom: 1px solid lightgray;
        margin-bottom: 12px;">
          <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
            <p style="margin: 0px;">
              {{ 'ct.layers_heading' | translate }} ({{ (field.layers && field.layers.length) || 0 }})
            </p>
            <button mat-icon-button (click)="extraData['layers']=!extraData['layers']">
              <mat-icon *ngIf="extraData['layers']">expand_less</mat-icon>
              <mat-icon *ngIf="!extraData['layers']">expand_more</mat-icon>
            </button>
          </div>
        </div>
        <div fxLayout="column" *ngIf="field.layers && extraData['layers']">
          <div fxLayout="column" *ngFor="let item of field.layers; let i=index" class="mb-12">
            <p style="margin: 0px;    font-size: 20px;
            font-weight: 700;
            margin-left: 12px;
            margin-bottom: 4px;">
              {{ item.name }}
            </p>
            <div fxLayout="column">
              <ng-container *ngFor="let item2 of item.list">
                <mat-checkbox *ngIf="!item2.useInput" (change)="onSelectChange()" class="ml-12" [(ngModel)]="filterValues['layer_' + item2.key]"> {{ item2.name }} </mat-checkbox>
                <mat-form-field *ngIf="item2.useInput" floatLabel="always" appearance="outline" class="happ-small-form-field-outline mb-8" fxFlex>
                  <input matInput [placeholder]="item2.name" (blur)="oneInputFilterChange($event, 'layer_' + item2.key, item2.keyName)" [(ngModel)]="inputFilter[item2.keyName]" >
                </mat-form-field>
              </ng-container>
            </div>
          </div>
        </div>

        <div *ngIf="isAnyLayerSelected" style="color: gray;
        border-bottom: 1px solid lightgray;
        margin-bottom: 12px;">
          <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
            <p style="margin: 0px;">
              {{ 'ct.filters_heading' | translate }} ({{ (field.filters && field.filters.length) || 0 }})
            </p>
            <button mat-icon-button (click)="extraData['filters']=!extraData['filters']">
              <mat-icon *ngIf="extraData['filters']">expand_less</mat-icon>
              <mat-icon *ngIf="!extraData['filters']">expand_more</mat-icon>
            </button>
          </div>
        </div>
        <div fxLayout="column" *ngIf="isAnyLayerSelected && field.filters && extraData['filters']">
          <div fxLayout="column" *ngFor="let item of field.filters; let i=index" class="mb-12">
            <p style="margin: 0px;    font-size: 20px;
            font-weight: 700;
            margin-left: 12px;
            margin-bottom: 4px;">
              {{ item.name }}
            </p>
            <div fxLayout="column">
              <mat-checkbox (change)="onSelectChange()" class="ml-12" [(ngModel)]="filterValues['filter_' + item2.key]"
                *ngFor="let item2 of item.list"> {{ item2.name }} </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab [disabled]="!detailObj" [label]="'ct.map_detail' | translate"> 
      <div style="margin-top: 12px;">
        <button class="mt-12" mat-raised-button (click)="popupEditForm(detailObj)"> {{ 'ct.open_more_detail' | translate }} </button>

        <div class="flex-table mt-12" fxLayout="column" *ngIf="detailObj && field.description">
          <div [innerHtml]="field.description | parseHtmlContent:detailObj:regExp | htmlWithStyle"></div>
        </div>

      </div>
    </mat-tab>
  </mat-tab-group>

 
</div>


</div>

