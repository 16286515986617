import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-description-edit',
  templateUrl: './description-edit.component.html',
  styleUrls: ['./description-edit.component.scss']
})
export class DescriptionEditComponent implements OnInit {

  
  constructor(
    public dialogRef: MatDialogRef<DescriptionEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private cdr: ChangeDetectorRef
    ) { }

  ngOnInit() {
    this.cdr.detectChanges();
  }

  toggleEditor() {
    if (this.data) {
      if (this.data.fieldType === 'textArea') {
        this.data.fieldType = 'editor';
      } else {
        this.data.fieldType = 'textArea';
      }
    }
    this.cdr.detectChanges();

  }
}
