import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from 'app/main/content/global-component/control-template';
import { DatatableService } from 'app/main/content/global-component/datatable/datatable.service';
import { InputDialogComponent } from 'app/main/content/global-component/input-dialog/input-dialog.component';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { WorkspaceQuery } from 'app/main/content/state/workspaceState/workspace.query';
import * as _ from 'lodash';
import * as moment from 'moment';
import { NgxTuiCalendarComponent } from 'ngx-tui-calendar';
import { Subject } from 'rxjs';
import { AgentProposalSelectionService } from './agent-proposal-selection-popup.service';

@Component({
  selector: 'app-agent-proposal-selection-popup',
  templateUrl: './agent-proposal-selection-popup.component.html',
  styleUrls: ['./agent-proposal-selection-popup.component.scss']
})
export class AgentProposalSelectionPopupComponent implements OnInit {
  dtTableAdvanceFilters;
  hardFilter;
  isWorkspace;
  regId;
  timeRange = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00"
  ];
  startDay = "07:00";
  endDay = "21:00";
  slotDuration = "00:30:00";
  showHeader;
  parentId;
  timeGap = [
    {
      title: '15 mins',
      value: "00:15:00"
    },
    {
      title: '30 mins',
      value: "00:30:00"
    },
    {
      title: '1 hr',
      value: "01:00:00"
    },
    {
      title: '2 hr',
      value: "02:00:00"
    }
  ]
  parentCaseId;
  appData;
  wsInfo;
  isAdmin;

  @ViewChild('exampleCalendar') exampleCalendar: NgxTuiCalendarComponent;
  
  private unsubscribe = new Subject<void>();

  view: string;
  containerEl: JQuery;
  eventId;
  viewDate: Date;
  defView = '';
  workspaceData;
  events = [];
  loading = true;
  mqFilter = '';
  selectedDay: any;
  currentMQFilter;
  options: object = {};
  selectedEventData: any;
  showUserFilter = false;
  dtOptions: object = {};
  public filters: object = {};
  public dataTablesParameters: object = {};
  inputDialogRef: MatDialogRef<InputDialogComponent>;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  manageGlobalFilter = false;
  managePrivateFilter = false;
  showGlobalFilter = true;
  showPrivateFilter = true;
  taskView: any = ['task', 'milestone'];
  selectedStart;
  constructor(
    public dialog: MatDialog,
    public calendarService: AgentProposalSelectionService,
    private dataTableService: DatatableService,
    public snackBar: MatSnackBar,
    private translationLoader: FuseTranslationLoaderService,
    private translate: TranslateService,
    private helperService: HelpersService,
    private workspaceQuery: WorkspaceQuery,
    private route: ActivatedRoute,
    private router: Router,
    public dialogRef: MatDialogRef<AgentProposalSelectionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,

  ) {
  }

  async openEvent(eventId) {
    const eventDoc = await this.calendarService.getEvent(eventId);
    // this.editEvent("edit", eventDoc);
  }

  ngOnInit() {

    if (this.data && this.data.eventId) {
      this.calendarService.currentEventId = this.data.eventId;
    } else {
      this.calendarService.currentEventId = null;
    }

    console.log("this.data ", this.data);

    if (this.data && this.data.eventDoc && this.data.eventDoc.startDateTime) {
      this.selectedEventData = this.data.eventDoc || this.selectedEventData;
    }

    this.route.queryParams.subscribe((data) => {
      if (data.eventId && this.eventId !== data.eventId) {
        this.openEvent(data.eventId);
      }
      this.changeCalendarView("agendaThreeDay");
    })


    this.translate.onLangChange.subscribe((lang) => {
      if (lang && lang.lang) {
        this.setCalendarLanguage(lang.lang);
      }
    });

    this.calendarService.calendarId = '';
    this.calendarService.calendarId = this.regId;

    // means control type
    if (this.parentId) {
      this.calendarService.filtertype = 'allCalendar';
    } else {
      this.calendarService.filtertype = 'myCalendar';
    }
    this.mqFilter = this.hardFilter;
    this.calendarService.resolveAll()
    .then(data => {

    console.log("events == ", this.calendarService.calendarViewSettings.events);

      this.view = 'calendar';

      // this.translationLoader.loadTranslations(english, polish);
      this.helperService.getTrans('calendar-template')
      .then((data2) => {
        this.translationLoader.loadTranslationsV2(data2);
      }).catch(err => {
        console.info(err);
      });

      this.calendarService.calendarViewSettings.locale = this.translate.getDefaultLang();
      this.translate.onLangChange.subscribe(lang => {

        this.calendarService.calendarViewSettings.locale = (lang && lang.lang) || 'en';

        this.containerEl.fullCalendar('destroy');
        this.containerEl = $('#calendar');
        this.containerEl.fullCalendar(this.calendarService.calendarViewSettings);
        console.log("events == ", this.calendarService.calendarViewSettings.events);
        this.containerEl.fullCalendar('addEventSource', this.calendarService.calendarViewSettings.events);
      }, (err: any) => {
        console.info(err);
      });

      this.events = this.calendarService.events || [];

    this.calendarService.calendarViewSettings.eventMouseout = function (calEvent, jsEvent)  {
      $(this).css('z-index', 8);
      $('.tooltipevent').remove();
    };

    this.calendarService.calendarViewSettings.eventMouseover = function (calEvent, jsEvent) {
      const tooltip = 
      `
      <div class="tui-full-calendar-floating-layer tui-view-17 tooltipevent" style="position:absolute;z-index:10001;" ><div class="tui-full-calendar-popup tui-full-calendar-popup-detail">
  <div class="tui-full-calendar-popup-container">
    <div class="tui-full-calendar-popup-section tui-full-calendar-section-header">
      <div>
        <span class="tui-full-calendar-schedule-private tui-full-calendar-icon tui-full-calendar-ic-private"></span>
        <span class="tui-full-calendar-schedule-title" style="color: darkblue;">${calEvent.title || '&nbsp'}</span>
      </div>
      <div class="tui-full-calendar-popup-detail-date tui-full-calendar-content">${moment(calEvent.start).format('YYYY-MM-DD HH:mm')} - ${moment(calEvent.end).format('YYYY-MM-DD HH:mm')}</div>
    </div>
    <div class="tui-full-calendar-section-detail">
        <div class="tui-full-calendar-popup-detail-item"><span class="tui-full-calendar-icon tui-full-calendar-ic-location-b"></span><span class="tui-full-calendar-content">${calEvent.info_responsible || ''}</span></div>
        
        <div class="tui-full-calendar-popup-detail-item tui-full-calendar-popup-detail-item-indent"><span class="tui-full-calendar-icon tui-full-calendar-ic-user-b"></span><span class="tui-full-calendar-content">anyone</span></div>
        
        <div class="tui-full-calendar-popup-detail-item"><span class="tui-full-calendar-icon tui-full-calendar-calendar-dot" style="background-color: #00a9ff"></span><span class="tui-full-calendar-content">${calEvent.info_type || ''}</span></div>
        
    </div>
  </div>
  <div class="tui-full-calendar-popup-top-line" style="background-color: #00a9ff"></div>
  <div id="tui-full-calendar-popup-arrow" class="tui-full-calendar-popup-arrow tui-full-calendar-arrow-left">
    <div class="tui-full-calendar-popup-arrow-border">
        <div class="tui-full-calendar-popup-arrow-fill"></div>
    </div>
  </div>
</div>
</div>`;
      $('body').append(tooltip);
      $(this).mouseover(function(e) {
          $(this).css('z-index', 10000);
          $('.tooltipevent').fadeIn('500');
          $('.tooltipevent').fadeTo(10, 1.9);
      }).mousemove(function(e) {
          $('.tooltipevent').css('top', e.pageY + 10);
          $('.tooltipevent').css('left', e.pageX + 20);
      });
  };
    this.calendarService.calendarViewSettings.eventRender = (eventObj, $el) => {

    };

    this.calendarService.calendarViewSettings.events = (start, end, timezone, cb) => {
      console.log("/*/*/*/ ", start, end);
      this.calendarService.filterDateRange.start = start.format() || new Date((new Date().getTime() - (60000 * 60 * 24)));
      this.calendarService.filterDateRange.end = end.format() || new Date();
      this.calendarService.getEvents(this.mqFilter)
      .then(events => {
      console.log("events == 33", this.calendarService.calendarViewSettings.events);

        cb(events || []);
      }).catch(err => {
        console.info(err);
      });
    };
    
    this.calendarService.calendarViewSettings.eventClick = (element: any, event) => {
      // this.editEvent('edit', element);
      console.log("event", event);
      console.log("element", element);
      // this.selectedStartDateTime = element.start
    };
    
    this.calendarService.calendarViewSettings.viewRender = (view, element) => {
      this.calendarService.filterDateRange.start = view.start.format() || new Date((new Date().getTime() - (60000 * 60 * 24)));
      this.calendarService.filterDateRange.end = view.end.format() || new Date();
      if (this.calendarService.calenderFilter) {
        this.calendarService.calenderFilter['registredDataTime'] = {
          $gte: this.calendarService.filterDateRange.start,
          $lte: this.calendarService.filterDateRange.end
        };
      }

    };
    
    this.calendarService.calendarViewSettings.select = (start, end, jsEvent, view, resource ) => {

      let defaultValue = {
        start: start.format(),
        end: end.format()
      };

      console.log("defaultValue - ", defaultValue);
      this.selectedStart = defaultValue.start;

      if (view && view.type === "month") {
        defaultValue = {
          start: start.format("YYYY-MM-DD ") + moment().format("HH:mm"),
          end: start.format("YYYY-MM-DD ") + moment().add(30, 'minute').format("HH:mm")
        };
      }

      this.selectedEventData = {
        start: defaultValue.start,
        end: moment(defaultValue.start).add(this.data.duration, "hour"),
        expireDateTime: moment().add(this.data.expiryTime, "hour"),
        subject: this.data.subject,
        caseSignature: this.data.caseSignature
      }
      if (this.calendarService.currentEventId) {
        this.calendarService.events = this.calendarService.events.map((ele) => {
          if (ele && ele._id === this.calendarService.currentEventId) {
            ele.start = moment(this.selectedEventData.start).format();
            ele.end = moment(this.selectedEventData.end).format()
          }
          return ele;
        });
      } else {
        const temp = this.calendarService.events.findIndex((ele) => (ele && ele._id === "add"));
        if (temp === -1) {
          this.calendarService.events.push({
            ...this.selectedEventData,
            textColor: "white",
            backgroundColor: "red",
            _id: "add"
          });
        } else {
          this.calendarService.events = this.calendarService.events.map((ele) => {
            if (ele && ele._id === "add") {
              ele.start = this.selectedEventData.start;
              ele.end = this.selectedEventData.end
            }
            return ele;
          });
        }
      }
      // this.containerEl.fullCalendar('rerenderEvents');
      // this.containerEl.fullCalendar('render');


      console.log("---------", this.calendarService.events);


      if (this.parentId) {
        defaultValue['parentId'] = this.parentId;
      }

      if (this.parentCaseId) {
        defaultValue['parentCaseId'] = this.parentCaseId;
      }
    };
    
    this.calendarService.calendarViewSettings.eventDrop = ( event, delta, revertFunc ) => {
     if (event && event['originalRec']) {
      event['originalRec'] = event['originalRec'] || {};
      event['originalRec']['start'] = moment(event.start).format();
      event['originalRec']['end'] = moment(event.end).format();
      this.updateEvent(event);
     }
    };

    this.calendarService.calendarViewSettings.eventResize = ( event, delta, revertFunc, jsEvent, ui, view ) => {
     if (event && event['originalRec']) {
      event['originalRec'] = event['originalRec'] || {};
      event['originalRec']['start'] = moment(event.start).format();
      event['originalRec']['end'] = moment(event.end).format();
      this.updateEvent(event);
     }
    };
    this.loading = false;

    this.calendarService.calendarViewSettings.locale = 'pl';

    setTimeout(() => {
      this.containerEl = $('#calendar');
      this.containerEl.fullCalendar(this.calendarService.calendarViewSettings);
      this.setCalendarLanguage(this.translate.currentLang);
    }, 400);

  }).catch(err => {
      console.info(err);
      this.snackBar.open(err || 'Error occured while getting data', 'Okay', {
        duration: 2000,
      });
    });
  }

  setCalendarLanguage(lang) {
    if (this.containerEl && this.containerEl.fullCalendar) {
      this.containerEl.fullCalendar('option', 'locale', lang);
    }
  }

  ngAfterViewInit() {
  }

  addEventButton() {
    // tslint:disable-next-line:radix
    const diff = (this.calendarService.slotDuration && parseInt(this.calendarService.slotDuration)) || 30;
    const defaultData = {
      start: moment().format('YYYY-MM-DD HH:mm'),
      end: moment((new Date().getTime()) + (60000 * diff)).format('YYYY-MM-DD HH:mm')
    };
    if (this.parentId) {
      defaultData['parentId'] = this.parentId;
    }

    if (this.parentCaseId) {
      defaultData['parentCaseId'] = this.parentCaseId;
    }

  }

  updateEvent(event) {
    this.calendarService.updateEvent(event['originalRec'])
    .then(data => {
      return this.calendarService.getEvents(this.mqFilter);
    })
    .then(eventList => {
      this.calendarService.calendarViewSettings.events = eventList || [];
      this.snackBar.open('Event Updated', 'Okay', {
        duration: 2000,
      });
    }).catch(err => {
      this.snackBar.open(err || 'Error occured while getting data', 'Okay', {
        duration: 2000,
      });
    });
  }

  filterEvents(filterType: string, filterValue: string) {
    if (filterType === 'info_status') {
      if (filterValue) {
        this.calendarService.statusFilter = filterValue;
        this.calendarService.customFilter['info_status'] = filterValue;
      } else {
        delete this.calendarService.customFilter['info_status'];
      }
    } else if (filterType === 'info_type') {
      if (filterValue) {
        this.calendarService.customFilter['info_type'] = filterValue;
      } else {
        delete this.calendarService.customFilter['info_type'];
      }
    } else if (filterType === 'info_responsible') {
      delete this.calendarService.customFilter['info_responsible'];
    }
    // if (this.view === 'dataTable') {
    //   this.dataTableService.onFilterChanged.next(this.calendarService.customFilter);
    // } else {
      this.reRenderCalendarData(this.mqFilter);
    // }
  }

  changeCalendarView(type: string): void {
    this.calendarService.calendarViewSettings.defaultView = type;      
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { view: type }, 
      queryParamsHandling: 'merge'
    });
    // if (type === 'list') {
    //   // datatable view..
    //   this.view = 'dataTable';
    //   this.dataTableService.onDataChanged.next({
    //     regId: this.calendarService.regId,
    //   });
    //   this.dataTableService.onFilterChanged.next(this.calendarService.customFilter);
    // } else {
      this.view = 'calendar';
      // this.containerEl = $('#calendar');
      if (this.containerEl) {

        this.containerEl.fullCalendar('changeView', type);
        this.calendarService.calendarViewSettings.defaultView = type;      
      }
    // }
  }

  removeSelecteUser(i) {
    if (this.calendarService.customFilter['info_responsible'].$in) {
      this.calendarService.customFilter['info_responsible'].$in.splice(i, 1);
      this.reRenderCalendarData(this.mqFilter);
    }
  }

  reRenderCalendarData(mqHardFilter) {
    this.calendarService.getEvents(mqHardFilter)
      .then(eventList => {
        eventList = eventList || [];
        this.containerEl.fullCalendar('refetchEvents');
        this.snackBar.open('Updating', 'Okay', {
          duration: 2000,
        });
      }).catch(err => {
        this.snackBar.open(err || 'Error occured while getting data', 'Okay', {
          duration: 2000,
        });
      });
  }

  changeSlotDuration(item) {
    this.calendarService.calendarViewSettings.slotDuration = item.value;
    this.slotDuration = item.value;
    // this.containerEl.fullCalendar('refetchEvents');
    this.containerEl.fullCalendar('option','slotDuration', item.value);
  }

  onSelectionChange(eve: any, type) {
    console.log(eve);
    this.containerEl.fullCalendar('option',type, eve.value);
  }

  async submit() {
    try {

      // precheck.
      const dataToInsert: any = { 
        type: "new",
        expiryTime: this.data.expiryTime, 
        start: this.selectedStart, 
        end: moment(this.selectedStart).add(this.data.duration, "hour") 
      };

      this.calendarService.events = this.calendarService.events || [];
      const tempIndex = this.calendarService.events.filter((ele) => (ele && ele.start && ele.end && (moment(ele.start).isBetween(dataToInsert.start, dataToInsert.end) || moment(ele.end).isBetween(dataToInsert.start, dataToInsert.end))))

      if (tempIndex && tempIndex.length && tempIndex[0] && tempIndex[0]._id !== this.calendarService.currentEventId) {
        this.helperService.openConfirmPopUp("Time slot is conflicting with one or more task events, are you sure you want to add?")
        .subscribe((data) => {
          if (data) {
            this.saveOrUpdate(dataToInsert);
          }
        })
      } else {
        this.saveOrUpdate(dataToInsert);
      }

      
    } catch (error) {
      console.error("error", error);
      this.loading = false;
    }
  }

  async saveOrUpdate(dataToInsert) {
    try {
      this.loading = true;
      
      if (this.data.eventId) {
        dataToInsert.type = "update";
        dataToInsert.eventId = this.data.eventId;
      }
      const data = await this.helperService.postRequest(`api/shared-service/cal-event/${this.data.serviceId}`, dataToInsert)
      this.helperService.showInfo("Slot added");
      this.dialogRef.close({startDateTime: this.selectedStart, eventId: data._id, endDateTime: data.end, subject: data.subject, state: data.state, serviceId: data.serviceId, expireDateTime: data.expireDateTime, caseSignature: data.caseSignature});
      this.loading = false;
    } catch (error) {
      console.error("error", error);
      this.loading = false;
    }
  }

}
