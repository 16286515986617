<!-- first save record then edit to have options. -->
<!-- <div *ngIf="!recId || recId === 'new'">
    <h3> {{ 'ct.first_save_record_then_edit_to_view_options' | translate }} </h3>
</div> -->
<!-- *ngIf="recId && recId !== 'new'"  -->
<div 
fxLayout="column">
    <div class="ml-12" *ngIf="field.allow_description && field.description" [innerHTML]="field.description | htmlWithStyle">
    </div>
   
    <div *ngIf="showSearch || (showList && model)" fxLayout="row">
        <mat-form-field color="accent" [ngClass]="{'happ-small-form-field-outline': field.smallField}" floatLabel="always" appearance="outline" fxFlex>
            <mat-label>{{field.title | translate}} </mat-label>

            <input matInput (keydown.Tab)="getDataFromServer(searchMultiFileds)" placeholder="{{field.title | translate}}" [(ngModel)]="searchMultiFileds">
        </mat-form-field>
        <mat-spinner-button style="margin: 0px; margin-left: 5px;"
            [options]="buttonOptions"
            (btnClick)="getDataFromServer(searchMultiFileds)">
        </mat-spinner-button>
    </div>

    <div fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="center center" (click)="showList=!showList"  matTooltip="Click to edit" *ngIf="model" class="mb-16">
            <h4 style="display: inline; width: 100%;"   class="m-0">
                <span *ngIf="fieldToStore && fieldToStore.length" style="font-weight: 700; cursor: pointer;padding-left: 12px;">
                        <span *ngFor="let i of fieldToStore; let tt=index">
                            {{(recordData[i]) || "--"}}
                            {{((fieldToStore.length - 1) === tt)? '' : ', ' }}
    
                        </span>
                </span>
            </h4>
            <p style="margin: 0px; color: blue;cursor: pointer;">
                {{ 'ct.click_to_change' | translate }}
            </p>
        </div>
        <div class="happ-list-field mb-20" fxLayout="column" *ngIf="resData && showList">
            <div *ngIf="(resData && resData.length === 0)" class="ml-12">
                <!-- Brak rekordów o takiej nazwie -->
                <h3>{{ 'ct.there_is_no_record' | translate }}</h3>
            </div>
            <div style="overflow: auto; max-height: 500px;" *ngIf="resData">
                <mat-nav-list>
                    <a  (click)="selectVal(option)" *ngFor="let option of resData" mat-list-item>
                        <mat-icon style="font-size: 32px; color: #16a716; width: 32px; height: 32px;" class="mr-16" *ngIf="model == option._id">check_circle</mat-icon>

                        <span *ngIf="field && field['field_to_show']">
                            <span *ngFor="let i of fieldToShow; let tt=index">
                                <!-- {{ option | json }} -->
                                {{option[i] || "--"}}
                                {{((fieldToShow.length - 1) === tt)? '' : ', ' }}
                            </span>
                        </span>
                    </a>
                </mat-nav-list>
            </div>
        </div>
    </div>
</div>