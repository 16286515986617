import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';
import { HelpersService } from '../../services/helpers/helpers.service';
import { ProfileHelperService } from '../../services/profile-helper/profile-helper.service';
import { IProfile } from '../../interfaces/profile.interface';

@Injectable()
export class OfficeListService implements Resolve<any>
{
  constructor(
    private router: Router,
    private helperService: HelpersService,
    private profileHelperService: ProfileHelperService,
  ) { }

  officeList;
  error;
  /**
   * The App Main Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      const officeId = localStorage.getItem('selectedOfficeId');
      this.error = null;
      if (route.params.officeId === ':officeId' && officeId) {
        this.router.navigate([route.routeConfig.path.replace(':officeId', officeId)]);
      }
      this.getOfficeList(route.params.id)
        .then(() => {
          resolve(true);
        }).catch(err => {
          console.info(err);
          reject(err);
        });
    });
  }

  getOfficeList(id): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log("getOfficeList === get");
      this.helperService.getRequest(`api/offices`)
        .then(officeList => {
          this.officeList = officeList || [];
          const profileOfficeList = (JSON.parse(localStorage.getItem('ngStorage-profile')) && JSON.parse(localStorage.getItem('ngStorage-profile'))['offices']) || [];
          if (profileOfficeList) {
            this.officeList.forEach(office => {
              profileOfficeList.forEach(profileOffice => {
                if (office['_id'] === profileOffice['officeId']) {
                  office['starred'] = profileOffice['starred'] || false;
                }
              });
            });
          }
          resolve(true);
        }).catch(err => {
          console.info(err);
        });
    });
  }

  officeChanged(officeId) {
    return this.profileHelperService.updateProfileDoc({ lastSelectedOffice: officeId });
  }

  transformSort(array: any[], field: string): any[] {
    if (!array || array && array.length === 0) {
      return;
    }
    array.sort((a: any, b: any) => {
      if (a[field] < b[field]) {
        return -1;
      } else if (a[field] > b[field]) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }

  starOffice(office: any) {
    if (office && office._id) {
      this.helperService.patchRequest(`api/profile/${office._id}/starred`, { name: office.name, starred: office.starred})
      .then(() => {
        this.profileHelperService.refreshProfileContactData();
      }).catch(err => {
        console.info(err);
      });
    } else {
      console.log('no officeId provided');
    }
  }

  updateProfileData(data) {
    let profileData: IProfile;
    this.profileHelperService.getLocalProfileData()
      .then((profile: IProfile) => {
        profileData = profile;
        profileData['offices'] = profileData['offices'] || [];
        if (profileData && profileData['offices']) {
          const i = _.findIndex(profileData['offices'], (o) => {
            return o['officeId'] === data['_id'];
          });

          if (i >= 0) {
            profileData['offices'][i]['name'] = data['name'];
            profileData['offices'][i]['starred'] = data['starred'];
          } else {
            const tt: any = {
              name: data['name'],
              starred: data['starred'],
              officeId: data['_id']
            };
            // office not present
            profileData['offices'].push(tt);
          }
          return this.profileHelperService.updateProfileDoc({ offices: profileData['offices'] });
        } else {
          console.log('No office');
        }
      })
      .then(res => {
        console.log('office-list');
        localStorage.setItem('ngStorage-profile', JSON.stringify(profileData));
      }).catch(err => {
        console.info(err);
      });
  }
}
