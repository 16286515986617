import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import * as _ from 'lodash';
var CtSharedTokenListComponent = /** @class */ (function () {
    function CtSharedTokenListComponent(helperService) {
        this.helperService = helperService;
        this.onFieldValueUpdated = new EventEmitter();
        this.modelChange = new EventEmitter();
        this.onError = new EventEmitter();
        this.list = [];
        this.loading = false;
        this.filteredNames = [];
    }
    CtSharedTokenListComponent.prototype.ngOnInit = function () {
        this.getList();
    };
    CtSharedTokenListComponent.prototype.getList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, user, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        this.loading = true;
                        _a = this;
                        return [4 /*yield*/, this.helperService.getRequest("api/shared-token/" + this.regId + "/client-list")];
                    case 1:
                        _a.list = _b.sent();
                        this.filteredNames = this.list;
                        this.loading = false;
                        user = _.find(this.filteredNames, function (ele) {
                            return (ele && ele.clientOfficeId && ele.clientOfficeId._id === _this.model);
                        });
                        console.log("user", user);
                        this.filterText = (user && user.clientOfficeId && user.clientOfficeId.name);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _b.sent();
                        console.error(error_1);
                        this.loading = false;
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CtSharedTokenListComponent.prototype.checkError = function () {
        // errorValidation
        if (this.field['required'] && (!this.model || this.model && this.model.length === 0)) {
            this.error = true;
            this.errorMessage = 'ct.field_is_required';
            if (!this.model) {
                this.onError.emit({
                    error: true,
                    label: this.field['label']
                });
            }
        }
        else {
            this.error = false;
            this.errorMessage = '';
            if (this.model) {
                this.onError.emit({
                    error: false,
                    label: this.field['label']
                });
            }
        }
    };
    CtSharedTokenListComponent.prototype._filterStates = function (value) {
        var filterValue = value.toLowerCase();
        return this.list.filter(function (state) { return (state.clientOfficeId && state.clientOfficeId.name.toLowerCase().indexOf(filterValue) === 0); });
    };
    CtSharedTokenListComponent.prototype.changed = function (value) {
        this.filteredNames = this._filterStates(this.filterText);
    };
    CtSharedTokenListComponent.prototype.onSelection = function () {
        var _this = this;
        var user = _.find(this.filteredNames, function (ele) {
            return (ele && ele.clientOfficeId && ele.clientOfficeId.name === _this.filterText);
        });
        this.model = (user && user.clientOfficeId && user.clientOfficeId._id);
        this.modelChange.next(this.model);
    };
    return CtSharedTokenListComponent;
}());
export { CtSharedTokenListComponent };
