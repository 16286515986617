<div fxLayout="column">
    <div *ngIf="field.allow_description && field.description" [innerHTML]="field.description | htmlWithStyle">
    </div>
    <div fxLayout="row">
        <mat-form-field color="accent" floatLabel="always" appearance="outline" [ngClass]="{'w-100-p': true, 'happ-small-form-field-outline': field.smallField}" fxFlex>
            <mat-label> {{ field.title | translate }} </mat-label>
            <input matInput (keydown.Tab)="getDataFromServer(searchMultiFileds)" placeholder="{{field.title | translate}}" [(ngModel)]="searchMultiFileds">
        </mat-form-field>
        <button (click)="getDataFromServer(searchMultiFileds)" mat-icon-button color="primary">
            <mat-icon>search</mat-icon>
        </button>
    </div>

    <div fxLayout="column">
        <h4 style="display: inline" matTooltip="Click to edit" (click)="showList=!showList" *ngIf="model">
            <!-- <span *ngIf="field && field['field_to_show']">
                    <span *ngFor="let i of field['field_to_show']">
                        {{model['value'][i]}} ,
                    </span>
            </span> -->
            {{ companyName }}
        </h4>
        <div class="happ-list-field" fxLayout="column" *ngIf="resData && showList">
            <div *ngIf="(resData && resData.length === 0)">
                <h3>Brak rekordów o takiej nazwie</h3>
            </div>
            <div *ngIf="resData">
                <mat-list role="list" fxLayout="column">
                    <mat-list-item role="listitem" *ngFor="let option of resData" [style.background]="(model == option)? 'gray': ''" [style.color]="(model && model._id == option[field['field_key']])? 'white': ''">
                        <button mat-raised-button fxFlex fxLayoutAlign="center center" (click)="selectVal(option)">
                            <div style="color: black;">
                              {{ (option.companyName || '') }} {{(option.shortName || '') }} {{ (option.longName || '')}} {{ (option.companyAdress || '')}} {{(option.nip || '') }}
                            </div>
                        </button>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>
    </div>
</div>