import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GlobalAnimation } from 'app/main/content/animations/global.animation';

@Component({
  selector: 'app-card-header-more-options',
  templateUrl: './card-header-more-options.component.html',
  styleUrls: ['./card-header-more-options.component.scss'],
  animations: GlobalAnimation

})
export class CardHeaderMoreOptionsComponent implements OnInit {

  @Input() cardActions: any;
  @Input() isAdmin: any;

  @Input() sortByField: any;
  @Output() sortByFieldChange: EventEmitter<Object> = new EventEmitter();

  @Input() sortByOrder: any;
  @Output() sortByOrderChange: EventEmitter<Object> = new EventEmitter();

  @Output() openWSAppsSettingsClicked: EventEmitter<Object> = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }

  toggleSort() {
    if (this.sortByOrder === 'asc') {
      this.sortByOrder = 'desc';
    } else {
      this.sortByOrder = 'asc';
    }
    this.sortByOrderChange.next(this.sortByOrder);
  }

  sortFieldChange() {
    this.sortByFieldChange.next(this.sortByField);
  }

  

}
