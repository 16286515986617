<div style="background: #f6f6f6;" class="m-0 p-8">
  <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">
      <p>{{ 'document.select_template' | translate }}
      </p>
    </span>
    <span fxFlex></span>
    <button class="mr-12" (click)="closeDialog(null)" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div>
    <div class="content fuse-white-bg">
        <div fxLayout="row">
            <div fxFlex *ngIf="data && data['templateList'] && data['templateList'].length > 0">
                <mat-list role="list">
                    <mat-list-item role="listitem" *ngFor="let temp of data['templateList']">
                        <div fxFlex="100">
                            {{ temp.title }}
                        </div>
                        <span fxFlex></span>
                        <button mat-raised-button class="mr-12" (click)="generateDoc(temp)" color="warn">{{ 'document.select' | translate }}</button>
                        <button mat-raised-button (click)="newTemplate(temp)" color="warn">{{ 'document.edit' | translate }}</button>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>
    </div>
</div>
