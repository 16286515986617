// tslint:disable:indent
import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import * as _ from 'lodash';

export interface AuthState {
token: string;
redirectUrl: string;
officeId: string;
officeName: string;
email: string;
profileId: string;
defaultLang: string;
isAdmin: boolean;
invitationCount: number;
}

const isUserAdmin = () => {
	try {
		const officeDoc = JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) || {};
		const profileDoc = JSON.parse(localStorage.getItem('ngStorage-profile')) || {};
		const usrDoc = _.find(officeDoc.users || [], { id: profileDoc.profileId });
		if (usrDoc && usrDoc['isAdmin']) {
			return { isAdmin: true };
		} else {
			return { isAdmin: false };
		}
	} catch (error) {
		throw error;
	}
};

function getOfficeInfo() {
	try {
		const officeDoc = JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) || {};
		return {
            officeId: (officeDoc && officeDoc._id) || '',
            officeName: (officeDoc && officeDoc.name) || '',
        };
	} catch (err) {
		throw err;
	}
}

function getProfileInfo() {
	try {
		const profileDoc = JSON.parse(localStorage.getItem('ngStorage-profile')) || {};
		return {
            email: (profileDoc.email) || '',
			profileId: (profileDoc.profileId) || '',
			invitationCount: (profileDoc.invitationCount) || 0,
            defaultLang: localStorage.getItem('selectedLanguage') || (profileDoc.defaultLang) || 'en'
        };
	} catch (error) {
		throw error;
	}
}

function getToken() {
	try {
		const tokenInfo = JSON.parse(localStorage.getItem('ngStorage-token')) || {};
		return {
			token: tokenInfo.token || null
		};
	} catch (error) {
		throw error;
	}
}

export function createInitialState(): AuthState {
	try {
		return {
			...getToken(),
			redirectUrl: null,
			...getOfficeInfo(),
			...getProfileInfo(),
			...isUserAdmin()
		};
	} catch (error) {
		console.error(error);
		return {
			officeId: '',
			officeName: '',
			email: '',
			profileId: '',
			invitationCount: 0,
			defaultLang: 'en',
			token: null,
			isAdmin: false,
			redirectUrl: '/login'
		};
	}
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth', resettable: true })
export class AuthStore extends Store<AuthState> {

	constructor() {
		super(createInitialState());
	}

}
