import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AddCardActionComponent } from './add-card-action/add-card-action.component';

@Component({
  selector: 'app-card-actions',
  templateUrl: './card-actions.component.html',
  styleUrls: ['./card-actions.component.scss']
})
export class CardActionsComponent implements OnInit {

  cardActions: any[] = [];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CardActionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit() {
    this.cardActions = this.data.cardActions;
  }

  addNewAction() {
    this.openPopup('new', { actionData: null, index: null });
  }

  editAction({actionData, index}) {
    this.openPopup('edit', { actionData, index });
  }

  openPopup(operation: 'new' | 'edit', data: any) {
    const dialogRef = this.dialog.open(AddCardActionComponent, {
      width: '100%',
      height: '100%',
      panelClass: 'happ-form-builder-dialog',
      data: { operation, data }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.updated) {
        this['cardActions'] = this['cardActions'] || [];
        if (operation === 'edit') {
          this['cardActions'][data.index] = result.actionData;
        } else {
          this['cardActions'].push(result.actionData);
        }
      }
    });
  }

  deleteAction(i: number) {
    if (this) {
      this['cardActions'] = this['cardActions'] || [];
      if (this['cardActions'][i]) {
        this['cardActions'].splice(i, 1);
      }
    }
  }

  saveData() {
    this.dialogRef.close(this.cardActions);
  }
 
}
