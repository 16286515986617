import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { ControlTemplateModule } from '../control-template/control-template.module';
import { SideMenuEditComponent } from '../side-menu-edit/side-menu-edit.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
   FuseSharedModule,
    ControlTemplateModule,

  ],
  exports: [SideMenuEditComponent],
  declarations: [
    SideMenuEditComponent
  ],
})
export class SideMenuEditModule { }
