import { NgModule } from '@angular/core';
import { OfficeGlobalVarComponent } from './office-global-var.component';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
    imports     : [
       FuseSharedModule  
    ],
    declarations: [
        OfficeGlobalVarComponent
    ],
    providers   : [
    ],
    exports: [
        OfficeGlobalVarComponent
    ],
    entryComponents: [
        OfficeGlobalVarComponent
    ]
})
export class OfficeGlobalVarModule
{
}
