import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-platform-layout',
  templateUrl: './platform-layout.component.html',
  styleUrls: ['./platform-layout.component.scss']
})
export class PlatformLayoutComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PlatformLayoutComponent>,
  ) { }

  ngOnInit() {
  }

}
