<div style="position: relative;" fxLayout="column" *ngIf="!field.hide || builder">
    <div fxLayout="row">
        <mat-form-field color="accent" [ngClass]="{'w-100-p': true, 'happ-small-form-field-outline': field.smallField, 'mb-24': (field.allow_description && field.description)}" floatLabel="always" appearance="outline" fxFlex>
            <mat-label> {{ field.title | translate }} </mat-label>
            <input *ngIf="!isTextarea" type="{{field.inputType}}" [attr.aria-label]="field.label" autofocus="true" matInput
                minlength="{{field.min_length}}" maxlength="{{field.max_length}}"
                [disabled]="field.showdisablecontroltype=='true' || field.disable"
                (keyup)="onKeyPress()" (blur)="onKeyPress()" [hidden]="field.hidden" [(ngModel)]="model">
            <textarea *ngIf="isTextarea" (keyup)="onKeyPress()" (blur)="onKeyPress()" fxFlex style="resize: none;" [rows]="field.no_of_rows" matInput [placeholder]="field.title" [disabled]="field.showdisablecontroltype=='true' || field.disable" [hidden]="field.hidden" [(ngModel)]="model"></textarea>
        </mat-form-field>
    </div>
    <div class="happ-field-description" *ngIf="field.allow_description && field.description"> {{ field.description }} </div>
    <div class="error-message" *ngIf="errorMessage && (!min_error && !max_error)"> {{ errorMessage | translate }}
    </div>
    <div class="error-message" *ngIf="min_error"> {{ errorMessage | translate }} {{ field.min_length }} </div>
    <div class="error-message" *ngIf="max_error"> {{ errorMessage | translate }} {{ field.max_length }} </div>
    <div fxLayout="row" style="color: #cc3333;">
        <!-- <mat-icon *ngIf="error" style="color: #cc3333;" class="ml-12">warning</mat-icon> -->
        <p *ngIf="min_error"> Min length Allowed is {{ field.min_length }} </p>
        <p *ngIf="max_error"> Max length Allowed is {{ field.max_length }} </p>
        <!-- <p *ngIf="errorMessage"> {{ errorMessage }} </p> -->
    </div>
</div>