<div style="background: aliceblue; border: 1px solid lightgray; border-radius: 5px;position: relative;" class="mb-12" fxLayout="column" *ngIf="!field.hide || builder">
  <ng-container *ngIf="field.linkType">
    <ng-container [ngSwitch]="field.linkType">
      <ng-container *ngSwitchCase="'client'">
        <a *ngIf="recData && recData._id" (click)="closeDialog()" [routerLink]="['/cp/room/client/'+recData._id]" class="p-12" >
          {{ field.title | translate }}
        </a>
      </ng-container>
      <ng-container *ngSwitchCase="'provider'">
        <a *ngIf="recData && recData._id" (click)="closeDialog()" [routerLink]="['/cp/room/provider/'+recData._id]" class="p-12" >
          {{ field.title | translate }}
        </a>
      </ng-container>
      <!-- <ng-container *ngSwitchDefault>output2</ng-container> -->
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!field.linkType">
    <p>
      {{ 'ct.select_room_type_in_settings' | translate }}
    </p>
  </ng-container>
</div>