<!-- <div class="mb-16" *ngIf="!loading">
    <mat-form-field floatLabel="always" appearance="outline" style="width: 100%">
        <mat-label>{{'ct.enter_comment' | translate}}</mat-label>
        <textarea  rows="4" matInput [placeholder]="'ct.enter_comment' | translate" fxFlex style="resize: none;" (keyup.enter)="createNewComment()"  [(ngModel)]="comment"></textarea>
    </mat-form-field>
  <div *ngIf="!loading && commentList && commentList.length">
    <div class="h-400" style="overflow: auto;">
      <h3> {{ 'ct.comments' | translate }} </h3>
      <div class="p-4 mb-4 px-8 happ-comment-list" fxLayout="row" *ngFor="let comment of commentList; let i=index">
        <div fxLayout="row">
          <img class="avatar my-4" *ngIf="comment && comment.creator" ngxGravatar [email]="comment.creator" >
          <div fxLayout="column">
            <p class="m-0 font-size-14" mat-line> {{ comment.description }} </p>
            <p class="m-0 font-size-12 text-hint"> {{comment.creator}} ( {{ comment.creatingTime | date: 'yyyy:MM:dd HH:mm:ss' }} ) </p>
          </div>
        </div>
        <span fxFlex></span>
        <button *ngIf="comment && this.currentUser && comment.creatorId === this.currentUser.id" (click)="removeComment(comment, i)" mat-icon-button>
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div fxLayoutAlign="center center" *ngIf="!loading && !commentList || (commentList && commentList.length === 0)">
    <h3> {{ 'ct.no_comment_yet' | translate }} </h3>
  </div>
</div>
<div class="w-100-p" fxLayoutAlign="center center" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div> -->
<div class="section">

    <div class="comments">

        <div class="section-header" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="mr-8">comment</mat-icon>
            <span class="section-title">Comments</span>
        </div>

        <div class="section-content">
            <form name="cardCommentForm"
                  #newCommentForm="ngForm" (submit)="createNewComment(newCommentForm)"
                  class="comment new-comment" fxLayout="column" fxLayoutAlign="start" no-validate>

                <div fxLayout="row" fxLayoutAlign="center center">

                    <img class="avatar" *ngIf="currentUser && currentUser.email" ngxGravatar [email]="currentUser.email">

                    <mat-form-field class="mr-12" [ngClass]="{'happ-small-form-field-outline': field.smallField}" floatLabel="always" appearance="outline" fxFlex>
                        <mat-label>Comment</mat-label>
                        <input matInput name="newComment" ngModel #newComment="ngModel" placeholder="Write a comment.." required>
                    </mat-form-field>

                    <button mat-mini-fab class="mat-accent"
                            [disabled]="!newCommentForm.valid || newCommentForm.pristine"
                            aria-label="Add">
                        <mat-icon>add</mat-icon>
                    </button>

                </div>
            </form>

            <div class="comment" fxLayout="row" fxLayoutAlign="start center" *ngFor="let comment of commentList">

                <img class="comment-member-avatar" *ngIf="comment && comment.creator" size="32" ngxGravatar [email]="comment.creator">


                <div fxLayout="column">
                    <div class="comment-member-name">
                        {{comment.creator | getUserInfoByEmail: 'symbleName' }}
                    </div>
                    <div class="comment-bubble">{{comment.description}}</div>
                    <div class="comment-time secondary-text">{{ comment.creatingTime | date: 'yyyy:MM:dd HH:mm:ss' }}</div>
                </div>
            </div>
        </div>
    </div>
</div>
