<div style="height: 20vh; width:100%; background: #52BAD5;position: absolute; top: 0px;">

  </div>
  <div fx style="background: #f5f5f5;">
    <div *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
    <div fxLayout="column" *ngIf="!loading">
      <mat-card [ngSwitch]="status" style=" position: fixed; top: 10vh; left: 30%; z-index: 1111; min-width: 400px; width: 50%; padding: 60px 40px; padding-top: 16px; padding-bottom: 16px;">
        <ng-container *ngSwitchCase="'reject'" >
            <h1 style="color:#737373; font-family:'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:30px; font-style:normal; font-weight:600; line-height:42px; letter-spacing:normal; margin:0; padding:0; text-align:left;">
                You've been invited to join a Pinbo24 account. Are you sure you want to reject?
              </h1>
              <br>
              <p *ngIf="invitationExpired" style="color:#737373; font-family:'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:16px; font-weight:400; line-height:24px; padding:0; margin:0; text-align:left;"><strong>
                This invitation will expire after 7 days</strong>.
              </p>
              <br>
              <div *ngIf="buttonText && userInvitationModel && userInvitationModel.status === 'pending'" fxLayoutAlign="center center">
                <a *ngIf="buttonText" (click)="triggerAction()" style="cursor: pointer; background-color:#6DC6DD; border-collapse:separate; border-top:20px solid #6DC6DD; border-right:40px solid #6DC6DD; border-bottom:20px solid #6DC6DD; border-left:40px solid #6DC6DD; border-radius:3px; color:#FFFFFF; display:inline-block; font-family:'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:16px; font-weight:600; letter-spacing:.3px; text-decoration:none;">
                    <div *ngIf="!loading">
                      Confirm Reject
                    </div>
                    <mat-spinner *ngIf="loading"></mat-spinner>
                  </a>
                </div>
              <br>
        </ng-container>
        <ng-container *ngSwitchCase="'migrate'" >
          <app-migrate [userInvitationId]="invitationId" ></app-migrate>
        </ng-container>
        <ng-container *ngSwitchDefault >
          <app-set-password [userInvitationModel]="userInvitationModel" [userInvitationId]="invitationId"></app-set-password>
        </ng-container>
      </mat-card>
    </div>
  </div>
  