/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./office-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i3 from "@angular/material/list";
import * as i4 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i10 from "@angular/material/icon";
import * as i11 from "@angular/common";
import * as i12 from "./office-list.component";
import * as i13 from "./office-list.service";
import * as i14 from "@angular/router";
import * as i15 from "@angular/material/snack-bar";
import * as i16 from "../../state/officeState/office.global.service";
var styles_OfficeListComponent = [i0.styles];
var RenderType_OfficeListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OfficeListComponent, data: {} });
export { RenderType_OfficeListComponent as RenderType_OfficeListComponent };
function View_OfficeListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "mat-list-item", [["class", "mat-elevation-z1 mat-list-item"], ["role", "listitem"], ["style", "margin: 10px 0px; justify-content: space-between;"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], null, null, i2.View_MatListItem_0, i2.RenderType_MatListItem)), i1.ɵdid(1, 1097728, null, 3, i3.MatListItem, [i1.ElementRef, [2, i3.MatNavList], [2, i3.MatList]], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(335544320, 2, { _avatar: 0 }), i1.ɵqud(335544320, 3, { _icon: 0 }), (_l()(), i1.ɵeld(5, 0, null, 2, 7, "div", [["style", "display: flex; flex-direction: row; align-items: center;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "button", [["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _v.context.$implicit["starred"] = !_v.context.$implicit["starred"];
        var pd_0 = (_co.officeListService.starOffice(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(7, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(8, 0, null, 0, 2, "mat-icon", [["aria-label", "Example icon-button with a heart icon"], ["class", "mat-icon"], ["role", "img"]], [[4, "color", null], [2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i9.View_MatIcon_0, i9.RenderType_MatIcon)), i1.ɵdid(9, 9158656, null, 0, i10.MatIcon, [i1.ElementRef, i10.MatIconRegistry, [8, null], [2, i10.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["star_rate"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [["class", "m-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), (_l()(), i1.ɵeld(13, 0, null, 2, 2, "button", [["mat-button", ""], ["style", "text-transform: capitalize;"]], [[1, "aria-label", 0], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeOffice(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" Select "]))], function (_ck, _v) { _ck(_v, 9, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); var currVal_1 = (i1.ɵnov(_v, 1)._avatar || i1.ɵnov(_v, 1)._icon); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 7).disabled || null); var currVal_3 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = (_v.context.$implicit["starred"] ? "#FFB300" : "black"); var currVal_5 = i1.ɵnov(_v, 9).inline; var currVal_6 = (((i1.ɵnov(_v, 9).color !== "primary") && (i1.ɵnov(_v, 9).color !== "accent")) && (i1.ɵnov(_v, 9).color !== "warn")); _ck(_v, 8, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = _v.context.$implicit.name; _ck(_v, 12, 0, currVal_7); var currVal_8 = _v.context.$implicit.name; var currVal_9 = (i1.ɵnov(_v, 14).disabled || null); var currVal_10 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_8, currVal_9, currVal_10); }); }
function View_OfficeListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "w-100-p"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "mat-list", [["class", "mat-list mat-list-base"], ["fxFlex", ""], ["role", "list"]], null, null, null, i2.View_MatList_0, i2.RenderType_MatList)), i1.ɵdid(2, 49152, null, 0, i3.MatList, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_OfficeListComponent_2)), i1.ɵdid(4, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.officeList; _ck(_v, 4, 0, currVal_0); }, null); }
function View_OfficeListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "h-500 w-100-p"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.officeListService.error; _ck(_v, 2, 0, currVal_0); }); }
export function View_OfficeListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "page-layout carded fullwidth single-scroll"], ["fusePerfectScrollbar", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "top-bg accent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "header white-fg p-24"], ["fxLayout", "row"], ["fxLayoutAlign", "start center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Lista Biur"])), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "content-card fuse-white-bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "content p-24"], ["style", "position: relative;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OfficeListComponent_1)), i1.ɵdid(9, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OfficeListComponent_3)), i1.ɵdid(11, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.officeListService.error; _ck(_v, 9, 0, currVal_0); var currVal_1 = _co.officeListService.error; _ck(_v, 11, 0, currVal_1); }, null); }
export function View_OfficeListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-office-list", [], null, null, null, View_OfficeListComponent_0, RenderType_OfficeListComponent)), i1.ɵdid(1, 114688, null, 0, i12.OfficeListComponent, [i13.OfficeListService, i14.ActivatedRoute, i15.MatSnackBar, i16.OfficeGlobalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OfficeListComponentNgFactory = i1.ɵccf("app-office-list", i12.OfficeListComponent, View_OfficeListComponent_Host_0, {}, {}, []);
export { OfficeListComponentNgFactory as OfficeListComponentNgFactory };
