import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FieldOptionUpdateDialogService {
  private subject = new Subject<any>();
  constructor() { 

  }
  sendNotify(formData) {
      this.subject.next(formData);
  }
  getUpdate(): Observable<any> {
      return this.subject.asObservable();
  }
}
