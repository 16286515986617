<div *ngIf="getLoading" style="width: 100%; height: 400px;" fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
</div>
<ng-container *ngIf="!getLoading" >

    <div *ngIf="getError === 'retry'">
        <h2 style="text-align: center;">
            {{ 'ct.retry_from_other_places' | translate }}
        </h2>
    </div>
    <div *ngIf="fileId && pdfURL">
        <iframe *ngIf="!getLoading && !finalError" [src]="pdfURL" frameborder="0" style="height: 70vh; width: 100%;"></iframe>
    </div>
    <div *ngIf="isScanque" style="cursor: pointer; background: aliceblue; padding: 5px; text-align: center; font-size: 18px; border: 1px solid rgba(200,200,200,0.5);" (click)="moveTo(scanqueEditUrl)">
        {{ 'ct.edit_scanque_document' | translate }}
    </div>
    <div fxLayout="column" style="height: 70vh; width: 100%;background: white;justify-content: center; align-items: center; border: 1px solid gray; display: flex;" *ngIf="(!pdfURL || !fileId ) && !getLoading && !finalError">
        <mat-icon *fuseIfOnDom [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" class="s-128 mb-16 hint-text select-message-icon">
            attach_file
        </mat-icon>
        <h2 *fuseIfOnDom [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" class="select-message-text hint-text">
            <!-- No File to preview -->
            {{ 'ct.no_file_to_preview' | translate }}
        </h2>
    </div>
</ng-container>
