import * as tslib_1 from "tslib";
import { Store, StoreConfig } from '@datorama/akita';
var localOfficeId = function (field) {
    try {
        if (localStorage.getItem('ngStorage-selectedOffice') && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))) {
            return JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))[field];
        }
        return null;
    }
    catch (error) {
        throw error;
    }
};
var ɵ0 = localOfficeId;
export function createInitialState() {
    try {
        return {
            applicationType: 'record',
            regId: null,
            officeId: localOfficeId('_id'),
            recordList: []
        };
    }
    catch (error) {
        console.error(error);
        return {
            applicationType: 'record',
            regId: null,
            officeId: null,
            recordList: []
        };
    }
}
var DataTableStore = /** @class */ (function (_super) {
    tslib_1.__extends(DataTableStore, _super);
    function DataTableStore() {
        return _super.call(this, createInitialState()) || this;
    }
    DataTableStore = tslib_1.__decorate([
        StoreConfig({ name: 'datatable', resettable: true }),
        tslib_1.__metadata("design:paramtypes", [])
    ], DataTableStore);
    return DataTableStore;
}(Store));
export { DataTableStore };
export { ɵ0 };
