import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectWorkspaceAppComponent } from './select-workspace-app.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SelectWorkspaceAppComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    TranslateModule
  ],
  entryComponents: [
    SelectWorkspaceAppComponent
  ],
  exports: [
    SelectWorkspaceAppComponent
  ]
})
export class SelectWorkspaceAppModule { }
