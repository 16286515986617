<ng-container *ngIf="status !== 'success'">
    <div id="login" [ngStyle]="{ 'background-image': 'url(' + storagePrefix + '/login-background.jpg' ||  '/assets/images/backgrounds/new-background.jpg' + ')'}"  fxLayout="row" fxLayoutAlign="start">
    
        <div id="login-intro" fxFlex fxHide fxShow.gt-xs>
    
        </div>
    
        <div id="login-form-wrapper" fusePerfectScrollbar *fuseIfOnDom [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">
    
            <div>
                <img style="width: 352px; margin: 24px;" [src]=" storagePrefix + '/header-logo.jpg' || 'assets/images/header-logo.jpg'" alt="header logo">
            </div>
    
            <div id="login-form">
    
                <div fxLayout="row" class="mb-24" fxLayoutGap="10px" fxLayoutAlign="center space-around">
                    <mat-select aria-label="login_language_selection" (selectionChange)="setLanguage(selectedLangCode)" [(ngModel)]="selectedLangCode" class="w-100-p" placeholder="{{ 'auth.select_language' | translate }}">
                        <mat-select-trigger *ngIf="selectedLanguage">
                            <div fxLayoutAlign="start center">
                                <img class="flag mr-16 w-24 h-24" [src]="'/assets/images/flags/'+selectedLangCode+'.png'"> {{ selectedLanguage.title }}
                            </div>
                          </mat-select-trigger>
                      <mat-option [attr.aria-label]="lang.code" *ngFor="let lang of languages" [value]="lang.code"  >
                          <div fxLayoutAlign="start center">
                              <img class="flag mr-16 w-24 h-24" [src]="'/assets/images/flags/'+lang.code+'.png'">{{lang.title}}
                            </div>
                      </mat-option>
                    </mat-select>
                </div>
    
                <div aria-label="login_to_account" class="title">{{ 'auth.login_to_you_account' | translate }}</div>
    
                <form name="loginForm" [formGroup]="loginForm" novalidate>
    
                    <mat-form-field>
                        <input matInput placeholder="Email" formControlName="email">
                        <mat-error *ngIf="loginFormErrors.email.required">
                            {{ 'auth.email_is_required' | translate }}
                        </mat-error>
                        <mat-error *ngIf="!loginFormErrors.email.required && loginFormErrors.email.email">
                            {{ 'auth.please_enter_valid_email_address' | translate }}
                        </mat-error>
                    </mat-form-field>
    
                    <mat-form-field>
                        <input matInput type="password" placeholder="{{ 'auth.password' | translate }}" formControlName="password">
                        <mat-error *ngIf="loginFormErrors.password.required">
                            {{ 'auth.password_is_required' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <div style="color: red;" aria-label="login_error_msg" class="remember-forgot-password" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" *ngIf="errMess">
                        {{(errMess || errMess['message']) | translate }}
                    </div>
    
                    <button mat-raised-button color="accent" class="submit-button" (click)="login()" aria-label="LOGIN" [disabled]="loader">
                        {{ 'auth.login' | translate }}
                    </button>
    
                </form>
    
                <div class="remember-forgot-password mt-32" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
                    <a class="link" [routerLink]="'/register'">
                        {{ 'auth.create_an_account' | translate }}
                      </a>
    
                    <a [routerLink]="'/forgot-password'" [queryParams]="{backUrl: '/login'}">
                      {{ 'auth.forgot_password' | translate }}
                    </a>
                </div>
    
                <button mat-raised-button color="accent" class="w-100-p mt-12" (click)="loginGoogle()" aria-label="LOGIN" >
                    {{ 'auth.login_with_google' | translate }}
                </button>
    
                <div style="margin-top: 10%;" fxLayoutAlign="center center">
                    <a href="https://pinbox24.com">
                    <h2>
                        {{ 'auth.move_back_to_pinbox24' | translate }}
                    </h2>
                    </a>
                </div>
    
                <!-- <div class="register" fxLayout="column" fxLayoutAlign="center center">
                    <span class="text">
                        {{ 'auth.dont_have_account' | translate }} ?
                      </span>
                    <a class="link" [routerLink]="'/register'">
                      {{ 'auth.create_an_account' | translate }}
                    </a>
                </div> -->
                <!-- <div>
                    <img [src]="storagePrefix + '/footer-logo.jpg'" alt="footer logo">
                </div> -->
            </div>
        </div>
        <p style="position: fixed;bottom: 0px;right: 150px;font-size: 18px; color: rgb(3,155,229)"> {{ version }} </p>
    </div>
</ng-container>
<ng-container *ngIf="status === 'success'">
    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;width: 100%;background: white;">
        <mat-spinner></mat-spinner>
        <h2>
            {{ 'auth.moving_to your_selected_office' | translate }}
        </h2>
    </div>
</ng-container>