
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { Login2Component } from './main/content/pages/authentication/login-2/login-2.component';
import { Register2Component } from './main/content/pages/authentication/register-2/register-2.component';
import { ForgotPasswordComponent } from './main/content/pages/authentication/forgot-password/forgot-password.component';
import { OfficeListService } from './main/content/apps/office-list/office-list.service';
import { OfficeListComponent } from './main/content/apps/office-list/office-list.component';
import { ProfileComponent } from './main/content/pages/profile/profile.component';
import { AppDashboardRouteGuard } from './app-dashboard-route.guard';
import { SetPasswordComponent } from './main/content/pages/authentication/set-password/set-password.component';
import { InvitationComponent } from './main/content/pages/invitation/invitation.component';
import { SuperAdminRouteGuard } from './main/content/gaurds/super-admin-route.guard';
import { SaUnauthViewComponent } from './main/content/apps/super-admin/sa-unauth-view/sa-unauth-view.component';
import { SsClientLoginComponent } from './main/content/pages/authentication/ss-client-login/ss-client-login.component';
import { PendingPaymentComponent } from './main/content/components/pending-payment/pending-payment.component';
import { ExternalProcessViewComponent } from './main/content/apps/external-process-view/external-process-view.component';

const routes: Routes = [
    {
        path      : 'register',
        component: Register2Component
    },
    {
        path      : 'pending-payment',
        component: PendingPaymentComponent
    },
    {
        path      : 'profile',
        component: ProfileComponent
    },
    {
        path      : 'login',
        component: Login2Component
    },
    {
        path      : 'cp-login',
        component: SsClientLoginComponent
    },
    {
        path      : 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path      : 'external-process/:token',
        component: ExternalProcessViewComponent
    },
    {
        path : 'changepassword/:token',
        component: SetPasswordComponent
    },
    {
        path      : 'set-password/:token',
        component: SetPasswordComponent
    },
    {
        path      : 'no-access/:type',
        component: SaUnauthViewComponent
    },
    {
        path        : 'dashboard',
        resolve  : {
            office: OfficeListService
        },
        component: OfficeListComponent
    },
    {
        path        : 'workspace',
        data: { breadcrumb: 'Workspace'},
        loadChildren: 'app/main/content/apps/workspace/workspace.module#WorkspaceModule'
    },
    {
        path        : 'checkout',
        loadChildren: 'app/main/content/pages/external/payment-page/external-payment-page.module#ExternalPaymentPageModule'
    },
    {
        path        : 'pricing-plan',
        data: { breadcrumb: 'Workspace'},
        loadChildren: 'app/main/content/apps/pricing-plan/pricing-plan.module#PricingPlanModule'
    },
    {
        path        : 'provider-client',
        data: { breadcrumb: 'Workspace'},
        loadChildren: 'app/main/content/pages/authentication/ss-provider-client-auth/register/register.module#SsProdClientRegisterModule'
    },
    {
        path        : 'cp/orders',
        data: { breadcrumb: 'Workspace'},
        loadChildren: 'app/main/content/apps/financial/orders/order.module#OrderModule'
    },
    {
        path        : 'cp/payments',
        data: { breadcrumb: 'Workspace'},
        loadChildren: 'app/main/content/apps/financial/payments/payments.module#PaymentsModule'
    },
    {
        path        : 'cp/invoices',
        data: { breadcrumb: 'Workspace'},
        loadChildren: 'app/main/content/apps/financial/invoices/invoices.module#InvoicesModule'
    },
    {
        path        : 'room',
        data: { breadcrumb: 'Workspace'},
        loadChildren: 'app/main/content/apps/shared-room/shared-room.module#SharedRoomModule'
    },
    {
        path        : 'web-template',
        data: { breadcrumb: 'Workspace'},
        loadChildren: 'app/main/content/apps/web-template/web-template.module#WebTemplateModule'
    },
    {
        path        : 'cp/specialist/cases',
        data: { breadcrumb: 'Workspace'},
        loadChildren: 'app/main/content/apps/personal-platform/pp-my-cases-new/pp-my-cases-new.module#PpMyCasesNewModule'
    },
    {
        path        : 'cp/specialist/calendar',
        data: { breadcrumb: 'Workspace'},
        loadChildren: 'app/main/content/apps/specialist-calendar/specialist-calendar.module#SpecialistCalendarModule'
    },
    {
        path        : 'cp/specialist/dashboard',
        data: { breadcrumb: 'Workspace'},
        loadChildren: 'app/main/content/apps/specialist-dashboard/specialist-dashboard.module#SpecialistDashboardModule'
    },
    {
        path        : 'cp/room',
        data: { breadcrumb: 'Workspace'},
        loadChildren: 'app/main/content/apps/shared-room/shared-room.module#SharedRoomModule'
    },
    {
        path        : 'cp/room-events',
        data: { breadcrumb: 'Workspace'},
        loadChildren: 'app/main/content/apps/room-event-list/room-event-list.module#RoomEventListModule'
    },
    {
        path: 'cp/calendar',
        data: { breadcrumb: 'Calendar' },
        loadChildren : './main/content/apps/private-calendar/private-calendar.module#PrivateCalenderModule'
    },
    {
        path        : 'provider-client',
        data: { breadcrumb: 'Workspace'},
        loadChildren: 'app/main/content/pages/authentication/ss-provider-client-auth/login/login.module#SsProdClientLoginModule'
    },
    {
        path        : 'provider-client',
        data: { breadcrumb: 'Workspace'},
        loadChildren: 'app/main/content/pages/authentication/ss-provider-client-auth/forgot-password/forgot-password.module#SsProdClientForgotPasswordModule'
    },
    {
        path        : 'cp',
        data: { breadcrumb: 'Workspace'},
        loadChildren: 'app/main/content/apps/personal-platform/personal-platform.module#PersonaPlatformModule'
    },
    {
        path        : 'provider/:providerId/cp',
        data: { breadcrumb: 'Workspace'},
        loadChildren: 'app/main/content/apps/personal-platform/personal-platform.module#PersonaPlatformModule'
    },
    {
        path        : 'office-profile',
        data: { breadcrumb: 'Office-profie'},
        loadChildren: 'app/main/content/apps/office-profile/office-profile.module#OfficeProfileModule'
    },
    {
        path        : 'cp/user',
        data: { breadcrumb: 'Office-profie'},
        loadChildren: 'app/main/content/apps/user-profile/user-profile.module#UserProfileModule'
    },
    {
        path        : 'personal-dashboard',
        data: { breadcrumb: 'Personal Dashboard'},
        loadChildren: 'app/main/content/apps/personal-dashboard/personal-dashboard.module#PersonalDashboardModule'
    },
    {
        path        : 'super-admin',
        data: { breadcrumb: 'Workspace'},
        canActivate: [SuperAdminRouteGuard],
        loadChildren: 'app/main/content/apps/super-admin/super-admin.module#SuperAdminModule'
    },
    {
        path        : 'persona24',
        data: { breadcrumb: 'Persona'},
        loadChildren: 'app/main/content/apps/persona/persona.module#PersonaModule'
    },  
    {
        path        : 'ss-all-apps',
        data: { breadcrumb: 'ss-all-apps'},
        loadChildren: 'app/main/content/apps/ss-all-apps/ss-all-apps.module#SsAllAppsModule'
    },  
    {
        path        : 'platform-global-template',
        data: { breadcrumb: 'platform-global-template'},
        loadChildren: 'app/main/content/apps/platform-global-template/platform-global-template.module#PlatformGlobalTemplateModule'
    },  
    {
        path        : 'invitation',
        data: { breadcrumb: 'Invitation'},
        loadChildren: './main/content/pages/invitation-list/invitation-list.module#InvitationListModule'
    },
    {
        path        : 'userInvitation/:userInvitationId/:status',
        data: { breadcrumb: 'userInvitation'},
        component: InvitationComponent
    },
    {
        path        : 'workspace-management',
        loadChildren: './main/content/apps/workspace-management/workspace-management.module#WorkspaceManagementModule' ,  
    },
    {    
        path        : 'plugins',
        data: { breadcrumb: 'Plugins'},
        loadChildren: 'app/main/content/apps/plugin/plugin.module#PluginModule'
    },
    {
        path        : 'offices/:officeId/dashboard',
        loadChildren: 'app/main/content/apps/dashboard/dashboard.module#DashboardModule' ,
    },
    {
        path        : 'offices/:officeId/organizations',
        loadChildren: 'app/main/content/apps/virtual-office-list/virtual-office-list.module#VirtualOfficeListModule' ,
    },
    {
        path        : 'offices/:officeId/customDashboard',
        loadChildren: 'app/main/content/apps/dashboard/dashboard.module#DashboardModule' ,
    },
    {
        path        : 'offices/:officeId/postBook',
        loadChildren: './main/content/apps/post-book/post-book.module#PostBookModule' ,
    },
    {
        path        : 'office/payments',
        loadChildren: './main/content/apps/office-coin/office-coin.module#OfficeCoinModule' ,
    },
    {
        path        : 'payments/status',
        loadChildren: './main/content/apps/payments/payments.module#PaymentsModule' ,
    },
    {
        path        : 'office-dashoboard',
        canActivate: [AppDashboardRouteGuard],
        loadChildren: 'app/main/content/apps/dashboard/dashboard.module#DashboardModule' ,
    },
    {
        path        : 'offices/:officeId/general-forms',
        data: { breadcrumb: 'Forms'},
        loadChildren: 'app/main/content/apps/global-form/global-form.module#GlobalFormModule'
    },
//     {
//         path        : 'offices/:officeId/org-chart',
//         data: { breadcrumb: 'Org-chart'},
//         loadChildren: 'app/main/content/apps/org-chart-settings/org-chart-settings.module#OrgChartSettingsModule'
//     },
    {
        path        : 'offices/:officeId/agenda-view',
        data: { breadcrumb: 'Agenda View'},
        loadChildren: 'app/main/content/apps/agenda-view/agenda-view.module#AgendaViewModule'
    },
    {    
        path        : 'translate',
        data: { breadcrumb: 'Translate'},
        loadChildren: 'app/main/content/apps/language/language-trans.module#LanguageTransModule'
    },
    {
        path        : 'offices/:officeId/address-book',
        data: { breadcrumb: 'Address book'},
        loadChildren: 'app/main/content/apps/addressbook/addressbook.module#AddressbookModule'
    },
    {
        path        : 'offices/:officeId/general-templates',
        data: { breadcrumb: 'Templates'},
        loadChildren: 'app/main/content/apps/global-template/global-template.module#GlobalTemplateModule'
    },
//     {
//         path        : 'external-widget',
//         loadChildren: 'app/main/content/apps/external-widget/external-widget.module#ExternalWidgetModule'        
//     },
    {
        path        : 'offices/:officeId/registries',
        data: { breadcrumb: 'Register'},
        loadChildren: 'app/main/content/apps/custom-registries/custom-registries.module#CustomRegistriesModule'
    },
    {
        path        : 'offices/:officeId/department-settings',
        data: { breadcrumb: 'Department Settings'},
        loadChildren: 'app/main/content/apps/department-settings/department-settings.module#DepartmentSettingsModule'
    },
    {
        path        : 'offices/:officeId/users-settings',
        data: { breadcrumb: 'Users Settings'},
        loadChildren: 'app/main/content/apps/users-settings/users-settings.module#UsersSettingsModule'
    },
    {
        path        : 'offices/:officeId/processes',
        data: { breadcrumb: 'Process List'},
        loadChildren: 'app/main/content/apps/processes/processes.module#ProcessesModule'
    },
    {
      path        : 'offices/:officeId/process-settings',
      data: { breadcrumb: 'Process Setting List'},
      loadChildren: 'app/main/content/apps/processes-settings/process-settings.module#ProcessSettingsModule'
  },
    {
        path        : 'offices/:officeId/academy',
        data: { breadcrumb: 'Academy'},
        loadChildren: 'app/main/content/apps/academy/academy.module#FuseAcademyModule'
    },
    {
        path        : 'personaStepper',
        data: { breadcrumb: 'Persona Stepper'},
        loadChildren: 'app/main/content/apps/academy/academy.module#FuseAcademyModule'
    },
//     {
//         path        : 'books',
//         loadChildren: 'app/main/content/apps/books/books.module#BooksModule'
//     },
    {
        path        : 'offices/:officeId/application-settings',
        data: { breadcrumb: 'Apps Setting'},

        loadChildren: 'app/main/content/apps/settings/settings.module#SettingsModule'
    },
    {
        path     : 'oneRecordView',
        data: { breadcrumb: 'One View'},
        loadChildren: 'app/main/content/global-component/one-recordview/one-recordview.module#OneRecordViewModule'
    },
//     {
//         path : 'offices/:officeId/gdpr-resources',
//         data: { breadcrumb : 'records' },
//         loadChildren: 'app/main/content/apps/gdpr-resources/gdpr-resources.module#GdprResourcesModule'
//     },
    {    path : 'offices/:officeId/office-configuration',
         data: { breadcrumb: 'Office Configration'},
         loadChildren : 'app/main/content/apps/office-configuration/office-configuration.module#OfficeConfigurationModule'
    },
    {
        path: 'offices/:officeId/calendar',
        data: { breadcrumb: 'Calendar' },
        loadChildren : './main/content/apps/calendar/calendar.module#CalenderModule'
    },
    {
        path: 'shared-service',
        loadChildren : './main/content/apps/shared-service/shared-service.module#SharedServiceModule'
    },
//     {
//         path    : 'error',
//         loadChildren: 'app/main/content/global-component/404/error-404.module#Error404Module'
//     },
    {
        path    : '',
        pathMatch: 'full',
        redirectTo: '/office-dashoboard'

    },
    {
        path      : '**',
        redirectTo: '/office-dashoboard'
        // redirectTo: '/mail-detail/62a1d0b1c158a75708677c8e/1'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRouting {
}
