<div fxLayout="column" style="padding: 12px; border: 1px solid lightgray" id="mailing_list">
  <div fxLayout="row">
    <h2 class="m-0">
      {{ 'ct.mailing_list' | translate }}
    </h2>
    <span fxFlex></span>
    <div class="mb-12">
      <button mat-raised-button (click)="addNew()" id="mailing_list_add">
        {{ 'ct.new' | translate }}
      </button>
    </div>
  </div>
  <table id="mailing_list_list">
    <tr *ngFor="let list of model; let j=index">
      <td>
        <p class="m-0">{{ list.name }}</p>
      </td>
      <td>
        <p class="m-0">{{list.action}}</p>
      </td>
      <!-- <td style="width: 50px;">
        <button mat-icon-button (click)="execute(list, j)">
          <mat-icon>play_circle_outline</mat-icon>
        </button>
      </td> -->
      <td style="width: 50px;">
        <button mat-icon-button (click)="edit(list, j)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </tr>
  </table>
</div>