<div *ngIf="!loader && !isSuccess">

    <h2>
        Login To Platform to confirm migration.
    </h2>
    
<form name="loginForm" [formGroup]="loginForm" novalidate>
    
    <mat-form-field>
        <input matInput placeholder="Email" formControlName="email">
        <mat-error *ngIf="loginFormErrors.email.required">
            {{ 'auth.email_is_required' | translate }}
        </mat-error>
        <mat-error *ngIf="!loginFormErrors.email.required && loginFormErrors.email.email">
            {{ 'auth.please_enter_valid_email_address' | translate }}
        </mat-error>
    </mat-form-field>
    
    <mat-form-field>
        <input matInput type="password" placeholder="{{ 'auth.password' | translate }}" formControlName="password">
        <mat-error *ngIf="loginFormErrors.password.required">
            {{ 'auth.password_is_required' | translate }}
        </mat-error>
    </mat-form-field>
    
    <div aria-label="login_error_msg" class="remember-forgot-password" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" *ngIf="errMess">
        {{ errMess || errMess['message'] }}
    </div>
    
    <button mat-raised-button color="accent" class="submit-button" (click)="login()" aria-label="LOGIN" [disabled]="loginForm.invalid || loader">
        {{ 'auth.login' | translate }}
    </button>
    
</form>
</div>
<div *ngIf="loader && !isSuccess">
    <mat-spinner></mat-spinner>
</div>
<div *ngIf="isSuccess && !loader">
    <h2>New Request Send to email {{ loginForm.get('email').value }} </h2>
    <h3>
        What's Next?
    </h3>
    <ul>
        <li>
            Login into Platform through {{ loginForm.get('email').value }} account
        </li>
        <li>
            Move to invitation list and accept or reject invitation
        </li>
    </ul>
    <button [routerLink]="['/login']" mat-raised-button>
        Move to Login
    </button>
</div>