/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ct-ss-cat-selection.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "./ct-ss-cat-selection.component";
import * as i11 from "../../../services/helpers/helpers.service";
import * as i12 from "@angular/material/dialog";
var styles_CtSsCatSelectionComponent = [i0.styles];
var RenderType_CtSsCatSelectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CtSsCatSelectionComponent, data: {} });
export { RenderType_CtSsCatSelectionComponent as RenderType_CtSsCatSelectionComponent };
function View_CtSsCatSelectionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ", "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
function View_CtSsCatSelectionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "ml-12"], ["fxLayout", "row"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["class", "mr-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Selected Categories: "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CtSsCatSelectionComponent_3)), i1.ɵdid(5, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.model.typesObj[_v.parent.context.$implicit]; _ck(_v, 5, 0, currVal_1); }, null); }
function View_CtSsCatSelectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["style", "box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); margin-bottom: 12px; padding: 0px 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "m-8"], ["fxLayout", "row"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [["class", "m-0 ml-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" Area: ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["fxFlex", ""]], null, null, null, null, null)), i1.ɵdid(6, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [["style", "color: blue; cursor: pointer;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectAreas("type", _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Select category"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CtSsCatSelectionComponent_2)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 2, 0, currVal_0); var currVal_2 = ""; _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.model.typesObj && _co.model.typesObj[_v.context.$implicit]); _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 4, 0, currVal_1); }); }
export function View_CtSsCatSelectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "w-100-p"], ["fxLayout", "column"], ["style", "box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); padding: 12px; margin: 12px 0px;"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["fxLayout", "row"], ["fxLayoutAlign", "center center"], ["style", "width: 100%;margin-bottom: 12px;border-bottom: 3px solid lightgray;"]], null, null, null, null, null)), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(4, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Select the areas "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["fxFlex", ""]], null, null, null, null, null)), i1.ɵdid(8, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectAreas("area", null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Manage"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CtSsCatSelectionComponent_1)), i1.ɵdid(14, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "row"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "center center"; _ck(_v, 4, 0, currVal_2); var currVal_3 = ""; _ck(_v, 8, 0, currVal_3); var currVal_6 = _co.model.areas; _ck(_v, 14, 0, currVal_6); }, function (_ck, _v) { var currVal_4 = (i1.ɵnov(_v, 11).disabled || null); var currVal_5 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_4, currVal_5); }); }
export function View_CtSsCatSelectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ct-ss-cat-selection", [], null, null, null, View_CtSsCatSelectionComponent_0, RenderType_CtSsCatSelectionComponent)), i1.ɵdid(1, 114688, null, 0, i10.CtSsCatSelectionComponent, [i11.HelpersService, i12.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CtSsCatSelectionComponentNgFactory = i1.ɵccf("app-ct-ss-cat-selection", i10.CtSsCatSelectionComponent, View_CtSsCatSelectionComponent_Host_0, { field: "field", model: "model", translateData: "translateData", hideTitle: "hideTitle", builder: "builder", formData: "formData" }, { onFieldValueUpdated: "onFieldValueUpdated", modelChange: "modelChange", valChange: "valChange", onError: "onError" }, []);
export { CtSsCatSelectionComponentNgFactory as CtSsCatSelectionComponentNgFactory };
