<div style="background: #f6f6f6;" class="m-0">
  <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <button class="mr-12" (click)="dialogRef.close(null)" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
    <span class="title dialog-title">
      <p>{{ 'general.hard_filter' | translate }} <button
          (click)="openInstructionPopup('info.ws_settings_hard_filter_info')" mat-icon-button>
          <mat-icon>info</mat-icon>
        </button>
      </p>
    </span>
    <span fxFlex></span>
    <button class="mr-12" (click)="saveData()" mat-icon-button>
      <mat-icon>check</mat-icon>
    </button>
  </div>
</div>
<div>
  <div fxLayoutAlign="center center" fxLayout="row" style="width: 96%; margin: auto;">
    <mat-form-field class="happ-small-form-field-outline w-100-p py-12" floatLabel="never" floatLabel="always" appearance="outline" fxFlex="100">
      <mat-label>{{ 'general.hard_filter' | translate }}</mat-label>
      <textarea matInput rows="10" [(ngModel)]="data.hardFilter"></textarea>
    </mat-form-field>
  </div>
  <div fxLayout="row">
    <span fxFlex></span>
    <div class="px-12">
      <button mat-raised-button color="warn" (click)="checkMqFilter()" >
          {{ 'general.validate_filter' | translate }}
      </button>
    </div>
  </div>
</div>