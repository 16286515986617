import * as tslib_1 from "tslib";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import * as _ from 'lodash';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { ProfileHelperService } from 'app/main/content/services/profile-helper/profile-helper.service';
import { CalenderRecordFilter, CalendarColor } from 'app/main/content/apps/calendar-template/calendar.model';
import { DaysModel } from 'app/main/content/apps/calendar-template/models/days.model';
var AgentProposalSelectionService = /** @class */ (function () {
    function AgentProposalSelectionService(http, officeHelperService, profileHelperService, helperService) {
        this.http = http;
        this.officeHelperService = officeHelperService;
        this.profileHelperService = profileHelperService;
        this.helperService = helperService;
        this.events = [];
        this.customFilter = {};
        this.filtertype = 'myCalendar';
        this.statusFilter = 'ongoing';
        this.defaultBackgroundColor = 'darkblue';
        this.defaultColor = 'white';
        this.calendarViewSettings = {
            header: {
                left: 'prev,next,today',
                center: 'title',
                right: 'agendaWeek, agendaDay, agendaThreeDay'
            },
            views: {
                agendaThreeDay: {
                    type: 'agenda',
                    duration: { days: 3 },
                    buttonText: '3 day'
                },
                defaultView: 'agendaThreeDay'
            },
            buttonText: {
                today: 'Dzisiaj'
            },
            allDayText: 'Cały dzień', eventLimitText: 'więcej', noEventsMessage: 'Brak wydarzeń do wyświetlenia',
            navLinks: true,
            editable: true,
            eventLimit: true,
            events: [],
            minTime: '7:00:00',
            maxTime: '22:00:00',
            contentHeight: 'auto',
            weekends: true,
            defaultView: 'agendaThreeDay',
            firstDay: 0,
            dragScroll: true,
            selectable: true,
            locale: 'pl',
            slotLabelFormat: 'HH:mm'
        };
        this.typeFilter = [];
        this.daysArray = DaysModel;
        this.globalFilters = [];
        this.privateFilters = [];
        console.log("private calendar");
        this.calenderFilter = CalenderRecordFilter;
        this.filterDateRange = {
            start: new Date((new Date().getTime() - (60000 * 60 * 24))),
            end: new Date()
        };
    }
    AgentProposalSelectionService.prototype.resolve = function (route, state) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.resolveAll().then(resolve).catch(reject);
        });
    };
    AgentProposalSelectionService.prototype.resolveAll = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var profileData, officeDoc, calendarId, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 8, , 9]);
                        return [4 /*yield*/, this.profileHelperService.getLocalProfileData()];
                    case 1:
                        profileData = _a.sent();
                        if (!profileData.privateAreaOfficeId) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.officeHelperService.getOfficeData(profileData.privateAreaOfficeId)];
                    case 2:
                        officeDoc = _a.sent();
                        localStorage.setItem("ngStorage-selectedOffice", JSON.stringify(officeDoc));
                        return [3 /*break*/, 4];
                    case 3:
                        this.helperService.showInfo("No private are found", 'error', 'error');
                        _a.label = 4;
                    case 4: return [4 /*yield*/, this.officeHelperService.getLocalOfficeData()];
                    case 5:
                        _a.sent();
                        if (profileData['email']) {
                            this.loggedUser = profileData['email'] || '';
                        }
                        else {
                            console.error('No user email in profile');
                        }
                        console.log("this.logged user - ", this.loggedUser);
                        if (this.filtertype === 'myCalendar') {
                            // this.customFilter = {
                            //   'info_responsible': {
                            //     '$in': [this.loggedUser]
                            //   }
                            // };
                        }
                        else {
                            this.customFilter = {};
                        }
                        return [4 /*yield*/, this.officeHelperService.getOfficeCalendarId()];
                    case 6:
                        calendarId = _a.sent();
                        this.regId = calendarId;
                        this.calenderFilter['regId'] = this.regId;
                        this.getUserColors(JSON.parse(localStorage.getItem('ngStorage-selectedOffice')));
                        return [4 /*yield*/, this.getCalendarSettings()];
                    case 7:
                        _a.sent();
                        return [2 /*return*/, this.regId];
                    case 8:
                        err_1 = _a.sent();
                        console.info(err_1);
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    AgentProposalSelectionService.prototype.getEvents = function (mqHardFilter) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.customFilter = _this.customFilter || {};
            if (_this.filtertype === 'myCalendar') {
                // this.customFilter['info_responsible'] = {
                //   '$in': [this.loggedUser]
                // };
            }
            else if (_this.statusFilter) {
                _this.customFilter['info_status'] = _this.statusFilter;
            }
            _this.customFilter['start'] = {
                $gte: moment(_this.filterDateRange.start).format('YYYY-MM-DD HH:mm:ss')
            };
            _this.customFilter['end'] = {
                $lte: moment(_this.filterDateRange.end).format('YYYY-MM-DD HH:mm:ss')
            };
            if (_this.customFilter['info_responsible'] && _this.customFilter['info_responsible'] &&
                _this.customFilter['info_responsible'].$in && _this.customFilter['info_responsible'].$in.length === 0) {
                delete _this.customFilter['info_responsible'];
            }
            if (mqHardFilter) {
                _this.calenderFilter['mqFilter'] = mqHardFilter;
            }
            else {
                delete _this.calenderFilter['mqFilter'];
            }
            _this.calenderFilter['filters'] = _this.customFilter || {};
            _this.calenderFilter.calKind = "provider";
            _this.helperService.postRequest("api/personal-panel/calendar", _this.calenderFilter)
                .then(function (response) {
                response.data = (response && response.data) || []; // response.data || [];
                _this.events = response.data.map(function (ele) { return _this.makeCalendarEvents(ele); });
                console.log("events +++ ", _this.events);
                resolve(_this.events);
            }).catch(function (err) {
                console.info(err);
                reject(err);
            });
        });
    };
    AgentProposalSelectionService.prototype.makeCalendarEvents = function (ele) {
        try {
            ele['originalRec'] = JSON.parse(JSON.stringify(ele));
            ele['title'] = ele['info_subject'] || ele['subject'];
            ele['all-day'] = (ele['allDay'] === 'true' || ele['allDay'] === true) ? true : false;
            ele['allDay'] = ele['all-day'];
            // ele['all-day'] = true;
            // ele['allDay'] = true;
            ele['backgroundColor'] = ele['backgroundColor'] || (this.usersCalendarColor[ele.info_responsible] &&
                this.usersCalendarColor[ele.info_responsible]['calendarColor'] &&
                this.usersCalendarColor[ele.info_responsible]['calendarColor']['backgroundColor']) || this.defaultBackgroundColor;
            ele['color'] = ele['fontColor'] || (this.usersCalendarColor[ele.info_responsible] &&
                this.usersCalendarColor[ele.info_responsible]['calendarColor'] &&
                this.usersCalendarColor[ele.info_responsible]['calendarColor']['fontColor']) || this.defaultColor;
            if (ele['_id'] && ele['_id'] === this.currentEventId) {
                ele['backgroundColor'] = "red";
                ele['color'] = "white";
            }
            ele['textColor'] = ele['color'];
            ele['id'] = ele['_id'];
            // for repeating events..
            if (ele['repeatingEvent']) {
                ele['start'] = (ele['start'] && moment(ele['start']).format('HH:mm')) || null;
                ele['end'] = (ele['end'] && moment(ele['end']).format('HH:mm')) || null;
                ele['dow'] = [];
                if (ele['repeatingDays']) {
                    for (var i in ele['repeatingDays']) {
                        if (ele['repeatingDays'][i] && this.daysArray.indexOf(i) >= 0) {
                            ele['dow'].push(this.daysArray.indexOf(i));
                        }
                    }
                }
                ele['ranges'] = [{
                        start: (ele['originalRec'] && ele['originalRec']['start'] && moment(ele['originalRec']['start'])) || null,
                        end: (ele['originalRec'] && ele['originalRec']['end'] && moment(ele['originalRec']['end'])) || null
                    }];
            }
            else {
                ele['start'] = (ele['start'] && moment(ele['start']).format()) || null;
                ele['end'] = (ele['end'] && moment(ele['end']).format()) || null;
            }
        }
        catch (err) {
            console.info(err);
        }
        return ele;
    };
    AgentProposalSelectionService.prototype.getCalendarSettings = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.helperService.getRegisterData(_this.regId)
                .then(function (res) {
                if (res) {
                    _this.regData = res || {};
                    _this.filtertype = _this.regData['filterType'] || _this.filtertype;
                    _this.statusFilter = _this.regData['statusFilter'] || _this.statusFilter;
                    var data = res;
                    if (data.calendar_settings) {
                        _this.calendarViewSettings.minTime = data.calendar_settings.minTime + ':00:00';
                        _this.calendarViewSettings.maxTime = data.calendar_settings.maxTime + ':00:00';
                        _this.calendarViewSettings.height = data.calendar_settings.height;
                        _this.calendarViewSettings.weekends = data.calendar_settings.weeeknds;
                        _this.calendarViewSettings.firstDay = data.calendar_settings.startDay === 'sunday' ? 0 : 1;
                        _this.calendarViewSettings.timeFormat = data.calendar_settings.timeFormat === 24 ? 'HH:mm' : 'hh:mm a';
                        _this.slotDuration = data.calendar_settings.slotDuration;
                        // const time = data.calendar_settings.slotDuration;
                    }
                    Promise.all([
                        _this.getTypeFilter(data),
                        _this.getGlobalFilters(data)
                    ])
                        .then(resolve)
                        .catch(reject);
                }
                else {
                    reject('Error occured or reg data is empty');
                }
            }).catch(function (err) {
                console.info(err);
            });
        });
    };
    AgentProposalSelectionService.prototype.getUserColors = function (storedOffice) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            storedOffice['users'] = storedOffice['users'] || [];
            _this.usersCalendarColor = _this.usersCalendarColor || {};
            storedOffice['users'].forEach(function (user) {
                if (user) {
                    _this.usersCalendarColor[user.email] = new CalendarColor();
                    _this.usersCalendarColor[user.email].calendarColor.backgroundColor = user['color'] || _this.usersCalendarColor[user.email].calendarColor.backgroundColor;
                    _this.usersCalendarColor[user.email].calendarColor.fontColor = user['fontColor'] || _this.usersCalendarColor[user.email].calendarColor.fontColor;
                }
            });
            resolve(null);
        });
    };
    AgentProposalSelectionService.prototype.getEvent = function (recId) {
        return this.helperService.getRegisterRecordData(this.regId, recId);
    };
    AgentProposalSelectionService.prototype.updateEvent = function (event) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.regId && event['_id']) {
                var data = JSON.parse(JSON.stringify(event));
                delete data['_id'];
                _this.helperService.updateRegisterRecord(_this.regId, event['_id'], data)
                    .then(function (data2) {
                    resolve(data2[0]);
                }).catch(reject);
            }
            else {
                reject('No regId or event is null');
            }
        });
    };
    AgentProposalSelectionService.prototype.getGlobalFilters = function (regData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            regData = regData || {};
            _this.globalFilters = regData['customFilters'] || [];
            resolve(true);
        });
    };
    AgentProposalSelectionService.prototype.getPrivateFilters = function (user) {
        user = user || {};
        this.privateFilters = (user['calendar'] && user['calendar']['privatefilters']) || [];
    };
    AgentProposalSelectionService.prototype.getDefaultFilter = function () {
        var user = this.storedOfficeData.users[this.loggedUserIndex];
        return (user && user.calendar && user.calendar['defaultFilter']);
    };
    AgentProposalSelectionService.prototype.getTypeFilter = function (regData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            regData = regData || {};
            if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
                if (regData && regData['ng5FormId']) {
                    _this.helperService.getForm(regData['ng5FormId'])
                        .then(function (formData) {
                        if (formData) {
                            var tempFields = _this.getFlatFormField(formData['ng5Form']) || [];
                            tempFields.forEach(function (field) {
                                if (field['label'] === 'info_type') {
                                    _this.typeFilter = field['options'];
                                }
                            });
                            resolve(true);
                        }
                        else {
                            reject('Form is null or empty');
                        }
                    }).catch(function (err) {
                        console.info(err);
                    });
                }
                else {
                    reject('Error in getting the form id');
                }
            }
            else {
                reject('Error getting the Office Id');
            }
        });
    };
    AgentProposalSelectionService.prototype.getFlatFormField = function (obj) {
        var ng5AllFields = [];
        obj = obj || [];
        obj.forEach(function (tabValue) {
            if (tabValue.tabContent && typeof tabValue.tabContent === 'object') {
                tabValue.tabContent.forEach(function (value) {
                    if (value.field || (value.data !== null && typeof value.data === 'string')) {
                        ng5AllFields.push(value);
                    }
                    if (value.children && typeof value.children === 'object') {
                        value.children.forEach(function (childValue) {
                            if (childValue.field || (childValue.data !== null && typeof childValue.data === 'string')) {
                                ng5AllFields.push(childValue);
                            }
                            if (childValue && typeof childValue === 'object') {
                                childValue.forEach(function (nestedChildValue) {
                                    if (nestedChildValue.field || (nestedChildValue.data !== null && typeof nestedChildValue.data === 'string')) {
                                        ng5AllFields.push(nestedChildValue);
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
        return ng5AllFields;
    };
    AgentProposalSelectionService.prototype.updateGlobalFilter = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.regId) {
                _this.regData['customFilters'] = _this.globalFilters || [];
                delete _this.regData['_id'];
                _this.helperService.updateRegisterData(_this.regId, _this.regData);
            }
            else {
                reject('RegId not found');
            }
        });
    };
    AgentProposalSelectionService.prototype.getLoggedUserInfo = function () {
        if (this.storedOfficeData) {
            this.storedOfficeData['users'] = this.storedOfficeData['users'] || [];
            this.loggedUserIndex = _.findIndex(this.storedOfficeData['users'], { email: this.loggedUser });
        }
    };
    AgentProposalSelectionService.prototype.onFilterChange = function (filter) {
        if (filter) {
            this.customFilter = {};
            if (filter['responsibleUsers']) {
                this.customFilter['info_responsible'] = {};
                // this.customFilter['info_responsible']['$in'] = filter['responsibleUsers'];
            }
            if (filter['selectedStatus']) {
                this.customFilter['info_status'] = filter['selectedStatus'];
            }
            if (filter['type']) {
                this.customFilter['info_type'] = filter['type'];
            }
            this.filtertype = filter['name'];
        }
    };
    AgentProposalSelectionService.prototype.updatePrivateFilter = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))) {
                var storedOffice = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'));
                storedOffice['users'] = storedOffice['users'] || [];
                var userIndex = _.findIndex(storedOffice['users'], { email: _this.loggedUser });
                var calenderColor = new CalendarColor();
                if (userIndex >= 0) {
                    storedOffice['users'][userIndex] = storedOffice['users'][userIndex] || {};
                    storedOffice['users'][userIndex]['calendar'] = storedOffice['users'][userIndex]['calendar'] || {};
                    storedOffice['users'][userIndex]['calendar']['privatefilters'] = _this.privateFilters || [];
                    localStorage.setItem('ngStorage-selectedOffice', JSON.stringify(storedOffice));
                    var officeId = storedOffice['_id'];
                    delete storedOffice['_id'];
                    _this.helperService.patchRequest("api/offices/" + officeId, storedOffice);
                }
                resolve(calenderColor.calendarColor);
            }
            else {
                reject('No office found');
            }
        });
    };
    AgentProposalSelectionService.prototype.setUserDefaultFilter = function (filter) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.storedOfficeData) {
                var calenderColor = new CalendarColor();
                if (_this.loggedUserIndex >= 0) {
                    _this.storedOfficeData['users'][_this.loggedUserIndex] = _this.storedOfficeData['users'][_this.loggedUserIndex] || {};
                    _this.storedOfficeData['users'][_this.loggedUserIndex]['calendar'] = _this.storedOfficeData['users'][_this.loggedUserIndex]['calendar'] || {};
                    _this.storedOfficeData['users'][_this.loggedUserIndex]['calendar']['defaultFilter'] = filter || {};
                    localStorage.setItem('ngStorage-selectedOffice', JSON.stringify(_this.storedOfficeData));
                    var officeId = _this.storedOfficeData['_id'];
                    _this.helperService.patchRequest("api/offices/" + officeId, { users: _this.storedOfficeData.users })
                        .then(resolve).catch(reject);
                }
                resolve(calenderColor.calendarColor);
            }
            else {
                reject('No office found');
            }
        });
    };
    return AgentProposalSelectionService;
}());
export { AgentProposalSelectionService };
