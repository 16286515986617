import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvitationComponent } from './invitation.component';
import { MatCardModule, MatProgressSpinnerModule, MatFormFieldModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SetPasswordComponent } from './set-password/set-password.component';
import { MigrateComponent } from './migrate/migrate.component';
import { MaterialModule } from '@fuse/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, FormControlDirective, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [InvitationComponent, SetPasswordComponent, MigrateComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    MaterialModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ],
  exports: [
    InvitationComponent
  ]
})
export class InvitationModule { }
