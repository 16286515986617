import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { HelpersService } from '../../services/helpers/helpers.service';

@Component({
  selector: 'app-process-button-comment-popup',
  templateUrl: './process-button-comment-popup.component.html',
  styleUrls: ['./process-button-comment-popup.component.scss']
})
export class ProcessButtonCommentPopupComponent implements OnInit {
  currentLang: string;

  constructor(
    public dialogRef: MatDialogRef<ProcessButtonCommentPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private helperService: HelpersService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.currentLang = this.translateService.getDefaultLang();
    this.translateService.onLangChange.subscribe((data2: any) => {
      this.currentLang = data2;
    });
  }

  saveData() {
    this.dialogRef.close(this.data.comment);
  }

  openInstructionPopup(infoKey: string) {
    this.helperService.openInstructionPopup(infoKey, 'translation');
  }
}
