import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { HelpersService } from '../../services/helpers/helpers.service';
import { OfficeQuery } from '../../state/officeState/office.query';

const ALL_SIDE_HIDE_LAYOUT = {
  layout: {
      navbar: {
          hidden: true
      },
      toolbar: {
          hidden: true
      },
      footer: {
          hidden: true
      },
      sidepanel: {
          hidden: true
      }
  }
};

@Component({
  selector: 'app-pending-payment',
  templateUrl: './pending-payment.component.html',
  styleUrls: ['./pending-payment.component.scss']
})
export class PendingPaymentComponent implements OnInit {

  timerInstance;
  timer = 0;
  dayDiff;
  configData: any;
  selectedConf: any;
  tempCurr: any;
  data: any;

  constructor(
    // public dialogRef: MatDialogRef<PendingPaymentComponent>,
    // @Inject(MAT_DIALOG_DATA) public data,
    private http: HttpClient,
    private _fuseConfigService: FuseConfigService,
    private officeQuery: OfficeQuery,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.startTimmer();
    this.getConfig();
    this.tempCurr = this._fuseConfigService.configValue;
    this._fuseConfigService.config = ALL_SIDE_HIDE_LAYOUT;
    setTimeout(() => {
      this._fuseConfigService.config = ALL_SIDE_HIDE_LAYOUT;
    }, 400);
    this.officeQuery.officeDoc.subscribe((officeData) => {
      if (officeData) {
        this.dayDiff = moment().diff(officeData.lastPaymentDate, "day");
      }
    });
  }

  startTimmer() {
    this.timerInstance = setInterval(() => {
      if (this.timer === 0) {
        clearInterval(this.timerInstance);
        // this.close();
      } else {
        this.timer = this.timer - 1;
      }
    }, 1000);
  }

  close() {
    // this.dialogRef.close();
    if (this.route.snapshot.queryParams.redirectUrl) {
      this.router.navigateByUrl(this.route.snapshot.queryParams.redirectUrl);
    } else {
      this.router.navigateByUrl("/dashboard");
    }
    this._fuseConfigService.config = this.tempCurr;
    setTimeout(() => {
      window.location.reload();
    }, 500);

  }

  addCredit() {
    this.router.navigateByUrl("/office/payments/dashboard")
    this.close();
  }

  
  getRequest(getUrl: string, extraHeader = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
      const token = JSON.parse(localStorage.getItem('ngStorage-token'))['token'];

        this.http.get(`${environment.serverUrl}${getUrl}`, { headers: new HttpHeaders({'Authorization': token}) })
            .subscribe((data: Response) => {
              this.buildBackendResponse(data, resolve, reject);
            }, err => this.buildHttpError(err, reject));
      } catch (err) {
        reject(err);
      }
    });
  }

    /**
   * 
   * @param err 
   * @param reject 
   * build the error message from the http error
   */
     buildHttpError(err, reject): void {
      // if (err && err['status'] && err['message']) {
      //   reject(err['status'] + '-' + err['message']);
      // } else {
      //   reject('Error Occured while fetching the data');
      // }
      reject((err && err.error && err.error.message) || err.message || 'Error Occured while fetching the data');
    }

  buildBackendResponse(data, resolve, reject): void {
    if (data && data['success']) {
      if (data['result']) {
        resolve(data['result']);
      } else {
        reject('Response is empty. Please check the configuration');
      }
    } else {
      reject((data && data['error'] && data['error']['txt']) || 'Some error occured');
    }
  }

  async getConfig() {
    try {
      const data = await this.getRequest("api/global-var/pending-usage-config");
      this.configData = data.data || [];
      this.selectedConf = this.configData.filter((ele) => {
        console.log("ele - ", ele, this.dayDiff);
        return (ele && ele.min < this.dayDiff && ele.max > this.dayDiff);
      });
      this.selectedConf = this.selectedConf[0] || {};
      this.timer = (this.selectedConf && this.selectedConf.time) || 5
      this.startTimmer();
      // if (this.dayDiff)
    } catch (error) {
      // this.helperSerice.showInfo(error, "Error", "error");
      alert(error);
    }
  }
}
