import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { EditFormPopupComponent } from 'app/main/content/apps/custom-registries/edit-form-popup/edit-form-popup.component';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';

@Component({
  selector: 'app-shared-service-position',
  templateUrl: './shared-service-position.component.html',
  styleUrls: ['./shared-service-position.component.scss']
})
export class SharedServicePositionComponent implements OnInit {
  @Input() recId: any;


  query;
  columns = [
    [
      {
        title: 'ct.signatura',
        key: 'signatura'
      },
      {
        title: 'ct.type',
        key: 'type'
      }
    ],
    [
      {
        title: 'ct.serviceName',
        key: 'serviceName'
      }
    ],
    [
      {
        title: 'ct.serviceStatus',
        key: 'processStatusLabel'
      }
    ],
    [
      {
        title: 'ct.caseStatus',
        key: 'caseStatus'
      }
    ],
    [
      {
        title: 'ct.caseManager',
        key: 'caseManager'
      },
      {
        title: 'ct.responsiblePerson',
        key: 'responsiblePerson'
      },
    ],
    [
      {
        title: 'ct.caseRegisteration',
        key: 'registredDataTime'
      },
      {
        title: 'ct.lastModification',
        key: 'modificatorDateTime'
      },
    ],
    {
      key: "action",
      title: 'Action',
      width: "10px",
      textAlign: "center"
    }
  ];

  data = [];
  loading: boolean;

  constructor(
    private _helperService: HelpersService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getData();
  }

  async getData() {
    try {
      this.loading = true;
      this.data = await this._helperService.getRequest(`api/registries/shared-service-position/${this.recId}/list`);
      this.loading = false;
    } catch (error) {
      this.loading = false;
      console.error(error);
    }
  }

  editForm(item: any) {
    const dialogRef = this.dialog.open(EditFormPopupComponent, {
        width: '100%',
        panelClass: 'happ-form-builder-dialog',
        data: { 
            recId: item._id, 
            regId: item.regId, 
            dialog: this.dialog, 
            defaultValue: {},
            action: 'edit',
            title: item.processStatusLabel
        }
    });


    dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.getData();
        }
    }, err => {
        console.info(err);
    });
  }

}
