<div>
    <div *ngFor="let tab of gvRequiredFields; let i=index">
      <div fxLayout="row" class="happ-ws-settings-heading">
        <h3 class="m-0">
          {{ tab.key }}
        </h3>
        <span fxFlex></span>
        <button *ngIf="i==0" (click)="updateData()" class="happ-hover-button" >
          Save
        </button>
      </div>
      <button mat-stroked-button>Basic</button>

      <div class="happ-field-right-align"  style="width: 96%; margin: auto;" *ngFor="let field of tab.fields">
        <p> {{field.title}} </p>
        <input *ngIf="allRecordData[tab.key]" [(ngModel)]="allRecordData[tab.key][field.label]" placeholder="Enter here..." type="text">
      </div>
    </div>
</div>