<ng-container *ngIf="(!recId || (recId && recId === 'new')); else recIdExist">
    <div fxLayoutAlign="center center" class="w-100-p h-200" >
        <h2>
            {{ 'ct.please_create_record_then_you_will_be_able_to_upload_the_record' | translate }}
        </h2>
    </div>
</ng-container>
<ng-template #recIdExist>

    <div *ngIf="!field.hide || builder">

        <div *ngIf="!field.readOnly" fxLayout="row" class="mb-12" fxLayoutAlign="space-between center ">
            <div class="file-upload">
                <label for="upload" class="file-upload__label">{{ (field.title || field.label) | translate }}</label>
                <input id="upload" (change)="fileChanged(file)" class="file-upload__input" name="uplFile" #file multiple type="file">
            </div>
            <button [disabled]="loading" *ngIf="selectedFiles && selectedFiles.length > 0" mat-raised-button color="warn" (click)="uploadFile(file)">
                    {{ 'ct.upload' | translate }}
            </button>
        </div>
        <br>
    
        <div *ngIf="!loading">
            <div style="font-size: 22px;" class="m-8 mb-24" *ngFor="let file of selectedFiles">
                {{ file }}
            </div>
        </div>
        <div *ngIf="loading">
            <mat-spinner></mat-spinner>
        </div>
    
        <div class="happ-table" [style.display]="(fileList && fileList.length > 0)? 'block': 'none'">
            <table fxFlex style="width: 100%" class="row-border hover custoum-datatbale display">
    
                <thead>
                    <tr>
                        <th style="width: 40px;">{{ 'ct.delete' | translate }}</th>
                        <th>{{ 'ct.creation_time' | translate }}</th>
                        <th>{{ 'ct.uploaded_by' | translate }}</th>
    
                        <th>{{ 'ct.original_name' | translate }}</th>
                        <th *ngIf="!field.readOnly"> <span fxFlex></span> {{ 'ct.action' | translate }} </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let file of fileList">
                        <td >
                            <button mat-icon-button color="warn" [matTooltip]="'ct.delete' | translate" *ngIf="!field.readOnly"  class="mr-4" (click)="deleteFile(file._id)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                        <td>{{ file.createdAt | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
                        <td>{{ (file.creatorLogin)? file.creatorLogin: '-' }}</td>
    
                        <td>{{ file.originalname || file.originalName }}</td>
    
                        <td *ngIf="!field.readOnly">
                            <span fxFlex></span>
                            <button mat-raised-button   *ngIf="!field.readOnly && field.showSaveAsMainDoc" [color]="(recordMainDocument===file._id)? 'warn': ''" (click)="saveAsMainDoc(file._id)">
                                {{(recordMainDocument === file._id)? ('ct.remove' | translate) : ('ct.save' | translate)}} {{ 'ct.default_doc' | translate }}
                            </button>
                            <button mat-icon-button [matTooltip]="'ct.download' | translate" *ngIf="!field.readOnly"  [disabled]="downloadLoading" class="mr-4" (click)="openPreview(file._id)">
                                <mat-icon>cloud_download</mat-icon>
                            </button>
                            <button mat-icon-button [matTooltip]="'ct.preview' | translate" *ngIf="!field.readOnly"  [disabled]="downloadLoading" class="mr-4" (click)="openPreview(file._id)">
                                <mat-icon>remove_red_eye</mat-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
    
            <div *ngIf="docBase">
                <iframe [src]="docBase" style="display: none;" frameborder="0"></iframe>
            </div>
    
        </div>
    </div>
    
</ng-template>
