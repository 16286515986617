import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
  selector: 'app-group-selection',
  templateUrl: './group-selection.component.html',
  styleUrls: ['./group-selection.component.scss']
})
export class GroupSelectionComponent implements OnInit, OnChanges {
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() translateData: any;
  @Input() model: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onError: EventEmitter<Object> = new EventEmitter();
    
  officeGroups = [];
  error: boolean;
  errorMessage: string;
  constructor(
    private translationLoader: FuseTranslationLoaderService,  

  ) { }

  onGroupChanged() {
    this.modelChange.next(this.model);

    if (this.field['required'] && (!this.model || this.model && this.model.length === 0)) {
      this.error = true;
      this.errorMessage = 'ct.field_is_required';
      if (!this.model) {
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
      } else {
        this.errorMessage = '';
        this.error = false;
        this.onError.emit({
          error: false,
          label: this.field['label']
        });
      }
    }
  }

  
  ngOnChanges(changes: any) {

    if (changes && changes.translateData && changes.translateData.currentValue) {
      this.translateData = changes.translateData.currentValue;
      this.translationLoader.loadTranslationsV2(this.translateData);    
    }
  }

  ngOnInit() {
    this.translationLoader.loadTranslationsV2(this.translateData);
    this.field = this.field || {};
    this.model = this.model || [];
    this.onGroupChanged();
    if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['groups']) {
      this.officeGroups = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['groups'] || [];
    } else {
      console.warn('no office user');
    }
  }

  onChange(group, i) {
    this.model = this.model || [];
    if (this.model.includes(group)) {
      this.model.splice(i, 1);
    } else {
      this.model.push(group);
    }
    this.onGroupChanged();
    this.onFieldValueUpdated.emit({
        label: this.field.label,
        value: this.model,
        ruleIndex: this.field.ruleIndex,
        field: this.field
      });
  }
 
}
