import * as objectPath from 'object-path';
var DatatableUISettings = /** @class */ (function () {
    function DatatableUISettings(url, appData, moreInfo) {
        if (appData === void 0) { appData = {}; }
        if (moreInfo === void 0) { moreInfo = {}; }
        this.general = [
            {
                'type': 'field',
                'settings': {
                    updateApi: '',
                    fields: [
                        {
                            fieldType: 'checkbox',
                            title: 'workspace.hide_header',
                            description: 'workspace.hide_header_desc',
                            initValue: '',
                            defaultValue: false,
                            keyName: 'hideDataTableHeader',
                            accessLevel: 'user'
                        },
                        {
                            fieldType: 'color_selection',
                            getKeyName: 'class',
                            title: 'workspace.data_table_background_color',
                            description: 'workspace.data_table_background_color_desc',
                            initValue: '',
                            defaultValue: '',
                            keyName: 'headerBackgroundColor',
                            accessLevel: 'user'
                        },
                        {
                            fieldType: 'input',
                            type: 'number',
                            title: 'workspace.data_table_header_border_bottom_size',
                            description: 'workspace.data_table_header_border_bottom_size_desc',
                            initValue: '',
                            defaultValue: 0,
                            keyName: 'datatableHeaderBottomBorderSize',
                            accessLevel: 'user'
                        },
                        {
                            fieldType: 'color_selection',
                            getKeyName: 'bg',
                            title: 'workspace.border_bottom_color',
                            description: 'workspace.border_bottom_color_desc',
                            initValue: '',
                            defaultValue: '',
                            keyName: 'datatableHeaderBottomBorderColor',
                            accessLevel: 'user'
                        },
                    ]
                }
            }
        ];
        this.settingJson = [
            {
                'type': 'section',
                'settings': [
                    {
                        'title': 'workspace.general',
                        'children': this.general.slice()
                    }
                ]
            }
        ];
        this.settingJson.forEach(function (setting) {
            setting.settings.forEach(function (sectionSetting) {
                sectionSetting.children.forEach(function (fieldSetting) {
                    fieldSetting.settings = fieldSetting.settings || {};
                    fieldSetting.settings.updateApi = url;
                    fieldSetting.settings['triggerSub'] = moreInfo.triggerSub;
                    fieldSetting.settings.fields = fieldSetting.settings.fields || [];
                    fieldSetting.settings.fields.forEach(function (field) {
                        field['initValue'] = (objectPath.get(appData, field.keyName) || objectPath.get(appData, field.keyName) === false) ?
                            objectPath.get(appData, field.keyName) : field['defaultValue'];
                        field['dialogRef'] = moreInfo.dialogRef;
                        if (field.fieldType === 'select') {
                            field.options = field.options || [];
                            field.options = field.options.concat(moreInfo[field.keyName] || []);
                        }
                        if (field.fieldType === 'popup') {
                            moreInfo[field.keyName] = moreInfo[field.keyName] || {};
                            field.extraData = field.extraData || {};
                            field.extraData = Object.assign(moreInfo[field.keyName], field.extraData);
                        }
                    });
                });
            });
        });
    }
    Object.defineProperty(DatatableUISettings.prototype, "settingsModel", {
        get: function () {
            return this.settingJson;
        },
        enumerable: true,
        configurable: true
    });
    return DatatableUISettings;
}());
export { DatatableUISettings };
