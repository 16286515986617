import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { RecordCommentModule } from 'app/main/content/global-component/control-template/record-comment/record-comment.module';
import { AgentProposalBuilderComponent } from './agent-proposal-builder/agent-proposal-builder.component';
import { AgentProposalSelectionPopupComponent } from './agent-proposal-selection-popup/agent-proposal-selection-popup.component';
import { ProcessStatusModule } from 'app/main/content/global-component/control-template/process-status/process-status.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AgentProposalSelectionService } from './agent-proposal-selection-popup/agent-proposal-selection-popup.service';
// import { ProviderServiceSelectionComponent } from './provider-service-selection.component';

@NgModule({
  declarations: [
    AgentProposalBuilderComponent,
    AgentProposalSelectionPopupComponent
    ],
  imports: [
    CommonModule,
    TranslateModule,
    FuseSharedModule,
    RecordCommentModule,
    ProcessStatusModule
  ],
  entryComponents: [
    AgentProposalSelectionPopupComponent
  ],
  exports: [
    AgentProposalBuilderComponent,
    AgentProposalSelectionPopupComponent
  ],
  providers: [
    AgentProposalSelectionService
  ]
})
export class AgentProposalBuilderModule { }
