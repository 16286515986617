import { Component, OnInit, Input } from '@angular/core';
import { HelpersService } from '../../services/helpers/helpers.service';
import { OfficeHelpersService } from '../../services/office-helpers/office-helpers.service';
import { IOfficeData } from '../../interfaces/office.interface';

@Component({
  selector: 'app-office-global-var',
  templateUrl: './office-global-var.component.html',
  styleUrls: ['./office-global-var.component.scss']
})
export class OfficeGlobalVarComponent implements OnInit {

  @Input() gvRequiredFields;
  @Input() dialogRef;

  allKeys = [
    'ADO',
    'IDO',
    'companyInvoiceData',
    'correspondenceSettings',
    'companyLogos'
  ];
  officeApps;
  gvRegisterId;
  gvRegisterText = 'gdpr-resources';
  allRecordData = {};

  constructor(
    private helperService: HelpersService,
    private officeHelperService: OfficeHelpersService
  ) { }

  ngOnInit() {
    this.gvRequiredFields = this.gvRequiredFields || [];
    this.getRegisterRecord();

  }

  initAllKeyRecord() {
    this.allKeys.forEach((key: string) => {
      this.allRecordData[key] = {};
    });
  }

  getRegisterId() {
    this.officeHelperService.getLocalOfficeData()
    .then((officeData: IOfficeData) => {
      this.officeApps = this.officeHelperService.getOfficeApps() || [];
      this.gvRegisterId = (this.officeApps[this.gvRegisterText] && this.officeApps[this.gvRegisterText]['id']) || '';
      this.getRegisterRecord();
    }).catch(err => {
      console.info(err);
    });
  }

  getRegisterRecord() {
    this.helperService.getRequest(`api/offices/gdpr-settings/group`)
    .then((data) => {
      this.allRecordData = data || {};
    })
    .catch(err => {
      console.info(err);
    });
  }

  updateData() {
    this.helperService.putRequest(`api/offices/gdpr-settings/group`, this.allRecordData)
    .then((data) => {
      // this.allRecordData = data || {};
      this.helperService.openSnackBar('Global Variable updated');
      this.dialogRef.close(true);
    })
    .catch(err => {
      console.info(err);
    });
  }
}
