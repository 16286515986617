import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { RecordCommentModule } from 'app/main/content/global-component/control-template/record-comment/record-comment.module';
import { ClientProposalSelectionComponent } from './client-proposal-selection/client-proposal-selection.component';
import { ProcessStatusModule } from 'app/main/content/global-component/control-template/process-status/process-status.module';

@NgModule({
  declarations: [
    ClientProposalSelectionComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FuseSharedModule,
    RecordCommentModule,
    ProcessStatusModule
  ],
  entryComponents: [
  ],
  exports: [
    ClientProposalSelectionComponent
  ]
})
export class ClientProposalBuilderModule { }
