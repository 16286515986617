import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { FormComponentModule } from 'app/main/content/global-component/form-component/form-component.module';
import { FuseCalendarEventFormDialogComponent } from './event-form.component';
import { FormComponentService } from '../../../global-component/form-component/form-component.service';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
  imports: [
    CommonModule,
   FuseSharedModule,
    FormComponentModule
  ],
  declarations: [
    FuseCalendarEventFormDialogComponent,
  ],
  providers: [
    FuseCalendarEventFormDialogComponent,
    FormComponentService
    ],
    entryComponents: [
      FuseCalendarEventFormDialogComponent,
    ],
    exports: [
      FuseCalendarEventFormDialogComponent,
    ]
})
export class EventFormModule { }
