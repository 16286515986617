import { GeneralSettings } from './general-settings.const';
import { AdvanceSettings } from './advance-settings.const';
import { DatatableUISettings } from './data-table-ui.const';
import { SuperAdminSettings } from './super-admin-settings.const';
var WsAppsSettings = /** @class */ (function () {
    function WsAppsSettings(url, appData, moreInfo) {
        if (moreInfo === void 0) { moreInfo = {}; }
        this.settingJson = [
            {
                'type': 'tab',
                'settings': []
            }
        ];
        this.settingJson[0].settings = [
            {
                'title': 'workspace.general',
                'children': new GeneralSettings(url, appData, moreInfo).settingsModel
            },
            {
                'title': 'workspace.data_table_ui',
                'children': new DatatableUISettings(url, appData, moreInfo).settingsModel
            },
            {
                'title': 'workspace.advance',
                'children': new AdvanceSettings(url, appData, moreInfo).settingsModel
            },
            {
                'title': 'workspace.superAdmin',
                'children': new SuperAdminSettings(url, appData, moreInfo).settingsModel
            }
        ];
    }
    Object.defineProperty(WsAppsSettings.prototype, "settingModel", {
        get: function () {
            return this.settingJson;
        },
        enumerable: true,
        configurable: true
    });
    return WsAppsSettings;
}());
export { WsAppsSettings };
