import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { HelpersService } from '../../services/helpers/helpers.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
var MailingDetailExternalComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    function MailingDetailExternalComponent(_fuseConfigService, http, helperService, route) {
        this._fuseConfigService = _fuseConfigService;
        this.http = http;
        this.helperService = helperService;
        this.route = route;
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }
    MailingDetailExternalComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MailingDetailExternalComponent.prototype.getData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    console.log("temp = ");
                    // this.data = await this.helperService.getRequest(`api/mailing-list/info/${this.route.snapshot.params.recId}/${this.route.snapshot.params.index}`, {}, true)
                    // console.log("this.data", this.data);
                    this.http.get(environment.serverUrl + "api/mailing-list/info/" + this.route.snapshot.params.recId + "/" + this.route.snapshot.params.index + "?recId=" + this.route.snapshot.queryParams.recId)
                        .subscribe(function (data) {
                        _this.data = data.result;
                        _this.data.email = _this.route.snapshot.params.email;
                        console.log("data", data);
                    }, function (err) { return alert(err); });
                }
                catch (error) {
                    alert(error);
                }
                return [2 /*return*/];
            });
        });
    };
    MailingDetailExternalComponent.prototype.accept = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.loading = true;
                        return [4 /*yield*/, this.helperService.getRequest("api/mailing-list/action/" + this.route.snapshot.queryParams.regId + "/" + this.route.snapshot.params.recId + "/accept?recId=" + this.route.snapshot.queryParams.recId, {}, true)
                            // this.loading = false;
                        ];
                    case 1:
                        _a.sent();
                        // this.loading = false;
                        this.success = "accept";
                        if (this.data.action === 'sendMailWithRecordWithSharedService') {
                            setTimeout(function () {
                                window.location.href = "https://5cb9648308e32961acf1ff1f.pages.pinbox24.com/our-services/" + _this.data.sharedServiceId + "?trackingId=" + _this.route.snapshot.queryParams.recId;
                                // window.location.href = `https://5cb9648308e32961acf1ff1f.pages.pinbox24.com/our-services/${this.data.sharedServiceId}?trackingId=${this.route.snapshot.queryParams.recId}`
                            }, 2000);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        // this.loading = false;
                        alert(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MailingDetailExternalComponent.prototype.reject = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.loading = true;
                        return [4 /*yield*/, this.helperService.getRequest("api/mailing-list/action/" + this.route.snapshot.queryParams.regId + "/" + this.route.snapshot.params.recId + "/reject?recId=" + this.route.snapshot.queryParams.recId, {}, true)];
                    case 1:
                        _a.sent();
                        this.success = "reject";
                        this.loading = false;
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        this.loading = false;
                        alert(error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MailingDetailExternalComponent.prototype.unSubscribeAll = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.loading = true;
                        return [4 /*yield*/, this.helperService.getRequest("api/mailing-list/action/" + this.route.snapshot.queryParams.regId + "/" + this.route.snapshot.params.recId + "/unSubscribeAll?recId=" + this.route.snapshot.queryParams.recId, {}, true)];
                    case 1:
                        _a.sent();
                        this.success = "unSubscribeAll";
                        this.loading = false;
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        this.loading = false;
                        alert(error_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MailingDetailExternalComponent.prototype.unSubscribeThis = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.loading = true;
                        return [4 /*yield*/, this.helperService.getRequest("api/mailing-list/action/" + this.route.snapshot.queryParams.regId + "/" + this.route.snapshot.params.recId + "/unSubscribeThis?recId=" + this.route.snapshot.queryParams.recId, {}, true)];
                    case 1:
                        _a.sent();
                        this.success = "unSubscribeThis";
                        this.loading = false;
                        return [3 /*break*/, 3];
                    case 2:
                        error_4 = _a.sent();
                        this.loading = false;
                        alert(error_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return MailingDetailExternalComponent;
}());
export { MailingDetailExternalComponent };
