import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-external-process-view',
  templateUrl: './external-process-view.component.html',
  styleUrls: ['./external-process-view.component.scss']
})
export class ExternalProcessViewComponent implements OnInit {
  buttons: any = [];
  loading: boolean;
  success: boolean;
  source: any;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private http: HttpClient,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) {
    this._fuseConfigService.config = {
      layout: {
          navbar   : {
              hidden: true
          },
          toolbar  : {
              hidden: true
          },
          footer   : {
              hidden: true
          },
          sidepanel: {
              hidden: true
          }
      }
  };
   }

  ngOnInit() {
    this.getProcessButtons();
  }

  getProcessButtons() {
    this.http.get(`${environment.serverUrl}api/external-process/buttons/${this.route.snapshot.params.token}`)
    .subscribe((data: any) => {
      if (data) {
        console.log("data ", data);
        this.buttons = data.result;
        this.source = this.buttons[0].source || {};
      } else {
        this.snackBar.open('Some Error occurred', 'Okay', {
          duration: 1500,
        });
      }
    }, err => {
      this.snackBar.open(err || 'Some Error occurred', 'Okay', {
        duration: 1500,
      });
    });

  }

  clickProcessButtons(item: any) {
    console.log("item = ", item);
    this.loading = true;
    this.http.get(`${environment.serverUrl}api/external-process/click-button/${this.route.snapshot.params.token}/${item.dest.id}`)
    .subscribe(data => {
      this.loading = false;
      this.success = true;
      if (data) {
        console.log("data ", data);
      } else {
        this.snackBar.open('Some Error occurred', 'Okay', {
          duration: 1500,
        });
      }
    }, err => {
      this.loading = false;
      this.snackBar.open(err || 'Some Error occurred', 'Okay', {
        duration: 1500,
      });
    });

  }

}
