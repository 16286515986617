import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';

@Component({
  selector: 'app-add-card-action',
  templateUrl: './add-card-action.component.html',
  styleUrls: ['./add-card-action.component.scss']
})
export class AddCardActionComponent implements OnInit {

  cardAction = {
    name: '',
    translations: [],
    isActive: true,
    showSearch: false,
    showAction: true,
    actionType: 'input',
    keyName: '',
    sort: 50,
    searchType: 'input', // input, dropdown
    useForSort: false,
    flexWidth: 100,
    options: []
  };

  currentValue = {
    name: '',
    value: '',
    sort: 50
  };

  constructor(
    public dialogRef: MatDialogRef<AddCardActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _helperService: HelpersService
  ) { }

  ngOnInit() {
    this.cardAction = (this.data && this.data.data && this.data.data.actionData) || this.cardAction;
  }

  deleteOption(i: number) {
    if (this.cardAction.options[i]) {
      this.cardAction.options.splice(i, 1);
    }
  }
  saveOption() {
    if (this.cardAction && !this.cardAction.keyName) {
      this._helperService.showInfo('First Enter the Key Name', 'Error', 'error');
      return;
    }
    this.cardAction.options = this.cardAction.options || [];
    this.cardAction.options.push(this.currentValue);
    this.currentValue = {
      name: '',
      value: '',
      sort: 50
    };
    this._helperService.showInfo('Option added', 'Success', 'success');

  }

  save() {
    this.dialogRef.close({ updated: true, actionData: this.cardAction});
  }
  
  close() {
    this.dialogRef.close({ updated: false});
  }

}
