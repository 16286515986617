import { HelpersService } from './../../services/helpers/helpers.service';
import { Component, OnInit, Input } from '@angular/core';
import * as Viz from 'viz.js/viz';
import { ProcessShapes } from '../../consts/processShapes.const';
import { Params } from '@angular/router';
import * as _ from 'lodash';
import { IActivity, IRole, IShape, IProcess, ITransition } from '../../interfaces/process.interface';
import * as moment from 'moment';
declare var $: any;
import * as svgPanZoom from 'svg-pan-zoom';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';


@Component({
  selector: 'app-process-graph-view',
  templateUrl: './process-graph-view.component.html',
  styleUrls: ['./process-graph-view.component.scss']
})
export class ProcessGraphViewComponent implements OnInit {

  @Input() processId: string;
  @Input() activeStatusId: any;
  @Input() showActivatedStatus: any;
  @Input() translateData: any;
  @Input() showDownloadButton: any;

  svgPanToZoomOptions: SvgPanZoom.Options = {
    viewportSelector: '#process-graph-down>svg', 
    panEnabled: true, 
    controlIconsEnabled: false, 
    zoomEnabled: true, 
    dblClickZoomEnabled: true, 
    mouseWheelZoomEnabled: true, 
    preventMouseEventsDefault: true, 
    zoomScaleSensitivity: 0.2, 
    minZoom: 0.5, 
    maxZoom: 10
  };
  
  processView;
  params: Params;
  processData;
  loading = false;
  prevBuilded = {};
  TASK_TYPE = 'trigger';
  TASK_KIND = 'automatic';
  TASK_KIND2 = 'interactive';

  focussedActivityStyles = {
    fillcolor: 'red',
    fontcolor: 'white'
  };

  orgActivities = [
    {
      label: 'department',
      title: 'Department'
    },
    {
      label: 'depGroup',
      title: 'Department Group'
    },
    {
      label: 'person',
      title: 'Person'
    }];


  shapes;

  constructor(
    private _helperService: HelpersService,
    private translationLoader: FuseTranslationLoaderService,
  ) { }

  ngOnInit() {
    this.translationLoader.loadTranslationsV2(this.translateData);

    this.shapes = ProcessShapes;
    this.getProcessData();
  }

  getProcessData() {
    if (this.processId) {
      this.loading = true;
      this._helperService.getProcess(this.processId)
      .then((processData) => {
        this.loading = false;
        this.makeIt((processData));
        this.parseProcessWithoutTasks((processData));
      }).catch(err => {
        this.loading = false;
        console.info(err);
      });
    }
  }

  makeIt(data) {
    let vizGraph = 'digraph G{ ';
    data['activities'].forEach((value, key) => {

      if (value.type !== 'subgraph' && value.type !== 'standalone') {
        vizGraph = `${vizGraph}${value.id}[label="`;

        const orgTypeCheckIndex = _.findIndex(this.orgActivities, { label: value.type });
        if (orgTypeCheckIndex >= 0) {
          vizGraph += `{<f0> ${(this.orgActivities[orgTypeCheckIndex] && this.orgActivities[orgTypeCheckIndex]['title'])} | <f1> ${value.id}: ${value.label} }`;
        } else {
          vizGraph += this.getActivityRoleInfo(value);
          vizGraph += this.getActivityTaskInfo(value);
          vizGraph = `${vizGraph}${value.id}: ${(value.sort || '')} - ${(value.label || '')}`;
        }
        vizGraph = `${vizGraph}";id=${value.id}`; 

        if (this.activeStatusId == value.id && this.showActivatedStatus) {
          vizGraph += this.getActivityShapes(value, true);
        } else {
          vizGraph += this.getActivityShapes(value, false);
        }
        vizGraph += '];';
      }
    });

    this.prevBuilded = {};
    data['activities'].forEach((value, key) => {
      if (value.type === 'subgraph') {
        this.makeSubGraph(value, data['activities']);
      }
    });

    if (this.prevBuilded && Object.keys(this.prevBuilded).length) {
      // tslint:disable-next-line:forin
      for (const i in this.prevBuilded) {
        vizGraph += this.prevBuilded[i];
      }
    }


    data['transitions'].forEach((value, key) => {
      if (value.source && value.source.id && value.dest && value.dest.id) {
        const procId = data['_id'];
        const transId = value.id;
        vizGraph += value.source.id + ' -> ' + value.dest.id;

        if (value.kind === 'manual') {
          vizGraph += '[color=blue, label="manual",arrowsize=2.0, id=' + value.id + ']';
        }
        if (value.kind === 'auto') {
          vizGraph += '[label="auto",arrowsize=2.0, id=' + value.id + ']';
        }
        if (value.kind === 'trigger') {
          vizGraph += '[color=darkgreen, label="trigger",arrowsize=2.0, id=' + value.id + ']';
        }
        if (value.kind === 'waiting') {
          vizGraph += '[color=yellow, label="waiting",arrowsize=2.0, id=' + value.id + ']';
        }
        vizGraph += ';';
      }
    });
    vizGraph += '}';

    this.processView = Viz(vizGraph, { 'format': 'svg' });
    setTimeout(() => {
      svgPanZoom('#process-graph-down>svg', this.svgPanToZoomOptions);
    }, 500);

  }

  makeSubGraph(subGraphActivity, activities) {
    let vizSubGraph = '';
    activities = activities || [];
    if (subGraphActivity) {
      vizSubGraph += `subgraph cluster_${subGraphActivity.id} {`;

      vizSubGraph += 'label="';
      vizSubGraph += subGraphActivity.id + ': ' + subGraphActivity.label;
      vizSubGraph += '";';
      vizSubGraph += 'id=' + subGraphActivity.id + ';';

      if (subGraphActivity.activityList && subGraphActivity.activityList.length) {
        subGraphActivity.activityList.forEach((activityId => {
          const subGraphIndex = _.findIndex(activities, { id: activityId });
          if (subGraphIndex >= 0 && activities[subGraphIndex] && activities[subGraphIndex]['type'] === 'subgraph') {
            vizSubGraph += this.makeSubGraph(activities[subGraphIndex], activities);
            if (this.prevBuilded[activities[subGraphIndex]['id']]) {
              delete this.prevBuilded[activities[subGraphIndex]['id']];
            }
          } else {
            vizSubGraph += `${activityId};`;
          }
        }));
      }
      vizSubGraph += '}; ';
      this.prevBuilded[subGraphActivity.id] = vizSubGraph;
      return vizSubGraph;
    } else {
      return '';
    }
  }

  downloadProcessGraph (eve) {
    if (eve) {
      eve.preventDefault();
    }
    const svgHtml = $('#process-graph-down svg').html().replace(/ /gi, '');
    const svgURL = new XMLSerializer().serializeToString(document.querySelector('#process-graph-down svg'));
    const svgHeight = $('#process-graph-down svg').height();
    const svgWidth = $('#process-graph-down svg').width();
    const data = 'data:image/svg+xml; charset=utf8, ' + encodeURIComponent(svgHtml);
    $('#canvas-graph-img').html(`<canvas id="canvas-img" width="${svgWidth}" height="${svgHeight}"></canvas>`);
    const canvas = $('#canvas-img').get(0);
    const ctx = canvas.getContext('2d');
    const image = new Image;
    const a = document.createElement('a');
    a.download = `${(this.processData && this.processData.name) || 'Process'}-${moment().format('YYYY-MM-DD')}.jpeg`;
    image.onload = function() {
      ctx.drawImage(image, 0, 0);
      a.href = canvas.toDataURL('image/jpeg');
      a.click();
    };
    image.src = 'data:image/svg+xml; charset=utf8, ' + encodeURIComponent(svgURL);
  }
  

  getActivityTaskInfo(activity: IActivity): string {
    let retString = '';
    if (activity) {
      activity.tasks = activity.tasks || <any>[];
      let taskCount = 0;
      let tt = '';
      if (activity.tasks) {
        activity.tasks.error = activity.tasks.error || [];
        activity.tasks.init = activity.tasks.init || [];
        activity.tasks.leaving = activity.tasks.leaving || [];
        activity.tasks.pending = activity.tasks.pending || [];
        const temp = activity.tasks.error.concat(activity.tasks.init).concat(activity.tasks.leaving).concat(activity.tasks.pending) || [];
        temp.map(ele => {
          tt += `${ele.name}, `;
        });
        taskCount = activity.tasks.error.length + activity.tasks.init.length + activity.tasks.leaving.length + activity.tasks.pending.length;
      }
      if (taskCount) {
        retString += `{${taskCount}Tasks}\n`;
      }
      if (tt.length) {
        tt = tt.substr(0, tt.length - 2);
        retString += `{${tt}}\n`;
      }
      return retString;
    } else {
      return '';
    }
  }

  getActivityRoleInfo(activity: IActivity): string {
    activity = activity || <any>[];
    let retString = '';
    activity.roles = activity.roles || [];
    if (activity.roles.length) {
      activity.roles.forEach((role: IRole, index) => {
        if (index !== 0) {
          retString += ', ';
        }
        retString += role.label || '';
      });
      retString = `(${retString})\n`;
    }
    return retString;
  }

  getActivityShapes(activity: IActivity, isActive: boolean): string {
    activity = activity || <any>[];
    let retString = '';
    this.shapes = this.shapes || [];
    this.shapes.forEach((shape: IShape) => {
      if (shape.type === activity.type && shape.kind === activity.kind) {
        retString = 
                  `shape="${shape.shape}",
                  style="${shape.style}",
                  fillcolor="${(isActive) ? this.focussedActivityStyles.fillcolor : shape.fillcolor}",
                  fontcolor="${(isActive) ? this.focussedActivityStyles.fontcolor : shape.fontcolor}",
                  fixedsize="${shape.fixedsize}"`;
      }
    });
    return retString;
  }

  isTaskTypeActivity(activity: IActivity): boolean {
    if (activity && (activity.kind === this.TASK_KIND || activity.kind === this.TASK_KIND2) && activity.type === this.TASK_TYPE) {
      return true;
    } else {
      return false;
    }
  }

  parseProcessWithoutTasks(processData: IProcess) {
    processData = processData || <any>{};
    const newProcessDoc = {
      activity: [],
      transition: []
    };
    // first transition to remove all tasks destination...
    processData.transitions = processData.transitions || [];
    processData.transitions = processData.transitions.map((transition: ITransition) => {
      if (!(transition && this.isTaskTypeActivity(transition.source))) {
        if (transition && this.isTaskTypeActivity(transition.dest)) {
          // find next destination
          transition.dest = this.findNextTransitionDestination(transition.dest, processData.transitions);
        }
      }
      return transition;
    });

    // remove all transition that have des and source task
    processData.transitions = processData.transitions.filter((transition: ITransition) => {
      if (transition && this.isTaskTypeActivity(transition.dest) && this.isTaskTypeActivity(transition.source)) {
          return false;
        } else {
          if ((transition && this.isTaskTypeActivity(transition.source))) {
            return false;
          } else {
            return true;
          }
        }
    });

    // remove task activity
    processData.activities = processData.activities.filter((activity: IActivity) => (!(this.isTaskTypeActivity(activity))));

    this.makeIt(processData);
  }

  findNextTransitionDestination(sourceActivity: IActivity, transitions: ITransition[]): IActivity {
    const tempTrans: ITransition = <any>_.find(transitions, { source: {id: sourceActivity.id} });
    if (tempTrans && this.isTaskTypeActivity(tempTrans.dest)) {
      return this.findNextTransitionDestination(tempTrans.dest, transitions);
    } else {
      return (tempTrans && tempTrans.dest) || <any>{};
    }
  }
}
