export const REGISTERED_DATE_TIME = 'registeredDataTime';

export const DELIVERY_ADDRESS_FIELDS = {
    SHORT_NAME: 'shortName',
    LONG_NAME: 'longName',
    COMPANY_NAME: 'companyName',
    COMPANY_ADRESS: 'companyAdress',
    COMPANY_CITY: 'companyCity',
    POSTAL_CODE: 'postalcode',
};
