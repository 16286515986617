/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./instance-timeline.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/ngx-timeline/dist/ngx-timeline.ngfactory";
import * as i3 from "ngx-timeline";
import * as i4 from "@angular/flex-layout/flex";
import * as i5 from "@angular/flex-layout/core";
import * as i6 from "@angular/common";
import * as i7 from "./instance-timeline.component";
import * as i8 from "@angular/common/http";
import * as i9 from "../../../services/helpers/helpers.service";
var styles_InstanceTimelineComponent = [i0.styles];
var RenderType_InstanceTimelineComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InstanceTimelineComponent, data: {} });
export { RenderType_InstanceTimelineComponent as RenderType_InstanceTimelineComponent };
function View_InstanceTimelineComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", " "])), i1.ɵppd(1, 2)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.date, "yyyy-MM-dd HH:mm:ss")); _ck(_v, 0, 0, currVal_0); }); }
function View_InstanceTimelineComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "ngx-timeline", [], null, null, null, i2.View_ɵa_0, i2.RenderType_ɵa)), i1.ɵdid(2, 114688, null, 4, i3.ɵa, [], { events: [0, "events"] }, null), i1.ɵqud(335544320, 1, { dateTemplate: 0 }), i1.ɵqud(335544320, 2, { headerTemplate: 0 }), i1.ɵqud(335544320, 3, { bodyTemplate: 0 }), i1.ɵqud(335544320, 4, { footerTemplate: 0 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_InstanceTimelineComponent_2)), i1.ɵdid(8, 16384, [[1, 4]], 0, i3.ɵb, [i1.TemplateRef], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(10, 671744, null, 0, i4.DefaultLayoutAlignDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.LayoutAlignStyleBuilder], i5.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Rozpocz\u0119cie procesu "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.events; _ck(_v, 2, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 10, 0, currVal_1); }, null); }
function View_InstanceTimelineComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Brak danych do pokazania."]))], null, null); }
export function View_InstanceTimelineComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstanceTimelineComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstanceTimelineComponent_3)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.instanceId; _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.instanceId || (_co.events && (_co.events.length === 0))); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_InstanceTimelineComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-instance-timeline", [], null, null, null, View_InstanceTimelineComponent_0, RenderType_InstanceTimelineComponent)), i1.ɵdid(1, 114688, null, 0, i7.InstanceTimelineComponent, [i8.HttpClient, i9.HelpersService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InstanceTimelineComponentNgFactory = i1.ɵccf("app-instance-timeline", i7.InstanceTimelineComponent, View_InstanceTimelineComponent_Host_0, { field: "field", instanceId: "instanceId" }, {}, []);
export { InstanceTimelineComponentNgFactory as InstanceTimelineComponentNgFactory };
