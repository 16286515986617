/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./json-settings.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./tab-settings/tab-settings.component.ngfactory";
import * as i3 from "./tab-settings/tab-settings.component";
import * as i4 from "./section-settings/section-settings.component.ngfactory";
import * as i5 from "./section-settings/section-settings.component";
import * as i6 from "./field-settings/field-settings.component.ngfactory";
import * as i7 from "./field-settings/field-settings.component";
import * as i8 from "../../services/helpers/helpers.service";
import * as i9 from "@angular/material/dialog";
import * as i10 from "../../state/workspaceState/workspace.store";
import * as i11 from "@angular/router";
import * as i12 from "@angular/common";
import * as i13 from "./json-settings.component";
var styles_JsonSettingsComponent = [i0.styles];
var RenderType_JsonSettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_JsonSettingsComponent, data: {} });
export { RenderType_JsonSettingsComponent as RenderType_JsonSettingsComponent };
function View_JsonSettingsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tab-settings", [], null, null, null, i2.View_TabSettingsComponent_0, i2.RenderType_TabSettingsComponent)), i1.ɵdid(1, 114688, null, 0, i3.TabSettingsComponent, [], { settings: [0, "settings"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_JsonSettingsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-section-settings", [], null, null, null, i4.View_SectionSettingsComponent_0, i4.RenderType_SectionSettingsComponent)), i1.ɵdid(1, 114688, null, 0, i5.SectionSettingsComponent, [], { settings: [0, "settings"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_JsonSettingsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-field-settings", [], null, null, null, i6.View_FieldSettingsComponent_0, i6.RenderType_FieldSettingsComponent)), i1.ɵdid(1, 114688, null, 0, i7.FieldSettingsComponent, [i8.HelpersService, i9.MatDialog, i10.WorkspaceStore, i1.ChangeDetectorRef, i11.Router], { settings: [0, "settings"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_JsonSettingsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, null, null, null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i12.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JsonSettingsComponent_2)), i1.ɵdid(4, 278528, null, 0, i12.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i12.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JsonSettingsComponent_3)), i1.ɵdid(6, 278528, null, 0, i12.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i12.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_JsonSettingsComponent_4)), i1.ɵdid(8, 278528, null, 0, i12.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i12.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.type; _ck(_v, 2, 0, currVal_0); var currVal_1 = "tab"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "section"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "field"; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_JsonSettingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_JsonSettingsComponent_1)), i1.ɵdid(1, 278528, null, 0, i12.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.jsonSettings; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_JsonSettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-json-settings", [], null, null, null, View_JsonSettingsComponent_0, RenderType_JsonSettingsComponent)), i1.ɵdid(1, 114688, null, 0, i13.JsonSettingsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var JsonSettingsComponentNgFactory = i1.ɵccf("app-json-settings", i13.JsonSettingsComponent, View_JsonSettingsComponent_Host_0, { jsonSettings: "jsonSettings" }, {}, []);
export { JsonSettingsComponentNgFactory as JsonSettingsComponentNgFactory };
