import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';
import { CompanyDetailCheckPopupComponent } from './company-detail-check-popup/company-detail-check-popup.component';

@Component({
  selector: 'app-ext-bank-check',
  templateUrl: './ext-bank-check.component.html',
  styleUrls: ['./ext-bank-check.component.scss']
})
export class ExtBankCheckComponent implements OnInit, OnChanges {

  @Input() field: any;
  @Input() regRec;
  @Output() onFieldValueUpdated: EventEmitter<any> = new EventEmitter();
  @Input() translateData: any;
  @Input() builder: any;
  @Input() autoFocus: any;
  @Input() model: string; 
  @Input() isTextarea: boolean; 
  @Output() modelChange: EventEmitter<any> = new EventEmitter();
  @Output() onError: EventEmitter<any> = new EventEmitter();
  nip: any;
  bankAccount: any;
  bankRes: any;
  
  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
  ) { }


  ngOnChanges(changes: SimpleChanges): void {

  }
  ngOnInit() {
    this.checkBank();
  }

  checkBank() {
    if (this.regRec && this.regRec['postalcode']) {
      this.http.get(`https://wl-api.mf.gov.pl/api/search/nip/${this.regRec['postalcode']}?date=${moment().format("YYYY-MM-DD")}&_=${moment().toDate()}`)
      .subscribe((data: any) => {
        console.log("data =", data);
        this.bankRes = data.result.subject;
      });
    }
  }

  viewDetail() {
    const data = this.dialog.open(CompanyDetailCheckPopupComponent, {
      disableClose: false,
      width: '100%',
      height: '100%',
      panelClass: 'happ-form-builder-dialog',
      data: {
        data: this.bankRes
      }
    });

  }
}
