// tslint:disable:indent
import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AuthState, AuthStore } from './auth.store';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
	isAuthenticated = this.select((state) => !!state.token);
	officeName = this.select((state) => state.officeName);
	officeId = this.select((state) => state.officeId);
	email = this.select((state) => state.email);
	isAdmin = this.select((state) => state.isAdmin);
	redirectUrl = this.select((state) => state.redirectUrl);
	defaultLang = this.select((state) => state.defaultLang);
	invitationCount = this.select((state) => state.invitationCount);

	constructor(protected store: AuthStore) {
		super(store);
	}
}
