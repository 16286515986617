<mat-toolbar class="p-0 mat-elevation-z1">
        <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
            
            <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
                    <ng-container *ngIf="!isOfficeView">
                        <div>
                            <button  mat-button aria-label="open_office_dropdown" class="user-button">
                                <div fxLayout="row" fxLayoutAlign="center center">
                                    <span class="username mr-12"> {{ 'toolbar.client_panel' | translate }} </span>
                                </div>
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isOfficeView">
                    <button *ngIf="!hiddenNavbar" mat-button class="toggle-button-navbar mat-icon-button" (click)="toggleSidebarFolded()"
                        fuseNavbarVertical="toggleBar" fxHide.gt-md>
                        <mat-icon>menu</mat-icon>
                    </button>

                    <!-- Home icon for office dashboard -->
                    <button id="step4" *ngIf="officeData && officeData['_id']" fxShow.gt-xs [routerLink]="'/offices/'+officeData['_id']+'/dashboard'" mat-icon-button
                        class="mat-icon-button quick-panel-toggle-button happ-home-button" aria-label="Toggle quick panel">
                        <mat-icon class="icon font-size-32 h-24 w-32">home</mat-icon>
                    </button>
                    <div class="toolbar-separator"></div>

                    <!-- office drop down start -->
                    <div>
                        <button fxHide.lt-md mat-button [matMenuTriggerFor]="officeMenu" aria-label="open_office_dropdown"
                            (click)="getOfficeList();" class="user-button">
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <span class="username mr-12" fxHide fxShow.gt-sm> {{ selectedOfficeName | translate }} </span>
                                <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                            </div>
                        </button>
                    </div>
                    <!-- office drop down ends -->

                    <!-- workspace drop down start -->
                    <div fxHide.lt-md *ngIf="!hideToolbar" class="toolbar-separator"></div>
                    <div *ngIf="!hideToolbar">
                        <button fxHide.lt-md mat-button [matMenuTriggerFor]="workspaceMenu" aria-label="open_office_dropdown"
                            class="user-button">
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <div fxLayout="column">
                                    <span class="username mr-12" fxHide fxShow.gt-sm>
                                        {{ selectedWorkspaceName | translate }}
                                    </span>
                                    <span *ngIf="virtualOrgObj" class="username mr-12" fxHide fxShow.gt-sm style="font-size: 10px;color: gray;">
                                        ({{ virtualOrgObj.orgName | translate }})
                                    </span>
                                </div>
                                <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                            </div>
                        </button>
                    </div>
                    <div class="toolbar-separator" fxHide fxShow.gt-md></div>
                    <!-- workspace drop down end -->

                    <!-- Short cut option with star icon - HIDDEN NOW -->
                    <div *ngIf="false" class="px-8 px-mat-16">
                        <fuse-shortcuts [navigation]="{}"></fuse-shortcuts>
                    </div>

                    <div class="toolbar-separator" fxHide.gt-md></div>
                    <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNav">
                        <div class="logo ml-16">
                            <img class="logo-icon" src="assets/images/logos/fuse.svg">
                        </div>
                    </div>
                </ng-container>

                </div>

            <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

                <ng-container *ngIf="isOfficeView">

                    <!-- Show invitations icon -->
                    <div class="toolbar-separator" fxHide fxShow.gt-xs></div>
                    <div *ngIf="false" [matTooltip]="'toolbar.plan_type' | translate" fxLayoutAlign="center center"
                        [routerLink]="'/pricing-plan'" mat-icon-button
                        class="mat-icon-button quick-panel-toggle-button happ-notification-count-container w-120">
                        <span> {{ (officeData && officeData.officePlanSettings && officeData.officePlanSettings.planType) || "Free" }} </span>
                    </div>

                    <div class="toolbar-separator" fxHide fxShow.gt-xs></div>
                    <div *ngIf="false" [matTooltip]="'toolbar.credits' | translate" fxLayoutAlign="center center"
                        [routerLink]="'/office/payments/dashboard'" mat-icon-button
                        class="mat-icon-button quick-panel-toggle-button happ-notification-count-container w-120">
                        <span> {{ officeCredit || 0 }} PP </span>
                    </div>

                    <div *ngIf="invitationCount" class="toolbar-separator" fxHide fxShow.gt-xs></div>
                    <button  *ngIf="invitationCount" [matTooltip]="'toolbar.invitation' | translate"
                        [routerLink]="'/invitation/list'" mat-icon-button
                        class="mat-icon-button quick-panel-toggle-button happ-notification-count-container"
                        aria-label="Toggle quick panel">
                        <span class="heartbit"></span>
                        <mat-icon class="icon font-size-32 h-24 w-32">mood</mat-icon>
                        <div class="unread-count" *ngIf="invitationCount"> {{ invitationCount }} </div>
                    </button>

                    <!-- Bug report starts -->
                    <div class="toolbar-separator" fxHide fxShow.gt-xs></div>
                    <button id="step1" fxShow.gt-xs
                        [matTooltip]="'toolbar.bug_report' | translate" (click)="addIssue('user')" mat-icon-button
                        class="mat-icon-button quick-panel-toggle-button" aria-label="Toggle quick panel">
                        <mat-icon class="icon font-size-32 h-24 w-32">bug_report</mat-icon>
                    </button>
                    <div fxShow.gt-xs *ngIf="ADMIN_EMAILS && username && ADMIN_EMAILS.includes(username)"
                        class="toolbar-separator" fxHide fxShow.gt-xs></div>
                    <button fxShow.gt-xs *ngIf="ADMIN_EMAILS && username && ADMIN_EMAILS.includes(username)"
                        [matTooltip]="'toolbar.bug_report_admin' | translate" color="warn" (click)="addIssue('admin')"
                        mat-icon-button class="mat-icon-button quick-panel-toggle-button" aria-label="Toggle quick panel">
                        <mat-icon class="icon font-size-32 h-24 w-32" style="color: #f44336;">bug_report</mat-icon>
                    </button>
                    <!-- <div *ngIf="ADMIN_EMAILS && username && ADMIN_EMAILS.includes(username)" class="toolbar-separator" fxHide
                        fxShow.gt-xs></div> -->
                    <!-- bug report ends -->

                    <!-- toobar settings -->
                    <div class="toolbar-separator" fxHide fxShow.gt-xs></div>
                    <button id="step2" fxShow.gt-xs [matMenuTriggerFor]="moreSettings" [matTooltip]="'toolbar.settings' | translate"
                        mat-icon-button class="mat-icon-button quick-panel-toggle-button">
                        <mat-icon class="icon font-size-32 h-24 w-32">settings</mat-icon>
                    </button>
                    <!-- language sub menu -->
                    <mat-menu #moreSettings="matMenu">
                        <button mat-menu-item [matMenuTriggerFor]="languagesMenu"> {{ 'toolbar.change_language' | translate }}
                            ({{(selectedLanguage && selectedLanguage.id)}}) </button>
                        <button mat-menu-item (click)="openLayoutPopup()">{{ 'toolbar.layouts' | translate }}</button>
                        <button *ngIf="navigationService && navigationService.isAdmin" routerLink="/translate/key" mat-menu-item class="">
                            <span>{{ 'toolbar.language_settings' | translate }}</span>
                        </button>
                        <button routerLink="/cp/dashboard" mat-menu-item class="">
                            <span>{{ 'toolbar.personal_platform' | translate }}</span>
                        </button>
                        <button routerLink="/personal-dashboard" mat-menu-item class="">
                            <span>{{ 'toolbar.personal_dashboard' | translate }}</span>
                        </button>
                        <button routerLink="/cp/dashboard" mat-menu-item class="">
                            <span>{{ 'toolbar.personal_platform' | translate }}</span>
                        </button>
                        <button *ngIf="navigationService && navigationService.isAdmin" routerLink="/shared-service/home" mat-menu-item class="">
                            <span>{{ 'toolbar.shared_service' | translate }}</span>
                        </button>
                        <button *ngIf="navigationService && navigationService.isAdmin" routerLink="/shared-service/client/home" mat-menu-item class="">
                            <span>{{ 'toolbar.client_shared_service' | translate }}</span>
                        </button>
                        <button *ngIf="navigationService && navigationService.isAdmin" routerLink="/shared-service/admin/home" mat-menu-item class="">
                            <span>{{ 'toolbar.admin_shared_service' | translate }}</span>
                        </button>
                        
                        <button *ngIf="navigationService && navigationService.isAdmin" routerLink="/ss-all-apps" mat-menu-item class="">
                            <span>{{ 'toolbar.ss_all_apps' | translate }}</span>
                        </button>
                        <button *ngIf="navigationService && navigationService.isAdmin" routerLink="/platform-global-template" mat-menu-item class="">
                            <span>{{ 'toolbar.platform_global_template' | translate }}</span>
                        </button>
                        <button *ngIf="navigationService && navigationService.isAdmin" routerLink="/plugins/package" mat-menu-item class="">
                            <span>{{ 'toolbar.apps_package' | translate }}</span>
                        </button>
                        <button *ngIf="navigationService && navigationService.isAdmin" routerLink="/super-admin/dashboard" mat-menu-item class="">
                            <span>{{ 'toolbar.super_admin' | translate }}</span>
                        </button>
                        <button mat-menu-item [matMenuTriggerFor]="settingsMenu" *ngIf="navigationService && navigationService.isAdmin"> {{ 'toolbar.settings' | translate }}</button>
                    </mat-menu>
                    <mat-menu #languagesMenu="matMenu" [overlapTrigger]="false">
                        <button [attr.aria-label]="lang.flag" mat-menu-item *ngFor="let lang of languages"
                            (click)="setLanguage(lang)">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <img class="flag mr-16 w-24 h-24" [src]="'assets/images/flags/'+lang.flag+'.png'">
                                <span class="iso">{{lang.title}}</span>
                            </div>
                        </button>
                    </mat-menu>
                    <mat-menu #settingsMenu="matMenu">
                        <button *ngFor="let setting of officeSettings" (click)="moveTo(setting)" mat-menu-item>
                            {{ setting.title | translate }} </button>
                        <button mat-menu-item (click)="this.toggleSidebarOpen('themeOptionsPanel')">
                            Default theme Config
                        </button>
                    </mat-menu>
                    <!-- layout sub menu items -->
                    <mat-menu #menuLayout="matMenu">
                        <button *ngFor="let layout of layouts" (click)="changeLayout(layout.settings)" mat-menu-item>
                            {{ layout.title | translate }} </button>
                    </mat-menu>
                </ng-container>

                <!-- user profile drop down -->
                <div class="toolbar-separator" fxHide fxShow.gt-xs></div>
                <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <!-- <img class="avatar" src="assets/images/avatars/profile.jpg"> -->
                        <img  class="avatar" src="/assets/images/profile.jpg" *ngIf="username" ngxGravatar [email]="username">
                        <span aria-label="user_email" class="username mr-12" fxHide fxShow.gt-sm>
                            {{username | getUserInfoByEmail: 'symbleName' }} </span>
                        <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                    </div>
                </button>

                
                    <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
                        <!-- <button [routerLink]="'/profile'" mat-menu-item>
                            <mat-icon>account_circle</mat-icon>
                            <span>{{ 'toolbar.my_profile' | translate }}</span>
                        </button> -->
                        <!-- <button mat-menu-item class="">
                            <mat-icon>mail</mat-icon>
                            <span>{{ 'toolbar.inbox' | translate }}</span>
                        </button> -->
                        <button mat-menu-item [routerLink]="'/invitation/list'">
                            <mat-icon>mail</mat-icon>
                            <span>{{ 'toolbar.invitation' | translate }}</span>
                        </button>
                        <!-- <button mat-menu-item [routerLink]="'/personal-dashboard'">
                            <mat-icon>person_outline</mat-icon>
                            <span>{{ 'toolbar.personal_dashboard' | translate }}</span>
                        </button> -->
                        <button *ngIf="navigationService && navigationService.isAdmin" mat-menu-item [routerLink]="'/office-profile'">
                            <mat-icon>account_balance</mat-icon>
                            <span>{{ 'toolbar.office_profile' | translate }}</span>
                        </button>
                        <button *ngIf="navigationService && navigationService.isAdmin" mat-menu-item [routerLink]="'/office-credit'">
                            <mat-icon>account_balance_wallet</mat-icon>
                            <span> {{ officeCredit || 0 }} - {{ 'toolbar.coins' | translate }}</span>
                        </button>
                        <button *ngIf="navigationService && navigationService.isAdmin" mat-menu-item [routerLink]="'/office-credit/list'">
                            <mat-icon>account_balance_wallet</mat-icon>
                            <span>  {{ 'toolbar.list' | translate }}</span>
                        </button>
                        <button fxHide fxShow.lt-md [matMenuTriggerFor]="officeMenu" mat-menu-item class="">
                            <mat-icon>business_center</mat-icon>
                            <span>{{ 'toolbar.office' | translate }} 
                            </span>
                        </button>
                        <button  fxHide fxShow.lt-md [matMenuTriggerFor]="workspaceMenu" mat-menu-item class="">
                            <mat-icon>laptop</mat-icon>
                            <span>{{ 'toolbar.workspace' | translate }}</span>
                        </button>
                        <button *ngIf="false" mat-menu-item class="">
                            <mat-icon>call</mat-icon>
                            <span>{{ 'toolbar.call' | translate }}</span>
                        </button>
                        <button mat-menu-item class="" (click)="logOut()">
                            <mat-icon>exit_to_app</mat-icon>
                            <span>{{ 'toolbar.logout' | translate }}</span>
                        </button>
                        <button mat-menu-item class="" (click)="systemInfo()">
                            <mat-icon>ll</mat-icon>
                            <span style="color: rgb(3,155,229)"> Version: {{ version }} </span>
                        </button>
                    </mat-menu>
                    <div class="toolbar-separator"></div>
            </div>
        </div>

    <!-- logout button -->
    <button id="step3" fxShow.gt-xs [matTooltip]="'toolbar.logout_icon' | translate" (click)="logOut()" mat-icon-button
        class="mat-icon-button quick-panel-toggle-button happ-red-button" aria-label="Toggle quick panel">
        <mat-icon class="icon font-size-32 h-24 w-32">power_settings_new</mat-icon>
    </button>

    <ng-container *ngIf="isOfficeView && false">
        <div class="toolbar-separator"></div>
        <button mat-icon-button
                class="quick-panel-toggle-button"
                (click)="toggleSidebarOpen('quickPanel')"
                aria-label="Toggle quick panel">
            <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            <div class="unread-count" *ngIf="socketService.callsList.length"> {{ socketService.callsList.length }} </div>
        </button>
    </ng-container>

    
    <!-- office menu -->
    <mat-menu #officeMenu="matMenu" [overlapTrigger]="false">
        <ng-container *ngIf="false">
        <div style="background: lightgreen;">
            <button mat-menu-item routerLink="/personal-dashboard" >
                <span> {{ 'dashboard.personal_area' | translate }} </span>
            </button>
        </div>
        <mat-divider></mat-divider>
        </ng-container>
        <div *ngIf="officeList">
            <div *ngFor="let office of officeList">
                <button mat-menu-item *ngIf="office && office['starred']" (click)="changeOffice(office)">
                    <span> {{ office['name'] || office['officeId'] }} </span>
                </button>
            </div>
        </div>
        <div *ngIf="!officeList">
            <button mat-menu-item>
                <span> {{ 'toolbar.loading' | translate }} </span>
            </button>
        </div>
        <mat-divider></mat-divider>
        <!-- <button aria-label="open_office_list" mat-menu-item [routerLink]="['dashboard']"> -->
        <button aria-label="open_office_list" mat-menu-item (click)="openOfficeList()">
            <span> {{ 'toolbar.all_offices' | translate }} </span>
        </button>
    </mat-menu>

    <!-- workspace sub menu -->
    <mat-menu #workspaceMenu="matMenu" [overlapTrigger]="false">
        <div *ngIf="officeData && officeData.allowSpecialist">
            <button (click)="selectedWorkspaceName='toolbar.client_panel'" mat-menu-item routerLink="/cp/dashboard" >
                <mat-icon>account_box</mat-icon>
                <span> {{ 'toolbar.client_panel' | translate }} </span>
            </button>
            <mat-divider></mat-divider>
        </div>
        <div *ngIf="officeData && officeData.allowSpecialist">
            <button (click)="selectedWorkspaceName='toolbar.client_panel'" mat-menu-item routerLink="/cp/specialist/dashboard" >
                <mat-icon>pan_tool</mat-icon>
                <span> {{ 'toolbar.specialist_panel' | translate }} </span>
            </button>
            <mat-divider></mat-divider>
        </div>
        <div>
            <button (click)="selectedWorkspaceName='toolbar.virtual_org_selection';moveToVirtualOrg()" mat-menu-item routerLink="/cp/specialist/dashboard" >
                <mat-icon>pan_tool</mat-icon>
                <span> {{ 'toolbar.virtual_org_selection' | translate }} </span>
            </button>
            <mat-divider></mat-divider>
        </div>
        <div *ngIf="defaultWorkspaceConfig">
            <div *ngFor="let workspace of defaultWorkspaceConfig.workspaceList">
                <button mat-menu-item (click)="workspace.function()">
                    <mat-icon>{{ workspace.icon }}</mat-icon>
                    <span> {{ (workspace['title'] || workspace['name'] || workspace['slug']) | translate }} </span>
                </button>
            </div>
        </div>
        <div *ngIf="false">
            <mat-divider></mat-divider>
            <button (click)="selectedWorkspaceName='toolbar.personal_dashboard'" mat-menu-item routerLink="/personal-dashboard" >
                <mat-icon>account_circle</mat-icon>
                <span> {{ 'toolbar.personal_dashboard' | translate }} </span>
            </button>
        </div>
        <!-- <div *ngIf="isProvider" > -->
        <div *ngIf="officeData && officeData.allowSpecialist">
            <mat-divider></mat-divider>
            <button (click)="selectedWorkspaceName='toolbar.provider_panel'" mat-menu-item routerLink="/shared-service/admin/home" >
                <mat-icon>admin_panel_settings</mat-icon>
                <span> {{ 'toolbar.provider_panel' | translate }} </span>
            </button>
        </div>
    </mat-menu>
</mat-toolbar>