import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';

@Component({
  selector: 'app-smtp-form',
  templateUrl: './smtp-form.component.html',
  styleUrls: ['./smtp-form.component.scss']
})
export class SmtpFormComponent implements OnInit {
  smtpSettings: any;
  loading: boolean;

  constructor(
    public dialogRef: MatDialogRef<SmtpFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private helperService: HelpersService
  ) { }

  ngOnInit() {
    this.smtpSettings = this.data.smtpSettings || {};
    // delete this.smtpSettings.password
  }
 
  async save() {
    this.loading = true;
    try {
      if (this.data.type === "smtpConfig") {
        this.smtpSettings.type = "default";
      }
      await this.helperService.postRequest("api/office-smtp", this.smtpSettings);
      this.loading = false;
      this.dialogRef.close(this.smtpSettings);
    } catch (error) {
      alert(error);
      this.loading = false;
    }
  }
    
  close() {
    this.dialogRef.close();
  }
}
