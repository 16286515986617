import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-company-detail-check-popup',
  templateUrl: './company-detail-check-popup.component.html',
  styleUrls: ['./company-detail-check-popup.component.scss']
})
export class CompanyDetailCheckPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CompanyDetailCheckPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
  }

}
