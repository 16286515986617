import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HardFilterSetupComponent } from './hard-filter-setup.component';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
  declarations: [HardFilterSetupComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FuseSharedModule
  ],
  entryComponents: [
    HardFilterSetupComponent
  ]
})
export class HardFilterSetupModule { }
