<table *ngIf="data">
  <tr>
    <td>
      {{ 'ct.field' | translate }}
    </td>
    <td>
      {{ 'ct.visible' | translate }}
    </td>
    <td>
      {{ 'ct.required' | translate }}
    </td>
    <td>
      {{ 'ct.sort' | translate }}
    </td>
  </tr>
  <tr *ngFor="let item of registerForm">
    <td>
      {{ item.title | translate }}
    </td>
    <td class="w-100" *ngIf="data[item.key]">
      <mat-checkbox (change)="onChange()" [(ngModel)]="data[item.key].visible"></mat-checkbox>
    </td>
    <td class="w-100" *ngIf="data[item.key]">
      <mat-checkbox (change)="onChange()" [(ngModel)]="data[item.key].required"></mat-checkbox>
    </td>
    <td class="w-100">
      <!-- <mat-checkbox [(ngModel)]="data[item.key].visible"></mat-checkbox> -->
      <mat-form-field class="example-full-width w-100" class="happ-small-form-field-outline" appearance="outline">
        <mat-label>{{ 'ct.sort' | translate }}</mat-label>
        <input (change)="onChange()" matInput  [(ngModel)]="data[item.key].sort">
      </mat-form-field>
    </td>
  </tr>
</table>