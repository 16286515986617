import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { GlobalModule } from '@fuse/global.module';
import { ProcessGraphViewComponent } from './process-graph-view.component';

@NgModule({
    imports     : [
       FuseSharedModule,
        GlobalModule,
    ],
    declarations: [
        ProcessGraphViewComponent
    ],
    providers   : [
    ],
    exports: [
        ProcessGraphViewComponent
    ],
    entryComponents: [
        ProcessGraphViewComponent
    ]
})
export class ProcessGraphViewModule { }
