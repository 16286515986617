import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HelpersService } from '../../services/helpers/helpers.service';

@Component({
  selector: 'app-template-preview',
  templateUrl: './template-preview.component.html',
  styleUrls: ['./template-preview.component.scss']
})
export class TemplatePreviewComponent implements OnInit {

  htmlPreviewLang = 'en';
  previewView = 'html';
  languages: any = [];
  templateData;
  constructor(
    private helperService: HelpersService,
    public dialogRef: MatDialogRef<TemplatePreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) { }

  ngOnInit() {
    this.getLang();
    this.templateData = this.data.templateData;
  }

  
  async getLang() {
    try {
      this.languages = this.helperService.formatTheLanguageArray(await this.helperService.getActiveLangs());
    } catch (err) {
      console.error(err);
    }
  }

  close() {
    this.dialogRef.close();

  }
}
