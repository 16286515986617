<div fxLayout="column">
  <div class="ml-8 mt-8 mb-12" fxLayout="row">
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-form-field floatLabel="always" appearance="outline"
        class="happ-small-form-field-outline happ-dt-table-filter-input">
        <mat-label> search </mat-label>
        <input matInput aria-label="record_fast_search_input" (keyup.enter)="filter(query)" [(ngModel)]="query">
      </mat-form-field>
      <button aria-label="record_fast_search_button" style="margin: auto 2px;" class="ml-8" matTooltip="search"
        (click)="filter(query)" mat-icon-button>
        <mat-icon style="color: black;">search</mat-icon>
      </button>
    </div>
    <span fxFlex></span>
    <div style="margin: auto;">
      <button class="ml-8" (click)="addNewTemplate()" mat-raised-button>Add Template</button>
    </div>
  </div>
  <div>

    <div fxLayout="row">
      <button *ngFor="let item of typeLists" mat-raised-button [color]="(type === item.key)" (click)="type = item.key" > {{ item.title }} </button>
    </div>

    <ngx-datatable [messages]="{emptyMessage: 'Brak danych', totalMessage: 'TOTAL'}" [@animateStagger]="{value:'50'}"
      class="bootstrap" [rows]="rows" [loadingIndicator]="loadingIndicator" [columns]="requestData.columns"
      [columnMode]="'force'" [headerHeight]="50" [externalPaging]="true" [footerHeight]="50" [rowHeight]="'auto'"
      [count]="requestData.page.totalElements" [offset]="requestData.page.pageNumber" (sort)="onSort($event)"
      [limit]="requestData.page.size" (page)='setPage($event)'>
      <ngx-datatable-column *ngFor="let col of requestData.columns; let colIndex=index" fxFlex [prop]="col.prop"
        [name]="col.title">
        <!-- Header Template Starts -->
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <div class="ml-4" (click)="sort();" style="display: inline-block; position: absolute; top: 13px;"
            *ngIf="column.name != 'Actions'">{{column.name}}</div>
          <div class="mr-8 w-100-p" style="position: absolute; right: 25px; display: inline-block;"
            *ngIf="column.name == 'Actions'">
            <span fxFlex></span>
            <p>Akcje</p>
          </div>
        </ng-template>
        <!-- Header Template Ends -->
        <!-- Row and cell Template Starts -->
        <ng-template let-value="value" class="happ-dummy" let-rowIndex="rowIndex" let-row="row" style="color: blue;"
          ngx-datatable-cell-template>
          <div class="ml-4" *ngIf="col.prop === 'createdAt'"> {{ value | date:'yyyy-MM-dd HH:mm:ss' }} </div>
          <div class="ml-4" *ngIf="col.prop === 'createdById'"> {{ value.login }} </div>
          <div class="p-4 mr-8 w-100-p" *ngIf="col.prop === '_id'">
            <span fxFlex></span>
            <button class=mr-8 color="warn" mat-raised-button (click)="deleteTemplate(row)"> Delete </button>
            <button class=mr-8 mat-raised-button (click)="editTemplate(value)"> Edit </button>
            <button class="mr-8" *ngIf="allowSelection"
              [color]="(selectedTemplates.includes(row._id) || (row && singleTemplate === row._id) || (row && singleTemplate && singleTemplate._id === row._id)) ? 'primary' : ''"
              mat-raised-button (click)="selectTemplate(row)"> Select Template </button>
          </div>
          <div class="ml-4" *ngIf="col.prop === 'title'"> {{ value }} </div>
        </ng-template>
        <!-- Row and cell Template Ends -->
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>