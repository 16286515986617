
<div [class]="'happ-header-data-align-' + ((col && col.control_type && col.control_type.columnAlign) || 'left')"
 *ngIf="column.name != '_id' && column.name !== 'subAction'">
    {{column.name}}
</div>
<div class="happ-header-data-align-right" *ngIf="column.name === 'subAction'">
    {{ 'dttable.subAction' | translate }} 
</div>
<div class="happ-header-data-align-right"
    *ngIf="column.name == '_id'">
    {{ 'dttable.actions' | translate }} </div>
  