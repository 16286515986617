import { LanguagesInterface } from '../interfaces/languages.interface';

export const LanguagesModel: Array<LanguagesInterface> = [
    {
        'id'   : 'en',
        'title': 'English',
        'flag' : 'us'
    },
    {
        'id'   : 'pl',
        'title': 'Polish',
        'flag' : 'pl'
    }
];
