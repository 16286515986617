<div fxLayout="column">
    <div class="toolbar px-24 py-8">
        <div>
            <button mat-raised-button (click)="currentView=0" [color]="(currentView == 0)? 'primary': ''">
                General
            </button>
            <button mat-raised-button (click)="currentView=1" [color]="(currentView == 1)? 'primary': ''">
                Translations
            </button>
            <button mat-raised-button (click)="currentView=2" [color]="(currentView == 2)? 'primary': ''">
                Target And Params
            </button>
        </div>
        <span fxFlex></span>
        <div>
            <button mat-raised-button (click)="dialogRef.close();">
                Cancel
            </button>
            <button mat-raised-button (click)="updateRegData()" color="warn">
                Save
            </button>
        </div>
    </div>
    <div class="mb-16 mx-20" *ngIf="currentView==0" fxLayout="column">
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
            <mat-form-field class="mx-4" fxFlex>
                <input placeholder="Default Action Name" [(ngModel)]="actionData.default_label" matInput required>
            </mat-form-field>
            <mat-form-field class="mx-4" fxFlex>
                <input placeholder="Sort" type="number" [(ngModel)]="actionData.sort" matInput required>
            </mat-form-field>
        </div>

        <div fxLayout="row">
            <div fxFlex="50" fxLayout="column">
                <p style="font-weight: 600;">Module to show action button on</p>
                <mat-radio-group fxLayout="column" [(ngModel)]="actionData.module">
                    <mat-radio-button *ngFor="let list of modules" [value]="list.key"> {{list.title}} </mat-radio-button>
                </mat-radio-group>
            </div>
            <div fxFlex="50" fxLayout="column">
                <p style="font-weight: 600;">Placement in module app for button</p>
                <mat-radio-group fxLayout="column" [(ngModel)]="placementVal" (change)="placementChanged(val)">
                    <mat-radio-button *ngFor="let list of placements" [value]="list.name"> {{list.name}} </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div fxLayout="row wrap">
            <div fxFlex="49" class="my-8" fxLayout="column">
              <h4>
                View Type
              </h4>
              <mat-radio-group class="example-radio-group" [(ngModel)]="actionData.typeOfView">
                <mat-radio-button class="example-radio-button mr-8" value="text">
                  Text Only
                </mat-radio-button>
                <mat-radio-button class="example-radio-button mr-8" value="icon">
                  Icon Only
                </mat-radio-button>
                <mat-radio-button class="example-radio-button mr-8" value="textAndIcon">
                  Text and Icon Both
                </mat-radio-button>
              </mat-radio-group>
            </div>
        
            
            <div fxFlex="49" fxLayoutAlign="start center" class="my-8" fxLayout="row">
              <p class="mr-8">Select Icon: </p>
              <button (click)="selectIcon()" mat-icon-button>
                <mat-icon> {{ actionData.icon || 'settings' }} </mat-icon>
              </button>
            </div>
          </div>
          <div fxLayout="row wrap">
              <div fxFlex="49" class="my-8" fxLayout="column">
                <h4>
                  ActionType
                </h4>
                <mat-radio-group class="example-radio-group" [(ngModel)]="actionData.actionType">
                  <mat-radio-button class="example-radio-button mr-8" value="subAction">
                    Sub Action
                  </mat-radio-button>
                  <mat-radio-button class="example-radio-button mr-8" value="action">
                    Action
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <span fxFlex></span>
            </div>

    </div>
    <div class="mb-16 mx-20" *ngIf="currentView==1" fxLayout="column">
        <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
            <mat-form-field class="mx-4" fxFlex>
                <input placeholder="Polish" [(ngModel)]="actionData.translations.pl" matInput required>
            </mat-form-field>
            <mat-form-field class="mx-4" fxFlex>
                <input placeholder="English" [(ngModel)]="actionData.translations.en" matInput required>
            </mat-form-field>
        </div>
    </div>
    <div class="mb-16 mx-20" *ngIf="currentView==2" fxLayout="column">
 <!-- ROW WITH FILTER BUTTONS -->
    <div class="mb-24 mx-20" fxLayout="row" fxLayoutAlign="center center">
      <button mat-raised-button (click)="selectedGroup='register'; actionData['appType'] = 'register'" [color]="(selectedGroup === 'register')? 'primary': ''">
        Register
      </button>
      <button mat-raised-button (click)="selectedGroup='record'; actionData['appType'] = 'record'" [color]="(selectedGroup == 'record')? 'primary': ''">
        Record
      </button>
      <button mat-raised-button (click)="selectedGroup='stepper'; actionData['appType'] = 'stepper'" [color]="(selectedGroup == 'stepper')? 'primary': ''">
        Stepper
      </button>
      <button mat-raised-button (click)="selectedGroup='position'; actionData['appType']='position'" [color]="(selectedGroup == 'position')? 'primary': ''">
        Position
      </button>
      <button mat-raised-button (click)="selectedGroup='special'; actionData['appType'] = 'special'" [color]="(selectedGroup == 'special')? 'primary': ''">
        Special
      </button>
      <button mat-raised-button (click)="selectedGroup='message_api'; actionData['appType'] = 'message_api'" [color]="(selectedGroup == 'message_api')? 'primary': ''">
        Task Settings
      </button>
    </div>
    <!-- / ROW WITH FILTER BUTTONS -->

    <div fxLayout="row">
      <!-- COLUMN WITH APPLICATION BUTTONS -->
      <div fxFlex fxLayout="column">
        <div *ngFor="let app of apps; let j=index">
          <div *ngIf="app.group.toString() == selectedGroup">
          <button style="margin: 8px;" (click)="actionData.application=app.app; actionData.fuse2App=app.fuse2App;actionData.applicationName=app.name;editApp=false; addTask(app)"
            mat-raised-button [disabled]="loading" [color]="(actionData['application'] == app.app)? 'primary': ''">
            {{ app.name }}
          </button>
        </div>
        </div>
      </div>
      <!-- / COLUMN WITH APPLICATION BUTTONS -->

      <!-- COLUMN WITH APPLICATION SETTINGS -->
      <div fxFlex fxLayout="column" class="mx-20">
        <mat-form-field class="mx-4">
          <input placeholder="Application" [(ngModel)]="actionData.application" matInput required>
        </mat-form-field>
        <mat-form-field class="mx-4">
          <input placeholder="ng5 Application" [(ngModel)]="actionData.fuse2App" matInput required>
        </mat-form-field>
        <p style="font-weight: 600;">Optional Params</p>
        <textarea style="border: 1px solid #d7d5d5;" [(ngModel)]="actionData.params.json" id="" cols="30" rows="10">{{ actionData.actionParams }}</textarea>
        <div>
          <mat-checkbox>Show user on postbook</mat-checkbox>
        </div>
      </div>
      <!-- / COLUMN WITH APPLICATION SETTINGS -->
    </div>
    </div>
</div>