<div id="login" fxLayout="row" fxLayoutAlign="start">

    <div id="login-intro" fxFlex fxHide fxShow.gt-xs>
  
    </div>
  
    <div id="login-form-wrapper" fusePerfectScrollbar *fuseIfOnDom [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">
  
      
          <div>
              <img style="width: 352px; margin: 24px;" src="assets/images/header-logo.jpg" alt="header logo">
          </div>
          
        <div id="login-form">
  
            <div fxLayout="row" class="mb-24" fxLayoutGap="10px" fxLayoutAlign="center space-around">
                <!-- <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang);">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <img class="flag mr-16" [src]="'assets/images/flags/'+lang.flag+'.png'">
                    <span class="iso">{{lang.title}}</span>
                </div>
            </button> -->
            <mat-select (selectionChange)="setLanguage(selectedLangCode)" [(ngModel)]="selectedLangCode" class="w-100-p" placeholder="{{ 'auth.select_language' | translate }}">
                <mat-option *ngFor="let lang of languages" [value]="lang.code"  >
                  {{lang.title}}
                </mat-option>
              </mat-select>
            </div>
  
            <div class="title" style="text-align: center;">{{ 'auth.set_your_password' | translate }}</div>
            
            <div class="happ-form">
  
                <mat-form-field>
                  <input matInput [(ngModel)]="email" placeholder="{{ 'auth.email' | translate }}" >                
                </mat-form-field>
  
                <div class="remember-forgot-password warn-500-fg" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" *ngIf="errMess">
                    {{ errMess }}
                </div>
  
                <button mat-raised-button color="accent" (click)="sendEmail()" class="submit-button">
                    {{ 'auth.send_request' | translate }}
                </button>
              </div>
  
              <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">
                    {{ 'auth.already_have_account' | translate }} ?
                </span>
                <a class="link" [routerLink]="'/login'">
                  {{ 'auth.login' | translate }}
                </a>
            </div>
              <div>
                  <img src="assets/images/footer-logo.jpg" alt="footer logo">
              </div>
        </div>
    </div>
    <p style="position: fixed;bottom: 0px;right: 150px;font-size: 18px; color: rgb(3,155,229)"> {{ version }} </p>
  </div>