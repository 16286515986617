<div style="background: #f6f6f6;" class="m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <button class="mr-12" (click)="dialogRef.close(null)" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
      <span class="title dialog-title"> {{ 'workspace.template_selection' | translate }} </span>
      <span fxFlex></span>
      <button class="mr-12" (click)="saveData()" mat-icon-button>
          <mat-icon>check</mat-icon>
        </button>
    </div>
  </div>
  <div fxLayout="column">
    <div fxLayout="row" class="happ-toolbar-simple">
      
      <span fxFlex></span>
      <div style="margin: auto;">
        <button mat-raised-button (click)="addNewTemplate()">
          {{ 'workspace.add_template' | translate }}      
        </button>
      </div>
    </div>
    <div>
        <div class="p-8" fxLayout="column">
            <div class="grey-100-bg pl-12" fxLayout="row">
              <p fxFlex="50"> Name (isActive) </p>
              <!-- <p fxFlex="30">
                keyName=value
              </p> -->
              <div class="pr-20" fxFlex>
                <span fxFlex></span>
                <p>Action</p>
              </div>
            </div>
            <div style="border: 1px solid #ccc4c4; border-top: 0px;" class="py-4"
              *ngFor="let template of templateList; let i=index" fxLayout="row">
              <div>
                <button (click)="delete(i)" mat-icon-button aria-label="Example icon-button with a heart icon">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
              <p class="pl-12" fxFlex="50"> {{ template.title }} </p>
              <span fxFlex></span>
              <div>
                <div style="margin: auto; " class="pr-4 ml-16" fxFlex>
                  <!-- <span fxFlex></span> -->
                  <div>
                    <button class="mr-8" mat-raised-button (click)="edit(template)">Edit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  </div>