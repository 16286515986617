import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var FieldOptionUpdateDialogService = /** @class */ (function () {
    function FieldOptionUpdateDialogService() {
        this.subject = new Subject();
    }
    FieldOptionUpdateDialogService.prototype.sendNotify = function (formData) {
        this.subject.next(formData);
    };
    FieldOptionUpdateDialogService.prototype.getUpdate = function () {
        return this.subject.asObservable();
    };
    FieldOptionUpdateDialogService.ngInjectableDef = i0.defineInjectable({ factory: function FieldOptionUpdateDialogService_Factory() { return new FieldOptionUpdateDialogService(); }, token: FieldOptionUpdateDialogService, providedIn: "root" });
    return FieldOptionUpdateDialogService;
}());
export { FieldOptionUpdateDialogService };
