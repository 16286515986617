import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { OfficeListPopupComponent } from 'app/main/content/apps/office-list-popup/office-list-popup.component';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';
import { ProfileHelperService } from 'app/main/content/services/profile-helper/profile-helper.service';

@Component({
  selector: 'app-cal-office-selection',
  templateUrl: './cal-office-selection.component.html',
  styleUrls: ['./cal-office-selection.component.scss']
})
export class CalOfficeSelectionComponent implements OnInit {
  
  @Input() field;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() parentId;
  @Output() parentIdChange: EventEmitter<Object> = new EventEmitter();
  @Output() updateRecord: EventEmitter<any> = new EventEmitter();
  @Input() translateData: any;
  @Input() model;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Input() modelName;
  @Output() modelNameChange: EventEmitter<Object> = new EventEmitter();
  @Input() hideTitle: boolean;
  @Input() builder;
  @Input() formData: any;
  @Output() valChange: EventEmitter<Object> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onError: EventEmitter<Object> = new EventEmitter();
  
  constructor(
    public dialog: MatDialog,
    private officeService: OfficeHelpersService,
    private profileService: ProfileHelperService
  ) { }

  ngOnInit() {
    this.getDefault()
    .catch(err => console.error(err));
  }

  async getDefault() {
    if (!this.model) {
      if (window.location.pathname.includes("/cp")) {
        const profile = await this.profileService.getLocalProfileData();
        this.model = profile.privateAreaOfficeId;
        this.modelName = "global.private_area";

        this.modelChange.emit(this.model);
        this.modelNameChange.emit(this.modelName);

      } else {
        const officeDoc = await this.officeService.getLocalOfficeData();
        this.model = officeDoc._id;
        this.modelName = officeDoc.name;

        this.modelChange.emit(this.model);
        this.modelNameChange.emit(this.modelName);
      }
    }
  }

  selectOffice(field: any) {
    const temp = this.dialog.open(OfficeListPopupComponent, {
      width: '100%',
      height: '100%',
      restoreFocus: false,
      panelClass: 'happ-form-builder-dialog',
      data: { privateArea: true, calView: true }
    }); 
    temp.afterClosed()
      .subscribe((response) => {
        console.log("response = ", response);
        if (response) {
          this.model = response._id;
          this.modelName = response.name;
          this.modelChange.emit(this.model);
          this.modelNameChange.emit(this.modelName);

        }
      });
  }

}
