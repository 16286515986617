import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatTabChangeEvent, MAT_DIALOG_DATA } from '@angular/material';
import { ProfileHelperService } from 'app/main/content/services/profile-helper/profile-helper.service';
import { SocketService } from 'app/main/content/services/socket/socket.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-voip-call',
  templateUrl: './voip-call.component.html',
  styleUrls: ['./voip-call.component.scss'],
  encapsulation: 2
})
export class VoipCallComponent implements OnInit {

  recordData = {
    category: 'normal',
    reportData: ''
  };
  oldData;
  loading = false;
  selectedIndex = 0;
  showForm = true;
  isOperator = false;
  stateToShow = 'not_in_call';
  allStatus = [
    {
        heading: 'popup.waiting_calls',
        length: 0,
        currentStates: ['callstart'],
        isEnable: false,
        currentCalls: [],
        class: 'waiting',
        showOpenButton: false,
    },
    {
        heading: 'popup.incoming_calls',
        length: 0,
        currentStates: ['callringing'],
        isEnable: true,
        currentCalls: [],
        class: 'incoming',
        showOpenButton: true,
    },
    {
        heading: 'popup.ongoing_calls',
        length: 0,
        currentStates: ['callanswer'],
        isEnable: true,
        currentCalls: [],
        class: 'ongoing',
        showOpenButton: true,
    },
    // {
    //     heading: 'popup.ended_calls',
    //     length: 0,
    //     currentStates: ['callend'],
    //     isEnable: true,
    //     currentCalls: [],
    //     class: 'ended',
    //     showOpenButton: false,
    // },
  ];

  othersList = [];
  mineList = [];
  // items = [
  //   {
  //     title: 'Spam',
  //     key: 'spam'
  //   },
  //   {
  //     title: 'Normal',
  //     key: 'normal'
  //   }
  // ];

  email;

  constructor(
    public dialogRef: MatDialogRef<VoipCallComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private socketService: SocketService,
    private cdr: ChangeDetectorRef,

  ) { }

  ngOnInit() {
    let profileData: any = localStorage.getItem('ngStorage-profile');
    if (profileData) {
      profileData = JSON.parse(profileData);
      this.email = profileData.email;
    }
    this.checkOperator();
    this.reporting('recordJoined');
    this.data.script = this.data.script || 'No Script Found !';
    this.socketService.onCallEvent.subscribe((data) => {
      if (data.eventType) {
        this.checkExistingOngoingCall();
        this.checkOperator();
        const index = _.findIndex(this.socketService.callsList, { callId: this.data.callId });
        if (index !== -1) {
          const temp = this.socketService.callsList[index];
          temp.helperInst = this.data.helperInst;
          temp.allPopupCalls = this.data.allPopupCalls;
          this.getRecordData();
          this.data = temp;
          this.checkCallState();
          if (this.data && this.data.users && this.data.users.length) {
            // this.showForm = true;
          }
        }
      }
  });
    this.getRecordData();
  }

  checkExistingOngoingCall() {
    this.mineList = [];
    this.othersList = [];
    this.allStatus = this.allStatus.map((ele) => {
        ele.currentCalls = this.socketService.callsList.filter((ele2) => (ele.currentStates.includes(ele2.callState)));
        ele.length = ele.currentCalls.length;
        return ele;
    });
    this.socketService.callsList.forEach((ele) => {
      if (ele && ele.operator && ele.operator.email && ele.operator.email === this.email) {
        this.mineList.push(ele);
      } else if (ele && ele.ringingOn && ele.ringingOn.email && ele.ringingOn.email === this.email) {
        this.mineList.push(ele);
      } else {
        this.othersList.push(ele);
      }
    });
    this.mineList = this.transformSort(this.mineList, 'startTime');
    this.othersList = this.transformSort(this.othersList, 'startTime');
    this.checkCallState();
    const index = _.findIndex(this.socketService.callsList, { callId: this.data.callId });
    console.log("switcihgn data - ", this.socketService.callsList);
    if (index === -1 && this.socketService.callsList[0]) {
      this.data = this.socketService.callsList[0];
    }
  }

  transformSort(array: any[], field: string): any[] {
    if (!array || array && array.length === 0) {
    return [];
    }
    array.sort((a: any, b: any) => {
      if (a && b && a[field] < b[field]) {
        return -1;
      } else if (a && b && a[field] > b[field]) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }

  checkCallState() {
    if (this.data && this.data.operator && this.data.operator.email && this.data.operator.email === this.email) {
      this.stateToShow = 'in_call';
    } else if (this.data && this.data.operator && this.data.operator.email && this.data.operator.email !== this.email) {
      this.stateToShow = 'not_in_call';
    } else if (this.data && this.data.ringingOn && this.data.ringingOn.email && this.data.ringingOn.email === this.email) {
      this.stateToShow = 'ringing_call'
    }
  }

  checkOperator() {
    this.isOperator = false;
    if (this.data.operator && this.data.operator.email && this.email && this.data.operator.email === this.email) {
      this.isOperator = true;
      this.showForm = true;
    } else if (this.data.ringingOn && this.data.ringingOn.email && this.email && this.data.ringingOn.email === this.email) {
      this.isOperator = true;
      this.showForm = true;
    }
  }

  async getRecordData() {
    try {
      if (this.data.recId) {
        this.loading = true;
        this.recordData = await this.data.helperInst.makeSharedServiceRequest('get', this.data.ownerOfficeId, `api/shared-service/record/${this.data.recId}`);
        this.oldData = JSON.stringify(this.recordData);
        this.loading = false;
      }
    } catch (error) {
      this.loading = false;
    }
  }

  reporting(type: 'recordJoined' | 'recordLeaved') {
    let officeDoc: any = localStorage.getItem('ngStorage-selectedOffice');
    if (officeDoc) {
      officeDoc = JSON.parse(officeDoc);
      this.socketService.sendSocketEvent('phoneNumber', { 
        phoneNumber: this.data.to, recId: this.data.recId, type: type, officeId: officeDoc._id, callId: this.data.callId });
      this.showForm = true;
      this.cdr.detectChanges();
    }
  }

  close() {
    this.reporting('recordLeaved');
    this.dialogRef.close();
  }

  saveSpam() {
    this.recordData.category = 'spam';
    this.recordData.reportData = 'spam';
    this.saveData();
  }

  async saveData() {
    try {
      if (!this.oldData || (this.oldData && this.oldData.length === JSON.stringify(this.recordData).length)) {
        return;
      } else {
        this.checkOperator();
        if (!this.isOperator) {
          this.data.helperInst.openConfirmPopUp('popup.confirm_not_operator')
          .subscribe(async (data) => {
            if (data) {
              await this.data.helperInst.makeSharedServiceRequest('put', this.data.ownerOfficeId, `api/shared-service/record/${this.data.recId}`, this.recordData);
              this.data.helperInst.showInfo('Record updated', 'Success', 'success');
            }
          })
        } else {
          await this.data.helperInst.makeSharedServiceRequest('put', this.data.ownerOfficeId, `api/shared-service/record/${this.data.recId}`, this.recordData);
          this.data.helperInst.showInfo('Record updated', 'Success', 'success');
        }
      }
    } catch (error) {
      this.data.helperInst.showInfo(error, 'Error', 'error');
    }
  }


  tabChanged(item: MatTabChangeEvent) {
    if (this.data.allPopupCalls && this.data.allPopupCalls[item.index]) {

      const callId = this.data.allPopupCalls[item.index].callId;
      const index = _.findIndex(this.socketService.callsList, { callId });
      if (index !== -1) {
        this.saveData();
        const temp = this.socketService.callsList[index];
        temp.helperInst = this.data.helperInst;
        temp.allPopupCalls = this.data.allPopupCalls;
        this.data = temp;
        this.checkCallState();
        this.checkOperator();
        this.getRecordData();
      }
      console.log("tab changed", item);
    }
  }
}
