<div style="position: relative;" fxLayout="column" *ngIf="!field.hide || builder">
    <div fxLayout="row" *ngIf="!field.defaultEdit && !errorMessage && !builder && field.showEdit"
        (click)="field.defaultEdit=!field.defaultEdit">
        <button class="mr-4" mat-icon-button>
            <mat-icon>edit</mat-icon>
        </button>
        <p class="font-size-18 mt-12"> {{ (model) | date: 'yyyy-MM-dd HH:mm' }} </p>
    </div>
    <div fxLayout="row wrap" *ngIf="(field.defaultEdit || errorMessage) || builder || !field.showEdit">
        <mat-form-field [ngClass]="{'happ-small-form-field-outline': field.smallField}" d floatLabel="always" appearance="outline"
            style="width: 180px;">
            <mat-label> {{ field.title | translate }} </mat-label>
            <input matInput [matDatepicker]="picker" (focus)="picker.open()" [placeholder]="field.title | translate" [disabled]="field.disable"
                (dateChange)="ondateChange()" [hidden]="field.hidden" [(ngModel)]="date">
            <!-- <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
            </mat-datepicker-toggle> -->
            <mat-datepicker #picker></mat-datepicker>
            <div class="error-message" *ngIf="errorMessage"> {{ errorMessage | translate  }} </div>
        </mat-form-field>
        <mat-form-field color="accent" [ngClass]="{'ml-8': true, 'happ-small-form-field-outline': field.smallField}"
            floatLabel="always" appearance="outline" *ngIf="!field.timeInInput">
            <mat-label> Select Time </mat-label>
            <mat-select (selectionChange)="ondateChange()" [disabled]="field.disable" [(ngModel)]="time"
                placeholder="Select Time">
                <mat-option *ngFor="let time of timeSlots" [value]="time"> {{time}} </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field color="accent" [ngClass]="{'ml-8': true, 'happ-small-form-field-outline': field.smallField}"
            floatLabel="always" appearance="outline" *ngIf="field.timeInInput" style="width: 100px; min-width: 100px;">
            <mat-label> Select Time </mat-label>
            <input type="time" matInput (keyup)="ondateChange()" *ngIf="field.timeInInput" [(ngModel)]="time"
                placeholder="Select Time">
        </mat-form-field>
    </div>
    <div class="happ-field-description" *ngIf="field.allow_description && field.description"> {{ field.description }} </div>
</div>