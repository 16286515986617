<div style="overflow: auto; max-height: 100vh;" class="dialog-content-wrapper">
    <mat-toolbar style="background: #f6f6f6;" matDialogTitle class="m-0">
      <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title"> {{ 'ct.create_branch' | translate }} </span>
        <span fxFlex></span>
        <button (click)="dialogRef.close(null)" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-toolbar>
  
    <div class="p-24" style="overflow: auto;" fxLayout="column">

      <p class="font-size-20">
        <!-- {{ 'ct.branch_name' | translate }}:  -->
        {{ allData.branchName }}
      </p>

      <div fxLayoutAlign="center center" *ngIf="data && data.otherInfo">
        <button mat-raised-button (click)="deleteBranch()" color="warn">{{ 'ct.delete' | translate }}</button>
      </div>

      <div *ngIf="data && !data.otherInfo">
        <div class="mb-12" fxLayoutAlign="space-around center" fxLayout="row wrap">
          <div fxLayout="row" fxFlex="100">
            <p style="margin: auto;" class="ml-4 mr-16 font-size-18">
              {{ 'ct.task_type' | translate }}
            </p>
            <div fxLayout="row wrap">
              <div>
                <button mat-raised-button class="mr-4" (click)="allData.type='feature'" [color]="(allData.type==='feature')? 'primary' : ''" >
                  {{ 'ct.feature' | translate }}              
                </button>
              </div>
              <div>
                <button mat-raised-button class="mr-4" (click)="allData.type='hotfix'" [color]="(allData.type==='hotfix')? 'primary' : ''" >
                  {{ 'ct.hotfix' | translate }}              
                </button>
              </div>
              <div>
                <button mat-raised-button class="mr-4" (click)="allData.type='patch'" [color]="(allData.type==='patch')? 'primary' : ''" >
                  {{ 'ct.patch' | translate }}
                </button>
              </div>
              <div>
                <button mat-raised-button class="mr-4" (click)="allData.type='release'" [color]="(allData.type==='release')? 'primary' : ''" >
                  {{ 'ct.release' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-12" fxLayoutAlign="space-around center" fxLayout="row wrap">
          <div fxLayout="row" fxFlex="100">
            <p style="margin: auto;" class="ml-4 mr-16 font-size-18">
              {{ 'ct.select_project' | translate }}
            </p>
            <div fxLayout="row wrap">
              <div>
                <button mat-raised-button class="mr-4" (click)="selectProject('12268684')" [color]="(allData && allData.project && allData.project.includes('12268684'))? 'primary' : ''" >
                    p24-aot-front           
                </button>
              </div>
              <div>
                <button mat-raised-button class="mr-4" (click)="selectProject('3948490')" [color]="(allData && allData.project && allData.project.includes('3948490'))? 'primary' : ''" >
                    p24backend        
                </button>
              </div>
              <div>
                <button mat-raised-button class="mr-4" (click)="selectProject('11345552')" [color]="(allData && allData.project && allData.project.includes('11345552'))? 'primary' : ''" >
                  test
                </button>
              </div>
            </div>
          </div>
        </div>
    
        <div class="pb-20" fxLayoutAlign="space-around center" fxLayout="row wrap">
          <div fxLayout="row" fxFlex="100">
            <mat-form-field color="accent" [ngClass]="{'w-100-p': true, 'happ-small-form-field-outline': field.smallField}" floatLabel="always" appearance="outline" fxFlex>
              <mat-label> {{ 'ct.human_readable_name' | translate }} </mat-label>
              <input #temp (keyup)="keyChanged($event, temp)" type="text" aria-label="human_readable_name" autofocus="true" matInput [(ngModel)]="allData.humanReadablePart">
            </mat-form-field>
          </div>
        </div>
      </div>

      <p *ngIf="errorMsg" style="color: brown;" class="ml-8">
        {{ errorMsg }}
      </p>

    </div>
    <div class="p-24 pt-20 pb-20">
      <span fxFlex></span>
      <button (click)="dialogRef.close(null)" aria-label="custom_app_cancel_button" mat-button
        class="mr-4">{{ 'workspace.cancel' | translate }}</button>
      <button mat-raised-button [disabled]="loading || !allData.project" aria-label="custom_app_create_button" (click)="createBranch()"
        color="primary">{{ 'workspace.save' | translate }}</button>
    </div>
  </div>