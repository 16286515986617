import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
var CtServiceSelectionComponent = /** @class */ (function () {
    function CtServiceSelectionComponent(_helperService, _translationLoader) {
        this._helperService = _helperService;
        this._translationLoader = _translationLoader;
        this.modelChange = new EventEmitter();
        this.onError = new EventEmitter();
        this.serviceList = [];
        this.staticSericeList = [];
    }
    CtServiceSelectionComponent.prototype.ngOnInit = function () {
        this.getData();
        this.checkError();
    };
    CtServiceSelectionComponent.prototype.getData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this._helperService.getRequest("api/shared-service/available-services/client")];
                    case 1:
                        _a.serviceList = _b.sent();
                        this.staticSericeList = this.serviceList;
                        return [2 /*return*/];
                }
            });
        });
    };
    CtServiceSelectionComponent.prototype.select = function (item) {
        var id = (item && item.serviceData && item.serviceData._id) || item._id;
        if (this.field.isMultiSelect) {
            this.model = this.model || [];
            if (this.model.includes(id)) {
                this.model.splice(this.model.indexOf(id), 1);
            }
            else {
                this.model.push(id);
            }
            this.modelChange.next(this.model);
        }
        else {
            this.model = id;
            this.modelChange.next(id);
        }
        this.checkError();
    };
    CtServiceSelectionComponent.prototype.checkError = function () {
        if (!this.model) {
            this.error = true;
            this.errorMessage = 'ct.field_is_required';
            this.onError.emit({
                error: true,
                label: this.field['label']
            });
        }
        else {
            this.error = false;
            this.errorMessage = '';
            this.onError.emit({
                error: false,
                label: this.field['label']
            });
        }
    };
    return CtServiceSelectionComponent;
}());
export { CtServiceSelectionComponent };
