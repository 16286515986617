<ng-container *ngIf="field.description">
  <div [class]="field.class + ' happ-html-preview-area'" [innerHTML]="parsedHtml | htmlWithStyle">
  </div>
</ng-container>
<ng-container *ngIf="!field.description">
<div class="mb-20" style="position: relative;">

    <p *ngIf="editModel" class="happ-field-title">{{ field.title | translate }} </p>
    
    <p class="happ-placeholder" *ngIf="!editModel">{{ field.title | translate }}</p>
    <div *ngIf="!editModel" (click)="(field.showdisablecontroltype=='true' || field.disable) ? null : editModel=true" class="happ-html-preview-area" [innerHTML]="parsedHtml | htmlWithStyle">
    </div>
    <ck-editor *ngIf="editModel" name="editor1" class="w-100-p" [config]="config" (blur)="checkForEdit()" (change)="refreshData()" [(ngModel)]="model"  skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>
  </div>
</ng-container>