import { Injectable } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { OfficeStore } from '../../state/officeState/office.store';
import { HelpersService } from '../helpers/helpers.service';
import { SocketService } from './socket.service';

@Injectable()
export class OfficeSocketService {
    constructor(
        private officeStore: OfficeStore,
        private helperService: HelpersService,
        private fuseConfig2: FuseConfigService,
        private socketService: SocketService
    ) {
        this.socketService.onSocketEventTriggered.subscribe((data) => {
            if (data && data.eventType === 'officeEvents') {
                this.onEvent(data.res);
            }
        });
    }

    onEvent(res: any) {
        switch (res.operationType) {
            case 'update-office': {
                if (res.officeDoc) {
                    this.updateDataForOfficeUpdate(res.officeDoc);
                }
            }
        }
    }

    updateDataForOfficeUpdate(officeDoc: any, url?: any) {
        localStorage.setItem('ngStorage-selectedOffice', JSON.stringify(officeDoc));
        this.officeStore.update(officeDoc);
        this.helperService.getFuseConfig()
          .then((fuseSettings) => {
              if (fuseSettings && Object.keys(fuseSettings).length) {
                  this.fuseConfig2.setConfig(fuseSettings);
              } else {
                  this.fuseConfig2.resetToDefaults();
              }
          }).catch(err => {
              console.info(err);
              this.fuseConfig2.resetToDefaults();
          });
      }
}
