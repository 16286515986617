import * as tslib_1 from "tslib";
import { Query } from '@datorama/akita';
import { WorkspaceStore } from './workspace.store';
import * as i0 from "@angular/core";
import * as i1 from "./workspace.store";
var WorkspaceQuery = /** @class */ (function (_super) {
    tslib_1.__extends(WorkspaceQuery, _super);
    function WorkspaceQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        _this.workspaceDoc = _this.select(function (state) { return state.workspaceData; });
        _this.selectedStep = _this.select(function (state) { return state.selectedStep; });
        _this.isWorkspaceAdmin = _this.select(function (state) { return state.isWorkspaceAdmin; });
        return _this;
    }
    WorkspaceQuery.ngInjectableDef = i0.defineInjectable({ factory: function WorkspaceQuery_Factory() { return new WorkspaceQuery(i0.inject(i1.WorkspaceStore)); }, token: WorkspaceQuery, providedIn: "root" });
    return WorkspaceQuery;
}(Query));
export { WorkspaceQuery };
