/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card-actions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i10 from "@angular/material/icon";
import * as i11 from "@ngx-translate/core";
import * as i12 from "./card-actions-list/card-actions-list.component.ngfactory";
import * as i13 from "./card-actions-list/card-actions-list.component";
import * as i14 from "./card-actions.component";
import * as i15 from "@angular/material/dialog";
var styles_CardActionsComponent = [i0.styles];
var RenderType_CardActionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardActionsComponent, data: {} });
export { RenderType_CardActionsComponent as RenderType_CardActionsComponent };
export function View_CardActionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "m-0"], ["style", "background: #f6f6f6;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["fxFlex", ""], ["fxLayout", "row"], ["fxLayoutAlign", "space-between center"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), i1.ɵdid(4, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "button", [["class", "mr-12"], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close(null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i9.View_MatIcon_0, i9.RenderType_MatIcon)), i1.ɵdid(8, 9158656, null, 0, i10.MatIcon, [i1.ElementRef, i10.MatIconRegistry, [8, null], [2, i10.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "span", [["class", "title dialog-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, [" ", " "])), i1.ɵpid(131072, i11.TranslatePipe, [i11.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [["fxFlex", ""]], null, null, null, null, null)), i1.ɵdid(14, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 4, "button", [["class", "mr-12"], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveData() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(16, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(17, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i9.View_MatIcon_0, i9.RenderType_MatIcon)), i1.ɵdid(18, 9158656, null, 0, i10.MatIcon, [i1.ElementRef, i10.MatIconRegistry, [8, null], [2, i10.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["check"])), (_l()(), i1.ɵeld(20, 0, null, null, 13, "div", [["fxLayout", "column"]], null, null, null, null, null)), i1.ɵdid(21, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 8, "div", [["class", "happ-toolbar-simple"], ["fxLayout", "row"]], null, null, null, null, null)), i1.ɵdid(23, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(24, 0, null, null, 1, "span", [["fxFlex", ""]], null, null, null, null, null)), i1.ɵdid(25, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(26, 0, null, null, 4, "div", [["style", "margin: auto;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 3, "button", [["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addNewAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(28, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(29, 0, [" ", " "])), i1.ɵpid(131072, i11.TranslatePipe, [i11.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(31, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 1, "app-card-actions-list", [], null, [[null, "deleteAction"], [null, "editAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("deleteAction" === en)) {
        var pd_0 = (_co.deleteAction($event) !== false);
        ad = (pd_0 && ad);
    } if (("editAction" === en)) {
        var pd_1 = (_co.editAction($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i12.View_CardActionsListComponent_0, i12.RenderType_CardActionsListComponent)), i1.ɵdid(33, 114688, null, 0, i13.CardActionsListComponent, [], { cardActionList: [0, "cardActionList"] }, { deleteAction: "deleteAction", editAction: "editAction" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "space-between center"; _ck(_v, 3, 0, currVal_1); var currVal_2 = ""; _ck(_v, 4, 0, currVal_2); _ck(_v, 8, 0); var currVal_8 = ""; _ck(_v, 14, 0, currVal_8); _ck(_v, 18, 0); var currVal_13 = "column"; _ck(_v, 21, 0, currVal_13); var currVal_14 = "row"; _ck(_v, 23, 0, currVal_14); var currVal_15 = ""; _ck(_v, 25, 0, currVal_15); var currVal_19 = _co.cardActions; _ck(_v, 33, 0, currVal_19); }, function (_ck, _v) { var currVal_3 = (i1.ɵnov(_v, 6).disabled || null); var currVal_4 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵnov(_v, 8).inline; var currVal_6 = (((i1.ɵnov(_v, 8).color !== "primary") && (i1.ɵnov(_v, 8).color !== "accent")) && (i1.ɵnov(_v, 8).color !== "warn")); _ck(_v, 7, 0, currVal_5, currVal_6); var currVal_7 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("workspace.card_actions")); _ck(_v, 11, 0, currVal_7); var currVal_9 = (i1.ɵnov(_v, 16).disabled || null); var currVal_10 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_9, currVal_10); var currVal_11 = i1.ɵnov(_v, 18).inline; var currVal_12 = (((i1.ɵnov(_v, 18).color !== "primary") && (i1.ɵnov(_v, 18).color !== "accent")) && (i1.ɵnov(_v, 18).color !== "warn")); _ck(_v, 17, 0, currVal_11, currVal_12); var currVal_16 = (i1.ɵnov(_v, 28).disabled || null); var currVal_17 = (i1.ɵnov(_v, 28)._animationMode === "NoopAnimations"); _ck(_v, 27, 0, currVal_16, currVal_17); var currVal_18 = i1.ɵunv(_v, 29, 0, i1.ɵnov(_v, 30).transform("workspace.add_action")); _ck(_v, 29, 0, currVal_18); }); }
export function View_CardActionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-actions", [], null, null, null, View_CardActionsComponent_0, RenderType_CardActionsComponent)), i1.ɵdid(1, 114688, null, 0, i14.CardActionsComponent, [i15.MatDialog, i15.MatDialogRef, i15.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardActionsComponentNgFactory = i1.ɵccf("app-card-actions", i14.CardActionsComponent, View_CardActionsComponent_Host_0, {}, {}, []);
export { CardActionsComponentNgFactory as CardActionsComponentNgFactory };
