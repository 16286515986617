import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { SetDefaultLanguageService } from 'app/main/content/services/set-default-language/set-default-language.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { LanguagesModel } from 'app/main/content/models/languages.model';
import { environment } from 'environments/environment';
import { version } from 'environments/version';
import { HttpClient } from '@angular/common/http';

@Component({
    selector     : 'forgot-password',
    templateUrl  : './forgot-password.component.html',
    styleUrls    : ['./forgot-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ForgotPasswordComponent implements OnInit
{
    version: any;
    languages: any;
    selectedLanguage: any;
    email;
    errMess: string;
    selectedLangCode;
  backUrl: any;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private setDefaultLanguageService: SetDefaultLanguageService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translate: TranslateService,
    private helperService: HelpersService,
    private _fuseConfigService: FuseConfigService,
  ) {
    
    this.backUrl = this.route.snapshot.queryParams.backUrl;
    // Configure the layout
    this._fuseConfigService.config = {
        layout: {
            navbar   : {
                hidden: true
            },
            toolbar  : {
                hidden: true
            },
            footer   : {
                hidden: true
            },
            sidepanel: {
                hidden: true
            }
        }
    };

    this.version = version;
    this.languages = LanguagesModel;
    this.helperService.getDefaultLangs()
    .then((lang) => {
        this.languages = lang || [];
    }).catch(err => {
        console.info(err);
    });
    this.setDefaultLanguageService.setDefaultLanguage();

    this.selectedLanguage = this.languages[0];
    this.selectedLangCode = this.selectedLanguage['id'];

    if (localStorage.getItem('selectedLanguage')) {
        this.languages.forEach(lang => {
            if (lang.id === localStorage.getItem('selectedLanguage')) {
                this.selectedLanguage = lang;
          this.selectedLangCode = this.selectedLanguage['id'];

            }
        });
    }
    // this.translationLoader.loadTranslations(english, polish);
    this.helperService.getTrans('authentication')
      .then((data2) => {
        this.translationLoader.loadTranslationsV2(data2);
      }).catch(err => {
        console.info(err);
      });
   }

  ngOnInit(): void {
  }

  sendEmail(): void {
    try {
      const mailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.email && mailPattern.test(String(this.email).toLowerCase())) {
        this.http.post(`${environment.serverUrl}api/auth/forgot`, { email: this.email })
        .subscribe(data => {
          if (data) {
            this.snackBar.open('An Email has been send with further instructions.', 'Okay', {
              duration: 1500,
            });
            this.router.navigate([this.backUrl || '/login']);
          } else {
            this.snackBar.open('Some Error occured', 'Okay', {
              duration: 1500,
            });
          }
        }, err => {
          this.snackBar.open(err || 'Some Error occured', 'Okay', {
            duration: 1500,
          });
        });
      } else {
        this.errMess = 'Please enter valid email';
      }
    } catch (err) {
      this.snackBar.open(err || 'Some Error occured', 'Okay', {
        duration: 1500,
      });
    }
  }

  setLanguage(lang): void
  {
      this.languages.map((ele) => {
          if (lang === ele.id) {
              this.selectedLanguage = ele;
          }
      });
      // Set the selected language for toolbar
      // this.selectedLanguage = lang;

      localStorage.setItem('selectedLanguage', this.selectedLanguage.id);
      
      this.translate.setDefaultLang(this.selectedLanguage.id);
      this.translate.use(localStorage.getItem('selectedLanguage') || this.translate.getBrowserLang() || 'en');      
  }
}
