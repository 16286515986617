import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { HelpersService } from '../../../services/helpers/helpers.service';
import { md5 } from '../../authentication/md5';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AuthService } from '../../authentication/auth.service';
import { LanguagesInterface } from 'app/main/content/interfaces/languages.interface';
import { LanguagesModel } from 'app/main/content/models/languages.model';
import { SetDefaultLanguageService } from 'app/main/content/services/set-default-language/set-default-language.service';
import { TranslateService } from '@ngx-translate/core';
import { FuseConfigService } from '@fuse/services/config.service';

const ALL_SIDE_HIDE_LAYOUT = {
  layout: {
      navbar: {
          hidden: true
      },
      toolbar: {
          hidden: true
      },
      footer: {
          hidden: true
      },
      sidepanel: {
          hidden: true
      }
  }
};

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {

  
  @Input() userInvitationId: string;
  @Input() userInvitationModel: any;


  password;
  confirmPassword;
  errMess = 'setPassword.empty_password_error';
  email;
  loader: boolean;
  isSuccess: boolean;
  languages: Array<LanguagesInterface>;
  selectedLanguage: any;
  selectedLangCode: any;

  constructor(
    private http: HttpClient,
    private helperService: HelpersService,
    private translationLoader: FuseTranslationLoaderService,
    private authService: AuthService,
    private setDefaultLanguageService: SetDefaultLanguageService,
    private translate: TranslateService,
    private _fuseConfigService: FuseConfigService,

  ) { }

  ngOnInit() {
    this._fuseConfigService.config = ALL_SIDE_HIDE_LAYOUT;
    console.log("44444");
    this.authService.logOut();
    this.languages = LanguagesModel;

    this.selectedLanguage = this.languages[0];
    this.selectedLangCode = this.selectedLanguage['id'];
    if (localStorage.getItem('selectedLanguage')) {
        this.languages.forEach((lang, i) => {
            if (lang.id === localStorage.getItem('selectedLanguage')) {
                this.selectedLanguage = lang;
                this.selectedLangCode = this.selectedLanguage['id'];
            }
        });
    }

    this.setDefaultLanguageService.setDefaultLanguage();

        this.helperService.getDefaultLangs()
        .then(lang => {
            this.languages = lang || [];
            this.selectedLangCode = this.translate.currentLang;
        }).catch(err => {
            console.info(err);
        });
    
    this.logoutBefore();
    this.helperService.getTrans('authentication')
      .then(data2 => {
          this.translationLoader.loadTranslationsV2(data2);
      }).catch(err => {
          console.info(err);
      });
  }

  logoutBefore() {
    console.log("5555");
    this.authService.logOut();
  }
  
  checkPassword() {
    if (this.password && this.password.length <= 6) {
      this.errMess = 'setPassword.passowrd_must_be_six_digit';
    } else {
      this.errMess = null;
    }
  }

  checkConfirmPassword() {
    if (this.password !== this.confirmPassword) {
      this.errMess = 'setPassword.confirm_password_do_not_match';
    } else {
      this.errMess = null;
    }
  }

  setPassword() {
    const user = {
        password: md5(this.password)
    };
    if (this.password && this.password.length < 6) {
      this.errMess = 'Password must contain at least 6 letters';
      // this.helperService.openSnackBar('');
      alert('Password must contain at least 6 letters');
      return;
    } else {
      this.loader = true;
      this.http.post(`${environment.serverUrl}api/offices/user-invitation-un-auth/${this.userInvitationId}/set-password`, user)
      .subscribe((data) => {
        this.loader = false;
        this.isSuccess = true;
        this.helperService.openSnackBar('Password set success.');
      }, err => {
        this.loader = false;
        this.helperService.handleHttpError(err);
      });
    }
  }

  setLanguage(lang: any): void {
    this.languages.map(ele => {
        if (lang === ele.id) {
            this.selectedLanguage = ele;
        }
    });

    localStorage.setItem('selectedLanguage', this.selectedLanguage.id);
    this.selectedLangCode = this.selectedLanguage.id;
    this.translate.setDefaultLang(this.selectedLanguage.id);
    this.translate.use(localStorage.getItem('selectedLanguage') || this.translate.getBrowserLang() || 'en');
}
}
