// tslint:disable:indent
import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { OfficeState, OfficeStore } from './office.store';


@Injectable({ providedIn: 'root' })
export class OfficeQuery extends Query<OfficeState> {
	officeDoc = this.select((state) => state);
	officeCredit = this.select((state) => {
		if (state) {
			const c1 = state.promoCredits || 0;
			const c2 = state.purchasedCredits || 0;
			return c1 + c2;
		} else {
			return 0;
		}
	});

    constructor(protected store: OfficeStore) {
		super(store);
	}
}
