<div class="p-24">
    <h2 style="text-align: center;"> {{ data && data['heading'] }} </h2>
    <div fxLayoutAlign="center center" class="h-100-p w-100-p" fxLayout="column">
        <h3>
            {{ data && data['message'] }}
        </h3>
        <br>
        <br>
        <img src="/assets/userinfo.png" alt="userinfo">
    </div>
</div>