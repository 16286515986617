import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { MatDialogRef, MatTabChangeEvent } from '@angular/material';
import { SocketService } from 'app/main/content/services/socket/socket.service';
import * as _ from 'lodash';
var VoipCallComponent = /** @class */ (function () {
    function VoipCallComponent(dialogRef, data, socketService, cdr) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.socketService = socketService;
        this.cdr = cdr;
        this.recordData = {
            category: 'normal',
            reportData: ''
        };
        this.loading = false;
        this.selectedIndex = 0;
        this.showForm = true;
        this.isOperator = false;
        this.stateToShow = 'not_in_call';
        this.allStatus = [
            {
                heading: 'popup.waiting_calls',
                length: 0,
                currentStates: ['callstart'],
                isEnable: false,
                currentCalls: [],
                class: 'waiting',
                showOpenButton: false,
            },
            {
                heading: 'popup.incoming_calls',
                length: 0,
                currentStates: ['callringing'],
                isEnable: true,
                currentCalls: [],
                class: 'incoming',
                showOpenButton: true,
            },
            {
                heading: 'popup.ongoing_calls',
                length: 0,
                currentStates: ['callanswer'],
                isEnable: true,
                currentCalls: [],
                class: 'ongoing',
                showOpenButton: true,
            },
        ];
        this.othersList = [];
        this.mineList = [];
    }
    VoipCallComponent.prototype.ngOnInit = function () {
        var _this = this;
        var profileData = localStorage.getItem('ngStorage-profile');
        if (profileData) {
            profileData = JSON.parse(profileData);
            this.email = profileData.email;
        }
        this.checkOperator();
        this.reporting('recordJoined');
        this.data.script = this.data.script || 'No Script Found !';
        this.socketService.onCallEvent.subscribe(function (data) {
            if (data.eventType) {
                _this.checkExistingOngoingCall();
                _this.checkOperator();
                var index = _.findIndex(_this.socketService.callsList, { callId: _this.data.callId });
                if (index !== -1) {
                    var temp = _this.socketService.callsList[index];
                    temp.helperInst = _this.data.helperInst;
                    temp.allPopupCalls = _this.data.allPopupCalls;
                    _this.getRecordData();
                    _this.data = temp;
                    _this.checkCallState();
                    if (_this.data && _this.data.users && _this.data.users.length) {
                        // this.showForm = true;
                    }
                }
            }
        });
        this.getRecordData();
    };
    VoipCallComponent.prototype.checkExistingOngoingCall = function () {
        var _this = this;
        this.mineList = [];
        this.othersList = [];
        this.allStatus = this.allStatus.map(function (ele) {
            ele.currentCalls = _this.socketService.callsList.filter(function (ele2) { return (ele.currentStates.includes(ele2.callState)); });
            ele.length = ele.currentCalls.length;
            return ele;
        });
        this.socketService.callsList.forEach(function (ele) {
            if (ele && ele.operator && ele.operator.email && ele.operator.email === _this.email) {
                _this.mineList.push(ele);
            }
            else if (ele && ele.ringingOn && ele.ringingOn.email && ele.ringingOn.email === _this.email) {
                _this.mineList.push(ele);
            }
            else {
                _this.othersList.push(ele);
            }
        });
        this.mineList = this.transformSort(this.mineList, 'startTime');
        this.othersList = this.transformSort(this.othersList, 'startTime');
        this.checkCallState();
        var index = _.findIndex(this.socketService.callsList, { callId: this.data.callId });
        console.log("switcihgn data - ", this.socketService.callsList);
        if (index === -1 && this.socketService.callsList[0]) {
            this.data = this.socketService.callsList[0];
        }
    };
    VoipCallComponent.prototype.transformSort = function (array, field) {
        if (!array || array && array.length === 0) {
            return [];
        }
        array.sort(function (a, b) {
            if (a && b && a[field] < b[field]) {
                return -1;
            }
            else if (a && b && a[field] > b[field]) {
                return 1;
            }
            else {
                return 0;
            }
        });
        return array;
    };
    VoipCallComponent.prototype.checkCallState = function () {
        if (this.data && this.data.operator && this.data.operator.email && this.data.operator.email === this.email) {
            this.stateToShow = 'in_call';
        }
        else if (this.data && this.data.operator && this.data.operator.email && this.data.operator.email !== this.email) {
            this.stateToShow = 'not_in_call';
        }
        else if (this.data && this.data.ringingOn && this.data.ringingOn.email && this.data.ringingOn.email === this.email) {
            this.stateToShow = 'ringing_call';
        }
    };
    VoipCallComponent.prototype.checkOperator = function () {
        this.isOperator = false;
        if (this.data.operator && this.data.operator.email && this.email && this.data.operator.email === this.email) {
            this.isOperator = true;
            this.showForm = true;
        }
        else if (this.data.ringingOn && this.data.ringingOn.email && this.email && this.data.ringingOn.email === this.email) {
            this.isOperator = true;
            this.showForm = true;
        }
    };
    VoipCallComponent.prototype.getRecordData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, error_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, , 4]);
                        if (!this.data.recId) return [3 /*break*/, 2];
                        this.loading = true;
                        _a = this;
                        return [4 /*yield*/, this.data.helperInst.makeSharedServiceRequest('get', this.data.ownerOfficeId, "api/shared-service/record/" + this.data.recId)];
                    case 1:
                        _a.recordData = _b.sent();
                        this.oldData = JSON.stringify(this.recordData);
                        this.loading = false;
                        _b.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        error_1 = _b.sent();
                        this.loading = false;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    VoipCallComponent.prototype.reporting = function (type) {
        var officeDoc = localStorage.getItem('ngStorage-selectedOffice');
        if (officeDoc) {
            officeDoc = JSON.parse(officeDoc);
            this.socketService.sendSocketEvent('phoneNumber', {
                phoneNumber: this.data.to, recId: this.data.recId, type: type, officeId: officeDoc._id, callId: this.data.callId
            });
            this.showForm = true;
            this.cdr.detectChanges();
        }
    };
    VoipCallComponent.prototype.close = function () {
        this.reporting('recordLeaved');
        this.dialogRef.close();
    };
    VoipCallComponent.prototype.saveSpam = function () {
        this.recordData.category = 'spam';
        this.recordData.reportData = 'spam';
        this.saveData();
    };
    VoipCallComponent.prototype.saveData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_2;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!(!this.oldData || (this.oldData && this.oldData.length === JSON.stringify(this.recordData).length))) return [3 /*break*/, 1];
                        return [2 /*return*/];
                    case 1:
                        this.checkOperator();
                        if (!!this.isOperator) return [3 /*break*/, 2];
                        this.data.helperInst.openConfirmPopUp('popup.confirm_not_operator')
                            .subscribe(function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!data) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.data.helperInst.makeSharedServiceRequest('put', this.data.ownerOfficeId, "api/shared-service/record/" + this.data.recId, this.recordData)];
                                    case 1:
                                        _a.sent();
                                        this.data.helperInst.showInfo('Record updated', 'Success', 'success');
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.data.helperInst.makeSharedServiceRequest('put', this.data.ownerOfficeId, "api/shared-service/record/" + this.data.recId, this.recordData)];
                    case 3:
                        _a.sent();
                        this.data.helperInst.showInfo('Record updated', 'Success', 'success');
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        error_2 = _a.sent();
                        this.data.helperInst.showInfo(error_2, 'Error', 'error');
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    VoipCallComponent.prototype.tabChanged = function (item) {
        if (this.data.allPopupCalls && this.data.allPopupCalls[item.index]) {
            var callId = this.data.allPopupCalls[item.index].callId;
            var index = _.findIndex(this.socketService.callsList, { callId: callId });
            if (index !== -1) {
                this.saveData();
                var temp = this.socketService.callsList[index];
                temp.helperInst = this.data.helperInst;
                temp.allPopupCalls = this.data.allPopupCalls;
                this.data = temp;
                this.checkCallState();
                this.checkOperator();
                this.getRecordData();
            }
            console.log("tab changed", item);
        }
    };
    return VoipCallComponent;
}());
export { VoipCallComponent };
