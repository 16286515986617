/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./create-branch.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "@ngx-translate/core";
import * as i11 from "./create-branch.component";
import * as i12 from "@angular/material/dialog";
import * as i13 from "../../../services/helpers/helpers.service";
var styles_CreateBranchComponent = [i0.styles];
var RenderType_CreateBranchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CreateBranchComponent, data: {} });
export { RenderType_CreateBranchComponent as RenderType_CreateBranchComponent };
function View_CreateBranchComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "m-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 1, 0, currVal_0); }); }
export function View_CreateBranchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["fxLayout", "row wrap"], ["fxLayoutAlign", "space-between center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreateBranchComponent_1)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "button", [["class", "my-12 mb-20"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(7, 0, [" ", " "])), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row wrap"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "space-between center"; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.model; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = (i1.ɵnov(_v, 6).disabled || null); var currVal_4 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.field.title)); _ck(_v, 7, 0, currVal_5); }); }
export function View_CreateBranchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-create-branch", [], null, null, null, View_CreateBranchComponent_0, RenderType_CreateBranchComponent)), i1.ɵdid(1, 114688, null, 0, i11.CreateBranchComponent, [i12.MatDialog, i13.HelpersService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateBranchComponentNgFactory = i1.ɵccf("app-create-branch", i11.CreateBranchComponent, View_CreateBranchComponent_Host_0, { field: "field", model: "model", otherInfo: "otherInfo", regId: "regId", recId: "recId", signatura: "signatura", translateData: "translateData" }, { onFieldValueUpdated: "onFieldValueUpdated", modelChange: "modelChange", otherInfoChange: "otherInfoChange" }, []);
export { CreateBranchComponentNgFactory as CreateBranchComponentNgFactory };
