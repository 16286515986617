import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { ProcessStatusComponent } from './process-status.component';

@NgModule({
  declarations: [
  ProcessStatusComponent
],
  imports: [
    CommonModule,
    TranslateModule,
    FuseSharedModule
  ],
  entryComponents: [
    
  ],
  exports: [
    ProcessStatusComponent
  ]
})
export class ProcessStatusModule { }
