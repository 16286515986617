import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-client-order-payment',
  templateUrl: './client-order-payment.component.html',
  styleUrls: ['./client-order-payment.component.scss']
})
export class ClientOrderPaymentComponent implements OnInit {

  @Input() editNg5Service: any;
  @Input() staticProcessStatus: any;
  @Input() staticCommentField: any;
  @Output() updateDataFromCt: EventEmitter<any> = new EventEmitter();
  @Output() processUpdateRecord: EventEmitter<any> = new EventEmitter();

  @Input() translateData: any;

  orderDetail: any = {};
  buttons = [];
  loading: boolean;
  recordData: any = {};
  paymentList = [];
  currentLang: string;
  reqBody: any = {
    amount: 0,
    totalAmount: 0,
    paymentSource: 'online',
    paymentMethod: 'przelewy24',
    currencyShortCode: 'PLN',
    purpose: "Select service payment"
  };

  constructor(
    private _helperService: HelpersService,
    private translateService: TranslateService,

  ) { }

  ngOnInit() {
    this.recordData = this.editNg5Service.formData;
    this.currentLang = this.translateService.getDefaultLang();
    this.translateService.onLangChange.subscribe((data2: any) => {
      this.currentLang = data2;
    });
    this.getOrderDetail();
    this.getAllPayments();
    this.getProcessButtons();
  }

  async getProcessButtons() {
    this.buttons = await this._helperService.getRequest(`api/reg-process/${this.editNg5Service.formData['instanceId']}/getButtons`)
  }

  async getOrderDetail() {
    if (this.editNg5Service && this.editNg5Service.formData && this.editNg5Service.formData.activeOrderId) {
      this.orderDetail = await this._helperService.getRegisterRecordData(environment.ORDER_SETTINGS_INFO.regId, this.editNg5Service.formData.activeOrderId);
    }
  }

  async payOrder () {
    try {
      this.loading = true;
      const data: any = await this._helperService.postRequest(`api/payment/make-payment`, { 
        continueUrl: `?detail=${window.location.pathname}`,
        paymentType: "przelewy24",
        serviceId: this.editNg5Service.formData._id,
        ...this.reqBody,
        description: "Select service payment",
        currencyCode: 'PLN',
        products: []
       });
       console.log(data);
       if (data) {
        window.location.href = data;
       }
      this.loading = false;
    } catch (error) {
      this._helperService.showInfo(error, 'Error', 'error');
      this.loading = false;
    }
  }

  async getAllPayments() {
    try {
      this.paymentList = await this._helperService.getRequest(`api/payment/service-payment-list/${this.editNg5Service.formData._id}`);
    } catch (err) {
      console.log("err", err);
    }
  }

  cancelClick() {
    const button = this.buttons[0];
    const record = this.editNg5Service.formData || {};
    if (record.officeId && record['processId'] && record['instanceId'] && button && button.id) {
      console.log(button.loadingText);
      const loading = (button && button.loadingText && (button.loadingText[this.currentLang] || button.loadingText['en'])) || '';
      this.processUpdateRecord.emit({
        isUpdateRecord: !button.dont_update_on_transition,
        updatedData: record,
        regId: record.regId,
        initBehaviour: '',
        loadingText: button.loadingText,
        recId: record._id,
        buttonId: button.id,
        type: 'actButton',
        comment: '',
        navigateTo: `oneRecordView/process/${record['processId']}/instances/${record['instanceId']}/processing/${button.id}/${decodeURI(button.label.replace(/\s+/g, '-'))}?loading=${loading}`
      });
    }
  }
}
