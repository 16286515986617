/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "./user-info.component";
import * as i5 from "@angular/material/dialog";
var styles_UserInfoComponent = [i0.styles];
var RenderType_UserInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserInfoComponent, data: {} });
export { RenderType_UserInfoComponent as RenderType_UserInfoComponent };
export function View_UserInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "p-24"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["style", "text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "h-100-p w-100-p"], ["fxLayout", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(4, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(5, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["alt", "userinfo"], ["src", "/assets/userinfo.png"]], null, null, null, null, null))], function (_ck, _v) { var currVal_1 = "column"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "center center"; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.data && _co.data["heading"]); _ck(_v, 2, 0, currVal_0); var currVal_3 = (_co.data && _co.data["message"]); _ck(_v, 7, 0, currVal_3); }); }
export function View_UserInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-info", [], null, null, null, View_UserInfoComponent_0, RenderType_UserInfoComponent)), i1.ɵdid(1, 114688, null, 0, i4.UserInfoComponent, [i5.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserInfoComponentNgFactory = i1.ɵccf("app-user-info", i4.UserInfoComponent, View_UserInfoComponent_Host_0, {}, {}, []);
export { UserInfoComponentNgFactory as UserInfoComponentNgFactory };
