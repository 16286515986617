<div style="width: 100%; height: 400px;" fxLayoutAlign="center center" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>

<ng-container *ngIf="!loading">
  <mat-toolbar matDialogTitle fxLayoutAlign="center center" class="call-header-one mat-toolbar mat-accent m-0"
    fxLayout="row">
    <!-- <div style="background: #f6f6f6;" class="m-0 p-8" *ngIf="data"> -->
    <div fxFlex fxLayout="header" fxLayoutAlign="space-between center">
      <span class="title dialog-title">
        <p class="m-0 font-size-20 ml-12">
          {{ 'popup.smtp_form' | translate }}
        </p>
      </span>
      <span fxFlex></span>
      <button class="mr-12" (click)="save()" mat-icon-button>
        <mat-icon>check</mat-icon>
      </button>
      <button class="mr-12" (click)="close()" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <div class="p-12" fxLayout="column">
    <ng-container *ngIf="data.type === 'smtpConfig'">
      <div class="happ-common-list-container">
        <p class="font-size-18" fxFlex="30">
          <b>Domain Name:</b> <br>
          Domain name for info.
        </p>
        <div fxFlex="70">
          <mat-form-field fxFlex class="mx-8 happ-small-form-field-outline">
            <input [(ngModel)]="smtpSettings.domainName" matInput >
          </mat-form-field>
        </div>
      </div>
      <div class="happ-common-list-container">
        <p class="font-size-18" fxFlex="30">
          <b>SMTP server:</b> <br>
          Host name of server
        </p>
        <div fxFlex="70">
          <mat-form-field fxFlex class="mx-8 happ-small-form-field-outline">
            <input [(ngModel)]="smtpSettings.server" matInput >
          </mat-form-field>
        </div>
      </div>
      <div class="happ-common-list-container">
        <p class="font-size-18" fxFlex="30">
          <b>SMTP Port:</b> <br>
          Host name of Port
        </p>
        <div fxFlex="70">
          <mat-form-field fxFlex class="mx-8 happ-small-form-field-outline">
            <input [(ngModel)]="smtpSettings.port" matInput >
          </mat-form-field>
        </div>
      </div>
      <br>
      <div class="happ-common-list-section">
        <h2>
          <strong>Default Email Value</strong>
        </h2>
      </div>
    </ng-container>
      <div class="happ-common-list-container">
        <p class="font-size-18" fxFlex="30">
          <b>SMTP Username:</b> <br>
          Host name of Username
        </p>
        <div fxFlex="70">
          <mat-form-field fxFlex class="mx-8 happ-small-form-field-outline">
            <input [(ngModel)]="smtpSettings.username" matInput >
          </mat-form-field>
        </div>
      </div>
      <div class="happ-common-list-container">
        <p class="font-size-18" fxFlex="30">
          <b>SMTP Password:</b> <br>
          Host name of Password
        </p>
        <div fxFlex="70">
          <mat-form-field fxFlex class="mx-8 happ-small-form-field-outline">
            <input type="password" [(ngModel)]="smtpSettings.password" matInput >
          </mat-form-field>
        </div>
      </div>
      <div class="happ-common-list-container">
        <p class="font-size-18" fxFlex="30">
          <b>SMTP replyTo (Optional) :</b> <br>
          replyTo 
        </p>
        <div fxFlex="70">
          <mat-form-field fxFlex class="mx-8 happ-small-form-field-outline">
            <input [(ngModel)]="smtpSettings.replyTo" matInput >
          </mat-form-field>
        </div>
      </div>
      <div class="happ-common-list-container">
        <p class="font-size-18" fxFlex="30">
          <b>SMTP sender(Optional):</b> <br>
          sender 
        </p>
        <div fxFlex="70">
          <mat-form-field fxFlex class="mx-8 happ-small-form-field-outline">
            <input [(ngModel)]="smtpSettings.sender" matInput >
          </mat-form-field>
        </div>
      </div>
  </div>
</ng-container>
