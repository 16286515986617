import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
var SsTypeSelectComponent = /** @class */ (function () {
    function SsTypeSelectComponent(translationLoader, helperService, translateService) {
        var _this = this;
        this.translationLoader = translationLoader;
        this.helperService = helperService;
        this.translateService = translateService;
        this.onFieldValueUpdated = new EventEmitter();
        this.model = [];
        this.modelChange = new EventEmitter();
        // tslint:disable-next-line:no-output-on-prefix
        this.onError = new EventEmitter();
        this.error = false;
        this.currentLang = 'en';
        this.errorMessage = '';
        this.currentLang = this.translateService.getDefaultLang();
        this.translateService.onLangChange.subscribe(function (data2) {
            _this.currentLang = data2;
        });
        this.getTypes();
    }
    SsTypeSelectComponent.prototype.getTypes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.helperService.getRequest('api/super-admin/platform-variable/shared-service')];
                    case 1:
                        data = _a.sent();
                        this.types = (data && data.data) || [];
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        alert(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SsTypeSelectComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.translateData && changes.translateData.currentValue) {
            this.translateData = changes.translateData.currentValue;
            this.translationLoader.loadTranslationsV2(this.translateData);
        }
    };
    SsTypeSelectComponent.prototype.ngOnInit = function () {
        this.translationLoader.loadTranslationsV2(this.translateData);
        if (this.field['required'] && (!this.model || this.model && this.model.length === 0)) {
            this.error = true;
            this.errorMessage = 'ct.field_is_required';
            if (!this.model) {
                this.onError.emit({
                    error: true,
                    label: this.field['label']
                });
            }
        }
    };
    SsTypeSelectComponent.prototype.onChangeItem = function (item) {
        this.model = this.model || [];
        if (item && item.key) {
            if (this.model.includes(item.key)) {
                var index = this.model.indexOf(item.key);
                this.model.splice(index, 1);
            }
            else {
                this.model.push(item.key);
            }
            this.modelChange.next(this.model);
        }
    };
    SsTypeSelectComponent.prototype.changeSingle = function (item) {
        this.model = item.key;
    };
    return SsTypeSelectComponent;
}());
export { SsTypeSelectComponent };
