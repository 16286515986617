<div style="position: relative; " class="my-16" fxLayout="column" *ngIf="!field.hide">
    <div>
      <table>
        <thead>
          <tr>
            <th>{{ 'ct.country' | translate }}</th>
            <th>{{ 'ct.lang' | translate }}</th>
            <th>{{ 'ct.type' | translate }}</th>
            <th>{{ 'ct.name' | translate }}</th>
            <th>{{ 'ct.status' | translate }}</th>
            <th style="text-align: center;" >{{ 'ct.actions' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of serviceList" >
            <td>{{ item.country }} {{ item._id }} </td>
            <td>{{ (item.serviceData && item.serviceData.lang) }}</td>
            <td>{{ (item.serviceData && item.serviceData.type) }}</td>
            <td>{{ (item.serviceData && item.serviceData.name) }}</td>
            <td>{{ item.processStatusLabel }}</td>
            <td style="text-align: center;"> 
              <button *ngIf="field.isMultiSelect" mat-raised-button [color]="(model.includes(item.serviceData._id)) ? 'primary' : ''" (click)="select(item)" > {{ 'ct.select' | translate }} </button>
              <button *ngIf="!field.isMultiSelect" mat-raised-button [color]="(model===item.serviceData._id) ? 'primary' : ''" (click)="select(item)" > {{ 'ct.select' | translate }} </button>
             </td>
          </tr>
        </tbody>
      </table>
    </div>
  <div class="happ-field-description" *ngIf="field.allow_description && field.description"> {{ field.description }} </div>
  <div class="error-message" *ngIf="errorMessage && (!min_error && !max_error)"> {{ errorMessage | translate }}
  </div>
</div>