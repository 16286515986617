<div style="overflow: auto; max-height: 100vh;" class="dialog-content-wrapper">
    <mat-toolbar style="background: #f6f6f6;" matDialogTitle class="m-0">
      <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title"> {{ 'bulk_action.bulk_action' | translate }} </span>
        <span fxFlex></span>
        <button (click)="dialogRef.close(null)" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-toolbar>
  
    <div *ngIf="loading" fxLayoutAlign="center center" class="p-24" style="overflow: auto;" fxLayout="column">
      <mat-spinner></mat-spinner>
      <p>
        {{ 'bulk_action.loading_text' | translate }}
      </p>
    </div>
    <div *ngIf="!loading" class="p-24" style="overflow: auto;" fxLayout="column">

      <ng-container *ngIf="customTag">
        <h3 class="happ-bulk-heading">
          {{ 'bulk_action.add_custom_tag' | translate }}
        </h3>
        <p>
          {{ 'bulk_action.add_custom_tag_desc' | translate }}
        </p>
        <mat-form-field fxFlex="35" floatLabel="always" appearance="outline" fxFlex class="happ-small-form-field-outline">
          <mat-label> {{ 'bulk_action.tag_key_name' | translate }} </mat-label>
          <input type="text" autofocus="true" matInput [(ngModel)]="customTagKey">
        </mat-form-field>

        <div fxLayout="row">
          <span fxFlex></span>
          <div>
            <button mat-button class="mr-4" (click)="customTag=false" >{{ 'bulk_action.cancel' | translate }}</button>
            <button mat-raised-button [disabled]="!customTagKey" (click)="addTag()"
              color="primary">{{ 'bulk_action.add_tag' | translate }}</button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!customTag">

        <ng-container *ngIf="step === '1'">
          <div *ngFor="let item of actions"  class="px-16 py-8" [fxLayout]="'row'" style="border: 1px solid #e8e3e3;">
            <div fxFlex="83" fxLayout="column">
              <p class="font-size-16 font-weight-700 mx-0 my-4">{{ item.title | translate }}</p>
              <p class="m-0"> {{ item.desc | translate }} </p>
              <!-- <p *ngIf="value" class="m-0"><strong> {{ value | translate }} </strong></p> -->
            </div>
            <span fxFlex></span>
            <div [style.margin]="(layout === 'row') ? 'auto' : 'initial'">
              <button (click)="reqData.actionType=item.type;step='2'" class="mr-8" mat-raised-button > {{ item.title | translate }} </button>
            </div>
          </div>
        </ng-container>
        <div *ngIf="data && !data.isTask" fxLayout="column" class="mr-16 mb-16">
          <p style="margin: auto;" class="mr-8 font-size-18 ml-4">
            {{ 'bulk_action.action_type' | translate }}
          </p>
          <div fxLayout="row">
            <div>  
              <button class="mr-8" [color]="(reqData.actionType==='mailingList')? 'primary' : ''" mat-raised-button (click)="reqData.actionType='mailingList'" > {{ 'bulk_action.add_to_mailing_list' | translate }} </button>
            </div>
            <div>  
              <button class="mr-8" [color]="(reqData.actionType==='update')? 'primary' : ''" mat-raised-button (click)="reqData.actionType='update'" > {{ 'bulk_action.update' | translate }} </button>
            </div>
            <div>  
              <button mat-raised-button [color]="(reqData.actionType==='delete')? 'primary' : ''" (click)="reqData.actionType='delete'" > {{ 'bulk_action.delete' | translate }} </button>
            </div>
          </div>
        </div>
        <div fxLayout="column" *ngIf="step==='2'">
          <p *ngIf="reqData.actionType === 'delete'" class="font-size-22" style="color: brown" >
            {{ 'bulk_action.you_are_about_to_delete' | translate }} {{ data.recCount }} {{ 'bulk_action.records_from_the_register' | translate }} {{ data.regName }}
          </p>
          <p *ngIf="reqData.actionType === 'update'" class="font-size-22" style="color: brown" >
            {{ 'bulk_action.you_are_about_to_update' | translate }} {{ data.recCount }} {{ 'bulk_action.records_from_the_register' | translate }} {{ data.regName }}
          </p>
          <p *ngIf="reqData.actionType === 'processTask'" class="font-size-22" style="color: brown" >
            {{ 'bulk_action.you_are_about_to_execute' | translate }} {{ (data && data.taskObject && data.taskObject.name) }} {{ 'bulk_action.on' | translate }} {{ data.recCount }} {{ 'bulk_action.records_from_the_register' | translate }} {{ data.regName }}
          </p>
          <div *ngIf="reqData.actionType === 'tags'" fxLayout="column">
            <h2 class="happ-bulk-heading">
              {{ 'bulk_action.tags' | translate }}
            </h2>
            <mat-form-field floatLabel="always" appearance="outline" fxFlex class="happ-small-form-field-outline">
              <mat-label>{{ 'bulk_action.select_field' | translate }}</mat-label>
              <mat-select [(ngModel)]="selectedField">
                <mat-option *ngFor="let field of data.fields" [value]="field.label">
                  {{field.title | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <ng-container *ngIf="selectedField">
              <h3 class="happ-bulk-heading">
                {{ 'bulk_action.update_tag_values' | translate }}
              </h3>
              <p class="happ-bulk-desc">
                {{ 'bulk_action.tag_fields_description' | translate }}
              </p>
              
              <ng-container *ngIf="!customTag">
                <div  fxLayoutGap="10px" *ngFor="let filter of reqData.updatedDoc; let i=index" fxLayout="row">
                  <div class="pt-12">
                    <mat-checkbox [(ngModel)]="filter.checked" class="example-margin"></mat-checkbox>
                  </div>
                  <p style="width: 200px;">
                    <b>
                      {{ filter.keyName }}
                    </b>
                  </p>
                  <!-- <mat-form-field fxFlex="35" floatLabel="always" appearance="outline" fxFlex>
                    <mat-label> {{ 'bulk_action.tag_key_name' | translate }} </mat-label>
                    <input type="text" autofocus="true" matInput [(ngModel)]="filter.keyName">
                  </mat-form-field> -->
                  <mat-form-field fxFlex="35" floatLabel="always" appearance="outline" fxFlex class="happ-small-form-field-outline">
                    <mat-label> {{ 'bulk_action.tag_value' | translate }} </mat-label>
                    <input [disabled]="!filter.checked" type="text" autofocus="true" matInput [(ngModel)]="filter.value">
                  </mat-form-field>
                  <!-- <div fxFlex="25">
                    <button class="mt-12" (click)="reqData.updatedDoc.push({})" mat-icon-button>
                      <mat-icon>add</mat-icon>
                    </button>
                    <button class="mt-12" (click)="reqData.updatedDoc.splice(i, 1)" mat-icon-button>
                      <mat-icon>remove</mat-icon>
                    </button>
                  </div> -->
                </div>
              </ng-container>
              <a (click)="customTag=true" style="color: blue; cursor: pointer;">
                {{ 'bulk_action.add_custom_tag' | translate }}
              </a>
            </ng-container>
          </div>
          <div *ngIf="reqData.actionType === 'mailingList'" fxLayout="column">
            <mat-radio-group class="mb-12" [(ngModel)]="mailingOperation" aria-label="Select an option" fxLayout="row" fxLayoutAlign="center center">
              <mat-radio-button style="font-size: 22px;" class="mr-12" value="create">{{ 'bulk_action.create_mailing_list' | translate }}</mat-radio-button>
              <mat-radio-button style="font-size: 22px;" value="update">{{ 'bulk_action.update_mailing_list' | translate }}</mat-radio-button>
            </mat-radio-group>

            <div fxLayout="column">
              <ng-container *ngIf="mailingOperation === 'update'">
                <p style="font-size: 22px; text-align: center; margin: 20px;">
                  {{ 'bulk_action.mailing_list_update_desc' | translate }}
                </p>
                <mat-form-field floatLabel="always" appearance="outline" fxFlex class="happ-small-form-field-outline happ-big-input mb-16" style="width: 80%; margin: auto;">
                  <mat-label> {{ 'bulk_action.search' | translate }} </mat-label>
                  <input type="text" autofocus="true" matInput [(ngModel)]="query">
                </mat-form-field>
                <div fxLayout="row" fxLayoutAlign="center center" class="happ-list-item" [ngClass]="{'happ-list-item-selected': listItem._id === listId}" *ngFor="let listItem of mailingList | filter: query" >
                  <div fxLayout="column">
                    <h2>
                      {{ listItem.name }}
                    </h2>
                    <p>
                      {{ listItem.description }}
                    </p>
                  </div>
                  <span fxFlex></span>
                  <div>
                    <button mat-raised-button (click)="listName=listItem.name;listId=listItem._id;confirmBulkAction()" >
                      {{ 'bulk_action.select' | translate }}
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
            <div fxLayout="column" *ngIf="mailingOperation === 'create'">
              <p style="font-size: 22px; text-align: center; margin: 20px;">
                {{ 'bulk_action.mailing_list_update_desc' | translate }}
              </p>
              <mat-form-field fxFlex="35" floatLabel="always" appearance="outline" fxFlex class="happ-small-form-field-outline happ-big-input mb-16" style="width: 80%; margin: auto;">
                <mat-label> {{ 'bulk_action.list_name' | translate }} </mat-label>
                <input type="text" autofocus="true" matInput [(ngModel)]="listName">
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="reqData.actionType === 'update'" fxLayout="column">
            <button class="mt-12" (click)="reqData.updatedDoc.push({})" mat-icon-button>
                  <mat-icon>add</mat-icon>
                </button>
            <div fxLayoutGap="10px" *ngFor="let filter of reqData.updatedDoc; let i=index" fxLayout="row">
              <mat-form-field fxFlex="35" floatLabel="always" appearance="outline" fxFlex class="happ-small-form-field-outline">
                <mat-label> {{ 'bulk_action.key_name' | translate }} </mat-label>
                <input type="text" autofocus="true" matInput [(ngModel)]="filter.keyName">
              </mat-form-field>
              <mat-form-field fxFlex="35" floatLabel="always" appearance="outline" fxFlex class="happ-small-form-field-outline">
                <mat-label> {{ 'bulk_action.value' | translate }} </mat-label>
                <input type="text" autofocus="true" matInput [(ngModel)]="filter.value">
              </mat-form-field>
              <div fxFlex="25">
                <button class="mt-12" (click)="reqData.updatedDoc.push({})" mat-icon-button>
                  <mat-icon>add</mat-icon>
                </button>
                <button class="mt-12" (click)="reqData.updatedDoc.splice(i, 1)" mat-icon-button>
                  <mat-icon>remove</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      <div *ngIf="step === '2'" class="p-24 pt-20 pb-20">
        <span fxFlex></span>
        <button (click)="step='1'" aria-label="custom_app_cancel_button" mat-button
          class="mr-4">{{ 'bulk_action.cancel' | translate }}</button>

        <ng-container [ngSwitch]="reqData.actionType">
          <ng-container *ngSwitchCase="'tags'">
            <button *ngIf="selectedField" mat-raised-button [disabled]="loading || (reqData && !reqData.actionType)" aria-label="custom_app_create_button" (click)="confirmBulkAction()"
              color="primary">{{ 'bulk_action.confirm' | translate }}</button>
          </ng-container>
          <ng-container *ngSwitchCase="'mailingList'">
            <ng-container *ngIf="mailingOperation === 'create'">
              <button mat-raised-button [disabled]="loading || !listName" aria-label="custom_app_create_button" (click)="confirmBulkAction()"
                color="primary">{{ 'bulk_action.confirm' | translate }}</button>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <button mat-raised-button [disabled]="loading || (reqData && !reqData.actionType)" aria-label="custom_app_create_button" (click)="confirmBulkAction()"
              color="primary">{{ 'bulk_action.confirm' | translate }}</button>
            
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>
  </div>