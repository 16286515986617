import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-file-retry-view',
  templateUrl: './file-retry-view.component.html',
  styleUrls: ['./file-retry-view.component.scss']
})
export class FileRetryViewComponent implements OnInit {

  @Input() url: string;
  @Input() type = 'popup';
  
  @Output() fileFound: EventEmitter<any> = new EventEmitter();

  retry = 1;
  error = false;
  totalTimeout = 3;
  retryServers = [
    {
      title: 'ct.server_1',
      errorTitle: 'ct.server_error_1'
    },
    {
      title: 'ct.server_2',
      errorTitle: 'ct.server_error_2'
    },
    {
      title: 'ct.server_3',
      errorTitle: 'ct.server_error_3'
    }
  ];


  constructor(
    private helperService: HelpersService,
    public dialogRef: MatDialogRef<FileRetryViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    this.url = (this.data && this.data.url) || this.url;
    this.getData();
  }

  getData() {
    if (this.url) {
      this.totalTimeout = 3;
      const temp = setInterval(() => {
        this.totalTimeout--;
      }, 1000);
      this.helperService.getRequest(`${this.data.url}?retry=${this.retry}`)
      .then((data) => {
        if (this.type === 'popup') {
          this.dialogRef.close(data);
        } else {
          this.fileFound.emit(data);
        }
      }).catch(err => {
        this.retry = 1 + this.retry;
        console.log(this.retry);
        if (this.retry > 3) {
          this.error = true;
        }
        if (this.totalTimeout > 0) {
          setTimeout(() => {
            if (this.retry <= 3) {
              this.getData();
            }
          }, this.totalTimeout * 1000);
        }
        console.error(err);
      });
    }
  }
}
