import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss']
})
export class PhoneComponent implements OnInit {
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() model: any;
  @Input() translateData: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  
    constructor() { }
  
    ngOnInit() {
    }
  
    onKeyPress() {
     
      this.modelChange.next(this.model);
      if (this.field.haveRules) {
        this.onFieldValueUpdated.emit({
          label: this.field.label,
          value: this.model,
          ruleIndex: this.field.ruleIndex,
          field: this.field
        });
      }
    }
  
    
  pasteIt(eve) {
    eve.preventDefault();
    this.model = this.model || '';
    this.model += (eve && eve.clipboardData && eve.clipboardData.getData('Text')) || '';
    this.onKeyPress();
  }
}
