import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MqFilterPipe } from '@fuse/pipes/mqFilter.pipe';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { latLng, TileLayer } from 'leaflet';
import * as objectPath from 'object-path';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import { MatDialog } from '@angular/material';
import { EditFormPopupComponent } from 'app/main/content/apps/custom-registries/edit-form-popup/edit-form-popup.component';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';


@Component({
  selector: 'app-map-cluster',
  templateUrl: './map-cluster.component.html',
  styleUrls: ['./map-cluster.component.scss']
})
export class MapClusterComponent implements OnInit, OnChanges {

  @Input() translateData: any;
  @Input() field;
  @Input() regRec;
  @Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Output() updateFormDataObj: EventEmitter<Object> = new EventEmitter();
  @Output() updateRecord: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() model: any = [];

  filterValues: any = {};
  extraData: any = {
    layers: true,
    filters: true
  };
  isAnyLayerSelected = false;
  regExp = /##([\s\S]*?)##/g;
  selectedIndex = 0;

  detailObj: any;

  private map: L.Map;
  coordinates: any[] = [];
  loading: any = false;

  options = {
    layers: [
      new TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
    ],
    zoom: 5,
    center: latLng(46.879966, -121.726909)
  };
  mqFilter: string;
  staticData: any[] = [];
  markerCluster: L.MarkerClusterGroup;
  allLayers: any = {};
  error: any;
  renderData: any[] = [];
  inputFilter: any = {};
  fieldsToShow: any = [];
  totalMarkers: number;
  constructor(
    private helperService: HelpersService,
    public dialog: MatDialog,
    private translationLoader: FuseTranslationLoaderService,
  ) {
   }

   ngOnChanges(changes: any) {

    if (changes && changes.translateData && changes.translateData.currentValue) {
      this.translateData = changes.translateData.currentValue;
      this.translationLoader.loadTranslationsV2(this.translateData);    
    }
  }

  ngOnInit() { 
    this.initPage(false);
  }

  initPage(isRefresh: any = null) {
    if (isRefresh) {
      if (this.field.rePullData) {
        this.getApiData();
      } else {
        this.afterApiCall();
      }
    } else {
      this.getApiData();
    }
    this.field.filters = this.field.filters || [];
    this.field.layers = this.field.layers || [];
    this.field.filters = this.field.filters.map((item: any) => {
      item.list = item.list || [];
      item.list = item.list.map((ele: any) => {
        return {
          ...ele,
          rootName: this.replaceSpecialCharacters(item.name),
          key: this.replaceSpecialCharacters(ele.value || ele.name)
        }
      })
      return item
    });
    this.field.layers = this.field.layers.map((item: any) => {
      item.list = item.list || [];
      item.list = item.list.map((ele: any) => {
        return {
          ...ele,
          rootName: this.replaceSpecialCharacters(item.name),
          key: this.replaceSpecialCharacters(ele.value || ele.name)
        }
      })
      return item
    });
  }

  replaceSpecialCharacters(input: string): string {
    return input.replace(/[^a-zA-Z0-9]/g, '_');
  }

  formatVars(textToReplace: string = '', extraData: any = {}, regExp: any = /##([\s\S]*?)##/g): any {
      const recordData: any = extraData || {};
      return textToReplace.replace(regExp, (_match, contents, _offset, _input_string) => {
          return this.getByKey(recordData, contents);
      });
  }

  getByKey(recordData: any, contents: any) {
    if (objectPath.get(recordData, contents) !== undefined && objectPath.get(recordData, contents) !== null) {
        return objectPath.get(recordData, contents);
    } else if (recordData[contents] !== undefined && recordData[contents] !== null) {
        return recordData[contents];
    }
    return '';
}

  afterApiCall() {
    this.coordinates = this.staticData.filter((ele: any) => (ele.location && ele.location.coordinates)).map((ele: any) => (ele.location.coordinates))
    // this.renderData = tempData;


    this.field.layers.forEach((item: any) => {
      item.list.forEach((ele: any) => {
        if (ele.defaultFilter) {
          this.filterValues['layer_' + ele.key] = true
        }
      });
    });
    this.field.filters.forEach((item: any) => {
      item.list.forEach((ele: any) => {
        if (ele.defaultFilter) {
          this.filterValues['filter_' + ele.key] = true
        }
      });
    });
    
    setTimeout(() => {
      this.initMap();
      if (Object.keys(this.filterValues).length) {
        this.filterChange(true)
      }
    }, 1000);
  }

  getApiData() {
    this.loading = true;
    const extraCols: any = {};
    if (this.field.fieldsToShow) {
      const cols = this.field.fieldsToShow.split(",").map((ele: any) => (ele.trim())).filter((ele: any) => (ele));
      this.fieldsToShow = cols;
      cols.forEach((ele: any) => {
        extraCols[ele] = 1;
      });
    }
    const reqBody = { "mqFilter": this.formatVars(this.field.mapMqFilter, this.regRec), "search": { "value": "", "regex": false }, columnFields: { location: 1, regId: 1, _id: 1, ...extraCols }, "filterRecord": {}, "filters": {}, page: {pageNumber: 0, size: this.field.mapRecLimit || 500 } }
    this.helperService.postRequest(`api/reg/${this.field.mapSourceRegId}/data-table?compact=true`, reqBody)
    .then((result) => {
      this.loading = false;
      const tempData = result.dttable.data || [];
      this.staticData = JSON.parse(JSON.stringify(tempData));
      this.afterApiCall();
    }).catch(err => {
      this.loading = false;
      console.log(err);
    });
  }

  makeCustomCluster(cluster, color) {

    const count = cluster.getChildCount();
    const icon = L.divIcon({
      html: `<div style="background-color: ${color}; border-radius: 50%; width: 30px; height: 30px; display: flex; justify-content: center; align-items: center; color: white;">${count}</div>`,
      className: 'custom-cluster-icon',
      iconSize: [30, 30]
    });
    return icon;
  }

  createMarkerCluster() {
    const options: any = {};

    if (this.field.disableClusteringAtZoom) {
      options.disableClusteringAtZoom = this.field.disableClusteringAtZoom;
    }

    if (this.field.disableGrouping) {
      options.disableClusteringAtZoom = 8;
    }
    options.iconCreateFunction = (cluster) => {
      this.makeCustomCluster(cluster, "red")
    }
    const markers = L.markerClusterGroup(options);
    this.renderData.forEach((ele) => {
      if (ele && ele.location) {
        const marker = L.marker([ele.location.coordinates[1], ele.location.coordinates[0]]
          , {
            title: ele.selectedAddress,
            alt: ele._id,
          icon: L.icon({
            iconUrl: this.field.markerUrl || `${window.location.origin}/assets/images/tank.png`,
            iconSize: [30, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
          })
          // "https://i.pinimg.com/originals/8d/eb/78/8deb783b9347ebca85571d583fa58e59.png" 
        }
        );
        marker.on('click', (e) => {
          console.log('Marker clicked!');
          console.log(e); // Log event details if needed
          this.markerClicked(e.target.options.alt);
      });
        markers.addLayer(marker);
      }
    })

    return markers;
  }

  createMarkerClusterV2(data: any) {
    const options: any = {};

    if (this.field.disableClusteringAtZoom) {
      options.disableClusteringAtZoom = this.field.disableClusteringAtZoom;
    }

    if (this.field.disableGrouping) {
      options.disableClusteringAtZoom = 8;
    }
    
    options.iconCreateFunction = function (cluster) {
      const color = data.color || "red";
      const count = cluster.getChildCount();
    const icon = L.divIcon({
      html: `<div style="background-color: ${color}; border-radius: 50%; width: 30px; height: 30px; display: flex; justify-content: center; align-items: center; color: white;">${count}</div>`,
      className: 'custom-cluster-icon',
      iconSize: [30, 30]
    });
    return icon;
    }
    const markers = L.markerClusterGroup(options);
    console.log("Using v2");
    data.locations.forEach((ele) => {
      if (ele && ele.location) {
        const marker = L.marker([ele.location.coordinates[1], ele.location.coordinates[0]]
          , {
            title: ele.selectedAddress,
            alt: ele._id,
            icon: L.icon({
              iconUrl: data.markerUrl || this.field.markerUrl || `${window.location.origin}/assets/images/tank.png`,
              iconSize: [30, 41],
              iconAnchor: [12, 41],
              popupAnchor: [1, -34],
            })
          // "https://i.pinimg.com/originals/8d/eb/78/8deb783b9347ebca85571d583fa58e59.png" 
        }
        );
        marker.on('click', (e) => {
          console.log('Marker clicked!');
          console.log(e); // Log event details if needed
          this.markerClicked(e.target.options.alt);
      });
        markers.addLayer(marker);
      }
    })

    return markers;
  }

  markerClicked(id: string) {
    const itemDoc = this.staticData.filter((ele) => (ele._id === id));
    if (itemDoc && itemDoc[0]) {
      this.detailObj = itemDoc[0];
      this.selectedIndex = 1;
    }
  }

  mapClicked(eve: any) {
    this.detailObj = null;
    this.selectedIndex = 0;
  }

  private initMap(): void {
    try {
      this.error = null;

      if (this.map) {
        return;
      }

      this.map = L.map('map', {
        center: [54.3520, 18.6466], // Centered on Gdańsk, Poland
        zoom: 12 // Zoom level to show the Gdańsk region
      });
  
      this.map.on('click', (e) => {
        console.log('Map clicked!');
        console.log(e); // Log event details if needed
        this.mapClicked(e);

    });
  
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }).addTo(this.map);
  
      this.renderData = [];
      this.markerCluster = this.createMarkerCluster();
  
      this.map.addLayer(this.markerCluster);
      setTimeout(() => {
        console.log("bounf 2222", this.markerCluster.getBounds(), this.markerCluster.getBounds().getNorthEast())
        if (this.markerCluster.getBounds().getNorthEast()) {
          this.map.fitBounds(this.markerCluster.getBounds());
        }
      }, 1000)
    } catch (err) {
      console.log(err);
      this.error = err.message;
    }
  }

  refreshMap(reCenter: any) {
    this.map.removeLayer(this.markerCluster);

    const markerCluster = this.createMarkerCluster();

    const polandBounds = L.latLngBounds(
      L.latLng(49.0, 14.0), // Southwest coordinates
      L.latLng(55.0, 24.0)  // Northeast coordinates
    );

    // this.map.addLayer(markerCluster);
    // setTimeout(() => {
    //   console.log("bounf", this.markerCluster.getBounds(), this.markerCluster.getBounds().getNorthEast())
    //   if (this.markerCluster.getBounds().getNorthEast()) {
    //   }
    // }, 1000)
    if (reCenter) {
      this.map.fitBounds(polandBounds);
    }
    this.markerCluster = markerCluster;
  }

  findItem(data: any[], type: any, ele: any) {
    let itemDoc: any;
    data.forEach((ele2: any) => {
      if (!itemDoc) {
        itemDoc = ele2.list.find((ele3: any) => (`${type}${ele3.key}` === ele));
      }
    });
    return itemDoc;
  }

  filterChange(reCenter: any) {
    this.mqFilter = '';
    let tempFilter: any = {};
    let tempLayer: any[] = [];
    Object.keys(this.filterValues).forEach((ele: any) => {
      if (this.filterValues[ele]) {
        if (ele.startsWith("layer")) {
          this.isAnyLayerSelected = true;
          const itemDoc = this.findItem(this.field.layers, 'layer_', ele);
          // this.field.layers.find((ele2: any) => (`layer_${ele2.key}` === ele));
          if (itemDoc) {
            tempLayer.push(itemDoc);
          }
        } else {
          const itemDoc = this.findItem(this.field.filters, 'filter_', ele);
          // const itemDoc = this.field.filters.find((ele2: any) => (`filter_${ele2.key}` === ele));
          if (itemDoc) {
            tempFilter[itemDoc.rootName] = tempFilter[itemDoc.rootName] || [];
            tempFilter[itemDoc.rootName].push(itemDoc.value);
          }
        }
        console.log("tempLayer = ", tempLayer);
      }
    });

    if (this.allLayers && this.map) {
      Object.values(this.allLayers).forEach((ele: any) => {
        this.map.removeLayer(ele);
      })
    }

    if (this.map) {
      this.map.removeLayer(this.markerCluster);
    }



    const finalFilter = Object.values(tempFilter).map((ele: any) => (ele.join(" OR "))).join(" AND ");

    // staticData
    const temp = new MqFilterPipe();
    let tempNewRecords = this.staticData;
    if (finalFilter) {
      tempNewRecords = this.staticData.filter((ele: any) => (temp.transform(ele, this.formatVars(finalFilter, this.regRec), "string")));
    }
    this.totalMarkers = 0;
    console.log("finalFilter", finalFilter, tempFilter)

    if (tempLayer && tempLayer.length) {
      this.totalMarkers = 0;
      const allLayers = tempLayer.map((ele: any) => {
        const res = tempNewRecords.filter((ele2: any) => (temp.transform(ele2, this.formatVars(ele.value, this.regRec), "string")));
        this.totalMarkers = this.totalMarkers + res.length;
        this.allLayers[ele.key] = this.createMarkerClusterV2({
          ...ele,
          locations: res,
          coordinates: res.filter((ele2: any) => (ele2.location && ele2.location.coordinates)).map((ele2: any) => (ele2.location.coordinates))
        })
        this.map.addLayer(this.allLayers[ele.key]);
        return {
          ...ele,
          coordinates: res.filter((ele2: any) => (ele2.location && ele2.location.coordinates)).map((ele2: any) => (ele2.location.coordinates))
        }
      })

    } else {
      this.coordinates = tempNewRecords.filter((ele: any) => (ele.location && ele.location.coordinates)).map((ele: any) => (ele.location.coordinates))
      this.renderData = tempNewRecords;
      // setTimeout(() => {
      //   this.refreshMap();
      // }, 500);
    }

    // this.map.fitBounds(markerCluster.getBounds());

    // this.coordinates = tempNewRecords.filter((ele: any) => (ele.location && ele.location.coordinates)).map((ele: any) => (ele.location.coordinates))
    setTimeout(() => {
      this.refreshMap(reCenter);
    }, 500);
  }

  onSelectChange() {
    this.filterChange(false);
  }

  popupEditForm = (recordData) => {
    const dialogRef = this.dialog.open(EditFormPopupComponent, {
        width: '100%',
        panelClass: 'happ-form-builder-dialog',
        data: { 
            recId: recordData._id, 
            regId: recordData.regId,
            dialog: this.dialog, 
            defaultValue: {},
            action: 'edit' 
        }
    });


    dialogRef.afterClosed().subscribe(result => {
    }, err => {
        console.info(err);
    });
  }

  oneInputFilterChange(eve: any, key: any, keyName: any) {
    if (this.inputFilter[keyName]) {
      this.filterValues[key] = true;
      const itemDoc = this.findItem(this.field.layers, 'layer_', key);
      itemDoc.value = `${keyName} CONTAINS '${this.inputFilter[keyName]}'`
      // itemDoc.value = `${keyName} CONTAINS '/${this.inputFilter[keyName]}*/'`
      // this.field.layers
    } else {
      this.filterValues[key] = false;
      const itemDoc = this.findItem(this.field.layers, 'layer_', key);
      itemDoc.value = null;
    }

    this.filterChange(false);
  }

  reInitData() {
    this.initPage(true);
  }
}
