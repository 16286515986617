<div class="navbar-top happ-nav-small-hide" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <div class="logo">
        <img class="logo-icon" src="assets/images/logos/fuse.svg">
        <span class="logo-text secondary-text">Pinbox24</span>
    </div>

    <div class="buttons">

        <button mat-icon-button class="toggle-sidebar-folded"
                (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened"
                (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div  class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground"
     fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">
    
    <div fxLayout="row"  *ngIf="fuseConfig && fuseConfig.isNotOffice"  fxLayoutAlign="start center" (click)="this.logout()" style="background: orange; color: black; font-size: 14px; height: 40px;padding: 10px 12px 10px 24px; cursor: pointer; margin-bottom: 12px;">
        <mat-icon style="color: black; font-size: 20px;height: 20px; width: 20px;">power_settings_new</mat-icon>
        <a style="font-size: 14px; color: black;text-decoration: none;" class="ml-8">{{ 'toolbar.logout' | translate }}</a>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" [routerLink]="['/offices/dashboard']"  style="margin-bottom: 0px; background: lightyellow; color: black; font-size: 14px; height: 40px;padding: 9px 17px 9px 12px; cursor: pointer; margin-bottom: 12px;border: 10px solid;border-bottom-width: 0px;border-top-width: 0px;">
        <mat-icon style="color: black; font-size: 20px;height: 20px; width: 20px;">dashboard</mat-icon>
        <a style="font-size: 14px; color: black;text-decoration: none;" class="ml-8">{{ 'toolbar.office_dashboard' | translate }}</a>
    </div>

    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>

</div>
