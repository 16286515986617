<div fxLayout="column" style="width: 100%;">
  <div style="background: darkblue; color: white;" fxLayout="row" fxLayoutAlign="space-between center">
    <span style="font-size: 20px;" class="m-4 ml-12">Pinbox24.com</span>
    <mat-icon class="mr-12">refresh</mat-icon>
  </div>
  <div style="margin: 0px auto; max-width: 400px; width: 100%" fxLayout="column">
    <div *ngIf="!loading">
      <ng-container *ngIf="!success">
        <h2 class="mt-20" *ngIf="source" style="text-align: center;font-size: 28px;">
          {{ source.label }}
        </h2>
        <mat-divider></mat-divider>
        <ng-container *ngFor="let item of buttons">
          <mat-card class="m-4 mb-8" mat-raised-button *ngIf="item.dest" style="font-size: 22px;">
            <mat-card-title>{{ item.dest.label | translate }}</mat-card-title>
            <mat-card-content>
              <p>
                {{ item.dest.description | translate }}
              </p>
            </mat-card-content>
            <button (click)="clickProcessButtons(item)" style="width: 100%;" mat-raised-button >
              Wybierz 
            </button>
          </mat-card>
        </ng-container>
        <p class="mt-12" *ngIf="source">
          {{ source.description }}
        </p>
      </ng-container>
      <ng-container *ngIf="success">
        <h2>
          Action Submitted successfully.
        </h2>
      </ng-container>
    </div>
    <div fxLayoutAlign="center center" style="width: 100%; height: 400px;" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>