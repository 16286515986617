<div style="position: relative;" fxLayout="column" *ngIf="!field.hide || builder">

    <div fxLayout="row">
        <p class="happ-symbol"> {{ field.prefix }} </p>
        <mat-form-field color="accent" [ngClass]="{'w-100-p': true, 'happ-small-form-field-outline': field.smallField}" floatLabel="always" appearance="outline" fxFlex>
            <mat-label> {{ field.title | translate }} </mat-label>
            <input type="number" (paste)="pasteIt($event)" matInput minlength="{{field.min_length}}" maxlength="{{field.max_length}}" placeholder="{{field.title | translate}}" [disabled]="field.showdisablecontroltype=='true' || field.disable" (keyup)="onKeyPress()" [hidden]="field.hidden" [(ngModel)]="model">
        </mat-form-field>
    </div>
    <div class="happ-field-description" *ngIf="field.allow_description && field.description"> {{ field.description }} </div>

    <div style="color: red">
        <p *ngIf="min_error"> Min Number Allowed is {{ field.min }} </p>
        <p *ngIf="max_error"> Max Number Allowed is {{ field.max }} </p>
        <p *ngIf="neg_error"> Negative Number not Allowed </p>
        <p *ngIf="dec_error"> Decimal number not Allowed </p>
    </div>
</div> 