import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WsTemplateSelectionComponent } from './ws-template-selection.component';
import { SelectTemplateModule } from '../../global-component/drag-and-drop/select-template/select-template.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [WsTemplateSelectionComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    TranslateModule,
    SelectTemplateModule
  ],
  entryComponents: [
    WsTemplateSelectionComponent
  ]
})
export class WsTemplateSelectionModule { }
