import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss']
})
export class CurrencyComponent implements OnInit {
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() model: any; 
@Input() translateData: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();

  min_error = false;
  max_error = false;
  neg_error = false;
  dec_error = false;
  constructor() { }

    ngOnInit() {
    }
  
    onKeyPress() {
      this.min_error = this.model < this.field.min;
      if (this.model < this.field.min) {
        this.model = this.field.min;
      }
      this.max_error = this.model > this.field.max;
      if (this.model > this.field.max) {
        this.model = this.field.max;
      }

      if (!this.field['allow_negative']) {
        if (this.model < 0) {
          this.neg_error = true;
          this.model = this.model * -1;        
        } else {
        this.neg_error = false;
        }
      }

      if (!this.field['allow_decimal']) {
        if (('' + this.model).indexOf('.') !== -1) {
          // tslint:disable-next-line:radix
          this.model = parseInt(('' + this.model).split('.').join(''));
          this.dec_error = true;
        }
        else {
          this.dec_error = false;
        }
        
      } else if (this.field['allow_decimal']) {
        if (this.field['decimal_places']) {
          this.model = parseFloat(this.model.toFixed(this.field['decimal_places']));
        }
      }
      this.modelChange.next(this.model);
      if (this.field.haveRules) {
        this.onFieldValueUpdated.emit({
          label: this.field.label,
          value: this.model,
          ruleIndex: this.field.ruleIndex,
          field: this.field
        });
      }
    }

    
  pasteIt(eve) {
    eve.preventDefault();
    this.model = (eve && eve.clipboardData && eve.clipboardData.getData('Text')) || this.model;
    this.onKeyPress();
  }
}
