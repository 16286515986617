<div style="background: #f6f6f6;" class="m-0 p-8">
  <div fxFlex fxLayout="header" fxLayoutAlign="space-between center">
    <span class="title dialog-title">
      <p class="m-0 font-size-20 ml-12"> 
        {{ 'popup.office_list' | translate }}
      </p>
    </span>
    <span fxFlex></span>
    <button class="mr-12" (click)="dialogRef.close(null)" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div>
  <div *ngIf="!loading" fxLayoutAlign="center center" fxLayout="column" style="width: 80%; margin: auto;" class="mt-32">
    <div fxLayout="column" *ngIf="data && data.calView" fxLayoutAlign="center center">
      <h2>
        {{ 'popup.calendar_office_selection_heading' | translate }}
      </h2>
      <p>
        {{ 'popup.calendar_office_selection_desc' | translate }}
      </p>
    </div>
    <mat-form-field floatLabel="always" appearance="outline" fxFlex class="happ-small-form-field-outline w-80-p" >
      <mat-label> {{ 'popup.search' | translate }} </mat-label>
      <input matInput [(ngModel)]="searchQuery">
    </mat-form-field>
    <mat-list class="w-100-p mt-20" role="list" *ngIf="officeList && officeList.length">
      <mat-list-item class="happ-list-hover" *ngFor="let office of officeList | filter: searchQuery; let i=index;" role="listitem">
        <div fxLayoutAlign="start center" class="w-100-p" fxLayout="row wrap">
          <mat-icon class="font-size-36 w-36 h-36 mr-12"> {{ office.icon || 'domain' }} </mat-icon>
          <a (click)="changeOffice(office)">
            {{ office.name }}
          </a>
          <span fxFlex></span>
          <div class="w-12-p">
            <button mat-icon-button (click)="onAdd(office, i)">
              <mat-icon [style.color]="(office['starred'])? '#FFB300': 'black'">star</mat-icon>
            </button>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
  <div *ngIf="loading" fxLayoutAlign="center center" fxLayout="column" class="w-100-p" style="height: calc(100vh - 200px);">
    <mat-spinner></mat-spinner>
    <h2> {{ 'popup.pulling_your_offices' | translate }} </h2>
  </div>
  <!-- <mat-nav-list *ngIf="officeList && officeList.length">
    <h3 matSubheader>{{ 'officeList.office_list' | translate }}</h3>
      <a mat-list-item *ngFor="let office of officeList" [routerLink]="['/detail']">
          <span matLine>{{ office.name }}</span>
        <button mat-icon-button (click)="onAdd($event)">
          <mat-icon [style.color]="(office['starred'])? '#FFB300': 'black'">star</mat-icon>
      </button>
      </a>
  </mat-nav-list> -->
</div>