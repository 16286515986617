import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { branch } from 'environments/branch';
import { HelpersService } from '../../services/helpers/helpers.service';

@Component({
  selector: 'app-system-info',
  templateUrl: './system-info.component.html',
  styleUrls: ['./system-info.component.scss']
})
export class SystemInfoComponent implements OnInit {

  version = '';
  branch = '';

  changeLog;

  constructor(
    private helperService: HelpersService
  ) { 
    this.version = environment.version;
    this.branch = branch;
  }

  ngOnInit() {
    this.helperService.getRequest(`api/super-admin/platform-variable/change-log`)
    .then((data) => {
      this.changeLog = data || {};
    }).catch(err => {
      console.log(err);
    });
  }

}
