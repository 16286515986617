import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';

@Component({
  selector: 'app-client-proposal-selection',
  templateUrl: './client-proposal-selection.component.html',
  styleUrls: ['./client-proposal-selection.component.scss']
})
export class ClientProposalSelectionComponent implements OnInit {

  @Input() editNg5Service: any;
  @Input() staticProcessStatus: any;
  @Input() staticCommentField: any;
  @Output() updateDataFromCt: EventEmitter<any> = new EventEmitter();
  @Output() processUpdateRecord: EventEmitter<any> = new EventEmitter();

  @Input() translateData: any;
  selectedProposal;

  recData: any;
  proposals = [];
  constructor(
    private helperService: HelpersService
  ) { }


  ngOnInit() {
    this.recData = this.editNg5Service.formData;
    this.proposals = this.recData.proposals || this.proposals;
  }

  selectProposal(i) {
    this.helperService.openConfirmPopUp("Are you sure you want to accept this time?")
    .subscribe((data) => {
      if (data) {
        this.selectedProposal = i;
        this.updateRec({ selectProposal: this.proposals[i], saveData: true });
      }
    })
  }

  updateRec(data: any) {
    this.updateDataFromCt.emit(data);
  }

}
