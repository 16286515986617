import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { HttpResponse, HttpErrorResponse, HttpResponseBase } from '@angular/common/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/timeout';
import 'rxjs/add/operator/finally';
import 'rxjs/add/observable/timer';
import { of, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
var AuthInterceptor = /** @class */ (function () {
    function AuthInterceptor(injector) {
        this.injector = injector;
        this.CODEMESSAGE = {
            200: 'The server successfully returned the requested data. ',
            201: 'New or modified data is successful. ',
            202: 'A request has entered the background queue (asynchronous task). ',
            204: 'Delete data successfully. ',
            400: 'The request was sent with an error. The server did not perform any operations to create or modify data. ',
            401: 'The user does not have permission (token, username, password is incorrect). ',
            403: 'User is authorized, but access is forbidden. ',
            404: 'The request sent is for a record that does not exist and the server is not operating. ',
            406: 'The format of the request is not available. ',
            410: 'The requested resource is permanently deleted and will not be obtained again. ',
            422: 'When creating an object, a validation error occurred. ',
            500: 'The server has an error. Please check the server. ',
            502: 'Gateway error. ',
            503: 'The service is unavailable, the server is temporarily overloaded or maintained. ',
            504: 'The gateway timed out. ',
        };
    }
    AuthInterceptor.prototype.checkStatus = function (ev) {
        if ((ev.status >= 200 && ev.status < 300) || ev.status === 401) {
            return;
        }
        return;
    };
    AuthInterceptor.prototype.handleData = function (ev) {
        this.checkStatus(ev);
        switch (ev.status) {
            case 200:
                if (ev instanceof HttpResponse) {
                    var body = ev.body;
                    if (body && body.success) {
                        return of(new HttpResponse(tslib_1.__assign({}, ev, { body: body || {} })));
                    }
                    else {
                        if (body.message) {
                            this.notification.error('Backend Error:', body.message);
                        }
                        return of(new HttpResponse(tslib_1.__assign({}, ev, { body: body || {} })));
                    }
                }
                else {
                    return of(ev);
                }
                // }
                break;
            case 401:
                if (ev instanceof HttpErrorResponse) {
                    // const errortext = (ev.error && ev.error.message) || this.CODEMESSAGE[ev.status];
                    // this.notification.error(errortext, ev.statusText);
                    // this.
                    localStorage.clear();
                    document.cookie.split(';').forEach(function (c) { document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/'); });
                    console.log("movign toe login screen");
                    this.goTo('/login');
                    return of(new HttpResponse(tslib_1.__assign({}, ev, { body: ev.error || {} })));
                }
                // Empty token information
                break;
            case 403:
            case 404:
            case 500:
                // this.goTo(`/exception/${ev.status}`);
                if (ev instanceof HttpErrorResponse) {
                    var body = ev.error;
                    var errortext = ev.statusText || this.CODEMESSAGE[ev.status];
                    this.notification.error(body.message, errortext);
                    return of(new HttpResponse(tslib_1.__assign({}, ev, { body: body || {} })));
                }
                break;
            default:
                if (ev instanceof HttpErrorResponse) {
                    var errortext = ev.statusText || this.CODEMESSAGE[ev.status];
                    this.notification.error('I don\'t know the error, maybe the backend is down or restarting try after some time.', '');
                    return throwError(ev);
                }
                break;
        }
        return of(ev);
    };
    AuthInterceptor.prototype.goTo = function (url) {
        var _this = this;
        setTimeout(function () { return _this.injector.get(Router).navigateByUrl(url); });
    };
    Object.defineProperty(AuthInterceptor.prototype, "notification", {
        get: function () {
            return this.injector.get(ToastrService);
        },
        enumerable: true,
        configurable: true
    });
    AuthInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        // Unified plus server prefix
        var url = req.url;
        // if (!url.startsWith('https://') && !url.startsWith('http://')) {
        //   url = environment.serverUrl + url;
        // }
        // let headers = req.headers;
        // if (this.tokenService.get()) {
        //   headers = headers.append('Authorization', this.tokenService.get().token);
        // }
        var newReq = req.clone({ url: url });
        return next.handle(newReq).pipe(mergeMap(function (event) {
            // Allow unified handling of request errors
            if (event instanceof HttpResponseBase)
                return _this.handleData(event);
            // If everything is ok, follow-up
            return of(event);
        }), catchError(function (err) { return _this.handleData(err); }));
    };
    return AuthInterceptor;
}());
export { AuthInterceptor };
