import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NgSummernoteService {

  onHintChange: Subject<object> = new Subject<Array<object>>();
  onValueChange: Subject<String> = new Subject<String>();

  constructor() { 
  }


}
