<div *ngIf="data">
    <mat-toolbar style="background: #f6f6f6;" matDialogTitle class="m-0">
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
          <button class="mr-12" (click)="dialogRef.close(null)" mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
          <span class="title dialog-title"> {{ 'global.description' | translate }} </span>
          <span fxFlex></span>
          <button class="mr-12" (click)="dialogRef.close(data.initValue)" mat-icon-button>
              <mat-icon>check</mat-icon>
            </button>
        </div>
      </mat-toolbar>
  <div class="m-12"  >
      <ng-container *ngIf="data">
        <div *ngIf="data.allowSwitch">
          <span fxFlex></span>
          <div>
            <mat-slide-toggle (change)="toggleEditor()" >Toggle editor</mat-slide-toggle>
          </div>
        </div>
          <div [ngSwitch]="data.fieldType">
            <div *ngSwitchCase="'textArea'">
                <mat-form-field class="happ-small-form-field-outline w-100-p py-12" floatLabel="never" floatLabel="always" appearance="outline" fxFlex="100">
                  <mat-label></mat-label>
                  <textarea matInput rows="8" style="width: 100%;" [(ngModel)]="data.initValue" placeholder=""></textarea>
                </mat-form-field>
            </div>
        
            <div class="p-4" *ngSwitchCase="'editor'">
              <app-html-editor type="admin" [height]="420" [(model)]="data.initValue" ></app-html-editor>
            </div>
          </div>
        </ng-container>
  </div>
</div>