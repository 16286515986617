import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { FabSpinnerButtonOptions } from 'app/main/content/consts/progressButtonOptions.const';
import { MatProgressButtonOptions } from '../../mat-progress-buttons/module/mat-progress-buttons.interface';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
  selector: 'app-reg-value-selection',
  templateUrl: './reg-value-selection.component.html',
  styleUrls: ['./reg-value-selection.component.scss']
})
export class RegValueSelectionComponent implements OnInit, OnDestroy {

  showList: boolean;
  resData: any;
  searchMultiFileds;
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() model;
  @Input() recordData;
  @Input() translateData: any;
  @Input() model2;
  @Input() regId;
  @Input() recId;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();  
  @Output() updateFormDataObj: EventEmitter<Object> = new EventEmitter();
  @Output() model2Change: EventEmitter<Object> = new EventEmitter();  
  public officeId: string;
  allPushed = {};
  buttonOptions: MatProgressButtonOptions;
  fieldToShow: any = [];
  fieldToStore: any = [];
  showSearch: boolean;

  constructor(
    private helperService: HelpersService,
    private _translationLoader: FuseTranslationLoaderService,

  ) { }

  ngOnInit() {
    this._translationLoader.loadTranslationsV2(this.translateData);

    if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))) {
      this.officeId = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'] || '';
    }

    console.log(" model = ", this.model);

    if (!this.model) {
      this.showList = true;
      this.showSearch = true
    }

    this.buttonOptions = JSON.parse(JSON.stringify(FabSpinnerButtonOptions));
    this.buttonOptions.iconButton = true;
    this.buttonOptions.spinnerSize = 40;
    this.buttonOptions.icon = 'search';

    if (this.field.multiSelect) {
      this.getDataFromServer('');
      this.model = this.model || [];
      this.model.forEach((ele) => {
        this.allPushed[ele.key] = true;
      });
    }    
    this.fieldToStore = this.field.field_to_show.filter((ele) => (ele.show)).map((ele) => (ele.storeKey))
    console.log("fieldToStore = ", this.fieldToStore, this.field)

  }

  
  onChange() {
  this.modelChange.next(this.model);
    if (this.field.multiSelect) {
      this.model = this.model || [];
      this.model2 = '';
      this.model.forEach((ele) => {
        ele.value = ele.value || {};
        // tslint:disable-next-line:forin
        for (const i in ele.value) {
          this.model2 += ele.value[i] + ', ';
        }
      });
      this.model2 = this.model2.substr(0, this.model2.length - 2);
      this.model2Change.next(this.model2);
    }
    this.onFieldValueUpdated.emit({
        label: this.field.label,
        value: this.model,
        ruleIndex: this.field.ruleIndex,
        field: this.field
      });


  }

  ngOnDestroy(): void {
    this.buttonOptions.active = false;    
  }

  getDataFromServer(filterVal: string) {
    const filters = {}; 
    const search = {
      regex: false
    };

    if (this.field['filters']) {
      this.field['filters'].forEach(element => {
        filters[element.key] = element.value;
      });
    }

    const columns: any = {};

    this.field.field_to_show = this.field.field_to_show || [];

    this.field.field_to_show.forEach((ele) => {
      columns[ele.key] = 1
    })

    this.fieldToShow = this.field.field_to_show.filter((ele) => (ele.show)).map((ele) => (ele.key))
    
    if (this.field.field_to_show.length) {

      search['value'] = filterVal;

      const workspace: any = {};

      let wsApp: any = window.localStorage.getItem("wsAppData")
      if (wsApp) {
        wsApp = JSON.parse(wsApp);
        workspace.index = wsApp.stepNumber;
      }

      this.buttonOptions.active = true;
      const sort: any = {};
      sort[this.field['field_key']] = 1;
      this.helperService.postRequest(`api/reg/${this.field.regId}/data-table`, 
      { columnFields: columns, workspace, search: search, filters: {}, filterRecord : {}, mqFilter: this.field['hardFilters'], sort: sort })      
      .then((data) => {
        console.log("data", data);
        this.resData = [];
        this.resData = data.dttable.data || [];
        this.buttonOptions.active = false;
      }).catch(err => {
        this.buttonOptions.active = false;
        console.info(err);
      });
    } else {
      console.error('Error in fields selction');
      
    }   
    this.showList = true;  
  }


  selectVal(option) {
    if (!this.field['only_display']) {
      const val = {};

      val[this.field.label] = option._id;
      this.model = option._id
    
      if (this.field['field_to_show'] && this.field['field_to_show'].length > 0) {
        this.field['field_to_show'].forEach(ele => {
          if (ele.store) {
            val[ele.storeKey] = option[ele.key];
          }
        });
      }

      console.log("Val = ", val);
      if (Object.keys(val).length) {
        this.updateFormDataObj.emit(val);
      }
        this.showList = false;
        this.showSearch = false;

      return;

      if (this.field.multiSelect || true) {
        this.model = this.model || [];
        if (!this.allPushed[option._id] || true) {
          this.model.push({key: option._id, value: val});
          this.model.forEach((ele) => {
            this.allPushed[ele.key] = true;
          });
          this.onChange(); 
        } else {
          // remove
          this.model = this.model.filter(ele => ele.key !== option._id);
          this.allPushed[option._id] = false;
          this.onChange(); 
        }
      } else {
        this.model = {key: option._id, value: val};
        this.onChange(); 
        this.showList = false;
        const linkData = {
          src_reg: this.regId,
          src_rec: this.recId,
          dest_reg: option.regId,
          dest_rec: option._id,
          main: false
        };
        this.helperService.createNewLink(linkData)
        .then(() => {
          // this..active = false;
          this.helperService.openSnackBar('Record Added');
        }).catch(err => {
          console.info(err);
        });

        this.helperService.updateRegisterRecord(this.regId, this.recId, {
          parentId: option['_id']
        }).then((data) => {
        }).catch(err => {
          console.info(err);
        });
      }
      
      
    }
  }

}
