import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'textTranslate',
    pure: false
})
export class TextTranslatePipe implements PipeTransform
{

    defaultEnableLang = {
        en: true,
        pl: true,
        ru: true,
        es: true,
        de: true,
    };

    /**
     * Transform
     *
     * @param {any[]} mainArr
     * @param {string} searchText
     * @param {string} property
     * @returns {any}
     */
    transform(data: any, langKey: any, enabledLang: any = this.defaultEnableLang): any
    {
        data = data || {};
        if (!enabledLang[langKey]) {
            langKey = 'en';
        }
        return data[langKey] || data['en'] || '';
    }
}
