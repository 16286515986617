<!-- <div fxLayout="column"  *ngIf="!field.hide">
  <mat-form-field fxFlex class="example-full-width">
      <input matInput placeholder="{{field.title | translate}}" [(ngModel)]="model" (blur)="onKeyPress()" fxFlex #temp (keyup)="changed(temp.value)" [disabled]="field.showdisablecontroltype=='true' || field.disable" [hidden]="field.hidden" aria-label="State" [matAutocomplete]="auto">
      <mat-autocomplete (optionSelected)="optionSelected()" #auto="matAutocomplete">
          <mat-option *ngFor="let country of filteredList" [value]="country">
              <span>{{ country }}</span>
          </mat-option>
      </mat-autocomplete>
  </mat-form-field>
</div> -->
<div fxLayout="column"  *ngIf="!field.hide">
    <mat-form-field color="accent" floatLabel="always" appearance="outline" class="w-100-p" floatPlaceholder="never">
        <mat-chip-list #userList>
            <mat-chip *ngFor="let country of countries"
                        removable="true" (removed)="removeUser(country)">
                {{country}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
        <mat-label> {{ field.title | translate }} </mat-label>
        <input matInput
            aria-label="user_autocomplete_input"
            class="w-100-p"
            matChipInputAddOnBlur="true"
            [(ngModel)]="inputValue"
            [placeholder]="field.title | translate"
            [matAutocomplete]="auto" 
            (blur)="addCountry($event, temp.value)"
            (keyup.enter)="addCountry($event, temp.value)"
            #temp (keyup)="inputValueChanged($event, temp.value)"
            [matChipInputFor]="userList"/>
            <mat-autocomplete (optionSelected)="addCountry($event, null)" #auto="matAutocomplete">
            <mat-option *ngFor="let user of filterValue" [value]="user">
                <span>{{user}}</span>
            </mat-option>
            </mat-autocomplete>
        </mat-form-field>
</div>