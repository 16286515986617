import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { ProfileHelperService } from 'app/main/content/services/profile-helper/profile-helper.service';
import * as _ from 'lodash';
var ProviderServiceSelectionComponent = /** @class */ (function () {
    function ProviderServiceSelectionComponent(_helperService, _profileHelper) {
        this._helperService = _helperService;
        this._profileHelper = _profileHelper;
        this.updateDataFromCt = new EventEmitter();
        this.processUpdateRecord = new EventEmitter();
        this.filteredList = [];
        this.selectedValues = [];
        this.comment = '';
        this.allComments = [];
        this.staticList = [];
        this.selectedServiceValues = [];
        this.showAllComments = false;
        this.view = 'step1';
    }
    ProviderServiceSelectionComponent.prototype.ngOnInit = function () {
        this.performInitOperation();
        this.getAllProviderServices();
        this.getLoggedUser();
        this.allComments = (this.editNg5Service && this.editNg5Service.formData && this.editNg5Service.formData.recComment) || [];
        this.allComments = this._helperService.transformSort(this.allComments, 'creatingTime');
    };
    ProviderServiceSelectionComponent.prototype.getAllProviderServices = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this._helperService.getRequest("api/shared-service/available-provider-services/" + this.editNg5Service.formData.providerOfficeId)];
                    case 1:
                        _a.filteredList = _b.sent();
                        this.filteredList = this.filteredList.map(function (ele) {
                            ele.serviceName = ele.serviceName || (ele && ele.serviceData && ele.serviceData.name);
                            if (!Array.isArray(ele.serviceVariants)) {
                                ele.serviceVariants = [];
                            }
                            ele.serviceVariants = ele.serviceVariants || [];
                            ele.serviceVariantsArray = ele.serviceVariants || [];
                            ele.selectedVariant = [ele.serviceVariantsArray[0] || {}];
                            return ele;
                        });
                        this.staticList = JSON.parse(JSON.stringify(this.filteredList));
                        return [2 /*return*/];
                }
            });
        });
    };
    ProviderServiceSelectionComponent.prototype.performInitOperation = function () {
        this.selectedServiceValues = this.selectedServiceValues || [];
        this.selectedValues = this.selectedServiceValues.map(function (ele) { return ele._id; });
        this.moveData = this.moveData || ['_id', 'serviceName', "selectedVariant", "serviceVariantsArray", "requiredPayment"];
    };
    ProviderServiceSelectionComponent.prototype.filterOutRegisters = function () {
        var _this = this;
        this.filteredList = this.staticList.filter(function (ele) {
            if (ele && ele._id && _this.selectedValues.includes(ele._id)) {
                return false;
            }
            return true;
        });
    };
    ProviderServiceSelectionComponent.prototype.addRegisterToList = function (regData) {
        var temp = {};
        this.moveData.forEach(function (ele) {
            temp[ele] = regData[ele];
        });
        this.selectedServiceValues.push(temp);
        console.log(this.selectedServiceValues);
        this.selectedValues.push(regData._id);
        this.filterOutRegisters();
    };
    ProviderServiceSelectionComponent.prototype.removeRegisterFromList = function (regData) {
        var index = _.findIndex(this.selectedServiceValues, { _id: regData._id });
        if (index >= 0) {
            this.selectedServiceValues.splice(index, 1);
            this.selectedValues.splice(this.selectedValues.indexOf(regData._id), 1);
            this.filterOutRegisters();
        }
    };
    ProviderServiceSelectionComponent.prototype.onNext = function () {
        this.updateDataFromCt.emit({ selectedServiceValues: this.selectedServiceValues });
    };
    ProviderServiceSelectionComponent.prototype.getLoggedUser = function () {
        var _this = this;
        this._profileHelper.getLocalProfileData()
            .then(function (data) {
            _this.currentUser = {
                id: data.profileId,
                email: data.email
            };
        }).catch(function (err) {
            console.info(err);
        });
    };
    ProviderServiceSelectionComponent.prototype.testComment = function () {
        if (this.comment) {
            this.allComments = this.allComments || [];
            this.allComments.push({
                description: this.comment,
                creatorId: this.currentUser.id,
                creator: this.currentUser.email,
                creatingTime: new Date().getTime()
            });
            this.comment = '';
        }
    };
    ProviderServiceSelectionComponent.prototype.processUpdateRecordTrigger = function (event) {
        if (this.comment) {
            this.editNg5Service.formData.recComment = this.editNg5Service.formData.recComment || [];
            this.editNg5Service.formData.recComment.push({
                description: this.comment,
                creatorId: this.currentUser.id,
                creator: this.currentUser.email,
                creatingTime: new Date().getTime()
            });
            this.comment = '';
        }
        this.processUpdateRecord.emit(event);
    };
    return ProviderServiceSelectionComponent;
}());
export { ProviderServiceSelectionComponent };
