import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { HelpersService } from '../../services/helpers/helpers.service';
import * as _ from 'lodash';
var DataTableColumnSettingsComponent = /** @class */ (function () {
    function DataTableColumnSettingsComponent(dialogRef, data, helperService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.helperService = helperService;
        this.formFields = [];
        this.currentRec = {
            title: '',
            label: '',
            sort: 50,
            columnAlign: 'left'
        };
        this.editIndex = null;
    }
    DataTableColumnSettingsComponent.prototype.ngOnInit = function () {
        this.data = this.data || {};
        this.data.dtTableColumns = this.data.dtTableColumns || [];
        // this.getForm();
    };
    DataTableColumnSettingsComponent.prototype.getForm = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var formData, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        if (!(this.data && this.data.formId)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.helperService.getForm(this.data.formId)];
                    case 1:
                        formData = _a.sent();
                        this.formFields = this.helperService.getFormFlatField(formData.ng5Form);
                        this.formName = formData.title;
                        this.formatFields();
                        return [3 /*break*/, 3];
                    case 2:
                        this.helperService.showInfo('No From Id found', '', 'error');
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        this.helperService.showInfo(error_1, '', 'error');
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    DataTableColumnSettingsComponent.prototype.formatFields = function () {
        var _this = this;
        var fieldsToCopy = ['columnAlign', 'sort', 'show_on_grid'];
        this.data.dtTableColumns.forEach(function (ele) {
            var index = _.findIndex(_this.formFields, { label: ele.label });
            fieldsToCopy.forEach(function (ele2) {
                _this.formFields[index][ele2] = ele[ele2];
            });
        });
    };
    DataTableColumnSettingsComponent.prototype.saveData = function () {
        var temp = this.formFields.filter(function (ele) { return (ele && ele.show_on_grid); });
        this.dialogRef.close(this.data.dtTableColumns);
    };
    DataTableColumnSettingsComponent.prototype.addNew = function () {
        if (this.currentRec.label && this.currentRec.title) {
            if (this.editIndex !== null) {
                this.data.dtTableColumns[this.editIndex] = this.currentRec;
                this.editIndex = null;
            }
            else {
                this.data.dtTableColumns.push((this.currentRec));
            }
            this.currentRec = {
                title: '',
                label: '',
                sort: 50,
                columnAlign: 'left'
            };
        }
        else {
            this.helperService.showInfo('Title and KeyName ir required to add field', '', 'error');
        }
    };
    return DataTableColumnSettingsComponent;
}());
export { DataTableColumnSettingsComponent };
