<div fxLayout="row">
  <!-- left side sort options -->
  <div fxLayout="row" fxLayoutAlign="center center" class="ml-12">
      <p class="mr-12"> {{ 'dttable.sort' | translate }} </p>
      <mat-form-field class="happ-small-form-field-outline" floatLabel="never" floatLabel="always" appearance="outline">
          <mat-select (selectionChange)="sortFieldChange()" [(ngModel)]="sortByField">
              <mat-option [value]="'registredDataTime'">
              {{ 'dttable.register_date_time' | translate }}
              </mat-option>
              <mat-option [value]="'modificatorDateTime'">
              {{ 'dttable.modificator_date_time' | translate }}
              </mat-option>
              <mat-option *ngFor="let item of cardActions | filter:true:'useForSort'" [value]="item.keyName">
              {{ item.name }}
              </mat-option>
          </mat-select>
      </mat-form-field>
      <button aria-label="datatable_settings_button" style="margin: auto 2px;" [@rotatedButton180]='sortByOrder' (click)="toggleSort()" mat-icon-button>
          <mat-icon style="color: black">sort</mat-icon>
      </button>
  </div>
  <span fxFlex></span>
  <!-- right side settings options. -->
  <div fxLayout="row" class="mr-8" style="margin: auto;">
    <p class="mr-4"> {{ 'dttable.settings' | translate }} </p>
      <button aria-label="datatable_settings_button" style="margin: auto 2px;" *ngIf="isAdmin"
        matTooltip="{{ 'dttable.settings' | translate }}" (click)="openWSAppsSettingsClicked.next(true)" mat-icon-button>
        <mat-icon style="color: black">settings</mat-icon>
      </button>
    </div>
</div>