import { IConditionList } from './../../interfaces/fieldRules.interface';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { INg5Form, IFormInterface } from '../../interfaces/form.interface';
import { IFieldAction, IFieldRule } from '../../interfaces/fieldRules.interface';



@Injectable()
export class FormRulesService {

  constructor() {
  }

  checkAllRules(ng5FormFields: INg5Form[], rules: IFieldRule[], recordData: any, isInit?: boolean): INg5Form[] {


    console.log("checkAllRules = ", ng5FormFields, rules, recordData, isInit);


    rules = rules || <any>[];
    rules.forEach((rule: IFieldRule, i) => {
        if (!isInit) {
            ng5FormFields = this.resetFieldsVisibility(ng5FormFields, rule.actions);
            ng5FormFields = this.resetComplexActions(ng5FormFields, rule.actions, i);
        }
    });
    rules.forEach((rule: IFieldRule, i) => {
        ng5FormFields = this.markAllFieldsForRule(ng5FormFields, rule.conditionList, i);
        let conditionMet = false;
        if (rule.conditionsMet === 'any') {
            conditionMet = this.isAnyCondition(recordData, rule.conditionList);
        } else if (rule.conditionsMet === 'all') {
            conditionMet = this.isAllCondition(recordData, rule.conditionList);
        }

     

        // if condition met perform operations..
        if (conditionMet) {
            ng5FormFields = this.performSimpleActions(ng5FormFields, rule.actions, i);
            ng5FormFields = this.performComplexActions(ng5FormFields, rule.actions, isInit);
        }

    });
    return ng5FormFields;
  }
  

  markAllFieldsForRule(formFields, conditionList, ruleIndex) {
    formFields = formFields || [];
    conditionList = conditionList || [];
    formFields.forEach((formField: INg5Form) => {
        conditionList.forEach((condition) => {
            const fieldIndex = _.findIndex(formField.tabContent, { label: condition.fieldLabel });
            if (fieldIndex >= 0) {
                formField.tabContent[fieldIndex]['haveRules'] = true;
                formField.tabContent[fieldIndex]['ruleIndex'] = ruleIndex;
                // cons
            }
            // check for section type..
            formField.tabContent.forEach((field) => {
                if (field.control_type === 'section') {
                    field.children.forEach((sectionFields) => {
                        sectionFields = sectionFields || [];
                        const fieldIndex2 = _.findIndex(sectionFields, <any>{ label: condition.fieldLabel });
                        if (fieldIndex2 >= 0) {
                            sectionFields[fieldIndex2]['haveRules'] = true;
                            sectionFields[fieldIndex2]['ruleIndex'] = ruleIndex;
                        }
                    });
                }
            });
        });
    });
    return formFields;
  }

  isAllCondition(recordData: any, conditionList: IConditionList[]): boolean {
    conditionList = conditionList || [];
    recordData = recordData || {};
    conditionList = conditionList.filter((ele: IConditionList) => {
        if (ele && ele.condition === 'is') {
            return !(recordData[ele.fieldLabel] !== null && recordData[ele.fieldLabel] !== undefined && recordData[ele.fieldLabel].toString() === ele.value);
        } else if (ele && ele.condition === 'isNot') {
            return !(recordData[ele.fieldLabel] !== null && recordData[ele.fieldLabel] !== undefined && recordData[ele.fieldLabel].toString() !== ele.value);
        }
    });
      return (conditionList.length === 0) ? true : false;
  }
  
  isAnyCondition(recordData: any, conditionList: any): boolean {
    conditionList = conditionList || [];
    recordData = recordData || {};
    const tt = conditionList.filter((ele: IConditionList) => {
        if (ele && ele.condition === 'is') {
            return !(recordData[ele.fieldLabel] !== null && recordData[ele.fieldLabel] !== undefined && recordData[ele.fieldLabel].toString() === ele.value);
        } else if (ele && ele.condition === 'isNot') {
            return !(recordData[ele.fieldLabel] !== null && recordData[ele.fieldLabel] !== undefined && recordData[ele.fieldLabel].toString() !== ele.value);
        }
    });
      return (conditionList.length === tt.length) ? false : true;
  }

  //   for show and hide only
  performSimpleActions(formFields: INg5Form[], actions: IFieldAction[], ruleIndex: number): INg5Form[] {
    actions = actions || [];
    formFields = formFields || [];
    actions.forEach((action: IFieldAction) => {
        if (action.action === 'showOptions' || action.action === 'hideOptions') {
            return;
        }
        formFields.forEach((formField: INg5Form) => {
            action.fieldLabel = action.fieldLabel || [];
            action.fieldLabel.forEach((actionField) => {
                const fieldIndex = _.findIndex(formField.tabContent, { label: actionField });
                if (fieldIndex >= 0) {
                    if (formField.tabContent[fieldIndex]['defaultHide'] === null || formField.tabContent[fieldIndex]['defaultHide'] === undefined) {
                        formField.tabContent[fieldIndex]['defaultHide'] = formField.tabContent[fieldIndex]['hide'];
                    }
                    formField.tabContent[fieldIndex]['hide'] = (action.action === 'hide') ? true : false;
                }
                // check for section type..
                formField.tabContent.map((field) => {
                    if (field.control_type === 'section') {
                        field.children.forEach((sectionFields) => {
                            sectionFields = sectionFields || [];
                            const fieldIndex2 = _.findIndex(sectionFields, <any>{ label: actionField });
                            if (fieldIndex2 >= 0) {
                                if (sectionFields[fieldIndex2]['defaultHide'] === null || sectionFields[fieldIndex2]['defaultHide'] === undefined) {
                                    sectionFields[fieldIndex2]['defaultHide'] = sectionFields[fieldIndex2]['hide'];
                                }
                                sectionFields[fieldIndex2]['hide'] = (action.action === 'hide') ? true : false;
                            }
                        });
                    }
                    return field;
                });
            });
        });
    });
    return formFields;
  }

  resetFieldsVisibility(formFields, actions) {
    actions = actions || [];
    formFields = formFields || [];
    actions.forEach((action: IFieldAction) => {
        if (action.action === 'showOptions' || action.action === 'hideOptions') {
            return;
        }
        formFields.forEach((formField: INg5Form) => {
            if (typeof(action.fieldLabel) === 'string') {
                action.fieldLabel = [action.fieldLabel];
            }
            action.fieldLabel = action.fieldLabel || [];
            action.fieldLabel.forEach((actionField) => {
                const fieldIndex = _.findIndex(formField.tabContent, { label: actionField });
                if (fieldIndex >= 0) {
                    formField.tabContent[fieldIndex]['hide'] = formField.tabContent[fieldIndex]['defaultHide'];
                }
                // check for section type..
                formField.tabContent.forEach((field) => {
                    if (field.control_type === 'section') {
                        field.children.forEach((sectionFields) => {
                            sectionFields = sectionFields || [];
                            const fieldIndex2 = _.findIndex(sectionFields, <any>{ label: actionField });
                            if (fieldIndex2 >= 0) {
                                sectionFields[fieldIndex2]['hide'] = sectionFields[fieldIndex2]['defaultHide'];
                            }
                        });
                    }
                });
            });
        });
    });
    return formFields;
  }

  resetComplexActions(formFields: INg5Form[], actions: IFieldAction[], ruleIndex: number): INg5Form[] {
    actions = actions || [];
    formFields = formFields || [];
    actions.forEach((action: IFieldAction) => {
        if (action.action === 'show' || action.action === 'hide') {
            return;
        }
        formFields.forEach((formField: INg5Form) => {
            const fieldIndex = _.findIndex(formField.tabContent, { label: action.fieldLabel });
            if (fieldIndex >= 0) {
                if (formField.tabContent[fieldIndex]['hide'] === undefined) {
                    formField.tabContent[fieldIndex]['defaultHide'] = formField.tabContent[fieldIndex]['hide'];
                }
                formField.tabContent[fieldIndex]['hide'] = true;
                formField.tabContent[fieldIndex]['options'] = formField.tabContent[fieldIndex]['allOptions'] || [];
                formField.tabContent[fieldIndex]['hide'] = formField.tabContent[fieldIndex]['defaultHide']; 
            }
            // check for section type..
            formField.tabContent.forEach((field) => {
                if (field.control_type === 'section') {
                    field.children.forEach((sectionFields) => {
                        sectionFields = sectionFields || [];
                        const fieldIndex2 = _.findIndex(sectionFields, <any>{ label: action.fieldLabel });
                        if (fieldIndex2 >= 0) {
                            if (sectionFields[fieldIndex2]['defaultHide'] === undefined) {
                                sectionFields[fieldIndex2]['defaultHide'] = sectionFields[fieldIndex2]['hide']
                            }
                            sectionFields[fieldIndex2]['hide'] = true;
                            sectionFields[fieldIndex2]['options'] = sectionFields[fieldIndex2]['allOptions'] || [];
                            setTimeout(() => { 
                                sectionFields[fieldIndex2]['hide'] = sectionFields[fieldIndex2]['defaultHide']; 
                            }, 10);

                        }
                    });
                }
            });
        });
    });
    return formFields;
  }

  //   for showOptions and hideOptions
  performComplexActions(formFields: INg5Form[], actions: IFieldAction[], isInit): INg5Form[] {
    actions = actions || [];
    formFields = formFields || [];
    actions.forEach((action: IFieldAction) => {
        if (action.action === 'show' || action.action === 'hide') {
            return;
        }
        formFields.forEach((formField: INg5Form) => {
            const fieldIndex = _.findIndex(formField.tabContent, { label: action.fieldLabel });
            if (fieldIndex >= 0) {
                if (formField.tabContent[fieldIndex]['defaultHide'] === undefined) {
                    formField.tabContent[fieldIndex]['defaultHide'] = formField.tabContent[fieldIndex]['hide'];
                }
                formField.tabContent[fieldIndex]['options'] = action.allOptions || [];
                if (action.action === 'showOptions') {
                    formField.tabContent[fieldIndex]['hide'] = true;
                    formField.tabContent[fieldIndex]['optionChanged'] = true;
                    if (isInit) {
                        formField.tabContent[fieldIndex]['allOptions'] = formField.tabContent[fieldIndex]['options'] || [];    
                    }
                    formField.tabContent[fieldIndex]['options'] = 
                    formField.tabContent[fieldIndex]['allOptions'].filter((option) => (action.options.includes(option.value || option.title)));
                }
                if (action.action === 'hideOptions') {
                    formField.tabContent[fieldIndex]['hide'] = true;
                    formField.tabContent[fieldIndex]['optionChanged'] = true;
                    if (isInit) {
                        formField.tabContent[fieldIndex]['allOptions'] = formField.tabContent[fieldIndex]['options'] || [];    
                    }
                    formField.tabContent[fieldIndex]['options'] = 
                    formField.tabContent[fieldIndex]['allOptions'].filter((option) => (!action.options.includes(option.value || option.title)));
                }
                setTimeout(() => { formField.tabContent[fieldIndex]['hide'] = formField.tabContent[fieldIndex]['defaultHide']; }, 10);
            }
            // check for section type..
            formField.tabContent.forEach((field) => {
                if (field.control_type === 'section') {
                    field.children.forEach((sectionFields) => {
                        sectionFields = sectionFields || [];
                        const fieldIndex2 = _.findIndex(sectionFields, <any>{ label: action.fieldLabel });
                        if (fieldIndex2 >= 0) {
                            // const t2 = sectionFields[fieldIndex2]['hide'];
                            if (sectionFields[fieldIndex2]['defaultHide'] === undefined) {
                                sectionFields[fieldIndex2]['defaultHide'] = sectionFields[fieldIndex2]['hide'];
                            }
                            sectionFields[fieldIndex2]['options'] = action.allOptions || [];
                            if (action.action === 'showOptions') {
                                sectionFields[fieldIndex2]['hide'] = true;
                                sectionFields[fieldIndex2]['optionChanged'] = true;
                                if (isInit) {
                                    sectionFields[fieldIndex2]['allOptions'] = sectionFields[fieldIndex2]['options'] || [];    
                                }
                                sectionFields[fieldIndex2]['options'] = 
                                sectionFields[fieldIndex2]['allOptions'].filter((option) => (action.options.includes(option.value || option.title)));
                            }
                            if (action.action === 'hideOptions') {
                                sectionFields[fieldIndex2]['hide'] = true;
                                sectionFields[fieldIndex2]['optionChanged'] = true;
                                if (isInit) {
                                    sectionFields[fieldIndex2]['allOptions'] = sectionFields[fieldIndex2]['options'] || [];    
                                }
                                sectionFields[fieldIndex2]['options'] = 
                                sectionFields[fieldIndex2]['allOptions'].filter((option) => (!action.options.includes(option.value || option.title)));
                            }
                            setTimeout(() => { sectionFields[fieldIndex2]['hide'] = sectionFields[fieldIndex2]['defaultHide']; }, 10);
                        }
                    });
                }
            });
        });
    });
    return formFields;
  }

  onValueUpdate(formFields: INg5Form[], rules: IFieldRule[], registerRecord: any, ruleIndex: number): INg5Form[] {
        // const rule = rules[ruleIndex];
        // let conditionMet = false;
        // if (rule.conditionsMet === 'any') {
        //     conditionMet = this.isAnyCondition(registerRecord, rule.conditionList);
        // } else if (rule.conditionsMet === 'all') {
        //     conditionMet = this.isAllCondition(registerRecord, rule.conditionList);
        // }

        // // if condition met perform operations..
        // if (conditionMet) {
        //     formFields = this.performSimpleActions(formFields, rule.actions, ruleIndex);
        //     formFields = this.performComplexActions(formFields, rule.actions, ruleIndex);
        // } else {
        //     formFields = this.resetFieldsVisibility(formFields, rule.actions);
        //     formFields = this.resetComplexActions(formFields, rule.actions, ruleIndex);
        // }
        // return formFields;
        return this.checkAllRules(formFields, rules, registerRecord, false);
    }
}
