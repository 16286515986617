<button mat-button
  [type]="options.type"
  [color]="options.buttonColor"
  [class.active]="options.active"
  [class.fullWidth]="options.fullWidth"
  [class.mat-raised-button]="options.raised"
  [class.mat-icon-button]="options.iconButton"
  [class.mat-stroked-button]="options.stroked"
  [class.mat-flat-button]="options.flat"
  [class.mat-fab]="options.fab"
  [disabled]="options.disabled">

  <span
    class="button-text"
    *ngIf="!options.fab && !options.iconButton"
    [class.active]="options.active && !options.disabled">
    <ng-content></ng-content>
  </span>

  <ng-container
    *ngIf="(options.fab || options.iconButton) && options.icon">
    <mat-icon>{{ options.icon }}</mat-icon>
  </ng-container>
  
  <mat-spinner class="spinner"
    [diameter]="options.fab ? 58 : options.spinnerSize"
    [color]="options.spinnerColor"
    [style.margin-top]="options.iconButton ? '-10px': ''"
    [mode]="options.mode"
    [value]="options.value"
    [class.active]="options.active && !options.disabled">
  </mat-spinner>
</button>
