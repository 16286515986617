import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { CtSsCatPopupComponent } from './ct-ss-cat-popup/ct-ss-cat-popup.component';

@Component({
  selector: 'app-ct-ss-cat-selection',
  templateUrl: './ct-ss-cat-selection.component.html',
  styleUrls: ['./ct-ss-cat-selection.component.scss']
})
export class CtSsCatSelectionComponent implements OnInit {

  @Input() field;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() model;
  @Input() translateData: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Input() hideTitle: boolean;
  @Input() builder;
  @Input() formData: any;
  @Output() valChange: EventEmitter<Object> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onError: EventEmitter<Object> = new EventEmitter();
  
  catData: any = [];
  ssType: any = [];
  category;
  type;
  filteredType: any = [];

  constructor(
    private helperService: HelpersService,
    public dialog: MatDialog,

  ) { }

  ngOnInit() {
    this.getData();
    this.model = this.model || {
      areas: [],
      types: [],
      typesObj: {}
    }
  }

  async getData() {
    try {
      const data = await this.helperService.getRequest(`api/super-admin/platform-variable/shared-service-category`)
      this.catData = (data && data.data) || [];
      const data2 = await this.helperService.getRequest(`api/external-page/shared-service-types`);
      this.ssType = (data2 && data2.data) || [];
      if (this.model && this.model.category) {
        this.onCatChange();
      }
    } catch (error) {
      alert(error);
    }
  }

  onCatChange() {
    const item = this.catData.find((ele) => (this.model.category && ele && ele.key === this.model.category));
    console.log(" ---", this.model, item);
    if (item) {
      this.filteredType = this.ssType.filter((ele: any) => (ele && ele.category && Array.isArray(ele.category) && ele.category.includes(item.key)));
    }

    // this.valChange.emit({
    //   category: item.key
    // });
    // this.modelChange.next(this.model);
  }

  onTypeChange() {
    // const item = this.ssType.find((ele) => (this.model.type && ele && ele.key === this.model.type.key));

    // this.valChange.emit({
    //   category: item.key
    // });
    this.modelChange.next(this.model);
  }

  selectAreas(type: any, item: any) {
    const mappedData = this.catData.map((ele: any) => {
      ele.allTypes = ele.allTypes || [];
      this.ssType.forEach((ele2) => {
        if (ele && ele.key && ele2.category && ele2.category.includes(ele.key)) {
          ele.allTypes.push(ele2);
        }
      });
      return ele;
    });

    let selectedTypes = [];
    let mySelectedTypes = [];
    let alreadyExists = -1;
    let title = '';
    if (item) {
      const t4 = mappedData.find((ele) => (ele && ele.key === item));
      const t5 = this.model.types.find((ele) => (ele && ele.key === item));
      if (t4) {
        selectedTypes = t4.allTypes;
        title = t4.key;
      }
      if (t5) {
        mySelectedTypes = t5.types || [];
        // alreadyExists = true;
        alreadyExists = this.model.types.findIndex((ele) => (ele && ele.key === item));
      }
    }
    
    console.log("mapped data - ", mappedData);

    const dialogRef = this.dialog.open(CtSsCatPopupComponent, {
      width: '100%',
      panelClass: 'happ-form-builder-dialog',
      data: { 
        catData: this.catData,
        ssType: this.ssType,
        selectedAreas: this.model.areas || [],
        data: this.model,
        selectedTypes,
        title,
        mySelectedTypes,
        mappedData,
        type
      }
  });


  dialogRef.afterClosed().subscribe(async (result) => {
      console.log("popup is closed.", result);
      // if (result) {
      // }
      // this._mailService.repullChanged.next(true);
      if (result) {
        if (type === 'area') {
          this.model.areas = result.selectedAreas;
        } else {
          if (alreadyExists !== -1) {
            this.model.types[alreadyExists].types = result.mySelectedTypes;
            this.model.typesObj[title] = result.mySelectedTypes;
          } else {
            this.model.types.push({
              title,
              types: result.mySelectedTypes || []
            });
            this.model.typesObj[title] = result.mySelectedTypes;
          }
        }
        this.modelChange.next(this.model);

      }
  }, err => {
      console.info(err);
  });
  }
}
