import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';
import { SmtpFormComponent } from '../smtp-form/smtp-form.component';
var SmtpListComponent = /** @class */ (function () {
    function SmtpListComponent(dialogRef, data, _officeHelper, helperService, dialog) {
        this.dialogRef = dialogRef;
        this.data = data;
        this._officeHelper = _officeHelper;
        this.helperService = helperService;
        this.dialog = dialog;
    }
    SmtpListComponent.prototype.ngOnInit = function () {
        this.getLocalOfficeFilter();
    };
    SmtpListComponent.prototype.getLocalOfficeFilter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var temp, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.helperService.getRequest("api/office-smtp")];
                    case 1:
                        temp = _a.sent();
                        this.smtpSettings = temp.filter(function (ele) { return (ele && ele.type === 'default'); });
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        alert(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SmtpListComponent.prototype.save = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        this.loading = true;
                        return [4 /*yield*/, this._officeHelper.updateOfficeDoc({ smtp: this.smtpSettings })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this._officeHelper.refreshLocalOfficeData()];
                    case 2:
                        _a.sent();
                        this.loading = false;
                        this.helperService.showInfo("Updated success", "Success", "success");
                        this.dialogRef.close();
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        this.loading = false;
                        alert(error_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SmtpListComponent.prototype.close = function () {
        this.dialogRef.close();
    };
    SmtpListComponent.prototype.addOrEdit = function (i) {
        var _this = this;
        var data = this.dialog.open(SmtpFormComponent, {
            disableClose: false,
            width: '100%',
            height: '100%',
            panelClass: 'happ-form-builder-dialog',
            data: {
                type: "smtpConfig",
                smtpSettings: this.smtpSettings[i]
            }
        });
        data.afterClosed()
            .subscribe(function (response) {
            if (response) {
                _this.getLocalOfficeFilter();
            }
        });
    };
    return SmtpListComponent;
}());
export { SmtpListComponent };
