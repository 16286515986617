<!-- <div class="h-100-p w-100-p p-8" style="position: relative; overflow: hidden;"> -->
        <div class="dialog-content-wrapper" style="background: #fafafa;">
                <mat-toolbar matDialogTitle class="mat-accent m-0">
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                        <div>
                            <p style="margin:auto; font-size: 15px;"> Current Version:
                                {{ version }}
                            </p>
                        </div>
                        <span fxFlex></span>
                        <h2 style=" text-align: center; " class="m-0">
                            Change Log
                        </h2>
                        <span fxFlex></span>
                        <div>
                            <p style="margin:auto; font-size: 15px;"> Branch: {{ branch }} </p>
                        </div>
                    </div>
                </mat-toolbar>
            
                <div class="changelog p-24" *ngIf="changeLog" style="overflow: auto;">
                    <div class="my-12">
                        <div [innerHtml]="changeLog.changeLog | htmlWithStyle" >

                        </div>
                    </div>
                    <hr>
                </div>
            </div>