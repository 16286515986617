import * as tslib_1 from "tslib";
import { OfficeHelpersService } from '../../services/office-helpers/office-helpers.service';
import { AuthStore } from '../../state/authState/auth.store';
import { OfficeStore } from '../../state/officeState/office.store';
import { HelpersService } from '../../services/helpers/helpers.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { WorkspaceStore } from '../workspaceState/workspace.store';
var OfficeGlobalService = /** @class */ (function () {
    function OfficeGlobalService(_officeHelper, authStore, officeStore, helperService, fuseConfig2, workspaceStore) {
        this._officeHelper = _officeHelper;
        this.authStore = authStore;
        this.officeStore = officeStore;
        this.helperService = helperService;
        this.fuseConfig2 = fuseConfig2;
        this.workspaceStore = workspaceStore;
    }
    OfficeGlobalService.prototype.changeOffice = function (officeId, url) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var officeDoc, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        localStorage.setItem("selectedOfficeId", JSON.stringify(officeId));
                        return [4 /*yield*/, this._officeHelper.loadNewOffice(officeId)];
                    case 1:
                        officeDoc = _a.sent();
                        this.updateAllDataForNewOffice(officeDoc, url);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        throw error_1;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OfficeGlobalService.prototype.changeVirtualOffice = function (orgId, url) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        localStorage.setItem("selectedVirtualOrgId", JSON.stringify(orgId));
                        // return localStorage.getItem("tempInitUrl") || `/offices/${this.office}/dashboard`
                        // const officeDoc = await this._officeHelper.loadNewOffice(orgId);
                        // this.updateAllDataForNewOffice(officeDoc, url);
                        return [4 /*yield*/, this._officeHelper.updateOfficeDoc({ lastSelectedOrg: orgId })];
                    case 1:
                        // return localStorage.getItem("tempInitUrl") || `/offices/${this.office}/dashboard`
                        // const officeDoc = await this._officeHelper.loadNewOffice(orgId);
                        // this.updateAllDataForNewOffice(officeDoc, url);
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        throw error_2;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OfficeGlobalService.prototype.updateAllDataForNewOffice = function (officeDoc, url) {
        var _this = this;
        this.workspaceStore.reset();
        this.authStore.update({ officeId: (officeDoc['_id']), officeName: officeDoc.name, redirectUrl: url || "/offices/" + officeDoc['_id'] + "/dashboard" });
        this.officeStore.update(officeDoc);
        this.helperService.getFuseConfig()
            .then(function (fuseSettings) {
            if (fuseSettings && Object.keys(fuseSettings).length) {
                _this.fuseConfig2.setConfig(fuseSettings);
            }
            else {
                _this.fuseConfig2.resetToDefaults();
            }
        }).catch(function (err) {
            console.info(err);
            _this.fuseConfig2.resetToDefaults();
        });
    };
    return OfficeGlobalService;
}());
export { OfficeGlobalService };
