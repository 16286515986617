import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';

@Component({
  selector: 'app-ct-service-selection',
  templateUrl: './ct-service-selection.component.html',
  styleUrls: ['./ct-service-selection.component.scss']
})
export class CtServiceSelectionComponent implements OnInit {

  @Input() field: any;
  @Input() translateData: any;
  @Input() model: any; 
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Output() onError: EventEmitter<Object> = new EventEmitter();
  

  serviceList = [];
  staticSericeList = [];
  error: boolean;
  errorMessage: string;

  constructor(
    private _helperService: HelpersService,
    private _translationLoader: FuseTranslationLoaderService,
  ) { }

  ngOnInit() {
    this.getData();
    this.checkError();
  }

  async getData() {
    this.serviceList = await this._helperService.getRequest(`api/shared-service/available-services/client`);
    this.staticSericeList = this.serviceList;
  }

  
  select(item: any) {
    const id = (item && item.serviceData && item.serviceData._id) || item._id;
    if (this.field.isMultiSelect) {
      this.model = this.model || [];
      if (this.model.includes(id)) {
        this.model.splice(this.model.indexOf(id), 1);
      } else {
        this.model.push(id);
      }
      this.modelChange.next(this.model);
    } else {
      this.model = id;
      this.modelChange.next(id);
    }
    this.checkError();
  }

  checkError() {
    if (!this.model) {
      this.error = true;
      this.errorMessage = 'ct.field_is_required';
      this.onError.emit({
        error: true,
        label: this.field['label']
      });
    } else {
      this.error = false;
      this.errorMessage = '';
      this.onError.emit({
        error: false,
        label: this.field['label']
      });
    }
  }
}
