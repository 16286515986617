<div style="position: relative;" fxLayout="column" *ngIf="!field.hide || builder">
    <mat-form-field color="accent" [ngClass]="{'w-100-p': true, 'happ-small-form-field-outline': field.smallField}" floatLabel="always" appearance="outline" fxFlex>
        <mat-label>{{field.title | translate}}</mat-label>
        <input type="number" (paste)="pasteIt($event)" matInput min="{{field.min}}" max="{{field.max}}" placeholder="{{field.title | translate}}" [disabled]="field.showdisablecontroltype=='true' || field.disable" (keyup)="onKeyPress()" [hidden]="field.hidden" [(ngModel)]="model">
        <mat-hint align="start"> {{ field.description }} </mat-hint>
        <div class="error-message" *ngIf="errorMessage && (!min_error && !max_error)">
            {{ errorMessage | translate }}
        </div>
        <div class="error-message" *ngIf="min_error">
            {{ errorMessage | translate }} {{ field.min }}
        </div>
        <div class="error-message" *ngIf="max_error">
            {{ errorMessage | translate }} {{ field.max }}
        </div>
    </mat-form-field>
    <div class="happ-field-description" *ngIf="field.allow_description && field.description"> {{ field.description }} </div>
</div>