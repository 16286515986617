<div fxLayout="column" fxLayoutAlign="center stretch" class="p-16 h-100-p">
    <div *ngIf="message" mat-dialog-content>{{ (message) | translate }}</div>
    <div  fxLayout="column">
        <mat-form-field class="w-320"  class="happ-small-form-field-outline" floatLabel="always" appearance="outline" fxFlex>
            <mat-label> {{ inputPlaceholder | translate }} </mat-label>
            <input matInput [(ngModel)]="inputValue" [placeholder]="inputPlaceholder | translate">
        </mat-form-field>
        <p *ngIf="errorMessage" class="m-0 ml-4" style="color: red;">
            {{ errorMessage | translate }}
        </p>
    </div>
    <div fxLayout="row" mat-dialog-actions>
        <span fxFlex></span>
        <div>
            <button mat-button (click)="dialogRef.close()">{{ 'popup.cancel' | translate }}</button>
            <button mat-raised-button class="mat-warn mr-0" (click)="saveData()">{{ 'popup.save' | translate }}</button>
        </div>
    </div>
</div>