import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';
import { SmtpFormComponent } from '../../../smtp-settings/smtp-form/smtp-form.component';
import { SmtpListComponent } from '../../../smtp-settings/smtp-list/smtp-list.component';
import { MailingFooterListComponent } from '../mailing-footer-list/mailing-footer-list.component';
import { MailingListLoadingPopupComponent } from '../mailing-list-loading-popup/mailing-list-loading-popup.component';
var MailingListActionPopupComponent = /** @class */ (function () {
    function MailingListActionPopupComponent(dialogRef, data, helperService, _officeHelper, dialog) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.helperService = helperService;
        this._officeHelper = _officeHelper;
        this.dialog = dialog;
        this.files = [];
        this.regList = [];
        this.introJson = [
            {
                element: '#mailing_list_type',
                intro: 'Select type of list',
                position: 'bottom'
            },
            {
                element: '#mailing_list_save',
                intro: 'Save content',
                position: 'bottom'
            },
            {
                element: '#mailing_list_cancel',
                intro: 'Cancel save',
                position: 'bottom'
            },
        ];
        this.logs = [];
    }
    MailingListActionPopupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formData = this.data.formData || {};
        this.files = this.formData.files || [];
        this.getLocalOfficeFilter();
        this.getRegisterList();
        this.getFooterList();
        this.getLogs();
        this.helperService.setIntroJson(this.introJson);
        setTimeout(function () {
            _this.helperService.startIntro();
        }, 2000);
    };
    MailingListActionPopupComponent.prototype.getFooterList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.helperService.getTemplateList("?type=footer")];
                    case 1:
                        _a.footerList = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MailingListActionPopupComponent.prototype.getLogs = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.helperService.getRequest("api/mailing-list/logs/" + this.data.recId + "?actionId=" + this.formData.actionId)];
                    case 1:
                        _a.logs = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MailingListActionPopupComponent.prototype.getRegisterList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.helperService.getRegisterList("?allowMailingList=true ")];
                    case 1:
                        _a.regList = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MailingListActionPopupComponent.prototype.getSharedService = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var temp;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.helperService.getRequest("api/external-page/" + this.officeId + ".pages.pinbox24.com?&route=our-services")];
                    case 1:
                        temp = _a.sent();
                        this.sharedServiceData = temp.providerServicesLists || [];
                        return [2 /*return*/];
                }
            });
        });
    };
    MailingListActionPopupComponent.prototype.close = function () {
        this.dialogRef.close();
    };
    MailingListActionPopupComponent.prototype.uploadFile = function (file) {
        var _this = this;
        var files = file.files;
        var formData = new FormData();
        for (var i = 0; i < files.length; i++) {
            formData.append('uplFile', files[i], files[i].name);
        }
        this.loading = true;
        this.helperService.uploadFiles("&regId=" + this.data.regId + "&recId=" + this.data.recId, formData)
            .then(function (data) {
            _this.loading = false;
            console.log("data", data);
            _this.files = _this.files || [];
            _this.files.push(data);
        }).catch(function (err) {
            console.info(err);
            _this.loading = false;
        });
    };
    MailingListActionPopupComponent.prototype.validate = function () {
        var invalidFields = [];
        switch (this.data.action) {
            case "sendMailWithRecordWithSharedService":
                if (!this.formData.sharedServiceId) {
                    invalidFields.push("sharedServiceId");
                }
            case "sendMailWithRecord":
                if (!this.formData.regId) {
                    invalidFields.push("regId");
                }
                if (!this.formData.websiteHtml) {
                    invalidFields.push("websiteHtml");
                }
            default:
                if (!this.formData.name) {
                    invalidFields.push("name");
                }
                if (!this.formData.subject) {
                    invalidFields.push("subject");
                }
                if (!this.formData.smtpServer) {
                    invalidFields.push("smtpServer");
                }
                if (!this.formData.from) {
                    invalidFields.push("from");
                }
        }
        return invalidFields;
    };
    MailingListActionPopupComponent.prototype.save = function () {
        var temp = this.validate();
        if (temp.length) {
            return this.helperService.showInfo("Following fields are required - " + temp.join(", "), "Error", "error");
        }
        this.dialogRef.close(tslib_1.__assign({}, this.formData, { files: this.files }));
    };
    MailingListActionPopupComponent.prototype.validateBySendEmail = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.helperService.getRequest("api/mailing-list-validate/" + this.data.recId + "/" + this.formData.actionId)];
                    case 1:
                        _a.sent();
                        this.helperService.showInfo("Smtp config is valid");
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        alert(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MailingListActionPopupComponent.prototype.deleteItem = function (i) {
        this.files.splice(i, 1);
    };
    MailingListActionPopupComponent.prototype.getLocalOfficeFilter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var temp, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.helperService.getRequest("api/office-smtp")];
                    case 1:
                        temp = _a.sent();
                        this.smtpEmailList = temp;
                        this.smtpServerList = temp.filter(function (ele) { return (ele && ele.type === 'default'); });
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        alert(error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MailingListActionPopupComponent.prototype.openSMTPSettings = function () {
        var _this = this;
        var temp = this.dialog.open(SmtpListComponent, {
            disableClose: true,
            width: '100%',
            height: '100%',
            panelClass: 'happ-form-builder-dialog',
            data: {
                smtpServerList: this.smtpServerList
            }
        });
        temp.afterClosed()
            .subscribe(function (response) {
            _this.getLocalOfficeFilter();
        });
    };
    MailingListActionPopupComponent.prototype.openSMTPFormSettings = function () {
        var _this = this;
        if (!this.formData.smtpServer) {
            return;
        }
        var temp = this.dialog.open(SmtpFormComponent, {
            disableClose: true,
            width: '100%',
            height: '100%',
            panelClass: 'happ-form-builder-dialog',
            data: {
                type: "smtpEmailForm",
                smtpServer: this.formData.smtpServer
            }
        });
        temp.afterClosed()
            .subscribe(function (response) {
            _this.getLocalOfficeFilter();
        });
    };
    MailingListActionPopupComponent.prototype.openFooterSettings = function () {
        var _this = this;
        var temp = this.dialog.open(MailingFooterListComponent, {
            disableClose: true,
            width: '100%',
            height: '100%',
            panelClass: 'happ-form-builder-dialog',
        });
        temp.afterClosed()
            .subscribe(function (response) {
            _this.getFooterList();
        });
    };
    MailingListActionPopupComponent.prototype.execute = function (item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var temp;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    console.log("item to execute");
                    temp = this.validate();
                    if (temp.length) {
                        return [2 /*return*/, this.helperService.showInfo("Following fields are required - " + temp.join(", "), "Error", "error")];
                    }
                    this.helperService.openConfirmPopUp("Are you sure you want to send email to this mailing list?")
                        .subscribe(function (data) {
                        // if (data) {
                        var temp = _this.dialog.open(MailingListLoadingPopupComponent, {
                            disableClose: true,
                            width: '60%',
                            height: '60%',
                            panelClass: 'happ-form-builder-dialog',
                            data: {
                                key: "recList",
                                action: item,
                                sourceRegId: _this.data.regId,
                                recId: _this.data.recId,
                                actionIndex: _this.data.index
                            }
                        });
                        temp.afterClosed()
                            .subscribe(function (response) {
                            _this.getLogs();
                        });
                        // }
                    });
                }
                catch (error) {
                    alert(error);
                }
                return [2 /*return*/];
            });
        });
    };
    MailingListActionPopupComponent.prototype.onServerChange = function (eve) {
        var _this = this;
        var temp = this.smtpServerList.find(function (ele) { return (ele && ele._id === _this.formData.smtpServer); });
        if (temp) {
            this.formData.from = temp.username;
        }
    };
    return MailingListActionPopupComponent;
}());
export { MailingListActionPopupComponent };
