import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HelpersService } from '../../services/helpers/helpers.service';

@Component({
  selector: 'app-hard-filter-setup',
  templateUrl: './hard-filter-setup.component.html',
  styleUrls: ['./hard-filter-setup.component.scss']
})
export class HardFilterSetupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<HardFilterSetupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private helperService: HelpersService
  ) { }

  ngOnInit() {
  }

  
  checkMqFilter() {
    this.helperService.checkMqFilter(this.data.hardFilter || '')
    .then(() => {
      this.helperService.openSnackBar('Valid');
    }).catch(err => {
      this.helperService.openSnackBar(err || 'Invalid');
    });
  }

  saveData() {
    this.helperService.checkMqFilter(this.data.hardFilter || '')
    .then(() => {
      this.dialogRef.close(this.data.hardFilter);
    }).catch(err => {
      this.helperService.showInfo('Filter Entered is not valid. Please Click info icon to get more or contact support for this.');
    });
  }

  openInstructionPopup(infoKey: string) {

    if (infoKey === 'dataRangeTimeDiff') {
      return this.helperService.openInstructionPopup(`
  <pre>
      years	y
      quarters	Q
      months	M
      weeks	w
      days	d
      hours	h
      minutes	m
      seconds	s
  </pre>`, 'data');
    }
    this.helperService.openInstructionPopup(` <pre>
    (responsibleEmail = 'abc' OR responsibleEmail = 'xyz')
    (responsibleEmail = 'asas' AND processStatusLabel = 'asas')
    (modificatorDate <= 'Date(now)')
    (modificatorDate <= 'Date(now)-2d')
    (modificatorDate <= 'Date(2018-08-01)')
    (processStatusId IN '8, 9, 12')
    (processStatusId IN 'test, abc, zyx')
    (processStatusId NOT_IN '4,5,7')
    (employeeSurname != 'sing')
    (employeeAddress CONTAINS '/asd*/')
    (employeeAddress EXISTS '@true')
    (employeeAddress NOT_CONTAINS '/asd*/')
    (managerGroup IN currentUserManagerGroups)
    (userGroup IN currentUserGroups)
</pre>
<pre>
    responsibleUserId - To get responsible User Id
    responsibleEmail - To get responsible User Email
    currentUserProfileEmail - To get responsible User Email
    currentUserGroups - To get all user groups
    currentUserManagerGroups - To get all user manager groups
</pre>
<pre>
    years	y
    quarters	Q
    months	M
    weeks	w
    days	d
    hours	h
    minutes	m
    seconds	s
    milliseconds	ms
</pre>`, 'data');
  }
}
