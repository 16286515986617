<div class="dialog-content-wrapper" style="background: #fafafa;">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
      <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title"> Filter </span>
        <!-- <button (click)="showInfo=!showInfo" mat-icon-button>
          <mat-icon>info</mat-icon>
        </button> -->
        <span fxFlex></span>
        <button class="mr-12" mat-raised-button 
          (click)="dialogRef.close(null)" style="color: black;">
            Cancel
        </button>
        <button mat-raised-button 
          (click)="save()"
          color="warn">
            Save
        </button>
      </div>
    </mat-toolbar>

    <mat-tab-group class="m-4">
        <mat-tab label="Filter Button"> 
          <div style="margin-left:24px ;" *ngIf="data && data.hardFilter">
            <p>
              Hard Filter:
              <strong>
                {{ data.hardFilter }}
              </strong>
            </p>
          </div>
          <div fxLayout="column" class="p-24 m-0" style="height: 89vh;" fusePerfectScrollbar>
            <div class="p-4">
              <button mat-raised-button color="warn" *ngIf="filters.length === 0" (click)="filters.push({name: '', value: '', sort: 50})" > Add </button>
            </div>
          <div *ngFor="let filter of filters; let i=index" fxLayout="row" fxLayoutGap="10px;" style="align-items: center; justify-content: space-around;" >
            <div *ngIf="viewType === 'form'" class="p-4">
              <button mat-raised-button [color]="(filter.defaultFilter)? 'accent': ''" (click)="setDefaultFilter(i)" > Default Filter </button>
            </div>
            <div class="p-4">
              <button (click)="filters.splice(i, 1)" mat-icon-button>
                <mat-icon>delete</mat-icon>
              </button>
            </div>
            <div class="p-4" fxFlex="15" fxLayout="column">
                <mat-form-field floatLabel="always" appearance="outline" class="happ-small-form-field-outline" fxFlex>
                  <input matInput placeholder="Filter Name" [(ngModel)]="filter.name">
                </mat-form-field>
              <mat-checkbox [(ngModel)]="filter.useInput" > Use Input </mat-checkbox>
            </div>
            <div class="p-4" fxFlex="40" fxLayout="column">
                <mat-form-field *ngIf="filter.useInput" floatLabel="always" appearance="outline" class="happ-small-form-field-outline mb-8" fxFlex>
                  <input matInput placeholder="keyName" [(ngModel)]="filter.keyName" >
                </mat-form-field>
                <mat-form-field *ngIf="!filter.useInput" floatLabel="always" appearance="outline" class="happ-small-form-field-outline mb-8" fxFlex>
                  <input matInput placeholder="(text = 'hello' AND responsibleEmail = 'example@xyz.com')" [(ngModel)]="filter.value" >
                </mat-form-field>
                <mat-form-field *ngIf="data.subType === 'layers'"  floatLabel="always" appearance="outline" class="happ-small-form-field-outline" fxFlex>
                  <input matInput placeholder="Marker Url" [(ngModel)]="filter.markerUrl" >
                </mat-form-field>
                <mat-form-field *ngIf="data.subType === 'layers' && viewType !== 'form'"  floatLabel="always" appearance="outline" class="happ-small-form-field-outline" fxFlex>
                  <input matInput placeholder="Cluster color" [(ngModel)]="filter.color" >
                </mat-form-field>
            </div>
            <div *ngIf="viewType !== 'form'" class="p-4" fxFlex="10">
              <mat-checkbox [(ngModel)]="filter.defaultFilter" > Show Default </mat-checkbox>
            </div>
            <div *ngIf="viewType === 'form'" class="p-4" fxFlex="10">
              <mat-checkbox [(ngModel)]="filter.onWeb" > Show on Web </mat-checkbox>
            </div>
            <div *ngIf="viewType === 'form'" class="p-4" fxFlex="10">
                <mat-checkbox [(ngModel)]="filter.onMobile" > Show on Mobile </mat-checkbox>
            </div>
            <div class="p-4" fxFlex="5">
                <mat-form-field  floatLabel="always" appearance="outline" class="happ-small-form-field-outline" fxFlex style="width: 100%;">
                  <input matInput placeholder="Sort" type="number" [(ngModel)]="filter.sort">
                </mat-form-field>
            </div>
            <div class="p-4">
              <button mat-raised-button color="warn" (click)="filters.push({name: '', value: '', sort: 50})" > Add </button>
            </div>
          </div>
        </div>
        </mat-tab>
        <mat-tab label="Description(Examples)">
          <pre>
              (responsibleEmail = 'abc' OR responsibleEmail = 'xyz')
              (responsibleEmail = 'asas' AND processStatusLabel = 'asas')
              (modificatorDate <= 'Date(now)')
              (employeeAddress EXISTS '@true')
              (modificatorDate <= 'Date(now)-2d')
              (modificatorDate <= 'Date(2018-08-01)')
              (processStatusId IN '8, 9, 12')
              (processStatusId IN 'test, abc, zyx')
              (processStatusId NOT_IN '4,5,7')
              (employeeSurname != 'sing')
              (employeeAddress CONTAINS '/asd*/')
              (employeeAddress NOT_CONTAINS '/asd*/')
              (managerGroup IN currentUserManagerGroups)
              (userGroup IN currentUserGroups)
          </pre>
          <pre>
              responsibleUserId - To get responsible User Id
              responsibleEmail - To get responsible User Email
              currentUserProfileEmail - To get responsible User Email
              currentUserGroups - To get all user groups
              currentUserManagerGroups - To get all user manager groups
          </pre>
          <pre>
              years	y
              quarters	Q
              months	M
              weeks	w
              days	d
              hours	h
              minutes	m
              seconds	s
              milliseconds	ms
          </pre>
        </mat-tab>
    </mat-tab-group>
</div>
