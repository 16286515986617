import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-slot-selection-settings',
  templateUrl: './slot-selection-settings.component.html',
  styleUrls: ['./slot-selection-settings.component.scss']
})
export class SlotSelectionSettingsComponent implements OnInit {

  @Input() field;
  @Output() onFieldValueUpdated: EventEmitter<any> = new EventEmitter();
  @Input() builder;
  @Input() regRec;
  @Input() recId: string;
  @Input() signature: string;
  @Input() regId: string;
  @Input() model: string; 
  @Input() translateData: any;
  @Output() modelChange: EventEmitter<any> = new EventEmitter();
  @Output() updateParentOnSave: EventEmitter<any> = new EventEmitter();
  @Output() getRecord: EventEmitter<any> = new EventEmitter();

  days = [
    {
      value: 'monday',
      title: 'ct.monday'
    },
    {
      value: 'tuesday',
      title: 'ct.tuesday'
    },
    {
      value: 'wednesday',
      title: 'ct.wednesday'
    },
    {
      value: 'thursday',
      title: 'ct.thursday'
    },
    {
      value: 'friday',
      title: 'ct.friday'
    },
    {
      value: 'saturday',
      title: 'ct.saturday'
    },
    {
      value: 'sunday',
      title: 'ct.sunday'
    },
  ]

  selectedSlots: any = {};
  selectedDays  = [];
  allDateRange: any = {};
  constructor() { }

  ngOnInit() {
    this.regRec.selectedDays = this.regRec.selectedDays || [];
    // this.regRec.slotRanges[day]
    this.regRec.slotRanges = this.regRec.slotRanges || {};
    this.days.forEach((item) => {
      this.regRec.slotRanges[item.value] = this.regRec.slotRanges[item.value] || [];
    });
    
  }


  toggleDay(item) {
    if (this.regRec.selectedDays.includes(item.value)) {
      const tempIndex = this.regRec.selectedDays.indexOf(item.value);
      this.regRec.selectedDays.splice(tempIndex, 1);
    } else {
      this.regRec.slotRanges[item.value] = this.regRec.slotRanges[item.value] || [];
      this.regRec.selectedDays.push(item.value);
    }
  }


  addRow(day: string) {
    this.regRec.slotRanges[day] = this.regRec.slotRanges[day] || [];
    this.regRec.slotRanges[day].push({
      startTime: '',
      endTime: ''
    })
  }

  deleteRow(i: any, day: string) {
    this.regRec.slotRanges[day].splice(i, 1);
  }

  onInputChange(event, key) {
    console.log(event, key);
  }
  updateData(key: string, value: string) {
    const temp: any = {};
    temp[key] = value;
    this.onFieldValueUpdated.emit(temp);
  }
}
