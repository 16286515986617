import { CalendarColorInteface } from './calendar.interface';
import { DataTableInterface } from '../../global-component/datatable/datatable.interface';
// import { OptionsInput } from 'fullcalendar/src/types/input-types';


export class CalendarColor implements CalendarColor {
    public calendarColor: CalendarColorInteface = {
      fontColor: '#ffffff',
      backgroundColor: '#3a87ad'
    };

    constructor(fontColor?: string, backgroundColor?: string) {
        this.calendarColor.backgroundColor = backgroundColor || '#3a87ad';
        this.calendarColor.fontColor = fontColor || '#ffffff';
    }
}

export const CalenderRecordFilter: DataTableInterface = {
    'page': {
      'size': 200,
      'totalElements': 0,
      'totalPages': 0,
      'pageNumber': 0
    },
    'columns': [
      {
        'prop': 'start',
        'title': 'Start date and time'
      },
      {
        'prop': 'info_subject',
        'title': 'Subject'
      },
      {
        'prop': 'info_responsible',
        'title': 'Responsisble Info'
      },
      {
        'prop': 'end',
        'title': 'End Date and Time'
      },
      {
        'prop': 'info_status',
        'title': 'Status'
      },
      {
        'prop': 'allDay',
        'title': 'All Day'
      },
      {
        'prop': 'repeatingEvent',
        'title': 'repeatingEvent'
      },
      {
        'prop': 'repeatingDays',
        'title': 'repeatingDays'
      },
      {
        'prop': 'info_type',
        'title': 'Type'
      },
      {
        'prop': '_id',
        'title': 'Actions'
      }
    ],
    'sort': {
      'prop': 'start',
      'dir': 'asc'
    },
    'filters': {},
    'filterRecord': {},
    'search': {
      'value': '',
      'regex': false
    },
  };

export const CalendarViewSettings: any = {
    header: {
      left: 'prev,next,today',
      center: 'title',
      right: 'month, agendaWeek, agendaDay, listMonth, listWeek, listDay'
    },
    views: {
      today: { buttonText: 'Dzisiaj' },
      month: { buttonText: 'Miesiąc' },
      agendaWeek: { buttonText: 'Tydzień' },
      agendaDay: { buttonText: 'Dzień' },
      listDay: { buttonText: 'Lista Dnia' },
      listWeek: { buttonText: 'Lista Tygodnia' },
      listMonth: { buttonText: 'Lista Miesiąca' }
    },
    buttonText: {
      today: 'Dzisiaj'
  },
  allDayText: 'Cały dzień', eventLimitText: 'więcej', noEventsMessage: 'Brak wydarzeń do wyświetlenia',
    navLinks: true, // can click day/week names to navigate views
    editable: true,
    eventLimit: true, // allow "more" link when too many events
    events: [],
    minTime: '7:00:00',
    defaultView: 'month',
    maxTime: '22:00:00',
    contentHeight: 'auto',
    weekends: true,
    firstDay: 0, // sunday
    dragScroll: true,
    selectable: true,
    locale: 'pl',
    slotLabelFormat: 'HH:mm'
  };
