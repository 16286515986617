import {ErrorHandler, Injectable,} from '@angular/core';
import { ErrorLogService } from './error.handler-helper.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
    constructor(private errorLogService: ErrorLogService) {
        super();
    }

    handleError(error) {
        this.errorLogService.logError(error);
    }
}
