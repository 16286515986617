<div fxLayout="column" *ngIf="!field.hide || builder">
  <div fxLayout="row">
      <div fxLayout="column" class="w-100-p">
          <div fxLayout="row">
            <mat-form-field  color="accent" [ngClass]="{'happ-small-form-field-outline': field.smallField}" floatLabel="always" appearance="outline" fxFlex>
            <mat-label>{{field.title | translate}}</mat-label>

              <input  autofocus="true" maxlength="4" matInput placeholder="{{field.title | translate}} Title" [disabled]="field.showdisablecontroltype=='true' || field.disable" (paste)="pasteIt($event)" (keyup)="titleChanged()" [hidden]="field.hidden"
              [(ngModel)]="rwaTitle">
            </mat-form-field>
            <mat-form-field  color="accent" [ngClass]="{'happ-small-form-field-outline': field.smallField}" floatLabel="always" appearance="outline" fxFlex>
            <mat-label>{{field.title | translate}}</mat-label>

              <input autofocus="true" maxlength="4" matInput placeholder="{{field.title | translate}}" [disabled]="field.showdisablecontroltype=='true' || field.disable" (keyup)="valueChanged()" [hidden]="field.hidden"
              [(ngModel)]="model">
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <div fxFlex="50" fxLayout="column">
              <div fxLayoutAlign="space-around center" fxLayout="row">
                <p class="font-size-20" fxFlex="20"> RWA </p>
                <p class="font-size-20" fxFlex="20"> I </p>
                <p class="font-size-20" fxFlex="20"> II </p>
                <p class="font-size-20" fxFlex="20"> III </p>
                <p class="font-size-20" fxFlex="20"> IV </p>
              </div>
              <div fxLayoutAlign="space-around center" fxLayout="row">
                <p class="font-size-20" fxFlex="20"></p>
                <p class="font-size-20" fxFlex="20"> {{ rwa1 || '-' }} </p>
                <p class="font-size-20" fxFlex="20"> {{ rwa2 || '-' }} </p>
                <p class="font-size-20" fxFlex="20"> {{ rwa3 || '-' }} </p>
                <p class="font-size-20" fxFlex="20"> {{ rwa4 || '-' }} </p>
              </div>
            </div>
            <mat-form-field  color="accent" [ngClass]="{'happ-small-form-field-outline': field.smallField}" fxFlex="50" class="example-full-width">
              <textarea matInput placeholder="{{field.title | translate}} Description" (keyup)="descriptionChanged()" [(ngModel)]="rwaDescription">{{ rwaDescription }}</textarea>
            </mat-form-field>
          </div>
      </div>
      <!-- <mat-icon *ngIf="error" style="color: red; font-size: 50px; margin: auto; margin-right: 32px;" class="ml-12">warning</mat-icon> -->
  </div>

  <div style="color: red">
      <p *ngIf="errorMessage"> {{ errorMessage }} </p>
  </div>
  <div *ngIf="field.allow_description && field.description" [innerHTML]="field.description | htmlWithStyle">
  </div>
</div>