import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';

@Component({
  selector: 'app-mailing-footer',
  templateUrl: './mailing-footer.component.html',
  styleUrls: ['./mailing-footer.component.scss']
})
export class MailingFooterComponent implements OnInit {

  footerContent;
  title;
  loading;
  templateData: any = {
    category: '',
    title: '',
    logoWidth: '100px',
    logoHeight: '50px',
    shortSymbol: '',
    logoBase64: '',
    type: 'footer',
    gridColumns: [
      {
        width: 10,
        align: 'LEFT',
        heading: 'New Column 1',
        variables: '##line##'
      }
    ],
    header: {
      show: true,
      editorType: "ck_editor",
      subType: "superAdmin",
      headerTemplate: "custom",
      slogan: '',
      selectedLangs: ['en'],
      content: '',
      translation: {}
    },
    footer: {
      show: true,
      editorType: "ck_editor",
      subType: "superAdmin",
      footerTemplate: 'custom',
      selectedLangs: ['en'],
      pageTemplateVar: '',
      content: '',
      slogan: '',
      translation: {}
    },
    mainContent: {
      editorType: "ck_editor",
      subType: "superAdmin",
      selectedLangs: ['en'],
      content: '',
      translation: {}
    },
    printringVarialbles: {
      line: {
        header: true,
        footer: true
      },
      margin: {
        bottom: 20,
        top: 20
      },
      spacing: {
        footer: 5,
        header: 5
      }
    }
  };
  constructor(
    private helperService: HelpersService,
    public dialogRef: MatDialogRef<MailingFooterComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit() {
    this.helperService.getTemplateList()
    this.templateData = this.data || this.templateData || {};
  }

  async save() {
    try {
      this.templateData.title = this.title;
      this.templateData.type = 'footer';

      this.templateData.footer = this.templateData.footer || {};
      this.templateData.footer.content = this.footerContent;
      await this.helperService.createTemplate(this.templateData)
      this.dialogRef.close(true);
    } catch (error) {
      alert(error);
    }
  }

  close() {
    this.dialogRef.close();
  }

}
