<div>
  <mat-toolbar style="background: #f6f6f6;" matDialogTitle class="m-0">
      <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title"> {{ 'dttable.report_generation' | translate }} </span>
        <span fxFlex></span>
        <button class="mr-12" (click)="dialogRef.close(null)" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-toolbar>
<div class="m-12" >
  <div *ngIf="!loading">
    <!-- excel -->
    <div fxLayout="column">
      <h2> {{ 'dttable.excel_report' | translate }} </h2>
      <app-setting-item-view [heading]="'dttable.excel_report' | translate" [description]="''" [value]="''" >
        <button mat-raised-button (click)="reportGenerateExcel()" > {{ 'dttable.generate' | translate }} </button>
      </app-setting-item-view>
    </div>
    <div *ngIf="data && data.templateList && data.templateList.length" fxLayout="column">
      <h2> {{ 'dttable.pdf_report' | translate }} </h2>
      <app-setting-item-view  *ngFor="let template of data.templateList" [heading]="template.title | translate" [description]="''" [value]="''" >
        <button mat-raised-button class="mr-8" (click)="reportGeneratePDF(template)" > {{ 'dttable.generate_pdf' | translate }} </button>
        <button mat-raised-button (click)="reportGenerateExcel(template)" > {{ 'dttable.generate_excel' | translate }} </button>
      </app-setting-item-view>
    </div>
  </div>
  <div fxLayout="column" class="h-460" fxLayoutAlign="center center" *ngIf="loading">
    <mat-spinner></mat-spinner>
    <h2> {{ loadingMessage | translate }} </h2>
  </div>
</div>
</div>