import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectTemplatePopupComponent } from './select-template-popup.component';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
  declarations: [SelectTemplatePopupComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FuseSharedModule
  ],
  entryComponents: [
    SelectTemplatePopupComponent
  ],
  exports: [
    SelectTemplatePopupComponent
  ]
})
export class SelectTemplatePopupModule { }
