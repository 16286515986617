import { FieldOptionUpdateDialogComponent } from './field-option-update-dialog.component';
import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';


// import { GlobalModule } from '@fuse/global.module';
import { GlobalModule } from '@fuse/global.module';

@NgModule({
    imports     : [
       FuseSharedModule,
        // GlobalModule,
    ],
    declarations: [
        FieldOptionUpdateDialogComponent
    ],
    providers   : [
    ],
    exports: [
        FieldOptionUpdateDialogComponent
    ],
    entryComponents: [
        FieldOptionUpdateDialogComponent
    ]
})
export class FieldOptionUpdateDialog
{
}
