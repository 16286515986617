<div class="dialog-content-wrapper">
  <mat-toolbar style="background: #f6f6f6;" matDialogTitle class="m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <button class="mr-8" (click)="dialogRef.close(null)" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
      <span class="title dialog-title"> {{ 'issueReporter.create_new_issue' | translate }} </span>
      <span fxFlex></span>
      <div>
          <button mat-raised-button [disabled]="loading || disabledButton" aria-label="custom_app_create_button" (click)="saveIssue()" color="primary">{{ 'issueReporter.create_issue' | translate }}</button>
      </div>
    </div>
  </mat-toolbar>
    <p class="font-size-14" style="color: red;" *ngIf="error"> {{ error }} </p>

  <div class="p-12" fxLayoutAlign="space-between center" [ngClass]="(reporterType !== 'user')? 'w-100-p' : 'w-600'" style="overflow: auto;" fxLayout="row">
    
    <div [fxFlex]="(reporterType !== 'user')? '49' : '100'" fxLayout="column">
      <mat-form-field floatLabel="always" appearance="outline" class="mb-8 happ-small-form-field-outline" >
        <mat-label> {{ 'issueReporter.summary' | translate }} </mat-label>
        <input matInput (keyup)="checkRequired()" [(ngModel)]="issueData.summary" >
      </mat-form-field>

      <!-- image url -->
      <mat-form-field class="w-100-p" floatLabel="always" appearance="outline" class="mb-8 happ-small-form-field-outline" >
        <mat-label> {{ 'issueReporter.image_url' | translate }} </mat-label>
        <input matInput [(ngModel)]="issueData.imgUrl" >
      </mat-form-field>

      <!-- <mat-form-field> -->
        <!-- <textarea matInput [placeholder]="'issueReporter.description' | translate" [(ngModel)]="issueData.description"></textarea> -->
        <ck-editor name="editor1" [config]="config" [(ngModel)]="issueData.description" skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>

      <!-- </mat-form-field> -->
      
      <p> {{ 'issueReporter.priority' | translate }} </p>
      <mat-radio-group (change)="checkRequired()" fxLayout="column" [(ngModel)]="issueData.priority" >
        <mat-radio-button class="m-8" [value]="9">{{ 'issueReporter.priority_low' | translate }}</mat-radio-button>
        <mat-radio-button class="m-8" [value]="6">{{ 'issueReporter.priority_normal' | translate }}</mat-radio-button>
        <mat-radio-button class="m-8" [value]="3">{{ 'issueReporter.priority_high' | translate }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <!-- for Admins -->

    <div fxFlex="49" fxLayout="column" class="w-100-p" *ngIf="reporterType !== 'user'">

      <div>
        <!-- projects -->
        <p class="mb-4"> {{ 'issueReporter.projects' | translate }} </p>
        <div fxLayout="row wrap">
          <button mat-raised-button class="m-4 happ-button-list" [color]="((list.value && list.value === issueData.projectName) || (list.title && list.title === issueData.projectName)) ? 'primary' : ''" *ngFor="let list of projectOptions" (click)="issueData.projectName=(list.value || list.title)" >
            {{ list.title }}
          </button>
        </div>
        
        <!-- labels -->      
        <p class="mb-4"> {{ 'issueReporter.labels' | translate }} </p>
        <div fxLayout="row wrap">
          <button mat-raised-button class="m-4 happ-button-list" [color]="((list.value && list.value === issueData.labels) || (list.title && list.title === issueData.labels)) ? 'primary' : ''" *ngFor="let list of labelsOptions" (click)="issueData.labels=(list.value || list.title)" >
            {{ list.title }}
          </button>
        </div>

        <!-- components -->
        <p class="mb-4"> {{ 'issueReporter.components' | translate }} </p>
        <div fxLayout="row wrap">
          <button mat-raised-button class="m-4 happ-button-list" [color]="((list.value && list.value === issueData.components) || (list.title && list.title === issueData.components)) ? 'primary' : ''" *ngFor="let list of componentsOptions" (click)="issueData.components=(list.value || list.title)" >
            {{ list.title }}
          </button>
        </div>

        <!-- issueType -->
        <p class="mb-4"> {{ 'issueReporter.issueType' | translate }} </p>
        <div fxLayout="row wrap">
          <button mat-raised-button class="m-4 happ-button-list" [color]="((list.value && list.value === issueData.issueType) || (list.title && list.title === issueData.issueType)) ? 'primary' : ''" *ngFor="let list of issueTypeOptions" (click)="issueData.issueType=(list.value || list.title)" >
            {{ list.title }}
          </button>
        </div>

        <!-- assignee -->
        <p class="mb-4"> {{ 'issueReporter.assignee' | translate }} </p>
        <div fxLayout="row wrap">
          <button mat-raised-button class="m-4 happ-button-list" [color]="((list.value && list.value === issueData.assignee) || (list.title && list.title === issueData.assignee)) ? 'primary' : ''" *ngFor="let list of assigneeOptions" (click)="issueData.assignee=(list.value || list.title)" >
            {{ list.title }}
          </button>
        </div>

        <p> {{ 'issueReporter.estTime' | translate }} </p>
        <div fxLayout="row wrap">
          <button mat-raised-button class="m-4" [color]="((list.value && list.value === issueData.estTime) || (list.title && list.title === issueData.estTime)) ? 'primary' : ''" *ngFor="let list of estimatetime" (click)="issueData.estTime=(list.value || list.title)" >
            {{ list.title }}
          </button>
        </div>
      </div>
      
 

  </div>

  </div>
  <!-- <div fxLayout="column" class="p-24 pt-20 pb-20">
    <p class="font-size-14" style="color: red;" *ngIf="error"> {{ error }} </p>
    <div fxLayout="row">
        <span fxFlex></span>
      <button (click)="dialogRef.close(null)" aria-label="custom_app_cancel_button" mat-button class="mr-4">{{ 'issueReporter.cancel' | translate }}</button>
      <button mat-raised-button [disabled]="loading || disabledButton" aria-label="custom_app_create_button" (click)="saveIssue()" color="primary">{{ 'issueReporter.create_issue' | translate }}</button>
    </div>
  </div> -->
</div>