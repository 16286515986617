<div>
  <form class="py-8" #newChecklistForm="ngForm" (submit)="addChecklist(newChecklistForm)"
        (click)="$event.stopPropagation()"
        fxLayout="column" fxLayoutAlign="start end">

      <mat-form-field color="accent" [ngClass]="{'w-100-p': true, 'happ-small-form-field-outline': field.smallField}" floatLabel="always" appearance="outline" (click)="$event.stopPropagation()" fxFlex>
          <mat-label>Checklist title</mat-label>
          <input #newCheckListTitleField matInput ngModel #checklistTitle="ngModel"
                  name="checklistTitle" required>
      </mat-form-field>

      <button mat-raised-button class="mat-accent" aria-label="Add Checklist"
              [disabled]="!newChecklistForm.valid">Add Checklist
      </button>

  </form>
</div>
<div class="section" *ngFor="let checklist of checklistList; let i=index">

  <div class="checklist">

      <div class="section-header" fxLayout="row" fxLayoutAlign="start center">

          <mat-icon class="mr-8">check_box</mat-icon>
          <span fxFlex class="section-title">{{checklist.name}}</span>

          <div>

              <button mat-icon-button class="checklist-actions-button"
                      [matMenuTriggerFor]="checklistActionsMenu">
                  <mat-icon class="s-20">more_vert</mat-icon>
              </button>

              <mat-menu #checklistActionsMenu="matMenu">
                  <button mat-menu-item (click)="removeComment(checklist, i)">
                      <mat-icon>delete</mat-icon>
                      <span>Remove Checklist</span>
                  </button>
              </mat-menu>

          </div>

      </div>

      <div class="section-content">

          <div class="checklist-progress" fxLayout="row" fxLayoutAlign="start center">

              <span class="checklist-progress-value">
                  {{checklist.checkItemsChecked}} / {{checklist.checkItems.length}}
              </span>

              <mat-progress-bar color="primary" class="checklist-progressbar" mode="determinate"
                                [value]="(!checklist.checkItemsChecked || !checklist.checkItems.length) ? 0 : (100 * (checklist.checkItemsChecked / checklist.checkItems.length))">
              </mat-progress-bar>

          </div>

          <div class="check-items">

              <div class="check-item" *ngFor="let checkItem of checklist.checkItems" fxLayout="row"
                   fxLayoutAlign="space-between center">

                  <div fxFlex fxLayout="row" fxLayoutAlign="start center">

                      <mat-form-field color="accent" [ngClass]="{'happ-small-form-field-outline': field.smallField}" floatLabel="always" appearance="outline" fxFlex>
                          <input matInput [(ngModel)]="checkItem.name">
                          <mat-checkbox matPrefix [(ngModel)]="checkItem.checked"
                                        (change)="updateCheckedCount(checklist)"
                                        aria-label="{{checkItem.name}}">
                          </mat-checkbox>
                          <button mat-icon-button matSuffix class="checklist-actions-button"
                                  (click)="removeChecklistItem(checkItem, checklist)">
                              <mat-icon class="secondary-text">delete</mat-icon>
                          </button>
                      </mat-form-field>

                  </div>

              </div>

          </div>

          <form #newCheckItemForm="ngForm" (submit)="addCheckItem(newCheckItemForm,checklist)"
                name="newCheckItemForm" class="new-check-item-form"
                fxLayout="row" fxLayoutAlign="start center">

              <div fxLayout="row" fxLayoutAlign="start center" fxFlex>

                  <mat-form-field color="accent" floatLabel="always" appearance="outline" [ngClass]="{'no-errors-spacer mr-16': true, 'happ-small-form-field-outline': field.smallField}" fxFlex>
                      <input matInput ngModel #checkItem="ngModel" name="checkItem" autocomplete="off"
                             placeholder="New checklist item">
                  </mat-form-field>

              </div>

              <button mat-mini-fab color="accent"
                      [disabled]="!newCheckItemForm.valid || newCheckItemForm.pristine"
                      aria-label="Add">
                  <mat-icon>add</mat-icon>
              </button>
          </form>

      </div>
  </div>
</div>