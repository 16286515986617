import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-select-form-popup',
  templateUrl: './select-form-popup.component.html',
  styleUrls: ['./select-form-popup.component.scss']
})
export class SelectFormPopupComponent implements OnInit {
  allowSelection: any;
  allowMultipleSelection: any;
  singleForm: any;
  multipleForm: any;

  constructor(
    public dialogRef: MatDialogRef<SelectFormPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit() {
    if (this.data) {
      this.allowSelection = this.data.allowSelection;
      this.allowMultipleSelection = this.data.allowMultipleSelection;
      this.singleForm = this.data.singleForm || this.data.formId;
      this.multipleForm = this.data.multipleForm;
    }
  }

  
  saveFormSelection() {
    this.dialogRef.close((this.singleForm && this.singleForm._id));
  }
}
