import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { CtAddServiceVariantComponent } from './ct-add-service-variant/ct-add-service-variant.component';

@Component({
  selector: 'app-ct-service-variant',
  templateUrl: './ct-service-variant.component.html',
  styleUrls: ['./ct-service-variant.component.scss']
})
export class CtServiceVariantComponent implements OnInit {

  @Input() field;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() model = [];
  @Input() parentId;
  @Output() parentIdChange: EventEmitter<Object> = new EventEmitter();
  @Output() updateRecord: EventEmitter<any> = new EventEmitter();
  @Input() translateData: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Input() hideTitle: boolean;
  @Input() builder;
  @Input() formData: any;
  @Output() valChange: EventEmitter<Object> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onError: EventEmitter<Object> = new EventEmitter();
  
  constructor(
    public dialog: MatDialog,
    private helperService: HelpersService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
  }

  delete(item: any, index: any) {
    this.helperService.openConfirmPopUp("Are you sure you want to delete?")
    .subscribe((data) => {
      if (data) {
        this.model.splice(index, 1);
        this.modelChange.emit(this.model);
        this.updateRecord.emit(() => {});
      }
    });
  }

  editOptions(item: any, i: any) {
    console.log("this.mode - ", typeof(this.model));
    if (typeof(this.model) !== "object") {
      this.model = [];
    }
    console.log("this.mode - ", typeof(this.model), this.model);

    const dialogRef = this.dialog.open(CtAddServiceVariantComponent, {
      width: '850px',
      height: 'max-content',
      disableClose: true,
      restoreFocus: false,
      data: {
        prodData: this.model[i]
      },
      panelClass: 'happ-form-builder-dialog'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (item) {
          this.model[i] = result;
        } else {
          this.model.push(result);
        }
        this.modelChange.next(this.model);
        this.changeDetectorRef.detectChanges();
        this.updateRecord.emit(() => {});
      }
    }); 
  }

}
