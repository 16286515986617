<div class="pb-12" *ngIf="!field.hide || builder">
    <div class="happ-list-field" [class]="field.class" [fxLayout]="(field.label_alignment === 'left') ? 'row' : 'column'" >
        <div *ngIf="field.label_alignment !== 'hide'" class="happ-default-title happ-title" [ngStyle]="{width: (formData && formData.leftLabelWidth) || '200px'}">
            <p *ngIf="errorMessage" class="warn-500-fg m-0 ml-8">
                {{ errorMessage | translate  }}
            </p>
            <p *ngIf="!hideTitle" class="happ-field-title mr-12">{{ field.title | translate }} : </p>
        </div>
        <mat-list class="happ-list happ-content" *ngIf="!field.multiple && !loading" role="list" fxLayout="{{field.flex}} wrap">
            <mat-nav-list *ngIf="field.showEdit && field.show_edit_button_align !== 'last'" role="listitem" class="happ-list-item" fxLayoutAlign="{{(field.align)? field.align : 'center'}} center">
                <a mat-list-item fxFlex style="text-decoration: none;" (click)="editOptions()">
                    <span class="text-center">
                        <mat-icon>settings</mat-icon>
                    </span>                    
                </a>
            </mat-nav-list>
            <mat-nav-list role="listitem" class="happ-list-item" fxLayoutAlign="{{(field.align)? field.align : 'center'}} center" [style.background]="option.background" [style.color]="option.color" *ngFor="let option of field.options" [style.background]="((option.value && model == option.value) || (option.title && model == option.title))? '#e1f5fe': option.background"[style.color]="((option.value && model == option.value) || (option.title && model == option.title))? 'black': option.color">
                <a mat-list-item fxFlex style="text-decoration: none;" (click)="model=option.value || option.title; onChange()">
                    <span class="text-center">{{ option.title || option.value }}</span>                    
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="field.showEdit && field.show_edit_button_align === 'last'" role="listitem" class="happ-list-item" fxLayoutAlign="{{(field.align)? field.align : 'center'}} center">
                <a mat-list-item fxFlex style="text-decoration: none;" (click)="editOptions()">
                    <span class="text-center">
                        <mat-icon>settings</mat-icon>
                    </span>                    
                </a>
            </mat-nav-list>
        </mat-list>

        <mat-list class="happ-list happ-content" *ngIf="field.multiple && !loading" role="list" fxLayout="{{field.flex}} wrap">
            <mat-nav-list *ngIf="field.showEdit && field.show_edit_button_align !== 'last'" role="listitem" class="happ-list-item" fxLayoutAlign="{{(field.align)? field.align : 'center'}} center">
                <a mat-list-item fxFlex style="text-decoration: none;" (click)="editOptions()">
                    <span class="text-center">
                        <mat-icon>settings</mat-icon>
                    </span>                    
                </a>
            </mat-nav-list>
            <mat-nav-list role="listitem" class="happ-list-item" fxLayoutAlign="{{(field.align)? field.align : 'center'}} center" [style.background]="option.background" [style.color]="option.color" *ngFor="let option of field.options" [style.background]="(model && (model[option.value] || model[option.title]))? '#e1f5fe': option.background"
                [style.color]="(model && model[option.value] && model[option.title])? 'black': option.color">
                <a mat-list-item fxFlex style="text-decoration: none;" (click)="onChange(option.value || option.title)">
                    <span class="text-center">{{ option.title || option.value }}</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="field.showEdit && field.show_edit_button_align === 'last'" role="listitem" class="happ-list-item" fxLayoutAlign="{{(field.align)? field.align : 'center'}} center">
                <a mat-list-item fxFlex style="text-decoration: none;" (click)="editOptions()">
                    <span class="text-center">
                        <mat-icon>settings</mat-icon>
                    </span>                    
                </a>
            </mat-nav-list>
        </mat-list>
        <div fxLayoutAlign="center center" *ngIf="loading">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <div *ngIf="field.allow_description && field.description" [innerHTML]="field.description | htmlWithStyle">

    </div>
</div>