<div fxLayout="column">
<div fxLayout="row" style="height: 61px; padding: 8px; border-bottom: 1px solid #b3afaf; background: white;">
    <!-- fast search -->
    <mat-form-field class="happ-small-form-field-outline" floatLabel="never" floatLabel="always" appearance="outline"
        style="width: 25%;">
        <mat-label>{{ 'dttable.search' | translate }}</mat-label>
        <input [(ngModel)]="fastSearch" matInput placeholder="">
    </mat-form-field>
    <!-- total and sum -->
    <h2 *ngIf="rows && !loadingIndicator" style="margin: auto;" class="ml-8">
        <span fxHide.lt-md> {{ 'dttable.total_in_view' | translate }} : </span>
        {{ (rows | mqFilter: appHardFilter | mqFilter: mqFilterString | contains: inputFilter | filter: fastSearch)?.length }}
        {{ 'dttable.of' | translate }} {{ (rows | mqFilter: appHardFilter | mqFilter: mqFilterString)?.length }}
        <!-- sum -->
        <ng-container *ngIf="cardHeader && cardHeader.showSum">
            <ng-container
                *ngIf="(rows | mqFilter: appHardFilter | mqFilter: mqFilterString | contains: inputFilter | filter: fastSearch | sumOfField:cardHeader.sumKeyName) > 0; else sumLessThanZero">
                <!-- if more than zero -->
                <span *ngIf="cardHeader.showPrefix"> {{ cardHeader.prefix | translate }} </span>
                <span *ngIf="cardHeader.isDivideSum && cardHeader.divideSumBy; else notDivideBy">
                    <!-- divide result -->
                    {{ ((rows | mqFilter: appHardFilter | mqFilter: mqFilterString | contains: inputFilter | filter: fastSearch | sumOfField:cardHeader.sumKeyName) / cardHeader.divideSumBy) | number: '1.1-1' }}
                </span>
                <ng-template #notDivideBy>
                    <span *ngIf="!cardHeader.isDivideSum">
                        <!-- not divide -->
                        {{ rows | mqFilter: appHardFilter | mqFilter: mqFilterString | contains: inputFilter | filter: fastSearch | sumOfField:cardHeader.sumKeyName }}
                    </span>
                </ng-template>
                <span *ngIf="cardHeader.showSuffix"> {{ cardHeader.suffix | translate }} </span>
            </ng-container>
            <ng-template #sumLessThanZero>
                <span *ngIf="cardHeader.showZeroInfo"> {{ cardHeader.zeroInfo | translate }} </span>
            </ng-template>
        </ng-container>
    </h2>
    <span fxFlex></span>
    <!-- right side options -->
    <div fxLayout="row wrap">
        <!-- refresh button -->
        <div style=" border-left: 1px solid #bdb4b4; background: lightgreen;">
            <button aria-label="datatable_settings_button" style="margin: auto 2px;" *ngIf="isAdmin"
                matTooltip="{{ 'dttable.refresh' | translate }}" (click)="refreshTable()" mat-icon-button>
                <mat-icon style="color: black">refresh</mat-icon>
            </button>
        </div>
        <!-- report button -->
        <div style="border-left: 1px solid #bdb4b4;">
            <button aria-label="datatable_settings_button" style="margin: auto 2px;" *ngIf="isAdmin"
                matTooltip="{{ 'dttable.report_generate' | translate }}" (click)="reportPopup()" mat-icon-button>
                <mat-icon style="color: black">description</mat-icon>
            </button>
        </div>
        <!-- filter button -->
        <div style="border-left: 1px solid #bdb4b4;">
            <button aria-label="datatable_settings_button" style="margin: auto 2px;" *ngIf="isAdmin"
                matTooltip="{{ 'dttable.advance_filter' | translate }}" (click)="toggleAdvanceFilter()" mat-icon-button>
                <mat-icon style="color: black">filter_list</mat-icon>
            </button>
        </div>
        <!-- more option button -->
        <div style="border-left: 1px solid #bdb4b4; border-right: 1px solid #bdb4b4;" class="labels" fxFlex="0 1 auto">
            <button mat-icon-button style="margin: auto 2px;" (click)="showMoreOptions=!showMoreOptions">
                <mat-icon>more_vert</mat-icon>
            </button>
        </div>
    </div>
    <!-- insert new record button -->
    <div class="ml-12" style="margin: auto;">
        <button mat-raised-button style="text-transform: capitalize;" (click)="editRecordClicked('new')" color="warn">
            {{ insertButtonText || 'Dodaj' }} </button>
    </div>
    </div>
     <!-- show more div and options -->
     <div style="height: fit-content;" fxLayout="row" class="w-100-p fuse-white-bg pt-4 pb-4" *ngIf="showMoreOptions">
            <app-card-header-more-options (openWSAppsSettingsClicked)="openWSAppsSettingsClicked($event)"
                [isAdmin]="isAdmin" [cardActions]="cardActions" [(sortByField)]="sortByField" [(sortByOrder)]="sortByOrder">
            </app-card-header-more-options>
        </div>
    
        <!-- card search options. -->
        <div style="height: fit-content;" fxLayout="row wrap" fxLayoutAlign="center center" class="fuse-white-bg"
            *ngIf="advanceFilter && cardActions && cardActions.length">
            <!-- cardActions -->
            <div  *ngFor="let action of cardActions | filter:true:'showSearch'"
            [fxFlex]="(100/(cardActions | filter:true:'showSearch').length)"
                [ngSwitch]="action.searchType">
                <ng-container *ngIf="action.showSearch">
                    <!-- select -->
                    <mat-form-field floatLabel="always" appearance="outline" floatLabel="never" *ngSwitchCase="'dropdown'"
                        floatLabel="never"
                        [ngClass]="{'happ-small-form-field-outline w-100-p': true, 'happ-small-form-field-outline-gray': (inputFilter[action.keyName] && inputFilter[action.keyName].length)}">
                        <mat-label> {{ action.name | translate }} <span *ngIf="inputFilter[action.keyName]">
                                {{ (rows | mqFilter: appHardFilter | mqFilter: mqFilterString | fullTextSearch:inputFilter[action.keyName]:action.keyName )?.length }}
                            </span>
                        </mat-label>
                        <mat-select (selectionChange)="filterChanged()" [(ngModel)]="inputFilter[action.keyName]">
                            <mat-option *ngFor="let item of action.options | sort:'sort'" [value]="item.value"> {{ item.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- input -->
                    <mat-form-field floatLabel="always" appearance="outline" floatLabel="never" *ngSwitchDefault
                        floatLabel="never"
                        [ngClass]="{'happ-small-form-field-outline w-100-p': true, 'happ-small-form-field-outline-gray': (inputFilter[action.keyName] && inputFilter[action.keyName].length)}">
                        <mat-label> {{ action.name | translate }} <span *ngIf="inputFilter[action.keyName]">
                                {{ (rows | mqFilter: appHardFilter | mqFilter: mqFilterString | fullTextSearch:inputFilter[action.keyName]:action.keyName )?.length }}
                            </span>
                        </mat-label>
                        <input type="text" (keyup)="filterChanged()" autofocus="true" matInput
                            [(ngModel)]="inputFilter[action.keyName]">
                    </mat-form-field>
                </ng-container>

            </div>
        </div>
</div>
<div class="mt-4 mb-16" style="background: #eeeeee; height: calc(100vh - 230px); overflow: auto; display: block;    box-sizing: border-box;flex-direction: row;" *ngIf="rows && !loadingIndicator">
    <!-- card starts -->
    <mat-card style="height: fit-content;" fxLayout="column"
        [class]="(expandedRecordId && expandedRecordId === row._id) ? 'mt-4 p-8 fuse-white-bg' : 'mt-4 p-8 happ-card-hover'"
        fxFlex="100"
        *ngFor="let row of rows | mqFilter: appHardFilter | mqFilter: mqFilterString | contains: inputFilter | filter: fastSearch | sort:sortByField:sortByOrder:(sortByField === 'estTime') ? 'number' : 'string'">
        <!-- without collapse -->
        <div *ngIf="!expandedRecordId || (expandedRecordId && expandedRecordId !== row._id)">
            <div fxLayout="row">
                <div style="cursor: pointer;" (click)="toggleViewToMore(row._id)" class="text-truncate" fxFlex [innerHTML]="row.cardHeader | parseHtmlContent:row | htmlWithStyle">
                </div>
                <div style="width: 100px;" [class]="(row.recordMainDocument) ? 'mr-12 w-80' : 'mr-12 w-40'" >
                    <span fxFlex></span>
                    <div style="margin: auto;">
                        <button *ngIf="row.recordMainDocument" (click)="downloadPdf(row.recordMainDocument)" mat-icon-button>
                            <mat-icon>description</mat-icon>
                        </button>
                        <button mat-icon-button class="happ-hover-icon-button" (click)="editRecordClicked(row._id)">
                            <mat-icon style="color: black">edit</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- collapse opened -->
        <div fxLayout="column" *ngIf="expandedRecordId && expandedRecordId === row._id">
            <div style="border-bottom: 5px solid darkblue;" class="pb-8" fxFlex="100" fxLayout="row">
                <div style="cursor: pointer" (click)="toggleViewToMore(row._id)" fxFlex
                    [innerHTML]="row.cardHeader | parseHtmlContent:row | htmlWithStyle">
                </div>
                <div fxFlex="5" style="width: 100px;" [class]="(row.recordMainDocument) ? 'mr-12 w-80' : 'mr-12 w-40'">
                    <span fxFlex></span>
                    <div style="margin: auto;">
                        <button *ngIf="row.recordMainDocument" (click)="downloadPdf(row.recordMainDocument)" mat-icon-button>
                            <mat-icon>description</mat-icon>
                        </button>
                        <button mat-icon-button class="happ-hover-icon-button" (click)="editRecordClicked(row._id)">
                            <mat-icon style="color: black">edit</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="cardActions" fxLayout="row">
                <app-card-actions [rowData]="row" (optionClicked)="cardOptionChangedClicked($event, row._id)"
                    [cardActions]="cardActions | filter:true:'showAction'"></app-card-actions>
            </div>
            <div *ngIf="row.cardBody" class="mr-12 ml-12"
                [innerHTML]="row.cardBody | parseHtmlContent:row | htmlWithStyle">
            </div>
            <div *ngIf="row.cardFooter" class="mr-12 ml-12"
                [innerHTML]="row.cardFooter | parseHtmlContent:row | htmlWithStyle">
            </div>
        </div>
    </mat-card>
    <div fxLayoutAlign="center center" class="w-100-p h-200"
        *ngIf="!(rows | mqFilter: appHardFilter | mqFilter: mqFilterString | contains: inputFilter | filter: fastSearch)?.length">
        <p class="font-size-30"> {{ 'dttable.no_data_to_show' | translate }} </p>
    </div>
</div>
<div style="background: #eeeeee; min-height: 400px;" fxLayoutAlign="center center" class="w-100-p"
    *ngIf="loadingIndicator">
    <mat-spinner></mat-spinner>
</div>