<div style="position: relative; " class="my-16" fxLayout="column" *ngIf="!field.hide || builder">
  <div fxLayout="row">
    <span fxFlex></span>
    <div>
      <button class="mb-12" mat-raised-button (click)="editOptions(null, null)" > {{ 'ct.add_variant' | translate }} </button>
    </div>
  </div>
  <div>
    <table *ngIf="model">
      <thead>
        <tr>
          <th>{{ 'ct.name' | translate }}</th>
          <th>{{ 'ct.description' | translate }}</th>
          <th>{{ 'ct.price' | translate }}</th>
          <th>{{ 'ct.productCode' | translate }}</th>
          <th style="text-align: right;" >{{ 'ct.action' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of model; let i=index;" >
          <td>{{ item.name }}</td>
          <td>{{ item.description }}</td>
          <td>{{ item.price }}</td>
          <td>{{ item.productCode }}</td>
          <td style="text-align: right;"> 
            <button class="mr-12" mat-raised-button (click)="editOptions(item, i)" > 
              {{ 'ct.edit' | translate }} 
            </button> 
            <button mat-raised-button color="warn" (click)="delete(item, i)" > 
              {{ 'ct.delete' | translate }} 
            </button> 
          </td>
        </tr>
      </tbody>
    </table>
    <div fxLayoutAlign="center center" *ngIf="!model || (model && model.length === 0)" class="mt-12" >
      <p style="font-size: 24px;">
        {{ 'ct.no_variant_yet_start_adding_one' | translate }}
      </p>
    </div>
  </div>
<div class="happ-field-description" *ngIf="field.allow_description && field.description"> {{ field.description }} </div>
<div class="error-message" *ngIf="errorMessage && (!min_error && !max_error)"> {{ errorMessage | translate }}
</div>
</div>