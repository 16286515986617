<div>
  <mat-toolbar style="background: #f6f6f6;" matDialogTitle class="m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <button class="mr-8" (click)="dialogRef.close()" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
      <span class="title dialog-title"> {{ 'ct.add_variant_dialog' | translate }} </span>
      <span fxFlex></span>
      <div fxLayout="row"  fxLayoutAlign="start center">
        <button aria-label="add_options"  class="mx-4" (click)="addOption()" mat-button mat-raised-button>
          {{ 'ct.save' | translate }} 
        </button>
      </div>
    </div>
  </mat-toolbar>
  <div style="overflow: auto;" class="p-12" *ngIf="data" fxLayout="column">
    <mat-form-field class="example-full-width">
      <mat-label> {{ 'ct.product_code' | translate }} </mat-label>
      <input matInput [(ngModel)]="prodData.productCode" [placeholder]="'ct.product_code' | translate">
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <mat-label>{{ 'ct.product_name' | translate }}</mat-label>
      <input matInput [(ngModel)]="prodData.name" [placeholder]="'ct.product_name' | translate">
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <mat-label>{{ 'ct.product_description' | translate }}</mat-label>
      <input matInput [(ngModel)]="prodData.description" [placeholder]="'ct.product_description' | translate">
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <mat-label>{{ 'ct.product_prize' | translate }}</mat-label>
      <input matInput [(ngModel)]="prodData.price" [placeholder]="'ct.product_prize' | translate" type="number">
    </mat-form-field>      
  </div>
  <!-- <div class="p-24 pt-20 pb-20">
    <button (click)="dialogRef.close(null)" aria-label="custom_app_cancel_button" mat-raised-button class="mr-4">{{ 'ct.cancel' | translate }}</button>
    <span fxFlex></span>
    <button (click)="dialogRef.close(fieldOptions)" aria-label="custom_app_cancel_button" mat-button class="mr-4">{{ 'ct.close' | translate }}</button>
    <button mat-raised-button [disabled]="loading" aria-label="custom_app_create_button" (click)="update()">{{ 'ct.update' | translate }}</button>
  </div> -->
</div>