import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DtTableAdvanceFilterInterface } from './dt-table-advance-filter.interface';

@Component({
  selector: 'app-dt-table-advance-filter',
  templateUrl: './dt-table-advance-filter.component.html',
  styleUrls: ['./dt-table-advance-filter.component.scss']
})
export class DtTableAdvanceFilterComponent implements OnInit {

  filters: Array<DtTableAdvanceFilterInterface> = [
    {
      'name': 'test',
      'value': '',
      'sort': 50
    }
  ];
  showInfo = false;
  hardFilter = '';
  viewType: any;

  constructor(
    public dialogRef: MatDialogRef<DtTableAdvanceFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.filters = data.viewFilters || this.filters || [];
    this.viewType = data.viewType || "form";
  }

  ngOnInit() {
  }

  save() {
    this.dialogRef.close(this.filters);
  }

  setDefaultFilter(i) {
    this.filters = this.filters || [];
    this.filters.map(ele => {
      ele['defaultFilter'] = false;
    });
    this.filters[i]['defaultFilter'] = true;
  }

}
