import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
var MailingLogComponent = /** @class */ (function () {
    function MailingLogComponent(helperService) {
        this.helperService = helperService;
    }
    MailingLogComponent.prototype.ngOnInit = function () {
        this.getLogs();
    };
    MailingLogComponent.prototype.getLogs = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.helperService.getRequest("api/mailing-list/logs/" + this.recId)];
                    case 1:
                        _a.logs = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return MailingLogComponent;
}());
export { MailingLogComponent };
