import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
var SmtpFormComponent = /** @class */ (function () {
    function SmtpFormComponent(dialogRef, data, helperService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.helperService = helperService;
    }
    SmtpFormComponent.prototype.ngOnInit = function () {
        this.smtpSettings = this.data.smtpSettings || {};
        // delete this.smtpSettings.password
    };
    SmtpFormComponent.prototype.save = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        if (this.data.type === "smtpConfig") {
                            this.smtpSettings.type = "default";
                        }
                        return [4 /*yield*/, this.helperService.postRequest("api/office-smtp", this.smtpSettings)];
                    case 2:
                        _a.sent();
                        this.loading = false;
                        this.dialogRef.close(this.smtpSettings);
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        alert(error_1);
                        this.loading = false;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SmtpFormComponent.prototype.close = function () {
        this.dialogRef.close();
    };
    return SmtpFormComponent;
}());
export { SmtpFormComponent };
