import { NgModule } from '@angular/core';

import { AfterPluginInstallComponent } from './after-plugin-install.component';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
    imports     : [
       FuseSharedModule
        // GlobalModule,
    ],
    declarations: [
        AfterPluginInstallComponent
    ],
    providers   : [
    ],
    exports: [
        AfterPluginInstallComponent
    ],
    entryComponents: [
        AfterPluginInstallComponent
    ]
})
export class AfterPluginInstallModule
{
}
