<div style="background: #039be5; color: white;" class="m-0">
  <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <!-- <button class="mr-12" (click)="dialogRef.close(null)" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button> -->
    <span class="title dialog-title">
      <h2 class="ml-16">{{ 'general.action_comment' | translate }} 
        <!-- <button
          (click)="openInstructionPopup('info.process_comment')" mat-icon-button>
          <mat-icon>info</mat-icon>
        </button> -->
      </h2>
    </span>
    <span fxFlex></span>
    <!-- <button class="mr-12" (click)="saveData()" mat-icon-button>
      <mat-icon>check</mat-icon>
    </button> -->
  </div>
</div>
<div>
  <div fxLayout="column" style="width: 96%; margin: auto;">
    <h3 class="ml-8 mb-0" *ngIf="data && data.commentText">
      {{ data.commentText | textTranslate:currentLang }}
    </h3>
    <mat-form-field class="happ-small-form-field-outline w-100-p py-12" floatLabel="never" floatLabel="always" appearance="outline" fxFlex="100">
      <!-- <mat-label>{{ 'general.comment' | translate }}</mat-label> -->
      <textarea matInput rows="10" [(ngModel)]="data.comment"></textarea>
    </mat-form-field>
  </div>
  <div class="p-12 pt-0" fxLayout="row">
    <span fxFlex></span>
    <div fxFlex="row">
      <div>
        <button class="mr-12" mat-raised-button (click)="dialogRef.close(null)" >
          {{ 'general.cancel' | translate }}
        </button>
      </div>
      <span fxFlex></span>
      <div>
        <button mat-raised-button color="warn" (click)="saveData()" >
          {{ 'general.proceed' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>