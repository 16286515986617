import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpResponseBase } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/timeout';
import 'rxjs/add/operator/finally';
import 'rxjs/add/observable/timer';
import { MatDialog } from '@angular/material';
import { MaintenanceComponent } from '../maintenance/maintenance.component';
import { of, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  
CODEMESSAGE = {
  200: 'The server successfully returned the requested data. ',
  201: 'New or modified data is successful. ',
  202: 'A request has entered the background queue (asynchronous task). ',
  204: 'Delete data successfully. ',
  400: 'The request was sent with an error. The server did not perform any operations to create or modify data. ',
  401: 'The user does not have permission (token, username, password is incorrect). ',
  403: 'User is authorized, but access is forbidden. ',
  404: 'The request sent is for a record that does not exist and the server is not operating. ',
  406: 'The format of the request is not available. ',
  410: 'The requested resource is permanently deleted and will not be obtained again. ',
  422: 'When creating an object, a validation error occurred. ',
  500: 'The server has an error. Please check the server. ',
  502: 'Gateway error. ',
  503: 'The service is unavailable, the server is temporarily overloaded or maintained. ',
  504: 'The gateway timed out. ',
};


  constructor(
    private injector: Injector
  ) {
  }

  private checkStatus(ev: HttpResponseBase) {
    if ((ev.status >= 200 && ev.status < 300) || ev.status === 401) {
      return;
    }
    return;
  }
  private handleData(ev: HttpResponseBase): Observable<any> {
    this.checkStatus(ev);
    switch (ev.status) {
      case 200:
        if (ev instanceof HttpResponse) {
          const body: any = ev.body;
          if (body && body.success) {
            return of(new HttpResponse({ ...ev, body: body || {} }));
          } else {
            if (body.message) {
              this.notification.error('Backend Error:', body.message);
            }
            return of(new HttpResponse({ ...ev, body: body || {} }));
          }
        } else {
          return of(ev);
        }
        // }
        break;
      case 401:
          if (ev instanceof HttpErrorResponse) {
            // const errortext = (ev.error && ev.error.message) || this.CODEMESSAGE[ev.status];
            // this.notification.error(errortext, ev.statusText);
            // this.
            localStorage.clear();
            document.cookie.split(';').forEach(function(c) { document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/'); });
            console.log("movign toe login screen");
            this.goTo('/login');
            return of(new HttpResponse({ ...ev, body: ev.error || {} }));
          }
        // Empty token information
        break;
      case 403:
      case 404:
      case 500:
        // this.goTo(`/exception/${ev.status}`);
        if (ev instanceof HttpErrorResponse) {
          const body: any = ev.error;
          const errortext = ev.statusText || this.CODEMESSAGE[ev.status];
          this.notification.error(body.message, errortext);
          return of(new HttpResponse({ ...ev, body: body || {} }));
        }
        break;
      default:
        if (ev instanceof HttpErrorResponse) {
          const errortext = ev.statusText || this.CODEMESSAGE[ev.status];
          this.notification.error('I don\'t know the error, maybe the backend is down or restarting try after some time.', '');

          return throwError(ev);
        }
        break;
    }
    return of(ev);
  }

  private goTo(url: string) {
    setTimeout(() => this.injector.get(Router).navigateByUrl(url));
  }

  private get notification(): ToastrService {
    return this.injector.get(ToastrService);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   // Unified plus server prefix
    const url = req.url;
    // if (!url.startsWith('https://') && !url.startsWith('http://')) {
    //   url = environment.serverUrl + url;
    // }
    // let headers = req.headers;
    // if (this.tokenService.get()) {
    //   headers = headers.append('Authorization', this.tokenService.get().token);
    // }

    const newReq = req.clone({ url });
    return next.handle(newReq).pipe(
      mergeMap((event: any) => {
        // Allow unified handling of request errors
        if (event instanceof HttpResponseBase) return this.handleData(event);
        // If everything is ok, follow-up
        return of(event);
      }),
      catchError((err: HttpErrorResponse) => this.handleData(err)),
    );
  }
  
}
