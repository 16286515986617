<div style="position: relative;" fxLayout="column" *ngIf="bankRes && !field.hide || builder">
    <ng-container *ngIf="bankRes && bankRes.statusVat === 'Czynny'">
        <p style="margin: 0px; background: green; color: white; padding: 10px; font-size: 18px; text-align: center;">
            {{ 'ct.valid' | translate }}
        </p>

        <a style="margin: 12px; cursor: pointer;" (click)="viewDetail()" > {{ 'ct.company_detail' | translate }} </a>
    </ng-container>
    <ng-container *ngIf="bankRes && bankRes.statusVat !== 'Czynny'">
      <p style="margin: 0px; background: red; color: white; padding: 10px; font-size: 18px; text-align: center;">
          {{ 'ct.invalid' | translate }}
      </p>
    </ng-container>

</div>