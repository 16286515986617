import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { CallHistoryComponent } from 'app/main/content/apps/voip-call/call-history/call-history.component';
import { VoipCallComponent } from 'app/main/content/apps/voip-call/voip-call/voip-call.component';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { SocketService } from 'app/main/content/services/socket/socket.service';

@Component({
    selector     : 'quick-panel',
    templateUrl  : './quick-panel.component.html',
    styleUrls    : ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent {
    incomingEnable = true;
    autoPopup = true;
    dndEnable = false;
    items = [];


    date: Date;
    incomingLength = 0;
    ongoingLength = 0;
    currentClass = 'di-small-panel';
    events: any[];
    notes: any[];
    settings: any;

    isPopUpOpened = false;

    waitingCallStates = [];
    incomingCall = [];
    ongoingCallState = [];
    endedCallState = [];
    currentUser: any;
    allStatus = [
        {
            heading: 'popup.waiting_calls',
            length: 0,
            currentStates: ['callstart'],
            isEnable: false,
            currentCalls: [],
            class: 'waiting',
            showOpenButton: false,
        },
        {
            heading: 'popup.incoming_calls',
            length: 0,
            currentStates: ['callringing'],
            isEnable: true,
            currentCalls: [],
            class: 'incoming',
            showOpenButton: true,
        },
        {
            heading: 'popup.ongoing_calls',
            length: 0,
            currentStates: ['callanswer'],
            isEnable: true,
            currentCalls: [],
            class: 'ongoing',
            showOpenButton: true,
        },
        // {
        //     heading: 'popup.ended_calls',
        //     length: 0,
        //     currentStates: ['callend'],
        //     isEnable: true,
        //     currentCalls: [],
        //     class: 'ended',
        //     showOpenButton: false,
        // },
    ];

    autoPopupOpened = {};
    allPopupCalls = [];

    /**
     * Constructor
     */
    constructor(
        public socketService: SocketService,
        private _fuseSidebarService: FuseSidebarService,
        private helperService: HelpersService,
        private cdr: ChangeDetectorRef,
        public dialog: MatDialog,
    )
    {
        // Set the defaults
        this.date = new Date();
        this.settings = {
            notify: true,
            cloud : false,
            retro : true
        };
        this.socketService.onCallEvent.subscribe((data) => {
            if (data.eventType) {
                let profile = localStorage.getItem('ngStorage-profile');
                if (profile) {
                    profile = JSON.parse(profile);
                    this.currentUser = profile;
                }
                this._fuseSidebarService.getSidebar('quickPanel').open();
                this.checkExistingOngoingCall();
                this.cdr.detectChanges();
            }
        });
        let profile = localStorage.getItem('ngStorage-profile');
        if (profile) {
            profile = JSON.parse(profile);
            this.currentUser = profile;
        }
    }

    checkCallState(data: any) {
        if (data && data.operator && data.operator.email && this.currentUser && data.operator.email === this.currentUser.email) {
          return 'in_call';
        } else if (data && data.operator && data.operator.email && this.currentUser && data.operator.email !== this.currentUser.email) {
          return 'not_in_call';
        } else if (data && data.ringingOn && data.ringingOn.email && this.currentUser && data.ringingOn.email === this.currentUser.email) {
          return 'ringing_call'
        }
      }


    openCallUi(item: any) {
        if (this.isPopUpOpened) {
            this.allPopupCalls.push({
                callId: item.callId,
                from: item.from,
                stateToShow: this.checkCallState(item)
            });
        } else {
            this.isPopUpOpened = true;
            this.allPopupCalls.push({
                callId: item.callId,
                from: item.from,
                stateToShow: this.checkCallState(item)
            });
            item.allPopupCalls = this.allPopupCalls;
            this.helperService.openCallPopup(item)
            .afterClosed().subscribe(() => {
                this.isPopUpOpened = false;
                this.allPopupCalls = [];
            });
        }
    }

    updateCallState(item: any, index: number) {
        console.error("item for update - ", item, index);
        if (this.allPopupCalls && this.allPopupCalls[index] && this.allPopupCalls[index].stateToShow) {
            console.log("----------", this.checkCallState(item));
            this.allPopupCalls[index].stateToShow = this.checkCallState(item);
        }
    }

    checkExistingOngoingCall() {
        this.allStatus = this.allStatus.map((ele) => {
            ele.currentCalls = this.socketService.callsList.filter((ele2) => (ele.currentStates.includes(ele2.callState)));
            ele.length = ele.currentCalls.length;
            if (this.autoPopup) {
                ele.currentCalls.forEach((ele2, index) => {
                    if (ele2 && ele2.ringingOn && ele2.ringingOn.id === this.currentUser.profileId && this.autoPopupOpened[ele2.callId]) {
                        this.updateCallState(ele2, index);
                    }
                    if (ele2 && ele2.ringingOn && ele2.ringingOn.id === this.currentUser.profileId && !this.autoPopupOpened[ele2.callId]) {
                        this.autoPopupOpened[ele2.callId] = true;
                        this.openCallUi(ele2);
                    }
                });
            }
            return ele;
        });
    }

    close() {
        this._fuseSidebarService.getSidebar('quickPanel').close();
    }

    toggleClass() {
        if (this.currentClass === 'di-small-panel') {
            this.currentClass = 'di-full-panel';
        } else {
            this.currentClass = 'di-small-panel';
        }
    }

    openLogs() {
        const dialogRef = this.dialog.open(CallHistoryComponent, {
        width: '100%',
        height: '100%',
        restoreFocus: false,
        panelClass: 'happ-form-builder-dialog',
        });
    }
}
