
export class Page {
    // The number of elements in the page
    // tslint:disable-next-line:no-inferrable-types
    size: number = 0;
    // The total number of elements
    totalElements: number = 0;
    // The total number of pages
    totalPages: number = 0;
    // The current page number
    pageNumber = 1;
}
