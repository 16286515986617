import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TemplatePreviewComponent } from 'app/main/content/components/template-preview/template-preview.component';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { DescriptionPopupComponent } from '../../datatable/description-popup/description-popup.component';
import { SelectTemplate } from '../../drag-and-drop/select-template/select-template.component';

@Component({
  selector: 'app-ct-template-selection',
  templateUrl: './ct-template-selection.component.html',
  styleUrls: ['./ct-template-selection.component.scss']
})
export class CtTemplateSelectionComponent implements OnInit {

  @Input() translateData: any;
  @Input() field;
  @Input() regRec;
  @Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Output() updateRecord: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() model: any = []; 
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onError: EventEmitter<Object> = new EventEmitter();
  loading: boolean;
  
  constructor(
    private translationLoader: FuseTranslationLoaderService,
    public dialogSec: MatDialog,
    private helperService: HelpersService
  ) { }

  ngOnChanges(changes: any) {

    if (changes && changes.translateData && changes.translateData.currentValue) {
      this.translateData = changes.translateData.currentValue;
      this.translationLoader.loadTranslationsV2(this.translateData);    
    }
  }

  ngOnInit() {
  }


  selectTemplate(type) {
    const key = `${this.field.label}${type}`;
    console.log("this.regRec[`${key}Id`]", this.regRec[`${key}Id`]);
    const dialogRef = this.dialogSec.open(SelectTemplate, {
      width: '100%',
      data: { 
        templateList: null, 
        selectedTemplate: this.regRec[`${key}Id`] || [],
        allowMultipleSelection: false,
        allowSelection: true,
        fields: ['_id', 'title']
      },
      panelClass: 'happ-form-builder-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("result", result);
        if (result) {
          const temp: any = {};
          temp[`${key}Id`] = result._id;
          temp[`${key}Name`] = result.title;
          this.regRec[`${key}Id`] = result._id;
          this.regRec[`${key}Name`] = result.title;
          this.updateRecord.emit(temp);
        }
    });
  }

  async previewTemplate(type) {
    try {
      const key = `${this.field.label}${type}Id`;
      console.log("key", key);
      console.log("this.regRecr", this.regRec);
      this.loading = true;
      const templateData = await this.helperService.getTemplate(this.regRec[key]);
      this.loading = false;
      const dialogRef = this.dialogSec.open(TemplatePreviewComponent, {
        width: '100%',
        height: '100%',
        panelClass: 'happ-form-builder-dialog',
        restoreFocus: false,
        data: { templateData }
      });
    } catch (error) {
      this.loading = false
      alert(error);
    }
  }
}
