import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getUserInfoByEmail',
    pure: false
})
export class GetUserInfoByEmailPipe implements PipeTransform
{
    /**
     * Transform
     *
     * @param {any[]} value
     * @param {number} id
     * @param {string} property
     * @returns {any}
     */
    transform(value: string = '', property: string): any
    {
        const users = this.getLocalOfficeUsers();
        const foundItem = users.find(item => {
            if ( item.email !== undefined )
            {
                return item.email === value;
            }

            return false;
        });

        if ( foundItem ) {
            return foundItem[property] || `${value}`;
        } else {
            return `${value}`;
        }
    }

    getLocalOfficeUsers() {
        try {
            if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))) {
                const officeData: any = JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) || {};
                return officeData.users || [];
            } else {
                return [];
            }
        } catch (err) {
            return [];
        }
    }
}
