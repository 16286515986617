import { GeneralSettings } from './general-settings.const';
import { AdvanceSettings } from './advance-settings.const';
import { INg5courseSetting } from 'app/main/content/interfaces/register.interface';
import { DatatableUISettings } from './data-table-ui.const';
import { SuperAdminSettings } from './super-admin-settings.const';


export class WsAppsSettings {
    settingJson = [
        {
            'type': 'tab',
            'settings': []
        }
    ];
    constructor(url: string, appData: INg5courseSetting, moreInfo: any = {}) {
        this.settingJson[0].settings = [
            {
                'title': 'workspace.general',
                'children': new GeneralSettings(url, appData, moreInfo).settingsModel
              },
            {
                'title': 'workspace.data_table_ui',
                'children': new DatatableUISettings(url, appData, moreInfo).settingsModel
              },
              {
                'title': 'workspace.advance',
                'children': new AdvanceSettings(url, appData, moreInfo).settingsModel
              },
              {
                'title': 'workspace.superAdmin',
                'children': new SuperAdminSettings(url, appData, moreInfo).settingsModel
              }
        ];
    }

    get settingModel() {
        return this.settingJson;
    }
}
