<div *ngIf="data" class="dialog-content-wrapper" style="background: #fafafa;">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title"> {{ (data && data.title) || 'Select Area' }}</span>
      <span fxFlex></span>
      <div>
        <button mat-button class="mat-icon-button" (click)="dialogRef.close()" aria-label="Close dialog">
          <mat-icon>close</mat-icon>
        </button>
        <button mat-button class="mat-icon-button" (click)="save()">
          <mat-icon>check</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>

  <div class="happ-edit-form-container m-0" fusePerfectScrollbar style="background: white;padding: 16px;">
    <ng-container *ngIf="data.type === 'area'">
      <div fxLayout="row wrap">
        <div *ngFor="let item of data.mappedData">
          <button class="mr-8" mat-raised-button [color]="(data.selectedAreas.includes(item.key)) ? 'primary' : ''"
            (click)="toogleSelect(item)">
            {{ item.title | textTranslate:currentLang }}
          </button>
        </div>
        <!-- <h2>
          {{ item.title | textTranslate:currentLang }}
        </h2> -->
        <!-- <div fxLayout="row">
          <div *ngFor="let item2 of item.allTypes">
            <button class="mr-8" mat-raised-button (click)="toogleSelect(item, item2)" >
              {{ item2.title | textTranslate:currentLang }}
            </button>
          </div>
        </div> -->
      </div>
    </ng-container>
    <ng-container *ngIf="data.type === 'type'">
      <div fxLayout="column">
        <h2>
          {{ data.title }}
        </h2>
        <div fxLayout="row wrap">
          <div *ngFor="let item2 of data.selectedTypes">
            <button class="mr-8" mat-raised-button [color]="(data.mySelectedTypes.includes(item2.key)) ? 'primary' : ''"
              (click)="toogleSelectType(item2)">
              {{ item2.title | textTranslate:currentLang }}
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>