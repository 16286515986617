<mat-toolbar matDialogTitle fxLayoutAlign="center center" class="call-header-one mat-toolbar mat-accent m-0"
  fxLayout="row">
  <!-- <div style="background: #f6f6f6;" class="m-0 p-8" *ngIf="data"> -->
  <div fxFlex fxLayout="header" fxLayoutAlign="space-between center">
    <span class="title dialog-title">
      <p class="m-0 font-size-20 ml-12">
        {{ 'popup.mailing_footer_list' | translate }}
      </p>
    </span>
    <span fxFlex></span>
    <button class="mr-12" (click)="close()" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</mat-toolbar>
<div fxLayout="column" style="padding: 12px; border: 1px solid lightgray">
  <ng-container *ngIf="loading">
    <div class="w-100" fxLayout="row" fxLayoutAlign="center center" style="height: 300px;">
      <mat-spinner></mat-spinner>
    </div>
  </ng-container>
  <ng-container *ngIf="!loading">
    <div fxLayout="row">
      <h2 class="m-0">
        {{ 'ct.footer_list' | translate }}
      </h2>
      <span fxFlex></span>
      <div>
        <button mat-raised-button (click)="addOrEdit()">
          {{ 'ct.new' | translate }}
        </button>
      </div>
    </div>
    <div *ngIf="footerList">
      <table class="mt-12">
        <tr *ngFor="let list of footerList; let j=index">
          <td>
            <p class="m-0">{{ list.title }}</p>
          </td>
          <td style="width: 50px;">
            <button mat-icon-button (click)="addOrEdit(j)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </tr>
      </table>
    </div>
  </ng-container>
</div>