import { MapsAPILoader } from '@agm/core';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { latLng, TileLayer } from 'leaflet';
// declare var google: any;
import * as L from 'leaflet';
import 'leaflet.markercluster';

@Component({
  selector: 'app-geo-location',
  templateUrl: './geo-location.component.html',
  styleUrls: ['./geo-location.component.scss']
})
export class GeoLocationComponent implements OnInit, AfterViewInit {

  private map: L.Map;

  @Input() translateData: any;
  @Input() field;
  @Input() regRec;
  @Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Output() updateFormDataObj: EventEmitter<Object> = new EventEmitter();
  @Output() updateRecord: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() model: any = []; 
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onError: EventEmitter<Object> = new EventEmitter();

  title: string = 'AGM project';

  latitude: number;

  longitude: number;

  zoom: number;

  address: string;
  options = {
    layers: [
      new TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
    ],
    zoom: 5,
    center: latLng(46.879966, -121.726909)
  };

  geoCoder: any;
  tempModel = '';

  @ViewChild('agmMap') agmMap: any;
  @ViewChild('search') public searchElementRef: ElementRef;
  mapLatitude: number;
  mapLongitude: number;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) { 
    setTimeout(() => {
      this.initMap();
    }, 5000);
  }

  ngOnInit() {
    this.init();

    this.address = (this.regRec && this.regRec['selectedAddress']) || '';
    this.latitude = (this.regRec && this.regRec['location'] && this.regRec['location']['coordinates'] && this.regRec['location']['coordinates'][1]) || '';
    this.longitude = (this.regRec && this.regRec['location'] && this.regRec['location']['coordinates'] && this.regRec['location']['coordinates'][0]) || '';

    this.mapLatitude = this.latitude
    this.mapLongitude = this.longitude

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      console.log('Resizing');
      this.agmMap.triggerResize();
    }, 100);
  }

  private initMap(): void {
    this.map = L.map('map', {
      center: [20.5937, 78.9629], // Centered on India
      zoom: 5
    });

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(this.map);

    const markers = L.markerClusterGroup();

    // Generate 50 random markers in India
    for (let i = 0; i < 5000; i++) {
      const lat = 8.4 + Math.random() * 23.6;  // Latitude range for India (8.4 to 32)
      const lng = 68.7 + Math.random() * 28.3; // Longitude range for India (68.7 to 97)
      const marker = L.marker([lat, lng]);
      markers.addLayer(marker);
    }

    this.map.addLayer(markers);
  }


  init() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          
          this.formatData(place);

          this.mapLatitude = this.latitude
          this.mapLongitude = this.longitude

        });
      });
    });
  }

  onMarkerDragEnd(event) {
    console.log("Event = ", event);
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    // this.getDataFroMap();

  }

  formatData(place) {
    console.log("place", place);
    this.latitude = place.geometry.location.lat();
    this.longitude = place.geometry.location.lng();
    this.address = place.formatted_address;
    this.zoom = 18;

    const tempComps = {};

    place.address_components = place.address_components || [];

    place.address_components.forEach((ele) => {
      tempComps[ele.types[0]] = ele.long_name
    })

    const updateDoc = {
      location: {
        type: "Point",
        coordinates: [ this.longitude, this.latitude ]
      },
      selectedAddress: this.address,
      ...tempComps
    }

    this.updateRecord.emit(updateDoc);
    this.updateFormDataObj.emit(updateDoc);

  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (!this.latitude && !this.longitude) {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
        }
        this.zoom = 18;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  onMapClick(event: any) {
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    // this.getDataFroMap();
  }

  getDataFroMap() {
    

    const geocoder = new google.maps.Geocoder();
    let latlong = new google.maps.LatLng(this.latitude, this.longitude)

    geocoder.geocode({ 'location': latlong }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.formatData(results[0]);
        } else {
        }
      } else {
      }
    });
  }

  getAddress(latitude: any, longitude: any) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 18;
          this.address = results[0].formatted_address;
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }
    });
  }
}
