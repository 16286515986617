import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { InstanceTimelineComponent } from './instance-timeline.component';
import { NgxTimelineModule } from 'ngx-timeline' ;

@NgModule({
    declarations: [
      InstanceTimelineComponent
    ],
    imports     : [
       FuseSharedModule,
        NgxTimelineModule
    ],
    exports: [
      InstanceTimelineComponent
    ]
})

export class InstanceTimelineModule
{

}
