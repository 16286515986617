import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import * as _ from 'lodash';
var CtInfoSourceSelectComponent = /** @class */ (function () {
    function CtInfoSourceSelectComponent(helperService, translateService) {
        this.helperService = helperService;
        this.translateService = translateService;
        this.onFieldValueUpdated = new EventEmitter();
        this.modelChange = new EventEmitter();
        this.onError = new EventEmitter();
        this.list = [];
        this.loading = false;
        this.activeLang = 'en';
        this.filteredNames = [];
    }
    CtInfoSourceSelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activeLang = this.translateService.getDefaultLang();
        this.translateService.onLangChange.subscribe(function (data2) {
            _this.activeLang = data2;
        });
        this.getInformationSources();
    };
    CtInfoSourceSelectComponent.prototype.getInformationSources = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, user, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        this.loading = true;
                        _a = this;
                        return [4 /*yield*/, this.helperService.getRequest('api/integration-source/list')];
                    case 1:
                        _a.list = _b.sent();
                        this.filteredNames = this.list;
                        user = _.find(this.filteredNames, function (ele) {
                            return (ele && ele.key === _this.model);
                        });
                        console.log("user", user);
                        this.filterText = (user && user.key);
                        this.loading = false;
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _b.sent();
                        console.error(error_1);
                        this.loading = false;
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CtInfoSourceSelectComponent.prototype.checkError = function () {
        // errorValidation
        if (this.field['required'] && (!this.model || this.model && this.model.length === 0)) {
            this.error = true;
            this.errorMessage = 'ct.field_is_required';
            if (!this.model) {
                this.onError.emit({
                    error: true,
                    label: this.field['label']
                });
            }
        }
        else {
            this.error = false;
            this.errorMessage = '';
            if (this.model) {
                this.onError.emit({
                    error: false,
                    label: this.field['label']
                });
            }
        }
    };
    CtInfoSourceSelectComponent.prototype._filterStates = function (value) {
        var filterValue = value.toLowerCase();
        return this.list.filter(function (state) { return (state && state.key.toLowerCase().indexOf(filterValue) === 0); });
    };
    CtInfoSourceSelectComponent.prototype.changed = function (value) {
        this.filteredNames = this._filterStates(this.filterText);
    };
    CtInfoSourceSelectComponent.prototype.onSelection = function () {
        var _this = this;
        var user = _.find(this.filteredNames, function (ele) {
            return (ele && ele.key === _this.filterText);
        });
        this.model = (user && user.key);
        this.modelChange.next(this.model);
    };
    return CtInfoSourceSelectComponent;
}());
export { CtInfoSourceSelectComponent };
