import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-ss-event-form-add-popup',
  templateUrl: './ss-event-form-add-popup.component.html',
  styleUrls: ['./ss-event-form-add-popup.component.scss']
})
export class SsEventFormAddPopupComponent implements OnInit {

  formData: any = {};

  constructor(
    public dialogRef: MatDialogRef<SsEventFormAddPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.formData = this.data.data || {};
  }

  ngOnInit() {
    console.log("data - ", this.data);
  }


  save() {
    this.dialogRef.close({
      ...this.formData
    })
  }


  delete() {
    this.dialogRef.close({
      operation: "delete"
    })
  }
}
