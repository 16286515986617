import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
var MailingFooterComponent = /** @class */ (function () {
    function MailingFooterComponent(helperService, dialogRef, data) {
        this.helperService = helperService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.templateData = {
            category: '',
            title: '',
            logoWidth: '100px',
            logoHeight: '50px',
            shortSymbol: '',
            logoBase64: '',
            type: 'footer',
            gridColumns: [
                {
                    width: 10,
                    align: 'LEFT',
                    heading: 'New Column 1',
                    variables: '##line##'
                }
            ],
            header: {
                show: true,
                editorType: "ck_editor",
                subType: "superAdmin",
                headerTemplate: "custom",
                slogan: '',
                selectedLangs: ['en'],
                content: '',
                translation: {}
            },
            footer: {
                show: true,
                editorType: "ck_editor",
                subType: "superAdmin",
                footerTemplate: 'custom',
                selectedLangs: ['en'],
                pageTemplateVar: '',
                content: '',
                slogan: '',
                translation: {}
            },
            mainContent: {
                editorType: "ck_editor",
                subType: "superAdmin",
                selectedLangs: ['en'],
                content: '',
                translation: {}
            },
            printringVarialbles: {
                line: {
                    header: true,
                    footer: true
                },
                margin: {
                    bottom: 20,
                    top: 20
                },
                spacing: {
                    footer: 5,
                    header: 5
                }
            }
        };
    }
    MailingFooterComponent.prototype.ngOnInit = function () {
        this.helperService.getTemplateList();
        this.templateData = this.data || this.templateData || {};
    };
    MailingFooterComponent.prototype.save = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.templateData.title = this.title;
                        this.templateData.type = 'footer';
                        this.templateData.footer = this.templateData.footer || {};
                        this.templateData.footer.content = this.footerContent;
                        return [4 /*yield*/, this.helperService.createTemplate(this.templateData)];
                    case 1:
                        _a.sent();
                        this.dialogRef.close(true);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        alert(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MailingFooterComponent.prototype.close = function () {
        this.dialogRef.close();
    };
    return MailingFooterComponent;
}());
export { MailingFooterComponent };
