<div id="register" fxLayout="row" fxLayoutAlign="start">

    <div id="register-intro" fxFlex fxHide fxShow.gt-xs>

    </div>

    <div id="register-form-wrapper" fusePerfectScrollbar *fuseIfOnDom [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">

        <div>
            <img style="width: 352px; margin: 24px;" src="assets/images/header-logo.jpg" alt="header logo">
        </div>

        <div id="register-form">

            <div fxLayout="row" class="mb-24" fxLayoutGap="10px" fxLayoutAlign="center space-around" >
                <!-- <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/images/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </div>
                </button> -->
                <mat-select (selectionChange)="setLanguage(selectedLangCode)" [(ngModel)]="selectedLangCode" class="w-100-p" placeholder="{{ 'auth.select_language' | translate }}">
                        <mat-option *ngFor="let lang of languages" [value]="lang.code"  >
                          {{lang.title}}
                        </mat-option>
                      </mat-select>
              </div>

            <div class="title">{{ 'auth.create_an_account' | translate }}</div>


            <form name="registerForm" [formGroup]="registerForm" novalidate>


                <mat-form-field>
                    <input matInput placeholder="Email" formControlName="email">
                    <mat-error *ngIf="registerFormErrors.email.required">
                        {{ 'auth.email_is_required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="!registerFormErrors.email.required && registerFormErrors.email.email">
                        {{ 'auth.please_enter_valid_email_address' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <input matInput type="password" placeholder="{{ 'auth.password' | translate }}" formControlName="password">
                    <mat-error *ngIf="registerFormErrors.password.required">
                        {{ 'auth.password_is_required' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <input matInput type="password" placeholder="{{ 'auth.password' | translate }}  ({{ 'auth.confirm' | translate }})" formControlName="passwordConfirm">
                    <mat-error *ngIf="registerFormErrors.passwordConfirm.required">
                        {{ 'auth.password_confirmation_is_required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="registerFormErrors.passwordConfirm.passwordsNotMatch">
                        {{ 'auth.password_must_match' | translate }}

                    </mat-error>
                </mat-form-field>

                <div class="terms" fxLayout="row" fxLayoutAlign="center center">
                    <mat-checkbox name="terms" aria-label="Accept" required>
                        <span>
                        {{ 'auth.accept_term_and_condition' | translate }}
                        </span>
                    </mat-checkbox>
                </div>

                <div class="terms" fxLayout="row" fxLayoutAlign="center center">
                    {{ errMess }}
                </div>
                <button mat-raised-button color="accent" (click)="signup()" class="submit-button" aria-label="CREATE AN ACCOUNT" [disabled]="registerForm.invalid">
                  {{ 'auth.create_an_account' | translate }}
                </button>

            </form>

            <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">
                    {{ 'auth.already_have_account' | translate }} ?
                </span>
                <a class="link" [routerLink]="'/login'">
                  {{ 'auth.login' | translate }}
                </a>
            </div>
            <div>
                <img src="assets/images/footer-logo.jpg" alt="footer logo">
            </div>
        </div>
    <p style="position: fixed;bottom: 0px;right: 150px;font-size: 18px; color: rgb(3,155,229)"> {{ version }} </p>

    </div>
</div>
