<ng-container *ngIf="settings">
  <ng-container *ngFor="let setting of settings.fields">
      <app-setting-item-view *ngIf="oldVal | mqFilter:setting.filterString:'string':tempCounter" [heading]="setting.title" [description]="setting.description" [value]="(setting.showValue) ? setting.initValue : ''" >
        <ng-container [ngSwitch]="setting.fieldType" >
          <!-- input field -->
          <mat-form-field *ngSwitchCase="'input'" class="happ-small-form-field-outline" floatLabel="never" floatLabel="always" appearance="outline" style="width: 200px;">
            <input [disabled]="setting.disabled" [type]="setting.type || 'text'" (blur)="valueChanged(setting.initValue, setting)" [(ngModel)]="setting.initValue" matInput placeholder="">
          </mat-form-field>

          <!-- select field -->
          <mat-form-field *ngSwitchCase="'select'" class="happ-small-form-field-outline" floatLabel="never" floatLabel="always" appearance="outline" style="width: 200px;">
            <mat-select (selectionChange)="valueChanged(setting.initValue, setting)" [(ngModel)]="setting.initValue">
              <mat-option *ngFor="let option of setting.options" [value]="option.value">
                {{ option.title | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- checkbox -->
          <div *ngSwitchCase="'checkbox'">
            <mat-checkbox (change)="valueChanged(setting.initValue, setting)" [(ngModel)]="setting.initValue" ></mat-checkbox>
          </div>

          <!-- open popup -->
          <div *ngSwitchCase="'popup'">
            <button mat-raised-button color="warn" (click)="openPopup(setting)" > {{ setting.buttonTitle | translate }} </button>
          </div>

          <!-- date range filter -->
          <div *ngSwitchCase="'date-filter'">
            <app-date-filter-field class="m-8" (modelChange)="dateFilterChange($event, setting)" [model]="setting.initValue" [fieldTitle]="''"></app-date-filter-field>
          </div>

          <!-- Color selection -->
          <div *ngSwitchCase="'color_selection'">
            <fuse-material-color-picker (colorChanged)="colorSelectionChanges($event, setting)" [(ngModel)]="setting.initValue">
            </fuse-material-color-picker>
          </div>

          <!-- route -->
          <div *ngSwitchCase="'route'">
            <button mat-raised-button color="warn" (click)="moveTo(setting)" > {{ setting.buttonTitle | translate }} </button>
          </div>
        </ng-container>
      </app-setting-item-view>
  </ng-container>
</ng-container>