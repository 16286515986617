import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-rwa-input',
  templateUrl: './rwa-input.component.html',
  styleUrls: ['./rwa-input.component.scss']
})
export class RwaInputComponent implements OnInit {
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() autoFocus;
  @Input() model: string; 
@Input() translateData: any;
  @Input() rwa1: string;
  @Input() rwa2: string;
  @Input() rwa3: string;
  @Input() rwa4: string;
  @Input() rwaTitle: string;
  @Input() rwaDescription: string;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Output() rwa1Change: EventEmitter<Object> = new EventEmitter();
  @Output() rwa2Change: EventEmitter<Object> = new EventEmitter();
  @Output() rwa3Change: EventEmitter<Object> = new EventEmitter();
  @Output() rwa4Change: EventEmitter<Object> = new EventEmitter();
  @Output() rwaTitleChange: EventEmitter<Object> = new EventEmitter();
  @Output() rwaDescriptionChange: EventEmitter<Object> = new EventEmitter();
  @Output() onError: EventEmitter<Object> = new EventEmitter();

  error = false;
  errorMessage = '';
  constructor() { }

  ngOnInit() {
  }

  valueChanged() {
    this.model = this.model || '';
    const temp = this.model.split('') || [];
    this.rwa1 = temp[0] || '';
    this.rwa2 = temp[1] || '';
    this.rwa3 = temp[2] || '';
    this.rwa4 = temp[3] || '';
    this.modelChange.next(this.model);
    this.rwa1Change.next(this.rwa1);
    this.rwa2Change.next(this.rwa2);
    this.rwa3Change.next(this.rwa3);
    this.rwa4Change.next(this.rwa4);
    this.onFieldValueUpdated.emit({
        label: this.field.label,
        value: this.model,
        ruleIndex: this.field.ruleIndex,
        field: this.field
      });
  }

  pasteIt(eve) {
    eve.preventDefault();
    this.model = this.model || '';
    this.model += (eve && eve.clipboardData && eve.clipboardData.getData('Text')) || '';
    this.valueChanged();
  }

  titleChanged() {
    this.rwaTitleChange.next(this.rwaTitle);
  }
  
  descriptionChanged() {
    this.rwaDescriptionChange.next(this.rwaDescription);
  }
}
