import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { GlobalModule } from '@fuse/global.module';
import { StepActionsComponent } from './step-actions.component';
import { AddTaskModule } from 'app/main/content/apps/processes-settings/process-view/add-task/add-task.module';

@NgModule({
    imports     : [
       FuseSharedModule,
       AddTaskModule
        // GlobalModule,
    ],
    declarations: [
        StepActionsComponent
    ],
    providers   : [
    ],
    exports: [
        StepActionsComponent
    ],
    entryComponents: [
        StepActionsComponent
    ]
})
export class StepActionModule
{
}
