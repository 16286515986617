/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./invitation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/flex-layout/flex";
import * as i8 from "@angular/flex-layout/core";
import * as i9 from "./migrate/migrate.component.ngfactory";
import * as i10 from "./migrate/migrate.component";
import * as i11 from "@angular/forms";
import * as i12 from "@ngx-translate/core";
import * as i13 from "../../../../../@fuse/services/translation-loader.service";
import * as i14 from "../../services/helpers/helpers.service";
import * as i15 from "@angular/common/http";
import * as i16 from "./set-password/set-password.component.ngfactory";
import * as i17 from "./set-password/set-password.component";
import * as i18 from "../authentication/auth.service";
import * as i19 from "../../services/set-default-language/set-default-language.service";
import * as i20 from "../../../../../@fuse/services/config.service";
import * as i21 from "../../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i22 from "@angular/material/card";
import * as i23 from "./invitation.component";
import * as i24 from "@angular/router";
var styles_InvitationComponent = [i0.styles];
var RenderType_InvitationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InvitationComponent, data: {} });
export { RenderType_InvitationComponent as RenderType_InvitationComponent };
function View_InvitationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(2, 49152, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_InvitationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "p", [["style", "color:#737373; font-family:'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:16px; font-weight:400; line-height:24px; padding:0; margin:0; text-align:left;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" This invitation will expire after 7 days"])), (_l()(), i1.ɵted(-1, null, [". "]))], null, null); }
function View_InvitationComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Confirm Reject "]))], null, null); }
function View_InvitationComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(1, 49152, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_InvitationComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["style", "cursor: pointer; background-color:#6DC6DD; border-collapse:separate; border-top:20px solid #6DC6DD; border-right:40px solid #6DC6DD; border-bottom:20px solid #6DC6DD; border-left:40px solid #6DC6DD; border-radius:3px; color:#FFFFFF; display:inline-block; font-family:'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:16px; font-weight:600; letter-spacing:.3px; text-decoration:none;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.triggerAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvitationComponent_7)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvitationComponent_8)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loading; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.loading; _ck(_v, 4, 0, currVal_1); }, null); }
function View_InvitationComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i7.DefaultLayoutAlignDirective, [i1.ElementRef, i8.StyleUtils, [2, i7.LayoutAlignStyleBuilder], i8.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvitationComponent_6)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "center center"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.buttonText; _ck(_v, 3, 0, currVal_1); }, null); }
function View_InvitationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["style", "color:#737373; font-family:'Helvetica Neue', Helvetica, Arial, sans-serif; font-size:30px; font-style:normal; font-weight:600; line-height:42px; letter-spacing:normal; margin:0; padding:0; text-align:left;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" You've been invited to join a Pinbo24 account. Are you sure you want to reject? "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvitationComponent_4)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvitationComponent_5)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.invitationExpired; _ck(_v, 5, 0, currVal_0); var currVal_1 = ((_co.buttonText && _co.userInvitationModel) && (_co.userInvitationModel.status === "pending")); _ck(_v, 8, 0, currVal_1); }, null); }
function View_InvitationComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-migrate", [], null, null, null, i9.View_MigrateComponent_0, i9.RenderType_MigrateComponent)), i1.ɵdid(2, 114688, null, 0, i10.MigrateComponent, [i11.FormBuilder, i12.TranslateService, i13.FuseTranslationLoaderService, i14.HelpersService, i15.HttpClient], { userInvitationId: [0, "userInvitationId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.invitationId; _ck(_v, 2, 0, currVal_0); }, null); }
function View_InvitationComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-set-password", [], null, null, null, i16.View_SetPasswordComponent_0, i16.RenderType_SetPasswordComponent)), i1.ɵdid(2, 114688, null, 0, i17.SetPasswordComponent, [i15.HttpClient, i14.HelpersService, i13.FuseTranslationLoaderService, i18.AuthService, i19.SetDefaultLanguageService, i12.TranslateService, i20.FuseConfigService], { userInvitationId: [0, "userInvitationId"], userInvitationModel: [1, "userInvitationModel"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.invitationId; var currVal_1 = _co.userInvitationModel; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_InvitationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["fxLayout", "column"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i7.DefaultLayoutDirective, [i1.ElementRef, i8.StyleUtils, [2, i7.LayoutStyleBuilder], i8.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 8, "mat-card", [["class", "mat-card"], ["style", " position: fixed; top: 10vh; left: 30%; z-index: 1111; min-width: 400px; width: 50%; padding: 60px 40px; padding-top: 16px; padding-bottom: 16px;"]], null, null, null, i21.View_MatCard_0, i21.RenderType_MatCard)), i1.ɵdid(3, 16384, null, 0, i5.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), i1.ɵdid(4, 49152, null, 0, i22.MatCard, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_InvitationComponent_3)), i1.ɵdid(6, 278528, null, 0, i5.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i5.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_InvitationComponent_9)), i1.ɵdid(8, 278528, null, 0, i5.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i5.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_InvitationComponent_10)), i1.ɵdid(10, 16384, null, 0, i5.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i5.NgSwitch], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.status; _ck(_v, 3, 0, currVal_1); var currVal_2 = "reject"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "migrate"; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_InvitationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["style", "height: 20vh; width:100%; background: #52BAD5;position: absolute; top: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["fx", ""], ["style", "background: #f5f5f5;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvitationComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvitationComponent_2)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.loading; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_InvitationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-invitation", [], null, null, null, View_InvitationComponent_0, RenderType_InvitationComponent)), i1.ɵdid(1, 114688, null, 0, i23.InvitationComponent, [i24.ActivatedRoute, i15.HttpClient, i20.FuseConfigService, i14.HelpersService, i13.FuseTranslationLoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InvitationComponentNgFactory = i1.ɵccf("app-invitation", i23.InvitationComponent, View_InvitationComponent_Host_0, {}, {}, []);
export { InvitationComponentNgFactory as InvitationComponentNgFactory };
