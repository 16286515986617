<div *ngIf="data" class="dialog-content-wrapper" style="background: #fafafa;">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title"> {{ 'ct.add_event' | translate }}</span>
      <span fxFlex></span>
      <div>
        <button mat-button class="mat-icon-button" (click)="dialogRef.close()" aria-label="Close dialog">
          <mat-icon>close</mat-icon>
        </button>
        <button mat-button class="mat-icon-button" (click)="save()">
          <mat-icon>check</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>

  <div class="happ-edit-form-container m-0" fusePerfectScrollbar style="background: white;padding: 16px;">
    
    <div fxLayout="column">
      <div>
        <h2>
          {{ 'ct.add_event' | translate }}
        </h2>
        <span fxFlex></span>
        <div *ngIf="data && data.edit">
          <button mat-raised-button (click)="delete()" >
            {{ 'ct.delelte' | translate }}
          </button>
        </div>
      </div>

      <h3>
        {{ 'ct.general' | translate }}
      </h3>
      
        <mat-form-field class="happ-small-form-field-outline" floatLabel="always" appearance="outline">
          <mat-label>
            {{ 'ct.event_name' | translate }}
          </mat-label>
          <input  matInput [(ngModel)]="formData.name">
        </mat-form-field>

        <mat-form-field class="happ-small-form-field-outline" floatLabel="always" appearance="outline">
          <mat-label>
            {{ 'ct.event_price' | translate }}
          </mat-label>
          <input  matInput [(ngModel)]="formData.price">
        </mat-form-field>

        <h3>
          {{ 'ct.other' | translate }}
        </h3>

        <mat-form-field class="happ-small-form-field-outline" floatLabel="always" appearance="outline">
          <mat-label>
            {{ 'ct.event_sort' | translate }}
          </mat-label>
          <input  matInput [(ngModel)]="formData.sort">
        </mat-form-field>

        <mat-form-field class="happ-small-form-field-outline" floatLabel="always" appearance="outline">
          <mat-label>
            {{ 'ct.event_tax' | translate }}
          </mat-label>
          <input  matInput [(ngModel)]="formData.tax">
        </mat-form-field>
      
      
        <mat-form-field class="happ-small-form-field-outline" floatLabel="always" appearance="outline">
          <mat-label>
            {{ 'ct.event_max_qty' | translate }}
          </mat-label>
          <input  matInput [(ngModel)]="formData.maxQty">
        </mat-form-field>

        <mat-form-field class="happ-small-form-field-outline" floatLabel="always" appearance="outline">
          <mat-label>
            {{ 'ct.event_type' | translate }}
          </mat-label>
          <input  matInput [(ngModel)]="formData.type">
        </mat-form-field>
      
    </div>
  </div>
</div>