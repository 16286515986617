<div style="position: relative;" fxLayout="column" *ngIf="!field.hide || builder">
  <div fxLayout="row">
      <mat-form-field color="accent" [ngClass]="{'w-100-p': true, 'happ-small-form-field-outline': field.smallField, 'm-0': errorMessage}" floatLabel="always" appearance="outline" fxFlex>
          <mat-label> {{ field.title | translate }} </mat-label>
          <input [type]="field.inputType" [attr.aria-label]="field.label" autofocus="true" matInput
              [minlength]="field.min_length" [maxlength]="field.max_length"
              [disabled]="field.showdisablecontroltype=='true' || field.disable" (paste)="pasteIt($event)"
              (keyup)="onKeyPress()" (blur)="checkValidation()" [hidden]="field.hidden" [(ngModel)]="model">
            <mat-icon class="mr-8" style="color: green;" matSuffix *ngIf="foundRecords && foundRecords.length === 0">done</mat-icon>
            <mat-spinner class="mr-8" *ngIf="loading" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
            <mat-icon class="mr-8" style="color: red;" matSuffix *ngIf="errorMessage">warning</mat-icon>
      </mat-form-field>
      <!-- <mat-icon style="    font-size: 40px;
      height: 40px;
      width: 40px;
      margin-top: 0px;
      margin-left: 10px;
      margin-right: 10px; color: green;" *ngIf="foundRecords && foundRecords.length === 0" >check_circle</mat-icon> -->
  </div>
  <div class="happ-field-description" *ngIf="field.allow_description && field.description"> {{ field.description }} </div>
  <div *ngIf="errorMessage" class="m-0 b-12 mb-12" fxLayout="row" style="color: #cc3333;">
    <div style="font-size: 12px;" fxLayout="column">
      <p class="m-0 ml-8 mb-4" *ngIf="errorMessage"> {{ (field.defaultErrorString || errorMessage) | translate }} 
        ({{ foundRecords.length }})
        </p>
        <ul [class]="'mt-0 mb-0 ' + field.color + '-fg'" *ngIf="!showMore">
            <li *ngFor="let item of limitedResult"> 
                <span *ngFor="let temp of field.fieldsToShow"> 
                    <strong *ngIf="temp === 'companyName'">
                        {{ item[temp] || '-' }} |
                    </strong>
                    <span *ngIf="temp !== 'companyName'">
                        {{ item[temp] || '-' }} |
                    </span>
                </span>
            </li>
        </ul>
        <ul [class]="'mt-0 mb-0 ' + field.color + '-fg'" *ngIf="showMore">
            <li *ngFor="let item of foundRecords"> 
                <span *ngFor="let temp of field.fieldsToShow"> 
                    <strong *ngIf="temp === 'companyName'">
                        {{ item[temp] || '-' }} |
                    </strong>
                    <span *ngIf="temp !== 'companyName'">
                        {{ item[temp] || '-' }} |
                    </span>    
                </span>
            </li>
        </ul>
        <a *ngIf="foundRecords && foundRecords.length > 5" (click)="showMore=!showMore" class="m-0 mb-8 ml-40">
            <span style="cursor: pointer;" >
                {{ ((!showMore) ? 'ct.show_more' : 'ct.less') | translate }}
                </span>
        </a>
    </div>
  </div>
</div>