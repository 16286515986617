import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DtTableReportPopupComponent } from './dt-table-report-popup.component';
import { SettingItemViewModule } from '../setting-item-view/setting-item-view.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DtTableReportPopupComponent],
  imports: [
    CommonModule,
    SettingItemViewModule,
    FuseSharedModule,
    TranslateModule
  ],
  entryComponents: [
    DtTableReportPopupComponent
  ]
})
export class DtTableReportPopupModule { }
