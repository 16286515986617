import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';

// import { GlobalModule } from '@fuse/global.module';
import { GlobalModule } from '@fuse/global.module';
import { PlatformIssueReporterComponent } from './platform-issue-reporter.component';
import { CKEditorModule } from 'ngx-ckeditor';

@NgModule({
    imports     : [
       FuseSharedModule,
        GlobalModule,
        CKEditorModule
    ],
    declarations: [
        PlatformIssueReporterComponent
    ],
    providers   : [
    ],
    exports: [
        PlatformIssueReporterComponent
    ],
    entryComponents: [
        PlatformIssueReporterComponent
    ]
})
export class PlatformIssueReporterModule
{
}
