<div *ngIf="edit" class="ml-4 min-height-65" fxLayout="column wrap">
  <div fxLayout="row wrap">
    <mat-form-field color="accent" [ngClass]="{'w-100-p': true, 'happ-small-form-field-outline': field.smallField}" floatLabel="always" appearance="outline" fxFlex>
        <mat-label>Case Id</mat-label>
        <input matInput  (keydown.Tab)="filterRecords(filterText)" [(ngModel)]="filterText" >
    </mat-form-field>
    
    <button (click)="filterRecords(filterText)" mat-icon-button>
      <mat-icon style="color: black;">search</mat-icon>
    </button>
  </div>
  
  <div class="mb-12" *ngIf="records && records.length" >
    <mat-nav-list style="max-height: 300px; overflow: auto">
      <a (click)="selected=record; onChange(record); edit=false;" *ngFor="let record of records" mat-list-item>
        {{ record.signatura }}
      </a>
    </mat-nav-list>
  </div>
</div>

<div *ngIf="!edit" class="min-height-65" fxLayout="row wrap" fxLayoutAlign="space-between center">
  <p class="font-size-20 m-10 ml-4" matTooltip="Click to edit" (click)="edit=!edit" >
      {{ field.title | translate }} {{ selected.signatura }}
  </p>
  <button (click)="edit=!edit" mat-icon-button>
    <mat-icon style="color: black;" >edit</mat-icon>
  </button>
</div>