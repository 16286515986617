/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mailing-list-loading-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i5 from "@angular/material/progress-spinner";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/common";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./mailing-list-loading-popup.component";
import * as i10 from "@angular/material/dialog";
import * as i11 from "../../../../services/helpers/helpers.service";
var styles_MailingListLoadingPopupComponent = [i0.styles];
var RenderType_MailingListLoadingPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MailingListLoadingPopupComponent, data: {} });
export { RenderType_MailingListLoadingPopupComponent as RenderType_MailingListLoadingPopupComponent };
function View_MailingListLoadingPopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["fxLayout", "row"], ["fxLayoutAlign", "center center"], ["style", "width: 100%; height: 100%;"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i4.View_MatSpinner_0, i4.RenderType_MatSpinner)), i1.ɵdid(4, 49152, null, 0, i5.MatSpinner, [i1.ElementRef, i6.Platform, [2, i7.DOCUMENT], [2, i8.ANIMATION_MODULE_TYPE], i5.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 4)._noopAnimations; var currVal_3 = i1.ɵnov(_v, 4).diameter; var currVal_4 = i1.ɵnov(_v, 4).diameter; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4); }); }
export function View_MailingListLoadingPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MailingListLoadingPopupComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MailingListLoadingPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mailing-list-loading-popup", [], null, null, null, View_MailingListLoadingPopupComponent_0, RenderType_MailingListLoadingPopupComponent)), i1.ɵdid(1, 114688, null, 0, i9.MailingListLoadingPopupComponent, [i10.MatDialogRef, i10.MAT_DIALOG_DATA, i11.HelpersService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MailingListLoadingPopupComponentNgFactory = i1.ɵccf("app-mailing-list-loading-popup", i9.MailingListLoadingPopupComponent, View_MailingListLoadingPopupComponent_Host_0, {}, {}, []);
export { MailingListLoadingPopupComponentNgFactory as MailingListLoadingPopupComponentNgFactory };
