<mat-toolbar matDialogTitle fxLayoutAlign="center center" class="call-header-one mat-toolbar mat-accent m-0"
  fxLayout="row">
  <div fxFlex fxLayout="header" fxLayoutAlign="space-between center">
    <span class="title dialog-title">
      <p class="m-0 font-size-20 ml-12">
        {{ 'popup.mailing_list_action' | translate }} - {{ formData.name || '' }}
      </p>
    </span>
    <span fxFlex></span>
    <button class="mr-12" (click)="save()" mat-icon-button id="mailing_list_save">
      <mat-icon>check</mat-icon>
    </button>
    <button class="mr-12" (click)="close()" mat-icon-button id="mailing_list_cancel">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</mat-toolbar>

<div fxLayout="column px-12 mb-24" style="    width: 100%;">
  <ng-container *ngIf="!formData.action">
    <div fxLayout="column" class="p-12" fxLayoutAlign="center space-between" id="mailing_list_type">
      <!-- <div class="m-12 happ-hover-div " (click)="formData.action = 'sendMail'" >
        <h2>
          {{ 'popup.send_email' | translate }}
        </h2>
      </div>
      <div class="m-12 happ-hover-div" (click)="formData.action = 'sendMailWithRecord'">
        <h2>
          {{ 'popup.send_email_after_create_record' | translate }}
        </h2>
      </div>
      <div class="m-12 happ-hover-div" (click)="formData.action = 'sendMailWithRecordWithSharedService'">
        <h2>
          {{ 'popup.send_email_after_create_record_with_shared_service' | translate }}
        </h2>
      </div> -->
      <div class="happ-common-list-container">
        <div fxFlex="70" fxLayout="column">
          <h4 style="margin: 0px; margin-left: 2px"> <strong>{{ 'popup.send_email_after_create_record' | translate
              }}</strong> </h4>
          <p class="m-0"> {{ 'popup.send_email_after_create_record_desc' | translate }} </p>
        </div>
        <div fxFlex="30" style="align-items: center; justify-content: end;" fxLayout="row">
          <button mat-raised-button color="danger" (click)="formData.action = 'sendMailWithRecord'">
            {{ 'popup.send_email_after_create_record' | translate }}
          </button>
        </div>
      </div>
      <div class="happ-common-list-container">
        <div fxFlex="70" fxLayout="column">
          <h4 style="margin: 0px; margin-left: 2px"> <strong>{{
              'popup.send_email_after_create_record_with_shared_service' | translate }}</strong> </h4>
          <p class="m-0"> {{ 'popup.send_email_after_create_record_with_shared_service_desc' | translate }} </p>
        </div>
        <div fxFlex="30" style="align-items: center; justify-content: end;" fxLayout="row">
          <button mat-raised-button color="danger" (click)="formData.action = 'sendMailWithRecordWithSharedService'">
            {{ 'popup.send_email_after_create_record_with_shared_service' | translate }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container
    *ngIf="formData.action === 'sendMail' || formData.action === 'sendMailWithRecord' || formData.action === 'sendMailWithRecordWithSharedService'">
    <!-- <div fxLayout="row">
      <div>
        <button mat-icon-button (click)="formData.action = null" >
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
      <p>
        {{ 'popup.mailing_list_action_desc' | translate }}
      </p>
    </div> -->

    <div class="mx-20">
      <mat-tab-group>
        <mat-tab label="Summary">
          <div>
            <div class="happ-common-list-container">
              <div fxLayout="column" fxFlex="70">
                <h1 class="m-0">
                  <strong>{{ (formData.name || 'popup.name_not_defined') | translate }} </strong>
                </h1>
                <p class="m-0">
                  {{ ('popup.'+formData.action+'_execute_desc') | translate }}
                </p>
              </div>
              <div fxLayout="row" fxFlex="30">
                <span fxFlex></span>
                <div>
                  <button color="danger" class="m-8" mat-raised-button (click)="execute(this.data.formData)">
                    Execute
                  </button>
                </div>
              </div>
            </div>

            <div class="happ-table-" *ngIf="logs && logs.length">
              <h2>
                Logs
              </h2>
              <table>
                <tr>
                  <td> <strong>Date</strong></td>
                  <td> <strong>Messages</strong></td>
                  <td> <strong>To Send</strong></td>
                  <td> <strong>Sended</strong></td>
                  <td> <strong>Status</strong></td>
                  <td> <strong>Action Name</strong></td>
                  <td> <strong>User</strong></td>
                </tr>
                <tr *ngFor="let item of logs">
                  <td>{{ item.createdAt | date : "yyyy-MM-dd hh:mm" }}</td>
                  <td>{{ item.message }}</td>
                  <td>{{ item.toSend }}</td>
                  <td>{{ item.totalSent }}</td>
                  <td>{{ item.status }}</td>
                  <td>{{ item.actionName }}</td>
                  <td>{{ item.userEmail }}</td>
                </tr>
              </table>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Preview">

          <h2>
            Below you can see the preview of website what will be sended in the email link.
          </h2>

          <div style="width: 100%; background: white;">

            <div fxLayout="column" fxLayoutAlign="center start" class="w-100-p" *ngIf="!loading && !success">
              <div fxLayout="row" class="w-100-p" style="border: 1px solid lightgray;">
                <div class="w-100-p px-12">
                  <h2>
                    Pinbox24.com
                  </h2>
                  <span fxFlex></span>
                  <h2>
                    Pinbox Information System - Shared service
                  </h2>
                </div>
              </div>
              <div style="width: 80%;margin: auto;">
                <h1>
                  Hey, {{ data.email}}
                </h1>
                <div style="border: 1px solid lightgray; padding: 12px;">
                  <div [innerHTML]="formData.websiteHtml | htmlWithStyle"></div>
                </div>
                <br>
                <p>
                  According to GDPR regulation we have to ask you for permission to send to you information in that
                  topic do you agree to receive this info?
                </p>
                <br>
                <div fxLayout="row"
                  *ngIf="data.action === 'sendMailWithRecord' || data.action === 'sendMailWithRecordWithSharedService'">
                  <div class="w-100-p">
                    <button [disabled]="true" mat-raised-button color="warn" class="w-20-p"
                      (click)="reject()">Reject</button>
                    <button [disabled]="true" mat-raised-button style="background: green; color: white;" class="w-80-p"
                      (click)="accept()">Accept</button>
                  </div>
                </div>

                <div>
                  <h1>Sender Identification</h1>
                  <div fxLayout="column">
                    <div fxLayout="row">
                      <p fxFlex="50">
                        Company
                      </p>
                      <p fxFlex="50" *ngIf="data && data.officeInfo && data.officeInfo.name">
                        {{ data.officeInfo.name }}
                      </p>
                    </div>
                    <div fxLayout="row">
                      <p>
                        Address
                      </p>
                    </div>
                    <div fxLayout="row">
                      <p>
                        City
                      </p>
                    </div>
                    <div fxLayout="row">
                      <p>
                        Country
                      </p>
                    </div>
                  </div>
                </div>

                <div style="border: 1px solid lightgray; padding: 12px; text-align: center;">
                  <p>
                    If you don't like to receive any information from us -
                  </p>
                  <a (click)="unSubscribeAll()" style="color: blue;">
                    Click here.
                  </a>
                </div>
              </div>
            </div>

            <div style="width: 100%; height: 400px;" fxLayoutAlign="center center" *ngIf="!loading && success">
              <h1>
                Thank you for showing you interest.
                <span *ngIf="data.action === 'sendMailWithRecordWithSharedService'">
                  Redirecting to service page.
                </span>
              </h1>
            </div>

            <div style="width: 100%; height: 400px;" fxLayoutAlign="center center" *ngIf="loading && !success">
              <mat-spinner></mat-spinner>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Settings">

          <div class="happ-common-list-section">
            <h2>
              {{ 'popup.general' | translate }}
            </h2>
          </div>

          <div class="happ-common-list-container">
            <div fxFlex="30" fxLayout="column">
              <h4 style="margin: 0px; margin-left: 2px"> <strong>{{ 'ct.action_name' | translate }}</strong> </h4>
              <p class="m-0"> {{ 'ct.action_name_desc' | translate }} </p>
            </div>
            <div fxFlex="70" style="align-items: center;" fxLayout="row">
              <mat-form-field fxFlex class="mx-8 happ-small-form-field-outline">
                <input matInput [(ngModel)]="formData.name">
              </mat-form-field>
            </div>
          </div>


          <div class="happ-common-list-container">
            <div fxFlex="30" fxLayout="column">
              <h4 style="margin: 0px; margin-left: 2px"> <strong>{{ 'ct.action_type' | translate }}</strong> </h4>
              <p class="m-0"> {{ 'ct.action_type_desc' | translate }} </p>
            </div>
            <div fxFlex="70" style="align-items: center;" fxLayout="row">
              <mat-form-field fxFlex class="mx-8 happ-small-form-field-outline">
                <input [disabled]="true" matInput [(ngModel)]="formData.action">
              </mat-form-field>
            </div>
          </div>

          <div
            *ngIf="formData.action === 'sendMailWithRecord' || formData.action === 'sendMailWithRecordWithSharedService'"
            style="border: 1px solid rgba(200,200,200,0.5); min-height: 75px;" class="mb-8 p-8">
            <div fxFlex="30" fxLayout="column">
              <h4 style="margin: 0px; margin-left: 2px"> <strong>{{ 'ct.select_register' | translate }}</strong> </h4>
              <p class="m-0"> {{ 'ct.select_register_desc' | translate }} </p>
            </div>
            <div fxFlex="70" style="align-items: center;" fxLayout="row">
              <mat-form-field fxFlex class="mx-8 happ-small-form-field-outline">
                <mat-select [(ngModel)]="formData.regId">
                  <mat-option *ngFor="let list of regList" [value]="list._id">
                    {{ list.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div *ngIf="formData.action === 'sendMailWithRecordWithSharedService'"
            style="border: 1px solid rgba(200,200,200,0.5); min-height: 75px;" class="mb-8 p-8">
            <div fxFlex="30" fxLayout="column">
              <h4 style="margin: 0px; margin-left: 2px"> {{ 'ct.select_shared_service' | translate }} </h4>
            </div>
            <div fxFlex="70" style="align-items: center;" fxLayout="row">
              <mat-form-field fxFlex class="mx-8 happ-small-form-field-outline">
                <mat-select [(ngModel)]="formData.sharedServiceId">
                  <mat-option *ngFor="let list of sharedServiceData" [value]="list._id">
                    {{ list.serviceName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div style="background: rgb(246, 246, 246); height: 64px;" fxLayoutAlign="start center" class="pl-12">
            <h2>
              {{ 'popup.email_config' | translate }}
            </h2>
          </div>

          <div style="border: 1px solid rgba(200,200,200,0.5); min-height: 75px;" class="mb-8 p-8">
            <div fxFlex="30" fxLayout="column">
              <h4 style="margin: 0px; margin-left: 2px"> <strong>{{ 'ct.subject' | translate }}</strong> </h4>
              <p class="m-0"> {{ 'ct.subject_desc' | translate }} </p>
            </div>
            <div fxFlex="70" style="align-items: center;" fxLayout="row">
              <mat-form-field fxFlex class="mx-8 happ-small-form-field-outline">
                <input matInput [(ngModel)]="formData.subject">
              </mat-form-field>
            </div>
          </div>

          <div style="border: 1px solid rgba(200,200,200,0.5); min-height: 75px;" class="mb-8 p-8">
            <div fxFlex="30" fxLayout="column" fxLayoutAlign="start start">
              <h4 style="margin: 0px; margin-left: 2px"> <strong>{{ 'ct.smtp_server' | translate }}</strong> </h4>
              <p class="m-0"> {{ 'ct.smtp_server_desc' | translate }} </p>
            </div>
            <div fxFlex="70" style="align-items: center;" fxLayout="row">
              <mat-form-field *ngIf="smtpServerList" fxFlex class="mx-8 happ-small-form-field-outline">
                <mat-select (selectionChange)="onServerChange($event)" [(ngModel)]="formData.smtpServer">
                  <mat-option *ngFor="let list of smtpServerList" [value]="list._id">
                    {{ list.username }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button mat-icon-button color="primary" (click)="openSMTPSettings()">
                <mat-icon>settings</mat-icon>
              </button>
            </div>
          </div>

          <div style="border: 1px solid rgba(200,200,200,0.5); min-height: 75px;" class="mb-8 p-8">
            <div fxFlex="30" fxLayout="column" fxLayoutAlign="start start">
              <h4 style="margin: 0px; margin-left: 2px"> <strong>{{ 'ct.from' | translate }}</strong> </h4>
              <p class="m-0"> {{ 'ct.from_desc' | translate }} </p>
            </div>
            <div fxFlex="70" style="align-items: center;" fxLayout="row">
              <mat-form-field *ngIf="smtpEmailList" fxFlex class="mx-8 happ-small-form-field-outline">
                <mat-select [(ngModel)]="formData.from">
                  <mat-option *ngFor="let list of smtpEmailList" [value]="list.username">
                    {{ list.username }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button mat-icon-button color="primary" (click)="openSMTPFormSettings()">
                <mat-icon>settings</mat-icon>
              </button>
            </div>
          </div>

          <div style="border: 1px solid rgba(200,200,200,0.5); min-height: 75px;" class="mb-8 p-8">
            <div fxFlex="30" fxLayout="column" fxLayoutAlign="start start">
              <h4 style="margin: 0px; margin-left: 2px"> <strong>{{ 'ct.body_html' | translate }}</strong> </h4>
              <p class="m-0"> {{ 'ct.body_html_desc' | translate }} </p>
            </div>
            <div fxFlex="70" style="align-items: center;" fxLayout="row">
              <ck-editor name="editor1" [(ngModel)]="formData.bodyHtml" skin="moono-lisa" language="en"
                [fullPage]="true"></ck-editor>
            </div>
          </div>


          <div style="border: 1px solid rgba(200,200,200,0.5); min-height: 75px;" class="mb-8 p-8">
            <div fxFlex="30" fxLayout="column">
              <h4 style="margin: 0px; margin-left: 2px"> <strong>{{ 'ct.body' | translate }}</strong> </h4>
              <p class="m-0"> {{ 'ct.body_desc' | translate }} </p>
            </div>
            <div fxFlex="70" style="align-items: center;" fxLayout="row">
              <mat-form-field fxFlex class="mx-8 happ-small-form-field-outline">
                <textarea matInput rows="6" [(ngModel)]="formData.body">{{ formData.body }}</textarea>
              </mat-form-field>
            </div>
          </div>

          <div style="border: 1px solid rgba(200,200,200,0.5); min-height: 75px;" class="mb-8 p-8">
            <div fxFlex="30" fxLayout="column">
              <h4 style="margin: 0px; margin-left: 2px"> <strong>{{ 'ct.upload_file' | translate }}</strong> </h4>
              <p class="m-0"> {{ 'ct.upload_file_desc' | translate }} </p>
            </div>
            <div fxFlex="70" style="align-items: center;" fxLayout="row">
              <div fxFlex fxLayout="column">
                <div class="file-upload">
                  <label for="upload" class="file-upload__label">Upload file</label>
                  <input multiple="true" id="upload" (change)="uploadFile(file, 'uploadFile')"
                    class="file-upload__input" name="uplFile" #file type="file">
                </div>
                <div *ngIf="loading">
                  <mat-spinner></mat-spinner>
                </div>
                <ng-container *ngIf="!loading">
                  <div fxLayout="row" *ngFor="let fileItem of files; let i=index"
                    style="    border: 1px solid rgba(200, 200, 200, 0.5); padding: 0px 10px; margin-top: 10px;">
                    <p>
                      {{ fileItem.filename }}
                    </p>
                    <span fxFlex></span>
                    <button class="mr-12" (click)="deleteItem(i)" mat-icon-button>
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>


          <div style="border: 1px solid rgba(200,200,200,0.5); min-height: 75px;" class="mb-8 p-8">
            <div fxFlex="30" fxLayout="column" fxLayoutAlign="start start">
              <h4 style="margin: 0px; margin-left: 2px"> <strong>{{ 'ct.footer' | translate }}</strong> </h4>
              <p class="m-0"> {{ 'ct.footer_desc' | translate }} </p>
            </div>
            <div fxFlex="70" style="align-items: center;" fxLayout="row">
              <mat-form-field *ngIf="footerList" fxFlex class="mx-8 happ-small-form-field-outline">
                <mat-select [(ngModel)]="formData.footer">
                  <mat-option *ngFor="let list of footerList" [value]="list._id">
                    {{ list.title }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button mat-icon-button color="primary" (click)="openFooterSettings()">
                <mat-icon>settings</mat-icon>
              </button>
            </div>
          </div>

          <div style="background: rgb(246, 246, 246); height: 64px;" fxLayoutAlign="start center" class="pl-12">
            <h2>
              {{ 'popup.website_settings' | translate }}
            </h2>
          </div>

          <div style="border: 1px solid rgba(200,200,200,0.5); min-height: 75px;" class="mb-8 p-8">
            <div fxFlex="30" fxLayout="column">
              <h4 style="margin: 0px; margin-left: 2px"> <strong>{{ 'ct.website_html' | translate }}</strong> </h4>
              <p class="m-0"> {{ 'ct.website_html_desc' | translate }} </p>
            </div>
            <div fxFlex="70" style="align-items: center;" fxLayout="row">
              <ck-editor name="editor1" [(ngModel)]="formData.websiteHtml" skin="moono-lisa" language="en"
                [fullPage]="true"></ck-editor>
            </div>
          </div>

        </mat-tab>

      </mat-tab-group>
    </div>



  </ng-container>
</div>