import { IOfficeData } from 'app/main/content/interfaces/office.interface';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { MatDialog } from '@angular/material';
import { AddRecordComponent } from './add-record/add-record.component';
import { BarButtonOptions } from 'app/main/content/consts/progressButtonOptions.const';
import * as _ from 'lodash';

@Component({
  selector: 'related-records-list',
  templateUrl: './related-records-list.component.html',
  styleUrls: ['./related-records-list.component.scss']
})
export class RelatedRecordListComponent implements OnInit, OnChanges {

  @Input() contactId: string;
  @Input() recData: string;
  @Input() regId: string;
  @Input() field: any;
  @Input() translateData: any;
  query;
  list;
  loading = false;
  btnOpts;
  isAddressBookReg = false;

  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private helperService: HelpersService,
    public dialog: MatDialog,
    private _officeHelper: OfficeHelpersService
  ) { }

  
  ngOnChanges(changes: any) {

    if (changes && changes.translateData && changes.translateData.currentValue) {
      this.translateData = changes.translateData.currentValue;
      this.translationLoader.loadTranslationsV2(this.translateData);    
    }
  }

  ngOnInit() {
    this.translationLoader.loadTranslationsV2(this.translateData);
    this.btnOpts = BarButtonOptions;
    this.getList(this.contactId);
    this.isAddressBookRegister()
    .then(isAddressBookReg => {
      this.isAddressBookReg = isAddressBookReg;
    }).catch(err => {
      console.info(err);
    });
  }    

  getList(contactId: string) {
    this.list = [];
    this.loading = true;
    this.helperService.getAllRegisterRecord({contactId, mqFilter: this.field.mqFilter, regType: this.field.regType})
    .then((data) => {
      this.list = (data) || [];
      this.loading = false;
    }).catch(err => {
      console.info(err);
      this.loading = false;
      this.helperService.openSnackBar(err);
    });
  }

  
  someFunc2(): void {
    this.btnOpts.active = true;
    this.btnOpts.text = 'Saving Data...';
    setTimeout(() => {
      this.btnOpts.active = false;
      this.btnOpts.text = 'Progress Bar Button';
    }, 3500);
  }


  openAddRecord() {
    // check if only one register then create record without popup
    if (this.field['selectedRegister'] && this.field['selectedRegister'].length === 1 && !this.field.show_responsible_field && !this.field.show_group_field) {
      this.btnOpts.active = true;
      this.helperService.createRegisterRecord(this.field['selectedRegister'][0]['_id'], {contactId: this.contactId})
      .then((data) => {
        const linkData = {
          src_reg: this.regId,
          src_rec: this.contactId,
          dest_reg: data.regId,
          dest_rec: data._id,
          main: true
        };
        this.helperService.createNewLink(linkData)
        .then(() => {
          this.btnOpts.active = false;
          this.helperService.openSnackBar('Record Added');
          this.getList(this.contactId);
        }).catch(err => {
          this.btnOpts.active = false;
          console.info(err);
        });
      }).catch(err => {
        this.btnOpts.active = false;
        this.helperService.openSnackBar(err);
      });
    } else {

      const addressBookData = {};

      if (this.isAddressBookReg) {
        addressBookData['companyAdress'] = this.recData['companyAdress'] || '';
        addressBookData['companyCity'] = this.recData['companyCity'] || '';
        addressBookData['shortName'] = this.recData['shortName'] || '';
        addressBookData['longName'] = this.recData['longName'] || '';
        addressBookData['postalcode'] = this.recData['postalcode'] || '';
        addressBookData['companyName'] = this.recData['companyName'] || '';
      }

      const dialogRef = this.dialog.open(AddRecordComponent, {
        width: '850px',
        height: 'max-content',
        panelClass: 'happ-form-builder-dialog',
        data: { 
          contactId: this.contactId,
          addressBookData,
          regId: this.regId,
          registerList: (this.field && this.field['selectedRegister']) || [],
          field: this.field 
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.getList(this.contactId);
        }
        // this.workspaceService.onEditForm.next('');
        // this.workspaceService.refreshView.next(true);
      }); 
    }
  }

  async isAddressBookRegister() {
    try {
      const officeData: IOfficeData = await this._officeHelper.getLocalOfficeData();
      const officeApps = officeData.apps || {} as any;
      if (officeApps.office_addressbook && officeApps.office_addressbook.id === this.regId) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return err;
    }
  }
}
