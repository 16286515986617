import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterSelectionViewComponent } from './register-selection-view.component';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
  declarations: [RegisterSelectionViewComponent],
  imports: [
    CommonModule,
    FuseSharedModule
  ],
  // exports: [
  //   RegisterSelectionViewComponent
  // ],
  entryComponents: [
    RegisterSelectionViewComponent
  ]
})
export class RegisterSelectionViewModule { }
