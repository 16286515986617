<div  fxLayout="column" *ngIf="!field.hide || builder">
  <p *ngIf="!hideTitle" class="happ-field-title mr-12 mb-8 ml-4">{{ field.title | translate }} : </p>
  <ng-container *ngIf="!field.singleSelection && types">
    <table *ngIf="types.length">
      <tbody>
          <tr>
              <td>
                <strong>{{ 'ct.ss_type' | translate }}</strong>
              </td>
              <td style="text-align: center">
                <strong>{{ 'ct.selected' | translate }}</strong>
              </td>
          </tr>
          <tr *ngFor="let item of types">
              <td>{{ item.title | textTranslate:currentLang }}</td>
              <td style="text-align: center">
                  <mat-checkbox (change)="onChangeItem(item)" [checked]="model.includes(item.key)" ></mat-checkbox>
              </td>
          </tr>
      </tbody>
    </table>
    <div class="w-100-p h-400" *ngIf="!types.length">
      <h2>
        {{ 'ct.no_types_found' | translate }}
      </h2>
    </div>
  </ng-container>
  <ng-container *ngIf="field.singleSelection">
    <div fxLayout="row wrap">
      <button class="mr-4" *ngFor="let item of types" mat-raised-button [color]="(item.key===model) ? 'primary' : ''" (click)="changeSingle(item)">
        {{ item.title | textTranslate:currentLang }}
      </button>
    </div>
  </ng-container>
</div>