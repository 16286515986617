<div fxLayout="column" [class]="currentClass" fxFlex fusePerfectScrollbar>
    <mat-toolbar matDialogTitle fxLayoutAlign="center center" class="call-header-one mat-toolbar mat-accent m-0" fxLayout="row">
        <button *ngIf="currentClass === 'di-small-panel'" mat-icon-button (click)="toggleClass()">
            <mat-icon>first_page</mat-icon>
          </button>
        <p class="mx-12">
            {{ 'popup.all_calls' | translate }}
        </p>
        <span fxFlex></span>
        <button *ngIf="currentClass === 'di-full-panel'" mat-icon-button (click)="toggleClass()" >
            <mat-icon>last_page</mat-icon>
          </button>
    </mat-toolbar>
    <div fxLayout="row" fxLayoutAlign="space-around center" class="call-header-two">
        <div [matTooltip]="'popup.dnd_info' | translate" fxFlex="33" style="border-right: 1px solid gray;" (click)="dndEnable=!dndEnable" class="di-cursor-pointer">
            <p [class]="(dndEnable) ? 'di-item-selected ' : ''" >DND</p>
        </div>
        <div fxFlex="34" [matTooltip]="'popup.auto_report_info' | translate" (click)="autoPopup=!autoPopup" [class]="(autoPopup) ? 'di-item-selected  di-cursor-pointer' : ' di-cursor-pointer'" style="border-right: 1px solid gray;">
            <mat-icon >launch</mat-icon>
        </div>
        <div fxFlex="33" [matTooltip]="'popup.history_info' | translate" (click)="openLogs()" class="di-cursor-pointer">
            <mat-icon >list</mat-icon>
        </div>
    </div>
    <!-- <div class="call-header-three">
        <section>

            <mat-checkbox class="mr-8" [(ngModel)]="incomingEnable" >Incoming ({{ incomingLength }}) </mat-checkbox>
        </section>
    </div> -->
    <div>
        <ng-container *ngIf="dndEnable">
            <p class="m-16">
                {{ 'popup.dnd_enabled_message' | translate }}
            </p>
        </ng-container>
        <ng-container *ngIf="!dndEnable" >
            <ng-container *ngFor="let callItem of allStatus">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <h2 class="call-header-four"> {{ callItem.heading | translate }} ({{ callItem.length }}) </h2>
                    <div>
                        <button mat-icon-button (click)="callItem.isEnable=!callItem.isEnable" >
                            <mat-icon> {{ (callItem.isEnable) ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
                        </button>
                    </div>
                </div>
                <ng-container *ngIf="callItem.isEnable" >
                    <div fxLayout="row wrap">
                        <ng-container *ngFor="let item of callItem.currentCalls">
                            <div *ngIf="callItem.currentStates.includes(item.callState)">
                                <div style="margin: 16px 20px;" [class]="callItem.class" >
                                        <mat-card >
                                            <mat-card-content>
                                                <p class="m-0" style="font-size: 22px; font-weight: 700;"> {{ item.from }} </p>
                                                <p class="m-0"> {{ item.officeName }} </p>
                                                <p class="m-0" *ngIf="item.operator"> {{ item.operator.shortCode || item.operator.email || item.operator.sipNumber }} </p>
                                                <ng-container *ngIf="item.showMore">
                                                    <p class="m-0"> {{ item.to }} </p>
                                                    <p class="m-0"> {{ item.signature }} </p>
                                                    <p class="m-0"> {{ item.callState }} </p>
                                                    <p class="m-0"> {{ item.startTime }} </p>
                                                </ng-container>
                                            </mat-card-content>
                                            <mat-card-actions fxLayout="row">
                                                <span fxFlex></span>
                                                <button mat-raised-button (click)="item.showMore = !item.showMore" >{{ 'popup.show_more' | translate }}</button>
                                                <button mat-raised-button *ngIf="callItem.showOpenButton" (click)="openCallUi(item)">{{ 'popup.open' | translate }}</button>
                                                <!-- <button mat-button>SHARE</button> -->
                                            </mat-card-actions>
                                        </mat-card>
                                </div>
                            </div>

                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>

</div>