import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { ClientOrderPaymentComponent } from './client-order-payment.component';
import { RecordCommentModule } from 'app/main/content/global-component/control-template/record-comment/record-comment.module';
// import { ProviderServiceSelectionComponent } from './provider-service-selection.component';

@NgModule({
  declarations: [
    //   ProviderServiceSelectionComponent
  ClientOrderPaymentComponent,
],
  imports: [
    CommonModule,
    TranslateModule,
    FuseSharedModule,
    RecordCommentModule
  ],
  entryComponents: [
    
  ],
  exports: [
    ClientOrderPaymentComponent
    // ProviderServiceSelectionComponent
  ]
})
export class ClientOrderPaymentModule { }
