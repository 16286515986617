import { HelpersService } from './../../services/helpers/helpers.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { EditFormPopupComponent } from 'app/main/content/apps/custom-registries/edit-form-popup/edit-form-popup.component';
import { FuseCalendarEventFormDialogComponent } from 'app/main/content/apps/calendar-template/event-form/event-form.component';

@Injectable()
export class DatatableService {

    onOptionChange: Subject<object>;
    onDataChanged: Subject<object> = new Subject<object>();
    reRenderData: Subject<boolean> = new Subject<boolean>();
    onEditRecord: Subject<object> = new Subject<object>();
    onRecordUpdated: Subject<boolean> = new Subject<boolean>();
    onPositionRecordChange: Subject<boolean> = new Subject<boolean>();
    public params: any = {};
    public currentLang: string;

    positionExtraValues: any = {};
    isStepper = false;
    appData;
    statusId: number;
    allData;
    processId: string;
    integratedProcessId;
    form: any = {};
    selectedOffice: object;
    allFormData = {};
    allFormField: any[];
    setting: object;
    regId: string;
    regData;
    officeId;
    formId: string;
    instanceFilters = {};
    dataChange;
    applicationType;
    onFilterChanged: BehaviorSubject<any> = new BehaviorSubject({});
    onRegisterChanged: BehaviorSubject<any> = new BehaviorSubject({});
    constructor(
        private router: Router,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        private helperService: HelpersService
    ) {
        this.onOptionChange = new Subject<object>();
        this.instanceFilters = {};
    }

    editClickHandle(allData, record) {
        localStorage.setItem('backurl-params', this.router.url);
        this.moveTo(`oneRecordView/edit/${allData['regId']}/${allData['formId']}/${record._id}/ng5`);
    }

    AddClickHandle(allData) {
        this.moveTo(`oneRecordView/new/${allData['regId']}/${allData['formId']}/new/ng5`);
    }

    formatActions(actions, allData) {
        actions = actions || [];
        actions.map(ele => {
            if (ele.fuse2App) {
                ele.fuse2App = this.formatUrl(ele.fuse2App, allData);
            }

            if (ele['application'] === 'app.oneRecordView.edit.popup') {
                ele.handler = this.popupEditForm;
            }
            if (ele['application'] === 'app.oneRecordView.edit.sideMenu') {
                ele.handler = this.sideMenuEditForm;
            }
            if (ele['application'] === 'app.oneRecordView.add.popup') {
                ele.handler = this.popupAddNew;
            }
            return ele;
        });
        return actions;
    }

    formatUrl(url: string = '', allData: any): string {
        url = url.replace(':officeId', allData['officeId']);
        url = url.replace(':childRegId', this.params['regId']);
        url = url.replace(':childId', this.params['regId']);
        url = url.replace(':priceListRegId', this.params['price_list_regId']);
        url = url.replace(':parentRegId',
            (this.params['postionsLookupSettings'] && this.params['postionsLookupSettings']['parentId']) ?
                this.params['postionsLookupSettings']['parentId'] : 'null');
        url = url.replace(':regId', allData['regId']);
        url = url.replace(':formId', allData['formId']);
        url = url.replace(':instanceId', allData['integratedProcessId']);
        return url;
    }

    popupEditForm = (recordData) => {
        const defaultValue = this.positionExtraValues || {};
        const dialogRef = this.dialog.open(EditFormPopupComponent, {
            width: '100%',
            panelClass: 'happ-form-builder-dialog',
            data: { 
                recId: recordData.recId, 
                regId: recordData.regId,
                dialog: this.dialog, 
                defaultValue: defaultValue,
                action: 'edit' 
            }
        });


        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.onPositionRecordChange.next(true);
            }
            this.reRenderData.next(true);
        }, err => {
            console.info(err);
        });
    }

    sideMenuEditForm = (recordData) => {
        this.onEditRecord.next(recordData);
    }

    getSums(isPosition: boolean, requestData: any) {
        if (isPosition) {
          delete requestData['filter'];
          delete requestData['mqFilter'];
        }
        requestData['columns'] = this.allData.summingColumns || ['timeSpent', 'timeLeft'];
        return this.helperService.postRequest(`api/registries/sum/${this.regId}`, requestData);
      }
    

    popupAddNew = (recordData) => {
        console.log("thid ata ", this);
        // for calendar event defaul value
        const defaultValue = this.positionExtraValues || {};
        defaultValue['start'] = new Date();
        defaultValue['end'] = new Date(new Date().getTime() + 30 * 60000);

        if (this.applicationType === 'position') {
            defaultValue[this.params['field']] = this.params['recId'] || '';
            defaultValue['parentCaseId'] = this.params['signature'] || '';

            if (this.params.addOnInit) {

                return this.helperService.createRegisterRecord(this.allData.regId, defaultValue)
                    .then(data => {
                        const recId = data['_id'];
                        data.recId = data._id;
                        this.popupEditForm(data);
                    });
            }

        }

        const dialogRef = this.dialog.open(FuseCalendarEventFormDialogComponent, {
            panelClass: 'happ-form-builder-dialog',
            data: {
                defaultValue: defaultValue,
                action: 'new',
                regId: recordData.regId
            },
            width: '100%'
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.onPositionRecordChange.next(true);
            }
            this.reRenderData.next(true);
        }, err => {
            console.info(err);
        });
    }

    moveTo(routeURL) {
        this.router.navigate(['/' + routeURL]);
    }

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

}
