
<div *ngIf="!field.hide || builder">
    <div class="happ-default-slider" [class]="field.class" >
        <div class="happ-default-title happ-title">
            <p *ngIf="!hideTitle" class="happ-field-title">{{ field.title | translate }} </p>
        </div>
        <div class="happ-list happ-content">
            <mat-slider  readonly  fxFlex [thumbLabel]="true"  (change)="onSliderChange()" [disabled]="field.showdisablecontroltype=='true' || disable" [max]="field.max" [min]="field.min" [step]="field.step" [(ngModel)]="model">
            </mat-slider>
        </div>
    </div>
    <div *ngIf="field.allow_description && field.description" [innerHTML]="field.description | htmlWithStyle">
    </div>
</div>