/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/flex-layout/flex";
import * as i2 from "@angular/flex-layout/core";
import * as i3 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "../../template-list/template-list.component.ngfactory";
import * as i9 from "../../template-list/template-list.component";
import * as i10 from "../../../services/helpers/helpers.service";
import * as i11 from "@angular/material/dialog";
import * as i12 from "./select-template.component";
var styles_SelectTemplate = [];
var RenderType_SelectTemplate = i0.ɵcrt({ encapsulation: 2, styles: styles_SelectTemplate, data: {} });
export { RenderType_SelectTemplate as RenderType_SelectTemplate };
export function View_SelectTemplate_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "div", [["class", "px-24"], ["fxLayout", "column"]], null, null, null, null, null)), i0.ɵdid(1, 671744, null, 0, i1.DefaultLayoutDirective, [i0.ElementRef, i2.StyleUtils, [2, i1.LayoutStyleBuilder], i2.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 13, "div", [["class", "px-8 mb-20"], ["fxLayout", "row"], ["fxLayoutAlign", "start center"]], null, null, null, null, null)), i0.ɵdid(3, 671744, null, 0, i1.DefaultLayoutDirective, [i0.ElementRef, i2.StyleUtils, [2, i1.LayoutStyleBuilder], i2.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i0.ɵdid(4, 671744, null, 0, i1.DefaultLayoutAlignDirective, [i0.ElementRef, i2.StyleUtils, [2, i1.LayoutAlignStyleBuilder], i2.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Select Template "])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [["fxFlex", ""]], null, null, null, null, null)), i0.ɵdid(9, 671744, null, 0, i1.DefaultFlexDirective, [i0.ElementRef, i2.StyleUtils, i2.LAYOUT_CONFIG, i1.FlexStyleBuilder, i2.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 2, "button", [["class", "mx-4"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal(null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i0.ɵdid(11, 180224, null, 0, i4.MatButton, [i0.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(-1, 0, [" Cancel "])), (_l()(), i0.ɵeld(13, 0, null, null, 2, "button", [["class", "mx-4"], ["color", "warn"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal("done") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i0.ɵdid(14, 180224, null, 0, i4.MatButton, [i0.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i0.ɵted(-1, 0, [" Done "])), (_l()(), i0.ɵeld(16, 0, null, null, 1, "app-template-list", [], null, [[null, "singleTemplateChange"], [null, "multipleTemplateChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("singleTemplateChange" === en)) {
        var pd_0 = ((_co.selectedTemplate = $event) !== false);
        ad = (pd_0 && ad);
    } if (("multipleTemplateChange" === en)) {
        var pd_1 = ((_co.selectedTemplateList = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_TemplateListComponent_0, i8.RenderType_TemplateListComponent)), i0.ɵdid(17, 114688, null, 0, i9.TemplateListComponent, [i10.HelpersService, i11.MatDialog], { allowSelection: [0, "allowSelection"], allowMultipleSelection: [1, "allowMultipleSelection"], singleTemplate: [2, "singleTemplate"], multipleTemplate: [3, "multipleTemplate"], fields: [4, "fields"] }, { singleTemplateChange: "singleTemplateChange", multipleTemplateChange: "multipleTemplateChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "row"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "start center"; _ck(_v, 4, 0, currVal_2); var currVal_3 = ""; _ck(_v, 9, 0, currVal_3); var currVal_8 = "warn"; _ck(_v, 14, 0, currVal_8); var currVal_9 = _co.data.allowSelection; var currVal_10 = _co.data.allowMultipleSelection; var currVal_11 = _co.selectedTemplate; var currVal_12 = _co.selectedTemplateList; var currVal_13 = _co.data.fields; _ck(_v, 17, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); }, function (_ck, _v) { var currVal_4 = (i0.ɵnov(_v, 11).disabled || null); var currVal_5 = (i0.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_4, currVal_5); var currVal_6 = (i0.ɵnov(_v, 14).disabled || null); var currVal_7 = (i0.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_6, currVal_7); }); }
export function View_SelectTemplate_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "template-select-dialog", [], null, null, null, View_SelectTemplate_0, RenderType_SelectTemplate)), i0.ɵdid(1, 4308992, null, 0, i12.SelectTemplate, [i11.MatDialog, i11.MatDialogRef, i11.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectTemplateNgFactory = i0.ɵccf("template-select-dialog", i12.SelectTemplate, View_SelectTemplate_Host_0, {}, {}, []);
export { SelectTemplateNgFactory as SelectTemplateNgFactory };
