// tslint:disable:indent
import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { DataTableStore, DataTableState } from './datatable.store';

@Injectable()
export class DataTableQuery extends Query<DataTableState> {
	
	applicationType = this.select((state) => state.applicationType);
	regId = this.select((state) => state.regId);
	officeId = this.select((state) => state.officeId);
	recordList = this.select((state) => state.recordList);

	constructor(protected store: DataTableStore) {
		super(store);
	}
}
