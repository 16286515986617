import { HelpersService } from './../../services/helpers/helpers.service';
import { ProfileHelperService } from 'app/main/content/services/profile-helper/profile-helper.service';
import { Component, OnInit, Inject } from '@angular/core';
import { OfficeHelpersService } from '../../services/office-helpers/office-helpers.service';
import { IOfficeData } from '../../interfaces/office.interface';
import { version } from 'environments/version';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { IFormInterface } from '../../interfaces/form.interface';
import * as _ from 'lodash';

@Component({
  selector: 'app-platform-issue-reporter',
  templateUrl: './platform-issue-reporter.component.html',
  styleUrls: ['./platform-issue-reporter.component.scss']
})
export class PlatformIssueReporterComponent implements OnInit {

  FORM_ID = '5cd56583890d6e3d11c448da';
  issueData: any = {};
  loading = false;
  error = '';
  reporterType = 'user';
  browserInfo: any = {};
  labelsOptions = [];
  componentsOptions = [];
  issueTypeOptions = [];
  projectOptions = [];
  assigneeOptions = [];
  estimatetime = [];
  formFields: any[];
  config = {
    toolbar: [
    [ 'Bold', 'Italic', '-', 'NumberedList', 'BulletedList', 'FontSize', 'TextColor', '-', 'Table', 'HorizontalRule' ]
]};
  disabledButton = true;
  constructor(
    private _officeHelper: OfficeHelpersService,
    private _profileHelper: ProfileHelperService,
    private _helperService: HelpersService,
    public dialogRef: MatDialogRef<PlatformIssueReporterComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _translationLoader: FuseTranslationLoaderService,

  ) { }

  ngOnInit() {
    this._helperService.getTrans('issueReporter')
    .then((data2) => {
      this._translationLoader.loadTranslationsV2(data2);
    }).catch(err => {
      console.info(err);
    });
    this.getFormFlatenFields();

    this.reporterType = (this.data && this.data.reporterType) || 'user';
    this.issueData.version = version;
    this.issueData.current_url = window.location.href;
    this.getInitData().catch(err => {
      console.info(err);
    });
    this.browserInfo = this._helperService.getBrowserInfo();

    this.issueData.browserInfo = this.browserInfo;
    this.issueData.browserName = this.browserInfo.browserName;
    this.issueData.fullVersion = this.browserInfo.fullVersion;
  }

  async getInitData () {
    try {
      this.issueData.office_json = await this._officeHelper.getLocalOfficeData();
      this.issueData.logged_user = await this._profileHelper.getLocalProfileData();
      this.getLocalWorkspaceData();
    } catch (err) {
      return err;
    }
  }

  getLocalWorkspaceData() {
    if (localStorage.getItem('wsAppData') && JSON.parse(localStorage.getItem('wsAppData'))) {
      this.issueData.workspace_json = JSON.parse(localStorage.getItem('wsAppData'));
    }
  }

  checkRequired() {
    if (this.issueData.priority && this.issueData.summary) {
      this.disabledButton = false;
    } else {
      this.disabledButton = true;
    }
  }

  saveIssue() {
    this.loading = true;
    this.error = '';
    if (this.issueData && !this.issueData.imgUrl) {
      delete this.issueData.imgUrl;
    }
    this._helperService.postRequest(`api/bug_reports`, this.issueData)
    .then(() => {
      this.loading = false;
      this._helperService.openSnackBar('Issue Reported');
      this.dialogRef.close(null);
    }).catch(err => {
      this.loading = false;
      this.error = err;
      console.info(err);
    });
  }

  getFormFlatenFields() {
    this._helperService.getForm(this.FORM_ID)
    .then((formData: IFormInterface) => {
      return this._helperService.getFormFlatField(formData.ng5Form);
    }).then((flattenFields) => {
      this.formFields = flattenFields || [];
      this.projectOptions = this.getFieldOptions('projectName') || [];
      this.labelsOptions = this.getFieldOptions('labels') || [];
      this.componentsOptions = this.getFieldOptions('components') || [];
      this.issueTypeOptions = this.getFieldOptions('issueType') || [];
      this.assigneeOptions = this.getFieldOptions('assignee') || [];
      this.estimatetime = this.getFieldOptions('estTime') || [];
     
    }).catch(err => {
      this._helperService.openSnackBar(err);
    });
  }

  getFieldOptions(fieldLabel: string): any {
    this.formFields = this.formFields || [];
    const field = _.find(this.formFields, (o) => (o && o.label === fieldLabel  && o.category !== "input" ));
    return (field && field.options) || [];
  }

}
