﻿import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import * as _ from 'lodash';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';
import { DataTableInterface } from 'app/main/content/global-component/datatable/datatable.interface';
import { ICalendarFilter } from 'app/main/content/interfaces/calendarFilter.interface';
import { IFormInterface } from 'app/main/content/interfaces/form.interface';
import { IOfficeData, IOfficeUser } from 'app/main/content/interfaces/office.interface';
import { IRegister } from 'app/main/content/interfaces/register.interface';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { ProfileHelperService } from 'app/main/content/services/profile-helper/profile-helper.service';
import { CalendarColorInteface } from 'app/main/content/apps/calendar-template/calendar.interface';
import { CalenderRecordFilter, CalendarViewSettings, CalendarColor } from 'app/main/content/apps/calendar-template/calendar.model';
import { IDateRange } from 'app/main/content/apps/calendar-template/interface/dateRange.interface';
import { DaysModel } from 'app/main/content/apps/calendar-template/models/days.model';

@Injectable()
export class AgentProposalSelectionService implements Resolve<any> {
 
  storedOfficeData: IOfficeData;
  loggedUserIndex: number;
  loggedUser: string;
  regId: string;
  events = [];

  calendarId;
  regData: IRegister;


  customFilter = {};
  filtertype = 'myCalendar';
  statusFilter = 'ongoing';
  defaultBackgroundColor = 'darkblue';
  defaultColor = 'white';
  defaultFilter: ICalendarFilter;
  currentEventId;
  calenderFilter: DataTableInterface;
  usersCalendarColor: any;
  calendarViewSettings: any = {
    header: {
      left: 'prev,next,today',
      center: 'title',
      right: 'agendaWeek, agendaDay, agendaThreeDay'
    },
    views: {
      agendaThreeDay: {
        type: 'agenda',
        duration: { days: 3 },
        buttonText: '3 day'
      },
      defaultView: 'agendaThreeDay'
    },
    buttonText: {
      today: 'Dzisiaj'
  },
  allDayText: 'Cały dzień', eventLimitText: 'więcej', noEventsMessage: 'Brak wydarzeń do wyświetlenia',
    navLinks: true, // can click day/week names to navigate views
    editable: true,
    eventLimit: true, // allow "more" link when too many events
    events: [],
    minTime: '7:00:00',
    maxTime: '22:00:00',
    contentHeight: 'auto',
    weekends: true,
    defaultView: 'agendaThreeDay',
    firstDay: 0, // sunday
    dragScroll: true,
    selectable: true,
    locale: 'pl',
    slotLabelFormat: 'HH:mm'
  };

  typeFilter = [];
  slotDuration;
  filterDateRange: IDateRange;
  daysArray: Array<string> = DaysModel;
  globalFilters: Array<ICalendarFilter> = [];
  privateFilters: Array<ICalendarFilter> = [];

  constructor(
    private http: HttpClient,
    private officeHelperService: OfficeHelpersService,
    private profileHelperService: ProfileHelperService,
    private helperService: HelpersService
  ) {
    console.log("private calendar");
    
    this.calenderFilter = CalenderRecordFilter;
    this.filterDateRange = {
      start: new Date((new Date().getTime() - (60000 * 60 * 24))),
      end: new Date()
    };
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      this.resolveAll().then(resolve).catch(reject);
    });
  }

  async resolveAll(): Promise<any> {
      try {
        const profileData = await this.profileHelperService.getLocalProfileData();
        if (profileData.privateAreaOfficeId) {
          const officeDoc = await this.officeHelperService.getOfficeData(profileData.privateAreaOfficeId);
          localStorage.setItem("ngStorage-selectedOffice", JSON.stringify(officeDoc));
        } else {
          this.helperService.showInfo("No private are found", 'error', 'error');
        }
        await this.officeHelperService.getLocalOfficeData();
        
        if (profileData['email']) {
          this.loggedUser = profileData['email'] || '';
        } else {
          console.error('No user email in profile');
        }

        console.log("this.logged user - ", this.loggedUser);
        if (this.filtertype === 'myCalendar') {
          // this.customFilter = {
          //   'info_responsible': {
          //     '$in': [this.loggedUser]
          //   }
          // };
        } else {
          this.customFilter = {};
        }

        const calendarId = await this.officeHelperService.getOfficeCalendarId();
        this.regId = calendarId;
        this.calenderFilter['regId'] = this.regId;
        this.getUserColors(JSON.parse(localStorage.getItem('ngStorage-selectedOffice')))
        await this.getCalendarSettings();
        return this.regId;
      } catch (err) {
        console.info(err);
      }
  }

  getEvents(mqHardFilter?): Promise<any> {
    return new Promise((resolve, reject) => {
      this.customFilter = this.customFilter || {};
      if (this.filtertype === 'myCalendar') {
        // this.customFilter['info_responsible'] = {
        //   '$in': [this.loggedUser]
        // };
      } else if (this.statusFilter) {
        this.customFilter['info_status'] = this.statusFilter;
      }

      this.customFilter['start'] = {
        $gte: moment(this.filterDateRange.start).format('YYYY-MM-DD HH:mm:ss')
      };
      this.customFilter['end'] = {
        $lte: moment(this.filterDateRange.end).format('YYYY-MM-DD HH:mm:ss')
      };

      if (this.customFilter['info_responsible'] && this.customFilter['info_responsible'] &&
        this.customFilter['info_responsible'].$in && this.customFilter['info_responsible'].$in.length === 0) {
        delete this.customFilter['info_responsible'];
      }

      if (mqHardFilter) {
        this.calenderFilter['mqFilter'] = mqHardFilter;
      } else {
        delete this.calenderFilter['mqFilter'];
      }

      this.calenderFilter['filters'] = this.customFilter || {};
      this.calenderFilter.calKind = "provider"
      this.helperService.postRequest("api/personal-panel/calendar", this.calenderFilter)
        .then((response) => {
          
          response.data =    (response && response.data) || [];  // response.data || [];
          this.events = response.data.map(ele => this.makeCalendarEvents(ele));
          console.log("events +++ ", this.events );
          resolve(this.events);
        }).catch(err => {
          console.info(err);
          reject(err);
        });
    });
  }

  makeCalendarEvents(ele) {
    try {
      ele['originalRec'] = JSON.parse(JSON.stringify(ele));
      ele['title'] = ele['info_subject'] || ele['subject'];

      ele['all-day'] = (ele['allDay'] === 'true' || ele['allDay'] === true) ? true : false;
      ele['allDay'] = ele['all-day'];
      // ele['all-day'] = true;
      // ele['allDay'] = true;
      ele['backgroundColor'] = ele['backgroundColor'] || (this.usersCalendarColor[ele.info_responsible] &&
        this.usersCalendarColor[ele.info_responsible]['calendarColor'] &&
        this.usersCalendarColor[ele.info_responsible]['calendarColor']['backgroundColor']) || this.defaultBackgroundColor;

      ele['color'] = ele['fontColor'] || (this.usersCalendarColor[ele.info_responsible] &&
        this.usersCalendarColor[ele.info_responsible]['calendarColor'] &&
        this.usersCalendarColor[ele.info_responsible]['calendarColor']['fontColor']) || this.defaultColor;

        if (ele['_id'] && ele['_id'] === this.currentEventId) {
          ele['backgroundColor'] = "red";
          ele['color'] = "white";
        }

      ele['textColor'] = ele['color'];
      ele['id'] = ele['_id'];

      // for repeating events..
      if (ele['repeatingEvent']) {
        ele['start'] = (ele['start'] && moment(ele['start']).format('HH:mm')) || null;
        ele['end'] = (ele['end'] && moment(ele['end']).format('HH:mm')) || null;
        ele['dow'] = [];
        if (ele['repeatingDays']) {
          for (const i in ele['repeatingDays']) {
            if (ele['repeatingDays'][i] && this.daysArray.indexOf(i) >= 0) {
              ele['dow'].push(this.daysArray.indexOf(i));
            }
          }
        }
        ele['ranges'] = [{
          start: (ele['originalRec'] && ele['originalRec']['start'] && moment(ele['originalRec']['start'])) || null,
          end: (ele['originalRec'] && ele['originalRec']['end'] && moment(ele['originalRec']['end'])) || null
        }];
      } else {
        ele['start'] = (ele['start'] && moment(ele['start']).format()) || null;
        ele['end'] = (ele['end'] && moment(ele['end']).format()) || null;
      }
    } catch (err) {
      console.info(err);
    }
      return ele;
  }

  getCalendarSettings(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.helperService.getRegisterData(this.regId)
        .then((res) => {
          if (res) {
            this.regData = res || {} as any;
            this.filtertype = this.regData['filterType'] || this.filtertype;
            this.statusFilter = this.regData['statusFilter'] || this.statusFilter;
            const data = res;
            if (data.calendar_settings) {
              this.calendarViewSettings.minTime = data.calendar_settings.minTime + ':00:00';
              this.calendarViewSettings.maxTime = data.calendar_settings.maxTime + ':00:00';
              this.calendarViewSettings.height = data.calendar_settings.height;
              this.calendarViewSettings.weekends = data.calendar_settings.weeeknds;
              this.calendarViewSettings.firstDay = data.calendar_settings.startDay === 'sunday' ? 0 : 1;
              this.calendarViewSettings.timeFormat = data.calendar_settings.timeFormat === 24 ? 'HH:mm' : 'hh:mm a';
              this.slotDuration = data.calendar_settings.slotDuration;
              // const time = data.calendar_settings.slotDuration;
            }
            Promise.all([
              this.getTypeFilter(data),
              this.getGlobalFilters(data)
            ])
              .then(resolve)
              .catch(reject);
          } else {
            reject('Error occured or reg data is empty');
          }
        }).catch(err => {
          console.info(err);
        });
    });
  }

  getUserColors(storedOffice: Object): Promise<CalendarColorInteface> {
    return new Promise((resolve, reject) => {
      storedOffice['users'] = storedOffice['users'] || [];
      this.usersCalendarColor = this.usersCalendarColor || {};
      storedOffice['users'].forEach(user => {
        if (user) {
          this.usersCalendarColor[user.email] = new CalendarColor();
          this.usersCalendarColor[user.email].calendarColor.backgroundColor = user['color'] || this.usersCalendarColor[user.email].calendarColor.backgroundColor;
          this.usersCalendarColor[user.email].calendarColor.fontColor = user['fontColor'] || this.usersCalendarColor[user.email].calendarColor.fontColor;
        }
      });
      resolve(null);
    });
  }

  getEvent(recId: string) {
    return this.helperService.getRegisterRecordData(this.regId, recId);
  }

  updateEvent(event): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.regId && event['_id']) {
        const data = JSON.parse(JSON.stringify(event));
        delete data['_id'];
        this.helperService.updateRegisterRecord(this.regId, event['_id'], data)
        .then((data2) => {
          resolve(data2[0]);
        }).catch(reject);
      } else {
        reject('No regId or event is null');
      }
    });
  }

  getGlobalFilters(regData): Promise<any> {
    return new Promise((resolve, reject) => {
      regData = regData || {};
      this.globalFilters = regData['customFilters'] || [];
      resolve(true);
    });
  }

  getPrivateFilters(user) {
    user = user || {};
    this.privateFilters = (user['calendar'] && user['calendar']['privatefilters']) || [];
  }

  getDefaultFilter(): ICalendarFilter {
    const user = this.storedOfficeData.users[this.loggedUserIndex];
    return (user && user.calendar && user.calendar['defaultFilter']);
  }

  getTypeFilter(regData) {
    return new Promise((resolve, reject) => {
      regData = regData || {};
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
        if (regData && regData['ng5FormId']) {
          this.helperService.getForm(regData['ng5FormId'])
          .then((formData: IFormInterface) => {
            if (formData) {
              const tempFields = this.getFlatFormField(formData['ng5Form']) || [];
              tempFields.forEach(field => {
                if (field['label'] === 'info_type') {
                  this.typeFilter = field['options'];
                }
              });
              resolve(true);
            } else {
              reject('Form is null or empty');
            }
          }).catch(err => {
            console.info(err);
          });
        } else {
          reject('Error in getting the form id');
        }
      } else {
        reject('Error getting the Office Id');
      }
    });
  }

  getFlatFormField(obj) {
    const ng5AllFields = [];
    obj = obj || [];
    obj.forEach((tabValue) => {
      if (tabValue.tabContent && typeof tabValue.tabContent === 'object') {
        tabValue.tabContent.forEach((value) => {
          if (value.field || (value.data !== null && typeof value.data === 'string')) {
            ng5AllFields.push(value);
          }
          if (value.children && typeof value.children === 'object') {
            value.children.forEach((childValue) => {
              if (childValue.field || (childValue.data !== null && typeof childValue.data === 'string')) {
                ng5AllFields.push(childValue);
              }
              if (childValue && typeof childValue === 'object') {
                childValue.forEach((nestedChildValue) => {
                  if (nestedChildValue.field || (nestedChildValue.data !== null && typeof nestedChildValue.data === 'string')) {
                    ng5AllFields.push(nestedChildValue);
                  }
                });
              }

            });
          }
        });
      }
    });
    return ng5AllFields;
  }

  updateGlobalFilter(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.regId) {
        this.regData['customFilters'] = this.globalFilters || [];
        delete this.regData['_id'];
        this.helperService.updateRegisterData(this.regId, this.regData);
      } else {
        reject('RegId not found');
      }
    });
  }

  getLoggedUserInfo() {
    if (this.storedOfficeData) {
        this.storedOfficeData['users'] = this.storedOfficeData['users'] || [];
        this.loggedUserIndex = _.findIndex(this.storedOfficeData['users'], {email: this.loggedUser});
    }
  }

  onFilterChange(filter: ICalendarFilter) {
    if (filter) {
      this.customFilter = {};
      if (filter['responsibleUsers']) {
        this.customFilter['info_responsible'] = {};
        // this.customFilter['info_responsible']['$in'] = filter['responsibleUsers'];
      }
      if (filter['selectedStatus']) {
        this.customFilter['info_status'] = filter['selectedStatus'];
      }
      if (filter['type']) {
        this.customFilter['info_type'] = filter['type'];
      }
      this.filtertype = filter['name'];
    }
  }

  updatePrivateFilter() {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))) {
        const storedOffice = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'));
        storedOffice['users'] = storedOffice['users'] || [];
        const userIndex = _.findIndex(storedOffice['users'], { email: this.loggedUser });
        const calenderColor = new CalendarColor();
        if (userIndex >= 0) {
          storedOffice['users'][userIndex] = storedOffice['users'][userIndex] || {};
          storedOffice['users'][userIndex]['calendar'] = storedOffice['users'][userIndex]['calendar'] || {};
          storedOffice['users'][userIndex]['calendar']['privatefilters'] = this.privateFilters || [];
          localStorage.setItem('ngStorage-selectedOffice', JSON.stringify(storedOffice));
          const officeId = storedOffice['_id'];
          delete storedOffice['_id'];
          this.helperService.patchRequest(`api/offices/${officeId}`, storedOffice);
        }
        resolve(calenderColor.calendarColor);
      } else {
        reject('No office found');
      }
    });
  }

  setUserDefaultFilter(filter: ICalendarFilter) {
    return new Promise((resolve, reject) => {
      if (this.storedOfficeData) {
        const calenderColor = new CalendarColor();
        if (this.loggedUserIndex >= 0) {
          this.storedOfficeData['users'][this.loggedUserIndex] = this.storedOfficeData['users'][this.loggedUserIndex] || <IOfficeUser>{};
          this.storedOfficeData['users'][this.loggedUserIndex]['calendar'] = this.storedOfficeData['users'][this.loggedUserIndex]['calendar'] || {};
          this.storedOfficeData['users'][this.loggedUserIndex]['calendar']['defaultFilter'] = filter || {};
          localStorage.setItem('ngStorage-selectedOffice', JSON.stringify(this.storedOfficeData));
          const officeId = this.storedOfficeData['_id'];
          this.helperService.patchRequest(`api/offices/${officeId}`, { users: this.storedOfficeData.users })
          .then(resolve).catch(reject);
        }
        resolve(calenderColor.calendarColor);
      } else {
        reject('No office found');
      }
    });
  }  
}
