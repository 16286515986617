/* eslint-disable */
import * as moment from 'moment';
import { escapeRegExp } from 'lodash';

/**
 * Module dependencies.
 */


/**
 * Expose `parse`.
 */

/**
 * Parse `str` to produce an AST.
 *
 * @param {String} str
 * @return {Object}
 * @api public
 */

function parse(str: string, filter: any) {
    str = "(" + str.trim() + ")";

    /**
     * expr
     */

    return expr();

    /**
     * Assert `expr`.
     */

    function error(expr2: any, msg: string) {
        if (expr2) {
            return;
        }
        const ctx = str.slice(0, 10);
        // assert(0, msg + " near `" + ctx + "`");
    }

    /**
     * '(' binop ')'
     */

    function expr(): any {
        error("(" === str[0], "missing opening '('");
        str = str.slice(1);

        const node = binop();

        error(")" === str[0], "missing closing ')'");
        str = str.slice(1);

        return node;
    }

    /**
     * field | expr
     */

    function primary() {
        return field()
      || expr();
    }

    /**
     * primary (OR|AND) binop
     */

    function binop(): any {
        const left = primary();

        const m1 = str.match(/^ *(OR|AND) */i);
        if (!m1) {
            return left;
        }

        str = str.slice(m1[0].length);

        const right = binop();

        return {
            type: "op",
            op: m1[1].toLowerCase(),
            left: left,
            right: right
        };
    }

    /**
     * FIELD[:VALUE]
     */

    function field() {
        let val: any = true;
        let m1 = str.match(/^([-.\w]+)/);
        let cmp;
        if (!m1) {
            return;
        }

        const name = m1[0];
        str = str.slice(name.length);

        m1 = str.match(/ *([:><!= \IN\ \NOT_IN\ \CONTAINS\ \EXISTS\ \NOT_CONTAINS\ ]*) *([-*\w.]+|".*?"|'.*?'|\/(.*?)\/) */);

        if (m1) {
            str = str.slice(m1[0].length);
            val = m1[2];
            cmp = comparison(m1[1]);
            if (numeric(val)) {
                val = parseFloat(val);
            }
        }

        const ret: any = {
            type: "field",
            name: name,
            value: coerce(val, cmp, filter)
        };

        if (cmp) {
            ret.cmp = cmp;
        }

        return ret;
    }
}

/**
 * Coerce `val`:
 *
 * - boolean strings to bools
 * - regexp notation to regexps
 * - quote-stripped strings
 */

function coerce(val: any, operator: any, filter: any) {
    if ("string" !== typeof val) {
        return val;
    }

    let m1: any;

    // regexp
    if ("/" === val[0]) {
        val = val.slice(1, -1);
        return new RegExp(val);
    }
    if ("@" === val[1]) {
        val = val.slice(1, -1);
        return val.includes("true");
    }
    val = val.trim();
    val = val.replace(/'/g, "");
    val = val.replace(/"/g, "");
    let val2 = val;
    if (val.match(/\W*(Date\(.*?)\)\W*/)) {
        m1 = val.match(/\((.*?)\)/)[1];
        val = "date";
    }


    // boolean
    switch (val) {
        case "date":

            const dateObj = getDate(m1);
            const tempDate = dateObj.date

            // other time operation

            val2 = val2.trim();
            const temp = val2.substr(val2.indexOf(")")+1, val2.length);

            return getDateFilter(tempDate, temp, dateObj.format);
    }

    for (const key in  filter) {
        if (filter.hasOwnProperty(key) && val===key) {
            val = filter[key];
        }
    }


    const exists = [ "in", "nin" ].some((o1) => o1 === operator);

    if (exists) {
        val = val.split(",").map((x1: any) => {
            return (Number(x1)? Number(x1):x1);
        });
    }

    // quoted
    if (quoted(val)) {
        return strip(val);
    }

    // pattern
    if (~val.indexOf("*")) {
        return pattern(val);
    }

    // term
    return val;
}

/**
 *get date from string.
 */
function getDate(value: any) {
    const temp = value.split(",").filter((ele: any) => ele).map((ele: any) => ele.trim());
    if (temp[1]) {
        return {
            format: temp[1],
            date: temp[0]==="now" ? moment().format(temp[1]) : moment(temp[0]).format(temp[1])
        }
    } else {
        return {
            format: "",
            date: temp[0]==="now"?new Date(): new Date(temp[0])
        }
    }
}


function getDateFilter(date: string | Date, operator: string | any, format = "") {
    let tt;
    let num2;
    operator = operator || "";
    const op = operator.substr(operator.length-1, 1);
    const num = operator.substr(0, operator.length-1);
    if (parseInt(num) !== NaN) {
        num2 = parseInt(num);
    } else {
        num2 = 0;
    }

    if (op) {
        tt = moment(date).add(num2, op).format(format);
    } else {
        tt = moment(date).format(format);
    }
    return tt;
}


/**
 * Convert pattern `str` to a regexp.
 */

function pattern(str: string) {
    str = escapeRegExp(str).replace(/\\\*/g, ".*");
    return new RegExp("^" + str + "$");
}

/**
 * Check if `str` is quoted.
 */

function quoted(str: string) {
    return "'" === str[0] || "\"" === str[0];
}

/**
 * Strip quotes from `str`.
 */

function strip(str: string) {
    return str.replace(/^['"]|['"]$/g, "");
}

/**
 * Check if `str` is numeric.
 */

function numeric(str: string) {
    return !isNaN(parseFloat(str));
}

/**
 * Return mongodb comparison operator.
 */

function comparison(str: string) {
    str = str.trim();
    str = str.replace(/'/g, "");
    switch (str) {
        case ">":
            return "gt";
        case "<":
            return "lt";
        case "<=":
            return "lte";
        case ">=":
            return "gte";
        case "!=":
            return "ne";
        case "IN":
            return "in";
        case "NOT":
            return "not";
        case "NOT_IN":
            return "nin";
        case "CONTAINS":
            return "regex";
        case "EXISTS":
            return "exists";
        case "NOT_CONTAINS":
            return "not";
        default:
            return null;
    }
}


/**
 * Expose `compile`.
 */


/**
 * Compile `node` to a mongo query.
 *
 * @param {Object} node
 * @return {Object}
 * @api public
 */

function compile(node: any) {
    let obj: any = {};
    let val: any;
    let op: any;

    switch (node.type) {
        case "field":
            obj = {};

            if (node.cmp) {
                op = "$" + node.cmp;
                val= {};
                val[op]= node.value;
            } else {
                val= node.value;
            }

            obj[node.name] = val;
            return obj;

        case "op":
            obj = {};
            op = "$" + node.op;

            obj[op] = [
                compile(node.left),
                compile(node.right)
            ];

            return obj;
    }
}

const mqFilter =  function(str: string, filter: any) {
    const temp = compile(parse(str, filter));
    return temp;
};

export default mqFilter;
