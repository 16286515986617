<div style="min-width: 800px;" fxLayout="row" *ngIf="appData && appData.datatableViewType === 'card'; else datatable">
    <div style="background: #eeeeee;" [style.min-width]="appData.leftMinWidth || '200px'">
        <app-datatable-hard-filter [stepLabel]="appData.stepLabel" [rows]="rows" [showBadge]="appData.showBadge"
            [layout]="'left'" [currentMQFilter]="mqFilterString || requestData['currentMQFilter']"
            [appHardFilter]="appData.hardFilter" [defaultHardFilter]="defaultHardFilter"
            [dtTableAdvanceFilters]="appData.viewFilters" (hardFilterClicked)=hardFilterClicked($event)>
        </app-datatable-hard-filter>
    </div>
    <div fxFlex style="background: #eeeeee;">
        <app-datatable-card-view [appHardFilter]="appData.hardFilter" [cardHeader]="appData.cardHeader"
            [sortByField]="appData.defaultCardSortField"
            (reportGenerationPopup)="reportGenerationPopup($event)"
            [insertButtonText]="(appData && appData['itemName']) || 'Dodaj'"
            [sortByOrder]="appData.defaultCardSortOrder" (reloadDatatable)="refreshTable($event)"
            [loadingIndicator]="loadingIndicator" [isAdmin]="isAdmin" [stepLabel]="appData.stepLabel"
            [mqFilterString]="mqFilterString || defaultHardFilter" [rows]="rows" [cardActions]="appData.cardActions"
            (openWSAppsSettings)="openWSAppsSettings($event)" (cardOptionChanged)="cardOptionChanged($event)"
            (editRecord)="editRecord($event)"></app-datatable-card-view>
    </div>
</div>
<ng-template #datatable>
    <div style="min-width: 750px;" class="page-layout simple left-sidebar inner-scroll">
        <!-- left side hard filter -->
        <fuse-sidebar
            *ngIf="appData && appData.showHardFilter && appData.hardFilterConfig && appData.hardFilterConfig.align === 'left'"
            class="sidebar happ-small-sidebar" name="simple-left-sidebar-2" position="left" lockedOpen="gt-md">
            <app-datatable-hard-filter [stepLabel]="appData.stepLabel" [layout]="appData.hardFilterConfig.align"
                [currentMQFilter]="requestData['currentMQFilter']" [defaultHardFilter]="defaultHardFilter"
                [dtTableAdvanceFilters]="allData.dtTableAdvanceFilters" (hardFilterClicked)=hardFilterClicked($event)>
            </app-datatable-hard-filter>
        </fuse-sidebar>
        <!-- center content -->
        <div class="center" fusePerfectScrollbar>
            <div class="content">
                <div style="height: -webkit-fill-available;">
                    <div *ngIf="allData.filters && !allData.filters.disableInsert" fxLayout="column"  [ngStyle]="{ 'background': 'white'}" class="p-8 pb-4">
                        <!-- top hard filter -->
                        <div fxLayout="row"
                            *ngIf="appData && appData.showHardFilter && appData.hardFilterConfig && appData.hardFilterConfig.align === 'top'">
                            <app-datatable-hard-filter [stepLabel]="appData.stepLabel"
                                [currentMQFilter]="requestData['currentMQFilter']"
                                [defaultHardFilter]="defaultHardFilter"
                                [dtTableAdvanceFilters]="allData.dtTableAdvanceFilters"
                                (hardFilterClicked)=hardFilterClicked($event)></app-datatable-hard-filter>
                        </div>
                        <!-- filter and action buttons on header -->
                        <div fxLayout="row wrap">
                            <!-- Advance filter and search options buttons -->
                            <div fxFlex fxLayout="row">
                                <!-- <button *ngIf="datatableService.applicationType==='position'" style="margin: auto;"
                                    mat-icon-button class="sidebar-toggle mr-8" fxHide.gt-md
                                    (click)="toggleSidebar('simple-left-sidebar-2')">
                                    <mat-icon>menu</mat-icon>
                                </button> -->
                                <ng-container *ngIf="allData && allData.showDateRange">
                                    <app-date-filter-field class="mr-8" [(model)]="dateTimeFilterType" [fieldTitle]=""></app-date-filter-field>
                                </ng-container>
                                <mat-form-field *ngIf="allData.filters && !allData.filters.noAdvanceFiltersSelected"
                                    class="happ-small-form-field-outline" style="max-width: 200px;" floatLabel="never"
                                    floatLabel="always" appearance="outline">
                                    <input matInput aria-label="record_fast_search_input"
                                        (keyup.enter)="filter(fastQuery)"
                                        placeholder="{{ 'dttable.search' | translate }}" [(ngModel)]="fastQuery">
                                </mat-form-field>
                                <button aria-label="record_fast_search_button"
                                    *ngIf="allData && allData.filters && !allData.filters.noAdvanceFiltersSelected"
                                    style="margin: auto 2px;" class="ml-8"
                                    matTooltip="{{ 'dttable.search' | translate }}" (click)="filter(fastQuery)"
                                    mat-icon-button>
                                    <mat-icon style="color: black;">search</mat-icon>
                                </button>
                                <button style="margin: auto 2px;" matTooltip="{{ 'dttable.reset' | translate }}"
                                    (click)="fastQuery=''; reset(); resetLocalData()" mat-icon-button>
                                    <mat-icon style="color: black">sync</mat-icon>
                                </button>
                                <div fxLayout="row" *ngIf="!(allData && allData.filters && allData.filters.noFilters)">
                                    <button [hidden]="allData.filters && allData.filters.noAdvanceFilters"
                                        style="margin: auto 2px;"
                                        matTooltip="{{ 'dttable.show_advance_filter' | translate }}"
                                        (click)="filterIconClicked()" mat-icon-button>
                                        <mat-icon style="color: black;">filter_list</mat-icon>
                                    </button>
                                </div>
                                <div *ngIf="(allData && allData.superFilter && allData.superFilter.length) || 
                            (allData && allData.dtTableAdvanceFilters && allData.dtTableAdvanceFilters.length > 0 && allData.filters && !allData.filters.noAdvanceFiltersSelected) || 
                            (appData && appData.officeFilters && appData.officeFilters.length)" fxLayout="row">
                                    <button style="margin: auto 2px;"
                                        matTooltip="{{ 'dttable.show_super_filter' | translate }}"
                                        (click)="allData.showSuperFilter=!allData.showSuperFilter" mat-icon-button>
                                        <mat-icon style="color: black;">tune</mat-icon>
                                    </button>
                                </div>
                                <!-- <button aria-label="datatable_excel_button" style="margin: auto 2px;"
                                    matTooltip="{{ 'dttable.excel_report' | translate }}" (click)="reportGenerationPopup()"
                                    mat-icon-button>
                                    <mat-icon style="color: black">chrome_reader_mode</mat-icon>
                                </button> -->
                                
                                <button aria-label="datatable_save_filter_button" style="margin: auto 2px;"
                                    matTooltip="{{ 'dttable.save_filter_to_office_doc' | translate }}"
                                    (click)="saveFilterToOffice()" mat-icon-button>
                                    <mat-icon style="color: black">save</mat-icon>
                                </button>
                                <button
                                    aria-label="datatable_pdf_button" style="margin: auto 2px;"
                                    matTooltip="{{ 'dttable.pdf_report' | translate }}" (click)="reportGenerationPopup()" mat-icon-button>
                                    <mat-icon style="color: black">picture_as_pdf</mat-icon>
                                </button>
                                <button *ngIf="appData && appData.stepDescription" aria-label="datatable_info_button"
                                    style="margin: auto 2px;" matTooltip="{{ 'dttable.app_info' | translate }}"
                                    (click)="openInstructionPopup((appData && appData.stepDescription))"
                                    mat-icon-button>
                                    <mat-icon style="color: black">info</mat-icon>
                                </button>
                                <button aria-label="datatable_settings_button" style="margin: auto 2px;"
                                    *ngIf="isAdmin && allData" matTooltip="{{ 'dttable.settings' | translate }}"
                                    (click)="openWSAppsSettings(0)" mat-icon-button>
                                    <mat-icon style="color: black">settings</mat-icon>
                                </button>
                                <p class="font-size-14 ml-8" style="cursor: pointer; color: brown; margin: auto;"
                                    *ngIf="lastSearchPersisted"
                                    (click)="fastQuery='';lastSearchPersisted=false; requestData={}; getServerSideRecord()">
                                    {{ 'dttable.last_search_persisted_clear_all' | translate }} </p>
                            </div>
                            <div fxFlex>
                                <ng-container *ngIf="!(datatableService.params && datatableService.params.hideAddButton)">
                                    <div fxFlex fxLayout="row" style="justify-content: flex-end;">
                                        <!-- left side action buttons -->
                                        <div fxLayout="row wrap" fxLayoutAlign="end center"
                                            *ngIf="datatableService && datatableService.applicationType !== 'workspace'">
                                            <div *ngFor="let action of allData.headerActions">
                                                <button
                                                [disabled]="addLoading"
                                                    [attr.aria-label]="((action.translations)? action.translations.pl :action.default_label) | uppercase"
                                                    *ngIf="action.placement.headerActions && (!action.appType || (action.appType && action.appType !== 'position'))"
                                                    mat-raised-button style="text-transform: capitalize;"
                                                    style="margin-left: 12px;"
                                                    (click)="actionMoveTo(rowIndex, action.application, null, action, action.fuse2App)"
                                                    color="warn">
                                                    {{ ((action.translations)? (action.translations.pl | translate) : (action.default_label | translate)) }}
                                                </button>
                                                <button
                                                [disabled]="addLoading"
                                                    [attr.aria-label]="((action.translations)? action.translations.pl :action.default_label) | uppercase"
                                                    *ngIf="action.placement.headerActions && allData.appType && allData.appType === 'position' && action.appType === 'position'"
                                                    mat-raised-button style="text-transform: capitalize;"
                                                    style="margin-left: 12px;"
                                                    (click)="actionMoveTo(rowIndex, action.application, null, action, action.fuse2App)"
                                                    color="warn">
                                                    {{ ((action.translations)? (action.translations.pl | translate) : (action.default_label | translate)) }}
                                                </button>
                                            </div>
                                        </div>
                                        <!-- left side required option in workspace -->
                                        <div *ngIf="appData && !wsRequiredFieldDone"
                                            style="font-size: 18px; margin: auto 5px; color: red;">
                                            {{ 'dttable.please_fill_the_required_fields' | translate }} <span
                                                style="cursor: pointer;"
                                                (click)="openWSAppsSettings(2)">{{ 'dttable.click_here' | translate }}</span>
                                        </div>
                                        <!-- <div fxLayoutAlign="end center"
                                            *ngIf="datatableService && datatableService.applicationType !== 'position' && appData && appData.showBulkAction">
                                            <button aria-label="dttable_barcode_single" mat-raised-button
                                                style="text-transform: capitalize;" style="margin-left: 12px;"
                                                (click)="openBulActionPopup(null)" color="warn">
                                                {{ 'dttable.open_bulk_action' | translate }} </button>
                                        </div> -->
                                        <button *ngIf="!(datatableService.params && datatableService.params.hideBulkActionButton)" [matMenuTriggerFor]="menu3" mat-icon-button>
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menu3="matMenu">
                                            <button mat-menu-item (click)="openBulActionPopup(null)">
                                                {{ 'dttable.open_bulk_action' | translate }}
                                            </button>
                                    
                                        </mat-menu>
                                        <!-- left side action buttons in workspace -->
                                        <div fxLayout="row wrap" fxLayoutAlign="end center"
                                            *ngIf="datatableService && datatableService.applicationType === 'workspace' && appData && !appData.hideHeaderActions && wsRequiredFieldDone">
                                            <div fxLayout="row wrap" *ngFor="let action of appData.actionList">
                                                <button mat-raised-button
                                                    *ngIf="action.placement && action.placement.headerActions"
                                                    style="text-transform: capitalize;" style="margin-left: 12px;"
                                                    (click)="openBulActionPopup(action)" color="warn">
                                                    {{ action.default_label | translate }} </button>
                                            </div>
                                            <button aria-label="dttable_barcode_single"
                                                *ngIf="appData && appData.barcode_single" mat-raised-button
                                                style="text-transform: capitalize;" style="margin-left: 12px;"
                                                (click)="moveTo('./barcode/single/'+appData.single_barcode_params)"
                                                color="warn"> {{ 'dttable.barcode_single' | translate }} </button>
                                            <button aria-label="dttable_barcode_package"
                                                *ngIf="appData && appData.barcode_package" mat-raised-button
                                                style="text-transform: capitalize;" style="margin-left: 12px;"
                                                (click)="moveTo('./barcode/package/'+appData.package_barcode_params)"
                                                color="warn"> {{ 'dttable.barcode_package' | translate }} </button>
                                            <button aria-label="dttable_open_scanque"
                                                *ngIf="appData && appData.showScanque && allData && allData.scanqueue && allData.scanqueue.isChecked"
                                                mat-raised-button style="text-transform: capitalize;"
                                                style="margin-left: 12px;" (click)="moveTo('./sq/'+allData.scanqueue.sqObj)"
                                                color="warn"> {{ 'dttable.open_scanque' | translate }} </button>
                                            <button aria-label="dttable_open_postbook"
                                                *ngIf="appData && appData.showPostbook" mat-raised-button
                                                style="text-transform: capitalize;" style="margin-left: 12px;"
                                                (click)="moveTo('./postbook')" color="warn">
                                                {{ 'dttable.open_postbook' | translate }} </button>
                                                <ng-container *ngIf="!(datatableService.params && datatableService.params.hideAddButton)">
                                                    <button aria-label="dttable_insert_with_process"
                                                        *ngIf="allData && allData['integratedProcessId']" mat-raised-button
                                                        style="text-transform: capitalize;" style="margin-left: 12px;"
                                                        (click)="moveTo('./recWithInstance')" color="warn">
                                                        {{ (appData && appData['itemName']) || 'Dodaj' }} </button>
                                                    <button aria-label="dttable_insert_simple"
                                                        *ngIf="allData && !allData['integratedProcessId'] && appData && !appData.createAndEdit" mat-raised-button
                                                        style="text-transform: capitalize;" style="margin-left: 12px;"
                                                        (click)="moveTo('./new')" color="warn">
                                                        {{ (appData && appData['itemName']) || 'Dodaj' }} </button>
                                                    <button aria-label="dttable_insert_simple"
                                                        *ngIf="allData && !allData['integratedProcessId'] && appData && appData.createAndEdit" mat-raised-button
                                                        style="text-transform: capitalize;" style="margin-left: 12px;"
                                                        (click)="moveTo('./create-and-edit')" color="warn">
                                                        {{ (appData && appData['itemName']) || 'Dodaj' }} </button>
                                                </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        <!-- date range filter in advance options -->
                        <div fxLayout="row" *ngIf="dateTimeFilterType === '##df_customValue##'" style="border-top: 1px solid lightgray;" fxLayoutAlign="start space-around" class="date mt-4 pt-4"
                        fxLayout="row wrap">
                        
                            <div fxLayout="row" fxLayoutAlign="center space-around">
                                <div class="mr-12">
                                    <mat-form-field class="mr-12 happ-small-form-field-outline" floatLabel="always" appearance="outline">
                                        <mat-label>{{ 'dttable.choose_start_date' | translate }}</mat-label>
                                        <input matInput [matDatepicker]="picker2"
                                            [(ngModel)]="timeFilter['startDate']">
                                        <mat-datepicker-toggle matSuffix [for]="picker2">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </mat-form-field>
                                    <mat-form-field class="w-80 happ-small-form-field-outline" floatLabel="always" appearance="outline">
                                        <mat-label>{{ 'dttable.start_time' | translate }}</mat-label>
                                        <input matInput [textMask]="{mask: mask}" [(ngModel)]="timeFilter['startTime']"
                                            type="text" />
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field class="mr-12 happ-small-form-field-outline" floatLabel="always" appearance="outline">
                                        <mat-label>{{ 'dttable.choose_end_date' | translate }}</mat-label>
                                        <input matInput [matDatepicker]="picker"
                                            [(ngModel)]="timeFilter['endDate']">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                    <mat-form-field class="w-80 happ-small-form-field-outline" floatLabel="always" appearance="outline">
                                        <mat-label> {{ 'dttable.end_time' | translate }} </mat-label>
                                        <input matInput [textMask]="{mask: mask}" [(ngModel)]="timeFilter['endTime']"
                                            type="text" />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    <!-- advance filter buttons -->
                        <div class="filter ml-8 mb-8" fxLayout="column" *ngIf="allData && allData.showSuperFilter">
                            <!-- MQ filter header buttons -->
                            <app-datatable-super-filter [superFilter]="allData.superFilter"
                                [(valueFilter)]="valueFilter"></app-datatable-super-filter>
                            <div fxLayout="row"
                                *ngIf="appData && appData.officeFilters && appData.officeFilters.length">
                                <app-datatable-office-filter [refreshOfficeFilter]="refreshOfficeFilter" [regId]="regId"
                                    [appDataOfficeFilters]="(appData && appData.officeFilters)"
                                    (officeFilterClicked)="officeFilterClicked($event)"></app-datatable-office-filter>
                            </div>
                        </div>
                        <!-- filter and action buttons on header ends -->
                        <div fxLayout="row"
                            *ngIf="appData && appData.showHardFilter && appData.hardFilterConfig && appData.hardFilterConfig.align === 'bottom'">
                            <app-datatable-hard-filter [stepLabel]="appData.stepLabel"
                                [currentMQFilter]="requestData['currentMQFilter']"
                                [defaultHardFilter]="defaultHardFilter"
                                [dtTableAdvanceFilters]="allData.dtTableAdvanceFilters"
                                (hardFilterClicked)=hardFilterClicked($event)></app-datatable-hard-filter>
                        </div>
                    </div>
                    <!-- field filter in advance options -->
                    <div class="filter" fxLayout="row wrap" fxLayoutAlign="start center"
                    *ngIf="allData.filters && allData.filters.noAdvanceFiltersSelected">
                    <div *ngFor="let filterOption of allData.filters.values">
                        <div fxLayoutAlign="center center"
                            *ngIf="filterOption.control_type !== 'date' && filterOption.control_type !== 'date-time' && filterOption.control_type !== 'input-tag'">
                            <mat-form-field class="m-8 happ-small-form-field-outline" floatLabel="always" appearance="outline">
                                <mat-label>{{ filterOption['title'] | translate }}</mat-label>
                                <input *ngIf="filterOption.control_type === 'number'" type="number" matInput [(ngModel)]="valueFilter[filterOption['label']]">
                                <input *ngIf="filterOption.control_type !== 'number'" matInput [(ngModel)]="valueFilter[filterOption['label']]">
                            </mat-form-field>
                        </div>
                        <mat-form-field *ngIf="filterOption.control_type !== 'date' && filterOption.control_type !== 'date-time' && filterOption.control_type === 'input-tag'" class="w-100-p m-8" floatPlaceholder="never">
                            <mat-chip-list #userList>
                                <mat-chip *ngFor="let user of items[filterOption['label']]"
                                            removable="true" (removed)="removeItem(user, filterOption['label'])">
                                    {{user}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                            <mat-label>{{ filterOption['title'] | translate }}</mat-label>
                            <input matInput
                                aria-label="user_autocomplete_input"
                                class="w-100-p"
                                matChipInputAddOnBlur="true"
                                [(ngModel)]="tagFilterInput[filterOption['label']]"
                                placeholder="{{placeHolder || ''}}"
                                [matAutocomplete]="auto" 
                                #temp (keyup)="inputValueChanged($event, temp.value, filterOption['label'])"
                                [matChipInputFor]="userList"/>
                                <mat-autocomplete (optionSelected)="addItem($event, null, filterOption['label'])" #auto="matAutocomplete">
                                <mat-option *ngFor="let user of filterValue[filterOption['label']]" [value]="user.tag">
                                    <span>{{user.tag}}</span>
                                </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        <div *ngIf="filterOption.control_type === 'date' || filterOption.control_type === 'date-time'" fxLayout="row" fxLayoutAlign="center center">
                            <app-date-filter-field class="m-8" (modelChange)="onCustomDateChange($event, filterOption['label'])"  [(model)]="valueFilter[filterOption['label']]" [fieldTitle]="filterOption['title']"></app-date-filter-field>
                            <ng-container *ngIf="valueFilter[filterOption['label']] && valueFilter[filterOption['label']] === '##df_customValue##'" >
                                <div fxLayout="row" fxLayoutAlign="center space-around" *ngIf="valueFilter['df_'+filterOption['label']]">
                                    <div class="mr-12">
                                        <mat-form-field class="mr-12 happ-small-form-field-outline" floatLabel="always" appearance="outline">
                                            <mat-label>{{ 'dttable.choose_start_date' | translate }}</mat-label>
                                            <input matInput [matDatepicker]="picker2"
                                                [(ngModel)]="valueFilter['df_'+filterOption['label']]['startDate']">
                                            <mat-datepicker-toggle matSuffix [for]="picker2">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker2></mat-datepicker>
                                        </mat-form-field>
                                        <mat-form-field class="w-80 happ-small-form-field-outline" floatLabel="always" appearance="outline">
                                            <mat-label>{{ 'dttable.start_time' | translate }}</mat-label>
                                            <input matInput [textMask]="{mask: mask}" [(ngModel)]="valueFilter['df_'+filterOption['label']]['startTime']"
                                                type="text" />
                                        </mat-form-field>
                                    </div>
                                    <div style="margin-right: 12px;">
                                        <mat-form-field class="mr-12 happ-small-form-field-outline" floatLabel="always" appearance="outline">
                                            <mat-label>{{ 'dttable.choose_end_date' | translate }}</mat-label>
                                            <input matInput [matDatepicker]="picker"
                                                [(ngModel)]="valueFilter['df_'+filterOption['label']]['endDate']">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                        <mat-form-field class="w-80 happ-small-form-field-outline" floatLabel="always" appearance="outline">
                                            <mat-label> {{ 'dttable.end_time' | translate }} </mat-label>
                                            <input matInput [textMask]="{mask: mask}" [(ngModel)]="valueFilter['df_'+filterOption['label']]['endTime']"
                                                type="text" />
                                        </mat-form-field>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <button mat-raised-button class="mx-4" (click)="search()">
                            <mat-icon aria-label="reset">search</mat-icon>
                            <!-- {{ 'dttable.search' | translate }} -->
                        </button>
                        <button class="mx-4" mat-raised-button color="warn" (click)="reset()">
                            <mat-icon style="color: white;" aria-label="reset">sync</mat-icon>
                            <!-- {{ 'dttable.reset' | translate }} -->
                        </button>
                        <button *ngIf="datatableService && datatableService.applicationType !== 'workspace'"
                            mat-raised-button class="mx-4" (click)="savePrivateFilter()">
                            <mat-icon aria-label="reset">add</mat-icon>
                            <!-- {{ 'dttable.add_private_filter' | translate }} -->
                        </button>
                        <app-private-filter
                            *ngIf="datatableService && datatableService.applicationType !== 'workspace'"
                            #privateFilter [regId]="allData['regId']"
                            (privateFilterChange)="triggerPrivateFilter($event)">
                        </app-private-filter>
                    </div>
                    <div [class]="(appData && appData.datatableHeaderBottomBorderColor) ? (appData.datatableHeaderBottomBorderColor + '-bg w-100-p') : 'w-100-p'" [ngStyle]="{'height.px': (appData && appData.datatableHeaderBottomBorderSize) || 0}">
                    </div>
                    <div *ngIf="loadingIndicator"
                        style="display: flex; height: 70vh; justify-content: center; align-items: center; background: white;">
                        <mat-spinner></mat-spinner>
                    </div>
                    <div  style="overflow: auto;" [ngStyle]="{'height': 'calc(100vh - '+ ((appData && (appData && appData.datatableHeaderBottomBorderSize)) ? ((appData && appData.datatableHeaderBottomBorderSize) + 230) : 230 )+'px)'}" *ngIf="!loadingIndicator"
                        fxLayout="column">
                        <div class="fuse-white-bg">
                            <div>
                                <!-- datatable header card view -->
                                <div *ngIf="chartData && chartData.length > 0"
                                    style="background: white; margin-bottom: -44px; margin-top: 20px">
                                    <div fxFlex="100" fxLayout="column" style="width: 100%; margin: 20px;">
                                        <div fxLayout="row" *ngFor="let i of cardVarsArray">
                                            <div *ngFor="let j of i.options"
                                                [fxFlex]="(i.options && i.options.length>0)? (100/i.options.length): '100'">
                                                <div fxLayout="row"
                                                    style="padding: 8px; margin: 2px; font-size: 25px; border-top: 15px solid;"
                                                    class="my-4"
                                                    [style.border-color]="(j.background)? j.background: 'white'">
                                                    <div fxHide.lt-md="true" style="font-size: 18px; margin: auto;"
                                                        fxFlex="49"> {{ j.value || j.title }} </div>
                                                    <div fxFlex.lt-md="100" style="font-size: 35px;"
                                                        fxLayoutAlign="center center" fxFlex="49">
                                                        {{ cardVars[i.label][j.value || j.title].count }} </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                </div>
                                <div *ngIf="preview" [innerHTML]="preview | htmlWithStyle">
                                </div>
                                <!-- data table grid starts -->
                                <ng-container *ngIf="!loadingIndicator && allData && !allData.groupBy">
                                    <div *ngIf="rows && rows.length && allData && allData.columns.length !== 1" [class]="(appData && appData.hideDataTableHeader) ? 'w-100-p hide-header-data-table' : 'w-100-p'" style="overflow: auto;">
                                        <ngx-datatable
                                            [messages]="{emptyMessage: 'dttable.empty_message' | translate, totalMessage: 'dttable.total' | translate}"
                                            [@animateStagger]="{value:'50'}" class="bootstrap" [rows]="rows"
                                            [rowClass]="getRowClass" [columnMode]="allData.columnMode"
                                            [headerHeight]="allData.headerHeight" [footerHeight]="allData.footerHeight"
                                            [rowHeight]="allData.rowHeight" [externalPaging]="allData.externalPaging"
                                            [count]="page.totalElements" [offset]="page.pageNumber"
                                            (sort)="onSort($event)" [sorts]="allData.sort || [{ prop: '_id' }]"
                                            [limit]="page.size" (page)='setPage($event)'>
                                            <ngx-datatable-column [headerClass]="(appData && appData.headerBackgroundColor) ? (appData.headerBackgroundColor + '-bg') : ''" [flexGrow]="(col.control_type && col.control_type.cell_width)? col.control_type.cell_width: ((col.name=='Actions' && allData.actionWidth)? allData.actionWidth: ((col.name=='subAction' && allData.subActionWidth)? allData.subActionWidth: 1)) "
                                                *ngFor="let col of allData.columns | filter:false:'isPullOnly'; let colIndex=index"
                                                fxFlex [prop]="col.prop" [name]="col.title">
                                                <!-- Header Template Starts -->
                                                <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                                                    <div (click)="sort()">
                                                        <app-datatable-header-tmp [col]="col" [column]="column" ></app-datatable-header-tmp>
                                                    </div>
                                                </ng-template>
                                                <!-- Header Template Ends -->
                                                <!-- Row and cell Template Starts -->
                                                <ng-template let-value="value" class="happ-dummy" let-rowIndex="rowIndex" let-row="row" style="color: blue;" ngx-datatable-cell-template>
                                                    <app-row-header-tmp (refreshTable)="makeRefreshTable()" [colIndex]="colIndex" [rowIndex]="rowIndex" [datatableService]="datatableService" [row]="row" [appData]="appData" [inlineEditCell]="inlineEditCell" [value]="value" [col]="col" [column]="column" (downloadPdfEvent)="downloadPdf($event)" (openDescriptionPopupOutput)="redirectCall($event, 'openDescriptionPopup')" (moveToOutput)="redirectCall($event, 'moveTo')" (inlineEditOutput)="redirectCall($event, 'inlineEdit')" (updateRegOutput)="redirectCall($event, 'updateReg')" (actionMoveToOutput)="redirectCall($event, 'actionMoveTo')"  ></app-row-header-tmp>
                                                </ng-template>
                                                <!-- Row and cell Template Ends -->
                                            </ngx-datatable-column>
                                        </ngx-datatable>
                                        <div
                                            *ngIf="allData && allData.summingColumns && allData.summingColumns.length > 0">
                                            <ngx-datatable class="bootstrap" [headerHeight]="0" [rows]="summaryArray"
                                                [rowClass]="getRowClass" [loadingIndicator]="loadingIndicator"
                                                [columns]="allData.columns" [columnMode]="allData.columnMode"
                                                [headerHeight]="allData.headerHeight" [rowHeight]="allData.rowHeight"
                                                [externalPaging]="allData.externalPaging" [count]="page.totalElements"
                                                [offset]="page.pageNumber" (sort)="onSort($event)"
                                                [sorts]="allData.sort"
                                                [limit]="(showTotalRow)? (page.size+1): page.size" [footerHeight]="0">
                                                <ngx-datatable-column
                                                    [flexGrow]="(col.control_type && col.control_type.cell_width)? col.control_type.cell_width: ((col.name=='Actions' && allData.actionWidth)? allData.actionWidth: 1) "
                                                    *ngFor="let col of allData.columns; let colIndex=index" fxFlex
                                                    [prop]="col.prop" [name]="col.title">
                                                    <ng-template let-value="value" class="happ-dummy"
                                                        let-rowIndex="rowIndex" let-row="row" style="color: blue;"
                                                        ngx-datatable-cell-template>
                                                        <p *ngIf="(value || value === 0)? true : false"
                                                            class="happ-full-area"
                                                            [ngClass]="{'happ-cell-summary': (value || value === 0)? true : false}">
                                                            {{ 'dttable.sum' | translate }} {{value}} </p>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-footer>
                                                    <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                                        class="happ-hide-footer" let-pageSize="pageSize"
                                                        let-selectedCount="selectedCount" let-curPage="curPage"
                                                        let-offset="offset">
                                                    </ng-template>
                                                </ngx-datatable-footer>
                                            </ngx-datatable>
                                        </div>
                                    </div>

                                    <div style="height: calc(100vh - 500px);" fxLayout="column"
                                        fxLayoutAlign="center center" *ngIf="rows && rows.length === 0 && allData && allData.columns &&allData.columns.length !== 1">
                                        <h1> {{ 'dttable.empty_message' | translate }} </h1>
                                    </div>

                                    <div style="height: calc(100vh - 500px);" fxLayout="column"
                                        fxLayoutAlign="center center" *ngIf="allData && allData.columns && allData.columns.length === 1">
                                        <h1> {{ 'dttable.no_columns_selected' | translate }} </h1>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <app-side-menu-edit></app-side-menu-edit>
            </div>
            <!-- / CONTENT -->
        </div>
        <!-- / CENTER -->
    </div>
</ng-template>