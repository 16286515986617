// tslint:disable:indent
import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import * as _ from 'lodash';

export interface DataTableState {
    applicationType: string;
    regId: string;
    officeId: string;
    recordList: any[];
}

const localOfficeId = (field: string) => {
    try {
        if (localStorage.getItem('ngStorage-selectedOffice') && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))) {
            return JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))[field];
        }
        return null;
    } catch (error) {
        throw error;
    }
};

export function createInitialState(): DataTableState {
	try {
		return {
            applicationType: 'record',
            regId: null,
            officeId: localOfficeId('_id'),
            recordList: []
		};
	} catch (error) {
		console.error(error);
		return {
			applicationType: 'record',
            regId: null,
            officeId: null,
            recordList: []
		};
	}
}

@Injectable()
@StoreConfig({ name: 'datatable', resettable: true })
export class DataTableStore extends Store<DataTableState> {

	constructor() {
		super(createInitialState());
	}

}
