<div class="px-16 py-8" [fxLayout]="layout" style="border: 1px solid #e8e3e3;">
  <div fxFlex="72" fxLayout="column">
    <p class="font-size-16 font-weight-700 mx-0 my-4">{{ heading | translate }}</p>
    <p class="m-0"> {{ description | translate }} </p>
    <p *ngIf="value" class="m-0"><strong> {{ value | translate }} </strong></p>
  </div>
  <span *ngIf="layout === 'row'" fxFlex></span>
  <div [style.margin]="(layout === 'row') ? 'auto' : 'initial'">
    <ng-content></ng-content>
  </div>
</div>