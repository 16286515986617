<div class="w-100-p h-100-p" fxLayout="column" fxLayoutAlign="start center">
  <h2 style="font-size: 36px; color: green; margin-bottom: 0px;">
    {{ 'global.pending_payment' | translate }}
  </h2>
  <mat-icon style="font-size: 100px; height: 100px; width: 100px; margin-bottom: 20px;">info</mat-icon>
  <p>
    {{ 'global.you_have_unpaid_bill' | translate }}
  </p>
  <p>
    {{ 'global.your_actual_credit_balance_is' | translate }} <strong>{{ (data && data.officeDoc && data.officeDoc.totalAmount) }}</strong>
  </p>
  <p>
    {{ 'global.your_debt_balance_is' | translate }} <strong>{{ (data && data.officeDoc && data.officeDoc.ussageBalance) }} </strong> {{ 'global.credits' | translate }}
  </p>
  <br>
  <p>
    {{ 'global.full_your_credit_balance_to_remove_your_debts' | translate }}
  </p>
  <br>
  <p>
    {{ 'global.you_reached_negative_balance_at' | translate }} <strong>{{ (data && data.officeDoc && data.officeDoc.lastPaymentDate) }}</strong>
  </p>
  <p>
    {{ 'global.you_are' | translate }} {{ dayDiff }} {{ 'global.days_after_pay_time' | translate }}
  </p>
  <p *ngIf="selectedConf && selectedConf.message">
    {{ ('global.' + selectedConf.message) | translate }}
  </p>
  <p *ngIf="timer">
    Closing in {{ timer }}
  </p>
  <br>
  <ng-container *ngIf="!timer">
    <div fxLayout="row">
      <button mat-raised-button class="mr-8" (click)="close()" >
        {{ 'global.got_it' | translate }}
      </button>
      <button mat-raised-button (click)="addCredit()" >
        {{ 'global.add_credit' | translate }}
      </button>
    </div>
  </ng-container>
</div>