/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./html-editor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/ngx-ckeditor/ngx-ckeditor.ngfactory";
import * as i3 from "ngx-ckeditor";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@angular/flex-layout/flex";
import * as i8 from "@angular/flex-layout/core";
import * as i9 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i10 from "@angular/material/button";
import * as i11 from "@angular/cdk/platform";
import * as i12 from "@angular/cdk/a11y";
import * as i13 from "@angular/platform-browser/animations";
import * as i14 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i15 from "@angular/material/icon";
import * as i16 from "../../../../../@fuse/pipes/htmlWithStyle.pipe";
import * as i17 from "@angular/platform-browser";
import * as i18 from "./html-editor.component";
var styles_HtmlEditorComponent = [i0.styles];
var RenderType_HtmlEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HtmlEditorComponent, data: {} });
export { RenderType_HtmlEditorComponent as RenderType_HtmlEditorComponent };
function View_HtmlEditorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ck-editor", [["language", "en"], ["name", "editor1"], ["skin", "moono-lisa"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onValueChange() !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = ((_co.model = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_CKEditorComponent_0, i2.RenderType_CKEditorComponent)), i1.ɵdid(1, 9158656, null, 0, i3.CKEditorComponent, [i1.NgZone, i1.ElementRef], { config: [0, "config"], skin: [1, "skin"], language: [2, "language"], fullPage: [3, "fullPage"] }, { change: "change" }), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.CKEditorComponent]), i1.ɵdid(3, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(5, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.config; var currVal_8 = "moono-lisa"; var currVal_9 = "en"; var currVal_10 = true; _ck(_v, 1, 0, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = "editor1"; var currVal_12 = _co.model; _ck(_v, 3, 0, currVal_11, currVal_12); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_HtmlEditorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ck-editor", [["language", "en"], ["name", "editor1"], ["skin", "moono-lisa"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onValueChange() !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = ((_co.model = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_CKEditorComponent_0, i2.RenderType_CKEditorComponent)), i1.ɵdid(1, 9158656, null, 0, i3.CKEditorComponent, [i1.NgZone, i1.ElementRef], { skin: [0, "skin"], language: [1, "language"], fullPage: [2, "fullPage"] }, { change: "change" }), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.CKEditorComponent]), i1.ɵdid(3, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(5, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "moono-lisa"; var currVal_8 = "en"; var currVal_9 = true; _ck(_v, 1, 0, currVal_7, currVal_8, currVal_9); var currVal_10 = "editor1"; var currVal_11 = _co.model; _ck(_v, 3, 0, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_HtmlEditorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HtmlEditorComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HtmlEditorComponent_3)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.all; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.all; _ck(_v, 4, 0, currVal_1); }, null); }
function View_HtmlEditorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "happ-placeholder"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.title)); _ck(_v, 1, 0, currVal_0); }); }
function View_HtmlEditorComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "happ-html-preview-area"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.defaultEditMode = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent, 0), _co.model)); _ck(_v, 0, 0, currVal_0); }); }
function View_HtmlEditorComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "w-100-p"], ["fxLayout", "row"], ["style", "border: 1px solid #e0e0e0; border-bottom: 0px;"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i7.DefaultLayoutDirective, [i1.ElementRef, i8.StyleUtils, [2, i7.LayoutStyleBuilder], i8.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [["class", "m-0 ml-8 font-weight-700"], ["style", "margin-top: 10px !important;"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["fxFlex", ""]], null, null, null, null, null)), i1.ɵdid(7, 671744, null, 0, i7.DefaultFlexDirective, [i1.ElementRef, i8.StyleUtils, i8.LAYOUT_CONFIG, i7.FlexStyleBuilder, i8.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 13, "div", [["fxLayout", "row"]], null, null, null, null, null)), i1.ɵdid(9, 671744, null, 0, i7.DefaultLayoutDirective, [i1.ElementRef, i8.StyleUtils, [2, i7.LayoutStyleBuilder], i8.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [["style", "border-left: 1px solid #e0e0e0;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "button", [["color", "warn"], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeEditor() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MatButton_0, i9.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i10.MatButton, [i1.ElementRef, i11.Platform, i12.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(13, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i14.View_MatIcon_0, i14.RenderType_MatIcon)), i1.ɵdid(14, 9158656, null, 0, i15.MatIcon, [i1.ElementRef, i15.MatIconRegistry, [8, null], [2, i15.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(16, 0, null, null, 5, "div", [["style", "border-left: 1px solid #e0e0e0;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 4, "button", [["class", "happ-square-button"], ["mat-icon-button", ""], ["style", "background: lightgreen;"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveData() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MatButton_0, i9.RenderType_MatButton)), i1.ɵdid(18, 180224, null, 0, i10.MatButton, [i1.ElementRef, i11.Platform, i12.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(19, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i14.View_MatIcon_0, i14.RenderType_MatIcon)), i1.ɵdid(20, 9158656, null, 0, i15.MatIcon, [i1.ElementRef, i15.MatIconRegistry, [8, null], [2, i15.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["check"]))], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_2 = ""; _ck(_v, 7, 0, currVal_2); var currVal_3 = "row"; _ck(_v, 9, 0, currVal_3); var currVal_6 = "warn"; _ck(_v, 12, 0, currVal_6); _ck(_v, 14, 0); _ck(_v, 20, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.title)); _ck(_v, 4, 0, currVal_1); var currVal_4 = (i1.ɵnov(_v, 12).disabled || null); var currVal_5 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_4, currVal_5); var currVal_7 = i1.ɵnov(_v, 14).inline; var currVal_8 = (((i1.ɵnov(_v, 14).color !== "primary") && (i1.ɵnov(_v, 14).color !== "accent")) && (i1.ɵnov(_v, 14).color !== "warn")); _ck(_v, 13, 0, currVal_7, currVal_8); var currVal_9 = (i1.ɵnov(_v, 18).disabled || null); var currVal_10 = (i1.ɵnov(_v, 18)._animationMode === "NoopAnimations"); _ck(_v, 17, 0, currVal_9, currVal_10); var currVal_11 = i1.ɵnov(_v, 20).inline; var currVal_12 = (((i1.ɵnov(_v, 20).color !== "primary") && (i1.ɵnov(_v, 20).color !== "accent")) && (i1.ɵnov(_v, 20).color !== "warn")); _ck(_v, 19, 0, currVal_11, currVal_12); }); }
function View_HtmlEditorComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ck-editor", [["class", "w-100-p"], ["language", "en"], ["name", "editor1"], ["skin", "moono-lisa"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onValueChange() !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = ((_co.model = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_CKEditorComponent_0, i2.RenderType_CKEditorComponent)), i1.ɵdid(1, 9158656, null, 0, i3.CKEditorComponent, [i1.NgZone, i1.ElementRef], { config: [0, "config"], skin: [1, "skin"], language: [2, "language"], fullPage: [3, "fullPage"] }, { change: "change" }), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.CKEditorComponent]), i1.ɵdid(3, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(5, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.config; var currVal_8 = "moono-lisa"; var currVal_9 = "en"; var currVal_10 = true; _ck(_v, 1, 0, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = "editor1"; var currVal_12 = _co.model; _ck(_v, 3, 0, currVal_11, currVal_12); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_HtmlEditorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["style", "position: relative;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HtmlEditorComponent_5)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HtmlEditorComponent_6)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HtmlEditorComponent_7)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HtmlEditorComponent_8)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.defaultEditMode; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.defaultEditMode; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.defaultEditMode; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.defaultEditMode; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_HtmlEditorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i16.HtmlWithStylePipe, [i17.DomSanitizer]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HtmlEditorComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HtmlEditorComponent_4)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isControlType; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isControlType; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_HtmlEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-html-editor", [], null, null, null, View_HtmlEditorComponent_0, RenderType_HtmlEditorComponent)), i1.ɵdid(1, 114688, null, 0, i18.HtmlEditorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HtmlEditorComponentNgFactory = i1.ɵccf("app-html-editor", i18.HtmlEditorComponent, View_HtmlEditorComponent_Host_0, { model: "model", height: "height", all: "all", isControlType: "isControlType", defaultEditMode: "defaultEditMode", title: "title", type: "type" }, { modelChange: "modelChange", saveChange: "saveChange" }, []);
export { HtmlEditorComponentNgFactory as HtmlEditorComponentNgFactory };
