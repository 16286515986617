import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { DescriptionPopupComponent } from './description-popup.component';

@NgModule({
    declarations: [
      DescriptionPopupComponent
    ],
    imports     : [
       FuseSharedModule
    ],
    exports: [
        DescriptionPopupComponent
    ],
    entryComponents: [
        DescriptionPopupComponent
    ]
})

export class DescriptionPopupModule { }
