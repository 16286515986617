<ng-container *ngIf="settings">
    <mat-accordion>
        <mat-expansion-panel *ngFor="let section of settings.settings; let i=index" [expanded]="i === 0">
          <mat-expansion-panel-header style="background: #f6f6f6;">
            <mat-panel-title>
              {{ section.title | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="pt-12" >
            <ng-container *ngFor="let setting of section.children" >
              <ng-container [ngSwitch]="setting.type">
                <app-tab-settings *ngSwitchCase="'tab'" [settings]="setting" ></app-tab-settings>
                <app-section-settings *ngSwitchCase="'section'" [settings]="setting" ></app-section-settings>
                <app-field-settings *ngSwitchCase="'field'" [settings]="setting" ></app-field-settings>
              </ng-container>
            </ng-container>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
  </ng-container>