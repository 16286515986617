<mat-card *ngIf="recData">
  <div *ngIf="recData">
    <p class="happ-section-heading">
      {{ 'ct.case_service_info' | translate }}
    </p>
    <div fxLayout="column">
      <table >
        <tr>
          <td style="width: 30%; font-size: 28px;" fxHide-lt-md> {{ 'ct.case_signature' | translate }} </td>
          <td style="width: 70%; color: gray; font-size: 28px;">
            {{ recData.parentCaseId }}
          </td>
        </tr>
        <tr>
          <td style="width: 30%; font-size: 28px;" fxHide-lt-md> {{ 'ct.event_name' | translate }} </td>
          <td style="width: 70%; color: gray; font-size: 28px;">
            {{ recData.serviceName }}
          </td>
        </tr>
      </table>
    </div>
  </div>
  <br>
  <div fxLayout="column" fxLayout.lt-md="column">
    <p class="happ-section-heading">
      {{ 'ct.event_info' | translate }}
    </p>
    <table >
      <tr>
        <td style="width: 30%; font-size: 28px;" fxHide-lt-md> {{ 'ct.expireDateTime' | translate }} </td>
        <td style="width: 70%; color: gray; font-size: 28px;">
          {{ recData.expireDateTime | date:"yyyy-MM-dd HH:mm:ss" }}
        </td>
      </tr>
      <tr>
        <td style="width: 30%; font-size: 28px;" fxHide-lt-md> {{ 'ct.eventTime' | translate }} </td>
        <td style="width: 70%; color: gray; font-size: 28px;">
          {{ recData.eventTime }}
        </td>
      </tr>
    </table>
  </div>
  <br>
  <br>
  <div>
    <div fxLayout="row" fxLayoutAlign="center center" class="mb-12">
      <p class="happ-section-heading m-0">
        {{ 'ct.select_proposals' | translate }}
      </p>
      <span fxFlex></span>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="10px">
      <div class="happ-list-item" fxFlex="32" fxFlex.lt-md="48" fxLayoutAlign="center center" fxLayout="row" *ngFor="let item of proposals; let i=index">
        <div fxLayout="row" class="w-100-p">
          <div class="w-50-p" fxLayoutAlign="center center" fxLayout="column">
            <img src="https://cdn4.vectorstock.com/i/thumb-large/23/28/time-check-clock-logo-icon-vector-36212328.jpg" alt="" style="width: 100px; height: 100px; border-radius: 50%;">
            <div>
              <p>
                {{ 'ct.selectedStartDate' | translate }} - <strong> {{ item.startDateTime  | date:"yyyy-MM-dd HH:mm:ss" }} </strong>
              </p>
              <p>
                {{ 'ct.selectedEndDate' | translate }} - <strong> {{ item.endDateTime | date:"yyyy-MM-dd HH:mm:ss" }} </strong>
              </p>
              <p>
                {{ 'ct.expireDateTime' | translate }} - <strong> {{ item.expireDateTime | date:"yyyy-MM-dd HH:mm:ss" }} </strong>
              </p>
            </div>
          </div>
          <div class="w-50-p" fxLayoutAlign="space-between center" fxLayout="column">
            <div>
              <p>
                 <strong> {{ item.subject }} </strong>
              </p>
              <p>
                <strong> {{ item.caseSignature }} </strong>
              </p>
            </div>
            <div fxLayout="row">
              <div>
               
                <button mat-raised-button [color]="(i===selectedProposal) ? 'primary' : ''" (click)="selectProposal(i)" >
                  {{ 'ct.select_proposal' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!editNg5Service.loading" class="happ-action-header">
    <div *ngIf="staticProcessStatus">
        <div class="my-8"
            *ngIf="!staticProcessStatus.hide && editNg5Service.error && editNg5Service.error.length === 0">
            <app-process-status [(model)]="editNg5Service.formData"
                (updateRecord)="processUpdateRecordTrigger($event)" [field]="staticProcessStatus">
            </app-process-status>
        </div>
        <div fxLayoutAlign="center center" *ngIf="editNg5Service.error && editNg5Service.error.length > 0">
            <h3> {{ 'ONE_REC.REMOVE_ALL_ERRORS' | translate }} </h3>
        </div>
    </div>
  </div>
</mat-card>