<div fxLayout="row wrap" fxLayoutGap="15px" *ngIf="(field.defaultEdit || errorMessage) || !field.showEdit">
    <app-date-time (onError)="checkError($event)" [builder]="true" [field]="field" [(model)]="start"></app-date-time>
    <app-date-time (onError)="checkError($event)" [builder]="true" [field]="field" [(model)]="end"></app-date-time>
</div>
<div fxLayout="row wrap" fxLayoutGap="8px" *ngIf="(!field.defaultEdit && !errorMessage && field.showEdit)" (click)="field.defaultEdit=!field.defaultEdit">
    <button class="mr-4" mat-icon-button>
        <mat-icon>edit</mat-icon>
    </button>
    <p class="font-size-18 mt-12">
        {{ (start) | date: 'yyyy-MM-dd HH:mm' }}
    </p>
    <p class="font-size-18 mt-12"> ~ </p>
    <p class="font-size-18 mt-12">
        {{ (end) | date: 'yyyy-MM-dd HH:mm' }}
    </p>
</div>