import { TemplateListComponent } from './template-list.component';
import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
    imports     : [
        FuseSharedModule,
        // GlobalModule,
        NgxDatatableModule
    ],
    declarations: [
        TemplateListComponent
    ],
    providers   : [
    ],
    exports: [
        TemplateListComponent
    ],
    entryComponents: [
        TemplateListComponent
    ]
})
export class TemplateListModule
{
}
