export const TaskDescriptions = {
    'gdpr-persona-approvalRequest': 'description of gdpr-persona-approvalRequest',
    'gdpr-persona-appeal-request': 'description of gdpr-persona-appeal-request',
    'gdpr-persona-infoProcessing': 'description of gdpr-persona-infoProcessing',
    'calendar-create-event': 'description of calendar-create-event',
    'cron_task': 'description of cron_task',
    'send_email': 'description of send_email',
    'generate_record_report': 'description of generate_record_report',
    'generate-record-report': 'description of generate',
    'invite-user-to-office': 'description of invite',
    'send-message-back-to-parent': 'description of send',
    'invite-user-platfrom-office': 'description of invite',
    'user_invitation': 'description of user_invitation',
    'check_user_in_profile': 'description of check_user_in_profile',
    'sms_one_way': 'description of sms_one_way',
    'create_user_profile': 'description of create_user_profile',
    'invite_instance_to_profile': 'description of invite_instance_to_profile',
    'invitation_response_trigger': 'description of invitation_response_trigger',
    'invite_user_to_workspace': 'description of invite_user_to_workspace',
    'invite_user_to_office': 'description of invite_user_to_office',
    'send_notification': 'description of send_notification',
    'messaging_jabber': 'description of messaging_jabber',
    'doller_calc_value': 'description of doller_calc_value',
    'register-record-report': 'description of register',
    'install_office_plugin': 'description of install_office_plugin',
    'create_personal_office': 'description of create_personal_office',
    'uninstall_office_plugin': 'description of uninstall_office_plugin',
    'register_rec_validation': 'description of register_rec_validation',
    'update_register_record': 'description of update_register_record ',
    'remove_invitation_from_profile': 'In this task we are removing invitation instance from profile which is present in keyName invitedPersonEmail',
    'socket_check_profile_update': 'In this task we are triggering frontend to update invitation count for profile which is present in keyName invitedPersonEmail',
    'create_merge_request': 'Create merge request',
    'create_branch': 'Create branch',
    'move_position_child_records': 'Move Position Child Record to an activity',
    'remove_cron_task': 'Remove Cron task using the filter by Filter String'
};
