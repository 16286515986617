<div style="position: relative;" fxLayout="column" *ngIf="!field.hide || builder">

    <div fxLayout="row">
        <mat-form-field color="accent" [ngClass]="{'w-100-p': true, 'happ-small-form-field-outline': field.smallField}" floatLabel="always" appearance="outline" fxFlex>
            <mat-label>{{field.title | translate}}</mat-label>
            <input type="{{field.inputType}}" (paste)="pasteIt($event)" matInput placeholder="{{field.title | translate}}" [disabled]="field.showdisablecontroltype=='true' || field.disable" (keyup)="onKeyPress()" [hidden]="field.hidden" [(ngModel)]="model">
            <div class="error-message" *ngIf="errorMessage">
                {{ errorMessage | translate }}
            </div>
        </mat-form-field>
    </div>
    <div class="happ-field-description" *ngIf="field.allow_description && field.description"> {{ field.description }} </div>
</div>