<div class="page-layout carded fullwidth single-scroll" fusePerfectScrollbar>

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- CONTENT HEADER -->
        <div class="header white-fg p-24" fxLayout="row" fxLayoutAlign="start center">
            <h2>{{ 'profile.profile' | translate }}</h2>

            <!-- <breadcrumb></breadcrumb> -->
            <span fxFlex></span>
            <div>
                <button mat-raised-button color="warn" (click)="edit=true" *ngIf="!edit">
                    {{ 'profile.edit' | translate }}
                </button>
                <button mat-raised-button color="warn" (click)="updateProfile();edit=false" *ngIf="edit">
                    {{ 'profile.save' | translate }}
                  </button>
            </div>
        </div>
        <!-- / CONTENT HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card fuse-white-bg">

            <!-- CONTENT -->
            <div class="content">

                <div id="about" class="p-12 w-100-p" fxLayout="row" fxLayoutWrap>

                    <div *ngIf="about" class="about-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="65">



                        <div class="profile-box info-box general" fxLayout="column">

                            <header class="mat-accent-bg">
                                <div class="title">{{ 'profile.general_information' | translate }}</div>
                            </header>

                            <div class="content">

                                <div class="info-line">
                                    <div class="title">{{ 'profile.name' | translate }}</div>
                                    <div *ngIf="!edit" class="info">{{about.general.name}}</div>
                                    <mat-form-field *ngIf="edit">
                                        <input matInput placeholder="{{ 'profile.name' | translate }}" [(ngModel)]="about.general.name">
                                    </mat-form-field>
                                </div>

                                <div class="info-line">
                                    <div class="title">{{ 'profile.gender' | translate }}</div>
                                    <div *ngIf="!edit" class="info">{{about.general.gender}}</div>
                                    <mat-radio-group [(ngModel)]="about.general.gender" *ngIf="edit">
                                        <mat-radio-button value="Male">{{ 'profile.male' | translate }}</mat-radio-button>
                                        <mat-radio-button value="Female">{{ 'profile.female' | translate }}</mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <div class="info-line">
                                    <div class="title">{{ 'profile.birthday' | translate }}</div>
                                    <div *ngIf="!edit" class="info">{{about.general.birthday | date: 'yyyy-MM-dd HH:mm:ss'}}</div>
                                    <mat-form-field *ngIf="edit">
                                        <input matInput (dateChange)="dateChange($event)" [matDatepicker]="picker" placeholder="{{ 'profile.birthday' | translate }}">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>

                                <div class="info-line">
                                    <div class="title">{{ 'profile.locations' | translate }}</div>
                                    <div *ngIf="!edit" class="info location" fxLayout="row" fxLayoutAlign="start center">
                                        <span>{{about.general.locations}}</span>
                                        <mat-icon class="s-16 ml-4">location_on</mat-icon>
                                    </div>
                                    <mat-form-field *ngIf="edit">
                                        <input matInput placeholder="{{ 'profile.locations' | translate }}" [(ngModel)]="about.general.locations">
                                    </mat-form-field>
                                </div>

                                <div class="info-line">
                                    <div class="title">{{ 'profile.about_me' | translate }}</div>
                                    <div *ngIf="!edit" class="info">{{about.general.about}}</div>
                                    <mat-form-field *ngIf="edit">
                                        <input matInput placeholder="{{ 'profile.about_me' | translate }}" [(ngModel)]="about.general.about">
                                    </mat-form-field>
                                </div>
                            </div>

                        </div>

                        <div class="profile-box info-box work" fxLayout="column">

                            <header class="mat-accent-bg">
                                <div class="title">{{ 'profile.work' | translate }}</div>
                            </header>

                            <div class="content">
                                <div class="info-line">
                                    <div class="title">{{ 'profile.occupation' | translate }}</div>
                                    <div *ngIf="!edit" class="info">{{about.work.occupation}}</div>
                                    <mat-form-field *ngIf="edit">
                                        <input matInput placeholder="{{ 'profile.occupation' | translate }}" [(ngModel)]="about.work.occupation">
                                    </mat-form-field>
                                </div>

                                <div class="info-line">
                                    <div class="title">{{ 'profile.skills' | translate }}</div>
                                    <div *ngIf="!edit" class="info">{{about.work.skills}}</div>
                                    <mat-form-field *ngIf="edit">
                                        <input matInput placeholder="{{ 'profile.skills' | translate }}" [(ngModel)]="about.work.skills">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="profile-box info-box contact" fxLayout="column">

                            <header class="mat-accent-bg">
                                <div class="title">{{ 'profile.contact' | translate }}</div>
                            </header>

                            <div class="content">
                                <div class="info-line">
                                    <div class="title">{{ 'profile.address' | translate }}</div>
                                    <div *ngIf="!edit" class="info">{{about.contact.address}}</div>
                                    <mat-form-field *ngIf="edit">
                                        <input matInput placeholder="{{ 'profile.address' | translate }}" [(ngModel)]="about.general.about">
                                    </mat-form-field>
                                </div>
                                <div class="info-line">
                                    <div class="title">{{ 'profile.tel' | translate }}</div>
                                    <div *ngIf="!edit">
                                        <div class="info" *ngFor="let tel of about.contact.tel">
                                            <span>{{tel}}</span>
                                        </div>
                                    </div>
                                    <mat-form-field *ngIf="edit">
                                        <input matInput placeholder="{{ 'profile.tel' | translate }}" [(ngModel)]="telNo">
                                    </mat-form-field>
                                </div>
                                <div class="info-line">
                                    <div class="title">{{ 'profile.websites' | translate }}</div>
                                    <div *ngIf="!edit">
                                        <div class="info" *ngFor="let website of about.contact.websites">
                                            <span>{{website}}</span>
                                        </div>
                                    </div>
                                    <mat-form-field *ngIf="edit">
                                        <input matInput placeholder="{{ 'profile.websites' | translate }" [(ngModel)]="websites">
                                    </mat-form-field>
                                </div>
                                <div class="info-line">
                                    <div class="title">{{ 'profile.emails' | translate }}</div>
                                    <div *ngIf="!edit">
                                        <div class="info" *ngFor="let email of about.contact.emails">
                                            <span>{{email}}</span>
                                        </div>
                                    </div>
                                    <mat-form-field *ngIf="edit">
                                        <input matInput placeholder="{{ 'profile.emails' | translate }" [(ngModel)]="emails">
                                    </mat-form-field>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="about-sidebar" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="35">

          <div class="profile-box friends" fxLayout="column">

              <header class="mat-accent-bg" fxLayout="row" fxLayoutAlign="space-between center">
                  <div class="title">Gitlab Auth Token</div>
              </header>

              <div class="content" fxLayout="column" fxLayoutWrap>
                    <mat-form-field>
                        <input matInput [(ngModel)]="gitAuthToken" placeholder="Auth Token" type="password">
                    </mat-form-field>
              </div>
              <div class="p-12" fxLayout="row">
                    <span fxFlex></span>
                    <div>
                        <button *ngIf="!gitTokenId" mat-raised-button color="warn" (click)="saveGitToken()">Save</button>
                        <button *ngIf="gitTokenId" mat-raised-button color="warn" (click)="updateGitToken()">Update</button>
                    </div>
                </div>

          </div>

      </div>

                </div>



            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>