import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
// import { DateTimeAdapter } from 'ng-pick-datetime';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent implements OnInit {
  @Input() field;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() start: any;
  @Output() startChange: EventEmitter<Object> = new EventEmitter();
  @Input() end: any;
  @Output() endChange: EventEmitter<Object> = new EventEmitter();
  @Output() onError: EventEmitter<Object> = new EventEmitter();
  
  public errorMessage = '';

  constructor() { }

  ngOnInit() {
    if (!this.start || !this.end ) {
      this.errorMessage = 'Error';
    }
  }

  checkError(err) {
    this.startChange.next(this.start);
    this.endChange.next(this.end);
    if (err && !err['error'] && this.start && this.end) {
      this.errorMessage = '';
    } else {
      this.errorMessage = 'Error';
    }
  }
}
