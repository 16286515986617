import { Component, OnInit, Inject } from '@angular/core';
import { HelpersService } from '../../services/helpers/helpers.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IRegister } from '../../interfaces/register.interface';
import * as _ from 'lodash';

@Component({
  selector: 'app-register-selection-view',
  templateUrl: './register-selection-view.component.html',
  styleUrls: ['./register-selection-view.component.scss']
})
export class RegisterSelectionViewComponent implements OnInit {

  // public variables
  public registerList: Array<IRegister>;
  public filteredRegisterList: Array<IRegister>;
  public selectedValues: Array<string> = [];
  public searchRight = '';
  public searchLeft = '';

  constructor(
    private helperService: HelpersService,
    public dialogRef: MatDialogRef<RegisterSelectionViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit() {
    this.performInitOperation();
    this.getRegisterList();
  }

  private performInitOperation() {
    this.data.selectedRegValues = this.data.selectedRegValues || [];
    this.selectedValues = this.data.selectedRegValues.map((ele: any) => ele._id);
    this.data.moveData = this.data.moveData || ['_id', 'name'];
  }

  public saveData() {
    this.dialogRef.close(this.data.selectedRegValues);
  }

  private getRegisterList() {
    this.helperService.getSmallRegisterList()
    .then((data) => {
      this.registerList = data || [];
      this.filterOutRegisters();
    }).catch(err => {
      this.helperService.showInfo(err, 'Error', 'error');
    });
  }

  private filterOutRegisters() {
    this.filteredRegisterList = this.registerList.filter((ele) => {
      if (ele && ele._id && this.selectedValues.includes(ele._id)) {
        return false;
      }
      return true;
    });
  }

  public addRegisterToList(regData: IRegister) {
    const temp: any = {};

    this.data.moveData.forEach((ele: any) => {
      temp[ele] = regData[ele];
    });
    this.data.selectedRegValues.push(temp);
    this.selectedValues.push(regData._id);
    this.filterOutRegisters();
  }

  public removeRegisterFromList(regData: IRegister) {
    const index = _.findIndex(this.data.selectedRegValues, { _id: regData._id });
    if (index >= 0) {
      this.data.selectedRegValues.splice(index, 1);
      this.selectedValues.splice(this.selectedValues.indexOf(regData._id), 1);
      this.filterOutRegisters();
    }
  }

}
