import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { DatatableModule } from '../../../global-component/datatable/datatable.module';

import { FormBuilderModalModule } from '../../../global-component/form-builder-modal/form-builder-modal.module';
import { EditFormPopupComponent } from './edit-form-popup.component';
import { EditNg5Service } from 'app/main/content/global-component/one-recordview/edit-ng5/edit-ng5.service';
import { FormComponentModule } from 'app/main/content/global-component/form-component/form-component.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FormComponentService } from 'app/main/content/global-component/form-component/form-component.service';
 

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        // DatatableModule,
        // FormBuilderModalModule,
        FormComponentModule,
        

    ],
    declarations: [
        EditFormPopupComponent
    ],
    providers: [
        // EditNg5Service,
        FormComponentService
    ],
    exports: [
        EditFormPopupComponent
    ],
    entryComponents: [
        EditFormPopupComponent
    ]
})
export class EditFormPopupModule { }
