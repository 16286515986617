<div *ngIf="!field.hide || builder" class="happ-border-outline">
    <p *ngIf="!hideTitle" class="happ-field-title m-0 mb-12 happ-outline-title">{{ field.title | translate }} </p>
    <div *ngIf="!model || edit">
        <div fxLayout="row wrap" *ngIf="field.options && field.options.length !== 1" class="m-4 mb-8">
            <mat-radio-group style="margin: auto;" (change)="address=[];result=[];message=''"
                [(ngModel)]="field.deliveryAddressSelection">
                <mat-radio-button *ngFor="let option of field.options | filter:true:'show'" class="mr-12"
                    [value]="option.value"> {{ option.title | translate }} </mat-radio-button>
            </mat-radio-group>
            <span fxFlex></span>
            <div>
                <button mat-icon-button (click)="edit=false">
                    <mat-icon>check</mat-icon>
                </button>
            </div>
        </div>
        <div class="w-100-p" fxLayout="row wrap">
            <div fxFlex *ngIf="field.deliveryAddressSelection == 'cityAndStreet'" fxLayout="column">
                <mat-form-field color="accent" [ngClass]="{'happ-small-form-field-outline': field.smallField}" floatLabel="always"
                    appearance="outline" fxFlex>
                    <mat-label> {{ 'deliveryAddress.city' | translate }} </mat-label>
                    <input matInput [disabled]="!regId" placeholder="{{ 'deliveryAddress.city' | translate }}"
                        [(ngModel)]="city">
                </mat-form-field>
                <mat-form-field color="accent" [ngClass]="{'happ-small-form-field-outline': field.smallField}" floatLabel="always"
                    appearance="outline" fxFlex>
                    <mat-label> {{ 'deliveryAddress.street' | translate }} </mat-label>
                    <input matInput [disabled]="!regId" (keydown.Tab)="search($event)"
                        placeholder="{{ 'deliveryAddress.street' | translate }}" [(ngModel)]="street">
                </mat-form-field>
            </div>
            <div fxFlex *ngIf="field.deliveryAddressSelection == 'searchNip'">
                <mat-form-field color="accent" [ngClass]="{'happ-small-form-field-outline': field.smallField}" floatLabel="always"
                    appearance="outline" fxFlex>
                    <mat-label> {{ 'deliveryAddress.nipNumber' | translate }} </mat-label>
                    <input matInput [disabled]="!regId" #nipVal (keydown)="validateNip($event, nipVal)"
                        (keydown.Tab)="search($event)" placeholder="{{ 'deliveryAddress.nipNumber' | translate }}"
                        [(ngModel)]="nip">
                </mat-form-field>
            </div>
            <div fxFlex *ngIf="field.deliveryAddressSelection == 'searchMultiFileds'">
                <mat-form-field color="accent" [ngClass]="{'happ-small-form-field-outline': field.smallField }" floatLabel="always"
                    appearance="outline" fxFlex>
                    <mat-label> {{ 'deliveryAddress.companyName' | translate }} </mat-label>
                    <input matInput [disabled]="!regId" (keydown.Tab)="search($event)"
                        placeholder="{{ 'deliveryAddress.companyName' | translate }}" [(ngModel)]="searchMultiFileds">
                </mat-form-field>
            </div>
            <div fxFlex fxLayout="row" *ngIf="field.deliveryAddressSelection == 'autoSearchMultiFields'">
                <mat-form-field color="accent"
                    [ngClass]="{'happ-small-form-field-outline': field.smallField, 'mr-16': (field.options && field.options.length != 1) }"
                    floatLabel="always" appearance="outline" fxFlex>
                    <input matInput [disabled]="!regId" (input)="autocompleteSearch($event)"
                        placeholder="{{ 'deliveryAddress.search' | translate }}" [matAutocomplete]="auto">
                    <mat-autocomplete (optionSelected)="selectOption($event)" #auto="matAutocomplete">
                        <mat-option *ngFor="let option of autoCompleteList" [value]="option"> {{option.allAddress}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <div *ngIf="field.options && field.options.length == 1">
                    <button mat-icon-button (click)="edit=false">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
            <div fxFlex *ngIf="field.deliveryAddressSelection == 'ext_nip_no'">
                <mat-form-field color="accent" [ngClass]="{'happ-small-form-field-outline': field.smallField}" floatLabel="always"
                    appearance="outline" fxFlex>
                    <mat-label> {{ 'deliveryAddress.externalNip' | translate }} </mat-label>
                    <input matInput [disabled]="!regId" (keydown.Tab)="search($event)"
                        placeholder="{{ 'deliveryAddress.externalNip' | translate }}" [(ngModel)]="ext_nip_no">
                </mat-form-field>
            </div>
            <div class="mr-12 w-100-p" *ngIf="field.deliveryAddressSelection == 'manualDeliveryAddress'">
                <div *ngIf="field.showFourFields">
                    <div fxLayoutGap="5px" fxLayout="row wrap">
                        <mat-form-field color="accent" aria-label="companyName" fxFlex.lt-sm="100" [ngClass]="{'happ-small-form-field-outline': field.smallField}"
                            floatLabel="always" appearance="outline" fxFlex>
                            <mat-label> {{ 'deliveryAddress.companyName' | translate }} </mat-label>
                            <input matInput (keyup)="onKeyPress('manual')" [disabled]="!regId"
                                placeholder="{{ 'deliveryAddress.companyName' | translate }}"
                                [(ngModel)]="manualData.companyName">
                        </mat-form-field>
                        <mat-form-field color="accent" aria-label="companyAdress" fxFlex.lt-sm="100" [ngClass]="{'happ-small-form-field-outline': field.smallField}"
                            floatLabel="always" appearance="outline" fxFlex>
                            <mat-label> {{ 'deliveryAddress.companyAdress' | translate }} </mat-label>
                            <input matInput (keyup)="onKeyPress('manual')" [disabled]="!regId"
                                placeholder="{{ 'deliveryAddress.companyAdress' | translate }}"
                                [(ngModel)]="manualData.companyAdress">
                        </mat-form-field>
                    </div>
                    <div fxLayoutGap="5px" fxLayout="row wrap">
                        <mat-form-field color="accent" aria-label="companyCity" fxFlex.lt-sm="100" [ngClass]="{'happ-small-form-field-outline': field.smallField}"
                            floatLabel="always" appearance="outline" fxFlex>
                            <mat-label> {{ 'deliveryAddress.companyCity' | translate }} </mat-label>
                            <input matInput (keyup)="onKeyPress('manual')" [disabled]="!regId"
                                placeholder="{{ 'deliveryAddress.companyCity' | translate }}"
                                [(ngModel)]="manualData.companyCity">
                        </mat-form-field>
                        <mat-form-field color="accent" aria-label="companyCity" fxFlex.lt-sm="100" [ngClass]="{'happ-small-form-field-outline': field.smallField}"
                            floatLabel="always" appearance="outline" fxFlex>
                            <mat-label> {{ 'deliveryAddress.postalcode' | translate }} </mat-label>
                            <input matInput (keyup)="onKeyPress('manual')" [disabled]="!regId"
                                placeholder="{{ 'deliveryAddress.postalcode' | translate }}"
                                [(ngModel)]="manualData.postalcode">
                        </mat-form-field>
                    </div>
                    <div fxLayoutGap="5px" fxLayout="row wrap">
                        <mat-form-field *ngIf="field.showNip" color="accent" aria-label="nip" fxFlex.lt-sm="100" [ngClass]="{'happ-small-form-field-outline': field.smallField}"
                                floatLabel="always" appearance="outline" fxFlex>
                            <mat-label> {{ 'deliveryAddress.nip' | translate }} </mat-label>
                            <input matInput (keyup)="onKeyPress('manual')" [disabled]="!regId"
                                placeholder="{{ 'deliveryAddress.nip' | translate }}"
                                [(ngModel)]="manualData.nip">
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="!field.showFourFields">
                    <div fxLayoutGap="5px" fxLayout="row wrap" fxLayout.lt-sm="column">
                        <mat-form-field color="accent" aria-label="shortName" fxFlex="32" fxFlex.md="48" [ngClass]="{'happ-small-form-field-outline': field.smallField}"
                            floatLabel="always" appearance="outline" fxFlex>
                            <mat-label> {{ 'deliveryAddress.shortName' | translate }} </mat-label>
                            <input matInput (keyup)="onKeyPress('manual')" [disabled]="!regId"
                                placeholder="{{ 'deliveryAddress.shortName' | translate }}"
                                [(ngModel)]="manualData.shortName">
                        </mat-form-field>
                        <mat-form-field color="accent" aria-label="longName" fxFlex="32" fxFlex.md="48" [ngClass]="{'happ-small-form-field-outline': field.smallField}"
                            floatLabel="always" appearance="outline" fxFlex>
                            <mat-label> {{ 'deliveryAddress.longName' | translate }} </mat-label>
                            <input matInput (keyup)="onKeyPress('manual')" [disabled]="!regId"
                                placeholder="{{ 'deliveryAddress.longName' | translate }}"
                                [(ngModel)]="manualData.longName">
                        </mat-form-field>
                        <mat-form-field color="accent" fxFlex="32" aria-label="companyName" fxFlex.md="48" [ngClass]="{'happ-small-form-field-outline': field.smallField}"
                            floatLabel="always" appearance="outline" fxFlex>
                            <mat-label> {{ 'deliveryAddress.companyName' | translate }} </mat-label>
                            <input matInput (keyup)="onKeyPress('manual')" [disabled]="!regId"
                                placeholder="{{ 'deliveryAddress.companyName' | translate }}"
                                [(ngModel)]="manualData.companyName">
                        </mat-form-field>
                    <!-- </div>
                    <div fxLayoutGap="5px" fxLayout="row" fxLayout.lt-sm="column"> -->
                        <mat-form-field color="accent" fxFlex="32" aria-label="companyAdress" fxFlex.md="48" [ngClass]="{'happ-small-form-field-outline': field.smallField}"
                            floatLabel="always" appearance="outline" fxFlex>
                            <mat-label> {{ 'deliveryAddress.companyAdress' | translate }} </mat-label>
                            <input matInput (keyup)="onKeyPress('manual')" [disabled]="!regId"
                                placeholder="{{ 'deliveryAddress.companyAdress' | translate }}"
                                [(ngModel)]="manualData.companyAdress">
                        </mat-form-field>
                        <mat-form-field color="accent" fxFlex="32" aria-label="companyCity" fxFlex.md="48" [ngClass]="{'happ-small-form-field-outline': field.smallField}"
                            floatLabel="always" appearance="outline" fxFlex>
                            <mat-label> {{ 'deliveryAddress.companyCity' | translate }} </mat-label>
                            <input matInput (keyup)="onKeyPress('manual')" [disabled]="!regId"
                                placeholder="{{ 'deliveryAddress.companyCity' | translate }}"
                                [(ngModel)]="manualData.companyCity">
                        </mat-form-field>
                        <mat-form-field color="accent" fxFlex="32" aria-label="postalcode" fxFlex.md="48" [ngClass]="{'happ-small-form-field-outline': field.smallField}"
                            floatLabel="always" appearance="outline" fxFlex>
                            <mat-label> {{ 'deliveryAddress.postalcode' | translate }} </mat-label>
                            <input matInput (keyup)="onKeyPress('manual')" [disabled]="!regId"
                                placeholder="{{ 'deliveryAddress.postalcode' | translate }}"
                                [(ngModel)]="manualData.postalcode">
                        </mat-form-field>
                        <mat-form-field *ngIf="field.showNip" color="accent" aria-label="nip" fxFlex.lt-sm="100" [ngClass]="{'happ-small-form-field-outline': field.smallField}"
                                floatLabel="always" appearance="outline" fxFlex>
                            <mat-label> {{ 'deliveryAddress.nip' | translate }} </mat-label>
                            <input matInput (keyup)="onKeyPress('manual')" [disabled]="!regId"
                                placeholder="{{ 'deliveryAddress.nip' | translate }}"
                                [(ngModel)]="manualData.nip">
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayoutAlign="space-between center" *ngIf="field && field.saveToContact" fxLayout="row wrap">
                    <!-- <p class="ml-12 font-weight-700">
                        {{ 'deliveryAddress.save_to_contact_info' | translate }}
                    </p> -->
                    <span fxFlex></span>
                    <div class="w-100-p mb-8">
                        <button mat-raised-button class="blue-100-bg w-100-p" (click)="uploadToContact()">
                            {{ 'deliveryAddress.save_to_contact' | translate }} </button>
                    </div>
                </div>
            </div>
            <button class="mt-0"
                *ngIf="field.deliveryAddressSelection !== 'manualDeliveryAddress' && field.deliveryAddressSelection !== 'autoSearchMultiFields'"
                mat-icon-button (click)="search($event)">
                <mat-icon>search</mat-icon>
            </button>
        </div>
    </div>
    <p class="happ-outline-content" style="color: black;" fxLayout="row" (click)="edit=!edit" *ngIf="model && !edit">
        <span> {{ model }} </span>
        <span fxFlex></span>
        <button mat-icon-button (click)="edit=true">
            <mat-icon>edit</mat-icon>
        </button>
    </p>
    <p style="text-align: center;"
        *ngIf="message && (field.deliveryAddressSelection != 'manualDeliveryAddress' && !model || edit)"> {{ message }}
    </p>
    <div fxLayout="column"
        *ngIf="result && result.length > 1 && field.deliveryAddressSelection != 'autoSearchMultiFields' && !loading && (result && result.length && field.deliveryAddressSelection != 'manualDeliveryAddress' && !model || edit)">
        <div class="happ-list-field happ-left-side-list">
            <div *ngIf="result && result.length > 1" fxLayout="row" fxLayoutAlign="start center">
                <label for="search" class="mr-8">
                    <mat-icon>search</mat-icon>
                </label>
                <mat-form-field color="accent" class="happ-small-form-field-outline" floatLabel="always" appearance="outline">
                    <mat-label> {{ 'deliveryAddress.search' | translate }} </mat-label>
                    <input matInput placeholder="{{ 'deliveryAddress.search' | translate }}" [(ngModel)]="query">
                </mat-form-field>
            </div>
            <mat-list role="list" fxLayout="column">
                <mat-list-item role="listitem"
                    *ngFor="let option of result | fullTextSearch:query:['allAddress']; let i=index"
                    [style.background]="(model == option.allAddress)? 'gray': ''"
                    [style.color]="(model == option.allAddress)? 'white': ''">
                    <button mat-button fxFlex (click)="model=option.allAddress;onKeyPress(option);edit=false">
                        {{ option.allAddress }} </button>
                </mat-list-item>
            </mat-list>
        </div>
    </div>
    <div *ngIf="loading">
        <mat-spinner>
        </mat-spinner>
    </div>
</div>
<div class="error-message" *ngIf="errorMessage && error"> 
    {{ errorMessage | translate }} 
    
  </div>