import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { HelpersService } from '../../services/helpers/helpers.service';
import * as _ from 'lodash';
import { TemplateModalComponent } from '../template-modal/template-modal.component';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations

})
export class TemplateListComponent implements OnInit {

  @Input() allowSelection: boolean;
  @Input() allowMultipleSelection: boolean;
  @Input() singleTemplate: string; 
  @Output() singleTemplateChange: EventEmitter<Object> = new EventEmitter();
  @Input() multipleTemplate: any; 
  @Input() fields: any = [];
  @Output() multipleTemplateChange: EventEmitter<Object> = new EventEmitter();

  type;
  typeLists = [
    {
      title: "All",
      key: null
    },
    {
      title: "Email footer",
      key: "footer"
    },
    // {
    //   title: "All",
    //   key: null
    // },
  ]
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  rows: Array<any>;
  selectedTemplates = [];
  loadingIndicator = false;
  // tslint:disable-next-line:max-line-length
  requestData = { 'filter': {}, 'page': { 'size': 10, 'totalElements': 0, 'totalPages': 0, 'pageNumber': 0 }, 'columns': [{ 'prop': 'title', 'title': 'Title' }, { 'prop': 'createdAt', 'title': 'Date Created' }, { 'prop': 'createdById', 'title': 'Created By' }, { 'prop': 'showAsMainDocument', 'title': 'showAsMainDocument' }, { 'prop': '_id', 'title': 'Actions' }], 'sort': { 'prop': 'createdAt', 'dir': 'desc' } };
  query: string;
  constructor(
    private helperService: HelpersService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.reRenderList();
    if (this.allowSelection && this.allowMultipleSelection) {
      this.multipleTemplate = this.multipleTemplate || [];
      this.selectedTemplates = this.multipleTemplate.map((ele: any) => ele._id);
    }
  }


  deleteTemplate(template: any) {
    this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
      disableClose: false
    });
    if (template) {
      this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete Template - ' + template['title'] + '?';
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.helperService.deleteTemplate(template['_id'])
            .then(() => {
              this.reRenderList();
              this.helperService.openSnackBar('Template Deleted');
            }).catch(err => {
              this.helperService.openSnackBar(err);
            });
        }
        this.confirmDialogRef = null;
      }, err => {
        console.info(err);
      });
    }
  }

  reRenderList() {
    // rerender
    if (this.type) {
      this.requestData.filter = {
        type: this.type
      }
    } else {
      delete this.requestData.filter;
    }
    this.helperService.getFormOrTemplate('template', this.requestData)
      .then((data) => {
        this.rows = data.data;
        this.requestData.page = data.page;
      }).catch(err => {
        console.info(err);
      });
  }

  setPage(pageInfo) {
    this.requestData.page.pageNumber = pageInfo.offset;
    this.loadingIndicator = true;
    this.reRenderList();
  }

  filter(query) {
    this.requestData['search'] = {
      value: query || '',
      regex: false
    };
    this.reRenderList();
  }

  onSort(event) {
    this.loadingIndicator = true;
    this.requestData['sort'] = (event.sorts && event.sorts[0]);    
    this.reRenderList();
  }

  addNewTemplate() {
    // new
    const dialogRef = this.dialog.open(TemplateModalComponent, {
      width: '100%',
      height: '100vh',
      data: { data: null, operation: 'add', formVar: [], selectedFormName: '' },
      panelClass: 'happ-form-builder-dialog'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.reRenderList();
    }, err => {
      this.helperService.openSnackBar(err);
      console.info(err);
    });
  }

  editTemplate(template: any) {
    // edit
    this.helperService.getTemplate(template)
      .then((templateData) => {
        const dialogRef = this.dialog.open(TemplateModalComponent, {
          width: '100%',
          height: '100vh',
          data: { data: templateData, operation: 'edit', formVar: [], selectedFormName: '' },
          panelClass: 'happ-form-builder-dialog'
        });
        dialogRef.afterClosed().subscribe(result => {
          this.reRenderList();
        }, err => {
          this.helperService.openSnackBar(err);
          console.info(err);
        });
      }).catch(err => {
        console.info(err);
      });
  }

  selectTemplate(template: any) {
    if (this.allowSelection) {
      if (this.allowMultipleSelection) {
        if (this.selectedTemplates.includes(template._id)) {
          // remove template
          const index = this.selectedTemplates.indexOf(template._id);
          this.selectedTemplates.splice(index, 1);
          this.multipleTemplate = this.multipleTemplate || [];
          const index2 = _.findIndex(this.multipleTemplate, { _id: template._id});
          if (index2 >= 0) {
            this.multipleTemplate.splice(index2, 1);
          }
          this.multipleTemplateChange.next(this.multipleTemplate);    
        } else {
          this.selectedTemplates.push(template._id);
          this.multipleTemplate.push(template);
          this.multipleTemplateChange.next(this.multipleTemplate);
        }
      } else {
        if (this.fields && this.fields.length) {
          const temp: any = {
            _id: template._id
          };
          this.fields.forEach(ele => {
            temp[ele] = template[ele];
          });
          this.singleTemplate = temp;
          this.singleTemplateChange.next(this.singleTemplate);
        } else {
          this.singleTemplate = template._id;
          this.singleTemplateChange.next(this.singleTemplate);
        }
      }
    }
  }
  
}
