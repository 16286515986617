import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';

// import { GlobalModule } from '@fuse/global.module';
import { GlobalModule } from '@fuse/global.module';
import { DateFilterFieldComponent } from './date-filter-field.component';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
    imports     : [
        FuseSharedModule,
        // GlobalModule
    ],
    declarations: [
        DateFilterFieldComponent
    ],
    providers   : [
    ],
    exports: [
        DateFilterFieldComponent
    ],
    entryComponents: [
    ]
})
export class DateFilterFieldModule
{
}
