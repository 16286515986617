import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { FileUploadComponent } from './file-upload.component';
import { FileRetryModule } from './file-retry-view/file-retry-view.module';


@NgModule({
    declarations: [
      FileUploadComponent,
      
    ],
    imports     : [
       FuseSharedModule,
       FileRetryModule
    ],
    exports: [
      FileUploadComponent,
    ],
    entryComponents: [
    ]
})

export class FileUploadModule
{

}