import * as tslib_1 from "tslib";
import { HelpersService } from './../../../../../../services/helpers/helpers.service';
import { OnInit } from '@angular/core';
import { MatSnackBar, MatDialogRef, MatDialog } from '@angular/material';
import { ActionDataModel, ActionPlacements, ActionApps, ActionModules } from '../../actions/actions.model';
import { AddTaskComponent } from 'app/main/content/apps/processes-settings/process-view/add-task/add-task.component';
import { SelectIconComponent } from 'app/main/content/apps/workspace-management/select-icon/select-icon.component';
import { TaskCategorySelectionComponent } from 'app/main/content/apps/processes-settings/process-view/add-task/task-category-selection/task-category-selection.component';
var StepActionsComponent = /** @class */ (function () {
    function StepActionsComponent(snackBar, dialogRef, data, dialog, _helperService) {
        this.snackBar = snackBar;
        this.dialogRef = dialogRef;
        this.data = data;
        this.dialog = dialog;
        this._helperService = _helperService;
        this.selectedGroup = 'register';
        this.currentView = 0;
        this.appEditManual = false;
        this.actionList = [];
        this.operation = 'new';
        this.editApp = false;
        this.loading = false;
        this.actionData = JSON.parse(JSON.stringify(ActionDataModel));
        this.placements = ActionPlacements;
        this.apps = ActionApps;
        this.modules = ActionModules;
    }
    StepActionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.operation = this.data['operation'];
        this.index = this.data['index'];
        this.actionList = this.data['actionList'];
        if (this.operation === 'edit' && this.actionList) {
            this.actionData = this.actionList[this.index];
        }
        //  placement value setting.
        if (this.actionData && this.actionData.placement) {
            for (var i in this.actionData.placement) {
                if (this.actionData.placement[i]) {
                    this.placementVal = i;
                }
            }
        }
        this.apps.forEach(function (ele) {
            if (ele.app === _this.actionData.application) {
                _this.selectedGroup = ele.group;
            }
        });
    };
    Object.defineProperty(StepActionsComponent.prototype, "actionParams", {
        get: function () {
            if (this.actionData && this.actionData.params) {
                return JSON.stringify(this.actionData.params);
            }
        },
        set: function (value) {
            try {
                if (this.actionData && this.actionData.params) {
                    this.actionData.params = JSON.parse(value);
                }
            }
            catch (e) {
                // handle json string is not validated.
                this.snackBar.open(e, 'Okay', {
                    duration: 2000,
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    StepActionsComponent.prototype.updateRegData = function () {
        for (var i in this.placements) {
            if (this.placementVal === this.placements[i]['name']) {
                this.actionData.placement[this.placements[i]['name']] = true;
            }
            else {
                this.actionData.placement[this.placements[i]['name']] = false;
            }
        }
        if (this.actionData.default_label) {
            this.actionData['appType'] = 'workspace';
            this.dialogRef.close(this.actionData);
        }
        else {
            this.snackBar.open('Please Enter Default Label', 'Okay', {
                duration: 2000,
            });
        }
    };
    StepActionsComponent.prototype.addTaskV2 = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var processData, dialogRef, err_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this._helperService.getProcess(this.data['processId'])];
                    case 1:
                        processData = _a.sent();
                        dialogRef = this.dialog.open(TaskCategorySelectionComponent, {
                            width: '100%',
                            panelClass: 'happ-form-builder-dialog',
                            data: { processData: processData, activityData: this.data['activityData'] }
                        });
                        dialogRef.afterClosed().subscribe(function (result) {
                            if (result) {
                                _this.actionData.taskInfo = result;
                            }
                        }, function (err) {
                            console.info(err);
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        console.info(err_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    StepActionsComponent.prototype.addTask = function (app) {
        var _this = this;
        var operation = 'new';
        var stageExc = 'init';
        var task;
        if (this.actionData.taskInfo) {
            operation = 'edit';
            task = this.actionData.taskInfo.task;
        }
        else {
            return this.addTaskV2();
        }
        this.loading = true;
        this._helperService.getProcess(this.data['processId'])
            .then(function (process) {
            _this.loading = false;
            var dialogRef = _this.dialog.open(AddTaskComponent, {
                width: '100%',
                panelClass: 'happ-form-builder-dialog',
                data: { task: task, operation: operation, processData: (process), activityData: null, stageExc: stageExc }
            });
            dialogRef.afterClosed().subscribe(function (result) {
                if (result) {
                    _this.actionData.taskInfo = result;
                }
            }, function (err) {
                console.info(err);
            });
        }).catch(function (err) {
            _this.loading = false;
            console.info(err);
        });
    };
    StepActionsComponent.prototype.placementChanged = function (val) {
    };
    StepActionsComponent.prototype.selectIcon = function () {
        var _this = this;
        var dialogRef = this.dialog.open(SelectIconComponent, {
            width: '700px',
            height: '600px',
            panelClass: 'happ-form-builder-dialog',
            data: { icon: this.actionData.icon || 'settings' }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.actionData.icon = result;
            }
        });
    };
    return StepActionsComponent;
}());
export { StepActionsComponent };
