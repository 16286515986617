<div fxLayout="row" *ngIf="!field.hide || builder && options">
    <div *ngIf="options" fxFlex style="width: 100%;">
        <app-datatable [regId]="positionRegId" [fieldData]="field" [calculateOnSave]="field.calculateOnSave" (updateOnSave)="updateOnSave($event)" ></app-datatable>
    </div>
    <div *ngIf="!options && !loading">
        <h2>
            {{ errorMessage }}
        </h2>
    </div>
    <!-- <div style="display: flex; justify-content: center; align-items: center;" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div> -->
</div>