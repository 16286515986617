import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AuthStore } from '../../state/authState/auth.store';
import { OfficeStore } from '../../state/officeState/office.store';
import * as _ from 'lodash';
import * as jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { SocketService } from '../../services/socket/socket.service';
import { WorkspaceStore } from '../../state/workspaceState/workspace.store';
import { PendingPaymentComponent } from '../../components/pending-payment/pending-payment.component';

@Injectable()
export class AuthService {
    tokenKey = 'ngStorage-token';
    isAuthenticated: Boolean = false;
    username: Subject<string> = new Subject<string>();
    authToken: string = undefined;
    onUserNameChanged: BehaviorSubject<any> = new BehaviorSubject(null);
    onOfficeChange: BehaviorSubject<any> = new BehaviorSubject({});

    constructor(
        private http: HttpClient,
        private router: Router,
        private readonly zone: NgZone,
        private readonly snackBar: MatSnackBar,
        public dialog: MatDialog,
        private authStore: AuthStore,
        private officeStore: OfficeStore,
        private cookieService: CookieService,
        private workspaceStore: WorkspaceStore,
        private socketService: SocketService,
    
    ) {}

    sendUsername(name: string) {
        this.username.next(name);
    }

    clearUsername() {
        this.username.next(undefined);
        this.onUserNameChanged.next(undefined);
    }

    loadUserCredentials() {
        const credentials = JSON.parse(localStorage.getItem(this.tokenKey));
        if (credentials && credentials.username !== undefined) {
            this.useCredentials(credentials);
        }
    }
    
    useCredentials(credentials: any) {
        this.isAuthenticated = true;
        this.sendUsername(credentials.username);
        this.authToken = credentials.token;
    }

    destroyUserCredentials() {
        this.authToken = undefined;
        this.clearUsername();
        this.authStore.reset();
        this.isAuthenticated = false;
        localStorage.removeItem(this.tokenKey);
        localStorage.removeItem('ngStorage-token');
        localStorage.removeItem('ngStorage-profile');
        localStorage.removeItem('ngStorage-selectedOffice');
        localStorage.clear();
    }

    signUp(user) {
        const header = new HttpHeaders();
        header.append('Content-Type', 'application/json');
        return this.http
            .post(environment.serverUrl + 'api/registration', user, {
                headers: header
            })
            .map(res => {
                if (res['success']) {
                    return { error: false };
                } else {
                    return { error: true, message: (res['message']) };
                }
            });
    }
    
    fail() {
        this.zone.run(() => {
            this.snackBar.open('Invalid  login data.', null, {
                duration: 7000,
                panelClass: 'snackbar-error'
            });
        });
    }

    getRedirectURL() {
        const redirectURL = localStorage.getItem('redirectUrl');
        if (redirectURL) {
            localStorage.removeItem('redirectUrl');
        }
        return redirectURL;
    }

    logIn(user: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const header = new HttpHeaders();
            header.append('Content-Type', 'application/json');
            let url = `${environment.serverUrl}api/auth`;
            if (user && user.provider === "GOOGLE") {
                url = `${environment.serverUrl}api/google-auth`;
            }
            return this.http.post(url, user, { headers: header })
            .subscribe((data: any) => {
                if (data['success'] && data['result']) {
                    data = data['result'] || {};
                    this.afterLogin(data.token);
                    resolve(true);
                } else {
                    reject(data);
                }
            }, err => {
                reject(err);
            });
        });
    }

    afterLogin(token: string) {
        try {
            this.setToken(token);
            const header = new HttpHeaders({
                'Authorization': token,
                'Content-Type': 'application/json'
            });
            this.http.get(`${environment.serverUrl}api/profile/contactData`, { headers: header })
            .subscribe((data: any) => {
                console.log("data = ", data);
                data = data.result;
                this.loadAllData(data);
                data.extraInfo = data.extraInfo || {};
                if (data.extraInfo.initUrl) {
                    localStorage.setItem("initUrl", data.extraInfo.initUrl);
                }
                data.extraInfo.initUrl = this.getRedirectURL() || data.extraInfo.initUrl || '/dashboard';
                const officeDoc = (data.extraInfo && data.extraInfo.officeDoc) || {};
                if (data.extraInfo.tempInitUrl) {
                    localStorage.setItem("tempInitUrl", data.extraInfo.tempInitUrl);
                }

                if (data.extraInfo.workspaceDoc) {
                    window.localStorage.setItem("temp-ws", JSON.stringify(data.extraInfo.workspaceDoc))
                }

                this.authStore.update({
                    token: token, 
                    redirectUrl: this.getRedirectURL() || data.extraInfo.initUrl || '/dashboard', 
                    // redirectUrl: `/pending-payment?redirectUrl=${this.getRedirectURL() || data.extraInfo.initUrl || '/dashboard'}`, 
                    ...this.getOfficeInfo(data.extraInfo), 
                    ...this.isUserAdmin(data),
                    ...this.getUserInfo(data.contactData) });
                if (officeDoc.ussageBalance) {
                    // open popover.
                    // const dialogRef = this.dialog.open(PendingPaymentComponent, {
                    //     width: '100%',
                    //     height: '100%',
                    //     disableClose: true,
                    //     panelClass: 'happ-form-builder-dialog',
                    //     data: {officeDoc }
                    //   });
                    setTimeout(() => {
                        this.router.navigate(["/pending-payment"], { queryParams: { redirectUrl: this.getRedirectURL() || data.extraInfo.initUrl || '/dashboard' } })
                    }, 400);
                  
                } else {
                    this.router.navigate([data.extraInfo.initUrl]);
                }
                
            }, err => {
                console.log(err);
            });
        } catch (error) {
            throw error;
        }
    }

    getOfficeInfo(extraInfo: any = {}) {
        return {
            officeId: (extraInfo.officeDoc && extraInfo.officeDoc._id) || '',
            officeName: (extraInfo.officeDoc && extraInfo.officeDoc.name) || '',
        };
    }

    isUserAdmin({extraInfo, contactData}: any) {
        try {
            const officeDoc = extraInfo.officeDoc || {};
            const profileDoc = contactData || {};
            const usrDoc = _.find(officeDoc.users || [], { id: profileDoc.profileId });
            if (usrDoc && usrDoc['isAdmin']) {
                return { isAdmin: true };
            } else {
                return { isAdmin: false };
            }
        } catch (error) {
            throw error;
        }
    };

    getUserInfo(contactData: any = {}) {
        return {
            email: (contactData.email) || '',
            profileId: (contactData.profileId) || '',
            defaultLang: (contactData.defaultLang) || 'en'
        };
    }

    setToken(token: string) {
        const decodedToken = jwt_decode(token);
        const credentials = {
            username: decodedToken['login'],
            token: token,
            date: decodedToken.date
        };
        this.setCrossSubdomainCookie('token', token, 7);
        this.isAuthenticated = true;
        localStorage.setItem(this.tokenKey, JSON.stringify(credentials));
    }

    loadAllData(res: any = {}) {
        localStorage.setItem('ngStorage-profile', JSON.stringify(res['contactData'] || {}));
        localStorage.setItem('selectedLanguage', (res['contactData'] && res['contactData']['defaultLang']) || 'en');
        if (res.extraInfo && res.extraInfo.officeDoc) {
            this.authStore.update({ officeName: res.extraInfo.officeDoc.name });
            this.officeStore.update(res.extraInfo.officeDoc);
            localStorage.setItem('selectedOfficeId', JSON.stringify(res.extraInfo.officeDoc['_id']));
            localStorage.setItem('ngStorage-selectedOffice', JSON.stringify(res.extraInfo.officeDoc));
            this.onOfficeChange.next(res.extraInfo.officeDoc['_id']);
        }
    }

    
    setCrossSubdomainCookie = (name, value, days) => {
        const assign = name + '=' + escape(value) + ';';
        const d = new Date();
        d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = 'expires=' + d.toUTCString() + ';';
        const path = 'path=/;';
        let domain;
        if (document.domain === 'localhost') {
        domain = document.domain;
        } else {
        domain = 'domain=' + (document.domain.match(/[^\.]*\.[^.]*$/)[0]) + ';';
        }
        document.cookie = assign + expires + path + domain;
    }

    logOut(route: string = "/login") {
        this.destroyUserCredentials();
        const loc = window.location.pathname;
        this.authStore.reset();
        this.officeStore.reset();
        this.workspaceStore.reset();
        this.socketService.disconnectSocket();
        this.cookieService.deleteAll();
        this.officeStore.reset();
        console.log("3333333");

        if (
            !(
                loc === '/login' ||
                loc === '/register' ||
                loc === '/cp-login' ||
                loc.includes('/mail-detail') ||
                loc.includes('/set-password') ||
                loc.includes('/checkout') ||
                loc.includes('/provider-client') ||
                loc.includes('/userInvitation') ||
                loc.includes('/changepassword')
            )
        ) {
            this.router.navigate([route]);
        }
    }



    isLoggedIn(): Boolean {
        return this.isAuthenticated;
    }


    refreshToken(): Promise<any> {
        return new Promise((resolve, reject) => {
            resolve();
        });
    }

    /**
     * to check if auth is in time range or not..
     */
    isAuth(): boolean {
        if (JSON.parse(localStorage.getItem(this.tokenKey))) {
            const credentials = JSON.parse(localStorage.getItem(this.tokenKey));
            const loginTime = credentials['date'] || 0;
            const expTime = Math.floor(loginTime / 1000) + 8 * 60 * 60;
            const now = Math.floor(Date.now() / 1000);
            const timeToExpire = expTime - now;
            if (timeToExpire < 60 * 60 + 60 * 60 && loginTime) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
}
