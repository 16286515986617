import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { MailingListActionPopupComponent } from './mailing-list-action-popup/mailing-list-action-popup.component';
import { MailingListLoadingPopupComponent } from './mailing-list-loading-popup/mailing-list-loading-popup.component';

@Component({
  selector: 'app-mailing-list-action',
  templateUrl: './mailing-list-action.component.html',
  styleUrls: ['./mailing-list-action.component.scss']
})
export class MailingListActionComponent implements OnInit {

  @Input() field;
  @Output() onFieldValueUpdated: EventEmitter<any> = new EventEmitter();
  @Input() builder;
  @Input() regRec;
  @Input() recId: string;
  @Input() signature: string;
  @Input() regId: string;
  @Input() model: any; 
  @Input() translateData: any;
  @Output() modelChange: EventEmitter<any> = new EventEmitter();
  @Output() updateParentOnSave: EventEmitter<any> = new EventEmitter();
  @Output() getRecord: EventEmitter<any> = new EventEmitter();

  introJson = [
    {
      element: '#mailing_list',
      intro: 'This is your mailing list',
      position: 'bottom'
    },
    {
      element: '#mailing_list_add',
      intro: 'You can add new mailing list',
      position: 'bottom'
    },
    {
      element: '#mailing_list_list',
      intro: 'Check all created mailing list here.',
      position: 'bottom'
    },
  ]

  constructor(
    public dialog: MatDialog,
    private helperService: HelpersService
  ) { }

  ngOnInit() {
    this.model = this.model || [];
    this.helperService.setIntroJson(this.introJson);
    setTimeout(() => {
      this.helperService.startIntro();
    }, 2000);
  }

  addNew(item?, index?) {
    if (!item) {
      item = {
        actionId: new Date().getTime()
      }
    }
    const data = this.dialog.open(MailingListActionPopupComponent, {
      disableClose: true,
      width: '100%',
      height: '100%',
      panelClass: 'happ-form-builder-dialog',
      data: {
        formData: item,
        regId: this.regId,
        recId: this.recId,
        index
      }
    });

    data.afterClosed()
      .subscribe((response) => {
        console.log("create action", response, index);
        if (response) {
          this.model = this.model || [];
          if (index !== null && index !== undefined) {
            this.model[index] = response;
          } else {
            this.model.push(response)
          }
          this.modelChange.emit(this.model);
        }
      });
  }

  edit(item, index) {
    this.addNew(item, index);
  }

}
