import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GlobalModule } from '@fuse/global.module';
import { MaterialModule } from '@fuse/material.module';
import { FormsModule } from '@angular/forms';
import { ProcessButtonCommentPopupComponent } from './process-button-comment-popup.component';
import { TranslateModule } from '@ngx-translate/core';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { TextTranslatePipe } from '../../pipes/textTranslate.pipe';
import { GlobalPipesModule } from '../../pipes/globalPipes.module';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        MaterialModule,
        FormsModule,
        TranslateModule,
        GlobalPipesModule
    ],
    declarations: [
        ProcessButtonCommentPopupComponent
    ],
    exports: [
        ProcessButtonCommentPopupComponent
    ],
    providers: [],
    entryComponents: [
        ProcessButtonCommentPopupComponent
    ]
})
export class ProcessButtonCommentPopupModule { }
