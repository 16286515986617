import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FuseNavigationMenuService } from 'app/navigation/navigation';
import { HelpersService } from '../../services/helpers/helpers.service';
import { WorkspaceStore } from '../../state/workspaceState/workspace.store';
var SelectWorkspaceAppComponent = /** @class */ (function () {
    function SelectWorkspaceAppComponent(dialogRef, data, _navigationService, _helperService, workspaceStore) {
        this.dialogRef = dialogRef;
        this.data = data;
        this._navigationService = _navigationService;
        this._helperService = _helperService;
        this.workspaceStore = workspaceStore;
        this.modelChange = new EventEmitter();
        this.workspaceList = [];
        this.workspaceApps = [];
    }
    SelectWorkspaceAppComponent.prototype.ngOnInit = function () {
        this.getUsersWorkspace();
    };
    SelectWorkspaceAppComponent.prototype.getUsersWorkspace = function () {
        this.workspaceList = this._navigationService.defaultWorkspaceConfig.workspaceList || [];
        this.workspaceList = this.workspaceList.filter(function (ele) { return (/^[0-9a-fA-F]{24}$/.test(ele.id)); });
    };
    SelectWorkspaceAppComponent.prototype.selectWorkspace = function (workspace) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var workspaceDoc, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this._helperService.getRegisterData(workspace.id)];
                    case 1:
                        workspaceDoc = _a.sent();
                        this.selectedWorkspace = workspace;
                        this.workspaceApps = workspaceDoc.appList || [];
                        this.workspaceApps.splice(0, 1);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        this._helperService.showInfo(error_1, '', 'error');
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SelectWorkspaceAppComponent.prototype.selectApp = function (workspaceApp) {
        console.log(workspaceApp);
        this.selectedWorkspaceApp = workspaceApp;
        if (this.isNotPopup) {
            this.modelChange.emit({ duplicateWorkspaceId: this.selectedWorkspace.id, duplicateWorkspaceApp: this.selectedWorkspaceApp.stepNumber });
        }
        else {
            this.performPopupOperation();
        }
    };
    SelectWorkspaceAppComponent.prototype.performPopupOperation = function () {
        var _this = this;
        if (this.data && this.data.existWithResult) {
            this.dialogRef.close(this.selectedWorkspaceApp);
        }
        else {
            this._helperService.openConfirmPopUp("Are you sure you want to copy config of workspaceApp - " + this.selectedWorkspaceApp.stepLabel)
                .subscribe(function (data) {
                if (data && _this.data.workspaceSlug) {
                    _this.selectedWorkspaceApp['wsAppType'] = 'duplicate-app';
                    _this._helperService.postRequest("api/offices/work-space/" + _this.data.workspaceSlug + "/apps/new", _this.selectedWorkspaceApp)
                        .then(function (data2) {
                        _this.dialogRef.close(_this.selectedWorkspaceApp);
                        if (data2) {
                            _this.workspaceStore.update({ workspaceData: data2 });
                            _this._helperService.showInfo('Workspace App Duplicated', '', 'success');
                        }
                    }).catch(function (err) {
                        _this._helperService.showInfo(err, '', 'error');
                    });
                }
                else {
                    _this._helperService.showInfo('No workspace Slug provided', '', 'error');
                }
            });
        }
    };
    return SelectWorkspaceAppComponent;
}());
export { SelectWorkspaceAppComponent };
