import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss']
})
export class HtmlEditorComponent implements OnInit {


  /**
   * 
   * List of available options
   * 
   * https://ckeditor.com/old/forums/CKEditor/Complete-list-of-toolbar-items#comment-123266
   *
   */
  @Input() model = '';
  @Input() height = 200;
  @Input() all = false;
  @Input() isControlType = false;
  @Input() defaultEditMode = true;
  @Input() title = '';
  @Input() type = 'normal';
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Output() saveChange: EventEmitter<Object> = new EventEmitter();

  config = {
    height: this.height,
    toolbar: [
      ['Bold', 'Italic', '-', 'NumberedList', 'BulletedList', 'FontSize', 'TextColor', '-', 'Table', 'HorizontalRule', 'Maximize']
    ]
  };

  editorTypes = {
    admin: ['Source', '-', 'Table',
      'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
      'Bold', 'Italic', '-', 'NumberedList', 'BulletedList', 'FontSize', 'TextColor', '-', 'HorizontalRule', 'Image', 'Link', 'Maximize'],
    superAdmin: ['Source', '-', 'Table',
      'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
      'Bold', 'Italic', '-', 'NumberedList', 'BulletedList', 'FontSize', 'TextColor', '-', 'HorizontalRule', 'Image', 'Link', 'Maximize'],
    normal: ['Bold', 'Italic', '-', 'NumberedList', 'BulletedList', 'FontSize', 'TextColor', '-', 'Table', 'HorizontalRule', 'Maximize']
  };

  constructor() { }

  ngOnInit() {
    this.config.height = this.height || this.config.height;
    this.config.toolbar[0] = this.editorTypes[this.type];

    if (!this.model) {
      this.defaultEditMode = true;
    } else {
      this.defaultEditMode = false;
    }
  }

  onValueChange() {
    this.modelChange.next(this.model);
  }

  saveData() {
    this.saveChange.emit(this.model);
    this.closeEditor();
  }

  closeEditor() {
    this.defaultEditMode = false;
  }

}
