import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DescriptionEditComponent } from './description-edit.component';
import { MaterialModule } from '@fuse/material.module';
import { HtmlEditorModule } from '../html-editor/html-editor.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [DescriptionEditComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FuseSharedModule,
    HtmlEditorModule,
    TranslateModule
  ],
  entryComponents: [
    DescriptionEditComponent
  ]
})
export class DescriptionEditModule { }
