import * as objectPath from 'object-path';
import { AppBuilderComponent } from '../../app-builder/app-builder.component';
var SuperAdminSettings = /** @class */ (function () {
    function SuperAdminSettings(url, appData, moreInfo) {
        if (appData === void 0) { appData = {}; }
        if (moreInfo === void 0) { moreInfo = {}; }
        this.general = [
            {
                'type': 'field',
                'settings': {
                    updateApi: '',
                    fields: [
                        {
                            fieldType: 'popup',
                            title: 'workspace.install_app_template',
                            description: 'workspace.install_app_template_desc',
                            initValue: '',
                            defaultValue: '',
                            keyName: 'installApp',
                            accessLevel: 'user',
                            extraData: { createNew: true },
                            component: AppBuilderComponent,
                            buttonTitle: 'workspace.install_app_template',
                            height: '100%',
                            width: '100%'
                        },
                        {
                            fieldType: 'popup',
                            title: 'workspace.update_app_template',
                            description: 'workspace.update_app_template_desc',
                            initValue: '',
                            defaultValue: '',
                            keyName: 'updateTemplate',
                            accessLevel: 'user',
                            extraData: { updateTemplate: true },
                            component: AppBuilderComponent,
                            buttonTitle: 'workspace.update_app_template',
                            height: '100%',
                            width: '100%'
                        }
                    ]
                }
            }
        ];
        this.settingJson = [
            {
                'type': 'section',
                'settings': [
                    {
                        'title': 'workspace.installer',
                        'children': this.general.slice()
                    }
                ]
            }
        ];
        this.settingJson.forEach(function (setting) {
            setting.settings.forEach(function (sectionSetting) {
                sectionSetting.children.forEach(function (fieldSetting) {
                    fieldSetting.settings = fieldSetting.settings || {};
                    fieldSetting.settings.updateApi = url;
                    fieldSetting.settings['triggerSub'] = moreInfo.triggerSub;
                    fieldSetting.settings.fields = fieldSetting.settings.fields || [];
                    fieldSetting.settings.fields.forEach(function (field) {
                        field['initValue'] = (objectPath.get(appData, field.keyName) || objectPath.get(appData, field.keyName) === false) ?
                            objectPath.get(appData, field.keyName) : field['defaultValue'];
                        field['dialogRef'] = moreInfo.dialogRef;
                        if (field.fieldType === 'select') {
                            field.options = field.options || [];
                            field.options = field.options.concat(moreInfo[field.keyName] || []);
                        }
                        if (field.fieldType === 'popup') {
                            moreInfo[field.keyName] = moreInfo[field.keyName] || {};
                            field.extraData = field.extraData || {};
                            field.extraData = Object.assign(moreInfo[field.keyName], field.extraData);
                        }
                    });
                });
            });
        });
    }
    Object.defineProperty(SuperAdminSettings.prototype, "settingsModel", {
        get: function () {
            return this.settingJson;
        },
        enumerable: true,
        configurable: true
    });
    return SuperAdminSettings;
}());
export { SuperAdminSettings };
