import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var CtSsCatPopupComponent = /** @class */ (function () {
    function CtSsCatPopupComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    CtSsCatPopupComponent.prototype.ngOnInit = function () {
        console.log("data - ", this.data);
    };
    CtSsCatPopupComponent.prototype.toogleSelect = function (item) {
        // console.log("data -", item, item2);
        this.data.selectedAreas.push(item.key);
    };
    CtSsCatPopupComponent.prototype.toogleSelectType = function (item) {
        this.data.mySelectedTypes.push(item.key);
    };
    CtSsCatPopupComponent.prototype.save = function () {
        this.dialogRef.close(tslib_1.__assign({}, this.data));
    };
    return CtSsCatPopupComponent;
}());
export { CtSsCatPopupComponent };
