 <!-- <button mat-raised-button (click)="searchNow()" >
      {{ 'ct.search_location' | translate }}
    </button> -->
        <!-- <div class="form-group">
      
          <label>Enter address</label>
      
          <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
      
        </div> -->

<div fxLayout="row" class="container" *ngIf="!field.hide || builder" style="margin-bottom: 12px;">
    
  <div fxLayout="column" style="width: 100%;">
    <mat-form-field class="happ-small-form-field-outline" floatLabel="always" appearance="outline" fxFlex>
      <mat-label> {{ field.title | translate }} </mat-label>
      <input matInput [(ngModel)]="tempModel" (keydown.enter)="$event.preventDefault()" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
    </mat-form-field>
   
      
        
      <ng-container [style.height]="field.showMap ? '300px' : '0px'" >
        <agm-map [latitude]="mapLatitude" [longitude]="mapLongitude" [zoom]="zoom" (mapClick)="onMapClick($event)">
      
          <agm-marker #agmMap [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true" (dragEnd)="onMarkerDragEnd($event)" ></agm-marker>
      
        </agm-map>
      </ng-container>
      
  </div>

    <div fxLayout="column" style="width: 300px; padding: 12px;">
      <h3 class="mb-0">Address: {{address}}</h3>
    
      <div>Latitude: {{latitude}}</div>
    
      <div>Longitude: {{longitude}}</div>

    </div>
    </div>

    <!-- <div id="map" style="height: 500px;"></div> -->
    