<div style="background: #f6f6f6;" class="m-0">
  <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <button class="mr-12" (click)="dialogRef.close(null)" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
    <span class="title dialog-title"> {{ 'workspace.card_actions' | translate }} </span>
    <span fxFlex></span>
    <button class="mr-12" (click)="saveData()" mat-icon-button>
        <mat-icon>check</mat-icon>
      </button>
  </div>
</div>
<div fxLayout="column">
  <div fxLayout="row" class="happ-toolbar-simple">
    
    <span fxFlex></span>
    <div style="margin: auto;">
      <button mat-raised-button (click)="addNewAction()">
        {{ 'workspace.add_action' | translate }}      
      </button>
    </div>
  </div>
  <div>
    <app-card-actions-list (deleteAction)="deleteAction($event)" [cardActionList]="cardActions" (editAction)="editAction($event)" ></app-card-actions-list>
  </div>
</div>