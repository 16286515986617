import { Routes } from '@angular/router';
import { Login2Component } from './main/content/pages/authentication/login-2/login-2.component';
import { Register2Component } from './main/content/pages/authentication/register-2/register-2.component';
import { ForgotPasswordComponent } from './main/content/pages/authentication/forgot-password/forgot-password.component';
import { OfficeListService } from './main/content/apps/office-list/office-list.service';
import { OfficeListComponent } from './main/content/apps/office-list/office-list.component';
import { ProfileComponent } from './main/content/pages/profile/profile.component';
import { AppDashboardRouteGuard } from './app-dashboard-route.guard';
import { SetPasswordComponent } from './main/content/pages/authentication/set-password/set-password.component';
import { InvitationComponent } from './main/content/pages/invitation/invitation.component';
import { SuperAdminRouteGuard } from './main/content/gaurds/super-admin-route.guard';
import { SaUnauthViewComponent } from './main/content/apps/super-admin/sa-unauth-view/sa-unauth-view.component';
import { SsClientLoginComponent } from './main/content/pages/authentication/ss-client-login/ss-client-login.component';
import { PendingPaymentComponent } from './main/content/components/pending-payment/pending-payment.component';
import { ExternalProcessViewComponent } from './main/content/apps/external-process-view/external-process-view.component';
var ɵ0 = { breadcrumb: 'Workspace' }, ɵ1 = { breadcrumb: 'Workspace' }, ɵ2 = { breadcrumb: 'Workspace' }, ɵ3 = { breadcrumb: 'Workspace' }, ɵ4 = { breadcrumb: 'Workspace' }, ɵ5 = { breadcrumb: 'Workspace' }, ɵ6 = { breadcrumb: 'Workspace' }, ɵ7 = { breadcrumb: 'Workspace' }, ɵ8 = { breadcrumb: 'Workspace' }, ɵ9 = { breadcrumb: 'Workspace' }, ɵ10 = { breadcrumb: 'Workspace' }, ɵ11 = { breadcrumb: 'Workspace' }, ɵ12 = { breadcrumb: 'Workspace' }, ɵ13 = { breadcrumb: 'Calendar' }, ɵ14 = { breadcrumb: 'Workspace' }, ɵ15 = { breadcrumb: 'Workspace' }, ɵ16 = { breadcrumb: 'Workspace' }, ɵ17 = { breadcrumb: 'Workspace' }, ɵ18 = { breadcrumb: 'Office-profie' }, ɵ19 = { breadcrumb: 'Office-profie' }, ɵ20 = { breadcrumb: 'Personal Dashboard' }, ɵ21 = { breadcrumb: 'Workspace' }, ɵ22 = { breadcrumb: 'Persona' }, ɵ23 = { breadcrumb: 'ss-all-apps' }, ɵ24 = { breadcrumb: 'platform-global-template' }, ɵ25 = { breadcrumb: 'Invitation' }, ɵ26 = { breadcrumb: 'userInvitation' }, ɵ27 = { breadcrumb: 'Plugins' }, ɵ28 = { breadcrumb: 'Forms' }, ɵ29 = { breadcrumb: 'Agenda View' }, ɵ30 = { breadcrumb: 'Translate' }, ɵ31 = { breadcrumb: 'Address book' }, ɵ32 = { breadcrumb: 'Templates' }, ɵ33 = { breadcrumb: 'Register' }, ɵ34 = { breadcrumb: 'Department Settings' }, ɵ35 = { breadcrumb: 'Users Settings' }, ɵ36 = { breadcrumb: 'Process List' }, ɵ37 = { breadcrumb: 'Process Setting List' }, ɵ38 = { breadcrumb: 'Academy' }, ɵ39 = { breadcrumb: 'Persona Stepper' }, ɵ40 = { breadcrumb: 'Apps Setting' }, ɵ41 = { breadcrumb: 'One View' }, ɵ42 = { breadcrumb: 'Office Configration' }, ɵ43 = { breadcrumb: 'Calendar' };
var routes = [
    {
        path: 'register',
        component: Register2Component
    },
    {
        path: 'pending-payment',
        component: PendingPaymentComponent
    },
    {
        path: 'profile',
        component: ProfileComponent
    },
    {
        path: 'login',
        component: Login2Component
    },
    {
        path: 'cp-login',
        component: SsClientLoginComponent
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'external-process/:token',
        component: ExternalProcessViewComponent
    },
    {
        path: 'changepassword/:token',
        component: SetPasswordComponent
    },
    {
        path: 'set-password/:token',
        component: SetPasswordComponent
    },
    {
        path: 'no-access/:type',
        component: SaUnauthViewComponent
    },
    {
        path: 'dashboard',
        resolve: {
            office: OfficeListService
        },
        component: OfficeListComponent
    },
    {
        path: 'workspace',
        data: ɵ0,
        loadChildren: 'app/main/content/apps/workspace/workspace.module#WorkspaceModule'
    },
    {
        path: 'checkout',
        loadChildren: 'app/main/content/pages/external/payment-page/external-payment-page.module#ExternalPaymentPageModule'
    },
    {
        path: 'pricing-plan',
        data: ɵ1,
        loadChildren: 'app/main/content/apps/pricing-plan/pricing-plan.module#PricingPlanModule'
    },
    {
        path: 'provider-client',
        data: ɵ2,
        loadChildren: 'app/main/content/pages/authentication/ss-provider-client-auth/register/register.module#SsProdClientRegisterModule'
    },
    {
        path: 'cp/orders',
        data: ɵ3,
        loadChildren: 'app/main/content/apps/financial/orders/order.module#OrderModule'
    },
    {
        path: 'cp/payments',
        data: ɵ4,
        loadChildren: 'app/main/content/apps/financial/payments/payments.module#PaymentsModule'
    },
    {
        path: 'cp/invoices',
        data: ɵ5,
        loadChildren: 'app/main/content/apps/financial/invoices/invoices.module#InvoicesModule'
    },
    {
        path: 'room',
        data: ɵ6,
        loadChildren: 'app/main/content/apps/shared-room/shared-room.module#SharedRoomModule'
    },
    {
        path: 'web-template',
        data: ɵ7,
        loadChildren: 'app/main/content/apps/web-template/web-template.module#WebTemplateModule'
    },
    {
        path: 'cp/specialist/cases',
        data: ɵ8,
        loadChildren: 'app/main/content/apps/personal-platform/pp-my-cases-new/pp-my-cases-new.module#PpMyCasesNewModule'
    },
    {
        path: 'cp/specialist/calendar',
        data: ɵ9,
        loadChildren: 'app/main/content/apps/specialist-calendar/specialist-calendar.module#SpecialistCalendarModule'
    },
    {
        path: 'cp/specialist/dashboard',
        data: ɵ10,
        loadChildren: 'app/main/content/apps/specialist-dashboard/specialist-dashboard.module#SpecialistDashboardModule'
    },
    {
        path: 'cp/room',
        data: ɵ11,
        loadChildren: 'app/main/content/apps/shared-room/shared-room.module#SharedRoomModule'
    },
    {
        path: 'cp/room-events',
        data: ɵ12,
        loadChildren: 'app/main/content/apps/room-event-list/room-event-list.module#RoomEventListModule'
    },
    {
        path: 'cp/calendar',
        data: ɵ13,
        loadChildren: './main/content/apps/private-calendar/private-calendar.module#PrivateCalenderModule'
    },
    {
        path: 'provider-client',
        data: ɵ14,
        loadChildren: 'app/main/content/pages/authentication/ss-provider-client-auth/login/login.module#SsProdClientLoginModule'
    },
    {
        path: 'provider-client',
        data: ɵ15,
        loadChildren: 'app/main/content/pages/authentication/ss-provider-client-auth/forgot-password/forgot-password.module#SsProdClientForgotPasswordModule'
    },
    {
        path: 'cp',
        data: ɵ16,
        loadChildren: 'app/main/content/apps/personal-platform/personal-platform.module#PersonaPlatformModule'
    },
    {
        path: 'provider/:providerId/cp',
        data: ɵ17,
        loadChildren: 'app/main/content/apps/personal-platform/personal-platform.module#PersonaPlatformModule'
    },
    {
        path: 'office-profile',
        data: ɵ18,
        loadChildren: 'app/main/content/apps/office-profile/office-profile.module#OfficeProfileModule'
    },
    {
        path: 'cp/user',
        data: ɵ19,
        loadChildren: 'app/main/content/apps/user-profile/user-profile.module#UserProfileModule'
    },
    {
        path: 'personal-dashboard',
        data: ɵ20,
        loadChildren: 'app/main/content/apps/personal-dashboard/personal-dashboard.module#PersonalDashboardModule'
    },
    {
        path: 'super-admin',
        data: ɵ21,
        canActivate: [SuperAdminRouteGuard],
        loadChildren: 'app/main/content/apps/super-admin/super-admin.module#SuperAdminModule'
    },
    {
        path: 'persona24',
        data: ɵ22,
        loadChildren: 'app/main/content/apps/persona/persona.module#PersonaModule'
    },
    {
        path: 'ss-all-apps',
        data: ɵ23,
        loadChildren: 'app/main/content/apps/ss-all-apps/ss-all-apps.module#SsAllAppsModule'
    },
    {
        path: 'platform-global-template',
        data: ɵ24,
        loadChildren: 'app/main/content/apps/platform-global-template/platform-global-template.module#PlatformGlobalTemplateModule'
    },
    {
        path: 'invitation',
        data: ɵ25,
        loadChildren: './main/content/pages/invitation-list/invitation-list.module#InvitationListModule'
    },
    {
        path: 'userInvitation/:userInvitationId/:status',
        data: ɵ26,
        component: InvitationComponent
    },
    {
        path: 'workspace-management',
        loadChildren: './main/content/apps/workspace-management/workspace-management.module#WorkspaceManagementModule',
    },
    {
        path: 'plugins',
        data: ɵ27,
        loadChildren: 'app/main/content/apps/plugin/plugin.module#PluginModule'
    },
    {
        path: 'offices/:officeId/dashboard',
        loadChildren: 'app/main/content/apps/dashboard/dashboard.module#DashboardModule',
    },
    {
        path: 'offices/:officeId/organizations',
        loadChildren: 'app/main/content/apps/virtual-office-list/virtual-office-list.module#VirtualOfficeListModule',
    },
    {
        path: 'offices/:officeId/customDashboard',
        loadChildren: 'app/main/content/apps/dashboard/dashboard.module#DashboardModule',
    },
    {
        path: 'offices/:officeId/postBook',
        loadChildren: './main/content/apps/post-book/post-book.module#PostBookModule',
    },
    {
        path: 'office/payments',
        loadChildren: './main/content/apps/office-coin/office-coin.module#OfficeCoinModule',
    },
    {
        path: 'payments/status',
        loadChildren: './main/content/apps/payments/payments.module#PaymentsModule',
    },
    {
        path: 'office-dashoboard',
        canActivate: [AppDashboardRouteGuard],
        loadChildren: 'app/main/content/apps/dashboard/dashboard.module#DashboardModule',
    },
    {
        path: 'offices/:officeId/general-forms',
        data: ɵ28,
        loadChildren: 'app/main/content/apps/global-form/global-form.module#GlobalFormModule'
    },
    //     {
    //         path        : 'offices/:officeId/org-chart',
    //         data: { breadcrumb: 'Org-chart'},
    //         loadChildren: 'app/main/content/apps/org-chart-settings/org-chart-settings.module#OrgChartSettingsModule'
    //     },
    {
        path: 'offices/:officeId/agenda-view',
        data: ɵ29,
        loadChildren: 'app/main/content/apps/agenda-view/agenda-view.module#AgendaViewModule'
    },
    {
        path: 'translate',
        data: ɵ30,
        loadChildren: 'app/main/content/apps/language/language-trans.module#LanguageTransModule'
    },
    {
        path: 'offices/:officeId/address-book',
        data: ɵ31,
        loadChildren: 'app/main/content/apps/addressbook/addressbook.module#AddressbookModule'
    },
    {
        path: 'offices/:officeId/general-templates',
        data: ɵ32,
        loadChildren: 'app/main/content/apps/global-template/global-template.module#GlobalTemplateModule'
    },
    //     {
    //         path        : 'external-widget',
    //         loadChildren: 'app/main/content/apps/external-widget/external-widget.module#ExternalWidgetModule'        
    //     },
    {
        path: 'offices/:officeId/registries',
        data: ɵ33,
        loadChildren: 'app/main/content/apps/custom-registries/custom-registries.module#CustomRegistriesModule'
    },
    {
        path: 'offices/:officeId/department-settings',
        data: ɵ34,
        loadChildren: 'app/main/content/apps/department-settings/department-settings.module#DepartmentSettingsModule'
    },
    {
        path: 'offices/:officeId/users-settings',
        data: ɵ35,
        loadChildren: 'app/main/content/apps/users-settings/users-settings.module#UsersSettingsModule'
    },
    {
        path: 'offices/:officeId/processes',
        data: ɵ36,
        loadChildren: 'app/main/content/apps/processes/processes.module#ProcessesModule'
    },
    {
        path: 'offices/:officeId/process-settings',
        data: ɵ37,
        loadChildren: 'app/main/content/apps/processes-settings/process-settings.module#ProcessSettingsModule'
    },
    {
        path: 'offices/:officeId/academy',
        data: ɵ38,
        loadChildren: 'app/main/content/apps/academy/academy.module#FuseAcademyModule'
    },
    {
        path: 'personaStepper',
        data: ɵ39,
        loadChildren: 'app/main/content/apps/academy/academy.module#FuseAcademyModule'
    },
    //     {
    //         path        : 'books',
    //         loadChildren: 'app/main/content/apps/books/books.module#BooksModule'
    //     },
    {
        path: 'offices/:officeId/application-settings',
        data: ɵ40,
        loadChildren: 'app/main/content/apps/settings/settings.module#SettingsModule'
    },
    {
        path: 'oneRecordView',
        data: ɵ41,
        loadChildren: 'app/main/content/global-component/one-recordview/one-recordview.module#OneRecordViewModule'
    },
    //     {
    //         path : 'offices/:officeId/gdpr-resources',
    //         data: { breadcrumb : 'records' },
    //         loadChildren: 'app/main/content/apps/gdpr-resources/gdpr-resources.module#GdprResourcesModule'
    //     },
    { path: 'offices/:officeId/office-configuration',
        data: ɵ42,
        loadChildren: 'app/main/content/apps/office-configuration/office-configuration.module#OfficeConfigurationModule'
    },
    {
        path: 'offices/:officeId/calendar',
        data: ɵ43,
        loadChildren: './main/content/apps/calendar/calendar.module#CalenderModule'
    },
    {
        path: 'shared-service',
        loadChildren: './main/content/apps/shared-service/shared-service.module#SharedServiceModule'
    },
    //     {
    //         path    : 'error',
    //         loadChildren: 'app/main/content/global-component/404/error-404.module#Error404Module'
    //     },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/office-dashoboard'
    },
    {
        path: '**',
        redirectTo: '/office-dashoboard'
        // redirectTo: '/mail-detail/62a1d0b1c158a75708677c8e/1'
    }
];
var AppRouting = /** @class */ (function () {
    function AppRouting() {
    }
    return AppRouting;
}());
export { AppRouting };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29, ɵ30, ɵ31, ɵ32, ɵ33, ɵ34, ɵ35, ɵ36, ɵ37, ɵ38, ɵ39, ɵ40, ɵ41, ɵ42, ɵ43 };
