<div class="pb-12" *ngIf="!field.hide || builder">
    <div class="happ-list-field" [class]="field.class" >

        <div class="happ-list happ-content" fxLayout="{{field.flex}} wrap">
          <button class="happ-button-list" mat-raised-button [ngClass]="{'happ-small-button': field.smallButton}" fxLayoutAlign="{{(field.align)? field.align : 'center'}} center" (click)="onChange(option.value || option.title)" role="listitem" *ngFor="let option of field.options" 
          style="background: white; color: black;">
              {{ option.title || option.value }}
          </button>
        </div>
        <h2 *ngIf="errorMes" style="text-align: center;">
           {{ errorMessage | translate }}
        </h2>
        <!-- {{ this.model | json }}
        <mat-form-field class="w-100-p m-4 mt-8">
          <textarea rows="{{ field.no_of_rows}}" class="w-100-p" matInput placeholder="{{field.title | translate}}" [disabled]="field.showdisablecontroltype=='true' || field.disable" (keyup)="onKeyPress()" [hidden]="field.hidden" [(ngModel)]="model" placeholder="Textarea">{{ model }} </textarea>
          <mat-hint align="start"> {{ field.description }} </mat-hint>
        </mat-form-field> -->
        <mat-form-field class="w-100-p" floatLabel="always" appearance="outline">
            <mat-label> {{ field.title | translate }} </mat-label>
            <mat-chip-list #userList>
                <mat-chip *ngFor="let user of items"
                            removable="true" (removed)="removeItem(user)">
                    {{user}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
            <input matInput
                aria-label="user_autocomplete_input"
                class="w-100-p"
                matChipInputAddOnBlur="true"
                [(ngModel)]="inputValue"
                placeholder="{{placeHolder || ''}}"
                [matAutocomplete]="auto" 
                (blur)="addItem($event, temp.value)"
                (keyup.enter)="addItem($event, temp.value)"
                style="padding: 12px; background: lightgray; margin-top: 8px;"
                #temp (keyup)="inputValueChanged($event, temp.value)"
                [matChipInputFor]="userList"/>
                <mat-autocomplete (optionSelected)="addItem($event, null)" #auto="matAutocomplete">
                <mat-option *ngFor="let user of filterValue" [value]="user.tag">
                    <span>{{user.tag}}</span> ({{ user.count }})
                </mat-option>
                </mat-autocomplete>
            </mat-form-field>
    </div>
    <!-- <div class="happ-desc" *ngIf="field.allow_description && field.description" [innerHTML]="field.description | htmlWithStyle">
    
    </div> -->
</div>