<div fxLayout="column" style="box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); padding: 12px; margin: 12px 0px;">
  <div fxFlex="row" class="mb-8" style="width: 100%;margin-bottom: 12px;border-bottom: 3px solid lightgray;">
    <h2>
      {{ 'ct.add_events' | translate }}
    </h2>
    <span fxFlex></span>
    <div>
      <button mat-raised-button (click)="add()" >
        {{ 'ct.add_new' | translate }}
      </button>
    </div>
  </div>
  <div style="box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); margin-bottom: 12px; padding: 0px 10px;" *ngFor="let item of listData; let i=index">
    <div fxLayout="row" class="m-8">
      <h2 class="m-0 ml-0">
         {{ 'ct.event_name' | translate }}: {{ item.name }}
        <!-- {{ item.title | textTranslate:currentLang }} -->
      </h2>
      <span fxFlex></span>  
      <div>
        <a (click)="add(i)" style="color: blue; cursor: pointer;">{{ 'ct.edit' | translate }}</a>
        <!-- <button mat-raised-button (click)="selectAreas('type', item)">Select category</button> -->
      </div>
    </div>
    <div fxLayout="row"  class="ml-8 mb-8">
      <p class="mr-4">
        {{ 'ct.event_type' | translate }}
      </p>
      <p class="mr-4">
        {{ item.type }}
      </p>
      |
      <p class="mr-4">
        {{ 'ct.max_qty' | translate }}
      </p>
      <p class="mr-4">
        {{ item.maxQty }}
      </p>
    </div>
  </div>
</div>