<div style="background: #f6f6f6;" class="m-0 p-8">
  <div fxFlex fxLayout="header" fxLayoutAlign="space-between center">
    
    <span class="title dialog-title">
      <p>
        <span *ngIf="data && data.row && data.row.signatura">
          {{ data.row.signatura }} /
        </span>
        <span>
          {{ 'dttable.file_list' | translate }}
        </span>
      </p>
    </span>
    <span fxFlex></span>
    <button class="mr-12" (click)="dialogRef.close(null)" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div  class="p-12">
 <div fxLayout="column" *ngIf="data.row">
  <div *ngFor="let item of data.row.connectedFiles">
    <div fxLayout="row" fxLayoutAlign="center center" style="    padding: 6px; border: 1px solid lightgray;">
      <p>
        {{ item.originalName }}
      </p>
      <span fxFlex></span>
      <a (click)="downloadPdf(item._id)"> {{ 'dttable.download' | translate }} </a>
    </div>
  </div>
 </div>
</div>