<mat-toolbar matDialogTitle class="pl-8" fxLayoutAlign="center center" class="call-header-one mat-toolbar mat-accent m-0" fxLayout="row">
<!-- <div style="background: #f6f6f6;" class="m-0 p-8" *ngIf="data"> -->
  <div fxFlex fxLayout="header" fxLayoutAlign="space-between center">
    <span class="title dialog-title">
      <p class="m-0 font-size-20 ml-12"> 
        {{ 'popup.call' | translate }}
      </p>
    </span>
    <span fxFlex></span>
    <button class="mr-12" (click)="close()" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</mat-toolbar>

<!-- </div> -->
<div fxLayout="row" fxLayoutAlign="space-around start">
  <mat-tab-group style="width: 80%;" (selectedTabChange)="tabChanged($event)" [(selectedIndex)]="selectedIndex" >
    <mat-tab [aria-label]="item.callState" *ngFor="let item of mineList; let i=index">
      <ng-template mat-tab-label>
        <!-- <mat-icon class="example-tab-icon mr-8" style="color: green;" *ngIf="item.stateToShow === 'in_call'">call</mat-icon> -->
        <mat-icon  style="color: red;" class="mr-8" *ngIf="i === selectedIndex">adjust</mat-icon>
        <!-- <mat-icon class="example-tab-icon mr-8" style="color: orange;" *ngIf="item.stateToShow === 'ringing_call'">ring_volume</mat-icon> -->
        {{ item.from || item.callId }} - {{ selectedIndex }}
      </ng-template>
      <div class="table-container m-12" fxLayout="row" fxLayoutAlign="space-between start">
        <div fxFlex="49" class="table-cell">
          <h2 class="table-cell-heading"> {{ 'popup.script' | translate }} </h2>
          <div [innerHTML]="data.script | translate | htmlWithStyle" >
          </div>
        </div>
        <div fxFlex="49">
          <div>
            <h2> {{ 'popup.call_info' | translate }} </h2>
            <div>
              <p> {{ 'popup.from' | translate }}: {{ data.from }} </p>
              <p> {{ 'popup.to' | translate }}: {{ data.to }} </p>
              <p> {{ 'popup.call_state' | translate }}: {{ data.callState }} </p>
              <p> {{ 'popup.call_id' | translate }}: {{ data.callId }} </p>
            </div>
          </div>
          <div class="table-cell">
            <!-- <h2 class="table-cell-heading"> {{ 'popup.operators_in_call' | translate }} </h2> -->
              <h2 style="font-size: 30px; color: green;" *ngIf="stateToShow === 'in_call'">
                <strong> {{ 'popup.you_are_in_this_call' | translate }} </strong>
              </h2>
              <h2 style="font-size: 30px; color: red;" *ngIf="stateToShow === 'not_in_call'">
                <strong> {{ 'popup.you_are_not_in_this_call' | translate }} </strong>
              </h2>
              <h2 style="font-size: 30px; color: orange;" *ngIf="stateToShow === 'ringing_call'">
                <strong> {{ 'popup.your_phone_is_rining' | translate }} </strong>
              </h2>
          </div>
          <div class="table-cell">
            <h2 class="table-cell-heading m-0">{{ 'popup.report' | translate }}</h2>
            <ng-container *ngIf="loading">
              <div fxLayoutAlign="center center" class="w-100-p h-400">
                <mat-spinner></mat-spinner>
              </div>
            </ng-container>
            <ng-container *ngIf="!loading">
              <div fxLayout="column">
                <div fxLayout="row wrap" class="mb-12">
                  <ng-container *ngFor="let item of items" >
                    <button mat-raised-button class="mr-4" [color]="(recordData.category===item.key) ? 'primary' : ''" (click)="recordData.category=item.key" >
                      {{ item.title }}
                    </button>
                  </ng-container>
                </div>
              <app-html-editor type="normal" [(model)]="recordData.reportData"></app-html-editor>

                <!-- <mat-form-field class="example-full-width">
                  <mat-label>Favorite food</mat-label>
                  <input matInput placeholder="Ex. Pizza" value="Sushi">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Favorite food</mat-label>
                  <input matInput placeholder="Ex. Pizza" value="Sushi">
                </mat-form-field> -->
                <div fxLayout="row" class="mt-8">
                  <span fxFlex></span>
                  <div>
                    <button mat-raised-button class="mr-8" (click)="saveSpam()" color="warn" > {{ 'popup.mark_spam' | translate }} </button>
                    <button mat-raised-button (click)="saveData()" color="warn" > {{ 'popup.save' | translate }} </button>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container  *ngIf="!loading && (!data || (data && !isOperator)) && !showForm" >
              <div fxLayoutAlign="center center" class="w-100-p h-400">
                {{ 'popup.operator_who_pick_info' | translate }}
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div style="width: 18%; padding: 0px 12px; border: 1px solid #e0e0e0;">
    <h2> {{ 'popup.waiting_calls' | translate }} - {{ (allStatus && allStatus[0] && allStatus[0].currentCalls && allStatus[0].currentCalls.length) || 0 }} </h2>
    <div fxLayout="column">
      <ng-container *ngFor="let item of othersList" >
        <mat-card class="mb-12" *ngIf="item.callState !== 'callstart' && item.callState !== 'callend'">

          <p> {{ item.callState }} </p>
          <p> {{ item.from }} </p>
          <ng-container *ngIf="item.callState === 'callringing'">
            <p> {{(item && item.ringingOn && item.ringingOn.shortCode && item.ringingOn.email)}} </p>
          </ng-container>
          <ng-container *ngIf="item.callState === 'callanswer'">
            <p> {{(item && item.operator && item.operator.shortCode && item.operator.email)}} </p>
          </ng-container>
        </mat-card>
        </ng-container>
    </div>
  </div>
</div>