import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';

@Component({
  selector: 'app-form-editor',
  templateUrl: './form-editor.component.html',
  styleUrls: ['./form-editor.component.scss']
})
export class FormEditorComponent implements OnInit {
  @Input() field: any;
  @Input() regRec: any;
  @Input() model: string; 
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  parsedHtml = '';

  editModel = true;
  editorTypes = {
    admin: ['Source', '-', 'Table',
      'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
      'Bold', 'Italic', '-', 'NumberedList', 'BulletedList', 'FontSize', 'TextColor', '-', 'HorizontalRule', 'Image', 'Link'],
    superAdmin: ['Source', '-', 'Table',
      'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
      'Bold', 'Italic', '-', 'NumberedList', 'BulletedList', 'FontSize', 'TextColor', '-', 'HorizontalRule', 'Image', 'Link'],
    normal: ['Bold', 'Italic', '-', 'NumberedList', 'BulletedList', 'FontSize', 'TextColor', '-', 'Table', 'HorizontalRule']
  };
  config = {
    height: 200,
    toolbar: [
    [ 'Bold', 'Italic', '-', 'NumberedList', 'BulletedList', 'FontSize', 'TextColor', '-', 'Table', 'HorizontalRule' ]
  ]};


  constructor(
    private _translateService: TranslateService,
    private _helperService: HelpersService
  ) { }

  ngOnInit() {
    this.checkForEdit();
    this.config.height = this.field.height || 200;
    this.config.toolbar[0] = this.editorTypes[this.field.editorType] || this.config.toolbar[0];
    this.parsedHtml = this._helperService.replaceTheVariables(this.model, this.regRec);
  }

  refreshData() {
    this.modelChange.next(this.model);
  }

  checkForEdit() {
    this.parsedHtml = this._helperService.replaceTheVariables(this.model, this.regRec);
    this.refreshData();
    if (this.model) {
      this.editModel = false;
    } else {
      this.editModel = true;
    } 
  }

  

}
