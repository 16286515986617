import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { GlobalModule } from '@fuse/global.module';
import { CreateWorkspaceComponent } from './create-workspace.component';
import { SelectIconModule } from '../select-icon/select-icon.module';
import { WorkspaceManagementService } from '../workspace-management.service';
import { HtmlEditorModule } from 'app/main/content/components/html-editor/html-editor.module';

@NgModule({
    imports     : [
       FuseSharedModule,
        GlobalModule,
        SelectIconModule,
        HtmlEditorModule
    ],
    declarations: [
        CreateWorkspaceComponent
    ],
    providers   : [
        WorkspaceManagementService
    ],
    exports: [
        CreateWorkspaceComponent
    ],
    entryComponents: [
        CreateWorkspaceComponent
    ]
})
export class CreateWorkspaceModule
{
}
