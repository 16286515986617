import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { environment } from 'environments/environment';
var ClientOrderPaymentComponent = /** @class */ (function () {
    function ClientOrderPaymentComponent(_helperService, translateService) {
        this._helperService = _helperService;
        this.translateService = translateService;
        this.updateDataFromCt = new EventEmitter();
        this.processUpdateRecord = new EventEmitter();
        this.orderDetail = {};
        this.buttons = [];
        this.recordData = {};
        this.paymentList = [];
        this.reqBody = {
            amount: 0,
            totalAmount: 0,
            paymentSource: 'online',
            paymentMethod: 'przelewy24',
            currencyShortCode: 'PLN',
            purpose: "Select service payment"
        };
    }
    ClientOrderPaymentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.recordData = this.editNg5Service.formData;
        this.currentLang = this.translateService.getDefaultLang();
        this.translateService.onLangChange.subscribe(function (data2) {
            _this.currentLang = data2;
        });
        this.getOrderDetail();
        this.getAllPayments();
        this.getProcessButtons();
    };
    ClientOrderPaymentComponent.prototype.getProcessButtons = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this._helperService.getRequest("api/reg-process/" + this.editNg5Service.formData['instanceId'] + "/getButtons")];
                    case 1:
                        _a.buttons = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ClientOrderPaymentComponent.prototype.getOrderDetail = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(this.editNg5Service && this.editNg5Service.formData && this.editNg5Service.formData.activeOrderId)) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this._helperService.getRegisterRecordData(environment.ORDER_SETTINGS_INFO.regId, this.editNg5Service.formData.activeOrderId)];
                    case 1:
                        _a.orderDetail = _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ClientOrderPaymentComponent.prototype.payOrder = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.loading = true;
                        return [4 /*yield*/, this._helperService.postRequest("api/payment/make-payment", tslib_1.__assign({ continueUrl: "?detail=" + window.location.pathname, paymentType: "przelewy24", serviceId: this.editNg5Service.formData._id }, this.reqBody, { description: "Select service payment", currencyCode: 'PLN', products: [] }))];
                    case 1:
                        data = _a.sent();
                        console.log(data);
                        if (data) {
                            window.location.href = data;
                        }
                        this.loading = false;
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        this._helperService.showInfo(error_1, 'Error', 'error');
                        this.loading = false;
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ClientOrderPaymentComponent.prototype.getAllPayments = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, err_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this;
                        return [4 /*yield*/, this._helperService.getRequest("api/payment/service-payment-list/" + this.editNg5Service.formData._id)];
                    case 1:
                        _a.paymentList = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _b.sent();
                        console.log("err", err_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ClientOrderPaymentComponent.prototype.cancelClick = function () {
        var button = this.buttons[0];
        var record = this.editNg5Service.formData || {};
        if (record.officeId && record['processId'] && record['instanceId'] && button && button.id) {
            console.log(button.loadingText);
            var loading = (button && button.loadingText && (button.loadingText[this.currentLang] || button.loadingText['en'])) || '';
            this.processUpdateRecord.emit({
                isUpdateRecord: !button.dont_update_on_transition,
                updatedData: record,
                regId: record.regId,
                initBehaviour: '',
                loadingText: button.loadingText,
                recId: record._id,
                buttonId: button.id,
                type: 'actButton',
                comment: '',
                navigateTo: "oneRecordView/process/" + record['processId'] + "/instances/" + record['instanceId'] + "/processing/" + button.id + "/" + decodeURI(button.label.replace(/\s+/g, '-')) + "?loading=" + loading
            });
        }
    };
    return ClientOrderPaymentComponent;
}());
export { ClientOrderPaymentComponent };
