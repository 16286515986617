import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { RadioGroupComponent } from './radio-group.component';



@NgModule({
    declarations: [
      RadioGroupComponent
    ],
    imports     : [
       FuseSharedModule
    ],
    exports: [
      RadioGroupComponent
    ]
})

export class RadioGroupModule
{

}
