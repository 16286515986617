<div *ngIf="!field.hide">
    <!-- <mat-list role="list" fxLayout="column">
        <mat-list-item role="listitem" >
          {{ rec.label }}
        </mat-list-item>
    </mat-list> -->
    <mat-nav-list>
        <a (click)="onSelect(rec)" *ngFor="let rec of regRecList;" [style.background]="(model && rec._id == model.importRecId)? 'rgba(200,200,200,0.5)': ''" [style.color]="(model && rec._id == model.importRecId)? 'black': ''" mat-list-item>
          {{ rec.label }}                
        </a>
    </mat-nav-list>
</div>