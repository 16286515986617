import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() model: string; 
  @Input() translateData: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  
    constructor() { }
  
    ngOnInit() {
    }
  
    onSliderChange() {
      this.modelChange.next(this.model);
      if (this.field.haveRules) {
        this.onFieldValueUpdated.emit({
          label: this.field.label,
          value: this.model,
          ruleIndex: this.field.ruleIndex,
          field: this.field
        });
      }
    }
  
}
