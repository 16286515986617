/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./process-graph-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i5 from "@angular/material/progress-spinner";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/common";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i10 from "@angular/material/button";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "@ngx-translate/core";
import * as i13 from "../../../../../@fuse/pipes/htmlWithStyle.pipe";
import * as i14 from "@angular/platform-browser";
import * as i15 from "./process-graph-view.component";
import * as i16 from "../../services/helpers/helpers.service";
import * as i17 from "../../../../../@fuse/services/translation-loader.service";
var styles_ProcessGraphViewComponent = [i0.styles];
var RenderType_ProcessGraphViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProcessGraphViewComponent, data: {} });
export { RenderType_ProcessGraphViewComponent as RenderType_ProcessGraphViewComponent };
function View_ProcessGraphViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "w-100-p h-100-p"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i4.View_MatSpinner_0, i4.RenderType_MatSpinner)), i1.ɵdid(3, 49152, null, 0, i5.MatSpinner, [i1.ElementRef, i6.Platform, [2, i7.DOCUMENT], [2, i8.ANIMATION_MODULE_TYPE], i5.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], function (_ck, _v) { var currVal_0 = "center center"; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 3)._noopAnimations; var currVal_2 = i1.ɵnov(_v, 3).diameter; var currVal_3 = i1.ɵnov(_v, 3).diameter; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); }); }
function View_ProcessGraphViewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "mr-8 mt-4"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadProcessGraph(null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MatButton_0, i9.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i10.MatButton, [i1.ElementRef, i6.Platform, i11.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(2, 0, ["", ""])), i1.ɵpid(131072, i12.TranslatePipe, [i12.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("ct.download")); _ck(_v, 2, 0, currVal_2); }); }
function View_ProcessGraphViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["fxLayout", "row"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["fxFlex", ""]], null, null, null, null, null)), i1.ɵdid(4, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProcessGraphViewComponent_3)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["id", "process-graph-down"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(9, 1), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["id", "canvas-graph-img"], ["style", "display: none"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 2, 0, currVal_0); var currVal_1 = ""; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.showDownloadButton; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent, 0), _co.processView)); _ck(_v, 8, 0, currVal_3); }); }
export function View_ProcessGraphViewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i13.HtmlWithStylePipe, [i14.DomSanitizer]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProcessGraphViewComponent_1)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProcessGraphViewComponent_2)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.loading; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ProcessGraphViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-process-graph-view", [], null, null, null, View_ProcessGraphViewComponent_0, RenderType_ProcessGraphViewComponent)), i1.ɵdid(1, 114688, null, 0, i15.ProcessGraphViewComponent, [i16.HelpersService, i17.FuseTranslationLoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProcessGraphViewComponentNgFactory = i1.ɵccf("app-process-graph-view", i15.ProcessGraphViewComponent, View_ProcessGraphViewComponent_Host_0, { processId: "processId", activeStatusId: "activeStatusId", showActivatedStatus: "showActivatedStatus", translateData: "translateData", showDownloadButton: "showDownloadButton" }, {}, []);
export { ProcessGraphViewComponentNgFactory as ProcessGraphViewComponentNgFactory };
