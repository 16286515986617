/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-editor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../../../node_modules/ngx-ckeditor/ngx-ckeditor.ngfactory";
import * as i4 from "ngx-ckeditor";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../../@fuse/pipes/htmlWithStyle.pipe";
import * as i8 from "@angular/platform-browser";
import * as i9 from "./form-editor.component";
import * as i10 from "../../../services/helpers/helpers.service";
var styles_FormEditorComponent = [i0.styles];
var RenderType_FormEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormEditorComponent, data: {} });
export { RenderType_FormEditorComponent as RenderType_FormEditorComponent };
function View_FormEditorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], [[8, "className", 0], [8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.field.class + " happ-html-preview-area"); var currVal_1 = i1.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.parsedHtml)); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_FormEditorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "happ-field-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.field.title)); _ck(_v, 1, 0, currVal_0); }); }
function View_FormEditorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "happ-placeholder"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.field.title)); _ck(_v, 1, 0, currVal_0); }); }
function View_FormEditorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "happ-html-preview-area"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((((_co.field.showdisablecontroltype == "true") || _co.field.disable) ? null : _co.editModel = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent, 0), _co.parsedHtml)); _ck(_v, 0, 0, currVal_0); }); }
function View_FormEditorComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ck-editor", [["class", "w-100-p"], ["language", "en"], ["name", "editor1"], ["skin", "moono-lisa"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "blur"], [null, "change"], [null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("blur" === en)) {
        var pd_0 = (_co.checkForEdit() !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.refreshData() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.model = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_CKEditorComponent_0, i3.RenderType_CKEditorComponent)), i1.ɵdid(1, 9158656, null, 0, i4.CKEditorComponent, [i1.NgZone, i1.ElementRef], { config: [0, "config"], skin: [1, "skin"], language: [2, "language"], fullPage: [3, "fullPage"] }, { change: "change", blur: "blur" }), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.CKEditorComponent]), i1.ɵdid(3, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(5, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.config; var currVal_8 = "moono-lisa"; var currVal_9 = "en"; var currVal_10 = true; _ck(_v, 1, 0, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = "editor1"; var currVal_12 = _co.model; _ck(_v, 3, 0, currVal_11, currVal_12); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_FormEditorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "mb-20"], ["style", "position: relative;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormEditorComponent_3)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormEditorComponent_4)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormEditorComponent_5)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormEditorComponent_6)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editModel; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.editModel; _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.editModel; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.editModel; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_FormEditorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i7.HtmlWithStylePipe, [i8.DomSanitizer]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormEditorComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormEditorComponent_2)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.field.description; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.field.description; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_FormEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-editor", [], null, null, null, View_FormEditorComponent_0, RenderType_FormEditorComponent)), i1.ɵdid(1, 114688, null, 0, i9.FormEditorComponent, [i2.TranslateService, i10.HelpersService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormEditorComponentNgFactory = i1.ɵccf("app-form-editor", i9.FormEditorComponent, View_FormEditorComponent_Host_0, { field: "field", regRec: "regRec", model: "model" }, { modelChange: "modelChange" }, []);
export { FormEditorComponentNgFactory as FormEditorComponentNgFactory };
