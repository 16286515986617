import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IProfile } from 'app/main/content/interfaces/profile.interface';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { ProfileHelperService } from 'app/main/content/services/profile-helper/profile-helper.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-client-service-selection',
  templateUrl: './client-service-selection.component.html',
  styleUrls: ['./client-service-selection.component.scss']
})
export class ClientServiceSelectionComponent implements OnInit, AfterViewInit {

  @Input() editNg5Service: any;
  @Input() staticProcessStatus: any;
  @Input() staticCommentField: any;
  @Output() updateDataFromCt: EventEmitter<any> = new EventEmitter();
  @Output() processUpdateRecord: EventEmitter<any> = new EventEmitter();

  @Input() translateData: any;

  filteredList = [];
  selectedValues: any = [];
  staticList = []
  comment = '';
  allComments = [];
  showAllComments = false;

  selectedServiceValues = []
  moveData;
  selectedOrderService: any = {};
  view = 'step1';
  currentUser: any;
  constructor(
    private _helperService: HelpersService,
    private _profileHelper: ProfileHelperService

  ) { }
  ngAfterViewInit(): void {
    console.log(this.editNg5Service);
    this.selectedServiceValues = (this.editNg5Service.formData && this.editNg5Service.formData.selectedServiceValues) || [];
    this.selectedOrderService = (this.editNg5Service.formData && this.editNg5Service.formData.selectedOrderService) || {};
    this.selectedServiceValues = this.selectedServiceValues.map((ele) => {
      ele.showVar
      return ele;
    })
  }

  ngOnInit() {
    this.performInitOperation();
    this.getAllProviderServices();
    this.getLoggedUser();

    this.allComments = (this.editNg5Service && this.editNg5Service.formData && this.editNg5Service.formData.recComment) || [];
    this.allComments = this._helperService.transformSort(this.allComments, 'creatingTime');
  }

  async getAllProviderServices() {
    this.filteredList = await this._helperService.getRequest(`api/shared-service/available-provider-services/${this.editNg5Service.formData.providerOfficeId}`);
    this.filteredList = this.filteredList.map((ele) => {
      ele.serviceName = ele.serviceName || (ele && ele.serviceData && ele.serviceData.name);
      if (!Array.isArray(ele.serviceVariants)) {
        ele.serviceVariants = [];  
      }
      ele.serviceVariants = ele.serviceVariants || [];
      ele.serviceVariantsArray = ele.serviceVariants || [];
      ele.selectedVariant = [ele.serviceVariantsArray[0] || {}];

      return ele;
    })
    this.staticList = JSON.parse(JSON.stringify(this.filteredList));
  }
  
  private performInitOperation() {
    this.selectedServiceValues = this.selectedServiceValues || [];
    this.selectedValues = this.selectedServiceValues.map((ele: any) => ele._id);
    this.moveData = this.moveData || ['_id', 'serviceName', "selectedVariant", "serviceVariantsArray"];
  }
  
  private filterOutRegisters() {
    this.filteredList = this.staticList.filter((ele) => {
      if (ele && ele._id && this.selectedValues.includes(ele._id)) {
        return false;
      }
      return true;
    });
  }

  public addRegisterToList(regData) {
    const temp: any = {};

    this.moveData.forEach((ele: any) => {
      temp[ele] = regData[ele];
    });
    this.selectedServiceValues.push(temp);
    console.log(this.selectedServiceValues);
    this.selectedValues.push(regData._id);
    this.filterOutRegisters();
  }

  public removeRegisterFromList(regData: any) {
    const index = _.findIndex(this.selectedServiceValues, { _id: regData._id });
    if (index >= 0) {
      this.selectedServiceValues.splice(index, 1);
      this.selectedValues.splice(this.selectedValues.indexOf(regData._id), 1);
      this.filterOutRegisters();
    }
  }

  onNext() {
    console.log({selectedOrderService: this.selectedOrderService, requiredPayment: this.selectedOrderService.requiredPayment, nextServiceId: this.selectedOrderService._id});
    this.updateDataFromCt.emit({selectedOrderService: this.selectedOrderService, requiredPayment: this.selectedOrderService.requiredPayment, nextServiceId: this.selectedOrderService._id});
  }

  getLoggedUser() {
    this._profileHelper.getLocalProfileData()
    .then((data: IProfile) => {
      this.currentUser = {
        id: data.profileId,
        email: data.email
      };
    }).catch(err => {
      console.info(err);
    });
  }

  testComment() {
    if (this.comment) {
      this.allComments = this.allComments || [];
      this.allComments.push({
       description: this.comment,
       creatorId: this.currentUser.id,
       creator: this.currentUser.email,
       creatingTime: new Date().getTime()
      });
      this.comment = '';
   }
  }

  processUpdateRecordTrigger(event: any) {
    console.log("event");
    if (this.comment) {
       this.editNg5Service.formData.recComment = this.editNg5Service.formData.recComment || [];
       this.editNg5Service.formData.recComment.push({
        description: this.comment,
        creatorId: this.currentUser.id,
        creator: this.currentUser.email,
        creatingTime: new Date().getTime()
       });
      this.comment = '';

    }
    this.processUpdateRecord.emit(event);
  }
}
