import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';

@Component({
  selector: 'app-ss-type-select',
  templateUrl: './ss-type-select.component.html',
  styleUrls: ['./ss-type-select.component.scss']
})
export class SsTypeSelectComponent implements OnInit {

  @Input() translateData: any;
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() model: any = []; 
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onError: EventEmitter<Object> = new EventEmitter();
  
    error = false;
    types;
    currentLang = 'en';

    errorMessage = '';
    constructor(
      private translationLoader: FuseTranslationLoaderService,
      private helperService: HelpersService,
    private translateService: TranslateService,
    ) { 
      this.currentLang = this.translateService.getDefaultLang();
      this.translateService.onLangChange.subscribe((data2: any) => {
        this.currentLang = data2;
      });
  
      this.getTypes();
    }
  

    async getTypes() {
      try {
        const data = await this.helperService.getRequest('api/super-admin/platform-variable/shared-service');
        this.types = (data && data.data) || [];
      } catch (error) {
        alert(error);
      }
    }
    
  ngOnChanges(changes: any) {

    if (changes && changes.translateData && changes.translateData.currentValue) {
      this.translateData = changes.translateData.currentValue;
      this.translationLoader.loadTranslationsV2(this.translateData);    
    }
  }

    ngOnInit() {
      this.translationLoader.loadTranslationsV2(this.translateData);
      if (this.field['required'] && (!this.model || this.model && this.model.length === 0)) {
        this.error = true;
        this.errorMessage = 'ct.field_is_required';
        if (!this.model) {
          this.onError.emit({
            error: true,
            label: this.field['label']
          });
        }
      }
    }
  
    onChangeItem(item) {
      this.model = this.model || [];
      if (item && item.key) {
        if (this.model.includes(item.key)) {
          const index = this.model.indexOf(item.key);
          this.model.splice(index, 1);
        } else {
          this.model.push(item.key);
        }
          this.modelChange.next(this.model);
      }
    }

    changeSingle(item: any) {
      this.model = item.key;
    }
}
