import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule, MatIconModule, MatProgressSpinnerModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { AppRouting } from './app.routing';
import { Login2Component } from './main/content/pages/authentication/login-2/login-2.component';
import { AuthService } from './main/content/pages/authentication/auth.service';
import { ActionService } from './main/content/services/action/action.service';
import { HelpersService } from './main/content/services/helpers/helpers.service';
import { OfficeHelpersService } from './main/content/services/office-helpers/office-helpers.service';
import { ProfileHelperService } from './main/content/services/profile-helper/profile-helper.service';
import { AppDashboardRouteGuard } from './app-dashboard-route.guard';
import { SocketService } from './main/content/services/socket/socket.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { SetDefaultLanguageService } from './main/content/services/set-default-language/set-default-language.service';
import { Register2Component } from './main/content/pages/authentication/register-2/register-2.component';
import { ForgotPasswordComponent } from './main/content/pages/authentication/forgot-password/forgot-password.component';
import { OfficeListModule } from './main/content/apps/office-list/office-list.module';
import { FuseNavigationMenuService } from './navigation/navigation';
import { SystemInfoComponent } from './main/content/pages/system-info/system-info.component';
import { MaintenanceModule } from './main/content/pages/maintenance/maintenence.module';
import { ProfileModule } from './main/content/pages/profile/profile.module';

import { CreateWorkspaceModule } from './main/content/apps/workspace-management/create-workspace/create-workspace.module';
import { UserInfoComponent } from './main/content/pages/user-info/user-info.component';
import { ThemeOptionExtensionService } from './main/content/global-component/theme-option-extension/theme-option-extension.service';
import { EventFormModule } from './main/content/apps/calendar-template/event-form/event-form.module';
import { SetPasswordComponent } from './main/content/pages/authentication/set-password/set-password.component';
import { EditFormPopupModule } from './main/content/apps/custom-registries/edit-form-popup/edit-form-popup.module';
import { InvitationListService } from './main/content/pages/invitation-list/invitation-list.service';
import { PlatformIssueReporterModule } from './main/content/global-component/platform-issue-reporter/platform-issue-reporter.module';
import { GlobalErrorHandler } from './main/content/services/error-handler/error-handler.service';
import { ErrorLogService } from './main/content/services/error-handler/error.handler-helper.service';
import { FieldOptionUpdateDialogService } from './main/content/components/field-option-update-dialog/field-option-update-dialog.service';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/pl';
import localeFrExtra from '@angular/common/locales/extra/pl';
import { VersionCheckService } from './version-check.service';
import { InvitationModule } from './main/content/pages/invitation/invitation.module';
import { AuthInterceptor } from './main/content/pages/authentication/auth.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { OfficeGlobalService } from './main/content/state/officeState/office.global.service';
import { SuperAdminRouteGuard } from './main/content/gaurds/super-admin-route.guard';
import { SaUnauthViewComponent } from './main/content/apps/super-admin/sa-unauth-view/sa-unauth-view.component';
import { PlatformLayoutModule } from './main/content/components/platform-layout/platform-layout.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { SlugServiceService } from './main/content/services/slug-service/slug-service.service';
import { AddBuilderModule } from './main/content/apps/app-builder/app-builder.module';
import { VoipCallModule } from './main/content/apps/voip-call/voip-call.module';
import { OfficeSocketService } from './main/content/services/socket/officeSocket.service';
import { UserSocketService } from './main/content/services/socket/userSocket.service';
import { WorkspaceSocketService } from './main/content/services/socket/workspaceSocket.service';
import { CodeEditorModule } from '@ngstack/code-editor';
import { SsClientLoginComponent } from './main/content/pages/authentication/ss-client-login/ss-client-login.component';
import { SsProdClientForgotPasswordModule } from './main/content/pages/authentication/ss-provider-client-auth/forgot-password/forgot-password.module';
import { SsProdClientLoginModule } from './main/content/pages/authentication/ss-provider-client-auth/login/login.module';
import { SsProdClientRegisterModule } from './main/content/pages/authentication/ss-provider-client-auth/register/register.module';
import { NgxTuiCalendarModule } from 'ngx-tui-calendar';
import { AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider, LinkedInLoginProvider, AuthService as SocialAuthService } from 'angularx-social-login';
import { PendingPaymentModule } from './main/content/components/pending-payment/pending-payment.module';
import { MailingDetailExternalModule } from './main/content/pages/mailing-detail-external/mailing-detail-external.module';
import { ExternalProcessViewModule } from './main/content/apps/external-process-view/external-process-view.module';


let config = new AuthServiceConfig([
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider("112892308088-fi5k9keev2ptlitvov7j46rls2d4ofpo.apps.googleusercontent.com")
    }
  ]);
  export function provideConfig() {
    return config;
  }
  


registerLocaleData(localeFr, 'pl', localeFrExtra);

@NgModule({
    declarations: [
        AppComponent,
        Login2Component,
        SsClientLoginComponent,
        Register2Component,
        ForgotPasswordComponent,
        SystemInfoComponent,
        UserInfoComponent,
        SetPasswordComponent,
        SaUnauthViewComponent,
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MailingDetailExternalModule,
        AppRouting,

        TranslateModule.forRoot(),

        // ToastrModule added
        ToastrModule.forRoot({
            progressBar: true,
            progressAnimation: 'increasing',
            preventDuplicates: true,
            positionClass: 'toast-bottom-full-width'
          }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        FuseSharedModule,

        // App modules
        LayoutModule,
        OfficeListModule,
        MaintenanceModule,
        ProfileModule,
        CreateWorkspaceModule,
        EventFormModule,
        EditFormPopupModule,
        PlatformIssueReporterModule,
        InvitationModule,
        PlatformLayoutModule,
        ReactiveFormsModule,
        FormlyModule.forRoot(),
        FormlyMaterialModule,
        PendingPaymentModule,
        AddBuilderModule,
        VoipCallModule,
        ExternalProcessViewModule,
        CodeEditorModule.forRoot(),
        NgxTuiCalendarModule.forRoot(),
          PendingPaymentModule


    ],
    providers: [
        AuthService,
        ActionService,
        HelpersService,
        OfficeHelpersService,
        ProfileHelperService,
        AppDashboardRouteGuard,
        SuperAdminRouteGuard,
        SocketService,
        UserSocketService,
        WorkspaceSocketService,
        OfficeSocketService,
        FuseNavigationService,
        SetDefaultLanguageService,
        FuseNavigationMenuService,
        ThemeOptionExtensionService,
        InvitationListService,
        ErrorLogService,
        FieldOptionUpdateDialogService,
        VersionCheckService,
        OfficeGlobalService,
        SlugServiceService,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        { provide: LOCALE_ID, useValue: 'pl-PL'},
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        SocialAuthService,
        {
            provide: AuthServiceConfig,
            useFactory: provideConfig
          }

    ],
    entryComponents: [
        UserInfoComponent,
        SystemInfoComponent
    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
