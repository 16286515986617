import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-ct-shared-token-list',
  templateUrl: './ct-shared-token-list.component.html',
  styleUrls: ['./ct-shared-token-list.component.scss']
})
export class CtSharedTokenListComponent implements OnInit {

  @Input() field;
  @Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() regId;
  @Input() builder;
  @Input() translateData;
  @Input() model: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Output() onError: EventEmitter<Object> = new EventEmitter();

  list = [];
  loading = false;

  filterText;
  errorMessage;
  error: boolean;
  filteredNames: any = [];
  constructor(
    private helperService: HelpersService
  ) { }

  ngOnInit() {
    this.getList();
  }

  async getList() {
    try {
      this.loading = true;
      this.list = await this.helperService.getRequest(`api/shared-token/${this.regId}/client-list`);
      this.filteredNames = this.list;
      this.loading = false;

      const user = _.find(this.filteredNames, (ele: any) => {
        return (ele && ele.clientOfficeId && ele.clientOfficeId._id === this.model);
      });
      console.log("user", user);
      this.filterText = (user && user.clientOfficeId && user.clientOfficeId.name);

    } catch (error) {
      console.error(error);
      this.loading = false;
    }
  }

  checkError() {
    // errorValidation
    if (this.field['required'] && (!this.model || this.model && this.model.length === 0)) {
      this.error = true;
      this.errorMessage = 'ct.field_is_required';
      if (!this.model) {
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
      }
    } else {
      this.error = false;
      this.errorMessage = '';
      if (this.model) {
        this.onError.emit({
          error: false,
          label: this.field['label']
        });
      }
    }
  }

  private _filterStates(value: string): any {
    const filterValue = value.toLowerCase();
    return this.list.filter(state => (state.clientOfficeId && state.clientOfficeId.name.toLowerCase().indexOf(filterValue) === 0));
  }

  changed(value) {
    this.filteredNames = this._filterStates(this.filterText);
  }

  onSelection() {
    const user = _.find(this.filteredNames, (ele: any) => {
      return (ele && ele.clientOfficeId && ele.clientOfficeId.name === this.filterText);
    });

    this.model = (user && user.clientOfficeId && user.clientOfficeId._id);
    this.modelChange.next(this.model);

  }

}
