import * as tslib_1 from "tslib";
import { Store, StoreConfig } from '@datorama/akita';
import * as i0 from "@angular/core";
export function createInitialState() {
    var selectedStep = localStorage.getItem('currentStep') || '0';
    return {
        workspaceData: null,
        selectedStep: (parseInt(selectedStep, 10) === NaN) ? 0 : parseInt(selectedStep, 10),
        isWorkspaceAdmin: false
    };
}
var WorkspaceStore = /** @class */ (function (_super) {
    tslib_1.__extends(WorkspaceStore, _super);
    function WorkspaceStore() {
        return _super.call(this, createInitialState()) || this;
    }
    WorkspaceStore.ngInjectableDef = i0.defineInjectable({ factory: function WorkspaceStore_Factory() { return new WorkspaceStore(); }, token: WorkspaceStore, providedIn: "root" });
    WorkspaceStore = tslib_1.__decorate([
        StoreConfig({ name: 'workspace', resettable: true }),
        tslib_1.__metadata("design:paramtypes", [])
    ], WorkspaceStore);
    return WorkspaceStore;
}(Store));
export { WorkspaceStore };
