import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, DateAdapter } from '@angular/material';
import { InputDialogComponent } from '../input-dialog/input-dialog.component';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { HelpersService } from '../../services/helpers/helpers.service';
import { AddTaskComponent } from '../../apps/processes-settings/process-view/add-task/add-task.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-message-settings',
  templateUrl: './message-settings.component.html',
  styleUrls: ['./message-settings.component.scss']
})
export class MessageSettingsComponent implements OnInit {

  processList;
  activityList;
  messageTypes;
  positionFields=[];
  selectedProcessActivity = [];
  templateList = [];
  registerList = [];
  inputDialogRef: MatDialogRef<InputDialogComponent>;
  messageTypeShowEmail = [
      'gdpr-persona-approvalRequest', 
      'gdpr-persona-infoProcessing', 
      'calendar-create-event', 
      'process-start-another-process', 
      'process-create-register-record', 
      'generate-record-report', 
      'update-calendar-event',
      'process-trigger-activity',
      'process-trigger-parent-activity',
      'invite-user-platfrom-office',
      'register-record-report',
      'send-message-back-to-parent',
      'gdpr-persona-appeal-request'
    ];

  constructor(
    public dialogRef: MatDialogRef<AddTaskComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private helperService: HelpersService,
    private translationService: TranslateService,
    private _adapter: DateAdapter<any>
  ) { }

    ngOnInit() {
        this._adapter.setLocale(this.translationService.getDefaultLang());
    
        this.getProcessMessageTypes();
        this.getProcessList();
        this.getTemplateList();
        this.getRegisterList();

        this.data.task = this.data.task || {};
        this.activityList = (this.data['processData'] && this.data['processData']['activities']) || [];
        this.data.task['send_message'] = (this.data.task && this.data.task['send_message']) || {};
        this.data.task['send_message']['positionFields'] = [];
        if (this.data.task['send_message']['messageType'] === 'process-trigger-parent-activity') {
            this.processSelected(this.data.task['send_message']['processId']);
        }
     
    }

    getProcessList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.helperService.getProcessList()
            .then((processList) => {
                this.processList = processList || [];
                resolve(processList);
            }).catch(reject);
        });
    }

    getRegisterList() {
        this.helperService.getSmallRegisterList()
        .then((data) => {
            this.registerList = data || [];
            // this.data.FormField = this.data.FormField || [];
            this.data.task['send_message']['positionFields'] = this.data.FormField.filter(x =>{ return x.control_type === 'position';});
           
        }).catch(err => {
            console.info(err);
        });
    }

    defaultValues(messageType) {
        if (this.data.selectFormField[messageType]) {
            this.data.selectFormField[messageType] = this.data.selectFormField[messageType] || [];
            this.data.selectFormField[messageType].forEach((formField) => {
                this.data.task['send_message'][formField['key']] = this.data.task['send_message'][formField['key']] || {};
                this.data.task['send_message'][formField['key']]['value'] = formField['key'];
                this.data.task['send_message'][formField['key']]['optionType'] = 'formField';
            });
        }
    }

    getProcessMessageTypes() {
        this.helperService.getRequest(`api/get-message-types`)
        .then((data) => {
            this.messageTypes = data || [];
        }).catch(err => {
            console.info(err);
        });
    }

    removeField(field) {
        this.data.selectFormField[this.data.task['send_message']['messageType']] = this.data.selectFormField[this.data.task['send_message']['messageType']] || [];
        const index = _.findIndex(this.data.selectFormField[this.data.task['send_message']['messageType']], {key: field.key, type: 'custom'});
        if (index >= 0) {
            this.data.selectFormField[this.data.task['send_message']['messageType']].splice(index, 1);
        }

        if (this.data.task[this.data.task['taskType']][field.key]) {
            delete this.data.task[this.data.task['taskType']][field.key];
        }
    }

    processSelected(processId) {
        this.helperService.getProcess(processId)
        .then(processData => {
            this.selectedProcessActivity = (processData && processData['activities']) || [];
        }).catch(err => {
            console.info(err);
        });
    }
  
    addField() {
        this.inputDialogRef = this.dialog.open(InputDialogComponent, {
            disableClose: false
        });

        this.inputDialogRef.componentInstance.inputPlaceholder = 'Key Name';
        this.inputDialogRef.componentInstance.confirmMessage = 'Key Name';
        this.inputDialogRef.componentInstance.inputValue = '';

        this.inputDialogRef.afterClosed().subscribe(result => {
            if ( result ) {
                this.data.selectFormField[this.data.task['send_message']['messageType']] = this.data.selectFormField[this.data.task['send_message']['messageType']] || [];
                this.data.selectFormField[this.data.task['send_message']['messageType']].push({
                    key: result,
                    value: result,
                    type: 'custom'
                });
                this.data.task[this.data.task['taskType']][result] = this.data.task[this.data.task['taskType']][result] || {};
                this.data.task[this.data.task['taskType']][result]['value'] = result;
                this.data.task[this.data.task['taskType']][result]['optionType'] = 'formField';
                this.data.task[this.data.task['taskType']][result]['type'] = 'custom';
            }
            this.inputDialogRef = null;
        });
    }

    getTemplateList() {
        try {
            this.helperService.getTemplateList()
                .then((templateList) => {
                    this.templateList = templateList || [];
                }).catch(err => {
                    console.info(err);
                });
        } catch (err) {
            console.info(err);
        }
    }

    openInstructionPopup(infoKey: string) {
        this.helperService.openInstructionPopup(infoKey, 'translation');
    }
}
