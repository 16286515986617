import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewInit, OnChanges } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';

@Component({
  selector: 'app-input-record-validate',
  templateUrl: './input-record-validate.component.html',
  styleUrls: ['./input-record-validate.component.scss']
})
export class InputRecordValidateComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() field: any;
  @Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() translateData: any;
  @Input() builder: any;
  @Input() autoFocus: any;
  @Input() model: string; 
  @Input() regId: string; 
  @Input() recId: string; 
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Output() onError: EventEmitter<Object> = new EventEmitter();
  
  error = false;
  errorMessage = '';
  extraValues: string;
  foundRecords;
  loading = false;
  limitedResult = [];
  showMore = false;
  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private changeDetectorRef: ChangeDetectorRef,
    private helperService: HelpersService
  ) { }

  ngOnInit() {
    this.changeDetectorRef.detectChanges();

    if (this.field['required'] && (!this.model || this.model && this.model.length === 0)) {
      this.error = true;
      this.errorMessage = 'ct.field_is_required';
      this.changeDetectorRef.detectChanges();

      if (!this.model) {
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
      }
    }
  }

  ngOnChanges(changes: any) {

    if (changes && changes.translateData && changes.translateData.currentValue) {
      this.translateData = changes.translateData.currentValue;
      this.translationLoader.loadTranslationsV2(this.translateData);    
    }
  }

  onKeyPress() {

    if (this.field['required'] && (!this.model || this.model && this.model.length === 0)) {
      this.errorMessage = 'ct.field_is_required';
      this.error = true;
      this.onError.emit({
        error: true,
        label: this.field['label']
      });
    } else {
      this.errorMessage = '';
      this.error = false;
      this.onError.emit({
        error: false,
        label: this.field['label']
      });
    }
    this.modelChange.next(this.model);
    this.onFieldValueUpdated.emit({
        label: this.field.label,
        value: this.model,
        ruleIndex: this.field.ruleIndex,
        field: this.field
      });

  }

  ngAfterViewInit() {
  }

  pasteIt(eve) {
    eve.preventDefault();
    this.model = this.model || '';
    this.model += (eve && eve.clipboardData && eve.clipboardData.getData('Text')) || '';
    this.onKeyPress();
  }

  checkValidation() {
    if (this.model) {
      this.field.fieldsToShow = this.field.fieldsToShow || [];
      const filter = {};
      filter[this.field.label] = this.model;
      if (this.recId) {
        filter['_id'] = { $ne: this.recId };
      }
      this.field.regList = this.field.regList || [];
      console.log(filter);
      this.loading = true;
      this.helperService.postRequest(`api/reg/${this.regId}/validate-record`, {
        columns: this.field.fieldsToShow || [],
        filter,
        regList: this.field.regList,
        limit: this.field.limitRec,
        oldRecTime: this.field.oldRecTime
      }).then((data) => {
        this.loading = false;
        this.foundRecords = data || [];
        this.limitedResult = data.slice(0, 5);
        if (data && data.length) {
          data = data[0];
          delete data._id;
          this.errorMessage = 'ct.records_found';
          // this.field.fieldsToShow.forEach((ele: any) => {
          //   this.extraValues = `${data}`;
          // });
          const temp = [];
          // tslint:disable-next-line:forin
          for (const i in data) {
            temp.push(data[i]);
          }
          if (temp.length) {
            this.extraValues = temp.join(', ');
          }
        }
      }).catch(err => {
        this.loading = false;
        this.helperService.showInfo(err, 'Error', 'error');
      });
    }
  }

}
