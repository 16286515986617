import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtmlEditorComponent } from './html-editor.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
  declarations: [HtmlEditorComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
    CKEditorModule
  ],
  exports: [
    HtmlEditorComponent
  ]
})
export class HtmlEditorModule { }
