import { Component, OnInit, Inject, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {MatSnackBar} from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { HelpersService } from '../../services/helpers/helpers.service';
import { TranslateService } from '@ngx-translate/core';
import { CK_EDITOR_CONFIG, CK_EDITOR } from '../../consts/ckEditorConfig.const';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-template-modal',
  templateUrl: './template-modal.component.html',
  styleUrls: ['./template-modal.component.scss'],
})
export class TemplateModalComponent implements OnInit, AfterViewInit {

  temp: string;
  testing
  selectedFormId: any;
  showInputs = false;
  rightPanelView='mainContent';
  rightPanelLang='en';
  officeId: any;
  regList: any[];
  editor = CK_EDITOR;
  currentValue = '';

  sections = [ 
    {
      title: "mainContent",
      key: "mainContent"
    }, 
    {
      title: "header",
      key: "header"
    }, 
    {
      title: "footer",
      key: "footer"
    }];

  options = {
    contextmenu: true,
    minimap: {
      enabled: true
    }
  };
  editorTypes = {
    admin: ['Source', '-', 'Table',
      'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
      'Bold', 'Italic', '-', 'NumberedList', 'BulletedList', 'FontSize', 'TextColor', '-', 'HorizontalRule', 'Image', 'Link'],
    superAdmin: ['Source', '-', 'Table',
      'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
      'Bold', 'Italic', '-', 'NumberedList', 'BulletedList', 'FontSize', 'TextColor', '-', 'HorizontalRule', 'Image', 'Link'],
    normal: ['Bold', 'Italic', '-', 'NumberedList', 'BulletedList', 'FontSize', 'TextColor', '-', 'Table', 'HorizontalRule']
  };
  config = {
    mainContent: {
      height: 700,
      allowedContent: true,
      toolbar: [
        ['Source', '-', 'Table',
        'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
        'Bold', 'Italic', '-', 'NumberedList', 'BulletedList', 'FontSize', 'TextColor', '-', 'HorizontalRule', 'Image', 'Link']
    ]},
    header: {
      height: 700,
      allowedContent: true,
      toolbar: [
        ['Source', '-', 'Table',
        'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
        'Bold', 'Italic', '-', 'NumberedList', 'BulletedList', 'FontSize', 'TextColor', '-', 'HorizontalRule', 'Image', 'Link']
    ]},
    footer: {
      height: 700,
      allowedContent: true,
      toolbar: [
        ['Source', '-', 'Table',
        'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
        'Bold', 'Italic', '-', 'NumberedList', 'BulletedList', 'FontSize', 'TextColor', '-', 'HorizontalRule', 'Image', 'Link']
    ]}
  }

  fieldVars = [
{
  key: "##processRecordReport##",
  pupetter: true,
  wkhtml: true
},
{
  key: "##pageBreak##",
  pupetter: true,
  wkhtml: true
},
{
  key: "##page##",
  pupetter: false,
  wkhtml: true
},
{
  key: "##toPage##",
  pupetter: false,
  wkhtml: true
},
{
  key: "##v2Page##",
  pupetter: true,
  wkhtml: false
},
{
  key: "##v2toPage##",
  pupetter: true,
  wkhtml: false
},
{
  key: "##RESPONSIBLE.SHORTNAME##",
  pupetter: true,
  wkhtml: true
},
{
  key: "##RESPONSIBLE.LONGNAME##",
  pupetter: true,
  wkhtml: true
},
{
  key: "##CLIENTNAME##",
  pupetter: true,
  wkhtml: true
},
{
  key: "##REPORT_NAME##",
  pupetter: true,
  wkhtml: true
},
{
  key: "##REGISTER_NAME##",
  pupetter: true,
  wkhtml: true
},
{
  key: "##PANEL_NAME##",
  pupetter: true,
  wkhtml: true
},
{
  key: "##CREATED_BY##",
  pupetter: true,
  wkhtml: true
},
{
  key: "##REPORT_TIME##",
  pupetter: true,
  wkhtml: true
},
{
  key: "##REPORT_DATE##",
  pupetter: true,
  wkhtml: true
},
{
  key: "##OFFICE_NAME##",
  pupetter: true,
  wkhtml: true
},
{
  key: "##CREATED_BY##",
  pupetter: true,
  wkhtml: true
},
{
  key: "##postBookTemplate##",
  pupetter: true,
  wkhtml: true
},
{
  key: "##positionTable_abc##",
  pupetter: true,
  wkhtml: true
},
{
  key: "##recCommentHTML##",
  pupetter: true,
  wkhtml: true
},
{
  key: "##grid_report_table##",
  pupetter: true,
  wkhtml: true
}
  ]
  temppp;
  previewView = 'html';
  htmlPreviewLang = 'en';
  formVar = [];
  templateData: any = {
    category: '',
    title: '',
    officeId: '',
    typeOfGenerator: 'puppeter',
    logoWidth: '100px',
    logoHeight: '50px',
    shortSymbol: '',
    logoBase64: '',
    type: 'recordTemplate',
    gridColumns: [
      {
        width: 10,
        align: 'LEFT',
        heading: 'New Column 1',
        variables: '##line##'
      }
    ],
    header: {
      show: true,
      editorType: "ck_editor",
      subType: "superAdmin",
      headerTemplate: "custom",
      slogan: '',
      selectedLangs: ['en'],
      content: '',
      translation: {}
    },
    footer: {
      show: true,
      editorType: "ck_editor",
      subType: "superAdmin",
      footerTemplate: 'custom',
      selectedLangs: ['en'],
      pageTemplateVar: '',
      content: '',
      slogan: '',
      translation: {}
    },
    mainContent: {
      editorType: "ck_editor",
      subType: "superAdmin",
      selectedLangs: ['en'],
      content: '',
      translation: {}
    },
    printringVarialbles: {
      line: {
        header: true,
        footer: true
      },
      margin: {
        bottom: 20,
        top: 20
      },
      spacing: {
        footer: 5,
        header: 5
      }
    }
  };
  pdfURL;
  globalVars = [];
  operation;
  currentView = 'editor';
  ckEditorConfig: any;
  defHeaderModel =  [[{
    type: 'input',
    text: 'Demo',
    fontSize: '18px'
  }], [{
    type: 'input',
    text: 'Demo',
    fontSize: '18px'
  }]];

  defFooterModel =  [[{
    type: 'input',
    text: 'Demo',
    fontSize: '18px'
  }], [{
    type: 'input',
    text: 'Demo',
    fontSize: '18px'
  }],
  [{
    type: 'input',
    text: 'Demo',
    fontSize: '18px'
  }]];
  languages: any = [];

  constructor(
    public dialogRef: MatDialogRef<TemplateModalComponent>,
    public snackBar: MatSnackBar, 
    private sanitizer: DomSanitizer,
    private helperService: HelpersService,
    private _translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.ckEditorConfig = JSON.parse(JSON.stringify(CK_EDITOR_CONFIG));
    this.ckEditorConfig.language = this._translateService.getDefaultLang();
    

    //  get the global vars..
    const selectedOffice = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'));
    if (selectedOffice && selectedOffice['users']) {
      selectedOffice['users'].forEach(ele => {
        this.globalVars.push(ele['email']);
      });
    }
    this.operation = this.data['operation'];
    if (this.operation === 'edit') {
      this.templateData = this.data.data;
      if (!this.templateData.title) {
        this.showInputs = true;
      }
      this.initCheck();
      this.templateData.header = this.templateData.header || {content: '', show: true, translation: {}};
      this.templateData.header.content = this.templateData.header.content || '';
      this.templateData.header.translation = this.templateData.header.translation || {};
      this.templateData.footer = this.templateData.footer || {content: '', show: true, translation: {}};
      this.templateData.footer.content = this.templateData.footer.content || '';
      this.templateData.footer.translation = this.templateData.footer.translation || {};
      this.templateData.mainContent.translation = this.templateData.mainContent.translation || {};
      this.templateData.mainContent.editorType = this.templateData.mainContent.editorType || 'ck_editor';
      this.templateData.footer.editorType = this.templateData.footer.editorType || 'ck_editor';
      this.templateData.header.editorType = this.templateData.header.editorType || 'ck_editor';

      this.templateData.mainContent.selectedLangs = this.templateData.mainContent.selectedLangs || ['en'];
      this.templateData.footer.selectedLangs = this.templateData.footer.selectedLangs || ['en'];
      this.templateData.header.selectedLangs = this.templateData.header.selectedLangs || ['en'];
      console.log('template data - ', this.templateData);
    } else {
      this.templateData.mainContent.content = '';

      this.templateData.title = '';
      this.templateData.category = '';
      this.templateData.shortSymbol = '';
    }
    this.getLang();

  }

  async getLang() {
    try {
      this.languages = this.helperService.formatTheLanguageArray(await this.helperService.getActiveLangs());
    } catch (err) {
      console.error(err);
    }
  }

  initCheck() {
    console.log(this.templateData);
    this.onSubTypeChange(null, "mainContent");
    this.onSubTypeChange(null, "header");
    this.onSubTypeChange(null, "footer");
  }

  downloadFile(data){
    const blob = new Blob([data], { type: 'text/pdf' });
    const url = window.URL.createObjectURL(blob);
    // window.open(url);
  }

  saveData(notClose?) {
    if (!this.templateData.isCustom) {
      this.helperService.openConfirmPopUp('You are about to update installed element, if you update it you cannot update this from the marketPlace.')
      .subscribe(async (data) => {
        if (data) {
          this.templateData.isCustom = true;
          this.saveDataHelper(notClose);
        }
      });
    } else {
      delete this.templateData.isCustom;
      this.saveDataHelper(notClose);
    }
  }

  saveDataHelper(notClose?) {
    if (!this.templateData.title && this.templateData.title.length === 0) {
      return this.snackBar.open('Fill title', 'Okey', {
        duration: 2000,
      });
    }
    this.templateData['formId'] = this.selectedFormId;
    if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
      this.templateData.officeId = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'];
      if (this.operation === 'add') {
        this.helperService.createTemplate(this.templateData)
          .then(() => {
            if (!notClose) {
              this.dialogRef.close();
            }
            this.snackBar.open('Saved Successfully', 'Okey', {
              duration: 2000,
            });
          }).catch(err => {
            console.info(err);
            this.snackBar.open(err || 'Error Occured while creating', 'Okey', {
              duration: 2000,
            });
          });
      } else {
        this.helperService.updateTemplate(this.data['data']['_id'], this.templateData)
          .then(() => {
            if (!notClose) {
              this.dialogRef.close();
            }
            this.snackBar.open('Updated Successfully', 'Okey', {
              duration: 2000,
            });
          }).catch(err => {
            console.info(err);
            this.snackBar.open(err || 'Error occured while updating', 'Okey', {
              duration: 2000,
            });
          });
      }
    } else {
      this.snackBar.open('No office Id', 'Okey', {
        duration: 2000,
      });
    }
  }

  updatePDF() {
    if (this.data && this.data['data'] && this.data['data']['_id']) {
      this.helperService.getDocument(this.data['data']['_id'])
        .then((document) => {
          const blob = this.helperService.b64toBlob(document['base64'], 'application/pdf', null);
          const blobUrl = URL.createObjectURL(blob);
          this.pdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
        }).catch(err => {
          this.snackBar.open(err || 'Error occured while updating the template', 'Okey', {
            duration: 2000,
          });
          console.info(err);
        });
    } else {
      this.snackBar.open('No Template Id', 'Okey', {
        duration: 2000,
      });
    }
  }

  ngAfterViewInit() {
    if (this.data.data) {
    this.selectedFormId = this.data['data']['formId'];
    }
  }

  typeChanged() {
    if (this.templateData) {
      this.templateData.gridColumns = this.templateData.gridColumns || [ {
        width: 10,
        align: 'LEFT',
        heading: 'New Column 1',
        variables: '##line##'
      }];
    }
  }

  addColumn() {
    if (this.templateData) {
      this.templateData.gridColumns = this.templateData.gridColumns || [];
      this.templateData.gridColumns.push({
        width: 10,
        align: 'LEFT',
        heading: 'New Column',
        variables: '##line##'
      });
    }
  }
  removeColumn(i: number) {
    if (this.templateData && this.templateData.gridColumns && this.templateData.gridColumns[i]) {
      this.templateData.gridColumns.splice(i, 1);
    }
  }

  dataUpdatedEvent(event: any, type: string) {
    switch (type) {
      case 'header': 
        this.templateData.headerJson = event.json;
        this.templateData.headerJson = event.json;
        this.templateData.header = this.templateData.header || {};
        this.templateData.header.content = event.htmlData;
      break;
      case 'footer':
        this.templateData.footerJson = event.json;
        this.templateData.footerJson = event.json;
        this.templateData.footer = this.templateData.footer || {};
        this.templateData.footer.content = event.htmlData;
      break;
    }
  }

  onSubTypeChange(event: any, type: string) {
    console.log(event, type, this.templateData[type].editorType);
    if (this.templateData[type].editorType === "ck_editor") {
      this.config[type].toolbar[0] = this.editorTypes[this.templateData[type].subType];
    }
    console.log(this.config[type]);
  }

  onCheckBoxChange(lang, type) {
    if (lang !== 'en') {
      this.templateData[type].selectedLangs = this.templateData[type].selectedLangs || [];
      if (this.templateData[type].selectedLangs.includes(lang)) {
        const temp = this.templateData[type].selectedLangs.indexOf(lang);
        this.templateData[type].selectedLangs.splice(temp, 1);
      } else {
        this.templateData[type].selectedLangs.push(lang);
      }
    }
    console.log("this.templateData[type].selectedLangs", this.templateData[type].selectedLangs);
  }

  uploadFile(event: any, file: any) {
    const file2 = file.files[0];
    // 2MB
    if (file2.size > 100000) {
      return this.helperService.showInfo('Image File Must be less than 2MB', 'Error', 'error');
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file2);
      reader.onload = () => {
        // const temp: any = {};
        // temp[keyName] = reader.result;
        // formControl.setValue(temp);
        const img = new Image();
        img.onload = () => {
          // this.height = img.height;
          // this.width = img.width;
          console.log(img.width, img.height);
        };
        img.src =  reader.result as any;
        console.log(reader.result);
        this.templateData.logoBase64 = reader.result as any
      };
    }
  }

  onCodeChanged(eve, rightPanelView, rightPanelLang) {
    console.log("eve ", eve);
    this.templateData[rightPanelView].translation[rightPanelLang] = eve;
  }

  onContentChange(eve, rightPanelView) {
    this.templateData[rightPanelView].content = this.templateData[rightPanelView].translation[this.rightPanelLang];
  }

  changeRightPanelView() {
    if (this.templateData.isMultiLang) {
      this.currentValue = this.templateData[this.rightPanelView].translation[this.rightPanelLang]
    } else {
      this.currentValue = this.templateData[this.rightPanelView].content
    }
  }

  onEnableAggregateChange() {
    this.templateData.aggregateConfig = this.templateData.aggregateConfig || {};
  }
}

// interfaces
interface TemplateForm {
  title: String;
  mainContent: {
    content: String;
  };
  shortSymbol: String;
  category: String;
  officeId: String;
}
