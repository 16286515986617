import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { FormsModule } from '@angular/forms';
import { DateComponent, TemplateModalModule, InputTextModule, 
        ListModule, RadioGroupModule, NumberModule, SliderComponent, DropdownComponent, CheckboxComponent, EmailComponent, CurrencyComponent, 
        PhoneComponent, ButtonListComponent, DeliveryAddressComponent, UserSelectionComponent, OneRecordDocumentComponent,  
        TimeComponent, RegValueSelectionComponent, FilePreviewComponent, 
        DateTimeComponent, CaseToRecIdComponent, GroupSelectionComponent, OrgSelectionComponent, 
        RwaInputComponent, ContactSearchComponent, CalendarRangeComponent, StandaloneButtonsComponent } from '.';
import { FileUploadKeyComponent } from './file-upload-key/file-upload-key.component';
import { InstanceTimelineModule } from './instance-timeline/instance-timeline.module';
import { ButtonListWithTextComponent } from './button-list-with-text/button-list-with-text.component';
import { FileUploadModule } from './file-upload/file-upload.module';
import { ExcelImportComponent } from './excel-import/excel-import.component';
import { MaterialModule } from '@fuse/material.module';
import { DateRangeComponent } from './date-range/date-range.component';
import { MAT_DATE_LOCALE } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { ContactPickComponent } from './contact-pick/contact-pick.component';
import { RelatedRecordListComponent } from './related-records-list/related-records-list.component';
import { AddRecordComponent } from './related-records-list/add-record/add-record.component';
import { FieldOptionUpdateDialog } from '../../components/field-option-update-dialog/field-option-update-dialog.module';
import { OptionBasedRegValueSelectionComponent } from './option-based-reg-value-selection/option-based-reg-value-selection.component';
import { CreateBranchComponent } from './create-branch/create-branch.component';
import { CreateBranchPopupComponent } from './create-branch/create-branch-popup/create-branch-popup.component';
import { ElectronicSenderCountryComponent } from './electronic-sender-country/electronic-sender-country.component';
import { OptionBasedRegValueMultiSelectionComponent } from './option-based-reg-value-multi-selection/option-based-reg-value-multi-selection.component';
import { FormEditorComponent } from './form-editor/form-editor.component';
import { RecordChecklistComponent } from './record-checklist/record-checklist.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { SelectTemplatePopupModule } from '../../components/select-template-popup/select-template-popup.module';
import { SelectTemplateComponent } from './select-template/select-template.component';
import { SettingItemViewModule } from '../../components/setting-item-view/setting-item-view.module';
import { SaveContactInfoPopupComponent } from './delivery-address/save-contact-info-popup/save-contact-info-popup.component';
import { OptionBasedAutoCompleteComponent } from './option-based-auto-complete/option-based-auto-complete.component';
import { InputRecordValidateComponent } from './input-record-validate/input-record-validate.component';
import { FileRetryModule } from './file-upload/file-retry-view/file-retry-view.module';
import { StatusToggleComponent } from './status-toggle/status-toggle.component';
import { MoveToAnyStatusComponent } from './move-to-any-status/move-to-any-status.component';
import { CtSharedTokenListComponent } from './ct-shared-token-list/ct-shared-token-list.component';
import { CtRegSelectionComponent } from './ct-reg-selection/ct-reg-selection.component';
import { SharedServicePositionModule } from './shared-service-position/shared-service-position.module';
import { ProcessButtonCommentPopupModule } from '../process-button-comment-popup/process-button-comment-popup.module';
import { CtInfoSourceSelectComponent } from './ct-info-source-select/ct-info-source-select.component';
import { RecordCommentModule } from './record-comment/record-comment.module';
import { CtServiceSelectionComponent } from './ct-service-selection/ct-service-selection.component';
import { CtServiceVariantComponent } from './ct-service-variant/ct-service-variant.component';
import { CtAddServiceVariantComponent } from './ct-service-variant/ct-add-service-variant/ct-add-service-variant.component';
import { SsTypeSelectComponent } from './ss-type-select/ss-type-select.component';
import { CtTemplateSelectionComponent } from './ct-template-selection/ct-template-selection.component';
import { SelectTemplateModule } from '../drag-and-drop/select-template/select-template.module';
import { DescriptionPopupModule } from '../datatable/description-popup/description-popup.module';
import { TemplatePreviewModule } from '../../components/template-preview/template-preview.module';
import { CalOfficeSelectionComponent } from './cal-office-selection/cal-office-selection.component';
import { RoomViewLinkComponent } from './room-view-link/room-view-link.component';
import { RouterModule } from '@angular/router';
import { SlotSelectionSettingsComponent } from './slot-selection-settings/slot-selection-settings.component';
import { GeoLocationComponent } from './geo-location/geo-location.component';
import { environment } from 'environments/environment';
import { AgmCoreModule } from '@agm/core';
import { CtSsCatSelectionComponent } from './ct-ss-cat-selection/ct-ss-cat-selection.component';
import { CtSsCatPopupComponent } from './ct-ss-cat-selection/ct-ss-cat-popup/ct-ss-cat-popup.component';
import { SsEventFormComponent } from './ss-event-form/ss-event-form.component';
import { SsEventFormAddPopupComponent } from './ss-event-form/ss-event-form-add-popup/ss-event-form-add-popup.component';
import { SsEventFormConfigComponent } from './ss-event-form-config/ss-event-form-config.component';
import { CurrencyListComponent } from './currency-list/currency-list.component';
import { ExtBankCheckComponent } from './ext-bank-check/ext-bank-check.component';
import { CompanyDetailCheckPopupComponent } from './ext-bank-check/company-detail-check-popup/company-detail-check-popup.component';
import { ButtonListWithTagComponent } from './button-list-with-tag/button-list-with-tag.component';
import { MailingListActionComponent } from './mailing-list-action/mailing-list-action.component';
import { MailingListActionPopupComponent } from './mailing-list-action/mailing-list-action-popup/mailing-list-action-popup.component';
import { SmtpSettingsModule } from '../smtp-settings/smtp-settings.module';
import { MailingListLoadingPopupComponent } from './mailing-list-action/mailing-list-loading-popup/mailing-list-loading-popup.component';
import { MailingFooterComponent } from './mailing-list-action/mailing-footer/mailing-footer.component';
import { MailingFooterListComponent } from './mailing-list-action/mailing-footer-list/mailing-footer-list.component';
import { MailingLogComponent } from './mailing-log/mailing-log.component';
import { MapClusterComponent } from './map-cluster/map-cluster.component';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
@NgModule({
    imports     : [
       FuseSharedModule,
        MaterialModule,
        FormsModule,
        TemplateModalModule,
        InputTextModule,
        ListModule,
        RadioGroupModule,
        RouterModule,
        NumberModule,
        InstanceTimelineModule,
        FileUploadModule, 
        TranslateModule,
        FieldOptionUpdateDialog,
        CKEditorModule,
        SelectTemplatePopupModule,
        SettingItemViewModule,
        FileRetryModule,
        SharedServicePositionModule,
        ProcessButtonCommentPopupModule,
        RecordCommentModule,
        SelectTemplateModule,
        TemplatePreviewModule,
        DescriptionPopupModule,
        FusePipesModule,
        SmtpSettingsModule,
    AgmCoreModule.forRoot({
            apiKey: environment.G_MAP_API,
            libraries: ['places']
        }),
    ],
    declarations: [
        DateComponent,
        SliderComponent,
        DropdownComponent,
        CheckboxComponent,
        EmailComponent,
        CurrencyComponent,
        PhoneComponent,
        ButtonListComponent,
        DeliveryAddressComponent,
        UserSelectionComponent,
        OneRecordDocumentComponent,
        TimeComponent,
        RegValueSelectionComponent,
        FilePreviewComponent,
        DateTimeComponent,
        DateRangeComponent,
        CaseToRecIdComponent,
        GroupSelectionComponent,
        OrgSelectionComponent,
        RwaInputComponent,
        ContactSearchComponent,
        CalendarRangeComponent,
        FileUploadKeyComponent,
        ButtonListWithTextComponent,
        ExcelImportComponent,
        ContactPickComponent,
        RelatedRecordListComponent,
        AddRecordComponent,
        StandaloneButtonsComponent,
        OptionBasedRegValueSelectionComponent,
        CreateBranchComponent,
        CreateBranchPopupComponent,
        ElectronicSenderCountryComponent,
        OptionBasedRegValueMultiSelectionComponent,
        FormEditorComponent,
        RecordChecklistComponent,
        SelectTemplateComponent,
        SaveContactInfoPopupComponent,
        OptionBasedAutoCompleteComponent,
        InputRecordValidateComponent,
        MoveToAnyStatusComponent,
        StatusToggleComponent,
        CtSharedTokenListComponent,
        CtRegSelectionComponent,
        CtInfoSourceSelectComponent,
        CtServiceSelectionComponent,
        CtServiceVariantComponent,
        CtAddServiceVariantComponent,
        SsTypeSelectComponent,
        CtTemplateSelectionComponent,
        CalOfficeSelectionComponent,
        RoomViewLinkComponent,
        SlotSelectionSettingsComponent,
        GeoLocationComponent,
        CtSsCatSelectionComponent,
        CtSsCatPopupComponent,
        SsEventFormComponent,
        SsEventFormAddPopupComponent,
        SsEventFormConfigComponent,
        CurrencyListComponent,
        ExtBankCheckComponent,
        CompanyDetailCheckPopupComponent,
        ButtonListWithTagComponent,
        MailingListActionComponent,
        MailingListActionPopupComponent,
        MailingListLoadingPopupComponent,
        MailingFooterComponent,
        MailingFooterListComponent,
        MailingLogComponent,
        MapClusterComponent
    ],
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: 'pl-PL'}
    ],
    exports: [
        // FileUploadComponent,
        DateComponent,
        SliderComponent,
        DropdownComponent,
        CheckboxComponent,
        EmailComponent,
        CurrencyComponent,
        PhoneComponent,
        ButtonListComponent,
        DeliveryAddressComponent,
        UserSelectionComponent,
        OneRecordDocumentComponent,
        TimeComponent,
        RegValueSelectionComponent,
        FilePreviewComponent,
        InputTextModule,
        ListModule,
        RadioGroupModule,
        NumberModule,
        DateTimeComponent,
        DateRangeComponent,
        CaseToRecIdComponent,
        GroupSelectionComponent,
        OrgSelectionComponent,
        RwaInputComponent,
        ContactSearchComponent,
        CalendarRangeComponent,
        FileUploadKeyComponent,
        InstanceTimelineModule,
        ButtonListWithTextComponent,
        FileUploadModule,
        ExcelImportComponent,
        ContactPickComponent,
        RelatedRecordListComponent,
        AddRecordComponent,
        StandaloneButtonsComponent,
        OptionBasedRegValueSelectionComponent,
        CreateBranchComponent,
        CreateBranchPopupComponent,
        ElectronicSenderCountryComponent,
        OptionBasedRegValueMultiSelectionComponent,
        FormEditorComponent,
        RecordChecklistComponent,
        SelectTemplateComponent,
        SaveContactInfoPopupComponent,
        OptionBasedAutoCompleteComponent,
        InputRecordValidateComponent,
        MoveToAnyStatusComponent,
        StatusToggleComponent,
        CtSharedTokenListComponent,
        CtInfoSourceSelectComponent,
        CtServiceSelectionComponent,
        CtServiceVariantComponent,
        CtAddServiceVariantComponent,
        SsTypeSelectComponent,
        CtTemplateSelectionComponent,
        CalOfficeSelectionComponent,
        SlotSelectionSettingsComponent,
        RoomViewLinkComponent,
        GeoLocationComponent,
        CtSsCatSelectionComponent,
        CtSsCatPopupComponent,
        SsEventFormAddPopupComponent,
        SsEventFormConfigComponent,
        SsEventFormComponent,
        CurrencyListComponent,
        ExtBankCheckComponent,
        CompanyDetailCheckPopupComponent,
        ButtonListWithTagComponent,
        MailingListActionComponent,
        MailingListActionPopupComponent,
        MailingListLoadingPopupComponent,
        MailingFooterComponent,
        MailingFooterListComponent,
        MailingLogComponent,
        MapClusterComponent
    ],
    entryComponents: [
        AddRecordComponent,
        CreateBranchPopupComponent,
        SaveContactInfoPopupComponent,
        CtAddServiceVariantComponent,
        SsEventFormAddPopupComponent,
        CtSsCatPopupComponent,
        CompanyDetailCheckPopupComponent,
        MailingListActionPopupComponent,
        MailingListLoadingPopupComponent,
        MailingFooterComponent,
        MailingFooterListComponent
    ]
})
export class ControlTemplateModule
{
}
