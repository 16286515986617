import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ChangeDetectorRef, OnChanges } from '@angular/core';

import * as moment from 'moment';
import { DateAdapter } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss']
})
export class DateTimeComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() model: any;
  @Input() translateData: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onError: EventEmitter<Object> = new EventEmitter();
  
    error = false;
    dateTime: Date | string;
    date: Date | string;
    time = '00:00';
    durationSlot = 15;
    timeSlots: Array<string> = [];
    errorMessage = '';
    constructor(
      private cdr: ChangeDetectorRef,
      private adapter: DateAdapter<any>,
      private translationService: TranslateService,
      private translationLoader: FuseTranslationLoaderService,  
      ) {
        this.adapter.setLocale(this.translationService.getDefaultLang());
        this.translationService.onLangChange.subscribe((lang) => {
          if (lang && lang.lang) {
            this.adapter.setLocale(lang.lang);
          }
        });
    }
  
    
  ngOnChanges(changes: any) {

    if (changes && changes.translateData && changes.translateData.currentValue) {
      this.translateData = changes.translateData.currentValue;
      this.translationLoader.loadTranslationsV2(this.translateData);    
    }
  }

    ngOnInit() {
      this.translationLoader.loadTranslationsV2(this.translateData);
      this.durationSlot = this.field['durationSlot'] || this.durationSlot;
      let mins = 0;
      // tslint:disable-next-line:radix
      this.durationSlot = parseInt(this.durationSlot + '');
      let i = 0;
      while (i < 24) {
        if (mins >= 60) {
          mins = 0;
          i++;
        }
        this.timeSlots.push(((i < 10) ? '0' + i : '' + i) + ':' + ((mins < 10) ? '0' + mins : '' + mins));
        mins += this.durationSlot;
      }
      if (this.field['required'] && (!this.model)) {
        this.error = true;
        this.errorMessage = 'ct.field_is_required';
        if (!this.model) {
          this.onError.emit({
            error: true,
            label: this.field['label']
          });
        }
      }
    }
  
    ondateChange() {
      if (this.date) {
        this.model = moment(this.date).format('YYYY-MM-DD') + ' ' + this.time;
        this.modelChange.next(moment(this.date).format('YYYY-MM-DD') + ' ' + this.time);
      }

      if (this.field['required'] && (!this.model)) {
        this.errorMessage = 'ct.field_is_required';
        this.error = true;
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
      } else {
        this.errorMessage = '';
        this.error = false;
        this.onError.emit({
          error: false,
          label: this.field['label']
        });
      }
      if (this.field.haveRules) {
        this.onFieldValueUpdated.emit({
          label: this.field.label,
          value: this.model,
          ruleIndex: this.field.ruleIndex,
          field: this.field
        });
      }
    }
  
    ngAfterViewInit() {
      if (!this.model) {
        if (this.field.default_value === 'now') {
          this.model = new Date();
          this.date = new Date();
          this.time = moment(this.model).format('HH:mm');
          this.time = this.getNearestTime(this.time);
        } else if (this.field.default_value === 'empty') {
          this.model = null;
        }
        this.ondateChange();
      } else {
        this.model = new Date(this.model);
        this.date = new Date(this.model);
        this.time = moment(this.model).format('HH:mm');
        this.time = this.getNearestTime(this.time);
        console.log("this.time - ", this.time);
        this.ondateChange();
        this.cdr.detectChanges();
      }
      this.dateTime = this.model;
      this.cdr.detectChanges();
    }

    getNearestTime(time: string): string {

      let tempTime = 0;
      if (time) {
        // tslint:disable-next-line:radix
        const min = (time.split(':') && time.split(':')[1] && parseInt(time.split(':')[1])) || 0;
        while (tempTime < min && tempTime < 60) {
          tempTime += this.durationSlot;
        }
        if (tempTime === 60) {
          tempTime = 0;
          // tslint:disable-next-line:radix
          time = (time.split(':') && time.split(':')[0] && (parseInt(time.split(':')[0]) + 1)) + ':' + ((tempTime < 10) ? '0' + tempTime : '' + tempTime);
        } else {
          time = (time.split(':') && time.split(':')[0]) + ':' + ((tempTime < 10) ? '0' + tempTime : '' + tempTime);
        }
      }
      return time;
    }
  
}
