import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';


@Pipe({name: 'contains'})
export class ContainsPipe implements PipeTransform
{
    /**
     * Transform
     *
     * @param value
     * @param {string[]} args
     * @returns {any}
     */
    transform(value: any = [], filter: any = {}): any
    {
        const filterLength = Object.keys(filter).length;
        return value.filter((ele: any) => {
            let count = 0;
            for (const i in filter) {
                if (ele && filter[i] && ele[i] && ele[i].toLowerCase().includes(filter[i].toLowerCase())) {
                    count++;
                } else if (!filter[i]) {
                    count++;
                }
            }
            if (count === filterLength) {
                return true;
            } else {
                return false;
            }
        });
    }
}
