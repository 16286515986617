<div *ngIf="loading" class="w-100-p h-100-p" fxLayoutAlign="center center">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="!loading">
  <div fxLayout="row">
    <span fxFlex></span>
    <div>
      <button class="mr-8 mt-4" mat-raised-button *ngIf="showDownloadButton" (click)="downloadProcessGraph(null)" >{{ 'ct.download' | translate }}</button>
    </div>
  </div>
  <div id="process-graph-down" [innerHTML]="processView | htmlWithStyle">  

  </div>
  <div style="display: none" id="canvas-graph-img">

  </div>
</div>