import * as tslib_1 from "tslib";
import { NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AuthStore } from '../../state/authState/auth.store';
import { OfficeStore } from '../../state/officeState/office.store';
import * as _ from 'lodash';
import * as jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { SocketService } from '../../services/socket/socket.service';
import { WorkspaceStore } from '../../state/workspaceState/workspace.store';
var AuthService = /** @class */ (function () {
    function AuthService(http, router, zone, snackBar, dialog, authStore, officeStore, cookieService, workspaceStore, socketService) {
        this.http = http;
        this.router = router;
        this.zone = zone;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.authStore = authStore;
        this.officeStore = officeStore;
        this.cookieService = cookieService;
        this.workspaceStore = workspaceStore;
        this.socketService = socketService;
        this.tokenKey = 'ngStorage-token';
        this.isAuthenticated = false;
        this.username = new Subject();
        this.authToken = undefined;
        this.onUserNameChanged = new BehaviorSubject(null);
        this.onOfficeChange = new BehaviorSubject({});
        this.setCrossSubdomainCookie = function (name, value, days) {
            var assign = name + '=' + escape(value) + ';';
            var d = new Date();
            d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
            var expires = 'expires=' + d.toUTCString() + ';';
            var path = 'path=/;';
            var domain;
            if (document.domain === 'localhost') {
                domain = document.domain;
            }
            else {
                domain = 'domain=' + (document.domain.match(/[^\.]*\.[^.]*$/)[0]) + ';';
            }
            document.cookie = assign + expires + path + domain;
        };
    }
    AuthService.prototype.sendUsername = function (name) {
        this.username.next(name);
    };
    AuthService.prototype.clearUsername = function () {
        this.username.next(undefined);
        this.onUserNameChanged.next(undefined);
    };
    AuthService.prototype.loadUserCredentials = function () {
        var credentials = JSON.parse(localStorage.getItem(this.tokenKey));
        if (credentials && credentials.username !== undefined) {
            this.useCredentials(credentials);
        }
    };
    AuthService.prototype.useCredentials = function (credentials) {
        this.isAuthenticated = true;
        this.sendUsername(credentials.username);
        this.authToken = credentials.token;
    };
    AuthService.prototype.destroyUserCredentials = function () {
        this.authToken = undefined;
        this.clearUsername();
        this.authStore.reset();
        this.isAuthenticated = false;
        localStorage.removeItem(this.tokenKey);
        localStorage.removeItem('ngStorage-token');
        localStorage.removeItem('ngStorage-profile');
        localStorage.removeItem('ngStorage-selectedOffice');
        localStorage.clear();
    };
    AuthService.prototype.signUp = function (user) {
        var header = new HttpHeaders();
        header.append('Content-Type', 'application/json');
        return this.http
            .post(environment.serverUrl + 'api/registration', user, {
            headers: header
        })
            .map(function (res) {
            if (res['success']) {
                return { error: false };
            }
            else {
                return { error: true, message: (res['message']) };
            }
        });
    };
    AuthService.prototype.fail = function () {
        var _this = this;
        this.zone.run(function () {
            _this.snackBar.open('Invalid  login data.', null, {
                duration: 7000,
                panelClass: 'snackbar-error'
            });
        });
    };
    AuthService.prototype.getRedirectURL = function () {
        var redirectURL = localStorage.getItem('redirectUrl');
        if (redirectURL) {
            localStorage.removeItem('redirectUrl');
        }
        return redirectURL;
    };
    AuthService.prototype.logIn = function (user) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var header = new HttpHeaders();
            header.append('Content-Type', 'application/json');
            var url = environment.serverUrl + "api/auth";
            if (user && user.provider === "GOOGLE") {
                url = environment.serverUrl + "api/google-auth";
            }
            return _this.http.post(url, user, { headers: header })
                .subscribe(function (data) {
                if (data['success'] && data['result']) {
                    data = data['result'] || {};
                    _this.afterLogin(data.token);
                    resolve(true);
                }
                else {
                    reject(data);
                }
            }, function (err) {
                reject(err);
            });
        });
    };
    AuthService.prototype.afterLogin = function (token) {
        var _this = this;
        try {
            this.setToken(token);
            var header = new HttpHeaders({
                'Authorization': token,
                'Content-Type': 'application/json'
            });
            this.http.get(environment.serverUrl + "api/profile/contactData", { headers: header })
                .subscribe(function (data) {
                console.log("data = ", data);
                data = data.result;
                _this.loadAllData(data);
                data.extraInfo = data.extraInfo || {};
                if (data.extraInfo.initUrl) {
                    localStorage.setItem("initUrl", data.extraInfo.initUrl);
                }
                data.extraInfo.initUrl = _this.getRedirectURL() || data.extraInfo.initUrl || '/dashboard';
                var officeDoc = (data.extraInfo && data.extraInfo.officeDoc) || {};
                if (data.extraInfo.tempInitUrl) {
                    localStorage.setItem("tempInitUrl", data.extraInfo.tempInitUrl);
                }
                if (data.extraInfo.workspaceDoc) {
                    window.localStorage.setItem("temp-ws", JSON.stringify(data.extraInfo.workspaceDoc));
                }
                _this.authStore.update(tslib_1.__assign({ token: token, redirectUrl: _this.getRedirectURL() || data.extraInfo.initUrl || '/dashboard' }, _this.getOfficeInfo(data.extraInfo), _this.isUserAdmin(data), _this.getUserInfo(data.contactData)));
                if (officeDoc.ussageBalance) {
                    // open popover.
                    // const dialogRef = this.dialog.open(PendingPaymentComponent, {
                    //     width: '100%',
                    //     height: '100%',
                    //     disableClose: true,
                    //     panelClass: 'happ-form-builder-dialog',
                    //     data: {officeDoc }
                    //   });
                    setTimeout(function () {
                        _this.router.navigate(["/pending-payment"], { queryParams: { redirectUrl: _this.getRedirectURL() || data.extraInfo.initUrl || '/dashboard' } });
                    }, 400);
                }
                else {
                    _this.router.navigate([data.extraInfo.initUrl]);
                }
            }, function (err) {
                console.log(err);
            });
        }
        catch (error) {
            throw error;
        }
    };
    AuthService.prototype.getOfficeInfo = function (extraInfo) {
        if (extraInfo === void 0) { extraInfo = {}; }
        return {
            officeId: (extraInfo.officeDoc && extraInfo.officeDoc._id) || '',
            officeName: (extraInfo.officeDoc && extraInfo.officeDoc.name) || '',
        };
    };
    AuthService.prototype.isUserAdmin = function (_a) {
        var extraInfo = _a.extraInfo, contactData = _a.contactData;
        try {
            var officeDoc = extraInfo.officeDoc || {};
            var profileDoc = contactData || {};
            var usrDoc = _.find(officeDoc.users || [], { id: profileDoc.profileId });
            if (usrDoc && usrDoc['isAdmin']) {
                return { isAdmin: true };
            }
            else {
                return { isAdmin: false };
            }
        }
        catch (error) {
            throw error;
        }
    };
    ;
    AuthService.prototype.getUserInfo = function (contactData) {
        if (contactData === void 0) { contactData = {}; }
        return {
            email: (contactData.email) || '',
            profileId: (contactData.profileId) || '',
            defaultLang: (contactData.defaultLang) || 'en'
        };
    };
    AuthService.prototype.setToken = function (token) {
        var decodedToken = jwt_decode(token);
        var credentials = {
            username: decodedToken['login'],
            token: token,
            date: decodedToken.date
        };
        this.setCrossSubdomainCookie('token', token, 7);
        this.isAuthenticated = true;
        localStorage.setItem(this.tokenKey, JSON.stringify(credentials));
    };
    AuthService.prototype.loadAllData = function (res) {
        if (res === void 0) { res = {}; }
        localStorage.setItem('ngStorage-profile', JSON.stringify(res['contactData'] || {}));
        localStorage.setItem('selectedLanguage', (res['contactData'] && res['contactData']['defaultLang']) || 'en');
        if (res.extraInfo && res.extraInfo.officeDoc) {
            this.authStore.update({ officeName: res.extraInfo.officeDoc.name });
            this.officeStore.update(res.extraInfo.officeDoc);
            localStorage.setItem('selectedOfficeId', JSON.stringify(res.extraInfo.officeDoc['_id']));
            localStorage.setItem('ngStorage-selectedOffice', JSON.stringify(res.extraInfo.officeDoc));
            this.onOfficeChange.next(res.extraInfo.officeDoc['_id']);
        }
    };
    AuthService.prototype.logOut = function (route) {
        if (route === void 0) { route = "/login"; }
        this.destroyUserCredentials();
        var loc = window.location.pathname;
        this.authStore.reset();
        this.officeStore.reset();
        this.workspaceStore.reset();
        this.socketService.disconnectSocket();
        this.cookieService.deleteAll();
        this.officeStore.reset();
        console.log("3333333");
        if (!(loc === '/login' ||
            loc === '/register' ||
            loc === '/cp-login' ||
            loc.includes('/mail-detail') ||
            loc.includes('/set-password') ||
            loc.includes('/checkout') ||
            loc.includes('/provider-client') ||
            loc.includes('/userInvitation') ||
            loc.includes('/changepassword'))) {
            this.router.navigate([route]);
        }
    };
    AuthService.prototype.isLoggedIn = function () {
        return this.isAuthenticated;
    };
    AuthService.prototype.refreshToken = function () {
        return new Promise(function (resolve, reject) {
            resolve();
        });
    };
    /**
     * to check if auth is in time range or not..
     */
    AuthService.prototype.isAuth = function () {
        if (JSON.parse(localStorage.getItem(this.tokenKey))) {
            var credentials = JSON.parse(localStorage.getItem(this.tokenKey));
            var loginTime = credentials['date'] || 0;
            var expTime = Math.floor(loginTime / 1000) + 8 * 60 * 60;
            var now = Math.floor(Date.now() / 1000);
            var timeToExpire = expTime - now;
            if (timeToExpire < 60 * 60 + 60 * 60 && loginTime) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            return false;
        }
    };
    return AuthService;
}());
export { AuthService };
