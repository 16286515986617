/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shared-service-position.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../../../../../../@fuse/pipes/registries-list.pipe";
import * as i10 from "./shared-service-position.component";
import * as i11 from "../../../services/helpers/helpers.service";
import * as i12 from "@angular/material/dialog";
var styles_SharedServicePositionComponent = [i0.styles];
var RenderType_SharedServicePositionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SharedServicePositionComponent, data: {} });
export { RenderType_SharedServicePositionComponent as RenderType_SharedServicePositionComponent };
function View_SharedServicePositionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("ct.action")); _ck(_v, 2, 0, currVal_0); }); }
function View_SharedServicePositionComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_v.context.$implicit.title)); _ck(_v, 1, 0, currVal_0); }); }
function View_SharedServicePositionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedServicePositionComponent_4)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SharedServicePositionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "td", [], [[4, "width", null], [4, "textAlign", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i3.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedServicePositionComponent_2)), i1.ɵdid(4, 278528, null, 0, i3.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i3.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedServicePositionComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i3.NgSwitch], null, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit.key; _ck(_v, 2, 0, currVal_2); var currVal_3 = "action"; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.width; var currVal_1 = _v.context.$implicit.textAlign; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SharedServicePositionComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editForm(_v.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(3, 0, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("ct.edit")); _ck(_v, 3, 0, currVal_2); }); }
function View_SharedServicePositionComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.parent.context.$implicit[_v.context.$implicit.key]; _ck(_v, 1, 0, currVal_0); }); }
function View_SharedServicePositionComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedServicePositionComponent_9)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SharedServicePositionComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "td", [], [[4, "width", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i3.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedServicePositionComponent_7)), i1.ɵdid(4, 278528, null, 0, i3.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i3.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedServicePositionComponent_8)), i1.ɵdid(6, 16384, null, 0, i3.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i3.NgSwitch], null, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.key; _ck(_v, 2, 0, currVal_1); var currVal_2 = "action"; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.width; _ck(_v, 0, 0, currVal_0); }); }
function View_SharedServicePositionComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedServicePositionComponent_6)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.columns; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SharedServicePositionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "p-12 mb-20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "table", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedServicePositionComponent_1)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 3, null, View_SharedServicePositionComponent_5)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpad(8, 1), i1.ɵpid(0, i9.FullTextSearchPipe, [])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.columns; _ck(_v, 5, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 9).transform(_co.data, _co.query, _ck(_v, 8, 0, "name"))); _ck(_v, 7, 0, currVal_1); }, null); }
export function View_SharedServicePositionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-shared-service-position", [], null, null, null, View_SharedServicePositionComponent_0, RenderType_SharedServicePositionComponent)), i1.ɵdid(1, 114688, null, 0, i10.SharedServicePositionComponent, [i11.HelpersService, i12.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SharedServicePositionComponentNgFactory = i1.ɵccf("app-shared-service-position", i10.SharedServicePositionComponent, View_SharedServicePositionComponent_Host_0, { recId: "recId" }, {}, []);
export { SharedServicePositionComponentNgFactory as SharedServicePositionComponentNgFactory };
