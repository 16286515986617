import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { OfficeListPopupComponent } from '../../apps/office-list-popup/office-list-popup.component';
import { HelpersService } from '../../services/helpers/helpers.service';

@Component({
  selector: 'app-all-fields-view',
  templateUrl: './all-fields-view.component.html',
  styleUrls: ['./all-fields-view.component.scss']
})
export class AllFieldsViewComponent implements OnInit {
  
  @Input() translateData;
  @Input() tempService;
  @Input() field;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onError: EventEmitter<Object> = new EventEmitter();
  @Output() updateFormDataObj: EventEmitter<Object> = new EventEmitter();
  @Output() processUpdateRecord: EventEmitter<Object> = new EventEmitter();
  @Output() updateRecord: EventEmitter<Object> = new EventEmitter();
  @Output() updateAddress: EventEmitter<Object> = new EventEmitter();
  @Output() updateParentOnSave: EventEmitter<Object> = new EventEmitter();
  @Output() getRecord: EventEmitter<Object> = new EventEmitter();
  
  styles = '';

  constructor(
    private helperService: HelpersService,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,

  ) { }

  ngOnInit() {
    this.changeDetectorRef.markForCheck();    
    if (this.tempService.formDoc && this.tempService.formDoc.formConfig && this.tempService.formDoc.formConfig.styles) {
      this.styles = `
      <style>
      ${this.tempService.formDoc.formConfig.styles}
      </style>
      `;
    }
  }

  checkError(ele) {
    this.onError.emit(ele);
  }

  onprocessUpdateRecord(ele) {
    this.processUpdateRecord.emit(ele);
  }

  onupdateRecord(ele) {
    this.updateRecord.emit(ele);
  }
  
  onupdateAddress(ele) {
    this.updateAddress.emit(ele);
  }

  onGetRecord(ele) {
    this.getRecord.emit(ele);
  }

  onFieldValueUpdateEvent(eve) {
    try {
      console.log("eve 5555");
      this.onFieldValueUpdated.emit(eve);
      this.changeDetectorRef.detectChanges();
    } catch (err) {
      console.info(err);
    }
  }

  updateFormDataObjHelper($event) {
    console.log("22222 = ", $event);
    this.updateFormDataObj.emit($event);
  }

  selectOffice(field: any) {
    const temp = this.dialog.open(OfficeListPopupComponent, {
      width: '100%',
      height: '100%',
      restoreFocus: false,
      panelClass: 'happ-form-builder-dialog',
      data: { privateArea: true, changeOffice: false }
    }); 
    temp.afterClosed()
        .subscribe((response) => {
          if (response) {
            this.tempService.formData[field.label] = response._id;
          }
        });
  }
}
