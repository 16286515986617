import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HelpersService } from './main/content/services/helpers/helpers.service';
import { environment } from 'environments/environment';
@Injectable()
export class VersionCheckService {
    // this will be replaced by actual hash post-build.js
    private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
    public isNewVersionAvailable = false;
    constructor(
        private http: HttpClient,
        private helperService: HelpersService
        ) { }
    /**
    * Checks in every set frequency the version of frontend application
    * @param url
    * @param {number} frequency - in milliseconds, defaults to 30 minutes
    */
    public initVersionCheck(url, frequency = 1000 * 60 * 30) {
        if (environment.production) {
            setInterval(() => {
                this.checkVersion(url);
            }, frequency);
            this.checkVersion(url);
        } else {
            console.info('Not checking the version in local deploy');
        }
    }
    /**
    * Will do the call and check if the hash has changed or not
    * @param url
    */
    public checkVersion(url: string, isLogin = false) {
        // timestamp these requests to invalidate caches
        this.http.get(url + '?t=' + new Date().getTime())
            .subscribe(
                (response: any) => {
                    const hash = response.hash;
                    const hashChanged = this.hasHashChanged(this.currentHash, hash);
                    // If new version, do something
                    if (hashChanged) {
                        this.isNewVersionAvailable = true;
                        if (isLogin){
                            this.helperService.openConfirmPopUp(`popup.new_version_available_we_will_refresh_to_update`, true, 8000)
                            .subscribe((data) => {
                                this.reloadBrowser();
                            });
                        }
                        // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
                        // for an example: location.reload();
                    } else {
                        this.isNewVersionAvailable = false;
                    }
                    // store the new hash so we wouldn't trigger versionChange again
                    // only necessary in case you did not force refresh
                    this.currentHash = hash;
                },
                (err) => {
                    console.error(err, 'Could not get version');
                }
            );
    }
    /**
    * Checks if hash has changed.
    * This file has the JS hash, if it is a different one than in the version.json
    * we are dealing with version change
    * @param currentHash
    * @param newHash
    * @returns {boolean}
    */
    private hasHashChanged(currentHash, newHash) {
        if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
            return false;
        }
        return currentHash !== newHash;
    }

    reloadBrowser() {
        window.location.reload(true);
    }
}
