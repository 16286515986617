<div fxLayout="column" class="p-16" *ngIf="!loader && userInvitationModel">
  <div fxLayout="row" class="mb-24" fxLayoutGap="10px" fxLayoutAlign="center space-around">
    <mat-select aria-label="login_language_selection" (selectionChange)="setLanguage(selectedLangCode)"
      [(ngModel)]="selectedLangCode" class="w-100-p" placeholder="{{ 'auth.select_language' | translate }}">
      <mat-option [attr.aria-label]="lang.code" *ngFor="let lang of languages" [value]="lang.code"> {{lang.title}}
      </mat-option>
    </mat-select>
  </div>
  <ng-container [ngSwitch]="userInvitationModel.status">
    <ng-container *ngSwitchCase="'accountCreated'">
      <div>
        <h2 class="hint-text mb-28"> {{ 'setPassword.accountCreated' | translate }}</h2>
        <div fxLayoutAlign="center center">
          <button class="m-4 mr-8" mat-raised-button [routerLink]="['/login']">
            {{ 'setPassword.move_to_login_page' | translate }} </button>
          </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'accepted'">
      <div>
        <h2 class="hint-text mb-28"> {{ 'setPassword.accountCreated' | translate }}</h2>
        <div fxLayoutAlign="center center">
          <button class="m-4 mr-8" mat-raised-button [routerLink]="['/login']">
            {{ 'setPassword.move_to_login_page' | translate }} </button>
          </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'canceled'">
      <div>
        <h2 class="hint-text mb-28"> {{ 'setPassword.already_canceled' | translate }}</h2>
        <div fxLayoutAlign="center center">
          <button class="m-4 mr-8" mat-raised-button [routerLink]="['/login']">
            {{ 'setPassword.move_to_login_page' | translate }} </button>
          </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'expired'">
      <div>
        <h2 class="hint-text mb-28"> {{ 'setPassword.already_expired' | translate }}</h2>
        <div fxLayoutAlign="center center">
          <button class="m-4 mr-8" mat-raised-button [routerLink]="['/login']">
            {{ 'setPassword.move_to_login_page' | translate }} </button>
          </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'rejected'">
      <div>
        <h2 class="hint-text mb-28"> {{ 'setPassword.already_rejected' | translate }}</h2>
        <div fxLayoutAlign="center center">
          <button class="m-4 mr-8" mat-raised-button [routerLink]="['/login']">
            {{ 'setPassword.move_to_login_page' | translate }} </button>
          </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <ng-container *ngIf="!isSuccess">
            <div fxLayout="row">
              <h2 class="hint-text mb-28"> {{ 'setPassword.set_password_to_join_the_platform' | translate }} - Pinbox24 </h2>
            </div>
            <mat-form-field class="happ-small-form-field-outline mb-8" floatLabel="always" appearance="outline"
              *ngIf="userInvitationModel" fxFlex>
              <mat-label> {{ 'setPassword.email' | translate }} </mat-label>
              <input type="text" disabled matInput [value]="userInvitationModel.invitedToEmail">
            </mat-form-field>
            <mat-form-field class="happ-small-form-field-outline mb-8" floatLabel="always" appearance="outline" fxFlex>
              <mat-label> {{ 'setPassword.password' | translate }} </mat-label>
              <input type="password" (keyup)="checkPassword()" matInput [(ngModel)]="password">
            </mat-form-field>
            <mat-form-field class="happ-small-form-field-outline mb-8" floatLabel="always" appearance="outline" fxFlex>
              <mat-label> {{ 'setPassword.confirmPassword' | translate }} </mat-label>
              <input type="password" matInput (keyup)="checkConfirmPassword()" [(ngModel)]="confirmPassword">
            </mat-form-field>
            <div class="error-message mr-12 mb-12 mt-12" *ngIf="errMess"> {{ errMess | translate }} </div>
            <div>
              <div>

                <button class="m-4 mr-8" color="warn" mat-raised-button [routerLink]="['/login']">
                  {{ 'setPassword.move_to_login' | translate }} </button>
                </div>
                <span fxFlex></span>
                <div>
                  <button class="m-4" [disabled]="(errMess === '' || errMess) ? true : false" class="light-green-300-bg"
                  mat-raised-button (click)="setPassword()"> {{ 'setPassword.set_password' | translate }} </button>
                </div>
            </div>
          </ng-container>
          <ng-container *ngIf="isSuccess">
            <div>
              <h2 class="mt-0"> {{ 'setPassword.password_saved_successfully' | translate }} </h2>
              <h3> {{ 'setPassword.what_next' | translate }} </h3>
              <ul>
                <li> {{ 'setPassword.login_to_platform' | translate }} -
                  {{ (userInvitationModel && userInvitationModel.invitedToEmail) }} </li>
                <li> {{ 'setPassword.move_to_invitation_list' | translate }} </li>
              </ul>
              <button [routerLink]="['/login']" mat-raised-button> {{ 'setPassword.forward' | translate }} </button>
            </div>
          </ng-container>
    </ng-container>
  </ng-container>
</div>
<div *ngIf="loader && !isSuccess">
  <mat-spinner></mat-spinner>
</div>