import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JsonSettingsComponent } from './json-settings.component';
import { TabSettingsComponent } from './tab-settings/tab-settings.component';
import { SectionSettingsComponent } from './section-settings/section-settings.component';
import { FieldSettingsComponent } from './field-settings/field-settings.component';
import { MaterialModule } from '@fuse/material.module';
import { SettingItemViewModule } from '../setting-item-view/setting-item-view.module';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { DateFilterFieldModule } from '../date-filter-field/date-filter-field.module';
import { FuseMaterialColorPickerModule } from '@fuse/components';

@NgModule({
  declarations: [
    JsonSettingsComponent, 
    TabSettingsComponent, 
    SectionSettingsComponent, 
    FieldSettingsComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SettingItemViewModule,
    TranslateModule,
    FuseSharedModule,
    DateFilterFieldModule,
    FuseMaterialColorPickerModule
  ],
  exports: [
    JsonSettingsComponent
  ]
})
export class JsonSettingsModule { }
