import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { CtSsCatPopupComponent } from './ct-ss-cat-popup/ct-ss-cat-popup.component';
var CtSsCatSelectionComponent = /** @class */ (function () {
    function CtSsCatSelectionComponent(helperService, dialog) {
        this.helperService = helperService;
        this.dialog = dialog;
        // tslint:disable-next-line:no-output-on-prefix
        this.onFieldValueUpdated = new EventEmitter();
        this.modelChange = new EventEmitter();
        this.valChange = new EventEmitter();
        // tslint:disable-next-line:no-output-on-prefix
        this.onError = new EventEmitter();
        this.catData = [];
        this.ssType = [];
        this.filteredType = [];
    }
    CtSsCatSelectionComponent.prototype.ngOnInit = function () {
        this.getData();
        this.model = this.model || {
            areas: [],
            types: [],
            typesObj: {}
        };
    };
    CtSsCatSelectionComponent.prototype.getData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, data2, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.helperService.getRequest("api/super-admin/platform-variable/shared-service-category")];
                    case 1:
                        data = _a.sent();
                        this.catData = (data && data.data) || [];
                        return [4 /*yield*/, this.helperService.getRequest("api/external-page/shared-service-types")];
                    case 2:
                        data2 = _a.sent();
                        this.ssType = (data2 && data2.data) || [];
                        if (this.model && this.model.category) {
                            this.onCatChange();
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        alert(error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CtSsCatSelectionComponent.prototype.onCatChange = function () {
        var _this = this;
        var item = this.catData.find(function (ele) { return (_this.model.category && ele && ele.key === _this.model.category); });
        console.log(" ---", this.model, item);
        if (item) {
            this.filteredType = this.ssType.filter(function (ele) { return (ele && ele.category && Array.isArray(ele.category) && ele.category.includes(item.key)); });
        }
        // this.valChange.emit({
        //   category: item.key
        // });
        // this.modelChange.next(this.model);
    };
    CtSsCatSelectionComponent.prototype.onTypeChange = function () {
        // const item = this.ssType.find((ele) => (this.model.type && ele && ele.key === this.model.type.key));
        // this.valChange.emit({
        //   category: item.key
        // });
        this.modelChange.next(this.model);
    };
    CtSsCatSelectionComponent.prototype.selectAreas = function (type, item) {
        var _this = this;
        var mappedData = this.catData.map(function (ele) {
            ele.allTypes = ele.allTypes || [];
            _this.ssType.forEach(function (ele2) {
                if (ele && ele.key && ele2.category && ele2.category.includes(ele.key)) {
                    ele.allTypes.push(ele2);
                }
            });
            return ele;
        });
        var selectedTypes = [];
        var mySelectedTypes = [];
        var alreadyExists = -1;
        var title = '';
        if (item) {
            var t4 = mappedData.find(function (ele) { return (ele && ele.key === item); });
            var t5 = this.model.types.find(function (ele) { return (ele && ele.key === item); });
            if (t4) {
                selectedTypes = t4.allTypes;
                title = t4.key;
            }
            if (t5) {
                mySelectedTypes = t5.types || [];
                // alreadyExists = true;
                alreadyExists = this.model.types.findIndex(function (ele) { return (ele && ele.key === item); });
            }
        }
        console.log("mapped data - ", mappedData);
        var dialogRef = this.dialog.open(CtSsCatPopupComponent, {
            width: '100%',
            panelClass: 'happ-form-builder-dialog',
            data: {
                catData: this.catData,
                ssType: this.ssType,
                selectedAreas: this.model.areas || [],
                data: this.model,
                selectedTypes: selectedTypes,
                title: title,
                mySelectedTypes: mySelectedTypes,
                mappedData: mappedData,
                type: type
            }
        });
        dialogRef.afterClosed().subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                console.log("popup is closed.", result);
                // if (result) {
                // }
                // this._mailService.repullChanged.next(true);
                if (result) {
                    if (type === 'area') {
                        this.model.areas = result.selectedAreas;
                    }
                    else {
                        if (alreadyExists !== -1) {
                            this.model.types[alreadyExists].types = result.mySelectedTypes;
                            this.model.typesObj[title] = result.mySelectedTypes;
                        }
                        else {
                            this.model.types.push({
                                title: title,
                                types: result.mySelectedTypes || []
                            });
                            this.model.typesObj[title] = result.mySelectedTypes;
                        }
                    }
                    this.modelChange.next(this.model);
                }
                return [2 /*return*/];
            });
        }); }, function (err) {
            console.info(err);
        });
    };
    return CtSsCatSelectionComponent;
}());
export { CtSsCatSelectionComponent };
