import { Component, OnInit } from '@angular/core';
import { OfficeListService } from './office-list.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { OfficeGlobalService } from '../../state/officeState/office.global.service';

@Component({
  selector: 'app-office-list',
  templateUrl: './office-list.component.html',
  styleUrls: ['./office-list.component.scss']
})
export class OfficeListComponent implements OnInit {
  officeList;
  constructor(
    public officeListService: OfficeListService,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    private officeGlobalService: OfficeGlobalService
  ) { 
    this.route.data.subscribe(({office}) => {
    }, err => {
      this.snackBar.open(err || 'Some error occured getting data', 'Okay', {
        duration: 2000,
      });
    });
  }

  ngOnInit() {
    
    this.officeList = this.officeListService.officeList || [];
  }

  changeOffice(office: string) {
    this.officeGlobalService.changeOffice(office['_id'] || office['officeId'])
    .catch(err => {
      console.info(err);
      this.snackBar.open(err || 'Error occured while updating data', 'Okay', {
        duration: 2000,
      });
    });
  }

}
