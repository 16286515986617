/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./group.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./group.component";
import * as i5 from "../../navigation.service";
import * as i6 from "../collapsable/collapsable.component.ngfactory";
import * as i7 from "../collapsable/collapsable.component";
import * as i8 from "@angular/router";
import * as i9 from "../item/item.component.ngfactory";
import * as i10 from "../item/item.component";
var styles_FuseNavVerticalGroupComponent = [i0.styles];
var RenderType_FuseNavVerticalGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FuseNavVerticalGroupComponent, data: {} });
export { RenderType_FuseNavVerticalGroupComponent as RenderType_FuseNavVerticalGroupComponent };
function View_FuseNavVerticalGroupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "group-title"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "group-title"; var currVal_1 = _co.item.classes; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.item.translate; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.item.title; _ck(_v, 4, 0, currVal_3); }); }
function View_FuseNavVerticalGroupComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-vertical-group", [], [[8, "className", 0]], null, null, View_FuseNavVerticalGroupComponent_0, RenderType_FuseNavVerticalGroupComponent)), i1.ɵdid(1, 245760, null, 0, i4.FuseNavVerticalGroupComponent, [i1.ChangeDetectorRef, i5.FuseNavigationService], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
function View_FuseNavVerticalGroupComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-vertical-collapsable", [], [[8, "className", 0], [2, "open", null]], null, null, i6.View_FuseNavVerticalCollapsableComponent_0, i6.RenderType_FuseNavVerticalCollapsableComponent)), i1.ɵdid(1, 245760, null, 0, i7.FuseNavVerticalCollapsableComponent, [i1.ChangeDetectorRef, i5.FuseNavigationService, i8.Router], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; var currVal_1 = i1.ɵnov(_v, 1).isOpen; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FuseNavVerticalGroupComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-vertical-item", [], [[8, "className", 0]], null, null, i9.View_FuseNavVerticalItemComponent_0, i9.RenderType_FuseNavVerticalItemComponent)), i1.ɵdid(1, 245760, null, 0, i10.FuseNavVerticalItemComponent, [i1.ChangeDetectorRef, i5.FuseNavigationService], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
function View_FuseNavVerticalGroupComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavVerticalGroupComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavVerticalGroupComponent_5)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavVerticalGroupComponent_6)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.type == "group"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type == "collapsable"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit.type == "item"); _ck(_v, 6, 0, currVal_2); }, null); }
function View_FuseNavVerticalGroupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavVerticalGroupComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "group-items"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavVerticalGroupComponent_3)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.item.hideGroupTitle; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.item.children; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_FuseNavVerticalGroupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavVerticalGroupComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.item.hidden; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FuseNavVerticalGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-nav-vertical-group", [], [[8, "className", 0]], null, null, View_FuseNavVerticalGroupComponent_0, RenderType_FuseNavVerticalGroupComponent)), i1.ɵdid(1, 245760, null, 0, i4.FuseNavVerticalGroupComponent, [i1.ChangeDetectorRef, i5.FuseNavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
var FuseNavVerticalGroupComponentNgFactory = i1.ɵccf("fuse-nav-vertical-group", i4.FuseNavVerticalGroupComponent, View_FuseNavVerticalGroupComponent_Host_0, { item: "item" }, {}, []);
export { FuseNavVerticalGroupComponentNgFactory as FuseNavVerticalGroupComponentNgFactory };
