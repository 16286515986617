/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "./datatable-header-tmp.component";
var styles_DatatableHeaderTmpComponent = ["[_nghost-%COMP%] {\n    width: 100%;\n}"];
var RenderType_DatatableHeaderTmpComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_DatatableHeaderTmpComponent, data: {} });
export { RenderType_DatatableHeaderTmpComponent as RenderType_DatatableHeaderTmpComponent };
function View_DatatableHeaderTmpComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("happ-header-data-align-" + (((_co.col && _co.col.control_type) && _co.col.control_type.columnAlign) || "left")); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.column.name; _ck(_v, 1, 0, currVal_1); }); }
function View_DatatableHeaderTmpComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "happ-header-data-align-right"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("dttable.subAction")); _ck(_v, 1, 0, currVal_0); }); }
function View_DatatableHeaderTmpComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "happ-header-data-align-right"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("dttable.actions")); _ck(_v, 1, 0, currVal_0); }); }
export function View_DatatableHeaderTmpComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_DatatableHeaderTmpComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DatatableHeaderTmpComponent_2)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DatatableHeaderTmpComponent_3)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.column.name != "_id") && (_co.column.name !== "subAction")); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.column.name === "subAction"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.column.name == "_id"); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_DatatableHeaderTmpComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-datatable-header-tmp", [], null, null, null, View_DatatableHeaderTmpComponent_0, RenderType_DatatableHeaderTmpComponent)), i0.ɵdid(1, 49152, null, 0, i3.DatatableHeaderTmpComponent, [], null, null)], null, null); }
var DatatableHeaderTmpComponentNgFactory = i0.ɵccf("app-datatable-header-tmp", i3.DatatableHeaderTmpComponent, View_DatatableHeaderTmpComponent_Host_0, { col: "col", column: "column" }, {}, []);
export { DatatableHeaderTmpComponentNgFactory as DatatableHeaderTmpComponentNgFactory };
