import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { MatSnackBar, MatDialogRef, MatDialog } from '@angular/material';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ConfirmChangesComponent } from '../confirm-changes/confirm-changes.component';
import { HelpersService } from '../../services/helpers/helpers.service';
import { IRegister } from '../../interfaces/register.interface';
import { FormRulesService } from '../../services/rules/form-rules.service';
import { IFormInterface, INg5Form } from '../../interfaces/form.interface';
import * as objectPath from 'object-path';
import { Subject } from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class FormComponentService {

  onFormDataChanged: BehaviorSubject<any> = new BehaviorSubject(null);
  onRecIdChanged: BehaviorSubject<any> = new BehaviorSubject(null);
  onRecordRefresh: Subject<boolean> = new Subject<boolean>();

  confirmDialogRef: MatDialogRef<ConfirmChangesComponent>;


    isStepper = false;  
    formId: any;
    ngForm: IFormInterface;
    form: INg5Form[];
    formComponentDefaultValue = {};
    auditInfo: object;
    loading = false;
    regId;
    regData;
    formData: any = {};
    recId;
    templates;
    formName = '';
    operation = 'new';
    tabId = 1;
    params;
    error = [];
    createNewRec = false;
    applicationType;
    externalRecId;
    redirectURL;
    oldRecordData = {};
    NOT_UPDATING_KEY_NAMES = [
      'recCheckList',
      'recComments',
      'recComment',
      'office_json',
      'logged_user',
    ];
    changedFormData = {};
    saveButton = false;
    processStatusField: object;
  instanceId: any;
  processFormId: any;
  selectedTab: number;
    constructor(
        private http: HttpClient,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        private helperService: HelpersService,
        private formRuleService: FormRulesService

    ) {

      this.onFormDataChanged
        .subscribe(async (formData) => {
          if (formData && !formData['formData']) {
           
            this.regId = formData['regId'];
            this.operation = formData['operation'] || 'new';
            this.formData = {};
            this.oldRecordData = {};
           
            // default value passed when required.
            this.formComponentDefaultValue = formData['defaultValue'] || {};
            this.changedFormData = formData;
            this.saveButton = formData['saveButton'];
            this.auditInfo = formData['auditInfo'];
            if (formData['recId']) {
              this.recId = formData['recId'];
              await this.getRecordData();
            }
            console.log("111", this.formId, this.regId);

            this.formId = formData['formId'] || this.formId;

            if (this.auditInfo) {
              this.isStepper = true;
            }
            this.loading = true;
            this.makeAllFormSettings().then(() => {
              this.setLoadingFalse();
            }).catch(err => {
              console.error(err);
              this.setLoadingFalse();
            });

          } else if (formData && formData['formData']) {
            this.applicationType = 'externalWidget';
            this.externalRecId = formData['formData']['externalRecId'];
            this.redirectURL = formData['formData']['redirectURL'];
            this.form = formData['formData']['ng5Form'];
            this.formId = formData['formData']['_id'];
          }
        }, err => {
          console.info(err);
        });

      this.onRecIdChanged
        .subscribe(recId => {
          if (recId) {
            console.log("----- temp data");
            this.operation = 'edit';
            this.recId = recId;
            this.getRecordData()
            .then(data => {
              if (!this.formData) {
                this.formData = {};
              }
              this.setLoadingFalse();
              this.oldRecordData = JSON.parse(JSON.stringify(this.formData));
            })
            .catch(err => {
              console.error(err);
              this.setLoadingFalse();
            });
          }
        }, err => {
          console.info(err);
        });
    }

    async makeAllFormSettings() {
      try {
        if (this.regId) {
          await this.getFormId();
          await this.getForm();
          this.oldRecordData = JSON.parse(JSON.stringify(this.formData));
          this.form = this.formRuleService.checkAllRules(this.ngForm.ng5Form, this.ngForm.rules, this.formData, true);
          this.checkForTabConditions();
          console.log("this.form", this.form);
        }
      } catch (error) {
        throw error;
      }
    }

    setLoadingFalse() {
      setTimeout(() => {
        this.loading = false;
      }, 600);
    }
    makeInit() {
      this.formId = '';
      this.formName = '';
      this.loading = false;
      this.templates = [];
      this.error = [];
      this.form = null;
      this.formData = {};
      this.processStatusField = null;
    }

    getFormId(): Promise<any> {
      return new Promise((resolve, reject) => {
        if (!this.formId) {
          this.helperService.getRegisterData(this.regId)
            .then((regData) => {
              if (regData) {
                console.log("222", this.formId);
                this.formId = regData['ng5FormId'];
                resolve(this.formId);
              } else {
                resolve('');
              }
            }).catch(err => {
              console.info(err);
              reject(err);
            });
        } else {
          resolve('');
        }
      });
    }

  /**
   * get the form
   */
  getForm(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.helperService.getForm(this.formId)
      .then(formData => {
        this.afterGetFormOperation(formData)
        .then(resolve).catch(reject);
      }).catch(reject);
    });
  }

  recordCreated(data) {
    this.operation = 'update';
    this.formData = data;
    this.recId = data._id;
  }

    /**
   * 
   * @param formData 
   * operations to be done after get form.
   */
  afterGetFormOperation(formData): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ngForm =  formData;
      this.formName = formData['title'];
      this.templates = formData['formTemplates'] || [];
      this.form = formData['ng5Form'] || [];
      this.traverse(this.form, function(k, v){
      });
      this.getDefaultValue(this.form || [], '')
      .then(() => {
        this.checkForTabConditions();
          resolve(this.form);
      })
      .catch(err => {
          reject(err);
      });
    });
  }

  checkForTabConditions() {
    console.log("checkForTabConditions");
    this.form = this.form || [];
    this.ngForm['ng5Form'] = this.ngForm['ng5Form'] || [];
    this.ngForm['ng5Form'] = this.ngForm['ng5Form'].filter((ele) => (!ele.showOnApp))
    const tabName = window.localStorage.getItem(`${this.regId}_name`);
    this.form = <any>this.ngForm['ng5Form'].filter((tab: any) => {
      if (tab && !tab.useCondition) {
        return true;
      } else if (tab) {
        let retValue = true;
        if (tab.condition && this.formData) {
          if (!Array.isArray(tab.condition)) {
            tab.condition = [];
          }
          tab.condition.forEach((ele, i) => {
            switch (ele.condition) {
              case 'isEqual':
                if (tab.condition && tab.condition[i-1] && tab.condition[i-1].operator === "AND") {
                  if (retValue) {
                    retValue = (this.formData[ele.field] == ele.value);
                  }
                } else if (tab.condition && tab.condition[i-1] && tab.condition[i-1].operator === "OR") {
                  if (!retValue) {
                    retValue = (this.formData[ele.field] == ele.value);
                  }
                } else {
                  retValue = (this.formData[ele.field] == ele.value);
                }
                // tslint:disable-next-line:triple-equals
                // return (this.formData[ele.field] == ele.value);
                break;
              case 'isNotEqual':
                // tslint:disable-next-line:triple-equals
                if (tab.condition && tab.condition[i-1] && tab.condition[i-1].operator === "AND") {
                  if (retValue) {
                    retValue = (this.formData[ele.field] != ele.value);
                  }
                } else if (tab.condition && tab.condition[i-1] && tab.condition[i-1].operator === "OR") {
                  if (!retValue) {
                    retValue = (this.formData[ele.field] != ele.value);
                  }
                } else {
                  retValue = (this.formData[ele.field] != ele.value);
                }
                break;
            }
          });
        }
        return retValue;
      }
      return true;
    });
    if (tabName) {
      const newIndex = _.findIndex(this.form, { tabTitle: tabName });
      if (newIndex >= 0) {
        this.selectedTab = newIndex;
        if (!this.selectedTab) {
          this.selectedTab = 0
        }
        localStorage.setItem(this.regId, newIndex.toString());
      }
    }
  }
  
  async getRecordData(): Promise<any> {
    try {
      console.log("operation ------- ", this);
      if (this.operation === 'add' || this.recId === 'new' || !this.recId) {
        return null;
      } else {
        if (this.regId) {
          console.log("1111")
          const recData = await this.helperService.getRegisterRecordData(this.regId, this.recId)
          console.log("2222")
          if (recData) {
            this.formData = recData || {};
            this.oldRecordData = recData || {};
            this.formatFormDataForDot();
            // this.getDefaultValue(this.form || [], '');
            this.formId = (recData && recData.formId);
            console.log("this.formId", this.formId);
            await this.makeAllFormSettings();
            this.checkForTabConditions()
            return (recData || {});
          }
        } else {
          const recData = await this.helperService.getRegisterRecordDataV2(this.recId);
          if (recData) {
            this.regId = recData.regId;
            this.formData = recData || {};
            this.oldRecordData = recData || {};
            this.formatFormDataForDot();
            this.formId = (recData && recData.formId);
            await this.makeAllFormSettings();
            this.checkForTabConditions()

            // this.getDefaultValue(this.form || [], '');
            return (recData || {});
          }
        }
      }
    } catch (error) {
      throw error;
    }
  }

  
  preventUpdateKeys(data: any) {
    const flatFields = this.helperService.getXTypeVariables(this.form, '', true) || [];

    console.log("Values = ", flatFields, data);
    flatFields.forEach((ele) => {
      if (ele.preventUpdate) {
        delete data[ele.label]
      }
    });

    return data;
  }

  formatFormDataForDot() {
    // tslint:disable-next-line:forin
    for (const i in this.formData) {
      if (i.includes('.')) {
        this.formData[i] = this.getUsingString(this.formData, i); 
        console.log("assiging the data- ", i, this.formData[i]);
      }
    }
  }
  
      saveFormData(notClose = false) {
        return new Promise((resolve, reject) => {

          this.loading = true;
          if (this.operation === 'new') {
            this.saveData(this.formData)
            .then(data => {
              // successe
              // this.location.back();
              if (!notClose) {
                this.closePopup(true);
              }
              this.loading = false;
              resolve(null);
              this.snackBar.open('Data Saved', 'Done', {
                duration: 2000,
              });
          })
          .catch(err => {
            this.loading = false;
            // error
            if (!notClose) {
              this.closePopup(null);
            }

            reject('Error Occured While updating the form');
            this.snackBar.open(err, 'done', {
              duration: 2000,
            });
          });
        } else {
          if (this.checkChanges(this.oldRecordData, this.formData)) {
             if (this.ngForm.changeConfirmPopup) {

              this.confirmDialogRef = this.dialog.open(ConfirmChangesComponent, {
                disableClose: false,
                width: '40%',
                data: { confirmText: (this.ngForm && this.ngForm.popupOptions && this.ngForm.popupOptions.confirmText) || '', 
                        resetText: (this.ngForm && this.ngForm.popupOptions && this.ngForm.popupOptions.resetText) || 'Spowoduje to zresetowanie danych', 
                        cancelText: (this.ngForm && this.ngForm.popupOptions && this.ngForm.popupOptions.cancelText) || 'Aby kontynuowac edycje' },
              });
            
              this.confirmDialogRef.afterClosed().subscribe(result => {
                  if ( result === 'confirm' ) {
                    this.updateData(this.formData)
                    .then(data => {
                      // success
                      // this.location.back();
                      resolve(null);
                      if (!notClose) {
                        this.closePopup(true);
                      }
                      this.snackBar.open('updated', 'done', {
                        duration: 2000,
                      });
              this.loading = false;

                    })
                    .catch(err => {
              this.loading = false;

                      // error
                      if (!notClose) {
                        this.closePopup(null);
                      }
                      reject('Error Occured While updating the form');
                      this.snackBar.open(err, 'done', {
                        duration: 2000,
                      });
                    });
                  } else if (result === 'reset') {
                    resolve(null);
                    if (!notClose) {
                      this.closePopup(true);
                    }

                    this.snackBar.open('no changes', 'done', {
                      duration: 2000,
                    });
              this.loading = false;

                  console.log('no changes');
                  }
                  this.confirmDialogRef = null;
                }, err => {
                  console.info(err);
                });
                } else {
                  this.updateData(this.formData)
                  .then(data => {
                    // success
                    // this.location.back();
                    resolve(null);
                    if (!notClose) {
                      this.closePopup(true);
                    }

                    this.snackBar.open('updated', 'done', {
                      duration: 2000,
                    });
              this.loading = false;

                  })
                  .catch(err => {
              this.loading = false;

                    // error
                    if (!notClose) {
                      this.closePopup(null);
                    }

                    reject('Error Occured While updating the form');
                    this.snackBar.open(err, 'done', {
                      duration: 2000,
                    });
                  });
                }
              } else {
                resolve(null);
              this.loading = false;

                if (!notClose) {
                  this.closePopup(true);
                }

                  this.snackBar.open('no changes', 'done', {
                    duration: 2000,
                  });
                console.log('no changes');
              }
            }
          });
        }

        closePopup(data) {
          if (this.changedFormData && this.changedFormData['popupOptions'] && this.changedFormData['popupOptions']['dialogRef']) {
            this.changedFormData['popupOptions']['dialogRef'].close(data);
          }
        }

        saveData(data: object) {
          return new Promise((resolve, reject) => {
            if (this.regId) {
              data = this.calculationOnSave(data);
      data = this.preventUpdateKeys(data);

              this.helperService.createRegisterRecord(this.regId, data)
                .then((res) => {
                  console.log("res data = ", res);
                  if (this.isStepper) {
                    // now update the audit regsiter.
                    this.auditInfo['courseSteps'][this.auditInfo['stepNo']]['recId'] = data['result']['_id'];
                    this.updateRegData(this.auditInfo['regId'], { ng5courseSetting: this.auditInfo['courseSteps'] })
                      .then(() => {
                        resolve('');
                      })
                      .catch(reject);
                  } else {
                    resolve('');
                  }
                }).catch(reject);
            } else {
              reject('No register Id Found');
            }
          });
        }
      
      checkChanges(oldData, newData): boolean {
        for (const i in newData) {
          if (typeof newData[i] === 'object') {
            for (const j in newData[i]) {
              if (newData[i][j] !== oldData[i][j]) {
                return true;
              }
            }
          } else if (newData[i] !== oldData[i]) {
            return true;
          }
        }
        return false;
      }

      externalWidgetSaveData() {
        return new Promise((resolve, reject) => {
          if (this.formData && this.externalRecId) {
            this.helperService.postRequest(`api/external-widget/form/${this.externalRecId}`, this.formData)
              .then((res) => {
                // now update the audit regsiter.
                this.snackBar.open('Data Saved', 'done', {
                  duration: 2000,
                });
                if (this.redirectURL) {
                  window.location.href = this.redirectURL;
                }
              }).catch(err => {
                this.snackBar.open(err || 'Error occurred', 'done', {
                  duration: 2000,
                });
                reject(err);
              });
          } else {
            reject('No register Id Found or form data found');
          }
        });
      }

      updateData(data: object, regId?: string, recId?: string) {
        return new Promise((resolve, reject) => {
          if (this.params && this.params['parentId']) {
              data['parentId'] = this.params['parentId'];
          }
        this.formatObject();

          data = this.calculationOnSave(data);
      data = this.preventUpdateKeys(data);

          this.buildInstanceReport(data)
          .then((newRec => {
            data = newRec;
            this.regId = this.regId || regId;
            this.recId = this.recId || recId;

            this.NOT_UPDATING_KEY_NAMES.forEach((ele) => (delete data[ele]));

            this.helperService.updateRegisterRecord(this.regId, this.recId, data)
            .then(updatedDoc => {
              resolve(updatedDoc[0]);
            }).catch(reject);
          })).catch(reject);
        });
      }

    updateRegData(regId: string, regData: Object): Promise<Object> {
    const tempRegData = JSON.parse(JSON.stringify(regData));
    delete tempRegData['_id'];
    return this.helperService.updateRegisterData(regId, tempRegData);
  }

 
  formatObject() {
    this.formData = this.formData || {};
    for (const i in this.formData) {
      if (i && i.includes('.')) {
        objectPath.set(this.formData, i, this.formData[i]);
        delete this.formData[i];
      }
    }
  }

  getUsingString(recData, label) {
    return objectPath.get(recData, label);
}

    formatFormData() {
      return new Promise((resolve, reject) => {
        if (this.operation === 'new' || !this.recId) {
            resolve(null);
        }
        const formVar = [];
        const tt = this;
          this.traverse(this.form, function(k, v){
              if (k === 'data') {
                  formVar.push(v);
                  // tt.formData[v] = tt.formData[v] || ""  ;
              }
          });
        resolve(formVar);
      });
    }

  obj;
  traverse = function(o, fn) {
    if (!this.formData) {
      this.formData = {};
    }
      // tslint:disable-next-line:forin
      for (const i in o) {
          if (i === 'data') {
              if (this.obj['multiple']) {
                  // this.formData[this.obj['label']] = this.formData[this.obj['label']] || this.obj['data'] || {};
              } else {
                  if (this.obj['control_type'] === 'process_status') {
                      this.processStatusField = this.obj;
                  }
                  // this.formData[this.obj['label']] = this.formData[this.obj['label']] || this.obj['data'] || '';
              }
          }
        fn.apply(this, [i, o[i]]);
        if (o[i] !== null && typeof(o[i]) === 'object') {
          this.obj = o[i];
          this.traverse(o[i], fn);
        }
      }
    };


    createNewRecFun(regId) {
      return new Promise((resolve, reject) => {
        this.helperService.createRegisterRecord(regId, {})
        .then(data => {
          this.recId = data['_id'];
        }).catch(reject);
      });
    }


    calculationOnSave(data) {
      let tempFields = this.helperService.getXTypeVariables(this.form, 'calculation_on_save') || [];
      tempFields = this.transformSort(tempFields, 'calculationSort');
      tempFields.forEach(ele => {
        let sum = 0;
        if (ele['fields']) {
          sum += this.helperService.rowCalculateOnSave(ele, data);
        }
        data[ele['label']] = sum;
        // this is to save title too..
        if (ele['saveRange']) {
          ele['range'] = ele['range'] || [];
          ele['range'].forEach((range) => {
            if (sum >= range.min && sum <= range.max) {
              data[ele['rangeLabel']] = range.title || sum;
            }
          });
        }
      });
      return data;
    }

    transformSort(array: any[], field: string): any[] {
      if (!array || array && array.length === 0) {
      return [];
      }
      array.sort((a: any, b: any) => {
        if (a && b && a[field] < b[field]) {
          return -1;
        } else if (a && b && a[field] > b[field]) {
          return 1;
        } else {
          return 0;
        }
      });
      return array;
    }
  
  getDefaultValue(obj, control_type, all?) {
    return new Promise((resolve, reject) => {
    const ng5AllFields = [];
    obj = obj || [];
    obj.forEach((tabValue) => {
      if (tabValue.tabContent && typeof tabValue.tabContent === 'object') {
        tabValue.tabContent.forEach((value) => {
          if (value.data !== null ) {
            if (value.label){
              this.formData[value.label] = this.formData[value.label] || value.data || this.formComponentDefaultValue[value.label] ||  '';
            }
          }
          if (value.children && typeof value.children === 'object') {
            value.children.forEach((childValue) => {
              if (childValue.data !== null ) {
                  if (childValue.label){
                    this.formData[childValue.label] = this.formData[childValue.label] || childValue.data || this.formComponentDefaultValue[childValue.label] ||  '';
                  }
              }
              if (childValue && typeof childValue === 'object') {
                childValue.forEach((nestedChildValue) => {
                  // tslint:disable-next-line:max-line-length
                  if (nestedChildValue.data !== null ) {
                    if (nestedChildValue.label){
                      // tslint:disable-next-line:max-line-length
                      this.formData[nestedChildValue.label] = this.formData[nestedChildValue.label] || nestedChildValue.data || this.formComponentDefaultValue[nestedChildValue.label] ||  '';
                    }
                  }
                });
              }
  
            });
          }
        });
      }
    });
    resolve(ng5AllFields);
  });
  }

  buildInstanceReport(recData): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        let reportFields = [];
        const reportData = {
          reportTitle: (this.ngForm && this.ngForm['registerRecordReportTitle']) || '',
          reportFields: []
        };
        this.traverseField(this.form, (field) => {
          if (field.formPrint) {
            reportFields.push(field);
          }
        }).then(data => {
          reportFields = reportFields || [];
          if (reportFields.length) {
            reportFields.forEach(field => {
              reportData.reportFields.push({
                key: field.title || field.label,
                value: this.formatFieldData(field, recData)
              });
            });
            recData['instanceReportData'] = recData['instanceReportData'] || {};
            if (reportData && reportData.reportFields && reportData.reportFields.length) {
              recData['instanceReportData']['recordReportSection'] = recData['instanceReportData']['recordReportSection'] || [];
              recData['instanceReportData']['recordReportSection'].push(reportData);
            }
            resolve(recData);
          } else {
            resolve(recData);
          }
        }).catch(err => {
          console.info(err);
          resolve(recData);        
        });
      } catch (err) {
        reject(err);
      }
    });
  }
  
  formatFieldData (field: any, value: any): string {
    try {
      if (field['control_type'] === 'reg_value_selection') {
        if (value[field.label] && value[field.label]['value']) {
            // tslint:disable-next-line:forin
            for (const reg_val in value[field.label]) {
                return value[field.label][reg_val];
            }
        }
    } else if (typeof value[field.label] === 'object') {
      let tempVal = '';
      for (const k in value[field.label]) {
        if (value[field.label][k]) {
          tempVal += `${k}, `; 
        }
      }
      return tempVal.substr(0, tempVal.length - 1);
      } else {
        return value[field.label] || '';
      }
    } catch (err) {
      console.info(err);
    }
  }

  
  traverseField(obj, condFunc) {
    return new Promise((resolve, reject) => {
      const ng5AllFields = [];
      if (obj) {
        obj.forEach((tabValue) => {
          if (tabValue.tabContent && typeof tabValue.tabContent === 'object') {
            tabValue.tabContent.forEach((value) => {
              condFunc(value);
              if (value.children && typeof value.children === 'object') {
                value.children.forEach((childValue) => {
                  condFunc(childValue);
                  if (childValue && typeof childValue === 'object') {
                    childValue.forEach((nestedChildValue) => {
                      condFunc(nestedChildValue);
                    });
                  }
      
                });
              }
            });
          }
        });
        resolve(ng5AllFields);
      } else {
        reject('No Form Data - please check that form is correct');
      }
    });
  }

  async loadDataFromRecData(recData: any) {
    try {
      const tt = JSON.parse(localStorage.getItem('wsAppData')) || {};
      recData = recData || {};
      if (recData) {
        if (!this.regId) {
          this.regId = (recData && recData['regId']);
        }
        this.formData = recData || {};
        this.instanceId = recData['instanceId'];
        tt['recName'] = recData['signatura'] || '';
        tt['statusId2'] = recData['processStatusId'] || '';
        tt['instanceId2'] = recData['instanceId'] || '';
        localStorage.setItem('wsAppData', JSON.stringify(tt));

        if (recData && recData['formId']) {
          this.processFormId = recData['formId'];
          if (!this.isStepper) {
            this.formId = recData['formId'];
          }
        } else {
          console.log('No form id in the record');
        }
        return recData;
      } else {
        return null;
      }
    } catch (error) {
      throw error;
    }
  }
}

