import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HelpersService } from '../../services/helpers/helpers.service';
import * as moment from 'moment';

@Component({
  selector: 'app-dt-table-report-popup',
  templateUrl: './dt-table-report-popup.component.html',
  styleUrls: ['./dt-table-report-popup.component.scss']
})
export class DtTableReportPopupComponent implements OnInit {

  loading = false;
  loadingMessage = 'dttable.getting_data_for_report';

  constructor(
    public dialogRef: MatDialogRef<DtTableReportPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private helperService: HelpersService
  ) { }

  ngOnInit() {
  }

  reportGenerateExcel(template) {
    console.log("reportGenerateExcel", template);
    if (this.data && this.data.requestData) {
      const reqData: any = JSON.parse(JSON.stringify(this.data.requestData));
      reqData.reportType = 'excel';
      if (template) {
        reqData.templateId = template._id;
        reqData.templateReport = true
      }
        this.loading = true;
      this.loadingMessagesChange();
      this.helperService.postRequest(`api/reg/${this.data.regId}/report`, reqData)
      .then((result) => {
        this.loading = false;
        const temp = {
          base64: result || '',
          mimetype: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          originalname: `${moment().format('YYYY:MM:DD HH:mm:ss')}.xlsx`
        };
        this.helperService.downloadDocument(temp);
        this.dialogRef.close(null);
      }).catch(err => {
        console.log(err);
        this.loading = false;
      });
    }
  }

  reportGeneratePDF(template: any) {
    if (template && template._id) {
      const reqData: any = JSON.parse(JSON.stringify(this.data.requestData));
      reqData.templateId = template._id;
      reqData.reportType = 'pdf';
      this.loading = true;
      this.loadingMessagesChange();
      this.helperService.postRequest(`api/reg/${this.data.regId}/report`, reqData)
      .then((result) => {
        this.loading = false;
        const temp = {
          base64: result || '',
          mimetype: 'application/pdf',
          originalname: `${moment().format('YYYY:MM:DD HH:mm:ss')}.pdf`
        };
        this.helperService.downloadDocument(temp);
        this.dialogRef.close(null);
      }).catch(err => {
        this.loading = false;
        console.log(err);
      });
    }
  }

  loadingMessagesChange() {
    setTimeout(() => {
      this.loadingMessage = 'dttable.generating_your_report';
    }, 500);
    setTimeout(() => {
      this.loadingMessage = 'dttable.getting_genrated_report';
    }, 2500);
    setTimeout(() => {
      this.loadingMessage = 'dttable.its_taking_more_than_usual';
    }, 8000);
  }
}
