import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { SsEventFormAddPopupComponent } from './ss-event-form-add-popup/ss-event-form-add-popup.component';
var SsEventFormComponent = /** @class */ (function () {
    function SsEventFormComponent(helperService, dialog) {
        this.helperService = helperService;
        this.dialog = dialog;
        this.onFieldValueUpdated = new EventEmitter();
        this.modelChange = new EventEmitter();
        // tslint:disable-next-line:no-output-on-prefix
        this.onError = new EventEmitter();
        this.listData = [];
    }
    SsEventFormComponent.prototype.ngOnInit = function () {
        this.listData = this.model || [];
    };
    SsEventFormComponent.prototype.add = function (index) {
        // this.listData.push({});
        var _this = this;
        var dialogRef = this.dialog.open(SsEventFormAddPopupComponent, {
            width: '100%',
            panelClass: 'happ-form-builder-dialog',
            data: {
                edit: index,
                data: this.listData[index] || {}
            }
        });
        dialogRef.afterClosed().subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                console.log("data -", result, this.listData);
                if (result) {
                    if (result && result.operation === "delete") {
                        this.listData.splice(index, 1);
                    }
                    else if (index) {
                        this.listData[index] = result;
                    }
                    else {
                        this.listData.push(result);
                    }
                    this.model = this.listData;
                    this.modelChange.next(this.model);
                }
                return [2 /*return*/];
            });
        }); }, function (err) {
            console.info(err);
        });
    };
    SsEventFormComponent.prototype.remove = function (i) {
    };
    return SsEventFormComponent;
}());
export { SsEventFormComponent };
