<div  fxLayout="column" *ngIf="!field.hide || builder">
  <p *ngIf="!hideTitle" class="happ-field-title mr-12 mb-8 ml-4">{{ field.title | translate }} </p>
    <div style="border: 1px solid #e0e0e0; padding: 10px;">
      <div fxLayout="row" fxLayoutAlign="space-between center" >
        <p>
          {{ 'ct.as_default_used_by_platform' | translate }}
        </p>
        <span fxFlex></span>
        <div>
          <button mat-raised-button class="mr-8" *ngIf="field.defaultSelection" (click)="selectTemplate('TmpDefault')" >
            {{ 'ct.select_template' | translate }}
          </button>
          <button mat-raised-button class="mr-4" [disabled]="loading" (click)="previewTemplate('TmpDefault')" >
            {{ 'ct.preview_template' | translate }}
          </button>
        </div>
      </div>
      <div class="mt-4" *ngIf="regRec && regRec[field.label + 'TmpDefaultId']">
        <p>
          {{ 'ct.default_template' }}: 
          <strong>

            {{ regRec[field.label + 'TmpDefaultName'] }} ({{regRec[field.label + 'TmpDefaultId']}})
          </strong>
        </p>
      </div>
      <ng-container *ngIf="field.showCustomSelection">
        <hr>

        <mat-checkbox [(ngModel)]="regRec[field.label + 'TmpCustomCheck']" > 
          {{ 'ct.use_custom_template' | translate }}
         </mat-checkbox>
        <br>
        <div *ngIf="regRec[field.label + 'TmpCustomCheck']" fxLayout="row" fxLayoutAlign="space-between center" >
          <p>
            {{ 'ct.select_custom_template' | translate }}
          </p>
          <span fxFlex></span>
          <div>
            <button mat-raised-button class="mr-8" (click)="selectTemplate('TmpCustom')" >
              {{ 'ct.select_template' | translate }}
            </button>
            <button mat-raised-button class="mr-4" [disabled]="loading" (click)="previewTemplate('TmpCustom')" >
              {{ 'ct.preview_template' | translate }}
            </button>
          </div>
        </div>
        <div *ngIf="regRec[field.label + 'TmpCustomCheck'] && regRec && regRec[field.label + 'TmpCustomId']" class="mt-4">
          <p>
            {{ 'ct.custom_template' }}: 
            <strong>
  
              {{ regRec[field.label + 'TmpCustomName'] }} ({{regRec[field.label + 'TmpCustomId']}})
            </strong>
          </p>
        </div>
      </ng-container>
    </div>
</div>