import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, ChangeDetectorRef, OnChanges } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() field: any;
  @Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() translateData: any;
  @Input() builder: any;
  @Input() autoFocus: any;
  @Input() model: string; 
  @Input() isTextarea: boolean; 
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Output() onError: EventEmitter<Object> = new EventEmitter();
  
  error = false;
  errorMessage = '';
  min_error = false;
  max_error = false;
  constructor(
    private translationLoader: FuseTranslationLoaderService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.changeDetectorRef.detectChanges();

    if (this.field['required'] && (!this.model || this.model && this.model.length === 0)) {
      this.error = true;
      this.errorMessage = 'ct.field_is_required';
      this.changeDetectorRef.detectChanges();

      if (!this.model) {
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
      }
    }
  }

  ngOnChanges(changes: any) {

    if (changes && changes.translateData && changes.translateData.currentValue) {
      this.translateData = changes.translateData.currentValue;
      this.translationLoader.loadTranslationsV2(this.translateData);    
    }
  }

  checkRegExError() {
    if (this.field.validate_reg_exp) {
      const regEx = new RegExp(this.field.validate_reg_exp)
      if (!regEx.test(this.model)) {
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
        this.error = true;
        this.errorMessage = this.field.validate_reg_exp_error_msg;
      } else {
        this.onError.emit({
          error: false,
          label: this.field['label']
        });
        this.error = false;
        this.errorMessage = '';
      }
    }
  }

  onKeyPress() {
    this.min_error = this.model.length < this.field.min_length;
    this.max_error = this.model.length > this.field.max_length;
    if (this.field['required'] && (!this.model || this.model && this.model.length === 0)) {
      this.errorMessage = 'ct.field_is_required';
      this.error = true;
      this.onError.emit({
        error: true,
        label: this.field['label']
      });
    }  else if (this.min_error) {
      this.errorMessage = 'ct.min_length_allowed_is';
      this.error = true;
      this.onError.emit({
        error: true,
        label: this.field['label']
      });
    } else if (this.max_error) {
      this.errorMessage = 'ct.max_length_allowed_is';
      this.error = true;
      this.onError.emit({
        error: true,
        label: this.field['label']
      });
    } else {
    if (this.field.validate_reg_exp) {
      this.checkRegExError();
    } else {
      this.errorMessage = '';
      this.error = false;
      this.onError.emit({
        error: false,
        label: this.field['label']
      });
    }
    }
    this.modelChange.next(this.model);
    this.onFieldValueUpdated.emit({
        label: this.field.label,
        value: this.model,
        ruleIndex: this.field.ruleIndex,
        field: this.field
      });

  }

  ngAfterViewInit() {
  }

  pasteIt(eve) {
    eve.preventDefault();
    this.model = this.model || '';
    this.model += (eve && eve.clipboardData && eve.clipboardData.getData('Text')) || '';
    this.onKeyPress();
  }

}
