// tslint:disable:indent
import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IOfficeData } from 'app/main/content/interfaces/office.interface';
import * as _ from 'lodash';

export interface OfficeState extends IOfficeData { 
    lastChangedAt?: Date;
}

function getOfficeDoc() {
	try {
		return JSON.parse(localStorage.getItem('ngStorage-selectedOffice'));
	} catch (err) {
		throw err;
	}
}

export function createInitialState(): OfficeState {
	try {
		return { ...getOfficeDoc() };
	} catch (error) {
		return {} as any;
	}
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'office', resettable: true })
export class OfficeStore extends Store<OfficeState> {

	constructor() {
		super(createInitialState());
	}

}
