import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material';
import { SaveContactInfoPopupComponent } from './save-contact-info-popup/save-contact-info-popup.component';

@Component({
  selector: 'app-delivery-address',
  templateUrl: './delivery-address.component.html',
  styleUrls: ['./delivery-address.component.scss']
})
export class DeliveryAddressComponent implements OnInit, OnDestroy, OnChanges {

  @Input() field;
  @Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() regRec;
  @Input() builder;
  @Input() model: string; 
  @Output() onError: EventEmitter<Object> = new EventEmitter();

  @Input() translateData: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Output() updateAddress: EventEmitter<Object> = new EventEmitter();
  autoCompleteList = [];
  defaultOptions = [
    {
      title: 'deliveryAddress.name',
      value: 'searchMultiFileds',
      show: true
    },
    {
      title: 'deliveryAddress.nipNumber',
      value: 'searchNip',
      show: true
    },
    {
      title: 'deliveryAddress.cityOrStreet',
      value: 'cityAndStreet',
      show: true
    },
    // {
    //   title: 'deliveryAddress.externalNip',
    //   value: 'by_nip_number_ext_api',
    //   show: true
    // },
    {
      title: 'deliveryAddress.manual',
      value: 'manualDeliveryAddress',
      show: true
    },
    {
      title: 'deliveryAddress.autocomplete_name',
      value: 'autoSearchMultiFields',
      show: false
    },
  ];

    address;
    query = '';
    result;
    city;
    street;
    nip;
    regId;
    searchMultiFileds;
    ext_nip_no;
    filteredData;
    officeId;
    edit = false;
    message;
    manualData = {
      shortName:      '',
      longName:       '',
      companyName:    '',
      companyAdress:  '',
      companyCity:    '',
      postalcode:     '',
    };
  loading = false;
  error: boolean;
  errorMessage: string;
    constructor(
      private translationLoader: FuseTranslationLoaderService,
      private helperService: HelpersService,
      public dialog: MatDialog,
      private _officeHelper: OfficeHelpersService
    ) { }
    
  ngOnChanges(changes: any) {

    if (changes && changes.translateData && changes.translateData.currentValue) {
      this.translateData = changes.translateData.currentValue;
      this.translationLoader.loadTranslationsV2(this.translateData); 
         
    }
  }
  
    ngOnInit() {
      this.translationLoader.loadTranslationsV2(this.translateData);
      this.getInitValue();
      this.regRec = this.regRec || {};
      this.manualData = this.getAddressDataObj(this.regRec);
      this.getOfficeAndRegId();
      if (this.field['required'] && (this.model === null || this.model === undefined || this.model === '')) {
        this.error = true;
        this.errorMessage = 'ct.field_is_required';
        if (!this.model) {
          this.onError.emit({
            error: true,
            label: this.field['label']
          });
        }
      }
    }
  
    async getOfficeAndRegId() {
      try {
        const officeDoc = await this._officeHelper.getLocalOfficeData();
        this.officeId = officeDoc._id;
        if (officeDoc && officeDoc.apps && officeDoc.apps.office_addressbook) {
          this.regId = officeDoc.apps.office_addressbook.id;
        }
      } catch (error) {
        console.error(error);
      }
    }

    ngOnDestroy(): void {
      this.result = [];
      this.edit = false;
    }

  onKeyPress(option) {
    if (option !== 'manual' && option && Object.keys(option).length) {
      this.error = false;
      this.onError.emit({
        error: false,
        label: this.field['label']
      });
      this.updateAddress.emit(this.getAddressDataObj(option));
      this.manualData = this.getAddressDataObj(option);
    } else {
      if (this.field.deliveryAddressSelection === 'manualDeliveryAddress') {
      // tslint:disable-next-line:max-line-length
      this.model = (this.manualData['companyName'] || '') + ' ' + (this.manualData['postalcode'] || '') + ' ' + (this.manualData['longName'] || '') + ' ' + (this.manualData['shortName'] || '') + ' ' + (this.manualData['companyCity'] || '') + ' ' + (this.manualData['companyAdress'] || '');
      this.modelChange.next(this.model);
      this.error = false;
      this.onError.emit({
        error: false,
        label: this.field['label']
      });
        this.updateAddress.emit(this.getAddressDataObj(this.manualData));
      }
    }
    this.modelChange.next(this.model);
    this.onFieldValueUpdated.emit({
        label: this.field.label,
        value: this.model,
        ruleIndex: this.field.ruleIndex,
        field: this.field
      });
  }

  search() {
    if (this.regId && this.officeId) {
      this.loading = true;
      this.helperService.getRequest(this.getURL())
        .then((data) => {
          this.loading = false;
          this.result = data || [];
          if (!this.result) {
            this.message = 'Nie znaleziono danych tego wyszukiwania';
            return;
          } else {
            this.message = this.result.length + ' znalezionych danych';
          }
          console.log("this result", this.result);
          this.result = this.result.map((element: any) => this.makeAllAddress(element));
        }).catch(err => {
          this.loading = false;
          this.message = err;
          console.info(err);
        });
    } else {
      console.log('No office or reg Id found');
      this.message = 'No office or reg Id found';
    }
  }

  makeAllAddress(ele: any = {}) {
    try {
      ele.allAddress = 
      `${ele['companyName'] || ''} ${ele['postalcode'] || ''} ${ele['longName'] || ''} ${ele['shortName'] || ''} ${ele['companyCity'] || ''} ${ele['companyAdress'] || ''}`;
      return ele;
    } catch (error) {
      console.error(error);
    }
  }

  getURL(): string {
    if (this.field.deliveryAddressSelection === 'cityAndStreet') {
      return `api/offices/contacts/lists/search?companyAdress=${this.street}&companyCity=${this.city}&officeId=${this.officeId}&regId=${this.regId}`;
    } else if (this.field.deliveryAddressSelection === 'searchNip') {
      return `api/offices/contacts/lists/search?nip=${this.nip}&officeId=${this.officeId}&regId=${this.regId}`;
    } else if (this.field.deliveryAddressSelection === 'searchMultiFileds' || this.field.deliveryAddressSelection === 'autoSearchMultiFields') {
      return `api/offices/contacts/lists/search?searchMultiFields=${this.searchMultiFileds}&officeId=${this.officeId}&regId=${this.regId}`;
    } else if (this.field.deliveryAddressSelection === 'ext_nip_no') {
      return '';
    }
  }

  getAddressDataObj(obj: any = {}) {
    console.log("enter val ", {
      companyAdress: obj['companyAdress'] || '',
      companyCity: obj['companyCity'] || '',
      shortName: obj['shortName'] || '',
      longName: obj['longName'] || '',
      postalcode: obj['postalcode'] || '',
      companyName: obj['companyName'] || '',
      companyId: obj['_id'] || obj['adress_id'],
      [`${this.field.label}_id`]: obj['_id'] || obj['adress_id']
  });
    return {
        companyAdress: obj['companyAdress'] || '',
        companyCity: obj['companyCity'] || '',
        shortName: obj['shortName'] || '',
        longName: obj['longName'] || '',
        postalcode: obj['postalcode'] || '',
        companyName: obj['companyName'] || '',
      companyId: obj['_id'] || obj['adress_id'],
        [`${this.field.label}_id`]: obj['_id'] || obj['adress_id']
    };
  }

  validateNip(e, value) {
    const specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
    const current = value.value || '';
    const next = current + e.key;
    if (specialKeys.includes(e.key)) {
      return;
    }
    if (this.field && this.field.onlyNumber) {
      const reg = new RegExp(`^(0|[1-9][0-9]*)$`);
      if (!reg.test(next)) {
        e.preventDefault();
      }
    }
    if (this.field && (this.field.maxDigit || this.field.maxDigit === 0)) {
      if (next && next.length > this.field.maxDigit) {
        e.preventDefault();
      }
    }
  }

  autocompleteSearch(eve: any) {
    const value = (eve && eve.target && eve.target.value);
    this.searchMultiFileds = value;
    this.field.autoCompleteSearchLength = this.field.autoCompleteSearchLength || 3;
    if (value && value.length >= this.field.autoCompleteSearchLength) {
      this.helperService.getRequest(this.getURL())
      .then((data) => {
        data = data || [];
        this.autoCompleteList = data.map(ele => this.makeAllAddress(ele)) || [];
      }).catch(err => {
        console.error(err);
      })
    }
  }

  selectOption(event: any) {
    const option = (event && event.option && event.option.value) || {};
    console.log("option.allAddress = ", option.allAddress);
    this.model = option.allAddress;
    option.adress_id = option._id;
    delete option._id;
    this.onKeyPress(option);
    this.edit = false;
  }

  async uploadToContact() {
    try {
      const dialogRef = this.dialog.open(SaveContactInfoPopupComponent, {
        width: '850px',
        height: 'max-content',
        restoreFocus: false,
        data: { fieldLabel: this.manualData, field: this.field},
        panelClass: 'happ-form-builder-dialog'
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          console.log(this.manualData);
          await this.helperService.createRegisterRecord(this.regId, this.manualData);
          this.helperService.showInfo('Added to your address book', 'Sucess', 'success');
        }
      }); 
    } catch (err) {
      this.helperService.showInfo(err, 'Error', 'error');
    }
  }

  getInitValue() {

    this.manualData = {
      shortName:      '',
      longName:       '',
      companyName:    '',
      companyAdress:  '',
      companyCity:    '',
      postalcode:     '',
    };
    this.autoCompleteList = [];
    
    if (this.field && this.field.options) {
      this.field.options = this.field.options || [];
      this.field.options = this.field.options.filter((ele) => (ele && ele.show));
      if (this.field.options.length === 1) {
        this.field.deliveryAddressSelection = this.field.options[0].value;
      }
    } else {
      this.field.options = this.defaultOptions;
    }

    if (!this.model) {
      this.edit = true;
    } else {
      if (this.field && this.field.options && this.field.options.length !== 1) {
        const temp = _.findIndex(this.field.options, { value: 'manualDeliveryAddress' });
        if (temp !== -1) {
          this.field.deliveryAddressSelection = 'manualDeliveryAddress';
        }
      }
    }
  }
}
