<div fxLayout="column">
  <p *ngIf="!hideTitle" class="m-0 ml-8"> {{ field.title | translate }} </p>
  <div fxLayout="row wrap" class="my-12">
    <button mat-raised-button [color]="(model === 'done') ? 'primary' : ''" (click)="updateStatus('done')" >
      <mat-icon style="    margin-top: -2px;">
        check_box
      </mat-icon>
      {{ 'global.done' | translate }}
    </button>
    <button mat-raised-button [color]="(model === 'inProgress') ? 'primary' : ''" (click)="updateStatus('inProgress')" >
      <mat-icon style="    margin-top: -2px;">check_box_outline_blank</mat-icon>
      {{ 'global.in_progress' | translate }}
    </button>
  </div>
</div>