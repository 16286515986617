import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-section-settings',
  templateUrl: './section-settings.component.html',
  styleUrls: ['./section-settings.component.scss']
})
export class SectionSettingsComponent implements OnInit {

  @Input() settings: any = {};
  constructor() { }

  ngOnInit() {
  }
  

}
