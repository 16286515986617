import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';
import { IRegister } from 'app/main/content/interfaces/register.interface';

@Component({
  selector: 'app-record-bulk-update',
  templateUrl: './record-bulk-update.component.html',
  styleUrls: ['./record-bulk-update.component.scss']
})
export class RecordBulkUpdateComponent implements OnInit {

  reqData: any = {
    updatedDoc: [{}]
  };
  loading = false;
  listName: boolean;
  step = "1";
  query = '';
  mailingOperation = 'create';
  actions = [
    {
      title: 'bulk_action.add_to_mailing_list',
      desc: 'bulk_action.add_to_mailing_list_desc',
      type: 'mailingList'
    },
    {
      title: 'bulk_action.update',
      desc: 'bulk_action.update_desc',
      type: 'update'
    },
    {
      title: 'bulk_action.delete',
      desc: 'bulk_action.delete_desc',
      type: 'delete'
    },
    {
      title: 'bulk_action.tags',
      desc: 'bulk_action.tags_desc',
      type: 'tags'
    },
  ]
  apps: any;
  mailingList: any = [];
  listId;
  tags: any;
  selectedField: any;
  addressBook: IRegister;
  customTagKey: any;
  customTag: boolean;
  
  constructor(public dialogRef: MatDialogRef<RecordBulkUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _helperService: HelpersService,
    private officeService: OfficeHelpersService,
    private _translationLoader: FuseTranslationLoaderService,
  ) { }

  ngOnInit() {
    this.data.fields = this.data.fields || [];
    this.data.fields = this.data.fields.filter((ele) => (ele && (ele.control_type === 'contact-search' || ele.control_type === 'contact_pick' || ele.control_type === 'delivery_address')))
    this.apps = this.officeService.getOfficeApps();
    this.getMailingList();
    this.getAddressbookRegister();
    if (this.data && this.data.isTask) {
      this.reqData.actionType = 'processTask';
      this.reqData.taskObject = this.data.taskObject;
    }
    // this.reqData.regId = this.data.regId;
    this.reqData.columnFields = this.data.columnFields || [];
    this.reqData.dtTableFilter = this.data.dtTableFilter || {};
    if (this.data.mqHardFilter) {
      if (this.reqData.dtTableFilter.mqFilter) {
        this.reqData.dtTableFilter.mqFilter = `${this.reqData.dtTableFilter.mqFilter} AND ${this.data.mqHardFilter}`;
      } else {
        this.reqData.dtTableFilter.mqFilter = this.data.mqHardFilter;
      }
    }
    this._helperService.getTrans('bulk_action')
      .then((data2) => {
        this._translationLoader.loadTranslationsV2(data2);
      }).catch(err => {
        console.info(err);
      });
  }

  confirmBulkAction() {
    if (this.reqData.actionType === "mailingList") {
      this.reqData.name = this.listName;
      this.reqData.listId = this.listId;
    }
    if (this.reqData.actionType === "tags") {
      this.reqData.field = this.selectedField;
      this.reqData.tags = this.tags;
      this.reqData.updatedDoc = this.reqData.updatedDoc || [];
      // this.reqData.updatedDoc = this.reqData.updatedDoc.filter((ele) => (ele && ele.checked));
    }
    if (this.reqData.actionType === "mailingList" && !this.listName) {
      return this._helperService.showInfo("Enter list name", "Error", "error");
    }
    // if (this.reqData.actionType === "tags" && !this.tags) {
    //   return this._helperService.showInfo("Enter tags.", "Error", "error");
    // }

    if (this.reqData.actionType === "mailingList") {
      if (this.mailingOperation === "update" || this.mailingOperation === "create") {
        let message = '';
        if (this.mailingOperation === "create") {
          message = `You are about to update mailing list. Are you sure you want to do?`;
        } else {
          message = `You are about to create new mailing list. Are you sure you want to do?`;
        }
        this._helperService.openConfirmPopUp(message)
        .subscribe((data) => {
          if (data) {
            this.makeApiRequest();
          }
        })
      } else {
        this.makeApiRequest();
      }
    } else {
      this.makeApiRequest();
    }

  }

  makeApiRequest() {
    this.loading = true;
    this.reqData.dtTableReqBody = this.data.dtTableFilter;
    this._helperService.putRequest(`api/registries/${this.data.regId}/bulkAction`, this.reqData)
    .then((data) => {
      this._helperService.openSnackBar('Done Bulk Action');
      this.dialogRef.close(true);
      this.loading = false;
    }).catch(err => {
      this._helperService.openSnackBar(err);
      this.loading = false;
    });
  }

  async getMailingList() {
    try {
      this.mailingList = await this._helperService.getRequest(`api/reg/${this.apps.mailingList.id}/list`);
    } catch (error) {
      this._helperService.showInfo(error, "Error", "error");
    }
  }

  async getAddressbookRegister() {
    try {
      this.addressBook = await this._helperService.getRegisterData(this.apps.office_addressbook.id);
      this.addressBook.tagKeys = this.addressBook.tagKeys || [];
      this.reqData.updatedDoc = [];
      this.addressBook.tagKeys.forEach((ele) => {
        this.reqData.updatedDoc.push({
          keyName: ele.key,
          value: "",
          checked: false
        })
      });
    } catch (error) {
      this._helperService.showInfo(error, "Error", "error");
    }
  }

  addTag() {
    this.reqData.updatedDoc.push({
      keyName: this.customTagKey,
      value: "",
      checked: true
    })
    this.customTagKey = '';
    this.customTag = false;
  }

}
