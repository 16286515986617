import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { OfficeHelpersService } from 'app/main/content/services/office-helpers/office-helpers.service';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material';
import { SaveContactInfoPopupComponent } from './save-contact-info-popup/save-contact-info-popup.component';
var DeliveryAddressComponent = /** @class */ (function () {
    function DeliveryAddressComponent(translationLoader, helperService, dialog, _officeHelper) {
        this.translationLoader = translationLoader;
        this.helperService = helperService;
        this.dialog = dialog;
        this._officeHelper = _officeHelper;
        this.onFieldValueUpdated = new EventEmitter();
        this.onError = new EventEmitter();
        this.modelChange = new EventEmitter();
        this.updateAddress = new EventEmitter();
        this.autoCompleteList = [];
        this.defaultOptions = [
            {
                title: 'deliveryAddress.name',
                value: 'searchMultiFileds',
                show: true
            },
            {
                title: 'deliveryAddress.nipNumber',
                value: 'searchNip',
                show: true
            },
            {
                title: 'deliveryAddress.cityOrStreet',
                value: 'cityAndStreet',
                show: true
            },
            // {
            //   title: 'deliveryAddress.externalNip',
            //   value: 'by_nip_number_ext_api',
            //   show: true
            // },
            {
                title: 'deliveryAddress.manual',
                value: 'manualDeliveryAddress',
                show: true
            },
            {
                title: 'deliveryAddress.autocomplete_name',
                value: 'autoSearchMultiFields',
                show: false
            },
        ];
        this.query = '';
        this.edit = false;
        this.manualData = {
            shortName: '',
            longName: '',
            companyName: '',
            companyAdress: '',
            companyCity: '',
            postalcode: '',
        };
        this.loading = false;
    }
    DeliveryAddressComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.translateData && changes.translateData.currentValue) {
            this.translateData = changes.translateData.currentValue;
            this.translationLoader.loadTranslationsV2(this.translateData);
        }
    };
    DeliveryAddressComponent.prototype.ngOnInit = function () {
        this.translationLoader.loadTranslationsV2(this.translateData);
        this.getInitValue();
        this.regRec = this.regRec || {};
        this.manualData = this.getAddressDataObj(this.regRec);
        this.getOfficeAndRegId();
        if (this.field['required'] && (this.model === null || this.model === undefined || this.model === '')) {
            this.error = true;
            this.errorMessage = 'ct.field_is_required';
            if (!this.model) {
                this.onError.emit({
                    error: true,
                    label: this.field['label']
                });
            }
        }
    };
    DeliveryAddressComponent.prototype.getOfficeAndRegId = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var officeDoc, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this._officeHelper.getLocalOfficeData()];
                    case 1:
                        officeDoc = _a.sent();
                        this.officeId = officeDoc._id;
                        if (officeDoc && officeDoc.apps && officeDoc.apps.office_addressbook) {
                            this.regId = officeDoc.apps.office_addressbook.id;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error(error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DeliveryAddressComponent.prototype.ngOnDestroy = function () {
        this.result = [];
        this.edit = false;
    };
    DeliveryAddressComponent.prototype.onKeyPress = function (option) {
        if (option !== 'manual' && option && Object.keys(option).length) {
            this.error = false;
            this.onError.emit({
                error: false,
                label: this.field['label']
            });
            this.updateAddress.emit(this.getAddressDataObj(option));
            this.manualData = this.getAddressDataObj(option);
        }
        else {
            if (this.field.deliveryAddressSelection === 'manualDeliveryAddress') {
                // tslint:disable-next-line:max-line-length
                this.model = (this.manualData['companyName'] || '') + ' ' + (this.manualData['postalcode'] || '') + ' ' + (this.manualData['longName'] || '') + ' ' + (this.manualData['shortName'] || '') + ' ' + (this.manualData['companyCity'] || '') + ' ' + (this.manualData['companyAdress'] || '');
                this.modelChange.next(this.model);
                this.error = false;
                this.onError.emit({
                    error: false,
                    label: this.field['label']
                });
                this.updateAddress.emit(this.getAddressDataObj(this.manualData));
            }
        }
        this.modelChange.next(this.model);
        this.onFieldValueUpdated.emit({
            label: this.field.label,
            value: this.model,
            ruleIndex: this.field.ruleIndex,
            field: this.field
        });
    };
    DeliveryAddressComponent.prototype.search = function () {
        var _this = this;
        if (this.regId && this.officeId) {
            this.loading = true;
            this.helperService.getRequest(this.getURL())
                .then(function (data) {
                _this.loading = false;
                _this.result = data || [];
                if (!_this.result) {
                    _this.message = 'Nie znaleziono danych tego wyszukiwania';
                    return;
                }
                else {
                    _this.message = _this.result.length + ' znalezionych danych';
                }
                console.log("this result", _this.result);
                _this.result = _this.result.map(function (element) { return _this.makeAllAddress(element); });
            }).catch(function (err) {
                _this.loading = false;
                _this.message = err;
                console.info(err);
            });
        }
        else {
            console.log('No office or reg Id found');
            this.message = 'No office or reg Id found';
        }
    };
    DeliveryAddressComponent.prototype.makeAllAddress = function (ele) {
        if (ele === void 0) { ele = {}; }
        try {
            ele.allAddress =
                (ele['companyName'] || '') + " " + (ele['postalcode'] || '') + " " + (ele['longName'] || '') + " " + (ele['shortName'] || '') + " " + (ele['companyCity'] || '') + " " + (ele['companyAdress'] || '');
            return ele;
        }
        catch (error) {
            console.error(error);
        }
    };
    DeliveryAddressComponent.prototype.getURL = function () {
        if (this.field.deliveryAddressSelection === 'cityAndStreet') {
            return "api/offices/contacts/lists/search?companyAdress=" + this.street + "&companyCity=" + this.city + "&officeId=" + this.officeId + "&regId=" + this.regId;
        }
        else if (this.field.deliveryAddressSelection === 'searchNip') {
            return "api/offices/contacts/lists/search?nip=" + this.nip + "&officeId=" + this.officeId + "&regId=" + this.regId;
        }
        else if (this.field.deliveryAddressSelection === 'searchMultiFileds' || this.field.deliveryAddressSelection === 'autoSearchMultiFields') {
            return "api/offices/contacts/lists/search?searchMultiFields=" + this.searchMultiFileds + "&officeId=" + this.officeId + "&regId=" + this.regId;
        }
        else if (this.field.deliveryAddressSelection === 'ext_nip_no') {
            return '';
        }
    };
    DeliveryAddressComponent.prototype.getAddressDataObj = function (obj) {
        if (obj === void 0) { obj = {}; }
        var _a, _b;
        console.log("enter val ", (_a = {
                companyAdress: obj['companyAdress'] || '',
                companyCity: obj['companyCity'] || '',
                shortName: obj['shortName'] || '',
                longName: obj['longName'] || '',
                postalcode: obj['postalcode'] || '',
                companyName: obj['companyName'] || '',
                companyId: obj['_id'] || obj['adress_id']
            },
            _a[this.field.label + "_id"] = obj['_id'] || obj['adress_id'],
            _a));
        return _b = {
                companyAdress: obj['companyAdress'] || '',
                companyCity: obj['companyCity'] || '',
                shortName: obj['shortName'] || '',
                longName: obj['longName'] || '',
                postalcode: obj['postalcode'] || '',
                companyName: obj['companyName'] || '',
                companyId: obj['_id'] || obj['adress_id']
            },
            _b[this.field.label + "_id"] = obj['_id'] || obj['adress_id'],
            _b;
    };
    DeliveryAddressComponent.prototype.validateNip = function (e, value) {
        var specialKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
        var current = value.value || '';
        var next = current + e.key;
        if (specialKeys.includes(e.key)) {
            return;
        }
        if (this.field && this.field.onlyNumber) {
            var reg = new RegExp("^(0|[1-9][0-9]*)$");
            if (!reg.test(next)) {
                e.preventDefault();
            }
        }
        if (this.field && (this.field.maxDigit || this.field.maxDigit === 0)) {
            if (next && next.length > this.field.maxDigit) {
                e.preventDefault();
            }
        }
    };
    DeliveryAddressComponent.prototype.autocompleteSearch = function (eve) {
        var _this = this;
        var value = (eve && eve.target && eve.target.value);
        this.searchMultiFileds = value;
        this.field.autoCompleteSearchLength = this.field.autoCompleteSearchLength || 3;
        if (value && value.length >= this.field.autoCompleteSearchLength) {
            this.helperService.getRequest(this.getURL())
                .then(function (data) {
                data = data || [];
                _this.autoCompleteList = data.map(function (ele) { return _this.makeAllAddress(ele); }) || [];
            }).catch(function (err) {
                console.error(err);
            });
        }
    };
    DeliveryAddressComponent.prototype.selectOption = function (event) {
        var option = (event && event.option && event.option.value) || {};
        console.log("option.allAddress = ", option.allAddress);
        this.model = option.allAddress;
        option.adress_id = option._id;
        delete option._id;
        this.onKeyPress(option);
        this.edit = false;
    };
    DeliveryAddressComponent.prototype.uploadToContact = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dialogRef;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    dialogRef = this.dialog.open(SaveContactInfoPopupComponent, {
                        width: '850px',
                        height: 'max-content',
                        restoreFocus: false,
                        data: { fieldLabel: this.manualData, field: this.field },
                        panelClass: 'happ-form-builder-dialog'
                    });
                    dialogRef.afterClosed().subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!result) return [3 /*break*/, 2];
                                    console.log(this.manualData);
                                    return [4 /*yield*/, this.helperService.createRegisterRecord(this.regId, this.manualData)];
                                case 1:
                                    _a.sent();
                                    this.helperService.showInfo('Added to your address book', 'Sucess', 'success');
                                    _a.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); });
                }
                catch (err) {
                    this.helperService.showInfo(err, 'Error', 'error');
                }
                return [2 /*return*/];
            });
        });
    };
    DeliveryAddressComponent.prototype.getInitValue = function () {
        this.manualData = {
            shortName: '',
            longName: '',
            companyName: '',
            companyAdress: '',
            companyCity: '',
            postalcode: '',
        };
        this.autoCompleteList = [];
        if (this.field && this.field.options) {
            this.field.options = this.field.options || [];
            this.field.options = this.field.options.filter(function (ele) { return (ele && ele.show); });
            if (this.field.options.length === 1) {
                this.field.deliveryAddressSelection = this.field.options[0].value;
            }
        }
        else {
            this.field.options = this.defaultOptions;
        }
        if (!this.model) {
            this.edit = true;
        }
        else {
            if (this.field && this.field.options && this.field.options.length !== 1) {
                var temp = _.findIndex(this.field.options, { value: 'manualDeliveryAddress' });
                if (temp !== -1) {
                    this.field.deliveryAddressSelection = 'manualDeliveryAddress';
                }
            }
        }
    };
    return DeliveryAddressComponent;
}());
export { DeliveryAddressComponent };
