import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-ct-ss-cat-popup',
  templateUrl: './ct-ss-cat-popup.component.html',
  styleUrls: ['./ct-ss-cat-popup.component.scss']
})
export class CtSsCatPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CtSsCatPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    console.log("data - ", this.data);
  }

  toogleSelect(item) {
    // console.log("data -", item, item2);
    this.data.selectedAreas.push(item.key);
  }

  toogleSelectType(item) {
    this.data.mySelectedTypes.push(item.key);
  }

  save() {
    this.dialogRef.close({
      ...this.data
    })
  }
}
