import * as tslib_1 from "tslib";
import { Observable } from 'rxjs/Observable';
import { environment } from 'environments/environment';
import * as socketIo from 'socket.io-client';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
var SocketService = /** @class */ (function () {
    function SocketService(toastrService) {
        this.toastrService = toastrService;
        this.previousEvent = {};
        this.subscribedChannels = [];
        this.onCallEvent = new BehaviorSubject({});
        this.onSocketEventTriggered = new BehaviorSubject({});
        this.callsList = [];
        this.getUniqueObjectFromArray = function (array, keyName) {
            if (array === void 0) { array = []; }
            var resArr = [];
            array.filter(function (item) {
                var index = resArr.findIndex(function (ele) { return ele[keyName] === item[keyName]; });
                if (index <= -1) {
                    resArr.push(item);
                }
                return null;
            });
            return resArr;
        };
    }
    SocketService.prototype.initSocket = function (token) {
        if (!environment.production) {
            console.log("YOU ARE WORKING IN LOCAL ENV - NO SOCKET CONNECTED");
            return;
        }
        this.socket = socketIo(environment.serverUrl, {
            query: {
                token: token
            },
            transports: ['websocket', 'polling']
        });
        this.callsList = [];
        this.subscribeToChannel();
        this.unSubscribeToChannel();
    };
    SocketService.prototype.subscribeToBasicEvents = function () {
        if (!this.socket) {
            return;
        }
        this.socket.on('disconnect', function (data) {
            console.log('disconnect', data);
        });
        this.socket.on('connection', function (data) {
            console.log('connection', data);
        });
    };
    SocketService.prototype.disconnectSocket = function () {
        var _this = this;
        if (this.socket) {
            this.socket.disconnect();
            this.subscribedChannels.forEach(function (ele) {
                _this.socket.removeListener(ele);
            });
            this.subscribedChannels = [];
        }
    };
    SocketService.prototype.reConnectSocket = function () {
        if (!this.socket) {
            var data = localStorage.getItem('ngStorage-token');
            if (data) {
                data = JSON.parse(data);
                this.initSocket(data.token);
            }
        }
    };
    SocketService.prototype.send = function (message) {
        if (!this.socket) {
            return;
        }
        this.socket.emit('message', message);
    };
    SocketService.prototype.sendSocketEvent = function (eventName, data) {
        if (!this.socket) {
            return;
        }
        // console.log('sendSocketEvent', eventName, data);
        this.socket.emit(eventName, data);
    };
    SocketService.prototype.usersNotifyToReload = function (users) {
        if (!this.socket) {
            return;
        }
        this.socket.emit('usersNotifyToReload', users);
    };
    SocketService.prototype.electronicSender = function (users) {
        if (!this.socket) {
            return;
        }
        this.socket.emit('electronicSenderData', users);
    };
    SocketService.prototype.packageSenderFilter = function (packageFilter, params) {
        if (!this.socket) {
            return;
        }
        this.socket.emit('packageSenderFilterData', { packageFilter: packageFilter, params: params });
    };
    SocketService.prototype.authenticate = function (token) {
        if (!this.socket) {
            return;
        }
        this.socket.on('authenticated', function () {
        }).emit('authenticate', { token: token });
    };
    SocketService.prototype.userNotifyToUpdate = function () {
        var _this = this;
        return new Observable(function (observer) {
            if (!_this.socket) {
                return;
            }
            _this.socket.on('userNotifyToUpdate', function () { return observer.next(); });
        });
    };
    SocketService.prototype.electronicReciver = function () {
        var _this = this;
        return new Observable(function (observer) {
            if (!_this.socket) {
                return;
            }
            _this.socket.on('electronicReceiverData', function (data) { return observer.next(data); });
        });
    };
    SocketService.prototype.packageSenderReciver = function () {
        var _this = this;
        return new Observable(function (observer) {
            if (!_this.socket) {
                return;
            }
            _this.socket.on('packageSenderReciverData', function (data) { return observer.next(data); });
        });
    };
    SocketService.prototype.onEvent = function (event) {
        var _this = this;
        return new Observable(function (observer) {
            if (!_this.socket) {
                return;
            }
            _this.socket.on(event, function () { return observer.next(); });
        });
    };
    SocketService.prototype.inviationNotify = function () {
        var _this = this;
        return new Observable(function (observer) {
            if (!_this.socket) {
                return;
            }
            _this.socket.on('inviationNotify', function (data) { return observer.next(data); });
        });
    };
    SocketService.prototype.showInfo = function (message, title, type, options) {
        if (title === void 0) { title = ''; }
        if (type === void 0) { type = 'info'; }
        if (options === void 0) { options = {}; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.toastrService[type](message, title, options);
                return [2 /*return*/];
            });
        });
    };
    SocketService.prototype.subscribeToChannel = function () {
        var _this = this;
        if (!this.socket) {
            return;
        }
        this.socket.on('message', function (data) {
            console.log("message recived", data);
        });
        this.socket.on('channelJoined', function (data) {
            // console.log('Channels list', this.subscribedChannels);
            if (_this.subscribedChannels.includes(data.channelName)) {
                console.log(' *** User already in channel', data);
            }
            else {
                data.eventType = data.eventType || 'defaultEvent';
                _this.subscribedChannels.push(data.channelName);
                // console.log('--------- channelJoined ', data);
                _this.socket.on(data.channelName, function (res) {
                    // console.log('triggered from backend - ', res);
                    if (data.eventType === 'phoneNumber') {
                        _this[data.eventType](tslib_1.__assign({ that: _this }, res));
                    }
                    else {
                        _this.onSocketEventTriggered.next({ eventType: data.eventType, res: tslib_1.__assign({ that: _this }, res) });
                    }
                    return true;
                });
            }
        });
    };
    SocketService.prototype.unSubscribeToChannel = function () {
        var _this = this;
        if (!this.socket) {
            return;
        }
        console.log('unsubscribeToChannel');
        this.socket.on('channelLeaved', function (data) {
            // console.log('--------- channelLeaved ', data);
            _this.socket.removeListener(data.channelName);
            if (_this.subscribedChannels.indexOf(data.channelName) !== -1) {
                _this.subscribedChannels.splice(_this.subscribedChannels.indexOf(data.channelName), 1);
            }
        });
    };
    SocketService.prototype.subscribeToNotification = function (type, channelName) {
        var _this = this;
        if (!this.socket) {
            return;
        }
        if (this.previousEvent[type]) {
            this.socket.removeListener(this.previousEvent[type]);
        }
        this.previousEvent[type] = channelName;
        // console.log('subscribing to ', channelName);
        this.socket.on(channelName, function (data) {
            // console.log('event  ', data);
            _this.showInfo(data);
        });
    };
    SocketService.prototype.defaultEvent = function (res) {
        console.log('triggering default event', res);
    };
    SocketService.prototype.phoneNumber = function (res) {
        // console.log('phoneNumber', res);
        res.that.callsList = res.that.callsList || [];
        switch (res.operationType) {
            case 'new':
                // const temp = JSON.parse(JSON.stringify(res.that.callsList));
                var temp = res.that.callsList;
                temp.unshift(res);
                res.that.callsList = res.that.getUniqueObjectFromArray(temp, 'callId');
                break;
            case 'update':
                {
                    var index = _.findIndex(res.that.callsList, { callId: res.callId });
                    if (index !== -1) {
                        res.that.callsList[index] = res;
                    }
                }
                break;
            case 'delete':
                {
                    var index = _.findIndex(res.that.callsList, { callId: res.callId });
                    // console.log("totla", res.that.callsList);
                    // console.log("removing item fronm", index);
                    if (index !== -1) {
                        res.that.callsList.splice(index, 1);
                    }
                }
                break;
        }
        res.that.onCallEvent.next({
            eventType: 'new_call'
        });
    };
    return SocketService;
}());
export { SocketService };
