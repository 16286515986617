import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { MatDialog } from '@angular/material';
import { WorkspaceStore } from 'app/main/content/state/workspaceState/workspace.store';
import { Router } from '@angular/router';

@Component({
  selector: 'app-field-settings',
  templateUrl: './field-settings.component.html',
  styleUrls: ['./field-settings.component.scss']
})
export class FieldSettingsComponent implements OnInit {

  @Input() settings: any = {};
  oldVal = {};
  tempCounter = Date.now();
  constructor(
    private _helperService: HelpersService,
    public dialog: MatDialog,
    private workspaceStore: WorkspaceStore,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router

  ) { }

  ngOnInit() {
    this.settings = (this.settings && this.settings.settings);
    this.setFieldsInitValue();
  }

  colorSelectionChanges(color: any, setting: any) {
    this.valueChanged(setting.initValue, setting);
  }

  setFieldsInitValue() {
    if (this.settings) {
      this.settings.fields = this.settings.fields || [];
      this.settings.fields = this.settings.fields.map((ele: any) => {
        // console.log(`${ele.title}/${ele.keyName} ----> ${ele.initValue}`);
        ele.initValue = (ele.initValue || ele.initValue === false) ? ele.initValue : ele.defaultValue;
        // console.log(`${ele.title}/${ele.keyName} ----> ${ele.initValue}`);

        this.oldVal[ele.keyName] = ele.initValue;
        return ele;
      });
    }
  }

  getFieldsData() {
    const temp = {};
    if (this.settings) {
      this.settings.fields = this.settings.fields || [];
      this.settings.fields.forEach((ele) => {
        temp[ele.keyName] = ele.initValue;
      });
    }
    return temp;
  }

  valueChanged(newVal: any, setting: any) {
    if (setting.type === 'number' && parseFloat(newVal) !== NaN) {
      newVal = parseFloat(newVal);
      setting.initValue = parseFloat(setting.initValue);
    }
    if ((this.oldVal && this.oldVal[setting.keyName] !== newVal) || setting.fieldType === 'popup') {
      this.oldVal[setting.keyName] = newVal;
      const temp = {};
      temp[setting.keyName] = setting.initValue;
      this.saveData(temp);
    }
  }

  saveData(data: any) {
    this.tempCounter = Date.now();
    this.changeDetectorRef.detectChanges();
    if (this.settings.triggerSub) {
      this.settings.triggerSub.next(data);
    } else {
      this._helperService.putRequest(this.settings.updateApi, data)
      .then((newWsData) => {
        this._helperService.showInfo('Setting Updated', '', 'success');
        this.workspaceStore.update({workspaceData: newWsData});
      }).catch((err) => {
        this._helperService.showInfo(err, '', 'error');
      });
    }
  }

  openPopup(setting: any) {
    setting.extraData = setting.extraData || {};
    const temp: any = {...setting.extraData};
    temp[setting.keyName] = setting.initValue;
    temp['extraData'] = setting.extraData || {};
    temp['initValue'] = setting.initValue;
    const dialogRef = this.dialog.open(setting.component, {
      width: setting.width,
      height: setting.height,
      panelClass: 'happ-form-builder-dialog',
      data: temp
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        switch (setting.keyName) {
          case 'formId':
            setting.initValue = result;
            this.valueChanged(result, setting);
            break;
          default:
            if (result !== null) {
              setting.initValue = result;
              this.valueChanged(result, setting);
            }
        }
      }
    });
  }

  dateFilterChange(event: any, setting: any) {
    setting.initValue = event;
    this.valueChanged(event, setting);
  }

  moveTo(setting: any) {
    try {
      if (localStorage.getItem('ngStorage-selectedOffice') && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))) {
        const officeId = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))._id;
        if (setting && setting.routeType === 'process') {
          this.router.navigate([`/offices/${officeId}/process-settings/process-settings-list/process-settings-list/${setting.initValue}`]);
        } else if (setting && setting.routeType === 'register') { 
          this.router.navigate([`/offices/${officeId}/application-settings/a2/${setting.initValue}/general`]);
        }
        setting.dialogRef.close(null);
      }
    } catch (error) {
      console.info(error);
    }
  }
}
