import * as tslib_1 from "tslib";
import { OnInit, AfterViewInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MatSnackBar } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { HelpersService } from '../../services/helpers/helpers.service';
import { TranslateService } from '@ngx-translate/core';
import { CK_EDITOR_CONFIG, CK_EDITOR } from '../../consts/ckEditorConfig.const';
var TemplateModalComponent = /** @class */ (function () {
    function TemplateModalComponent(dialogRef, snackBar, sanitizer, helperService, _translateService, data) {
        this.dialogRef = dialogRef;
        this.snackBar = snackBar;
        this.sanitizer = sanitizer;
        this.helperService = helperService;
        this._translateService = _translateService;
        this.data = data;
        this.showInputs = false;
        this.rightPanelView = 'mainContent';
        this.rightPanelLang = 'en';
        this.editor = CK_EDITOR;
        this.currentValue = '';
        this.sections = [
            {
                title: "mainContent",
                key: "mainContent"
            },
            {
                title: "header",
                key: "header"
            },
            {
                title: "footer",
                key: "footer"
            }
        ];
        this.options = {
            contextmenu: true,
            minimap: {
                enabled: true
            }
        };
        this.editorTypes = {
            admin: ['Source', '-', 'Table',
                'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
                'Bold', 'Italic', '-', 'NumberedList', 'BulletedList', 'FontSize', 'TextColor', '-', 'HorizontalRule', 'Image', 'Link'],
            superAdmin: ['Source', '-', 'Table',
                'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
                'Bold', 'Italic', '-', 'NumberedList', 'BulletedList', 'FontSize', 'TextColor', '-', 'HorizontalRule', 'Image', 'Link'],
            normal: ['Bold', 'Italic', '-', 'NumberedList', 'BulletedList', 'FontSize', 'TextColor', '-', 'Table', 'HorizontalRule']
        };
        this.config = {
            mainContent: {
                height: 700,
                allowedContent: true,
                toolbar: [
                    ['Source', '-', 'Table',
                        'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
                        'Bold', 'Italic', '-', 'NumberedList', 'BulletedList', 'FontSize', 'TextColor', '-', 'HorizontalRule', 'Image', 'Link']
                ]
            },
            header: {
                height: 700,
                allowedContent: true,
                toolbar: [
                    ['Source', '-', 'Table',
                        'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
                        'Bold', 'Italic', '-', 'NumberedList', 'BulletedList', 'FontSize', 'TextColor', '-', 'HorizontalRule', 'Image', 'Link']
                ]
            },
            footer: {
                height: 700,
                allowedContent: true,
                toolbar: [
                    ['Source', '-', 'Table',
                        'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock',
                        'Bold', 'Italic', '-', 'NumberedList', 'BulletedList', 'FontSize', 'TextColor', '-', 'HorizontalRule', 'Image', 'Link']
                ]
            }
        };
        this.fieldVars = [
            {
                key: "##processRecordReport##",
                pupetter: true,
                wkhtml: true
            },
            {
                key: "##pageBreak##",
                pupetter: true,
                wkhtml: true
            },
            {
                key: "##page##",
                pupetter: false,
                wkhtml: true
            },
            {
                key: "##toPage##",
                pupetter: false,
                wkhtml: true
            },
            {
                key: "##v2Page##",
                pupetter: true,
                wkhtml: false
            },
            {
                key: "##v2toPage##",
                pupetter: true,
                wkhtml: false
            },
            {
                key: "##RESPONSIBLE.SHORTNAME##",
                pupetter: true,
                wkhtml: true
            },
            {
                key: "##RESPONSIBLE.LONGNAME##",
                pupetter: true,
                wkhtml: true
            },
            {
                key: "##CLIENTNAME##",
                pupetter: true,
                wkhtml: true
            },
            {
                key: "##REPORT_NAME##",
                pupetter: true,
                wkhtml: true
            },
            {
                key: "##REGISTER_NAME##",
                pupetter: true,
                wkhtml: true
            },
            {
                key: "##PANEL_NAME##",
                pupetter: true,
                wkhtml: true
            },
            {
                key: "##CREATED_BY##",
                pupetter: true,
                wkhtml: true
            },
            {
                key: "##REPORT_TIME##",
                pupetter: true,
                wkhtml: true
            },
            {
                key: "##REPORT_DATE##",
                pupetter: true,
                wkhtml: true
            },
            {
                key: "##OFFICE_NAME##",
                pupetter: true,
                wkhtml: true
            },
            {
                key: "##CREATED_BY##",
                pupetter: true,
                wkhtml: true
            },
            {
                key: "##postBookTemplate##",
                pupetter: true,
                wkhtml: true
            },
            {
                key: "##positionTable_abc##",
                pupetter: true,
                wkhtml: true
            },
            {
                key: "##recCommentHTML##",
                pupetter: true,
                wkhtml: true
            },
            {
                key: "##grid_report_table##",
                pupetter: true,
                wkhtml: true
            }
        ];
        this.previewView = 'html';
        this.htmlPreviewLang = 'en';
        this.formVar = [];
        this.templateData = {
            category: '',
            title: '',
            officeId: '',
            typeOfGenerator: 'puppeter',
            logoWidth: '100px',
            logoHeight: '50px',
            shortSymbol: '',
            logoBase64: '',
            type: 'recordTemplate',
            gridColumns: [
                {
                    width: 10,
                    align: 'LEFT',
                    heading: 'New Column 1',
                    variables: '##line##'
                }
            ],
            header: {
                show: true,
                editorType: "ck_editor",
                subType: "superAdmin",
                headerTemplate: "custom",
                slogan: '',
                selectedLangs: ['en'],
                content: '',
                translation: {}
            },
            footer: {
                show: true,
                editorType: "ck_editor",
                subType: "superAdmin",
                footerTemplate: 'custom',
                selectedLangs: ['en'],
                pageTemplateVar: '',
                content: '',
                slogan: '',
                translation: {}
            },
            mainContent: {
                editorType: "ck_editor",
                subType: "superAdmin",
                selectedLangs: ['en'],
                content: '',
                translation: {}
            },
            printringVarialbles: {
                line: {
                    header: true,
                    footer: true
                },
                margin: {
                    bottom: 20,
                    top: 20
                },
                spacing: {
                    footer: 5,
                    header: 5
                }
            }
        };
        this.globalVars = [];
        this.currentView = 'editor';
        this.defHeaderModel = [[{
                    type: 'input',
                    text: 'Demo',
                    fontSize: '18px'
                }], [{
                    type: 'input',
                    text: 'Demo',
                    fontSize: '18px'
                }]];
        this.defFooterModel = [[{
                    type: 'input',
                    text: 'Demo',
                    fontSize: '18px'
                }], [{
                    type: 'input',
                    text: 'Demo',
                    fontSize: '18px'
                }],
            [{
                    type: 'input',
                    text: 'Demo',
                    fontSize: '18px'
                }]];
        this.languages = [];
    }
    TemplateModalComponent.prototype.closeDialog = function () {
        this.dialogRef.close();
    };
    TemplateModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.ckEditorConfig = JSON.parse(JSON.stringify(CK_EDITOR_CONFIG));
        this.ckEditorConfig.language = this._translateService.getDefaultLang();
        //  get the global vars..
        var selectedOffice = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'));
        if (selectedOffice && selectedOffice['users']) {
            selectedOffice['users'].forEach(function (ele) {
                _this.globalVars.push(ele['email']);
            });
        }
        this.operation = this.data['operation'];
        if (this.operation === 'edit') {
            this.templateData = this.data.data;
            if (!this.templateData.title) {
                this.showInputs = true;
            }
            this.initCheck();
            this.templateData.header = this.templateData.header || { content: '', show: true, translation: {} };
            this.templateData.header.content = this.templateData.header.content || '';
            this.templateData.header.translation = this.templateData.header.translation || {};
            this.templateData.footer = this.templateData.footer || { content: '', show: true, translation: {} };
            this.templateData.footer.content = this.templateData.footer.content || '';
            this.templateData.footer.translation = this.templateData.footer.translation || {};
            this.templateData.mainContent.translation = this.templateData.mainContent.translation || {};
            this.templateData.mainContent.editorType = this.templateData.mainContent.editorType || 'ck_editor';
            this.templateData.footer.editorType = this.templateData.footer.editorType || 'ck_editor';
            this.templateData.header.editorType = this.templateData.header.editorType || 'ck_editor';
            this.templateData.mainContent.selectedLangs = this.templateData.mainContent.selectedLangs || ['en'];
            this.templateData.footer.selectedLangs = this.templateData.footer.selectedLangs || ['en'];
            this.templateData.header.selectedLangs = this.templateData.header.selectedLangs || ['en'];
            console.log('template data - ', this.templateData);
        }
        else {
            this.templateData.mainContent.content = '';
            this.templateData.title = '';
            this.templateData.category = '';
            this.templateData.shortSymbol = '';
        }
        this.getLang();
    };
    TemplateModalComponent.prototype.getLang = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, err_1;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 2, , 3]);
                        _a = this;
                        _c = (_b = this.helperService).formatTheLanguageArray;
                        return [4 /*yield*/, this.helperService.getActiveLangs()];
                    case 1:
                        _a.languages = _c.apply(_b, [_d.sent()]);
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _d.sent();
                        console.error(err_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    TemplateModalComponent.prototype.initCheck = function () {
        console.log(this.templateData);
        this.onSubTypeChange(null, "mainContent");
        this.onSubTypeChange(null, "header");
        this.onSubTypeChange(null, "footer");
    };
    TemplateModalComponent.prototype.downloadFile = function (data) {
        var blob = new Blob([data], { type: 'text/pdf' });
        var url = window.URL.createObjectURL(blob);
        // window.open(url);
    };
    TemplateModalComponent.prototype.saveData = function (notClose) {
        var _this = this;
        if (!this.templateData.isCustom) {
            this.helperService.openConfirmPopUp('You are about to update installed element, if you update it you cannot update this from the marketPlace.')
                .subscribe(function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    if (data) {
                        this.templateData.isCustom = true;
                        this.saveDataHelper(notClose);
                    }
                    return [2 /*return*/];
                });
            }); });
        }
        else {
            delete this.templateData.isCustom;
            this.saveDataHelper(notClose);
        }
    };
    TemplateModalComponent.prototype.saveDataHelper = function (notClose) {
        var _this = this;
        if (!this.templateData.title && this.templateData.title.length === 0) {
            return this.snackBar.open('Fill title', 'Okey', {
                duration: 2000,
            });
        }
        this.templateData['formId'] = this.selectedFormId;
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
            this.templateData.officeId = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'];
            if (this.operation === 'add') {
                this.helperService.createTemplate(this.templateData)
                    .then(function () {
                    if (!notClose) {
                        _this.dialogRef.close();
                    }
                    _this.snackBar.open('Saved Successfully', 'Okey', {
                        duration: 2000,
                    });
                }).catch(function (err) {
                    console.info(err);
                    _this.snackBar.open(err || 'Error Occured while creating', 'Okey', {
                        duration: 2000,
                    });
                });
            }
            else {
                this.helperService.updateTemplate(this.data['data']['_id'], this.templateData)
                    .then(function () {
                    if (!notClose) {
                        _this.dialogRef.close();
                    }
                    _this.snackBar.open('Updated Successfully', 'Okey', {
                        duration: 2000,
                    });
                }).catch(function (err) {
                    console.info(err);
                    _this.snackBar.open(err || 'Error occured while updating', 'Okey', {
                        duration: 2000,
                    });
                });
            }
        }
        else {
            this.snackBar.open('No office Id', 'Okey', {
                duration: 2000,
            });
        }
    };
    TemplateModalComponent.prototype.updatePDF = function () {
        var _this = this;
        if (this.data && this.data['data'] && this.data['data']['_id']) {
            this.helperService.getDocument(this.data['data']['_id'])
                .then(function (document) {
                var blob = _this.helperService.b64toBlob(document['base64'], 'application/pdf', null);
                var blobUrl = URL.createObjectURL(blob);
                _this.pdfURL = _this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
            }).catch(function (err) {
                _this.snackBar.open(err || 'Error occured while updating the template', 'Okey', {
                    duration: 2000,
                });
                console.info(err);
            });
        }
        else {
            this.snackBar.open('No Template Id', 'Okey', {
                duration: 2000,
            });
        }
    };
    TemplateModalComponent.prototype.ngAfterViewInit = function () {
        if (this.data.data) {
            this.selectedFormId = this.data['data']['formId'];
        }
    };
    TemplateModalComponent.prototype.typeChanged = function () {
        if (this.templateData) {
            this.templateData.gridColumns = this.templateData.gridColumns || [{
                    width: 10,
                    align: 'LEFT',
                    heading: 'New Column 1',
                    variables: '##line##'
                }];
        }
    };
    TemplateModalComponent.prototype.addColumn = function () {
        if (this.templateData) {
            this.templateData.gridColumns = this.templateData.gridColumns || [];
            this.templateData.gridColumns.push({
                width: 10,
                align: 'LEFT',
                heading: 'New Column',
                variables: '##line##'
            });
        }
    };
    TemplateModalComponent.prototype.removeColumn = function (i) {
        if (this.templateData && this.templateData.gridColumns && this.templateData.gridColumns[i]) {
            this.templateData.gridColumns.splice(i, 1);
        }
    };
    TemplateModalComponent.prototype.dataUpdatedEvent = function (event, type) {
        switch (type) {
            case 'header':
                this.templateData.headerJson = event.json;
                this.templateData.headerJson = event.json;
                this.templateData.header = this.templateData.header || {};
                this.templateData.header.content = event.htmlData;
                break;
            case 'footer':
                this.templateData.footerJson = event.json;
                this.templateData.footerJson = event.json;
                this.templateData.footer = this.templateData.footer || {};
                this.templateData.footer.content = event.htmlData;
                break;
        }
    };
    TemplateModalComponent.prototype.onSubTypeChange = function (event, type) {
        console.log(event, type, this.templateData[type].editorType);
        if (this.templateData[type].editorType === "ck_editor") {
            this.config[type].toolbar[0] = this.editorTypes[this.templateData[type].subType];
        }
        console.log(this.config[type]);
    };
    TemplateModalComponent.prototype.onCheckBoxChange = function (lang, type) {
        if (lang !== 'en') {
            this.templateData[type].selectedLangs = this.templateData[type].selectedLangs || [];
            if (this.templateData[type].selectedLangs.includes(lang)) {
                var temp = this.templateData[type].selectedLangs.indexOf(lang);
                this.templateData[type].selectedLangs.splice(temp, 1);
            }
            else {
                this.templateData[type].selectedLangs.push(lang);
            }
        }
        console.log("this.templateData[type].selectedLangs", this.templateData[type].selectedLangs);
    };
    TemplateModalComponent.prototype.uploadFile = function (event, file) {
        var _this = this;
        var file2 = file.files[0];
        // 2MB
        if (file2.size > 100000) {
            return this.helperService.showInfo('Image File Must be less than 2MB', 'Error', 'error');
        }
        else {
            var reader_1 = new FileReader();
            reader_1.readAsDataURL(file2);
            reader_1.onload = function () {
                // const temp: any = {};
                // temp[keyName] = reader.result;
                // formControl.setValue(temp);
                var img = new Image();
                img.onload = function () {
                    // this.height = img.height;
                    // this.width = img.width;
                    console.log(img.width, img.height);
                };
                img.src = reader_1.result;
                console.log(reader_1.result);
                _this.templateData.logoBase64 = reader_1.result;
            };
        }
    };
    TemplateModalComponent.prototype.onCodeChanged = function (eve, rightPanelView, rightPanelLang) {
        console.log("eve ", eve);
        this.templateData[rightPanelView].translation[rightPanelLang] = eve;
    };
    TemplateModalComponent.prototype.onContentChange = function (eve, rightPanelView) {
        this.templateData[rightPanelView].content = this.templateData[rightPanelView].translation[this.rightPanelLang];
    };
    TemplateModalComponent.prototype.changeRightPanelView = function () {
        if (this.templateData.isMultiLang) {
            this.currentValue = this.templateData[this.rightPanelView].translation[this.rightPanelLang];
        }
        else {
            this.currentValue = this.templateData[this.rightPanelView].content;
        }
    };
    TemplateModalComponent.prototype.onEnableAggregateChange = function () {
        this.templateData.aggregateConfig = this.templateData.aggregateConfig || {};
    };
    return TemplateModalComponent;
}());
export { TemplateModalComponent };
