import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from '@fuse/material.module';
import { VoipCallComponent } from './voip-call/voip-call.component';
import { HtmlEditorModule } from '../../components/html-editor/html-editor.module';
import { CallHistoryComponent } from './call-history/call-history.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  imports: [
    CommonModule,
    FuseSharedModule,
    MaterialModule,
    HtmlEditorModule,
    NgxDatatableModule,

  ],
  declarations: [    
    VoipCallComponent, CallHistoryComponent
  ],
  providers: [
  ],
  exports: [
    VoipCallComponent
  ],
  entryComponents: [
    VoipCallComponent,
    CallHistoryComponent
  ]
})
export class VoipCallModule { }
