import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { IUser } from 'app/main/content/interfaces/office.interface';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { ProfileHelperService } from 'app/main/content/services/profile-helper/profile-helper.service';
import { IProfile } from 'app/main/content/interfaces/profile.interface';
import { MatMenuTrigger, MatDialogRef } from '..';
import { NgForm } from '@angular/forms';
import { FuseUtils } from '@fuse/utils';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-record-checklist',
  templateUrl: './record-checklist.component.html',
  styleUrls: ['./record-checklist.component.scss']
})
export class RecordChecklistComponent implements OnInit {

  @Input() field: any;
  @Input() translateData: any;
  @Input() recId: any;
  @Input() regId: any;

  checklistList = [];
  checklist = '';
  currentUser: IUser;
  loading = false;

  card: any;
  board: any;
  list: any;

  toggleInArray = FuseUtils.toggleInArray;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  
  @ViewChild('checklistMenuTrigger')
  checklistMenu: MatMenuTrigger;

  @ViewChild('newCheckListTitleField')
  newCheckListTitleField;
  checkItems: number;
  checkItemsChecked: number;

  
  constructor(
    private _translationLoader: FuseTranslationLoaderService,
    private _helperService: HelpersService,
    private _profileHelper: ProfileHelperService
  ) { }

  
  ngOnChanges(changes: any) {

    if (changes && changes.translateData && changes.translateData.currentValue) {
      this.translateData = changes.translateData.currentValue;
      this._translationLoader.loadTranslationsV2(this.translateData);    
    }
  }

  ngOnInit() {
    this._translationLoader.loadTranslationsV2(this.translateData);
    this.getLoggedUser();
    this.getCheckList();
  }

  getLoggedUser() {
    this._profileHelper.getLocalProfileData()
    .then((data: IProfile) => {
      this.currentUser = {
        id: data.profileId,
        email: data.email
      };
    }).catch(err => {
      console.info(err);
    });
  }

  getCheckList() {
    this.loading = true;
    this._helperService.getRegisterRecordData(this.regId, this.recId)
    .then((data) => {
      data = (data) || {};
      this.checklistList = data[this.field.label] || [];
      this.loading = false;
    }).catch(err => {
      this.loading = false;
      console.info(err);
    });
  }

  updateChecklist() {
    const tt = {};
    tt[this.field.label] = this.checklistList || [];
    this._helperService.updateRegisterRecord(this.regId, this.recId, tt)
    .then(() => {
      // this._helperService.showInfo('Checklist updated', '', 'success');
    }).catch(err => {
      this._helperService.openSnackBar(err);
    });
  }

  transformSortDes(array: any[], field: string): any[] {
    if (!array || array && array.length === 0) {
    return [];
    }
    array.sort((a: any, b: any) => {
      if (a && b && a[field] > b[field]) {
        return -1;
      } else if (a && b && a[field] < b[field]) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }

   /**
     * Add checklist
     *
     * @param {NgForm} form
     */
    addChecklist(form: NgForm): void
    {
        this.checklistList.push({
            id               : FuseUtils.generateGUID(),
            name             : form.value.checklistTitle,
            checkItemsChecked: 0,
            checkItems       : []
        });

        form.setValue({checklistTitle: ''});
        form.resetForm();
        this.updateCard();
    }

    
    /**
     * Add check item
     *
     * @param {NgForm} form
     * @param checkList
     */
    addCheckItem(form: NgForm, checkList): void
    {
        const checkItemVal = form.value.checkItem;

        if ( !checkItemVal || checkItemVal === '' )
        {
            return;
        }

        const newCheckItem = {
            'name'   : checkItemVal,
            'checked': false
        };

        checkList.checkItems.push(newCheckItem);

        this.updateCheckedCount(checkList);

        form.setValue({checkItem: ''});

        this.updateCard();
    }
    
    /**
     * Remove checklist item
     *
     * @param checkItem
     * @param checklist
     */
    removeChecklistItem(checkItem, checklist): void
    {
        checklist.checkItems.splice(checklist.checkItems.indexOf(checkItem), 1);

        this.updateCheckedCount(checklist);

        this.updateCard();
    }


    
    /**
     * Update checked count
     *
     * @param list
     */
    updateCheckedCount(list): void
    {
        const checkItems = list.checkItems;
        let checkedItems = 0;
        let allCheckedItems = 0;
        let allCheckItems = 0;

        for ( const checkItem of checkItems )
        {
            if ( checkItem.checked )
            {
                checkedItems++;
            }
        }

        list.checkItemsChecked = checkedItems;

        for ( const item of this.checklistList )
        {
            allCheckItems += item.checkItems.length;
            allCheckedItems += item.checkItemsChecked;
        }

        this.checkItems = allCheckItems;
        this.checkItemsChecked = allCheckedItems;

        this.updateCard();
    }

    
    /**
     * Remove checklist
     *
     * @param checklist
     */
    removeChecklist(checklist): void
    {
        this.card.checklists.splice(this.checklistList.indexOf(checklist), 1);

        this.updateCard();
    }


    
    /**
     * Update card
     */
    updateCard(): void
    {
      this.updateChecklist();
    }

    removeComment(checklist: any, index: number) {
      this.checklistList = this.checklistList || [];
      if (this.checklistList[index]) {
        this.checklistList.splice(index, 1);
        this.updateCard();
      }
    }
}
