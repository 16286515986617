import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { TemplatePreviewComponent } from './template-preview.component';

@NgModule({
  declarations: [

  TemplatePreviewComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
  ],
  exports: [
    TemplatePreviewComponent
  ],
  entryComponents: [
    TemplatePreviewComponent
  ]
})
export class TemplatePreviewModule { }
