import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { REGISTERED_DATE_TIME } from 'app/main/content/consts/global.const';
var DatatableCardViewComponent = /** @class */ (function () {
    function DatatableCardViewComponent(changeDetectorRef, _helperService) {
        this.changeDetectorRef = changeDetectorRef;
        this._helperService = _helperService;
        this.sortByField = REGISTERED_DATE_TIME;
        this.sortByOrder = 'desc';
        this.appHardFilter = '';
        this.reportGenerationPopup = new EventEmitter();
        this.openWSAppsSettings = new EventEmitter();
        this.cardOptionChanged = new EventEmitter();
        this.editRecord = new EventEmitter();
        this.reloadDatatable = new EventEmitter();
        this.advanceFilter = false;
        this.inputFilter = {};
        this.mainRows = [];
        this.showMoreOptions = false;
        this.fastSearch = '';
    }
    DatatableCardViewComponent.prototype.ngOnInit = function () {
    };
    DatatableCardViewComponent.prototype.ngOnChanges = function (changes) {
        this.changeDetectorRef.detectChanges();
        this.changeDetectorRef.markForCheck();
        this.sortCardAction();
    };
    DatatableCardViewComponent.prototype.toggleViewToMore = function (recId) {
        if (this.expandedRecordId === recId) {
            this.expandedRecordId = null;
        }
        else {
            this.expandedRecordId = recId;
        }
    };
    DatatableCardViewComponent.prototype.editRecordClicked = function (recId) {
        this.editRecord.emit(recId);
    };
    DatatableCardViewComponent.prototype.cardOptionChangedClicked = function (event, recId) {
        this.cardOptionChanged.emit({ optionData: event, recId: recId });
    };
    DatatableCardViewComponent.prototype.reportPopup = function () {
        this.reportGenerationPopup.emit(this.mqFilterString);
    };
    // updateDataLocally(updatedData: any, recId: string) {
    //   try {
    //     const recIndex = _.findIndex(this.rows, { _id: recId } as any);
    //     if (recIndex >= 0) {
    //       this.rows[recIndex][]
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    DatatableCardViewComponent.prototype.openWSAppsSettingsClicked = function (ele) {
        this.openWSAppsSettings.emit(0);
    };
    DatatableCardViewComponent.prototype.refreshTable = function () {
        this.reloadDatatable.emit(true);
    };
    DatatableCardViewComponent.prototype.sortCardAction = function () {
        this.cardActions = this.cardActions || [];
        this.cardActions = this._helperService.transformSort(this.cardActions, 'sort');
    };
    DatatableCardViewComponent.prototype.toggleAdvanceFilter = function () {
        this.advanceFilter = !this.advanceFilter;
        this.inputFilter = {};
    };
    DatatableCardViewComponent.prototype.filterChanged = function () {
        this.rows = this.rows.slice();
    };
    DatatableCardViewComponent.prototype.downloadPdf = function (recordMainDocument) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var doc, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!recordMainDocument) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._helperService.getDocument(recordMainDocument)];
                    case 1:
                        doc = _a.sent();
                        this._helperService.downloadDocument(doc);
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        console.info(err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return DatatableCardViewComponent;
}());
export { DatatableCardViewComponent };
