import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { FormComponentService } from './form-component.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Location } from '@angular/common';
import { HelpersService } from '../../services/helpers/helpers.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FormRulesService } from '../../services/rules/form-rules.service';
import { STATIC_FIELDS } from '../../consts/staticFields.const';
import { FuseMatchMediaService } from '@fuse/services/match-media.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MediaObserver } from '@angular/flex-layout';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
var FormComponentComponent = /** @class */ (function () {
    function FormComponentComponent(formComponentService, location, snackBar, router, dialog, helperService, translationLoader, _fuseMatchMediaService, _mediaObserver, formRuleService, route) {
        this.formComponentService = formComponentService;
        this.location = location;
        this.snackBar = snackBar;
        this.router = router;
        this.dialog = dialog;
        this.helperService = helperService;
        this.translationLoader = translationLoader;
        this._fuseMatchMediaService = _fuseMatchMediaService;
        this._mediaObserver = _mediaObserver;
        this.formRuleService = formRuleService;
        this.route = route;
        this.isArrayFields = {};
        this.staticProcessStatus = STATIC_FIELDS.processStatus;
        this.isSmallScreen = false;
        this._unsubscribeAll = new Subject();
    }
    FormComponentComponent.prototype.ngOnDestroy = function () {
        console.log("form component in destroyed");
        this.formComponentService.makeInit();
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    FormComponentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._fuseMatchMediaService.onMediaChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function () {
            if (_this._mediaObserver.isActive('lt-md')) {
                _this.isSmallScreen = true;
            }
            else {
                _this.isSmallScreen = false;
            }
            console.log("isSmallScreen", _this.isSmallScreen);
        });
        this.helperService.getTrans('control-template')
            .then(function (data2) {
            _this.translateData = data2;
        }).catch(function (err) {
            console.info(err);
        });
        this.helperService.getTrans('one-recordview')
            .then(function (data2) {
            _this.translationLoader.loadTranslationsV2(data2);
        }).catch(function (err) {
            console.info(err);
        });
        var tabId = localStorage.getItem(this.formComponentService.regId);
        if (tabId) {
            this.currentView = tabId;
            localStorage.removeItem(this.formComponentService.regId);
        }
        else {
            this.currentView = 0;
            // localStorage.setItem("backPath", this.router.url);
            // this.changeTab(0);
        }
    };
    FormComponentComponent.prototype.changeTab = function (i) {
        var prev = this.currentView;
        this.currentView = i;
        localStorage.setItem(this.formComponentService.regId, i);
    };
    FormComponentComponent.prototype.goBack = function () {
        // this.location.back();
    };
    FormComponentComponent.prototype.updateRecord = function (updated) {
        var _this = this;
        this.formComponentService.updateData(this.formComponentService.formData)
            .then(function (data) {
            // success
            _this.snackBar.open('updated', 'done', {
                duration: 2000,
            });
            updated();
        })
            .catch(function (err) {
            // error
            _this.snackBar.open(err, 'done', {
                duration: 2000,
            });
        });
    };
    FormComponentComponent.prototype.checkError = function (e) {
        if (e['error'] && e['label']) {
            if (this.formComponentService.error && !this.formComponentService.error.includes(e['label'])) {
                this.formComponentService.error.push(e['label']);
                this.isArrayFields[e['label']] = true;
            }
        }
        else {
            if (this.formComponentService.error && this.formComponentService.error.includes(e['label'])) {
                this.formComponentService.error.splice(this.formComponentService.error.indexOf(e['label']), 1);
                this.isArrayFields[e['label']] = false;
            }
        }
    };
    FormComponentComponent.prototype.saveFormData = function () {
        var _this = this;
        console.log("closing the popup");
        this.formComponentService.saveFormData(true)
            .catch(function (err) {
            _this.snackBar.open(err || 'Some error occured while updating', 'done', {
                duration: 2000,
            });
        });
    };
    FormComponentComponent.prototype.processUpdateRecord = function (event) {
        var _this = this;
        console.log("button.loadingText 2", event);
        if (event.loadingText) {
            this.loadingText = event.loadingText;
        }
        if (event.goBack) {
            this.formComponentService.closePopup(null);
            this.goBack();
        }
        else if (event && event.isUpdateRecord) {
            this.formComponentService.updateData(this.formComponentService.formData, event.regId, event.recId)
                .then(function (updated) {
                _this.snackBar.open('Updated', 'Done', {
                    duration: 2000,
                });
                // refresh the form and record
                _this.refreshData(event.buttonId, event.type, event.comment);
            }).catch(function (err) {
                console.info(err);
            });
        }
        else {
            this.refreshData(event.buttonId, event.type, event.comment);
        }
    };
    FormComponentComponent.prototype.refreshData = function (buttonId, type, comment) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, data, activityData, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 7, , 8]);
                        // temp
                        // this.formComponentService.closePopup(null);
                        console.log(buttonId);
                        this.formComponentService.loading = true;
                        if (!(type === 'standalone')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.helperService.getRequest("api/reg-process/" + this.formComponentService.formData.instanceId + "/standalone/" + buttonId + "?comment=" + comment)];
                    case 1:
                        data = _a.sent();
                        this.formComponentService.loading = false;
                        this.formComponentService.makeInit();
                        this.processNextInitBehaviour(data);
                        return [3 /*break*/, 6];
                    case 2:
                        if (!(type === 'toAnyStatus')) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.helperService.getRequest("api/reg-process/" + this.formComponentService.formData.instanceId + "/any-status/" + buttonId + "?comment=" + comment)];
                    case 3:
                        data = _a.sent();
                        this.formComponentService.loading = false;
                        this.formComponentService.makeInit();
                        this.processNextInitBehaviour(data);
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.helperService.getProcessActivityDataV2(this.formComponentService.formData.instanceId, buttonId, comment)];
                    case 5:
                        activityData = _a.sent();
                        this.formComponentService.loading = false;
                        this.formComponentService.makeInit();
                        this.processNextInitBehaviour(activityData);
                        _a.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        err_1 = _a.sent();
                        this.errorOccured(err_1);
                        console.info(err_1);
                        this.formComponentService.loading = false;
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    FormComponentComponent.prototype.processNextInitBehaviour = function (activityData) {
        var _this = this;
        if (activityData && activityData.activity && activityData.activity.initStatusBehaviour === 'useSatusForm') {
            if (activityData.activity.formType === 'training') {
                this.formComponentService.loading = false;
                this.router.navigate(["/oneRecordView/training/" + this.formComponentService.regId + "/" + this.formComponentService.recId]);
            }
            else {
                this.formComponentService.formId = activityData['ng5FormId'];
                this.formComponentService.afterGetFormOperation(activityData['ng5FormDoc'])
                    .then(function () {
                    _this.formComponentService.loadDataFromRecData(activityData.recData)
                        .catch(function (err) {
                        console.info(err);
                    });
                    _this.formComponentService.onRecordRefresh.next(true);
                    _this.formComponentService.formId = activityData['ng5FormId'];
                    var tabId = localStorage.getItem(_this.formComponentService.regId);
                    // this.router.navigate(['./' + tabId], {relativeTo: this.route});
                    _this.formComponentService.loading = false;
                }).catch(function (err) {
                    _this.errorOccured(err);
                    console.info(err);
                });
            }
        }
        else {
            this.goBack();
            this.formComponentService.loading = false;
        }
    };
    FormComponentComponent.prototype.errorOccured = function (err) {
        this.snackBar.open(err || 'Error Occured while processing.', 'Okay', {
            duration: 2000,
        });
        this.goBack();
        this.formComponentService.loading = false;
    };
    // processNextInitBehaviour(activityData) {
    //   if (activityData && activityData && activityData.initStatusBehaviour === 'useSatusForm') {
    //     if (activityData.formType === 'training') {
    //       this.formComponentService.loading = false;
    //       this.router.navigate([`/oneRecordView/training/${this.formComponentService.regId}/${this.formComponentService.recId}`]);
    //     } else {
    //       this.formComponentService.formId = activityData['ng5FormId'];
    //       this.formComponentService.getRecordData()
    //       .then(() => {
    //         this.formComponentService.afterGetFormOperation(activityData['ng5FormDoc'])
    //         .catch(err => {
    //           console.info(err);
    //         });
    //         // this.formComponentService.onRecordRefresh.next(true);
    //         this.formComponentService.formId = activityData['ng5FormId'];
    //         this.formComponentService.loading = false;
    //       }).catch(err => {
    //         this.errorOccured(err);
    //         console.info(err);
    //       });
    //     }
    //   } else {
    //     // this.location.back();
    //     // if (this.ngForm && this.ngForm.saveWithoutMove) {
    //     //   console.log('do not move back');
    //     // } else {
    //       // }
    //     this.goBack();
    //     this.formComponentService.loading = false;
    //   }
    // }
    FormComponentComponent.prototype.updateAddress = function (event) {
        if (this.formComponentService && this.formComponentService.formData) {
            this.formComponentService.formData['companyAdress'] = (event && event.companyAdress) || '';
            this.formComponentService.formData['companyCity'] = (event && event.companyCity) || '';
            this.formComponentService.formData['shortName'] = (event && event.shortName) || '';
            this.formComponentService.formData['longName'] = (event && event.longName) || '';
            this.formComponentService.formData['postalcode'] = (event && event.postalcode) || '';
            this.formComponentService.formData['companyName'] = (event && event.companyName) || '';
        }
    };
    FormComponentComponent.prototype.updateParentOnSave = function (event) {
        // this.editNg5Service.formData
        event = event || {};
        // tslint:disable-next-line:forin
        for (var i in event) {
            this.formComponentService.formData[i] = event[i];
        }
    };
    FormComponentComponent.prototype.openParentRec = function () {
        // [routerLink]="['/oneRecordView/record/'+editNg5Service.formData['parentId']]" 
        if (this.formComponentService && this.formComponentService.formData && this.formComponentService.formData['parentId']) {
            // tslint:disable-next-line:max-line-length
            if (this.formComponentService.changedFormData && this.formComponentService.changedFormData['popupOptions'] && this.formComponentService.changedFormData['popupOptions']['dialogRef']) {
                this.formComponentService.changedFormData['popupOptions']['dialogRef'].close(null);
            }
            this.router.navigate(["/oneRecordView/record/" + this.formComponentService.formData['parentId']]);
        }
    };
    FormComponentComponent.prototype.deleteRecordConfirm = function () {
        var _this = this;
        this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });
        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';
        this.confirmDialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.deleteRecord();
            }
            else {
                _this.snackBar.open('You cancelled Deleting Record', 'Okay', {
                    duration: 2000,
                });
            }
            _this.confirmDialogRef = null;
        }, function (err) {
            console.info(err);
        });
    };
    FormComponentComponent.prototype.deleteRecord = function () {
        var _this = this;
        if (this.formComponentService && this.formComponentService.recId && this.formComponentService.regId) {
            this.helperService.deleteRegisterRecordData(this.formComponentService.regId, this.formComponentService.recId)
                .then(function (data) {
                _this.snackBar.open('Record Deleted', 'Done', {
                    duration: 2000,
                });
                _this.formComponentService.closePopup(true);
            }).catch(function (err) {
                console.info(err);
                _this.snackBar.open(err || 'Error occured while getting data', 'Done', {
                    duration: 2000,
                });
            });
        }
    };
    FormComponentComponent.prototype.onFieldValueUpdate = function (eve) {
        console.log("eve data = ", eve);
        this.formComponentService.checkForTabConditions();
        // this.form
        if (eve && eve.field && eve.field.haveRules) {
            // tslint:disable-next-line:max-line-length
            this.formComponentService.form = this.formRuleService.onValueUpdate(this.formComponentService.form, this.formComponentService.ngForm.rules, this.formComponentService.formData, eve.ruleIndex);
            console.log("this.formComponentService.form = ", this.formComponentService.form);
        }
    };
    FormComponentComponent.prototype.updateDataFromCt = function (event) {
        if (event) {
            this.formComponentService.formData = Object.assign(this.formComponentService.formData, event);
            if (event.saveData) {
                this.formComponentService.saveFormData(true);
            }
        }
    };
    FormComponentComponent.prototype.updateFormDataObj = function (event) {
        if (event === void 0) { event = {}; }
        this.formComponentService.formData = tslib_1.__assign({}, this.formComponentService.formData, { event: event });
    };
    FormComponentComponent.prototype.changeSelection = function (event) {
        this.currentView = event.value;
        console.log("event", event);
        this.changeTab(event.value);
    };
    return FormComponentComponent;
}());
export { FormComponentComponent };
