<div *ngIf="filterList && filterList.length">
  <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>settings</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
      <button *ngFor="let i of filterList" (click)="filterIt(i)" mat-menu-item>
          <mat-icon *ngIf="deleteMode">delete</mat-icon>
          <span>{{ i.name }}</span>
      </button>
      <mat-divider></mat-divider>
      <button *ngFor="let i of filterList" (click)="deleteMode=!deleteMode" mat-menu-item>
          <span> Delete Model - {{ (!deleteMode) ? 'On': 'Off' }} </span>
      </button>
  </mat-menu>
</div>