import * as tslib_1 from "tslib";
import { Query } from '@datorama/akita';
import { DataTableStore } from './datatable.store';
var DataTableQuery = /** @class */ (function (_super) {
    tslib_1.__extends(DataTableQuery, _super);
    function DataTableQuery(store) {
        var _this = _super.call(this, store) || this;
        _this.store = store;
        _this.applicationType = _this.select(function (state) { return state.applicationType; });
        _this.regId = _this.select(function (state) { return state.regId; });
        _this.officeId = _this.select(function (state) { return state.officeId; });
        _this.recordList = _this.select(function (state) { return state.recordList; });
        return _this;
    }
    return DataTableQuery;
}(Query));
export { DataTableQuery };
