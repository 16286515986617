import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgSummernoteModule } from '../ng-summernote/ng-summernote.module';
import { NgSummernoteService } from '../ng-summernote/ng-summernote.service';
import { TemplateModalComponent } from './template-modal.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { JsonToHtmlComponent } from './json-to-html/json-to-html.component';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { CodeEditorModule } from '@ngstack/code-editor';


@NgModule({
  imports: [
    CommonModule,
    FuseSharedModule,
    NgSummernoteModule,
    CKEditorModule,
    FusePipesModule,
    CodeEditorModule.forRoot()
  ],
  declarations: [
    TemplateModalComponent,
    JsonToHtmlComponent
  ],
  providers: [
    NgSummernoteService
  ],
  exports: [
    TemplateModalComponent,
    JsonToHtmlComponent
  ],
  entryComponents: [
    TemplateModalComponent
  ]
})
export class TemplateModalModule { }
