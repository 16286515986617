import * as tslib_1 from "tslib";
import { ErrorHandler, } from '@angular/core';
import { ErrorLogService } from './error.handler-helper.service';
var GlobalErrorHandler = /** @class */ (function (_super) {
    tslib_1.__extends(GlobalErrorHandler, _super);
    function GlobalErrorHandler(errorLogService) {
        var _this = _super.call(this) || this;
        _this.errorLogService = errorLogService;
        return _this;
    }
    GlobalErrorHandler.prototype.handleError = function (error) {
        this.errorLogService.logError(error);
    };
    return GlobalErrorHandler;
}(ErrorHandler));
export { GlobalErrorHandler };
