export const SelectFormField = {
    'gdpr-persona-approvalRequest': [
        {
            key: 'emailField',
            value: 'Email Field'
        }
    ],
    'gdpr-persona-appeal-request': [
        {
            key: 'appealType',
            value: 'Appeal Type'
        }
    ],
    'gdpr-persona-infoProcessing': [
        {
            key: 'emailField',
            value: 'Email Field'
        }
    ],
    'calendar-create-event': [
        {
            key: 'start',
            value: 'Start Date Field'
        }, 
        {
            key: 'end',
            value: 'End Date Field'
        }, 
        {
            key: 'info_responsible',
            value: 'Responsible Person'
        }, 
        {
            key: 'info_status',
            value: 'Status (default: ongoing)'
        },
        {
            key: 'info_subject',
            value: 'Subject'
        },
        {
            key: 'allDay',
            value: 'All Day field (optional & default-no)'
        },
        {
            key: 'info_contact',
            value: 'Contact (Optional)'
        },
        {
            key: 'info_des',
            value: 'Description (Optional)'
        },
        {
            key: 'info_finishdate',
            value: 'Finish Date Optional'
        },
        {
            key: 'info_id',
            value: 'Contact Id (Optional)'
        },
        {
            key: 'info_location',
            value: 'Location (Optional)'
        },
        {
            key: 'info_percent',
            value: 'Percentage (Optional)'
        },
        {
            key: 'info_priority',
            value: 'Priority (OptionalP'
        },
        {
            key: 'info_type',
            value: 'Type (Optional)'
        },
    ],
    'cron_task': [
        {
            key: 'start',
            value: 'Start Date Field'
        }, 
        {
            key: 'filterString',
            value: 'Filter String',
            description: 'Unique string to filter out the cron task while removing the cron task'
        }
    ],
    'send_email': [
        {
            key: 'from',
            value: 'From'
        }, 
        {
            key: 'subject',
            value: 'Subject'
        }, 
        {
            key: 'cc',
            value: 'cc'
        }, 
        {
            key: 'body',
            value: 'Body'
        }, 
        {
            key: 'email',
            value: 'Email'
        }, 
        {
            key: 'footer',
            value: 'Footer'
        }, 
    ],
    'check_bank': [
        {
            key: 'nip',
            value: 'NIP'
        }, 
        {
            key: 'bankAccount',
            value: 'Bank Account'
        }
    ],
    'ss_update_event': [
        {
            key: 'providerId',
            value: 'Provider Id'
        }
    ],
    'generate_record_report': [
        {
            key: 'recordId',
            value: 'Record Id'
        }, 
        {
            key: 'templateId',
            value: 'Template Id'
        }, 
        {
            key: 'keyName',
            value: 'Key Name'
        }
    ],
    "shared_service_doc": [
        {
            key: 'templateId',
            value: 'Template Id'
        }
    ],
    'generate-record-report': [
        {
            key: 'recordId',
            value: 'Record Id'
        }, 
        {
            key: 'templateId',
            value: 'Template Id'
        }, 
        {
            key: 'keyName',
            value: 'Key Name'
        }
    ],
    'shared_service_provider_contract': [
        {
            key: 'responsibleOfficeId',
            value: 'responsibleOfficeId'
        }
    ],
    'update_office_service_info': [
        {
            key: 'status',
            value: 'status'
        }
    ],
    'shared_service_client_contract': [
        {
            key: 'responsibleOfficeId',
            value: 'responsibleOfficeId'
        },
        {
            key: 'sourceOfficeId',
            value: 'sourceOfficeId'
        },
        {
            key: 'selectedRegisterId',
            value: 'selectedRegisterId'
        }
    ],
    'update_register_doc': [],
    'discord_message': [
        {
            key: 'role',
            value: 'role'
        },
        {
            key: 'webhookUrl',
            value: 'webhookUrl'
        }
    ],
    'convert_to_number': [
        {
            key: 'decimalSeparator',
            value: 'decimalSeparator'
        },
        {
            key: 'fieldName',
            value: 'fieldName'
        }
    ],
    'create_reg_process_pack': [
        // {
        //     key: 'type',
        //     value: 'type (process, process-template) '
        // },
        // {
        //     key: 'name',
        //     value: 'name'
        // },
        // {
        //     key: 'officeId',
        //     value: 'officeId'
        // },
        // {
        //     key: 'processTemplateId',
        //     value: 'processTemplateId (only in case of type = process-template) '
        // }
    ],
    'invite-user-to-office': [
        {
            key: 'email',
            value: 'Email'
        }
    ],
    'send-message-back-to-parent': [
        {
            key: 'parentMessage',
            value: 'Parent Message'
        }
    ],
    'invite-user-platfrom-office': [
        {
            key: 'officeId',
            value: 'Office Id'
        },
        {
            key: 'officeGroups',
            value : 'office Group'
        }, 
        {
            key: 'email',
            value: 'Email'
        }, 
        {
            key: 'sendEmailTemplateId',
            value: 'Send Email Template Id'
        }
    ],
    'user_invitation': [
        {
            key: 'officeId',
            value: 'Office Id'
        },
        {
            key: 'officeGroups',
            value : 'office Group'
        }, 
        {
            key: 'email',
            value: 'Email'
        }, 
        {
            key: 'sendEmailTemplateId',
            value: 'Send Email Template Id'
        },
        {
            key: 'subject',
            value: 'Subject'
        }
    ],
    'check_user_in_profile': [
        {
            key: 'email',
            value: 'Email Id'
        },
        {
            key: 'emailCheck',
            value : 'Email Check (yes/no)'
        }
    ],
    'sms_one_way': [
        {
            key: 'to',
            value: 'Send To'
        },
        {
            key: 'message',
            value : 'Message'
        }
    ],
    'create_user_profile': [
        {
            key: 'email',
            value: 'Email'
        }
    ],
    'invite_instance_to_profile': [
        {
            key: 'email',
            value: 'Email'
        }
    ],
    'invitation_response_trigger': [
        {
            key : 'invitationResponse',
            value: 'Invitation Response'
        },
        {
            key : 'email',
            value : 'Email'
        }
    ],
    'invite_user_to_workspace': [
        {
            key : 'email',
            value: 'email'
        },
        {
            key : 'InvitedOfficeId',
            value: 'InvitedOfficeId'
        },
        {
            key : 'workSpaces',
            value: 'workSpaces'
        }
    ],
    'invite_user_to_office': [
        {
            key : 'email',
            value: 'email'
        },
        {
            key : 'InvitedOfficeId',
            value: 'InvitedOfficeId'
        }, 
        {
            key : 'groups',
            value: 'groups'
        }
    ],
    'send_notification': [
        {
            key: 'to',
            value: 'Send To (default is sender)'
        },
        {
            key: 'heading',
            value : 'Heading'
        },
        {
            key: 'body',
            value : 'Body'
        }
    ],
    'messaging_jabber': [
        {
            key: 'to',
            value: 'Send To (default is sender)'
        },
        {
            key: 'message',
            value : 'Message'
        }
    ],
    'doller_calc_value': [
        {
            key: 'preFix',
            value: 'Pre-fix on the field'
        },
        {
            key: 'keyName',
            value : 'Key Name to store sum'
        }
    ],
    'parse_email': [
        {
            key: 'parserName',
            value: 'Parse Name (optional)'
        },
        {
            key: 'bankRegId',
            value: 'Bank Reg Id (optional)'
        },
        {
            key: 'htmlField',
            value : 'Html Field (optional)'
        }
    ],
    'find_addressbook_client': [],
    'find_order_record': [
        {
            key: 'orderRegId',
            value: 'orderRegId'
        }
    ],
    'recalculate_balance_by_contact_id': [
        {
            key: 'inOutRegId',
            value: 'inOutRegId'
        }
    ],
    'create_profile_and_add_to_office': [
        
        {
            key: 'workspaceId',
            filter: "",
            description: '[Required]: Choose workspace in which user will be invited.',
            value: 'workspaceId'
        },
        {
            key: 'emailKey',
            filter: "",
            description: '',
            value: 'emailKey'
        },
        {
            key: 'groupName',
            description: '[Required]: Choose Office Group in which user will be invited.',
            value: 'groupName'
        },
        {
            key: 'from',
            value: 'From'
        }, 
        {
            key: 'newProfileMail',
            filter: "",
            description: 'If checked then it will send mail using the provided template.',
            value: 'newProfileMail'
        },
        {
            key: 'newEmailSubject',
            filter: "",
            description: 'New Profile email Subject.',
            value: 'newEmailSubject'
        },
        {
            key: 'newEmailBody',
            filter: "",
            description: 'New Profile email Body.',
            value: 'newEmailBody'
        },
        {
            key: 'forgotPasswordMail',
            filter: "",
            description: 'If checked then it will send mail using the provided template for forgot password.',
            value: 'forgotPasswordMail'
        },
        {
            key: 'forgotEmailSubject',
            filter: "",
            description: 'Forgot Password email Subject.',
            value: 'forgotEmailSubject'
        },
        {
            key: 'forgotEmailBody',
            filter: "",
            description: 'Forgot Password email Body.',
            value: 'forgotEmailBody'
        },
    ],
    'check_org_in_profile': [
        
        {
            key: 'path',
            filter: "",
            description: '[Required]: Choose workspace in which user will be invited.',
            value: 'path'
        },
        {
            key: 'value',
            filter: "",
            description: '',
            value: 'value'
        },
        {
            key: 'profileId',
            description: '[Required]: Choose Office Group in which user will be invited.',
            value: 'profileId'
        }
    ],
    'filter_by_mq_filter_and_update': [
        
        {
            key: 'keyName',
            filter: "",
            description: '',
            value: 'keyName'
        },
        {
            key: 'mqFilter',
            filter: "",
            description: '',
            value: 'mqFilter'
        },
        {
            key: 'sourceRegId',
            description: '',
            value: 'sourceRegId'
        }
    ],
    'update_reg_rec_with_filter': [
        
        {
            key: 'sourceRegId',
            filter: "",
            description: '',
            value: 'sourceRegId'
        },
        {
            key: 'mqFilter',
            filter: "",
            description: '',
            value: 'mqFilter'
        },
    ],
    'context_html_report': [
        
        {
            key: 'sourceRegId',
            filter: "",
            description: '',
            value: 'sourceRegId'
        },
        {
            key: 'mqFilter',
            filter: "",
            description: '',
            value: 'mqFilter'
        },
        {
            key: 'htmlDesc',
            filter: "",
            description: '',
            value: 'htmlDesc'
        },
        {
            key: 'keyToStore',
            filter: "",
            description: '',
            value: 'keyToStore'
        },
    ],
    'limitation_html': [
        
        {
            key: 'sourceRegId',
            filter: "",
            description: '',
            value: 'sourceRegId'
        },
        {
            key: 'mqFilter',
            filter: "",
            description: '',
            value: 'mqFilter'
        },
        {
            key: 'htmlDesc',
            filter: "",
            description: '',
            value: 'htmlDesc'
        },
        {
            key: 'keyToStore',
            filter: "",
            description: '',
            value: 'keyToStore'
        },
    ],
    'init_create_order_route_based_on_locality': [
        
        {
            key: 'routeRegId',
            filter: "",
            description: '',
            value: 'routeRegId'
        },
        {
            key: 'mqFilter',
            filter: "",
            description: '',
            value: 'mqFilter'
        },
        {
            key: 'sensorRegId',
            description: '',
            value: 'sensorRegId'
        },
        {
            key: 'routeToCreate',
            description: '',
            value: 'routeToCreate'
        },
    ],
    'add_new_order_to_route_based_on_locality': [
        {
            key: 'mqFilter',
            filter: "",
            description: '',
            value: 'mqFilter'
        },
        {
            key: 'sensorRegId',
            description: '',
            value: 'sensorRegId'
        }
    ],
    'create_order_route_based_on_locality': [
        
        {
            key: 'mqFilter',
            filter: "",
            description: '',
            value: 'mqFilter'
        },
        {
            key: 'sensorRegId',
            description: '',
            value: 'sensorRegId'
        },
        {
            key: 'orderRegId',
            description: '',
            value: 'orderRegId'
        }
    ],
    'crud_email_to_workspace': [
        {
            key: 'emailKey',
            filter: "",
            description: '',
            value: 'emailKey'
        },
        {
            key: 'workspaceIds',
            filter: "",
            description: '[Required]: Choose workspace in which user will be invited.',
            value: 'workspaceIds'
        },
        {
            key: 'operation',
            value: 'operation',
            defaultValue: "add",
            optionType: "select",
            options: [
                {
                    title: "Add",
                    value: "add"
                },
                {
                    title: "Remove",
                    value: "remove"
                },
            ]
        }
    ],
    'make_open_ai_request': [
        {
            key: 'prompt',
            filter: "",
            description: '[Required]',
            value: 'prompt'
        },
        {
            key: 'model',
            filter: "",
            description: '[Optional] - default is gpt-4-32k',
            value: 'model'
        },
        {
            key: 'apiKey',
            filter: "",
            description: '[Optional]',
            value: 'apiKey'
        },
        {
            key: 'maxToken',
            filter: "",
            description: '[Optional] - default is 1500',
            value: 'maxToken'
        },
        {
            key: 'args',
            filter: "",
            description: '[Optional] - Additional object if needed',
            value: 'args'
        },
       
    ],
    'crud_email_to_group': [
        
       
        {
            key: 'emailKey',
            filter: "",
            description: '',
            value: 'emailKey'
        },
        {
            key: 'groupNames',
            description: '[Required]: Choose Office Group in which user will be invited.',
            value: 'groupNames'
        },
        {
            key: 'operation',
            value: 'operation',
            defaultValue: "add",
            optionType: "select",
            options: [
                {
                    title: "Add",
                    value: "add"
                },
                {
                    title: "Remove",
                    value: "remove"
                },
            ]
        }
    ],
    'register-record-report': [
        {
            key: 'keyName',
            value: 'Key name to save in'
        }
    ],
    'install_office_plugin': [
        {
            key: 'officeId',
            value: 'Office Id'
        },
        {
            key: 'pluginName',
            value: 'Plugin Name'
        }
    ],
    'create_personal_office': [
        {
            key: 'officeName',
            value: 'Office Name'
        },
        {
            key: 'showWorkspace',
            value: 'Show Workspace'
        },
        {
            key: 'userEmail',
            value: 'User Email to give access'
        }
    ],
    'uninstall_office_plugin': [
        {
            key: 'officeId',
            value: 'Office Id'
        },
        {
            key: 'pluginName',
            value: 'Plugin Name'
        }
    ],
    'register_rec_validation': [
        {
            key: 'destinationField',
            description: `This is the field keyName(label) in the destination register to match with.`,
            value: 'Destination Field Keyname to check with'
        },
        {
            key: 'sourceRecordField',
            description: `This is the field keyName(label) in the source register to get Value.`,            
            value: 'Value to check with'
        },
        {
            key: 'keyName',
            description: `This is the field keyName(label) in the source register to store the result (true or false)`,
            value: 'Key Name to store the result (true/false) (optional)'
        },
        {
            key: 'recordCount',
            description: `This is the field recordCount(label) in the source register to store the count of matching records`,
            value: 'Key Name to store the count of record (optional)'
        }
    ],
    'register_record_report': [
        {
            key: 'keyName',
            value: 'Key name to save in'
        }
    ],
    'calculate_hrs_eco_trans': [
        {
            key: 'employerRegId',
            value: 'employerRegId'
        },
        {
            key: 'limitationRegId',
            value: 'limitationRegId'
        }
    ],
    'update_register_record': [
        {
            key: 'regType',
            value: 'regType'
        }
    ],
    'update_profile_path': [
        {
            key: 'path',
            value: 'path'
        },
        {
            key: 'value',
            value: 'value'
        },
        {
            key: 'profileId',
            value: 'profileId'
        },
        {
            key: 'operation',
            value: 'operation',
            defaultValue: "add",
            optionType: "select",
            options: [
                {
                    title: "Add",
                    value: "add"
                },
                {
                    title: "Remove",
                    value: "remove"
                },
            ]
        }

    ],
    'update_register_rec_math_operation': [
        {
            key: 'regType',
            value: 'regType',
            optionType: "select",
            defaultValue: "regRecord",
            options: [
                {
                    title: "Register Record",
                    value: "regRecord"
                },
                {
                    title: "Address Book",
                    value: "office_addressbook"
                },
            ]
        },
        {
            key: 'fieldKey',
            value: 'fieldKey'
        },
        {
            key: 'value',
            value: 'value'
        },
        {
            key: 'operation',
            value: 'operation',
            defaultValue: "+",
            optionType: "select",
            options: [
                {
                    title: "+",
                    value: "+"
                },
                {
                    title: "-",
                    value: "-"
                },
                {
                    title: "*",
                    value: "*"
                },
                {
                    title: "/",
                    value: "/"
                },
                {
                    title: "%",
                    value: "%"
                },
                {
                    title: "Date Diff",
                    value: "dateDiff"
                },
            ]
        },
        {
            key: 'destRecId',
            value: 'destRecId'
        },
    ],
    'update_register_by_range_check': [
        
        {
            key: 'fieldKey',
            value: 'fieldKey'
        },
        {
            key: 'destKey',
            value: 'destKey'
        }
    ],
    'update_register_rec_path': [
        {
            key: 'regType',
            value: 'regType',
            optionType: "select",
            defaultValue: "regRecord",
            options: [
                {
                    title: "Register Record",
                    value: "regRecord"
                },
                {
                    title: "Address Book",
                    value: "office_addressbook"
                },
            ]
        },
        {
            key: 'path',
            value: 'path'
        },
        {
            key: 'value',
            value: 'value'
        },
        {
            key: 'operation',
            value: 'operation',
            defaultValue: "add",
            optionType: "select",
            options: [
                {
                    title: "Add",
                    value: "add"
                },
                {
                    title: "Remove",
                    value: "remove"
                },
            ]
        },
        {
            key: 'destRecId',
            value: 'destRecId'
        },
    ],
    'convert_address_to_lat_long': [
        {
            key: 'regType',
            value: 'regType',
            optionType: "select",
            defaultValue: "regRecord",
            options: [
                {
                    title: "Register Record",
                    value: "regRecord"
                },
                {
                    title: "Address Book",
                    value: "office_addressbook"
                },
            ]
        },
        {
            key: 'apiType',
            value: 'apiType',
            optionType: "select",
            defaultValue: "openstreetmap",
            options: [
                {
                    title: "openstreetmapopenstreetmap",
                    value: "openstreetmap"
                },
                {
                    title: "Google Api",
                    value: "google"
                },
            ]
        },
        {
            key: 'fieldKey',
            value: 'fieldKey'
        },
        {
            key: 'destRecId',
            value: 'destRecId'
        },
    ],
    'update_office_path': [
        {
            key: 'path',
            value: 'path'
        },
        {
            key: 'value',
            value: 'value'
        }
    ],
    'jump_to_another_activity': [
        {
            key: 'activity',
            description: `this is the Jump Activity`,
            value: 'Jump Activity',
            optionType: 'activity'
        }
    ],
    'move_position_child_records': [
        // {
        //     key: 'fromActivity',
        //     description: `From activity to filter out the record`,
        //     value: 'From Activity',
        //     optionType: 'activity'
        // },
        // {
        //     key: 'toActivity',
        //     description: `To activity `,
        //     value: 'To Activity',
        //     optionType: 'activity'
        // },
        // {
        //     key: 'positionRegId',
        //     description: `Position Used to move the child record`,
        //     value: 'Position To Use',
        //     optionType: 'position'
        // }
    ],
    'recalculate_in_and_out_to_meet_saldo': [
        {
            key: 'inOutRegId',
            description: 'inOutRegId',
            value: 'inOutRegId'
        }
    ],
    'cron_task_for_actions': [
        {
            key: 'timeDiff',
            description: 'Time difference value (Ex. 5)',
            value: 'Time difference value'
        },
        {
            key: 'task',
            description: 'Select Task',
            value: 'Select task'
        },
        {
            key: 'filterString',
            value: 'Filter String',
            description: 'Unique string to filter out the cron task while removing the cron task'
        }
    ],    
    'remove_cron_task': [
        {
            key: 'filterString',
            description: 'Unique string to filter out the cron task while removing the cron task',
            value: 'Filter String',
            optionType: 'defaultText'
        }
    ],    
    'create_branch': [
        {
            key: 'projectId',
            description: 'Project Id of the project that you want to make merge request to.',
            value: 'Project Id'
        },
        {
            key: 'source_branch',
            description: 'Source branch or ref branch from which you want to create branch',
            value: 'Source Branch'
        },
        {
            key: 'task_type',
            description: 'Task Type',
            value: 'Task Type'
        },
        {
            key: 'human_readable_text',
            description: 'Human Readable Text.',
            value: 'Branch name to create'
        }
    ],    
    'create_merge_request': [
        {
            key: 'title',
            description: 'Title of the merge to make',
            value: 'Merge title'
        },
        {
            key: 'projectId',
            description: 'Project Id of the project that you want to make merge request to.',
            value: 'Project Id'
        },
        {
            key: 'source_branch',
            description: 'Source branch from which you want to make merge request',
            value: 'Source Branch'
        },
        {
            key: 'target_branch',
            description: 'Branch to which you want to make merge to.',
            value: 'Target Branch'
        }
    ],
    'calendar_create_event': [
        {
            key: 'start',
            value: 'Start Date Field'
        }, 
        {
            key: 'end',
            value: 'End Date Field'
        }, 
        {
            key: 'info_responsible',
            value: 'Responsible Person'
        }, 
        {
            key: 'info_status',
            value: 'Status (default: ongoing)'
        },
        {
            key: 'info_subject',
            value: 'Subject'
        },
        {
            key: 'allDay',
            value: 'All Day field (optional & default-no)'
        },
        {
            key: 'info_contact',
            value: 'Contact (Optional)'
        },
        {
            key: 'info_des',
            value: 'Description (Optional)'
        },
        {
            key: 'info_finishdate',
            value: 'Finish Date Optional'
        },
        {
            key: 'info_id',
            value: 'Contact Id (Optional)'
        },
        {
            key: 'info_location',
            value: 'Location (Optional)'
        },
        {
            key: 'info_percent',
            value: 'Percentage (Optional)'
        },
        {
            key: 'info_priority',
            value: 'Priority (OptionalP'
        },
        {
            key: 'info_type',
            value: 'Type (Optional)'
        },
    ],
};

export const SelectionType = [
    {
        key: 'formField',
        value: 'Form Field'
    },
    {
        key: 'instanceRecordField',
        value: 'Instance record field'
    },
    {
        key: 'defaultDate',
        value: 'Default (Date)'
    },
    {
        key: 'defaultText',
        value: 'Default (Text)'
    },
    {
        key: 'defaultTextArea',
        value: 'Default (TextArea)'
    },
    {
        key: 'defaultEditor',
        value: 'Default (Editor)'
    },
    {
        key: 'now',
        value: 'Now'
    },
    {
        key: 'activity',
        value: 'Activity List'
    },
    {
        key: 'position',
        value: 'PositionFields'
    },
    {
        key: 'officeServiceStatus',
        value: 'OfficeServiceStatus'
    },
    {
        key: 'officeServiceStatus',
        value: 'OfficeServiceStatus'
    },
    {
        key: 'select',
        value: 'select'
    },
    {
        key: 'task',
        value: 'tasks List'
    },
    {
        key: 'checkbox',
        value: 'Checkbox'
    }
];

