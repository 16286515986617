import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit, OnChanges {

  @Input() translateData: any;
  @Input() field;
@Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() model: string; 
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onError: EventEmitter<Object> = new EventEmitter();
  
    error = false;
    errorMessage = '';
    constructor(
      private translationLoader: FuseTranslationLoaderService,
    ) { }
  
    
  ngOnChanges(changes: any) {

    if (changes && changes.translateData && changes.translateData.currentValue) {
      this.translateData = changes.translateData.currentValue;
      this.translationLoader.loadTranslationsV2(this.translateData);    
    }
  }

    ngOnInit() {
      this.translationLoader.loadTranslationsV2(this.translateData);
      if (this.field['required'] && (!this.model || this.model && this.model.length === 0)) {
        this.error = true;
        this.errorMessage = 'ct.field_is_required';
        if (!this.model) {
          this.onError.emit({
            error: true,
            label: this.field['label']
          });
        }
      }
    }
  
    onKeyPress() {
      this.modelChange.next(this.model);
      this.error = this.isValidMail(this.model);
      if (this.error) {
        this.errorMessage = 'ct.not_valid_email';
        if (this.field['required'] && this.model && this.model.length === 0) {
          this.errorMessage = 'ct.field_is_required';
        } else if (this.model && this.model.length === 0) {
          this.errorMessage = '';
          this.error = false;
        }
      } else {
        this.errorMessage = '';
      }
      this.onError.emit({
        error: this.error,
        label: this.field['label']
      });
      if (this.field.haveRules) {
        this.onFieldValueUpdated.emit({
          label: this.field.label,
          value: this.model,
          ruleIndex: this.field.ruleIndex,
          field: this.field
        });
      }
    }
  
    isValidMail(str) { 
      let mailPattern;
      if (this.field['validate_reg_exp'] && this.field['validate_reg_exp'].length > 0) {
        mailPattern = new RegExp('^[a-zA-Z0-9._-]+@' + this.field['validate_reg_exp'] + '$');
      } else {
        // tslint:disable-next-line:max-line-length
        mailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      }
      return !mailPattern.test(String(str).toLowerCase()); 
    }
  
    
  pasteIt(eve) {
    eve.preventDefault();
    this.model = this.model || '';
    this.model += (eve && eve.clipboardData && eve.clipboardData.getData('Text')) || '';
    this.onKeyPress();
  }
}
