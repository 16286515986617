import { FormListComponent } from './form-list.component';
import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CommonModule } from '@angular/common';

@NgModule({
    imports     : [
        CommonModule,
        FuseSharedModule,
        NgxDatatableModule

    ],
    declarations: [
        FormListComponent
    ],
    exports: [
        FormListComponent
    ]
})
export class FormListModule
{
}
