import { fuseAnimations } from '@fuse/animations';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { HelpersService } from '../../services/helpers/helpers.service';
import { WsAppsSettings } from './ws-apps-settings-json/ws-apps-settings.cont';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-ws-apps-settings',
  templateUrl: './ws-apps-settings.component.html',
  styleUrls: ['./ws-apps-settings.component.scss'],
  animations: fuseAnimations

})
export class WsAppsSettingsComponent implements OnInit {

  onValueChanged: Subject<any> = new Subject<any>();
  appData: any;
  settingsModel: { 'type': string; 'settings': any[]; }[];

  constructor(
    public dialogRef: MatDialogRef<WsAppsSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translationLoader: FuseTranslationLoaderService,
    private helperService: HelpersService,
  ) { 
    this.helperService.getTrans('workspace-apps-settings')
      .then((data2) => {
        this.translationLoader.loadTranslationsV2(data2);
      }).catch(err => {
        console.info(err);
      });
  }

  ngOnInit() {
    this.appData = this.data.appData || {};
    const moreInfo = {};
    moreInfo['dialogRef'] = this.dialogRef;
    this.appData.cardActions = this.appData.cardActions || [];
    moreInfo['defaultCardSortField'] = this.appData.cardActions.map((action: any) => ({ title: (action && action.name), value: (action && action.keyName)}));
    moreInfo['workspaceSlug'] = this.appData.workspaceSlug;
    const temp: any = {};
    temp['appRegId'] = this.appData.regId;
    temp['appList'] = this.data.appList;
    temp['workspaceSlug'] = this.appData.workspaceSlug;
    moreInfo['installApp'] = JSON.parse(JSON.stringify(temp));
    moreInfo['updateTemplate'] = JSON.parse(JSON.stringify(temp));
    moreInfo['dtTableColumns'] = { formId: this.appData.formId };
    if (this.data.triggerUpdate) {
      moreInfo['triggerSub'] = this.onValueChanged;
    }
    this.onValueChanged.subscribe((data) => {
      if (data) {
        this.appData = Object.assign(this.appData, data);
      }
    });
    this.settingsModel = new WsAppsSettings(`api/offices/work-space/${this.appData.workspaceSlug}/step/${this.appData.stepNumber}`, this.appData, moreInfo).settingModel;
  }

  closePopup(data: any) {
    this.onValueChanged.unsubscribe();
    this.dialogRef.close(data);
  }

  saveData() {
    this.closePopup(this.appData);
  }
}
