<div style="background: #f6f6f6;" class="m-0">
  <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <button class="mr-12" (click)="dialogRef.close(null)" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
    <span class="title dialog-title">
      <p>{{ 'dt_table_column_settings.data_table_column_settings' | translate }} 
      </p>
    </span>
    <span fxFlex></span>
    <button class="mr-12" (click)="saveData()" mat-icon-button>
      <mat-icon>check</mat-icon>
    </button>
  </div>
</div>
<div class="p-12">
  <ng-container >
    <div fxLayoutAlign="start center" class="my-8" fxLayout="row">
      <div fxFlex="25">
          <mat-form-field class="happ-small-form-field-outline w-95-p" floatLabel="always" appearance="outline">
            <mat-label> {{ 'dt_table_column_settings.title' | translate }} </mat-label>
            <input [(ngModel)]="currentRec['title']" matInput placeholder="">
          </mat-form-field>
      </div>
      <div fxFlex="25">
          <mat-form-field class="happ-small-form-field-outline w-95-p" floatLabel="always" appearance="outline">
            <mat-label> {{ 'dt_table_column_settings.label' | translate }} </mat-label>
            <input [(ngModel)]="currentRec['label']" matInput placeholder="">
          </mat-form-field>
      </div>
      <div fxFlex="25">
        <mat-form-field class="happ-small-form-field-outline w-95-p" floatLabel="always" appearance="outline">
          <mat-label> {{ 'dt_table_column_settings.sort' | translate }} </mat-label>
          <input type="number" [(ngModel)]="currentRec['sort']" matInput placeholder="">
        </mat-form-field>
      </div>
      <div fxFlex="25">
        <mat-form-field class="happ-small-form-field-outline w-95-p" floatLabel="always" appearance="outline" >
          <mat-label>{{ 'dt_table_column_settings.align_default_left' | translate }} </mat-label>
          <mat-select [(ngModel)]="currentRec['columnAlign']">
              <mat-option value="left">{{ 'dt_table_column_settings.left' | translate }}</mat-option>
              <mat-option value="center">{{ 'dt_table_column_settings.center' | translate }}</mat-option>
              <mat-option value="right">{{ 'dt_table_column_settings.right' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <button *ngIf="editIndex=== null" style="margin: auto 2px;" matTooltip="{{ 'dt_table_column_settings.add' | translate }}" (click)="addNew()" mat-icon-button>
          <mat-icon style="color: black">add_circle</mat-icon>
      </button>
      <button *ngIf="editIndex!==null"  (click)="addNew()" mat-icon-button>
        <mat-icon>check</mat-icon>
      </button>
      <button *ngIf="editIndex!==null"  (click)="editIndex=null" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="happ-heading" fxLayout="row">
        <div fxFlex="25">
            {{ 'dt_table_column_settings.name' | translate }}
        </div>
        <div fxFlex="25">
            {{ 'dt_table_column_settings.key_name' | translate }}
        </div>
     
        <div fxFlex="25">
            {{ 'dt_table_column_settings.sort' | translate }}
        </div>
        <div fxFlex="25">
            {{ 'dt_table_column_settings.align' | translate }}
        </div>
      </div>
    <div class="happ-content" fxLayout="row" fxLayoutAlign="center center" *ngFor="let field of data.dtTableColumns; let i=index">
        <button style="margin: auto 2px;" matTooltip="{{ 'dt_table_column_settings.delete' | translate }}" (click)="data.dtTableColumns.splice(i, 1)" mat-icon-button>
            <mat-icon style="color: black">delete</mat-icon>
        </button>
        <div fxFlex="25">
            {{ field.title }}
        </div>
        <div fxFlex="25">
            {{ field.label }}
        </div>
        <div fxFlex="25">
            {{ field.sort }}
        </div>
        <div fxFlex="25">
            {{ field.columnAlign }}
        </div>
        <button style="margin: auto 2px;" matTooltip="{{ 'dt_table_column_settings.edit' | translate }}" (click)="currentRec=field;editIndex=i" mat-icon-button>
            <mat-icon style="color: black">edit</mat-icon>
        </button>
      </div>
  </ng-container>
</div>