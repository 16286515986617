import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-update-success',
  templateUrl: './update-success.component.html',
  styleUrls: ['./update-success.component.scss']
})
export class UpdateSuccessComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UpdateSuccessComponent>,
  ) { }

  ngOnInit() {
  }

}
