<div fxLayout="column" *ngFor="let filterOption of superFilter">
    <div fxLayout="row" class="my-8" *ngIf="filterOption && filterOption.options && filterOption.options.length">
        <p class="m-0 mr-12 w-100 font-weight-600 font-size-14"
            style="margin: auto 12px auto 0px !important"> {{ filterOption['title'] }} </p>
        <div fxLayout="row wrap">
            <button class="m-4 happ-small-button happ-button-list happ-button font-size-14"
                *ngFor="let buttonInfo of filterOption.options"
                [color]="(valueFilter[filterOption['label']] && valueFilter[filterOption['label']].$in && ((buttonInfo.title && valueFilter[filterOption['label']].$in.includes(buttonInfo.title)) || (buttonInfo.value && valueFilter[filterOption['label']].$in.includes(buttonInfo.value)))) ? 'primary' : ''"
                mat-raised-button (click)="superFilterButtonClicked(filterOption, buttonInfo)">
                {{ buttonInfo.title }}
            </button>
            <button class="m-4 happ-small-button happ-button-list happ-button font-size-14"
                [color]="(valueFilter[filterOption['label']] && valueFilter[filterOption['label']].$in && (valueFilter[filterOption['label']].$in.includes(null))) ? 'primary' : ''"
                mat-raised-button (click)="superFilterButtonClicked(filterOption, {}, true)">
                {{ 'dttable.empty' | translate }}
            </button>
        </div>
    </div>
</div>