import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-card-actions-list',
  templateUrl: './card-actions-list.component.html',
  styleUrls: ['./card-actions-list.component.scss']
})
export class CardActionsListComponent implements OnInit {
  
  @Input() cardActionList: any[];
  @Output() deleteAction: EventEmitter<any> = new EventEmitter();
  @Output() editAction: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.cardActionList = this.cardActionList || [];
  }

  delete(i: any) {
    this.deleteAction.emit(i);
  }

  edit(actionData: any, i: number) {
    this.editAction.emit({ actionData, index: i });
  }
}
