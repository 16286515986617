import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ChangeDetectorRef, OnChanges } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FieldOptionUpdateDialogComponent } from 'app/main/content/components/field-option-update-dialog/field-option-update-dialog.component';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';

@Component({
  selector: 'app-button-list-with-tag',
  templateUrl: './button-list-with-tag.component.html',
  styleUrls: ['./button-list-with-tag.component.scss']
})
export class ButtonListWithTagComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() field;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() model;
  @Input() regId;
  @Input() recId;
  @Input() translateData: any;
  @Output() modelChange: EventEmitter<Object> = new EventEmitter();
  @Input() hideTitle: boolean;
  @Input() builder;
  @Input() formData: any;
  @Output() valChange: EventEmitter<Object> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onError: EventEmitter<Object> = new EventEmitter();
  
  error = false;
  loading = false;
  errorMessage = '';
  errorMes = false;
  modelButton;
  inputValue = "abc";
  items: any = [];
  officeTags: any;
  filterValue: any;
  constructor(
    public snackBar: MatSnackBar,
    private translationLoader: FuseTranslationLoaderService,
    public dialog: MatDialog,
    private _helperService: HelpersService,
    private changeDetectorRef: ChangeDetectorRef,
    ) {
      this.model = {};
    }
    
  ngOnChanges(changes: any) {

    if (changes && changes.translateData && changes.translateData.currentValue) {
      this.translateData = changes.translateData.currentValue;
      this.translationLoader.loadTranslationsV2(this.translateData);    
    }
  }
  
    editOptions() {
      const dialogRef = this.dialog.open(FieldOptionUpdateDialogComponent, {
        width: '850px',
        disableClose: true,
        height: 'max-content',
        data: { 
          fieldLabel: this.field.label, 
          formData: this.formData, 
          id: this.field.id, fieldOptions: JSON.parse(JSON.stringify(this.field.options || [])) || [], field: this.field },
        panelClass: 'happ-form-builder-dialog'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.field.options = result;
        }
      }); 
    }
    

  getOptions() {
    if (this.field.regId && this.field.optionTitle) {
      const cols = [
        {
          prop: this.field.optionTitle,
          title: this.field.optionTitle
        },
        {
          prop: '_id',
          title: '_id'
        }
      ];
      if (this.field.optionValue) {
        cols.push(this.field.optionValue);
      }

      this.loading = true;
      this._helperService.getDataForOptionBasedField(this.field.regId, cols, this.field.hardFilter, this.field.sortBy)
      .then((data) => {
        const tt = [];
        data = data || [];
        data.forEach((ele) => {
          if (this.field.optionValue) {
            tt.push({
              title: ele[this.field.optionTitle],
              value: ele[this.field.optionValue]
            });
          } else {
            tt.push({
              title: ele[this.field.optionTitle]
            });
          }
        });
        this.field.options = tt || [];
        this.loading = false;
        this.changeDetectorRef.detectChanges();

      }).catch(err => {
        console.info(err);
        this.loading = false;
        this.changeDetectorRef.detectChanges();

      });
    }
    
  }
  
  onChange(name) {
    this.errorMes = false;
    if (name) {
      this.modelButton[name] = !this.modelButton[name];
      if (this.model) {
        const tempArr = this.model.split(',').map(ele => ele.trim()) || [];
        if (tempArr.includes(name)) {
          this.errorMes = true;
        } else {
          this.model += ', ' + name;
        }
      } else {
        this.model = name;
      }
    } 
    this.valChange.emit({
      modelButton: this.modelButton
    });
    // this.modelButton = this.modelButton || {};
    // this.model = '';
    // Object.keys(this.modelButton).forEach(ele => {
    //   if (this.modelButton[ele]) {
    //     this.model += ele + ', ' ;
    //   }
    // });
    this.modelChange.next(this.model);

    if (this.field['required'] && (this.modelButton === null || this.modelButton === undefined || this.modelButton === '')) {
      this.error = true;
      this.errorMessage = 'ct.field_is_required';
      if (!this.modelButton) {
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
      }
    } else {
      this.errorMessage = '';
      this.error = false;
      this.onError.emit({
        error: false,
        label: this.field['label']
      });
    }
    this.onFieldValueUpdated.emit({
        label: this.field.label,
        value: this.model,
        ruleIndex: this.field.ruleIndex,
        field: this.field
      });
  }
  
  ngAfterViewInit() {
    const temp = {};
    this.field['options'].forEach(element => {
      temp[element.value || element.title] = false;
    });
    this.modelButton = (this.modelButton) ? this.modelButton : temp;  

    if (this.field['preselectedValue'] && this.modelButton && this.modelButton.length === 0){
      this.modelButton = this.field['preselectedValue'];
      this.onChange(null);
    }

    if (this.model && this.model.length) {
      const tempArr = this.model.split(',') || [];
      tempArr.forEach(ele => {
        if (ele) {
          ele = ele.trim();
          this.modelButton[ele] = true;
        }
      });
    } else {
      this.model = '';
    }

  }

  ngOnInit() {
    if (this.field.userRegister) {
      this.getOptions();
    }
    this.getOfficeTags();
    this.model = this.model || '';
    this.items = this.model.split(",").filter(ele => ele);
    this.translationLoader.loadTranslationsV2(this.translateData);
    if (this.field['required'] && (this.modelButton === null || this.modelButton === undefined || this.modelButton === '')) {
      this.error = true;
      this.errorMessage = 'ct.field_is_required';
      if (!this.modelButton) {
        this.onError.emit({
          error: true,
          label: this.field['label']
        });
      }
    }
  }

  onKeyPress() {
    this.errorMes = false;
    if (this.field['required'] && (!this.model || this.model && this.model.length === 0)) {
      this.errorMessage = 'ct.field_is_required';
      this.error = true;
      this.onError.emit({
        error: true,
        label: this.field['label']
      });
    } else {
      this.errorMessage = '';
      this.error = false;
      this.onError.emit({
        error: false,
        label: this.field['label']
      });
    }
    this.modelChange.next(this.model);
  }

  inputValueChanged(temp, val) {
    if (temp.key === ',' || temp.key === ' ') {
      if (val) {
        val = val.substr(0, (val.length - 1));
      }
      this.addItem(temp, val);
    }
    this.filterValue = this._filterStates(val); 
  }

  async addItem(eve, val) {
    val = (eve && eve.option && eve.option.value) || val || '';
    if (this.items.includes(val) || !val.trim()) {
      return;
    }
    this.items.push(val);
    this.model = this.items.join(",");
    this.modelChange.next(this.model);
    this.inputValue = '';
    console.log("removing the data");
    await this._helperService.postRequest("api/record-tag", { key: this.field.label, tag: val, operation: "add", regId: this.regId, updateKey: this.field.label, updateValue: this.model, recId: this.recId })
  }

  async removeItem(ele) {
    if (this.items.includes(ele)) {
      this.items.splice(this.items.indexOf(ele), 1);
      this.model = this.items.join(",");
      this.modelChange.next(this.model);
      await this._helperService.postRequest("api/record-tag", { key: this.field.label, tag: ele, operation: "delete", regId: this.regId, updateKey: this.field.label, updateValue: this.model, recId: this.recId })

    }
  }

  
  private _filterStates(value: string) {
    const filterValue = (value && value.toLowerCase());
    return this.officeTags.filter(state => state.tag.toLowerCase().indexOf(filterValue) === 0);
  }

  async getOfficeTags() {
    try {
      this.officeTags = await this._helperService.getRequest(`api/record-tag?key=${this.field.label}`);
      this.filterValue = JSON.parse(JSON.stringify(this.officeTags));
    } catch (error) {
      console.log("Err = ", error);
    }
  }

}
