import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { SelectTemplate } from '../../global-component/drag-and-drop/select-template/select-template.component';
import { HelpersService } from '../../services/helpers/helpers.service';
import { TemplateModalComponent } from '../../global-component/template-modal/template-modal.component';

@Component({
  selector: 'app-ws-template-selection',
  templateUrl: './ws-template-selection.component.html',
  styleUrls: ['./ws-template-selection.component.scss']
})
export class WsTemplateSelectionComponent implements OnInit {

  templateList = [];

  constructor(
    public dialogRef: MatDialogRef<WsTemplateSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private helperService: HelpersService
  ) { }

  ngOnInit() {
    this.templateList = this.data.templateList || [];
  }

  addNewTemplate() {
    
    const dialogRef = this.dialog.open(SelectTemplate, {
      width: '100%',
      height: '100%',
      panelClass: 'happ-form-builder-dialog',
      data: {
        templateList: null, 
        selectedTemplate: null,
        allowMultipleSelection: false,
        allowSelection: true,
        fields: ['_id', 'title']
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.templateList.push(result);
      }
    });
  }

  delete(i: any) {
    if (this.templateList && this.templateList[i]) {
      this.templateList.splice(i, 1);
    }
  }

  edit(template) {
// edit
    this.helperService.getTemplate(template._id)
      .then((templateData) => {
        const dialogRef = this.dialog.open(TemplateModalComponent, {
          width: '100%',
          height: '100vh',
          data: { data: templateData, operation: 'edit', formVar: [], selectedFormName: '' },
          panelClass: 'happ-form-builder-dialog'
        });
        dialogRef.afterClosed().subscribe(result => {
        }, err => {
          this.helperService.openSnackBar(err);
          console.info(err);
        });
      }).catch(err => {
        console.info(err);
      });
  }

  saveData() {
    this.dialogRef.close(this.templateList);
  }
}
