<button mat-button
  [type]="options.type"
  [color]="options.buttonColor"
  [class.active]="options.active"
  [class.mat-raised-button]="options.raised"
  [class.mat-stroked-button]="options.stroked"
  [class.mat-flat-button]="options.flat"
  [class.fullWidth]="options.fullWidth"
  [disabled]="options.active || options.disabled">

  <span>
    <ng-content></ng-content>
  </span>

  <mat-progress-bar
    class="bar"
    *ngIf="options.active && !options.disabled"
    [color]="options.barColor"
    [mode]="options.mode"
    [value]="options.value">
  </mat-progress-bar>
</button>
